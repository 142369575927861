import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop, map, isEqual } from 'lodash';
import { Button, Pagination, SearchSelect, Input, Select } from '../../../components/base';
import { formatData,  normalizeAmount } from '../../../utils/transformer.util';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { KODE_MATPEL_TABLE_FIELDS } from '../../../constants/kurikulum/kurikulum.constant';
import { SEARCH_FIELDS} from '../../../constants/Akademi/DataKelas/kelas.constant';
import language from '../../../languages';

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class KodeMatpel extends PureComponent {
  constructor(props) {
    super(props);
    this._onClickAdd = this._onClickAdd.bind(this);
    this._onClickAddGroup = this._onClickAddGroup.bind(this);
    this._onClickEdit = this._onClickEdit.bind(this);
    this._onClickUpload = this._onClickUpload.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this._renderButtons = this._renderButtons.bind(this);
    this.handleGetData = this.handleGetData.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onChangeListAmount = this._onChangeListAmount.bind(this);
    this._getSubjectOptions= this._getSubjectOptions.bind(this);
    this._getClassesOptions= this._getClassesOptions.bind(this);
    
    this.state= {
      content: initialContent,
      list: [],
      workflow: {},
      listAmount: 5,
      filters:{},
      form: {
        value: {},
        error: {},
      },
      param:{}
    };
  
  }

  componentDidMount() {
    const { content } = this.state;
    const { user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id, units_id } = school_unit;
    this.handleGetData();
    if (isEmpty(content.list)) {
      this._onSearchContent({ page: 1 });
    }
    this._getSubjectOptions({ filters: { units_id: [units_id] }});
    this._getClassesOptions({ filters: { levels_id: [levels_id] }});

  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    const { workingUnit } = user;
    const { user: prevUser } = prevProps;
    const { workingUnit: prevWorkingUnit } = prevUser;

    if (!isEqual(workingUnit, prevWorkingUnit)) {
      // this.handleGetData();
      this._onSearchContent({ page: 1 });
    }
  }

  async _getClassesOptions(filters={}) {
    const { getClassesOptions, user } = this.props;
    const {school_unit} = user;
    const { levels_id } = school_unit;
    filters.levels_id = levels_id;

    const res = await getClassesOptions(filters);
    // console.log(res)
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        classes_prm: res,
      },
    }));
   
  }

  async _getParamOptions(type, filters={}) {
    const {getParamOptions, user} = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;
    if (type === 'classes' || type === 'classrooms') {
      filters.organizations_id = organizations_id;
      filters.levels_id = levels_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptions(filters, type);
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getSubjectOptions(filters={}) {
    const { getSubjectOptions, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id, units_id } = school_unit;
    const res = await getSubjectOptions(filters);
    filters.units_id = units_id;
    // console.log(res)
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        subject: res,
      },
    }));
   
  }

  

  async handleGetData(payload = {}) {
    const { handleListKodeMatpel, handleListAnnouncementStudent, user } = this.props;
    const { units_id, workingUnit, id, user_group, profile } = user;
    let unit_id = units_id;

    if (unit_id === null && workingUnit && workingUnit.id) {
      unit_id = workingUnit.id;
    }

    // if(user_group.id === 2){
      const res = await handleListKodeMatpel({...payload});

    this.setState({
      list: res,
    });
    // }

    
  }

  async onClickDelete(id) {
    const { handleDeleteSubjectDetail } = this.props;
    const res = await handleDeleteSubjectDetail(id);
    if(res){
      this._onSearchContent();
    }
  }
  async _onSearchContent(params = {}) {
    const { filters } = this.state;
    this.setState({
      content: initialContent,
    }, async () => {
      try {
        const { handleListKodeMatpel, user } = this.props;
        const { organizations_id, school_unit } = user;
        const { units_id } = school_unit;
        const {
          result = [], currentPage = 1, total = 0, totalPage = 1,
        } = await handleListKodeMatpel({ ...params, filters, organizations_id, units_id });
        // if (this.isMount) {
          this.setState({
            content: {
              loading: false,
              list: result,
              currentPage,
              total,
              totalPage,
            },
          });
        // }
      } catch (err) {
        // if (this.isMount) {
          this.setState({
            content: {
              loading: false,
              list: [],
              currentPage: 1,
              total: 1,
              totalPage: 1,
              error: true,
            },
          });
        }
      // }
    });
  }

  _renderButtons(data) {
    const { user } = this.props;
    const {user_group} = user;
    const button = {};
    
    return (
      <td>
        <div className="table__actions">
        
          
          {/* {user_group.id === 2? */}
             <Button
             onClick={() => this._onClickEdit(data)}
             title='Edit'
           />
           {/* :'' */}
          {/* } */}
            {/* {user_group.id === 2? */}
          
           <Button
             onClick={() => this.onClickDelete(data)}
             title='Delete'
           />
           {/* :'' */}
          {/* } */}
         
        </div>
      </td>
    );
  }

  onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this._onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  _onClickAdd() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/mata-pelajaran/buat-kode-mata-pelajaran', { data: '' });
  }

  _onClickAddGroup() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/mata-pelajaran/buat-group-mata-pelajaran', { data: '' });
  }

  _onClickEdit(val) {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/mata-pelajaran/buat-kode-mata-pelajaran', { data: val, isEdit: true });
  }

  _onClickUpload() {
    const { history } = this.props;
    history.push('/dashboard/administrasi/data-kelas/upload-kelas');
  }
  _onFormChange(event) {
    const {
      name,
      value,
      dataset,
      checked,
      type,
    } = event.target;
    const {
      inputType = 'text', inputArray = false, arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        if (type === 'checkbox') {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
        if (name === 'code_of_account') {
          newList[arrayPosition].isCredit = value.type;
        }
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = '';
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray
              ? { [fieldName]: newListError }
              : { [name]: '' }),
          },
        },
      };
    },() => {
      // if (name === 'levels_id') {
      //   this._getClassesOptions({ filters: { levels_id: [value] }} );
      // }
      if (name === 'classes_id') {
        this._getSubjectOptions({ filters: {classes_id: value} } );
      }
      
    });
  }
  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    this.setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        [name]: value,
      },
    }), () => this._onSearchContent());
  }
  onClickEdit(val) {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/mata-pelajaran/buat-kode-mata-pelajaran', { data: val, isEdit: true });
  }
  _onChangeListAmount(event) {
    const { keywords } = this.state;
    this.setState({
      listAmount: Number(event.target.value),
    });
    this._onSearchContent({ limit: event.target.value, keywords, page: 1 });
  }
  onFormChange(event) {
    const {
      name,
      value,
      dataset,
    } = event.target;
    console.log(name)
    const { inputArray = false, arrayPosition = 0, inputType = 'text' } = dataset;
    this.setState((prevState) => {
      let formattedValue = value;
      let newPlafon = [];
      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }

      if (inputArray) {
        newPlafon = prevState.form.value.plafon;
        newPlafon[arrayPosition][name] = value;
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray ? 
              { plafon: newPlafon } : 
              { [name]: formattedValue }
            )
          },
          error: {
            ...prevState.form.error,
            form:'',
            [name]: '',
          },
        },
      };
    });
  }


  render() {
    const {param, form, list, filters, content, classes = {}} = this.state;
    const { handleGetKelas, handleDeleteKelas,coa } = this.props;
    const {
      subject_name = '',
      students_id = '',
    } = filters;
    return (
      <div className="student-list">
         <div className="student-list__header">
              <div className="student-list__button">
                <Button
                  title="Tambah Kode Matapelajaran"
                  onClick={this._onClickAdd}
                />
              </div>
              <div className="student-list__button">
                <Button
                  title="Buat Group Pelajaran"
                  onClick={this._onClickAddGroup}
                />
              </div>
         </div>

         <div className="manage-registration">
              <div className="absensi-rekap__custom-form">
                <div className="absensi-rekap__custom-form-row">
                  <div className="absensi-rekap__custom-form-row__field">
                  <Select
                    type="text"
                    name="classes_id"
                    label="Tingkat Kelas"
                    placeholder="Pilih Kelas"
                    data={param.classes_prm}
                    onChange={this._onFormChange}
                    value={form.value.classes_id}
                    error={form.error.classes_id || ''}
                  />
                  </div>
                  <div className="absensi-rekap__custom-form-column__field">
                  <SearchSelect
                      noMargin
                      async={false}
                      name="name"
                      list={param.subject}
                      inputArray
                      onClick={this._onChangeFilter}
                      placeholder="Pilih Nama Matpel"
                      value={filters.name}
                     // labelName="label"
                     // valueName="value"
                      rightIcon="icon-search"
                      label= "Nama Matapelajaran"
                    />
                  </div>
                </div>
               
            </div>
            </div>
        <div className="budget__content">
         
          <div className="fund-request__content">
          <table className="table">
            <thead>
              <tr>
                {
                    map(KODE_MATPEL_TABLE_FIELDS.label, (field, idx) => (
                      <th key={`table_th_${idx}`}>{field}</th>
                   ))
                  }
              </tr>
            </thead>
            <tbody>
              {
                  map(content.list, (data, idx) => (
                    <tr key={`budget_row_${idx}`}>
                      {
                      map(KODE_MATPEL_TABLE_FIELDS.value, (field, fieldIdx) => {
                        if (fieldIdx === 0) {
                          return (
                            <td key={`table_index_${fieldIdx}_${idx}`}>{idx + 1}</td>
                          );
                        }
                        if (field.type === 'link') {
                          return (
                            <td key={`table_${fieldIdx}_${idx}`}><a href={"https://"+data.hyperlink} target="_blank" rel="noopener noreferrer">{data.hyperlink}</a></td>
                              // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                          );
                        }
                        return (
                          <td className={(field.type === 'number') ? 'nominal' : ''} key={`table_${fieldIdx}_${idx}`}>
                            {formatData(data, field)}
                          </td>
                        );
                      })
                      }
                      {this._renderButtons(data)}
                    </tr>
                  ))
                }
            </tbody>
          </table>
        </div>
        <div className="user-management__table-footer">
          <p className="user-management__page-info">
            {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${content.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${content.totalPage}`}
          </p>
          <Pagination
            totalPage={content.totalPage}
            currentPage={content.currentPage}
            onClick={this.onChangePage}
          />
        </div>
        </div>
      </div>
    );
  }
}
KodeMatpel.propTypes = {
  handleListClassData: PropTypes.func,
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
KodeMatpel.defaultProps = {
  handleListClassData: noop,
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};

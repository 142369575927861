export const FINANCE_PAYMENT_JOURNAL_FORM_FIELDS = [{
  type: 'date',
  name: 'date',
  label: 'Tanggal',
}, {
  type: 'text',
  name: 'journal_number',
  label: 'Nomor Jurnal/Bukti',
}, {
  type: 'text',
  name: 'payment_va_code',
  label: 'VA Code',
}, {
  type: 'text',
  name: 'mmyy',
  label: 'MMYY',
}, {
  type: 'text',
  name: 'payment_type',
  label: 'Tipe Pembayaran',
}, {
  type: 'text',
  inputType: 'number',
  name: 'nominal',
  label: 'Nominal',
}];

export const FINANCE_PAYMENT_JOURNAL_TABLE_FIELDS = {
  label: [
    'No',
    'Tanggal',
    'Nomor Jurnal',
    '',
  ],
  value: [
    {},
    { type: 'date', attribute: 'date' },
    { type: 'string', attribute: 'journal_number' }],
};

export const FINANCE_ADJUSTMENT_JOURNAL_TABLE_FIELDS = {
  label: [
    'No',
    'Tanggal',
    'Akun',
    'Keterangan',
    'Debet',
    'Kredit',
    '',
  ],
  value: [
    {},
    { type: 'date', attribute: 'date' },
    { type: 'string', attribute: 'journal_number' },
    { type: 'string', attribute: 'account' },
    { type: 'string', attribute: 'description' },
    { type: 'number', attribute: 'debet' },
    { type: 'number', attribute: 'credit' },
  ],
};

export const FINANCE_ADJUSTMENT_JOURNAL_FORM_FIELDS = [{
  type: 'date',
  name: 'date',
  label: 'Tanggal',
}, {
  type: 'text',
  name: 'journal_number',
  label: 'Nomor Jurnal/Bukti',
}];

export const FINANCE_CLOSING_JOURNAL_TABLE_FIELDS = {
  label: [
    'No',
    'Tanggal',
    'Akun',
    'Keterangan',
    'Debet',
    'Kredit',
    '',
  ],
  value: [
    {},
    { type: 'date', attribute: 'date' },
    { type: 'string', attribute: 'journal_number' },
    { type: 'string', attribute: 'account' },
    { type: 'string', attribute: 'description' },
    { type: 'number', attribute: 'debet' },
    { type: 'number', attribute: 'credit' },
  ],
};

export const FINANCE_CLOSING_JOURNAL_FORM_FIELDS = [{
  type: 'date',
  name: 'date',
  label: 'Tanggal',
}, {
  type: 'text',
  name: 'journal_number',
  label: 'Nomor Jurnal/Bukti',
}];

export const FINANCE_NON_KAS_JOURNAL_TABLE_FIELDS = {
  label: [
    'No',
    'Tanggal',
    'Akun',
    'Keterangan',
    'Debet',
    'Kredit',
    '',
  ],
  value: [
    {},
    { type: 'date', attribute: 'date' },
    { type: 'string', attribute: 'journal_number' },
    { type: 'string', attribute: 'account' },
    { type: 'string', attribute: 'description' },
    { type: 'number', attribute: 'debet' },
    { type: 'number', attribute: 'credit' },
  ],
};

export const FINANCE_NON_KAS_JOURNAL_FORM_FIELDS = [{
  type: 'date',
  name: 'date',
  label: 'Tanggal',
}, {
  type: 'text',
  name: 'journal_number',
  label: 'Nomor Jurnal/Bukti',
}];

export const FINANCE_GENERAL_JOURNAL_TABLE_FIELDS = {
  label: [
    'No',
    'Tanggal',
    'Akun',
    'Keterangan',
    'Debet',
    'Kredit',
    '',
  ],
  value: [
    {},
    { type: 'date', attribute: 'date' },
    { type: 'string', attribute: 'journal_number' },
    { type: 'string', attribute: 'account' },
    { type: 'string', attribute: 'description' },
    { type: 'number', attribute: 'debet' },
    { type: 'number', attribute: 'credit' },
  ],
};

export const FINANCE_GENERAL_JOURNAL_FORM_FIELDS = [{
  type: 'date',
  name: 'date',
  label: 'Tanggal',
}, {
  type: 'text',
  name: 'journal_number',
  label: 'Nomor Jurnal/Bukti',
  placeholder: 'Dibuat otomatis oleh sistem',
  disabled: true,
}];

export const FINANCE_GENERAL_LEDGER_TABLE_FIELDS = [{
  label: [
    'No.',
    'Sumber Jurnal',
    'Saldo Awal',
    'Debit',
    'Kredit',
    'Saldo Akhir',
  ],
  value: [
    {},
    { type: 'string', attribute: 'journal_type' },
    { type: 'number', attribute: 'starting_balance' },
    { type: 'number', attribute: 'debit' },
    { type: 'number', attribute: 'credit' },
    { type: 'number', attribute: 'final_balance' },
  ],
}];

import { createAction } from 'redux-actions';

export const APP_SETUP = 'APP_SETUP';
export const appSetup = createAction(APP_SETUP);

export const SET_LOADING = 'SET_LOADING';
export const setLoading = createAction(SET_LOADING);

export const SET_LANGUAGE = 'SET_LANGUAGE';
export const setLanguage = createAction(SET_LANGUAGE);

export const SET_ADDITIONAL_API_PAYLOAD_PARAMETER = 'SET_ADDITIONAL_API_PAYLOAD_PARAMETER';
export const setAPIPayloadParam = createAction(SET_ADDITIONAL_API_PAYLOAD_PARAMETER);

export const GET_COA_REQUESTED = 'GET_COA_REQUESTED';
export const GET_COA_SUCCEEDED = 'GET_COA_SUCCEEDED';
export const GET_COA_FAILED = 'GET_COA_FAILED';
export const getCoaRequested = createAction(GET_COA_REQUESTED);
export const getCoaSucceeded = createAction(GET_COA_SUCCEEDED);
export const getCoaFailed = createAction(GET_COA_FAILED);

export const GET_OPTIONS_REQUESTED = 'GET_COA_OPTIONS_REQUESTED';
export const GET_OPTIONS_SUCCEEDED = 'GET_COA_OPTIONS_SUCCEEDED';
export const GET_OPTIONS_FAILED = 'GET_COA_OPTIONS_FAILED';
export const getOptionsRequested = createAction(GET_OPTIONS_REQUESTED);
export const getOptionsSucceeded = createAction(GET_OPTIONS_SUCCEEDED);
export const getOptionsFailed = createAction(GET_OPTIONS_FAILED);

export const LOAD_JOURNAL_LIST = 'LOAD_JOURNAL_LIST';
export const LOAD_JOURNAL_LIST_SUCCESS = 'LOAD_JOURNAL_LIST_SUCCESS';
export const LOAD_JOURNAL_LIST_FAILED = 'LOAD_JOURNAL_LIST_FAILED';
export const POST_JOURNAL_POSTING = 'POST_JOURNAL_POSTING';
export const POST_JOURNAL_POSTING_SUCCESS = 'POST_JOURNAL_POSTING_SUCCESS';
export const POST_JOURNAL_POSTING_FAILED = 'POST_JOURNAL_POSTING_FAILED';
export const loadJournalList = createAction(LOAD_JOURNAL_LIST);
export const loadJournalListSuccess = createAction(LOAD_JOURNAL_LIST_SUCCESS);
export const loadJournalListFailed = createAction(LOAD_JOURNAL_LIST_FAILED);
export const postJournalPosting = createAction(POST_JOURNAL_POSTING);
export const postJournalUnposting = createAction(POST_JOURNAL_POSTING);
export const postJournalPostingSuccess = createAction(POST_JOURNAL_POSTING_SUCCESS);
export const postJournalPostingFailed = createAction(POST_JOURNAL_POSTING_FAILED);

import {
  map, noop,
} from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactExport from 'react-export-excel';
import { Button } from '../../../../../components/base';
import SelectCoa from '../../../../../components/SelectCoa/SelectCoa.component';
import { commaFormatted } from '../../../../../utils/transformer.util';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const userGroupPusat = ['Korektor Pusat', 'Manager Keuangan', 'Bendahara'];

const loopSubheaderTema = (subHeaderTema, budgetLoop, total, rows = []) => {
  const {
    tema: subTema, prm_detail_header_tema = [],
    prm_sub_header_detail_tema = [],
    name,
    code_sub_header_tema,
  } = subHeaderTema;
  console.log(subHeaderTema);
  let subTotal = total;
  rows.push(
    <tr>
      <td colSpan={10}>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {code_sub_header_tema} - {name}
      </td>
    </tr>
  );

  subTema.forEach((tema) => {
    const { id, code, name } = tema;
    const filtered = budgetLoop.filter(o => o.tema === id) || {};
    filtered.forEach((budget) => {
      const {
        target = '', allocations = [], execution_time, desc,
      } = budget;
      const rutin = allocations.find(o => o.plafond_id === 1) || 0;
      const kegiatan = allocations.find(o => o.plafond_id === 2) || 0;
      const swadaya = allocations.find(o => o.plafond_id === 3) || 0;
      const bpp = allocations.find(o => o.plafond_id === 4) || 0;
      const rowTotal = rutin.nominal || 0
        + kegiatan.nominal || 0
        + swadaya.nominal || 0
        + bpp.nominal || 0;

      subTotal.totalRutin += rutin.nominal || 0;
      subTotal.totalKegiatan += kegiatan.nominal || 0;
      subTotal.totalSwadaya += swadaya.nominal || 0;
      subTotal.totalBpp += bpp.nominal || 0;
      if (Object.keys(budget).length > 0) {
        rows.push(
          <tr>
            <td>{code}</td>
            <td>{name}</td>
            <td>{desc}</td>
            <td>{target}</td>
            <td>{execution_time}</td>
            <td className="nominal">{commaFormatted(rutin.nominal || 0)}</td>
            <td className="nominal">{commaFormatted(kegiatan.nominal || 0)}</td>
            <td className="nominal">{commaFormatted(swadaya.nominal || 0)}</td>
            <td className="nominal">{commaFormatted(bpp.nominal || 0)}</td>
            <td className="nominal">{commaFormatted(rowTotal) || 0}</td>
          </tr>,
        );
      }
    });
  });

  if (prm_detail_header_tema.length > 0) {
    prm_detail_header_tema.forEach((o) => {
      subTotal = loopSubheaderTema(o, budgetLoop, subTotal, rows);
    });
  }

  if (prm_sub_header_detail_tema.length > 0) {
    prm_sub_header_detail_tema.forEach((o) => {
      subTotal = loopSubheaderTema(o, budgetLoop, subTotal, rows);
    });
  }

  return subTotal;
};
export default class BudgetRapbuRekapExcel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // content: initialContent,
      // listAmount: 10,
      form: {
        value: {
          tema: '',
        },
      },
      selectedTema: '',
    };

    this.setRef = this.setRef.bind(this);
    this.renderRows = this.renderRows.bind(this);
    this.renderExcel = this.renderExcel.bind(this);
    this.onSearchTema = this.onSearchTema.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
  }

  setRef(el) {
    const { input } = el;
    const { dataset } = input;
    const { pos, referenceid } = dataset;
  }

  _onFormChange(event) {
    const { onChange } = this.props;
    onChange(event);
  }

  onSearchTema(tema = null) {
    // let divisiValue = this.state.selectedDivisi?this.state.selectedDivisi:2;
    const { handleGetTemaOptions } = this.props;
    const { target } = tema;
    const { value } = target;
    handleGetTemaOptions({
      tema,
      value,
      // divisiValue
    });

    this.setState({ selectedTema: target.value });
    // console.log(this.props)
    // console.log(value)
    // console.log(divisiValue)
    // console.log(this.state.selectedTema)
  }

  onFormChange(event) {
    const { name, value, dataset } = event.target;
    const { onChange, idx } = this.props;
    // console.log(onChange)
    // console.log(idx)

    onChange({
      target: {
        name,
        value,
        dataset: {
          ...dataset,
          inputArray: true,
          arrayPosition: idx,
        },
      },
    });
    // console.log(dataset.arrayPosition)
  }

  renderExcel() {
    const { dataloop: list = [], budgetloop } = this.state;
    const excelTbl = [];
    let items = Object.create({});
    let totalRutin = 0;
    let totalSwadaya = 0;
    let totalKegiatan = 0;
    let totalBpp = 0;
    let total = 0;
    let rows = [];
    list.forEach((header) => {
      const {
        tema: childTema, sub_header_tema: subHeader, name, divisi, code_header_tema,
      } = header;
      items = Object.create({});
      items.code = `${divisi} - ${code_header_tema}`;
      items.name = name;
      excelTbl.push(items);

      subHeader.forEach((subHeaderTema) => {
        const subTotal = loopSubheaderTema(subHeaderTema, budgetloop, {
          totalRutin,
          totalKegiatan,
          totalBpp,
          totalSwadaya,
        }, rows);
        totalRutin = subTotal.totalRutin || 0;
        totalKegiatan = subTotal.totalKegiatan || 0;
        totalSwadaya = subTotal.totalSwadaya || 0;
        totalBpp = subTotal.totalBpp || 0;
      });
    });

    if (list.length !== undefined) {
      map(list, (data) => {
        map(data.header_tema, (data_header_tema) => {
          const items = Object.create({});
          items.code = `${data.divisi} - ${data_header_tema.code_header_tema}`;
          items.name = data_header_tema.name;

          excelTbl.push(items);

          let total2 = 0;
          let total3 = 0;

          if (data_header_tema.sub_header_tema.length === 0) {
            map(data_header_tema.tema, (data_tema) => {
              let execution_time = '';
              let total = 0;
              let target = '';
              let i = '';
              let a = '';
              let nominal = 0;
              let rutinNominal = 0;
              let kegiatanNominal = 0;
              let bppNominal = 0;
              let danaNominal = 0;
              let plafond_id = '';

              budgetloop.forEach((o) => {
                const { tema, execution_time, target = null, allocations = []} = o;
                if (tema === data_tema.id) {
                  allocations.forEach((n) => {
                    if (plafond_id === 1) {
                      rutinNominal = parseInt(rutinNominal, 10) + parseInt(nominal, 10);
                    } else if (plafond_id === 2) {
                      kegiatanNominal = parseInt(kegiatanNominal, 10) + parseInt(nominal, 10);
                    } else if (plafond_id === 3) {
                      danaNominal = parseInt(danaNominal, 10) + parseInt(nominal, 10);
                    } else if (plafond_id === 4) {
                      bppNominal = parseInt(bppNominal, 10) + parseInt(nominal, 10);
                    }
                  });
                  total = rutinNominal + kegiatanNominal + danaNominal + bppNominal;
                }
              });
              total2 += total;

              const items = Object.create({});

              items.code = data_tema.code;
              items.name = data_tema.name;
              items.execution_time = execution_time;
              items.target = target;
              items.rutinNominal = `${'Rp' + '.'}${commaFormatted(rutinNominal)}`;
              items.kegiatanNominal = `${'Rp' + '.'}${commaFormatted(kegiatanNominal)}`;
              items.danaNominal = `${'Rp' + '.'}${commaFormatted(danaNominal)}`;
              items.bppNominal = `${'Rp' + '.'}${commaFormatted(bppNominal)}`;
              items.total = `${'Rp' + '.'}${commaFormatted(total)}`;

              excelTbl.push(items);
            });

            for (let t = 0; t < 1; t++) {
              const items = Object.create({});
              items.bppNominal = 'Total';
              items.total = `${'Rp' + '.'}${commaFormatted(total2)}`;

              excelTbl.push(items);
            }
          } else if (data_header_tema.tema.length == 0) {
            map(data_header_tema.sub_header_tema,
              (data_header_tema, idx_header_tema) => {
                const total2 = 0;
                let totalht2 = 0;
                map(data_header_tema.tema, (data_tema, idx_tema) => {
                  let execution_time = '';
                  let total = 0;
                  let target = '';
                  let i = '';
                  let a = '';
                  let nominal = 0;
                  let rutinNominal = 0;
                  let kegiatanNominal = 0;
                  let bppNominal = 0;
                  let danaNominal = 0;
                  let plafond_id = '';

                  for (i = 0; i < budgetloop.length; i++) {
                    // console.log(budgetloop[i].length)
                    if (budgetloop[i].tema == data_tema.id) {
                      // console.log(budgetloop[i].execution_time)
                      execution_time = budgetloop[i].execution_time;
                      // total += Number(budgetloop[i].total);
                      if (budgetloop[i].target != null) {
                        target = budgetloop[i].target;
                      }
                      for (a = 0; a < budgetloop[i].allocations.length; a++) {
                        plafond_id = budgetloop[i].allocations[a].plafond_id;
                        // console.log(plafond_id);
                        nominal = budgetloop[i].allocations[a].nominal;
                        if (plafond_id === 1) {
                          rutinNominal = parseInt(rutinNominal) + parseInt(nominal);
                        } else if (plafond_id === 2) {
                          kegiatanNominal = parseInt(kegiatanNominal) + parseInt(nominal);
                        } else if (plafond_id === 3) {
                          danaNominal = parseInt(danaNominal) + parseInt(nominal);
                        } else if (plafond_id === 4) {
                          bppNominal = parseInt(bppNominal) + parseInt(nominal);
                        }
                        // console.log(rutinNominal)
                      }
                      total = rutinNominal
                        + kegiatanNominal
                        + danaNominal
                        + bppNominal;
                    }
                  }
                  totalht2 += total;
                });
                total3 += totalht2;

                const items = Object.create({});

                items.code = data_header_tema.code_sub_header_tema;
                items.name = data_header_tema.name;
                items.total = `${'Rp' + '.'}${commaFormatted(totalht2)}`;

                excelTbl.push(items);
              });

            for (let t = 0; t < 1; t++) {
              const items = Object.create({});
              items.bppNominal = 'Total';
              items.total = `${'Rp' + '.'}${commaFormatted(total3)}`;

              excelTbl.push(items);
            }

            map(data_header_tema.sub_header_tema,
              (data_header_tema, idx_header_tema) => {
                let total2 = 0;

                const items = Object.create({});
                items.code = data_header_tema.code_sub_header_tema;
                // items.code = data_header_tema.code_header_tema;
                items.name = data_header_tema.name;

                excelTbl.push(items);

                let execution_time = '';
                let total = 0;
                let target = '';
                let i = '';
                let a = '';
                let nominal = 0;
                let rutinNominal = 0;
                let kegiatanNominal = 0;
                let bppNominal = 0;
                let danaNominal = 0;
                let plafond_id = '';
                for (i = 0; i < budgetloop.length; i++) {
                  if (budgetloop[i].tema == data_header_tema.id) {
                    execution_time = budgetloop[i].execution_time;
                    // total += Number(budgetloop[i].total);
                    if (budgetloop[i].target != null) {
                      target = budgetloop[i].target;
                    }
                    for (a = 0; a < budgetloop[i].allocations.length; a++) {
                      plafond_id = budgetloop[i].allocations[a].plafond_id;
                      // console.log(plafond_id);
                      nominal = budgetloop[i].allocations[a].nominal;
                      if (plafond_id === 1) {
                        rutinNominal = parseInt(rutinNominal) + parseInt(nominal);
                      } else if (plafond_id === 2) {
                        kegiatanNominal = parseInt(kegiatanNominal) + parseInt(nominal);
                      } else if (plafond_id === 3) {
                        danaNominal = parseInt(danaNominal) + parseInt(nominal);
                      } else if (plafond_id === 4) {
                        bppNominal = parseInt(bppNominal) + parseInt(nominal);
                      }
                    }
                    total = rutinNominal + kegiatanNominal + danaNominal + bppNominal;
                  }
                }

                map(data_header_tema.tema, (data_tema, idx_tema) => {
                  let execution_time = '';
                  let total = 0;
                  let target = '';
                  let i = '';
                  let a = '';
                  let nominal = 0;
                  let rutinNominal = 0;
                  let kegiatanNominal = 0;
                  let bppNominal = 0;
                  let danaNominal = 0;
                  let plafond_id = '';
                  for (i = 0; i < budgetloop.length; i++) {
                    // console.log(budgetloop[i].length)
                    if (budgetloop[i].tema == data_tema.id) {
                      // console.log(budgetloop[i].execution_time)
                      execution_time = budgetloop[i].execution_time;
                      // total += Number(budgetloop[i].total);
                      if (budgetloop[i].target != null) {
                        target = budgetloop[i].target;
                      }
                      for (a = 0; a < budgetloop[i].allocations.length; a++) {
                        plafond_id = budgetloop[i].allocations[a].plafond_id;
                        // console.log(plafond_id);
                        nominal = budgetloop[i].allocations[a].nominal;
                        if (plafond_id === 1) {
                          rutinNominal = parseInt(rutinNominal) + parseInt(nominal);
                        } else if (plafond_id === 2) {
                          kegiatanNominal = parseInt(kegiatanNominal) + parseInt(nominal);
                        } else if (plafond_id === 3) {
                          danaNominal = parseInt(danaNominal) + parseInt(nominal);
                        } else if (plafond_id === 4) {
                          bppNominal = parseInt(bppNominal) + parseInt(nominal);
                        }
                      }
                      total = rutinNominal
                        + kegiatanNominal
                        + danaNominal
                        + bppNominal;
                    }
                  }

                  total2 += total;

                  const items = Object.create({});

                  items.code = data_tema.code;
                  items.name = data_tema.name;
                  items.execution_time = execution_time;
                  items.target = target;
                  // items.rutinNominal = "Rp"+"."+commaFormatted(rutinNominal)+"cek";
                  items.rutinNominal = `${'Rp' + '.'}${commaFormatted(rutinNominal)}`;
                  items.kegiatanNominal = `${'Rp' + '.'}${commaFormatted(kegiatanNominal)}`;
                  items.danaNominal = `${'Rp' + '.'}${commaFormatted(danaNominal)}`;
                  items.bppNominal = `${'Rp' + '.'}${commaFormatted(bppNominal)}`;
                  items.total = `${'Rp' + '.'}${commaFormatted(total)}`;

                  excelTbl.push(items);
                });
                for (let t = 0; t < 1; t++) {
                  const items = Object.create({});
                  items.bppNominal = 'Total';
                  items.total = `${'Rp' + '.'}${commaFormatted(total2)}`;
                  // items.total ="Rp"+"."+commaFormatted(total2)+"cek";

                  excelTbl.push(items);
                }
              });

          } else if (
            data_header_tema.sub_header_tema
            && data_header_tema.tema
          ) {
            map(data_header_tema.tema, (data_tema, idx_tema) => {
              let execution_time = '';
              let total = 0;
              let target = '';
              let i = '';
              let a = '';
              let nominal = 0;
              let rutinNominal = 0;
              let kegiatanNominal = 0;
              let bppNominal = 0;
              let danaNominal = 0;
              let plafond_id = '';

              for (i = 0; i < budgetloop.length; i++) {
                if (budgetloop[i].tema == data_tema.id) {
                  execution_time = budgetloop[i].execution_time;
                  // total += Number(budgetloop[i].total);
                  if (budgetloop[i].target != null) {
                    target = budgetloop[i].target;
                  }

                  for (a = 0; a < budgetloop[i].allocations.length; a++) {
                    plafond_id = budgetloop[i].allocations[a].plafond_id;
                    // console.log(plafond_id);
                    nominal = budgetloop[i].allocations[a].nominal;

                    if (plafond_id === 1) {
                      rutinNominal = parseInt(rutinNominal) + parseInt(nominal);
                    } else if (plafond_id === 2) {
                      kegiatanNominal = parseInt(kegiatanNominal) + parseInt(nominal);
                    } else if (plafond_id === 3) {
                      danaNominal = parseInt(danaNominal) + parseInt(nominal);
                    } else if (plafond_id === 4) {
                      bppNominal = parseInt(bppNominal) + parseInt(nominal);
                    }
                  }
                  total = rutinNominal + kegiatanNominal + danaNominal + bppNominal;
                }
              }

              total2 += total;
              total3 += total2;

              // console.log(total)
              const items = Object.create({});
              items.code = data_tema.code_sub_header_tema;
              items.code = data_tema.code;
              items.name = data_tema.name;
              items.execution_time = execution_time;
              items.target = target;
              items.rutinNominal = `${'Rp' + '.'}${commaFormatted(rutinNominal)}`;
              items.kegiatanNominal = `${'Rp' + '.'}${commaFormatted(kegiatanNominal)}`;
              items.danaNominal = `${'Rp' + '.'}${commaFormatted(danaNominal)}`;
              items.bppNominal = `${'Rp' + '.'}${commaFormatted(bppNominal)}`;
              items.total = `${'Rp' + '.'}${commaFormatted(total)}`;

              excelTbl.push(items);
            });

            for (let t = 0; t < 1; t++) {
              const items = Object.create({});
              items.bppNominal = 'Total';
              items.total2 = `${'Rp' + '.'}${commaFormatted(total3)}`;

              excelTbl.push(items);
            }

            map(data_header_tema.sub_header_tema,
              (data_header_tema, idx_header_tema) => {
                const items = Object.create({});
                items.code = `${data.divisi} - ${data_header_tema.code_sub_header_tema}`;
                // items.code = data_header_tema.code_header_tema;
                items.name = data_header_tema.name;

                excelTbl.push(items);

                map(data_header_tema.tema, (data_tema, idx_tema) => {
                  let execution_time = '';
                  let total = 0;
                  let target = '';
                  let i = '';
                  let a = '';
                  let nominal = 0;
                  let rutinNominal = 0;
                  let kegiatanNominal = 0;
                  let bppNominal = 0;
                  let danaNominal = 0;
                  let plafond_id = '';

                  for (i = 0; i < budgetloop.length; i++) {
                    if (budgetloop[i].tema == data_tema.id) {
                      execution_time = budgetloop[i].execution_time;
                      if (budgetloop[i].target != null) {
                        target = budgetloop[i].target;
                      }

                      for (a = 0; a < budgetloop[i].allocations.length; a++) {
                        plafond_id = budgetloop[i].allocations[a].plafond_id;
                        nominal = budgetloop[i].allocations[a].nominal;

                        if (plafond_id === 1) {
                          rutinNominal = parseInt(rutinNominal) + parseInt(nominal);
                        } else if (plafond_id === 2) {
                          kegiatanNominal = parseInt(kegiatanNominal) + parseInt(nominal);
                        } else if (plafond_id === 3) {
                          danaNominal = parseInt(danaNominal) + parseInt(nominal);
                        } else if (plafond_id === 4) {
                          bppNominal = parseInt(bppNominal) + parseInt(nominal);
                        }
                      }
                      total = rutinNominal
                        + kegiatanNominal
                        + danaNominal
                        + bppNominal;
                    }
                  }
                  const items = Object.create({});

                  items.code = data_tema.code;
                  items.name = data_tema.name;
                  items.execution_time = execution_time;
                  items.target = target;
                  items.rutinNominal = `${'Rp' + '.'}${commaFormatted(rutinNominal)}`;
                  items.kegiatanNominal = `${'Rp' + '.'}${commaFormatted(kegiatanNominal)}`;
                  items.danaNominal = `${'Rp' + '.'}${commaFormatted(danaNominal)}`;
                  items.bppNominal = `${'Rp' + '.'}${commaFormatted(bppNominal)}`;
                  items.total = `${'Rp' + '.'}${commaFormatted(total)}`;

                  excelTbl.push(items);
                });
                for (let t = 0; t < 1; t++) {
                  const items = Object.create({});
                  items.bppNominal = 'Total';
                  items.total = `${'Rp' + '.'}${commaFormatted(total2)}`;

                  excelTbl.push(items);
                }
              });
          } else if (data_header_tema.sub_header_tema) {
            map(data_header_tema.sub_header_tema,
              (data_header_tema, idx_sub_header_tema) => {
                if (data_header_tema.prm_detail_header_tema) {
                  map(data_header_tema.prm_detail_header_tema,
                    (data_header_tema, idx_header_tema) => {
                      map(data_header_tema.tema, (data_tema, idx_tema) => {
                        let execution_time = '';
                        let total = 0;
                        let target = '';
                        let i = '';
                        let a = '';
                        let nominal = 0;
                        let rutinNominal = 0;
                        let kegiatanNominal = 0;
                        let bppNominal = 0;
                        let danaNominal = 0;
                        let plafond_id = '';

                        for (i = 0; i < budgetloop.length; i++) {
                          if (budgetloop[i].tema == data_tema.id) {
                            execution_time = budgetloop[i].execution_time;
                            // total += Number(budgetloop[i].total);
                            if (budgetloop[i].target != null) {
                              target = budgetloop[i].target;
                            }

                            for (
                              a = 0;
                              a < budgetloop[i].allocations.length;
                              a++
                            ) {
                              plafond_id = budgetloop[i].allocations[a].plafond_id;
                              // console.log(plafond_id);
                              nominal = budgetloop[i].allocations[a].nominal;

                              if (plafond_id === 1) {
                                rutinNominal = parseInt(rutinNominal) + parseInt(nominal);
                              } else if (plafond_id === 2) {
                                kegiatanNominal = parseInt(kegiatanNominal) + parseInt(nominal);
                              } else if (plafond_id === 3) {
                                danaNominal = parseInt(danaNominal) + parseInt(nominal);
                              } else if (plafond_id === 4) {
                                bppNominal = parseInt(bppNominal) + parseInt(nominal);
                              }
                            }
                            total = rutinNominal
                              + kegiatanNominal
                              + danaNominal
                              + bppNominal;
                          }
                        }

                        const items = Object.create({});

                        items.code = data_tema.code;
                        items.name = data_tema.name;
                        items.execution_time = execution_time;
                        items.target = target;
                        items.rutinNominal = `${'Rp' + '.'}${commaFormatted(rutinNominal)}`;
                        items.kegiatanNominal = `${'Rp' + '.'}${commaFormatted(kegiatanNominal)}`;
                        items.danaNominal = `${'Rp' + '.'}${commaFormatted(danaNominal)}`;
                        items.bppNominal = `${'Rp' + '.'}${commaFormatted(bppNominal)}`;
                        items.total = `${'Rp' + '.'}${commaFormatted(total)}`;

                        excelTbl.push(items);
                      });
                      for (let t = 0; t < 1; t++) {
                        const items = Object.create({});
                        items.bppNominal = 'Total';
                        items.total = `${'Rp' + '.'}${commaFormatted(total2)}`;

                        excelTbl.push(items);
                      }

                      if (data_header_tema.prm_sub_detail_header_tema) {
                        // console.log(data_header_tema.prm_sub_detail_header_tema)
                        map(data_header_tema.prm_sub_detail_header_tema,
                          (data_header_tema, idx_header_tema) => {
                            map(data_header_tema.tema,
                              (data_tema, idx_tema) => {
                                let execution_time = '';
                                let total = 0;
                                let target = '';
                                let i = '';
                                let a = '';
                                let nominal = 0;
                                let rutinNominal = 0;
                                let kegiatanNominal = 0;
                                let bppNominal = 0;
                                let danaNominal = 0;
                                let plafond_id = '';

                                for (i = 0; i < budgetloop.length; i++) {
                                  if (budgetloop[i].tema == data_tema.id) {
                                    execution_time = budgetloop[i].execution_time;
                                    // total += Number(budgetloop[i].total);
                                    target = budgetloop[i].target;

                                    for (
                                      a = 0;
                                      a < budgetloop[i].allocations.length;
                                      a++
                                    ) {
                                      plafond_id = budgetloop[i].allocations[a].plafond_id;
                                      // console.log(plafond_id);
                                      nominal = budgetloop[i].allocations[a].nominal;

                                      if (plafond_id === 1) {
                                        rutinNominal = parseInt(rutinNominal)
                                          + parseInt(nominal);
                                      } else if (plafond_id === 2) {
                                        kegiatanNominal = parseInt(kegiatanNominal)
                                          + parseInt(nominal);
                                      } else if (plafond_id === 3) {
                                        danaNominal = parseInt(danaNominal)
                                          + parseInt(nominal);
                                      } else if (plafond_id === 4) {
                                        bppNominal = parseInt(bppNominal)
                                          + parseInt(nominal);
                                      }
                                    }
                                    total = rutinNominal
                                      + kegiatanNominal
                                      + danaNominal
                                      + bppNominal;
                                  }
                                }

                                const items = Object.create({});

                                items.code = data_tema.code;
                                items.name = data_tema.name;
                                items.execution_time = execution_time;
                                items.target = target;
                                items.rutinNominal = `${'Rp' + '.'}${commaFormatted(rutinNominal)}`;
                                items.kegiatanNominal = `${'Rp' + '.'}${commaFormatted(kegiatanNominal)}`;
                                items.danaNominal = `${'Rp' + '.'}${commaFormatted(danaNominal)}`;
                                items.bppNominal = `${'Rp' + '.'}${commaFormatted(bppNominal)}`;
                                items.total = `${'Rp' + '.'}${commaFormatted(total)}`;

                                excelTbl.push(items);
                              });
                            for (let t = 0; t < 1; t++) {
                              const items = Object.create({});
                              items.bppNominal = 'Total';
                              items.total = `${'Rp' + '.'}${commaFormatted(total2)}`;

                              excelTbl.push(items);
                            }
                          });
                      } else {
                        // console.log(data_header_tema.code)
                        map(data_header_tema.tema,
                          (data_header_tema, idx_tema) => {
                            let execution_time = '';
                            let total = 0;
                            let target = '';
                            let i = '';
                            let a = '';
                            let nominal = 0;
                            let rutinNominal = 0;
                            let kegiatanNominal = 0;
                            let bppNominal = 0;
                            let danaNominal = 0;
                            let plafond_id = '';

                            for (i = 0; i < budgetloop.length; i++) {
                              if (budgetloop[i].tema == data_header_tema.id) {
                                execution_time = budgetloop[i].execution_time;
                                // total += Number(budgetloop[i].total);
                                if (budgetloop[i].target != null) {
                                  target = budgetloop[i].target;
                                }

                                for (
                                  a = 0;
                                  a < budgetloop[i].allocations.length;
                                  a++
                                ) {
                                  plafond_id = budgetloop[i].allocations[a].plafond_id;
                                  // console.log(plafond_id);
                                  nominal = budgetloop[i].allocations[a].nominal;

                                  if (plafond_id === 1) {
                                    rutinNominal = parseInt(rutinNominal)
                                      + parseInt(nominal);
                                  } else if (plafond_id === 2) {
                                    kegiatanNominal = parseInt(kegiatanNominal)
                                      + parseInt(nominal);
                                  } else if (plafond_id === 3) {
                                    danaNominal = parseInt(danaNominal) + parseInt(nominal);
                                  } else if (plafond_id === 4) {
                                    bppNominal = parseInt(bppNominal) + parseInt(nominal);
                                  }
                                }
                                total = rutinNominal
                                  + kegiatanNominal
                                  + danaNominal
                                  + bppNominal;
                              }
                            }

                            const items = Object.create({});

                            items.code = data_header_tema.code;
                            items.name = data_header_tema.name;
                            items.execution_time = execution_time;
                            items.target = target;
                            items.rutinNominal = `${'Rp' + '.'}${commaFormatted(rutinNominal)}`;
                            items.kegiatanNominal = `${'Rp' + '.'}${commaFormatted(kegiatanNominal)}`;
                            items.danaNominal = `${'Rp' + '.'}${commaFormatted(danaNominal)}`;
                            items.bppNominal = `${'Rp' + '.'}${commaFormatted(bppNominal)}`;
                            items.total = `${'Rp' + '.'}${commaFormatted(total)}`;

                            excelTbl.push(items);
                          });
                        for (let t = 0; t < 1; t++) {
                          const items = Object.create({});
                          items.bppNominal = 'Total';
                          items.total = `${'Rp' + '.'}${commaFormatted(total2)}`;

                          excelTbl.push(items);
                        }
                      }
                    });
                }
              });
          }

          map(data_header_tema.sub_header_tema,
            (data_header_tema, idx_sub_header_tema) => {
              if (data_header_tema.prm_detail_header_tema) {
                map(data_header_tema.prm_detail_header_tema,
                  (data_header_tema, idx_header_tema) => {
                    map(data_header_tema.tema, (data_tema, idx_tema) => {
                      let execution_time = '';
                      let total = 0;
                      let target = '';
                      let i = '';
                      let a = '';
                      let nominal = 0;
                      let rutinNominal = 0;
                      let kegiatanNominal = 0;
                      let bppNominal = 0;
                      let danaNominal = 0;
                      let plafond_id = '';

                      for (i = 0; i < budgetloop.length; i++) {
                        if (budgetloop[i].tema == data_tema.id) {
                          execution_time = budgetloop[i].execution_time;
                          // total += Number(budgetloop[i].total);
                          if (budgetloop[i].target != null) {
                            target = budgetloop[i].target;
                          }

                          for (
                            a = 0;
                            a < budgetloop[i].allocations.length;
                            a++
                          ) {
                            plafond_id = budgetloop[i].allocations[a].plafond_id;
                            // console.log(plafond_id);
                            nominal = budgetloop[i].allocations[a].nominal;

                            if (plafond_id === 1) {
                              rutinNominal = parseInt(rutinNominal) + parseInt(nominal);
                            } else if (plafond_id === 2) {
                              kegiatanNominal = parseInt(kegiatanNominal) + parseInt(nominal);
                            } else if (plafond_id === 3) {
                              danaNominal = parseInt(danaNominal) + parseInt(nominal);
                            } else if (plafond_id === 4) {
                              bppNominal = parseInt(bppNominal) + parseInt(nominal);
                            }
                          }
                          total = rutinNominal
                            + kegiatanNominal
                            + danaNominal
                            + bppNominal;
                        }
                      }

                      const items = Object.create({});

                      items.code = data_tema.code;
                      items.name = data_tema.name;
                      items.execution_time = execution_time;
                      items.target = target;
                      items.rutinNominal = `${'Rp' + '.'}${commaFormatted(rutinNominal)}`;
                      items.kegiatanNominal = `${'Rp' + '.'}${commaFormatted(kegiatanNominal)}`;
                      items.danaNominal = `${'Rp' + '.'}${commaFormatted(danaNominal)}`;
                      items.bppNominal = `${'Rp' + '.'}${commaFormatted(bppNominal)}`;
                      items.total = `${'Rp' + '.'}${commaFormatted(total)}`;

                      excelTbl.push(items);
                    });
                    for (let t = 0; t < 1; t++) {
                      const items = Object.create({});
                      items.bppNominal = 'Total';
                      items.total = `${'Rp' + '.'}${commaFormatted(total2)}`;

                      excelTbl.push(items);
                    }

                    if (data_header_tema.prm_sub_detail_header_tema) {
                      // console.log(data_header_tema.prm_sub_detail_header_tema)
                      map(data_header_tema.prm_sub_detail_header_tema,
                        (data_header_tema, idx_header_tema) => {
                          map(data_header_tema.tema, (data_tema, idx_tema) => {
                            let execution_time = '';
                            let total = 0;
                            let target = '';
                            let i = '';
                            let a = '';
                            let nominal = 0;
                            let rutinNominal = 0;
                            let kegiatanNominal = 0;
                            let bppNominal = 0;
                            let danaNominal = 0;
                            let plafond_id = '';

                            for (i = 0; i < budgetloop.length; i++) {
                              if (budgetloop[i].tema == data_tema.id) {
                                execution_time = budgetloop[i].execution_time;
                                // total += Number(budgetloop[i].total);
                                if (budgetloop[i].target != null) {
                                  target = budgetloop[i].target;
                                }

                                for (
                                  a = 0;
                                  a < budgetloop[i].allocations.length;
                                  a++
                                ) {
                                  plafond_id = budgetloop[i].allocations[a].plafond_id;
                                  // console.log(plafond_id);
                                  nominal = budgetloop[i].allocations[a].nominal;

                                  if (plafond_id === 1) {
                                    rutinNominal = parseInt(rutinNominal)
                                      + parseInt(nominal);
                                  } else if (plafond_id === 2) {
                                    kegiatanNominal = parseInt(kegiatanNominal)
                                      + parseInt(nominal);
                                  } else if (plafond_id === 3) {
                                    danaNominal = parseInt(danaNominal) + parseInt(nominal);
                                  } else if (plafond_id === 4) {
                                    bppNominal = parseInt(bppNominal) + parseInt(nominal);
                                  }
                                }
                                total = rutinNominal
                                  + kegiatanNominal
                                  + danaNominal
                                  + bppNominal;
                              }
                            }

                            const items = Object.create({});

                            items.code = data_tema.code;
                            items.name = data_tema.name;
                            items.execution_time = execution_time;
                            items.target = target;
                            items.rutinNominal = `${'Rp' + '.'}${commaFormatted(rutinNominal)}`;
                            items.kegiatanNominal = `${'Rp' + '.'}${commaFormatted(kegiatanNominal)}`;
                            items.danaNominal = `${'Rp' + '.'}${commaFormatted(danaNominal)}`;
                            items.bppNominal = `${'Rp' + '.'}${commaFormatted(bppNominal)}`;
                            items.total = `${'Rp' + '.'}${commaFormatted(total)}`;

                            excelTbl.push(items);
                          });
                          for (let t = 0; t < 1; t++) {
                            const items = Object.create({});
                            items.bppNominal = 'Total';
                            items.total = `${'Rp' + '.'}${commaFormatted(total2)}`;

                            excelTbl.push(items);
                          }
                        });
                    }
                  });
              }
            });
        });
      });

      const rows = [];
      rows.push([
        <ExcelFile
          element={<Button style={{ height: 30, width: 250 }}>Excel</Button>}
        >
          <ExcelSheet data={excelTbl} name="excel">
            <ExcelColumn label="Tema" value="tema" />
            <ExcelColumn label="Nama" value="name" />
            <ExcelColumn label="Waktu Pelaksanaan" value="execution_time" />
            <ExcelColumn label="Target" value="target" />
            <ExcelColumn label="Rutin" value="rutinNominal" />
            <ExcelColumn label="Kegiatan" value="kegiatanNominal" />
            <ExcelColumn label="Dana Pemerintah" value="danaNominal" />
            <ExcelColumn
              label="Biaya Pengembangan Pendidikan"
              value="bppNominal"
            />
            <ExcelColumn label="Total" value="total" />
          </ExcelSheet>
        </ExcelFile>,
      ]);
      return rows;
    }
  }

  renderRows() {
    const { dataloop: list = [], budgetloop = [] } = this.props;
    const rows = [];
    let totalRutin = 0;
    let totalSwadaya = 0;
    let totalKegiatan = 0;
    let totalBpp = 0;
    let total = 0;
    list.forEach((data) => {
      const { header_tema = [], divisi } = data;
      rows.push(
        <tr>
          <td colSpan={10}>
            {divisi}
          </td>
        </tr>
      );
      header_tema.forEach((header) => {
        const {
          code_header_tema, name: headerName, sub_header_tema: subHeader = [], tema: childTema = [],
        } = header;
        rows.push(
          <tr>
            <td colSpan={10}>
              &nbsp;&nbsp;&nbsp;&nbsp;{code_header_tema} - {headerName}
            </td>
          </tr>);
        childTema.forEach((tema) => {
          const { id, code, name } = tema;
          const filtered = budgetloop.filter(o => o.tema === id) || {};
          rows.push(
            <tr>
              <td colSpan={10}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {code} - {name}
              </td>
            </tr>);
          filtered.forEach((budget) => {
            const {
              target = '', allocations = [], execution_time, desc,
            } = budget;
            const rutin = allocations.find(o => o.plafond_id === 1) || 0;
            const kegiatan = allocations.find(o => o.plafond_id === 2) || 0;
            const swadaya = allocations.find(o => o.plafond_id === 3) || 0;
            const bpp = allocations.find(o => o.plafond_id === 4) || 0;
            const subTotal = rutin.nominal || 0 + kegiatan.nominal || 0 + swadaya.nominal || 0 + bpp.nominal || 0;
  
            totalRutin += rutin.nominal || 0;
            totalKegiatan += kegiatan.nominal || 0;
            totalSwadaya += swadaya.nominal || 0;
            totalBpp += bpp.nominal || 0;
            if (Object.keys(budget).length > 0) {
              rows.push(
                <tr>
                  <td>{code}</td>
                  <td>{name}</td>
                  <td>{desc}</td>
                  <td>{target}</td>
                  <td>{execution_time}</td>
                  <td className="nominal">{commaFormatted(rutin.nominal || 0)}</td>
                  <td className="nominal">{commaFormatted(kegiatan.nominal || 0)}</td>
                  <td className="nominal">{commaFormatted(swadaya.nominal || 0)}</td>
                  <td className="nominal">{commaFormatted(bpp.nominal || 0)}</td>
                  <td className="nominal">{commaFormatted(subTotal) || 0}</td>
                </tr>,
              );
            }
          });
        });
        subHeader.forEach((subHeaderTema) => {
          const subTotal = loopSubheaderTema(subHeaderTema, budgetloop, {
            totalRutin,
            totalKegiatan,
            totalBpp,
            totalSwadaya,
          }, rows);
          totalRutin = subTotal.totalRutin || 0;
          totalKegiatan = subTotal.totalKegiatan || 0;
          totalSwadaya = subTotal.totalSwadaya || 0;
          totalBpp = subTotal.totalBpp || 0;
        });
      });

      
    });

    total += totalRutin + totalKegiatan + totalSwadaya + totalBpp;

    rows.push(
      <tfoot>
        <tr className="total">
          <td colSpan={4}>Total</td>
          <td className="nominal">{commaFormatted(totalRutin)}</td>
          <td className="nominal">{commaFormatted(totalKegiatan)}</td>
          <td className="nominal">{commaFormatted(totalSwadaya)}</td>
          <td className="nominal">{commaFormatted(totalBpp)}</td>
          <td className="nominal">{commaFormatted(total)}</td>
        </tr>
      </tfoot>
    );

    return rows;
  }

  render() {
    const {
      error,
      target,
      onSearchTema,
      tema,
    } = this.props;

    return (
      <div className="budget_content">
        <div>
          {this.renderExcel()}
        </div>
        <br />
        <br />
        <table className="table">
          <thead>
            <tr>
              <th
                style={{ width: '50%' }}
                rowSpan={2}
              >
                Kode
              </th>
              <th
                style={{ width: '5%' }}
                rowSpan={2}
              >
                Nama
              </th>
              <th rowSpan={2}>
                Waktu Pelaksanaan
              </th>
              <th rowSpan={2}>
                Target
              </th>
              <th rowSpan={2}>
                Rutin
              </th>
              <th rowSpan={2}>
                Kegiatan
              </th>
              <th rowSpan={2}>
                Dana BOS
              </th>
              <th rowSpan={2}>
                Biaya Pengembangan Pendidikan
              </th>
              <th rowSpan={2}>
                Total
              </th>
            </tr>
          </thead>          
          {this.renderRows()}
        </table>
      </div>
    );
  }
}
BudgetRapbuRekapExcel.propTypes = {
  onSelectBudgetDetail: PropTypes.func,
  rapbuSummary: PropTypes.object.isRequired,
  user: PropTypes.object,
  recommendations: PropTypes.object,
  handleGetTemaOptions: PropTypes.func,
  onSearchTema: PropTypes.object,
};
BudgetRapbuRekapExcel.defaultProps = {
  handleGetTemaOptions: noop,
  onSearchCoa: noop,
  onChange: noop,
  onSearchTema: noop,
  onSelectBudgetDetail: noop,
  user: null,
  recommendations: {},
  error: {},
};

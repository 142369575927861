import { connect } from 'react-redux';
import ManageBudgetRapbuDetail from '../../../../views/Finance/Budgeting/Budget/ManageBudgetRapbuDetail.finance.view';
import {
  getBudgetDetail, handleGetBudgetDetailRapbu,  saveRecommendations, submitApproval, rejectRapbu,
} from '../../../../states/thunks/finance.thunk';
import {getTemaFilterOptions , getDivisiOptions} from '../../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    user: state.user,
    tema: state.options.tema,
    divisi: state.options.divisi,
    
  };
  
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetBudgetDetailRapbu: payload => dispatch(handleGetBudgetDetailRapbu(payload)),
    handleGetTemaOptions: payload => dispatch(getTemaFilterOptions(payload)),
    handleGetDivisiOptions: payload => dispatch(getDivisiOptions(payload)),

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageBudgetRapbuDetail);


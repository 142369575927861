import { connect } from 'react-redux';
import StudentNumber from '../../../views/Student/report/StudentNumber.student.view';
import { getStudent } from '../../../states/thunks/student.thunk';
import { getClasses, getLevels, getParamOptionsAcademics, getAllStudentOptions, getClassesOptions, getPeriodsOptions } from '../../../states/thunks/options.thunk';


function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    result: state.result,
    user: state.user || {},
  }
}
function mapDispatchToProps(dispatch) {
  return {
    handleGetClasses: payload => dispatch(getClasses(payload)),
    handleGetStudent: () => dispatch(getStudent()),
    handleGetLevels: payload => dispatch(getLevels(payload)),
    getAllStudentOptions: (payload) => dispatch(getAllStudentOptions(payload)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    getClassesOptions: (payload) => dispatch(getClassesOptions(payload)),
    getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentNumber);
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';
import pendapatan from '../../../../assets/img/finance/pendapatan.png';
import pengeluaran from '../../../../assets/img/finance/pengeluaran.png';
import rapbu from '../../../../assets/img/finance/rapbu.png';
import rekap from '../../../../assets/img/finance/rekap.png';
import detail from '../../../../assets/img/finance/detail.png';

export default class BudgetType3 extends PureComponent {
  constructor(props) {
    super(props);
    this.clickHandlers = {};
    this.onClick = this.onClick.bind(this);
  }

  onClick(type, code) {
    const { location, history } = this.props;
    const { data } = location.state;
    const { account = [], unique_id, periode } = location.state.data;
    // console.log(this.props);
    // console.log(location);
    // console.log(data)
    if (type === 'RAPBU') {
      history.push('/dashboard/keuangan/penganggaran/rapbs', {
        type,
        code: '',
        account_unique_id: '',
        head_unique_id: unique_id,
      });
    }
    else if (type === 'RAPBY') {
        history.push('/dashboard/keuangan/penganggaran/rapby', {
          type,
          code: '',
          account_unique_id: '',
          head_unique_id: unique_id,
        });
      }
    
    else if (type === 'DETAIL_RAPBU') {
      history.push('/dashboard/keuangan/penganggaran/detail-rapbs', {
        type,
        code: '',
        head_unique_id: unique_id,
      });
    }
    else if (type === 'REKAP_RAPBU') {
      history.push('/dashboard/keuangan/penganggaran/rekap-rapbs', {
        type,
        code: '',
        head_unique_id: unique_id,
      });
    }

    else {
      const selectedData = find(account, ['account_type', code]);
      history.push('/dashboard/keuangan/penganggaran/kelola-anggaran2', {
        type,
        code,
        account_unique_id: selectedData.unique_id,
        head_unique_id: selectedData.head,
      });
    }
  }

  getClickHandler(type, code) {
    // console.log(type)
    
    if (!Object.prototype.hasOwnProperty.call(this.clickHandlers, type)) {
      this.clickHandlers[type] = () => this.onClick(type, code);
    }
    return this.clickHandlers[type];

  }

  render() {
    const { user } = this.props;
    const { user_group } = user;
    return (
      <div className="bookkeeping">
       
        {/* <div className="finance__list">
          <button onClick={this.getClickHandler('RAPBU')}>
            <img src={rapbu} alt="RAPBS" />
            <h3>RAPBS</h3>
          </button>
        </div> */}

        <div className="finance__list">
          <button onClick={this.getClickHandler('REKAP_RAPBU')}>
            <img src={rekap} alt="REKAP RAPBU"/>
            <h3>Rekap RAPBS</h3>
          </button>
        </div>

        <div className="finance__list">
          <button onClick={this.getClickHandler('DETAIL_RAPBU')}>
            <img src={detail} alt="DETAIL RAPBU" />
            <h3>Detail RAPBS</h3>
          </button>
        </div>

        <div className="finance__list">
          <button onClick={this.getClickHandler('RAPBY')}>
            <img src={rapbu} alt="RAPBY" />
            <h3>RAPBY</h3>
          </button>
        </div>

      </div>
    );
  }
}
BudgetType3.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

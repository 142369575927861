import React from 'react';
import PropTypes from 'prop-types';

import ManageMonthlyActivityActivity from './ManageMonthlyActivityActivity.finance.view';
import ManageMonthlyActivityProcurement from './ManageMonthlyActivityProcurement.finance.view';
import ManageMonthlyActivityMaintenance from './ManageMonthlyActivityMaintenance.finance.view';

export default function ManageMonthlyActivityPlan(props) {
  const { location } = props;
  const { state } = location;
  const { type } = state;

  return (
    <>
      {
        type === 'procurement' && (
          <ManageMonthlyActivityProcurement {...props} />
        )
      }
      {
        type === 'maintenance' && (
          <ManageMonthlyActivityMaintenance {...props} />
        )
      }
      {
        type === 'activity' && (
          <ManageMonthlyActivityActivity {...props} />
        )
      }
    </>
  );
}
ManageMonthlyActivityPlan.propTypes = {
  handleGetAvailableBudgetDetailsRKBTema: PropTypes.func,
  handleTransferFund: PropTypes.func,
  handleAddFundRequest: PropTypes.func,
  handleGetFundRequestById: PropTypes.func,
  handleSubmitApproval: PropTypes.func,
  handleReject: PropTypes.func,
  handleDeleteFundRequestDetail: PropTypes.func,
  handleGetMonthOptions: PropTypes.func,
  handleMonthlyActivityPlantTema: PropTypes.func,
  history: PropTypes.object.isRequired,
  onSearchUnit: PropTypes.func,
  location: PropTypes.object.isRequired,
  month: PropTypes.object,
  tema: PropTypes.object,
  user: PropTypes.object,
  type: PropTypes.string,
};

ManageMonthlyActivityPlan.defaultProps = {
  handleGetAvailableBudgetDetailsRKBTema: () => {},
  handleTransferFund: () => {},
  handleAddFundRequest: () => {},
  handleGetFundRequestById: () => {},
  handleSubmitApproval: () => {},
  handleReject: () => {},
  handleDeleteFundRequestDetail: () => {},
  handleGetMonthOptions: () => {},
  onSearchUnit: () => {},
  handleMonthlyActivityPlantTema: () => {},
  month: {},
  tema: {},
  user: {},
  type: '',
};

import { connect } from 'react-redux';
import ManageMonthlyActivityPlandRequest from '../../../../views/Finance/Budgeting/MonthlyActivityPlan/ManageMonthlyActivityPlan.finance.view';
import{
  getMonthlyActivityPlantCoa,getAvailableBudgetDetailsRKB,addFundRequest,
} from '../../../../states/thunks/finance.thunk';
import {
   getCoaOptions,
   getMonthOptions,
   getTemaFilterOptions
} from '../../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  // console.log(state.options)
  return {
    options: state.options || {},
    month: state.options.month ,
    coa: state.options.coa,
    tema: state.options.tema,

    user: state.user || {},
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetTemaOptions: payload => dispatch(getTemaFilterOptions(payload)),
    handleGetCoaOptions: payload => dispatch(getCoaOptions(payload)),
    handleGetMonthOptions: payload => dispatch(getMonthOptions(payload)),
    handleMonthlyActivityPlantCoa: payload => dispatch(getMonthlyActivityPlantCoa(payload)),
    handleGetAvailableBudgetDetailsRKB: payload => dispatch(getAvailableBudgetDetailsRKB(payload)),
    handleAddFundRequest: (payload, goBack) => dispatch(addFundRequest(payload, goBack)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageMonthlyActivityPlandRequest);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop, isEqual, map } from 'lodash';
import StudentReportFilter from '../../../components/Filter/StudentReport.filter.component';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { ABSENSI_HARIAN } from '../../../constants/student.constant';
import { ABSENSI_HARIAN_TABLE } from '../../../constants/student.constant';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted, normalizeAmount, formatData } from '../../../utils/transformer.util';
import { Button, Pagination } from '../../../components/base/index';
import language from '../../../languages';
import { SEARCH_FIELDS} from '../../../constants/Akademi/DataKelas/kelas.constant';
import { TABLE_EKSKUL_TABLE_FIELDS} from '../../../constants/Student/ekskul.constant';


const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};
export default class JenisEkstrakulikuler extends Component {
    constructor(props) {
        super(props);
        this._onClickAdd = this._onClickAdd.bind(this);
        this._onClickEdit = this._onClickEdit.bind(this);
        this._onClickAddGroup = this._onClickAddGroup.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
        this._renderButtons = this._renderButtons.bind(this);
        this.handleGetData = this.handleGetData.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
      
        this.state= {
          content: initialContent,
          list: [],
          workflow: {},
          listAmount: 5,
          form: {
            value: {},
            error: {},
          },
        };
      
      }
      componentDidMount() {
        const { content } = this.state;
        this.handleGetData();
      }

      componentDidUpdate(prevProps) {
        const { user } = this.props;
        const { workingUnit } = user;
        const { user: prevUser } = prevProps;
        const { workingUnit: prevWorkingUnit } = prevUser;
    
        if (!isEqual(workingUnit, prevWorkingUnit)) {
          this.handleGetData();
        }
      }

      async handleGetData(payload = {}) {
        const { handleListJenisEkskul, handleListAnnouncementStudent, user } = this.props;
        const { units_id, workingUnit, id, user_group, organizations_id } = user;
        let unit_id = units_id;
    
        if (unit_id === null && workingUnit && workingUnit.id) {
          unit_id = workingUnit.id;
        }
    
        // if(user_group.id === 2){
          const res = await handleListJenisEkskul({...payload, organizations_id});
    
        this.setState({
          list: res,
        });
       
        
      }

      async onClickDelete(id) {
        const { handleDeleteJenisEKskul } = this.props;
        const res = await handleDeleteJenisEKskul(id);
        if(res){
          this.handleGetData();
        }
      }


      _onClickAdd() {
        const { history } = this.props;
        history.push('/dashboard/kesiswaan/ekstrakulikuler/buat-jenis-ekskul', { isEdit: true });
      }
    
      _onClickEdit(val) {
        const { history } = this.props;
        history.push('/dashboard/kesiswaan/ekstrakulikuler/buat-jenis-ekskul', { data: val, isEdit: true });
      }
    
      _onClickAddGroup() {
        const { history } = this.props;
        history.push('/dashboard/kesiswaan/displiner/list-group-pelanggaran');
      }
      _onFormChange(event) {
        const {
          name,
          value,
          dataset,
          checked,
          type,
        } = event.target;
        const {
          inputType = 'text', inputArray = false, arrayPosition = 0,
          fieldName,
        } = dataset;
        this.setState((prevState) => {
          let newList = [];
          let newListError = [];
          let formattedValue = value;
    
          if (inputType === 'number') {
            formattedValue = normalizeAmount(value);
          }
          if (inputArray) {
            if (type === 'checkbox') {
              formattedValue = checked;
            }
            newList = prevState.form.value[fieldName];
            newListError = prevState.form.error[fieldName];
            newList[arrayPosition][name] = formattedValue;
            if (name === 'code_of_account') {
              newList[arrayPosition].isCredit = value.type;
            }
            if (!isEmpty(newListError[arrayPosition])) {
              newListError[arrayPosition][name] = '';
            }
          }
          return {
            form: {
              value: {
                ...prevState.form.value,
                ...(inputArray
                  ? { [fieldName]: newList }
                  : { [name]: formattedValue }),
              },
              error: {
                ...prevState.form.error,
                ...(inputArray
                  ? { [fieldName]: newListError }
                  : { [name]: '' }),
              },
            },
          };
        });
      }

      onChangePage(page) {
        const { listAmount, keywords } = this.state;
        const offset = listAmount * (page - 1);
        this.handleGetData({
          limit: listAmount,
          keywords,
          offset,
          page,
        });
      }

      _renderButtons(data) {
        const { user } = this.props;
        const {user_group} = user;
        const button = {};
        
        return (
          <td>
            <div className="table__actions">
            
              
              {/* {user_group.id === 2? */}
                 <Button
                 onClick={() => this._onClickEdit(data)}
                 title='Edit'
               />
               {/* :'' */}
              {/* } */}
                {/* {user_group.id === 2? */}
              
               <Button
                 onClick={() => this.onClickDelete(data)}
                 title='Delete'
               />
               {/* :'' */}
              {/* } */}
             
            </div>
          </td>
        );
      }
      
    
      render() {
        const {form, list} = this.state;
        const { handleGetKelas, handleDeleteKelas,coa } = this.props;
        return (
          <div className="student-list">
            <div className="budget__title">
              <h1>Jenis Ekstrakulikuler</h1>
              <hr></hr>
            </div>
            
            <div className="student-list__header">
              <div className="student-list__button">
                <Button
                  title="Buat Jenis Ekskul"
                  onClick={this._onClickAdd}
                />
              </div>
            </div>
    
            <div className="fund-request__content">
          <table className="table">
            <thead>
              <tr>
                {
                    map(TABLE_EKSKUL_TABLE_FIELDS.label, (field, idx) => (
                      <th key={`table_th_${idx}`}>{field}</th>
                   ))
                  }
              </tr>
            </thead>
            <tbody>
              {
                  map(list.result, (data, idx) => (
                    <tr key={`budget_row_${idx}`}>
                      {
                      map(TABLE_EKSKUL_TABLE_FIELDS.value, (field, fieldIdx) => {
                        if (fieldIdx === 0) {
                          return (
                            <td key={`table_index_${fieldIdx}_${idx}`}>{idx + 1}</td>
                          );
                        }
                        if (field.type === 'link') {
                          return (
                            <td key={`table_${fieldIdx}_${idx}`}><a href={"https://"+data.hyperlink} target="_blank" rel="noopener noreferrer">{data.hyperlink}</a></td>
                              // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                          );
                        }
                        return (
                          <td className={(field.type === 'number') ? 'nominal' : ''} key={`table_${fieldIdx}_${idx}`}>
                            {formatData(data, field)}
                          </td>
                        );
                      })
                      }
                      {this._renderButtons(data)}
                    </tr>
                  ))
                }
            </tbody>
          </table>
        </div>
        <div className="user-management__table-footer">
          <p className="user-management__page-info">
            {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${list.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${list.totalPage}`}
          </p>
          <Pagination
            totalPage={list.totalPage}
            currentPage={list.currentPage}
            onClick={this.onChangePage}
          />
        </div>
          </div>
        );
      }
}
JenisEkstrakulikuler.propTypes = {
    
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
JenisEkstrakulikuler.defaultProps = {
    
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};

export const DEFAULT_LANGUAGE = 'id';

export const HEADER_ICON = [{
  icon: 'icon-download',
}, {
  icon: 'icon-comments',
}, {
  icon: 'icon-envelope',
}, {
  icon: 'icon-bell',
}, {
  icon: 'icon-repeat',
}];

export const GENDER = [
  { label: 'Laki-Laki', value: 'P' },
  { label: 'Perempuan', value: 'L' },
];

export const ALPHABET = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];

export const SEMESTER = [{
  label: '1',
  value: 1,
}, {
  label: '2',
  value: 2,
}];

export const JOURNAL_TYPE = [
  { label: 'Standard', value: '1' },
  { label: 'Rekonsiliasi', value: '2' },
  { label: 'Standard & Rekonsiliasi', value: '3' },
];

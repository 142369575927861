export const DAFTAR_NILAI_FORM_FIELDS=[{
    type:'select',
    label: 'Guru',
    name:'tahunajaran',
    data:[
        {label: 'guru', value:'guru'},
        {label: '2020-2021', value:'tahunajaran2'},

    ]
}
    ,{
    type:'text',
    label:'Materi',
    name:'group',
}];


export const DAFTAR_PRAKTEK_FORM_FIELDS=[{
    type:'select',
    label: 'Guru',
    name:'tahunajaran',
    data:[
        {label: 'guru', value:'guru'},
        {label: '2020-2021', value:'tahunajaran2'},

    ]
}
    ,{
    type:'text',
    label:'Materi',
    name:'group',

}];


export const PENGISIAN_PRAKTEK_FORM_FIELDS=[{
    type:'text',
    label:'Materi Indikator',
    name:'group',
    full:true,

}];

export const PENGISIAN_SIKAP_FORM_FIELDS=[{
    type:'text',
    label:'Materi Indikator',
    name:'group',
    full:true,

}];

export const ESTIMASI_KENAIKAN_KLS_FORM=[{
    type:'select',
    label: 'Syarat Kenaikan Kelas',
    name:'tahunajaran',
    full:true,
    data:[
        {label: 'Kelas 8 ', value:'kelas'},

    ]
    
}];
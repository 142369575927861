import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { map, noop } from 'lodash';
import { commaFormatted } from '../../../utils/transformer.util';
import { Multiselect } from 'multiselect-react-dropdown';

export default function SelectMultiple(props) {
  const {
    label, value, onChange, data, placeholder, error, center, noMargin,
    theme,
    otherName,
    options,
    disable,
    displayValue,
    onRemove,
    onSelect,
    showCheckbox,
    param,
    values,
    fieldName,
    arrayPosition,
    inputArray,
    ...others
  } = props;

  // optionsAll(){
  //   const optionsAll = [{label: "All", value: "*"}];
  // }
 
  const optionsAll = [{value: '*', label: "Semua"}];
  const onCheck = (value) => { 
    console.log(value.length)
    for(let i=0; i<value.length;i++){
      if (value[i].value === '*') {
        onSelect(options, inputArray, fieldName, arrayPosition)
      } else {
        onSelect(value, inputArray, fieldName, arrayPosition)
      }
    }
    
  }

  return (
    <Multiselect    
     name={otherName}
     options={[...optionsAll, ...options]}
     disable	={disable}
     displayValue="label" // Property name to display in the dropdown options
     showCheckbox={true}
     //  selectAllText="Select All" 
     // ref={this.selectAllItems()}
     selectedValues={values}
     onRemove={onRemove}
     onSelect={onCheck} 
     />
  );
}
SelectMultiple.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  data: PropTypes.array,
  onChange: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  classNames: PropTypes.string,
  otherValue: PropTypes.string,
  otherName: PropTypes.string,
  onRemove: PropTypes.func,
  onSelect: PropTypes.func,
  selectedValues: PropTypes.array,
  displayValue: PropTypes.string,
  disable: PropTypes.bool,
  options: PropTypes.array,
  showCheckbox: PropTypes.bool,

};
SelectMultiple.defaultProps = {
  center: false,
  noMargin: false,
  isNumber: false,
  isOther: false,
  value: '',
  onChange: noop,
  data: [{ label: '', value: '' }],
  label: '',
  placeholder: '',
  error: '',
  theme: 'default',
  classNames: '',
  otherValue: '',
  otherName: '',
  onRemove: noop,
  onSelect: noop,
  selectedValues: '',
  displayValue: 'label',
  disable: '',
  options: '',
  showCheckbox: false,

};

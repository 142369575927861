import React from 'react';
import PropTypes from 'prop-types';
import { noop, map, isEqual, isEmpty } from 'lodash';
import { Button, Pagination, Textarea, Input, Select, SearchSelect } from '../../components/base';
import BaseTable from '../../components/BaseTable/BaseTable.component';
import ContentTable from '../../components/ContentTable/ContentTable.component';
import { formatData, normalizeAmount} from '../../utils/transformer.util';
import language from '../../languages';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
import { BUAT_CATATAN_KONSELING_LIST_TABLE_FIELDS } from '../../constants/kurikulum/kurikulum.constant';


const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class BuatCatatanKonseling extends BaseTable {
  constructor(props) {
    super(props);
    this._getCounselingPlaceOptions= this._getCounselingPlaceOptions.bind(this);
    this.onClickAdd = this.onClickAdd.bind(this);
    this.onClickAddFolder = this.onClickAddFolder.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this._renderButtons = this._renderButtons.bind(this);
    this.handleGetData = this.handleGetData.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._getStudentOptions = this._getStudentOptions.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._setForm = this._setForm.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this._getSubjectOptions= this._getSubjectOptions.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);

    const {user} = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;

    this.state = {
      content: initialContent,
      list: [],
      workflow: {},
      listAmount: 5,
      form: {
        value: {
          levels_id,
        },
        error: {},
      },
      param:{},
    };
  }

  componentDidMount() {
    const { location, handleGetclassrooms, handleGetLevels, getPrmclassroomsOptions  } = this.props;
    const { state = {} } = location;
    const {isEdit =  false, data} = location.state;
    const { id } = state.data || '';
    const paramTypes = ['levels', 'classrooms'];

    if (isEdit && id) {
      this.setState({id:id})
      this._setForm(data.id);
    }
    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
      this._getStudentOptions();
    });
    this._getCounselingPlaceOptions();
    
  
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    const { workingUnit } = user;
    const { user: prevUser } = prevProps;
    const { workingUnit: prevWorkingUnit } = prevUser;

    // if (!isEqual(workingUnit, prevWorkingUnit)) {
      // this.handleGetData();
    // }
  }

  async _getSubjectOptions() {
    const { getSubjectOptions } = this.props;
    const res = await getSubjectOptions();
    // console.log(res)
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        subject: res,
      },
    }));
   
  }

  async _getParamOptionsAcademics(type, filters={}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;

    if (type === 'classrooms' || type === 'classrooms') {
      filters.organizations_id = organizations_id;
      filters.levels_id = levels_id;

    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getStudentOptions(filters={}) {
    const { getStudentOptions } = this.props;
    const res = await getStudentOptions(filters);
    // console.log(res)
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        student: res,
      },
    }));
    
  }

  async _getParamOptions(type, filters={}) {
    const {getParamOptions, user} = this.props;
    const { organizations_id } = user;

    if (type === 'classes' || type === 'classrooms') {
      filters.organizations_id = organizations_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptions(filters, type);
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _setForm(id) {
    const { handleGetCounselingRecord } = this.props;
    try {
      const payload = await handleGetCounselingRecord({ id });
      this.setState({
        form: {
          value: {
            id: payload.id,
            approach: payload.approach,
            classrooms_id: payload.classrooms_id,
            conclusion:payload.conclusion,
            counseling_place_id: payload.counseling_place_id,
            date: payload.date,
            descriptions: payload.descriptions,
            levels_id: payload.levels_id,
            materials: payload.materials,
            problems: payload.problems,
            problems_solving: payload.problems_solving,
            schedule_counseling: payload.schedule_counseling,
            source: payload.source,
            specific_record: payload.specific_record,
            students_id: payload.students_id,
            type: payload.type,
            
          },
          error: {
            // details: [],
          },
        },
      }, () => {this.handleGetData(id)});
    } catch (err) {
      // err action
    }
  }


  async _getCounselingPlaceOptions() {
    const { getCounselingPlaceOptions } = this.props;
    const res = await getCounselingPlaceOptions();
    // console.log(res)
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        counseling_place: res,
      },
    }));
   
  }

  async onSubmit() {
    const { history, handleSaveCounselingRecord, } = this.props;
    const { form } = this.state;
    const { value } = form;        
    let canSaveA = false;
    const dataForm = form.value;
  
      await handleSaveCounselingRecord(value);
      history.push('/dashboard/kesiswaan/bimbingan-konseling/konseling-siswa');
    // }
  }

  


  async handleGetData(payload = {}) {
    const { handleHistoryCounselingRecord, user, handleGetCounselingRecord } = this.props;

    const {form } = this.state;
    const {students_id} = form.value;
    const { units_id, workingUnit, id, user_group, organizations_id } = user;
    let unit_id = units_id;

    if (unit_id === null && workingUnit && workingUnit.id) {
      unit_id = workingUnit.id;
    }
      const res = await handleHistoryCounselingRecord({...payload, students_id: students_id, organizations_id});
      
      this.setState({
        list: res,
      });
    }


    async onEdit() {
      const { history, handleEditCounselingRecord } = this.props;
      const { form } = this.state;
      const { value } = form;
      const res = await handleEditCounselingRecord(value);        
      history.push('/dashboard/kesiswaan/bimbingan-konseling/konseling-siswa');
    }

  async onClickDownload(id) {
    const { handleDownloadAnnouncement } = this.props;
    const res = await handleDownloadAnnouncement(id.id, id.upload)
  }

 
  onClickAdd() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/buat-catatan-konseling', { isEdit: true });
  }

  onClickAddFolder() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/tambah-folder', { isEdit: true });
  }

  onClickEdit(val) {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/tambah-pengumuman', { data: val, isEdit: true });
  }
  async onClickDelete(id) {
    const { handleDeleteAnnouncement } = this.props;
    const res = await handleDeleteAnnouncement(id);
    if(res){
      this.handleGetData();
    }
  }

  onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this.handleGetData({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }
  _onChangeFilter(e) {
    const { user } = this.props;
    const { organizations_id } = user;
    const { target } = e;
    const { value, name } = target;
    this.setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        [name]: value,
      },
    }), () =>{
      if (name === 'levels_id') {
        this._getParamOptions( 'classrooms',  { levels_id: value, organizations_id} );
      } this._onSearchContent()});
  
  }

  _renderButtons(data) {
    const { user } = this.props;
    const {user_group} = user;
    const button = {};
    
    return (
      <td>
        <div className="table__actions">
        
          {data.upload ? <Button
            onClick={() => this.onClickDownload(data)}
            title='Download'
          /> : <div></div>}
          {/* {user_group.id === 2? */}
             <Button
             onClick={() => this.onClickEdit(data)}
             title='Edit'
           />
           {/* :'' */}
          {/* } */}
            {/* {user_group.id === 2? */}
          
           <Button
             onClick={() => this.onClickDelete(data)}
             title='Delete'
           />
           {/* :'' */}
          {/* } */}
         
        </div>
      </td>
    );
  }

  _onFormChange(event) {
    const { user } = this.props;
    const { organizations_id } = user;
    const {
      name,
      value,
      dataset,
      checked,
      type,
    } = event.target;
    const {
      inputType = 'text', inputArray = false, arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        if (type === 'checkbox') {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
        if (name === 'code_of_account') {
          newList[arrayPosition].isCredit = value.type;
        }
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = '';
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray
              ? { [fieldName]: newListError }
              : { [name]: '' }),
          },
        },
      };
    }, () => {
      if (name === 'levels_id') {
        this._getParamOptionsAcademics( 'classrooms', { levels_id: value, organizations_id} );
      }
      if (name === 'classrooms_id') {
        this._getStudentOptions({ filters: { classrooms_id: [value], organizations_id}} );
      }
    });
  }

  render() {
    const { form, list, param, filters = {} } = this.state;
    const { handleGetFundRequest, handleDeleteFundRequest, user, onFormChange } = this.props;
    const { user_group } = user;
    const { permissions } = user_group;
    const {
      classrooms_id = '',
      date='',
      period='',
      levels_id='',
    } = filters;
    const dataset = {
      toggle: "modal"
    };
    return (
      <div className="kode-matpel__custom-form">
      <div className="kode-matpel__custom-form-row">
      <div className="kode-matpel__custom-form-row__field">
     
      <Input
              type='date'
              name="date"
              label="Tanggal"
              onChange={this._onFormChange}
              value={form.value.date}
              error={form.error.date || ''}
            />
      </div>
       
      
      <div className="kode-matpel__custom-form-column__field">
    
        
             <Select
                placeholder="Pilih Unit"
                label = "Unit"
                name="levels_id"
                value={form.value.levels_id}
                error={form.error.levels_id || ''}
                data={param.levels}
                disabled
                onChange={this._onFormChange}
              />
     
       
      </div>
      </div>
      <div className="kode-matpel__custom-form-row">
      <div className="kode-matpel__custom-form-row__field">
            <Select
                placeholder="Pilih Kelas"
                label = "Kelas"
                name="classrooms_id"
                value={form.value.classrooms_id}
                error={form.error.classrooms_id || ''}
                data={param.classrooms}
                onChange={this._onFormChange}
              />
       
      </div>
      
      <div className="kode-matpel__custom-form-column__field">
         
      {/* <SearchSelect
              noMargin
              async={false}
              name="students_id"
              list={param.student}
              inputArray
              onClick={this._onFormChange}
              placeholder="Pilih Nama Siswa"
              value={form.value.students_id}
              // labelName="label"
              // valueName="value"
              rightIcon="icon-search"
              label= "Nama Siswa"
              /> */}
          <Select
              name="students_id"
              label="Nama Siswa"
              onChange={this._onFormChange}
              data={param.student}
              placeholder={'Pilihan'}
              value={form.value.students_id}
              error={form.error.students_id || ''}
            />
     
       
      </div>
      </div>
      <div className="kode-matpel__custom-form-row">
      <div className="kode-matpel__custom-form-row__field">
      <Input
          placeholder="Sumber"
          label = "Sumber"
          name="source"
          value={form.value.source}
          error={form.error.source || ''}
          onChange={this._onFormChange}
        />
      </div>

      <div className="kode-matpel__custom-form-column__field">
          
      <Input
          placeholder="Jenis"
          label = "Jenis"
          name="type"
          value={form.value.type}
          error={form.error.type || ''}
          onChange={this._onFormChange}
        />
       
        </div> 
        </div>

        <div className="kode-matpel__custom-form-row">
      <div className="kode-matpel__custom-form-row__field">
      <Input
          placeholder="Pendekatan"
          label = "Pendekatan"
          name="approach"
          value={form.value.approach}
          error={form.error.approach || ''}
          onChange={this._onFormChange}
        />
      </div>

      <div className="kode-matpel__custom-form-column__field">
          
      <Select
          placeholder="Pilihan"
          label = "Tempat Konseling"
          name="counseling_place_id"
          value={form.value.counseling_place_id}
          error={form.error.counseling_place_id || ''}
          data={param.counseling_place}
          onChange={this._onFormChange}
        />
       
         
        </div>
      </div>
      <div className="kode-matpel__custom-form-row">
      <div className="kode-matpel__custom-form-row__field">
      <Textarea
          placeholder=""
          label = "Materi Konseling"
          style={{backgroundColor:'yellow'}}
          name="materials"
          value={form.value.materials}
          error={form.error.materials || ''}
          onChange={this._onFormChange}
        />
      </div>

      <div className="kode-matpel__custom-form-column__field">
          
      <Textarea
          placeholder=""
          label = "Catatan Khusus"
          name="specific_record"
          value={form.value.specific_record}
          error={form.error.specific_record || ''}
          onChange={this._onFormChange}
        />
       
         
        </div>
      </div>
      <div className="kode-matpel__custom-form-row">
      <div className="kode-matpel__custom-form-row__field">
      <Textarea
          placeholder=""
          label = "Masalah yang dihadapi"
          name="problems"
          value={form.value.problems}
          error={form.error.problems || ''}
          onChange={this._onFormChange}
        />
      </div>

      <div className="kode-matpel__custom-form-column__field">
          
      <Textarea
          placeholder=""
          label = "Penyelesaian Masalah"
          name="problems_solving"
          value={form.value.problems_solving}
          error={form.error.problems_solving || ''}
          onChange={this._onFormChange}
        />
       
         
        </div>
      </div>

      <div className="kode-matpel__custom-form-row">
      <div className="kode-matpel__custom-form-row__field">
      <Textarea
          placeholder=""
          label = "Tindakan / Sanksi / Keterangan"
          name="descriptions"
          value={form.value.descriptions}
          error={form.error.descriptions || ''}
          onChange={this._onFormChange}
        />
      </div>

      <div className="kode-matpel__custom-form-column__field">
          
      
      <Input
              type='date'
              name="schedule_counseling"
              label="Jadwal Konseling Berikutnya"
              onChange={this._onFormChange}
              value={form.value.schedule_counseling}
              error={form.error.schedule_counseling || ''}
              onChange={this._onFormChange}
            />
       
         
        </div>
      </div>

         
      <div className="absensi-rekap__custom-form-row">
              <div className="absensi-rekap__custom-form-row__field">
            
            
        <Textarea
          placeholder=""
          label = "Kesimpulan"
          name="conclusion"
          value={form.value.conclusion}
          error={form.error.conclusion || ''}
          onChange={this._onFormChange}
        />
 
              </div>  
              
              <div className="absensi-rekap__custom-form-column__field">
              
              
              </div>
              </div>
     


      <div className="student-list__button">
   
      {this.state.id?
              <Button
                type="button"
                title="Update"
                onClick={this.onEdit}
              />:
              <Button
                type="button"
                title="Simpan"
                onClick={this.onSubmit}
              />}
    </div>
        <div className="fund-request__content">
          <table className="table">
            <thead>
              <tr>
                {
                    map(BUAT_CATATAN_KONSELING_LIST_TABLE_FIELDS.label, (field, idx) => (
                      <th key={`table_th_${idx}`}>{field}</th>
                   ))
                  }
              </tr>
            </thead>
            <tbody>
              {
                  map(list.result, (data, idx) => (
                    <tr key={`budget_row_${idx}`}>
                      {
                      map(BUAT_CATATAN_KONSELING_LIST_TABLE_FIELDS.value, (field, fieldIdx) => {
                        if (fieldIdx === 0) {
                          return (
                            <td key={`table_index_${fieldIdx}_${idx}`}>{idx + 1}</td>
                          );
                        }
                        if (field.type === 'link') {
                          return (
                            <td key={`table_${fieldIdx}_${idx}`}><a href={"https://"+data.hyperlink} target="_blank" rel="noopener noreferrer">{data.hyperlink}</a></td>
                              // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                          );
                        }
                        return (
                          <td className={(field.type === 'number') ? 'nominal' : ''} key={`table_${fieldIdx}_${idx}`}>
                            {formatData(data, field)}
                          </td>
                        );
                      })
                      }
                      
                    </tr>
                  ))
                }
            </tbody>
          </table>
        </div>
        {/* <div className="user-management__table-footer">
          <p className="user-management__page-info">
            {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${list.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${list.totalPage}`}
          </p>
          <Pagination
            totalPage={list.totalPage}
            currentPage={list.currentPage}
            onClick={this.onChangePage}
          />
        </div> */}
      </div>
    );
  }
}
BuatCatatanKonseling.propTypes = {
  handleListAnnouncement: PropTypes.func,
  handleListAnnouncementStudent: PropTypes.func,
  handleDelete: PropTypes.func,
  getCounselingPlaceOptions: PropTypes.func,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
};
BuatCatatanKonseling.defaultProps = {
  handleListAnnouncement: noop,
  handleListAnnouncementStudent:PropTypes.func,
  getCounselingPlaceOptions: noop,
  handleDelete: noop,
  user: null,
};
import { connect } from 'react-redux';
import JenisSPP from '../../../../views/Finance/Spp/JenisSPP/JenisSPP.JenisSPP.view';
import { getAdjustmentJournal } from '../../../../states/thunks/finance.thunk';
import {uploadJenisSPP, deleteJenisSPP, editJenisSPP, listJenisSPP } from '../../../../states/thunks/academics.thunk';
function mapStateToProps(state) {
  return {
    result: state.result,
    user: state.user || {},
  };
}
function mapDispatchToProps(dispatch) {
  return {
    handleGetAdjustmentJournal: payload => dispatch(getAdjustmentJournal(payload)),
    handleListJenisSPP: (payload, goback) => dispatch(listJenisSPP(payload, goback)),
    handleEditJenisSPP: (payload, goback) => dispatch(editJenisSPP(payload, goback)), 
    handleDeleteJenisSPP: (payload, goback) => dispatch(deleteJenisSPP(payload, goback)),

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(JenisSPP);

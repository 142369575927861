import { connect } from 'react-redux';
import BudgetType3 from '../../../../views/Finance/Budgeting/Budget/BudgetType3.finance.view';


function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, null)(BudgetType3);

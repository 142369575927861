import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, map, noop, isEqual } from 'lodash';
import ListForm from '../../../components/ListForm/ListForm.component';
import { commaFormatted, normalizeAmount, manageGetClasses } from '../../../utils/transformer.util';
import {  KENAIKANKELAS_DATAKELAS_FORM_FIELDS  } from '../../../constants/Akademi/DataKelas/kelas.constant';
import { Button } from '../../../components/base';
import {Input, Select} from '../../../components/base';
import { formatData } from '../../../utils/transformer.util';
import { LIST_KENAIKAN_KELAS_LIST_TABLE_FIELDS } from '../../../constants/Akademi/Akademi.constant';
import ReactToPrint from "react-to-print";

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class KenaikanKelas extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._getClassesOptions= this._getClassesOptions.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._onSetForm = this._onSetForm.bind(this);
    this._getParamOptions = this._getParamOptions.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._getStudentOptions = this._getStudentOptions.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this._setClassrooms = this._setClassrooms.bind(this);
    this._getPeriodsOptions= this._getPeriodsOptions.bind(this);
    const { user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    this.state = {
      page: 1,
      selected: {},
      content: initialContent,
      unchecked: {},
      filters:{},
      list:[],
      form: {
        value: {
          levels_id,
          organizations_id,
          units_id,
          
          kenaikan_kelas: [
            {
              students_id: '',
              graduation: 0,
              period: '',
              classrooms_id: '',
              date: '',
              status: '',
              description: '',
            }
          ],
        },
        error: {
          classes: '',
          levels: '',
          kenaikan_kelas:''
        },
      },
      param:{},
      
    };
  }

  componentDidMount() {
    const { location, getLevels, getClasses, user } = this.props;
    const { school_unit } = user;
    const { levels_id } = school_unit;
    const { state = {} } = location;
    const {isEdit =  false, data} = state;
    const { id } = state.data || '';
    const { content } = this.state;
    const paramTypes = ['classes','levels', 'classrooms'];

    console.log(location)
    paramTypes.forEach((type) => {
      // this._getParamOptions(type);
      this._getParamOptionsAcademics(type);
    });
    if (isEdit && id) {
      this.setState({id:id})
      this._setForm(data.id);
    } else
    // this.handleGetData();
    // this.isMount = true;
    if (isEmpty(content.list)) {
      this._onSearchContent({ page: 1 });
    }

    this._getPeriodsOptions();       
    this._getClassesOptions({ filters: { levels_id: [levels_id] }});
  
  }

    componentDidUpdate(prevProps) {
      const { location } = this.props;
      const { state = {} } = location;
      const {isEdit =  false, data} = state;
      const { id } = state.data || '';
    //  console.log(state)
      if ((!isEdit || !id) && !isEqual(prevProps, this.props)) {
        this._onSearchContent({ page: 1 });
      }
    }

    async _getPeriodsOptions(filters={}) {
      const { getPeriodsOptions ,user} = this.props;
      const { organizations_id, school_unit } = user;
      const { units_id, levels_id } = school_unit
      const res = await getPeriodsOptions(filters);
      filters.organizations_id = organizations_id;

      this.setState(prevState => ({
        ...prevState,
        param: {
          ...prevState.param,
          periods: res,
        },
      }));
    }

  _onSetForm() {
    const { location } = this.props;
    const { name } = location.state;
    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          name,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }

  // async _setForm() {
  //   const { handleGetClasses } = this.props;
  //   try {
  //     const payload = await handleGetClasses({  });
  //     // console.log("paload bor manageStaffFormState")
  //     console.log(payload + "babana")
  //     this.setState(manageGetClasses(payload));
  //   } catch (err) {
  //     // err action
  //   }
  // }


  // on formchange untuk form

 

  async _getParamOptions(type, filters={}) {
    const { getParamOptions } = this.props;
    const res = await getParamOptions(filters, type);
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getParamOptions(type, filters={}) {
    const { getParamOptions } = this.props;
    const res = await getParamOptions(filters, type);
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  _onFormChange(event) {
    const {
      name,
      value,
      dataset,
      checked,
      type,
    } = event.target;
    const {
      inputType = 'text', inputArray = false, arrayPosition = 0,
      fieldName,
    } = dataset;
    const { user } = this.props;
    const { organizations_id } = user;

    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        if (type === 'checkbox') {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
        if (name === 'code_of_account') {
          newList[arrayPosition].isCredit = value.type;
        }
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = '';
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray
              ? { [fieldName]: newListError }
              : { [name]: '' }),
          },
        },
      };
    }, () => {
      if (name === 'levels_id') {
        this._getParamOptionsAcademics( 'classrooms', {levels_id: value, organizations_id });
      }
      // if (name === 'classes_id') {
      //   this._getParamOptionsAcademics( 'classrooms', {classes_id: value, organizations_id });
      // }
      if (name === 'classrooms_id' && !inputArray ) {
        this._setClassrooms(value)
      }
      if (name === 'levels_id') {
        this._getClassesOptions({ filters: { levels_id: [value] }} );
      }
      if (name === 'classes_id') {
        const param = this.state.param.classes_prm;
        // console.log(param)
        this.setState(prevState => ({
              ...prevState,
          form: {
            ...prevState.form,
            value: {
              ...prevState.form.value,
              graduate: param.find(i => i.value == value).attributes.graduate, 
            }
          },
        }));
      }
    });
  }

  _setClassrooms(classroomsId) {
    const { form } = this.state;
    const { value } = form;
    const { kenaikan_kelas } = value;
    const newList = JSON.parse(JSON.stringify(kenaikan_kelas));

    newList.forEach(item => {
      item.classrooms_id = classroomsId;
    });

    this.setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          kenaikan_kelas: newList,
        }
      }
    }));
  }

  async _getClassesOptions(filters={}) {
    const { getClassesOptions } = this.props;
    const { form } = this.state;
    const { classes_id } = form.value;
    const res = await getClassesOptions(filters);
    const selectedClasses = res.find(item => item.value == classes_id); 

    this.setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          graduate: selectedClasses ? selectedClasses.attributes.graduate : '' 
        }
      },
      param: {
        ...prevState.param,
        classes_prm: res,
      },
    }));
   
  }


  // on formchange untuk tabel
  onFormChange(event) {
    const {
      name,
      value,
      dataset,
    } = event.target;
    console.log(name)
    const { inputArray = false, arrayPosition = 0, inputType = 'text' } = dataset;
    this.setState((prevState) => {
      let formattedValue = value;
      let newPlafon = [];
      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }

      if (inputArray) {
        newPlafon = prevState.form.value.plafon;
        newPlafon[arrayPosition][name] = value;
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray ? 
              { plafon: newPlafon } : 
              { [name]: formattedValue }
            )
          },
          error: {
            ...prevState.form.error,
            form:'',
            [name]: '',
          },
        },
      };
    });
  }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    this.setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        [name]: value,
      },
    }), () => this._onSearchContent());
  }
  

  async _onSearchContent(params = {}) {
    const { filters, form } = this.state;
    const { value } = form;
      try {
        const { handleListNextClassStudent, user } = this.props;
        const { organizations_id, school_unit } = user;
        const { units_id } = school_unit;
        const result = await handleListNextClassStudent({ ...params, organizations_id, units_id, filters, classrooms_id: value.classrooms_id });
        const { classes = [], data = [] } = result;

        if (result) {
          result.forEach(item => {
            if (!item.graduation) {
              item.graduation = '';
            }
          });
        } 
          this.setState(prevState => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                kenaikan_kelas: result,
              }
            }
          }));
          
        // }
      } catch (err) {
        // if (this.isMount) {
          this.setState(prevState => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                kenaikan_kelas: [],
              }
            }
          }));
          
      }
      // }
  }

  async _getParamOptionsAcademics(type, filters={}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;
    if (type === 'classes' || type === 'classrooms') {
      filters.organizations_id = organizations_id;
      filters.levels_id = levels_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }
  
  async _getStudentOptions(filters={}) {
    const { getStudentOptions } = this.props;
    const res = await getStudentOptions(filters);
    // console.log(res)
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        student: res,
      },
    }));
    
  }


  async onSubmit() {
    const { history, handleSaveNextClass } = this.props;
    const { form } = this.state;
    const { value } = form;        
    let canSaveA = false;
    const dataForm = form.value;
      await handleSaveNextClass(value);
      history.push('/dashboard/kesiswaan/laporan/daftar-siswa-kelas');
    // }
  }
 

  render() {
    const { list = {}, param, filters } = this.state;
    const { form} = this.state;
    const { classrooms = [ ] } = param;
    const { value } = form;
    const {
      classrooms_id2 = '',
    } = filters;
    const {kenaikan_kelas} = form.value;
    const {classes = {}, levels = {}} = this.props;
    const academic_year = [
      { label: '2019', value: '2019'},
      { label: '2020', value: '2020'},
      { label: '2021', value: '2021'},
    ]
    const tidak_naik = [{value: filters.classrooms_id2, label : "Tidak Naik"}]
    const lulus = [{value: 'NULL', label : "Lulus"}]
    
    const componentRef = React.createRef();
    return (
      
       <div className="isi-bidang__custom-form">
       <div className="isi-bidang__custom-form-column">
      <div className="isi-bidang__custom-form-row__field">
      <Select
            name="periods_id"
            label="Tahun Ajaran"
            placeholder={'Pilihan'}
            onChange={this._onFormChange}
            data={ param.periods}
            value={form.value.periods_id}
            error={form.error.periods_id || ''}
          />
        <div className="isi-bidang__custom-form-row__field"></div>
            <Select
                    type="text"
                    name="levels_id"
                    label="Unit"
                    placeholder="Pilih Unit"
                    disabled
                    data={param.levels}
                    value={form.value.levels_id}
                    onChange={this._onFormChange}
                    error={form.error.levels_id || ''}
                  />
        
    </div>

    <div className="isi-bidang__custom-form-column">
      <div className="isi-bidang__custom-form-row__field">

          <Select
              placeholder="Pilih Kelas"
              label = "Kelas"
              name="classrooms_id2"
              value={classrooms_id2}
              data={param.classrooms}
              onChange={this._onChangeFilter}
            />
   
       
        <div className="isi-bidang__custom-form-row__field"></div>
       
        <Select
                placeholder="Pilih Tingkat Kelas"
                label = " Naik ke Tingkat Kelas"
                name="classes_id"
                value={form.value.classes_id}
                data={param.classes_prm}
                onChange={this._onFormChange}
              />
       
    </div>

    <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
     
      <Select
          placeholder="Pilihan"
          name="classrooms_id"
          label="Pilih untuk semua murid"
          value={form.value.classrooms_id}
          data={form.value.graduate === 1?[...lulus, ...classrooms]: param.classrooms}
          onChange={this._onFormChange}
        />
    </div>

        <div className="isi-bidang__custom-form-row__field"></div>
      
    </div>
        
        <div className="kode-matpel__custom-form-column__field">
          </div>
          </div>
    <br></br>
    <p>List Kenaikan Kelas</p>
  </div>
        {/* <ReactToPrint
            trigger={() => (
              <div className="form-group-member">
                <Button title="Cetak" />
              </div>
            )}
            content={() => componentRef.current}
          /> */}
        <div className="fund-request__content">
        <table className="table" ref={componentRef}>
            <thead>
              <tr>
                {
                    map(LIST_KENAIKAN_KELAS_LIST_TABLE_FIELDS.label, (field, idx) => (
                      <th key={`table_th_${idx}`}>{field}</th>
                   ))
                  }
              </tr>
            </thead>
            <tbody>
          {
              map(kenaikan_kelas, (list, idx) => (
                <tr key={`budget_row_${idx}`}>
                  {
                  map(LIST_KENAIKAN_KELAS_LIST_TABLE_FIELDS.value, (field, fieldIdx) => {
                    if (fieldIdx === 0) {
                      return (
                        <td key={`table_index_${fieldIdx}_${idx}`}>{(idx + 1)}</td>
                      );
                    }
                    if (field.attribute === 'classrooms_id') {
                      return (
                        <td key={`table_${fieldIdx}_${idx}`}>
                            <Select
                                placeholder="Pilihan"
                                data-input-array={true}
                                data-array-position={idx}
                                data-field-name='kenaikan_kelas'        
                                name="classrooms_id"
                                value={form.value.kenaikan_kelas[idx].classrooms_id}
                                data={form.value.graduate === 1?[...lulus, ...tidak_naik, ...classrooms]:[...tidak_naik, ...classrooms]}
                                onChange={this._onFormChange}
                            />
                       {/* <Select
                          type="text"
                          name="classrooms_id2"
                          // data-input-array={true}
                          // data-array-position={idx}
                          value={form.value.classrooms_id2}
                          data={param.classrooms2}
                          onChange={this._onFormChange}
                       />   */}
                       </td>
                          // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                      );
                    }
                    if (field.attribute === 'description') {
                      return (
                        <td key={`table_${fieldIdx}_${idx}`}><Input
                        type="text"
                        name="description"
                        data-input-array={true}
                        data-array-position={idx}
                        data-field-name='kenaikan_kelas'
                        onChange={this._onFormChange}
                        value={list.description}
                      />  </td>
                          // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                      );
                    }

                    return (
                      <td className={(field.type === 'number') ? 'nominal' : ''} key={`table_${fieldIdx}_${idx}`}>
                        {formatData(list, field)}
                      </td>
                    );
                  })
                  }
                </tr>
              ))
            }
        </tbody>
          </table>
         <div className="add-budget">
            <Button
              type="submit"
              title="SIMPAN"
              onClick={this.onSubmit}
            />
          </div>
          </div>
      </div>
    );
  }
}
KenaikanKelas.propTypes = {
  handleGetClasses: PropTypes.func,
  handleGetLevels: PropTypes.func,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
};
KenaikanKelas.defaultProps = {
  handleGetClasses: noop,
  handleGetLevels: noop,
  user: null,
};

export const PENGUMUMAN_LIST_TABLE_FIELDS = {
  label: [
    'No',
    'Judul',
    'Unit',
    'Nama Folder',
    'Hyperlink',
    '',
  ],
  value: [
    {},
    { type: 'string', attribute: 'name' },
    { type: 'string', attribute: 'levels' },
    { type: 'string', attribute: 'folder_name' },
    { type: 'link', attribute: 'hyperlink'},
  ],
};
 export const JADWAL_KELAS_LIST_TABLE_FIELDS = {
  label: [
    'No',
    'Judul',
    'Unit',
    'Tanggal Mulai',
    'Tanggal Selesai',
    '',
  ],
  value: [
    {},
    { type: 'string', attribute: 'name' },
    { type: 'string', attribute: 'levels' },
    { type: 'string', attribute: 'publish_date' },
    { type: 'string', attribute: 'tgl_selesai' },
  ],
};

  export const JADWAL_KARYAWAN_LIST_TABLE_FIELDS = {
    label: [
      'No',
      'Judul',
      'Unit',
      'Golongan',
      'Jabatan',
      '',
    ],
    value: [
      {},
      { type: 'string', attribute: 'name' },
      { type: 'string', attribute: 'units' },
      { type: 'string', attribute: 'golongan' },
      { type: 'string', attribute: 'jabatan' },
    ],
  };
  export const FOLDER_LIST_TABLE_FIELDS = {
    label: [
      'No',
      'Code',
      'Name',
      'Description',
      'Active',
      '',
    ],
    value: [
      {},
      { type: 'string', attribute: 'folder_code' },
      { type: 'string', attribute: 'folder_name' },
      { type: 'string', attribute: 'folder_description' },
      { type: 'string', attribute: 'folder_active'},
    ],
  };

  export const LIST_SOAL_FROM_TABEL = {
    label: [
      'No',
      'Tanggal',
      'Judul',
      'Mata Pelajaran',
      'Unit',
      'Status',
      'Action',
    ],
    value: [
      {},
      { type: 'string', attribute: 'kode_jenis' },
      { type: 'string', attribute: 'nama_jenis' },
    ],
  };


  export const ABSEN_REKAP_LIST_TABLE_FIELDS = {
    label: [
      'No',
      'Nis',
      'Nama Siswa',
      'Status',
      'Total',
      'Percent',
      '',
    ],
    
    value: [
      {},
      { type: 'string', attribute: 'nis' },
      { type: 'string', attribute: 'name' },
      { type: 'string', attribute: 'status' },
      { type: 'string', attribute: 'total'},
      { type: 'string', attribute: 'percent'},

    ],
  };
  export const CETAK_ABSEN_REKAP_LIST_TABLE_FIELDS = {
    label: [
      'No',
      'Nis',
      'Nama Siswa',
      'Status',
      'Keterangan',
      'Jumlah Hadir',
      '%',
      '',
    ],
    
    value: [
      {},
      { type: 'string', attribute: 'nis' },
      { type: 'string', attribute: 'name' },
      { type: 'string', attribute: 'stataus' },
      { type: 'link', attribute: 'keterangan'},
      { type: 'string', attribute: 'jml_hadir' },
      { type: 'link', attribute: 'percent'},
    ],
  };




  export const ATTENDANCE_LIST_TABLE_FIELDS = {
    label: [
      'No',
      'Nis',
      'Nama',
      'Hadir',
      'Izin',
      'Sakit',
      'Alpa',
      'Terlambat',
      'Keterangan',
    ],
    value: [
      {},
      { type: 'string', attribute: 'nis' },
      { type: 'string', attribute: 'name' },
      { type: 'string', attribute: 'status', value: 'HADIR' },
      { type: 'string', attribute: 'status', value: 'IZIN' },
      { type: 'string', attribute: 'status', value: 'SAKIT' },
      { type: 'string', attribute: 'status', value: 'ALPA' },
      { type: 'string', attribute: 'status', value: 'TERLAMBAT' },
      { type: 'string', attribute: 'description'},
    ],
  };
  
  export const TEACHER_LIST_TABLE_FIELDS = {
    label: [
      'No',
      'NIK/NIP',
      'Nama Guru',
      'Pelajaran',
      'Kelas',
      'Ekstrakulikuler',
      ''
    ],
    value: [
      {},
      { type: 'string', attribute: 'nip' },
      { type: 'string', attribute: 'name' },
      { type: 'string', attribute: 'matpel' },
      { type: 'string', attribute: 'classes' },
      { type: 'string', attribute: 'ekskul' },
    ],
  };

  export const PEMBAGIAN_KELAS_TABEL_FIELDS = {
    label: [
      'No',
      'Pilihan',
      'NIS',
      'Nama Siswa',
      'L/P',
    ],
    value: [
      {},
      { type: 'string', attribute: 'checkbox', value: 'true'},
      { type: 'string', attribute: 'nis' },
      { type: 'string', attribute: 'name' },
      { type: 'string', attribute: 'genders'},

    ],
  };
  export const UPLOAD_ABSENSI_HARIAN_FORM_FIELDS = [{
    type: 'file',
    name: 'file',
    label: 'File Name',
    placeholder: 'Pilih file',
    inputType: 'file',
    full: true,
  }];
  export const KODE_MATPEL_TABLE_FIELDS = {
    label: [
      'No',
      'Kode Mata Pelajaran',
      'Nama Mata Pelajaran',
      'Nama Singkat',
      'Unit',
      'Tingkat Kelas',
      'Jurusan',
      'Option',
    ],
    value: [
      {},
      { type: 'string', attribute: 'code_subject' },
      { type: 'string', attribute: 'name' },
      { type: 'string', attribute: 'nick_name' },
      { type: 'string', attribute: 'levels'},
      { type: 'string', attribute: 'classes'},
      { type: 'string', attribute: 'major'},
    ],
  };
  export const PINDAH_KELAS_LIST_TABLE_FIELDS = {
    label: [
      'No',
      'Pilihan',
      'NIS',
      'Nama Siswa',
      'L/P',
    ],
    value: [
      {},
      { type: 'string', attribute: 'checkbox', value: 'true'},
      { type: 'string', attribute: 'nis' },
      { type: 'string', attribute: 'name' },
      { type: 'string', attribute: 'genders'},

    ],
  };

  export const KONSELING_SISWA_LIST_TABLE_FIELDS = {
    label: [
      'No',
      'Tgl',
      'NIS',
      'Nama',
      'Kelas',
      'Materi Konseling',
      'Tindakan/Sanksi',
      'Opsi',
    ],
    value: [
      {},
      { type: 'string', attribute: 'date' },
      { type: 'string', attribute: 'nis' },
      { type: 'string', attribute: 'students_name' },
      { type: 'string', attribute: 'classrooms_name' },
      { type: 'string', attribute: 'materials' },
      { type: 'string', attribute: 'descriptions' },
    ],
  };

  export const BUAT_CATATAN_KONSELING_LIST_TABLE_FIELDS = {
    label: [
      'No',
      'Tgl Konseling',
      'Catatan Khusus',
      'Inti Masalah',
      'Penyelesaian',
      'Keterangan',
      'Kesimpulan',

    ],
    value: [
      {},
      { type: 'string', attribute: 'date' },
      { type: 'string', attribute: 'specific_record' },
      { type: 'string', attribute: 'problems' },
      { type: 'string', attribute: 'problems_solving' },
      { type: 'string', attribute: 'descriptions' },
      { type: 'string', attribute: 'conclusion' },

    ],
  };

  export const TAMBAH_TEMPAT_KONSELING_LIST_TABLE_FIELDS = {
    label: [
      'No',
      'Code',
      'Name',
      'Description',
      'Active',
      'Opsi',
    ],
    value: [
      {},
      { type: 'string', attribute: 'code_place' },
      { type: 'string', attribute: 'place_name' },
      { type: 'string', attribute: 'description' },
      { type: 'string', attribute: 'folder_active' },
    ],
  };

  export const BOBOT_NILAI_TABLE_FIELDS = {
    label: [
      'No',
      'Bobot Nilai',
      'Detail Nilai',
      'Poin',
    ],
    value: [
      {},
      { type: 'string', attribute: 'nis' },
      { type: 'string', attribute: 'name' },
      { type: 'string', attribute: 'status', value: 'HADIR' },
      { type: 'string', attribute: 'status', value: 'IZIN' },
      { type: 'string', attribute: 'status', value: 'SAKIT' },
      { type: 'string', attribute: 'status', value: 'ALPHA' },
      { type: 'string', attribute: 'description'},
    ],
  };

  export const JENIS_SPP_FORM_TABLE = {
    label: [
      'No',
      'Kode Jenis SPP',
      'Nama Jenis SPP',
      'Action',
    ],
    value: [
      {},
      { type: 'string', attribute: 'code' },
      { type: 'string', attribute: 'name' },
    ],
  };

  export const NILAI_RAPORT_LIST_TABLE_FIELDS = {
    label: [
      'No',
      'NIS',
      'Nama Siswa',
      'Keterangan',
      'Opsi',
    ],
    
    value: [
      {},
      { type: 'string', attribute: 'nis' },
      { type: 'string', attribute: 'name' },
      { type: 'string', attribute: 'keterangan' },
    ],
  };

  export const TABLE_LIST_GROUP_MATPEL_TABLE_FIELDS={
    label: [
        'No',
        'Nama Group Pelajaran',
        'Opsi',
 
    ],
    value: [
        {},
        {type: 'string', attribute:'name'},
    ]
 
 
 };

 export const DAFTAR_NILAI_LIST_TABLE_FIELDS = {
  label: [
    'No',
    'Nis',
    'Nama',
    'Guru',
    'Tgl Ulangan',
    'Ulangan Harian/Test Lisan',
    'UH',
    'NH',
    'Ko-Kurikuler/Test Lisan',
    'KO',
    'Praktek/Penugasan',
    'PR',
    'MPR',
    'FPR',
    'UM',
    'UU',
    'RNH',
    'RPR',
    'NR',
    'Comment'




  ],
  value: [
    {},
    { type: 'string', attribute: 'nis' },
    { type: 'string', attribute: 'name' },
    { type: 'string', attribute: 'teacher'},
    { type: 'string', attribute: 'date_exam'},
    { type: 'string', attribute: 'uh', value: '1'},
    { type: 'string', attribute: 'nh'},
    { type: 'string', attribute: 'ko', value: '2'},
    { type: 'string', attribute: 'ko'},
    { type: 'string', attribute: 'praktek', value: '3'},
    { type: 'string', attribute: 'pr'},
    { type: 'string', attribute: 'mpr'},
    { type: 'string', attribute: 'fpr'},
    { type: 'string', attribute: 'um', value: '4'},
    { type: 'string', attribute: 'uu', value: '5'},
    { type: 'string', attribute: 'rnh'},
    { type: 'string', attribute: 'rpr'},
    { type: 'string', attribute: 'nr'},
    { type: 'string', attribute: 'comment'},
  ],
};

export const MATA_PELAJARAN_LIST_TABLE_FIELDS = {
  label: [
    'No',
    'Mata Pelajaran',
    'KKM',
    'Nilai',
    'Deskripsi',
  ],
  
  value: [
    {},
    { type: 'string', attribute: 'name' },
    { type: 'string', attribute: 'kkm' },
    { type: 'string', attribute: 'score' },
    { type: 'string', attribute: 'descriptions' },

  ],
};

export const PINDAH_UNITS_LIST_TABLE_FIELDS = {
  label: [
    'No',
    'Pilihan',
    'NIS',
    'Nama Siswa',
    'L/P',
  ],
  value: [
    {},
    { type: 'string', attribute: 'checkbox', value: 'true'},
    { type: 'string', attribute: 'nis' },
    { type: 'string', attribute: 'name' },
    { type: 'string', attribute: 'genders'},

  ],
};

export const DAFTAR_PINDAH_UNITS_LIST_TABLE_FIELDS = {
  label: [
    'No',
    'Unit Tujuan',
    'NIS',
    'Nama Siswa',
    'L/P',
    'Status',
    'Opsi',
  ],
  value: [
    {},
    { type: 'string', attribute: 'unit_to'},
    { type: 'string', attribute: 'nis' },
    { type: 'string', attribute: 'name' },
    { type: 'string', attribute: 'genders'},
    { type: 'string', attribute: 'status'},

  ],
};

export const PERMOHONAN_PINDAH_UNITS_LIST_TABLE_FIELDS = {
  label: [
    'No',
    'Unit Asal',
    'NIS',
    'Nama Siswa',
    'L/P',
    'Status',
    'Opsi',
  ],
  value: [
    {},
    { type: 'string', attribute: 'unit_from'},
    { type: 'string', attribute: 'nis' },
    { type: 'string', attribute: 'name' },
    { type: 'string', attribute: 'genders'},
    { type: 'string', attribute: 'status'},
  ],
};

export const TABLE_LIST_TEMA_TABLE_FIELDS={
    label: [
        'No',
        'Nama Tema',
        'Semester',
        'Opsi',
 
    ],
    value: [
        {},
        {type: 'string', attribute:'name'},
        {type: 'string', attribute:'semester'},
    ]
 
 
};

export const TABLE_LIST_SUB_TEMA_TABLE_FIELDS={
    label: [
        'No',
        'Nama Sub Tema',
        'Opsi',
 
    ],
    value: [
        {},
        {type: 'string', attribute:'name'},
    ]
 
 
};

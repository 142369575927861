import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {isEmpty, noop } from 'lodash';
import AlamatForm from './components/AlamatForm.component';
import { Button } from '../../components/base/index';
import AsalSekolah from './components/AsalSekolahForm.component';
import OrtuForm from './components/OrtuForm.component';
import OrtuIbuForm from './components/OrtuIbuForm.component';
import OrtuWaliForm from './components/OrtuWaliForm.component';
import DataDiri from './components/DataDiri.component';
import {validateManageRegis} from '../../utils/validation.util';
import { errorAlert } from '../../../src/utils/alert.util';
import * as commonActions from '../../states/actions/common.action';


export default class ManageRegistration extends PureComponent {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this._setForm = this._setForm.bind(this);
    this._onSubmit    = this._onSubmit.bind(this);
    this._onClickNext = this._onClickNext.bind(this);
    this._onClickBack = this._onClickBack.bind(this);
    this._getParamOptions = this._getParamOptions.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this._getClassesOptions= this._getClassesOptions.bind(this);
    this._getDistrictOptions = this._getDistrictOptions.bind(this);
    this.onRemoveFile = this.onRemoveFile.bind(this);
    this._getYearsOfEducation = this._getYearsOfEducation.bind(this);

    const {user} = this.props;
    const { organizations_id, school_unit} = user;
    const { units_id, levels_id} = school_unit;
    this.state = {
      page: 1,
      form: {
        value: {
          levels_id,
          units_id: units_id,
          organizations_id: organizations_id,

        },
        error: {
          
        },
      },
      param: {},
    };
  }

  componentDidMount() {
    const { handleGetProvinces, handleGetCities,
    handleGetDistricts, handleGetSubDistricts,
    handleGetCitizenships,handleGetReligions, handleGetEducations, handleGetOccupations, handleGetClasses,
    handleGetGenders, handleGetBloodTypes, handleGetLevels, location, user} = this.props;
    const { state = {} } = location;
    const { school_unit, organizations_id } = user;
    const { levels_id, units_id } = school_unit;
    const {isEdit =  false, data} = state;
    const { id } = state.data || '';
      const { content } = this.state;
    const paramTypes = ['educations', 'occupations', 'levels', 'citizenships', 'religions', 'genders', 'blood_types'];
    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
    if (isEdit && id) {
      this.setState({id:id})
      this._setForm(data.id);
    } else {    
      this._getClassesOptions({ filters: { levels_id: [levels_id] }});
    }
    // commonActions.setLoading({ show: true });
    this._getPeriodsOptions(); 
    this._getYearsOfEducation();
    this._getDistrictOptions();

    handleGetCitizenships();
    handleGetReligions();
    handleGetEducations();
    handleGetOccupations();
    handleGetClasses();
    handleGetGenders();
    handleGetBloodTypes();
    handleGetLevels()
  }

  _onFormChange(event) {
    const { handleGetProvinces,
            handleGetCities,
            handleGetDistricts,
            handleGetSubDistricts, 
    } = this.props;

    const { name, value, dataset, checked, type,files } = event.target;
    const { inputType = 'text', inputArray = false, 
            arrayPosition = 0, fieldName,
          } = dataset;
    let keyword = null;

    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;
      if(type==="file"){
        formattedValue = files[0]
      }
      if (inputType === 'number') {
        // formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        if (type === 'checkbox') {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
       
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = '';
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray
              ? { [fieldName]: newListError }
              : { [name]: '' }),
          },
        },

      };
    }, () => {
    });
  }

  async _setForm(id) {
    const { handleGetManageRegistration, user } = this.props;
    const { organizations_id } = user;
    try {
      const payload = await handleGetManageRegistration({ id });
      const { classrooms, levels_id } = payload;
      this._getClassesOptions({ filters: { levels_id: [levels_id] }} );
      this.setState({
        form: {
          value: {
           id: payload.id,
           first_name: payload.first_name,
           last_name: payload.last_name,
           nis: payload.nis,
           nick_name: payload.nick_name,
           genders_id: payload.genders_id,
           weight: payload.weight,
           height: payload.height,
           pob: payload.pob,
           dob: payload.dob,
           blood_types_id: payload.blood_types_id,
           disabilities: payload.disabilities,
           religions_id: payload.religions_id,
           citizenships_id: payload.citizenships_id,
           nth_child: payload.nth_child,
           siblings: payload.siblings,
           language: payload.language,
           status: payload.status,
           email: payload.email,
           phone: payload.phone,
           house_ownership: payload.house_ownership,
           school_house_distance: payload.school_house_distance,
           levels_id: payload.levels_id,
           entrance_year: payload.entrance_year,
           classes_id: payload.classes_id,
           address: payload.address,
           provinces_id: payload.provinces_id,
           cities_id: payload.cities_id,
           districts_id: payload.districts_id,
           sub_districts_id: payload.sub_districts_id,
           hobby: payload.hobby,
           previous_school_name: payload.previous_school_name,
           previous_school_address: payload.previous_school_address,
           previous_school_prov_id: payload.previous_school_prov_id,
           previous_school_city_id: payload.previous_school_city_id,
           previous_school_districts_id: payload.previous_school_districts_id,
           previous_school_sub_districts_id: payload.previous_school_sub_districts_id,
           previous_school_phone: payload.previous_school_phone,
           reason_move: payload.reason_move,
           father_name: payload.father_name,
           father_nik: payload.father_nik,
           father_religions_id: payload.father_religions_id,
           father_citizenships_id: payload.father_citizenships_id,
           father_educations_id: payload.father_educations_id,
           father_pob: payload.father_pob,
           father_dob: payload.father_dob,
           father_phone: payload.father_phone,
           father_occupations_id: payload.father_occupations_id,
           father_position: payload.father_position,
           father_salary: payload.father_salary,
           father_address: payload.father_address,
           father_provincess_id: payload.father_provincess_id,
           father_city_id: payload.father_city_id,
           father_districts_id: payload.father_districts_id,
           father_sub_districts_id: payload.father_sub_districts_id,
           mother_name: payload.mother_name,
           mother_nik: payload.mother_nik,
           mother_religions_id: payload.mother_religions_id,
           mother_citizenships_id: payload.mother_citizenships_id,
           mother_educations_id: payload.mother_educations_id,
           mother_pob: payload.mother_pob,
           mother_dob: payload.mother_dob,
           mother_phone: payload.mother_phone,
           mother_occupations_id: payload.mother_occupations_id,
           mother_position: payload.mother_position,
           mother_salary: payload.mother_salary,
           mother_address: payload.mother_address,
           mother_provincess_id: payload.mother_provincess_id,
           mother_city_id: payload.mother_city_id,
           mother_districts_id: payload.mother_districts_id,
           mother_sub_districts_id: payload.mother_sub_districts_id,
           guardian_name: payload.guardian_name,
           guardian_nik: payload.guardian_nik,
           guardian_religions_id: payload.guardian_religions_id,
           guardian_citizenships_id: payload.guardian_citizenships_id,
           guardian_educations_id: payload.guardian_educations_id,
           guardian_pob: payload.guardian_pob,
           guardian_dob: payload.guardian_dob,
           guardian_phone: payload.guardian_phone,
           guardian_occupations_id: payload.guardian_occupations_id,
           guardian_position: payload.guardian_position,
           guardian_salary: payload.guardian_salary,
           guardian_address: payload.guardian_address,
           guardian_provincess_id: payload.guardian_provincess_id,
           guardian_city_id: payload.guardian_city_id,
           guardian_districts_id: payload.guardian_districts_id,
           guardian_sub_districts_id: payload.guardian_sub_districts_id,
           certificate_number: payload.certificate_number, 
           certificate_date: payload.certificate_date,
           grade: payload.grade,
           number_of_subject_certificate: payload.number_of_subject_certificate,
           certificate_parent_name: payload.certificate_parent_name, 
           shun_number: payload.shun_number, 
           shun_date: payload.shun_date,
           grade_shun: payload.grade_shun,
           number_of_subject_shun: payload.number_of_subject_shun,
           participant_number: payload.participant_number, 
           father_status: payload.father_status,
           mother_status: payload.mother_status,
           guardian_status: payload.guardian_status, 
           long_study: payload.long_study, 
           name_transfer_letter: payload.name_transfer_letter, 
           nisn: payload.nisn, 
           date_of_transfer: payload.date_of_transfer, 
           upload_image: payload.upload_image,
           organizations_id: payload.organizations_id,
           years_of_education: payload.years_of_education,
          },
          error: {
            // details: [],
          },
        },
      });
    } catch (err) {
      // err action
    }
    finally {
      commonActions.setLoading({ show: false });
    }
  }

  async _getPeriodsOptions(filters={}) {
    const { getPeriodsOptions ,user} = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit
    const res = await getPeriodsOptions(filters);
    filters.organizations_id = organizations_id;

    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
    
  }

  async _getYearsOfEducation(filters={}) {
    const { getYearsOfEducationOptions ,user} = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit
    const res = await getYearsOfEducationOptions(filters);
    filters.organizations_id = organizations_id;

    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        years_educations: res,
      },
    }));
    
  }
  
  async _getParamOptions(type, filters={}) {
    const { getParamOptions } = this.props;
    const res = await getParamOptions(filters, type);
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getDistrictOptions() {
    const {
      handleGetCities,
      handleGetDistricts,
      handleGetProvinces,
      handleGetSubDistricts
    } = this.props;
    const { param } = this.state;

    const provinces = await handleGetProvinces();
    const cities = await handleGetCities();
    const districts = await handleGetDistricts();
    const subDistricts = await handleGetSubDistricts();
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        provinces: provinces,
        cities: cities,
        districts: districts,
        subDistricts: subDistricts,
      },
    }));
   
  }

  async _getParamOptionsAcademics(type, filters={}) {
    const { getParamOptionsAcademics } = this.props;
    if (type === 'classes' || type === 'classrooms') {
      filters.organizations_id = 4;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getClassesOptions(filters={}) {
    const { getClassesOptions, user } = this.props;
    const {school_unit} = user;
    const { levels_id } = school_unit;
    filters.levels_id = levels_id;
    const res = await getClassesOptions(filters);
    // console.log(res)
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        classes: res,
      },
    }));
   
  }

  _onClickNext() {
    window.scrollTo(0, 0);
    this.setState(prevState => ({
      page: prevState.page + 1,
    }));
  }

  _onClickBack() {
    window.scrollTo(0, 0);
    this.setState(prevState => ({
      page: prevState.page - 1,
    }));
  }

  onRemoveFile() {
    this.setState((prevState) => {
      return {
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            upload_image: '',
            file:'',
          }
        }
      }
    });
  }

  async _onSubmit() {
    const { history, handleManageRegistration } = this.props;
    const { form } = this.state;
    const { value } = form;        
    let canSaveA = false;
    const dataForm = form.value;
    // if(dataForm.folder_code && dataForm.folder_name && dataForm.folder_description){
    //   canSaveA = true;
    // } else {
    //   canSaveA = false;
    // }
    // if (canSaveA == false){
    //   alert("Harap lengkapi data form. Bagian yang harus diisi ditandai dengan tanda bintang (*).");
    // } else {
      await handleManageRegistration(value);
      history.push('/dashboard/kesiswaan/list-pendaftaran-siswa');
    // }
  }

  async onEdit() {
    const { history, handleEditManageReistrationt } = this.props;
    const { form } = this.state;
    const { value } = form;
    const res = await handleEditManageReistrationt(value);        
    history.push('/dashboard/kesiswaan/list-pendaftaran-siswa');
  }

  render() {
    const { param, form, page} = this.state;
    const { provinces = [], cities = [], districts = [], subDistricts =[] } = param;
    const { citizenships = {}, religions = {},
      educations = {}, occupations = {}, classes = {},
      genders = {}, blood_types = {}, levels = {},
      handleUploadImageFileStudents
    } = this.props;
    
    return (
      <div className="manage-registration">
        <form onSubmit={this._onSubmit}>
         
          {
            (page === 1) ? (
              <DataDiri
                form={form} 
                param={param}
                onRemoveFile={this.onRemoveFile}
                onChange={this._onFormChange}
                handleUploadImageFileStudents = {handleUploadImageFileStudents}
              />
            ) : (
             ''
            )
          }
          {
            (page === 1) ? (
              <AlamatForm
                sub_districts={subDistricts}
                districts={districts}
                cities={cities}
                provinces={provinces}
                form={form}
                onChange={this._onFormChange}
                param={param}
              />
            ) : (
             ''
            )
          }
           {
            (page === 1) ? (
              <AsalSekolah
                sub_districts={subDistricts}
                districts={districts}
                cities={cities}
                provinces={provinces}
                form={form}
                onChange={this._onFormChange}
                param={param}
              />
             
            ) : (
              ''
            )
          }

          {
            // (page === 1) ? (
            //   <ListForm
            //     form={form}
            //     formFields={STUDENT_PAGE_ASAL_SEKOLAH}
            //     onFormChange={this._onFormChange}
            //   />
            // ) : (
            //   ''
            // )
          }
          {
            (page === 2) ? (
              <OrtuForm
                sub_districts={subDistricts}
                classes={classes}
                districts={districts}
                cities={cities}
                provinces={provinces}
                form={form}
                onChange={this._onFormChange}
                param={param}
              />
            ) : (
             ''
            )
          }
            {
            (page === 2) ? (
              <OrtuIbuForm
              sub_districts={subDistricts}
              classes={classes}
              districts={districts}
              cities={cities}
              provinces={provinces}
              form={form}
              onChange={this._onFormChange}
              param={param}
            
              />
            ) : (
             ''
            )
          }
          {
            (page === 2) ? (
              <OrtuWaliForm
              sub_districts={subDistricts}
              districts={districts}
              cities={cities}
              provinces={provinces}
              form={form}
              onChange={this._onFormChange}
              param={param}
              />
            ) : (
             ''
            )
          }
          
          
              {
                page === 1 && (
                <div className="manage-registration__footer">
                  <div className="manage-registration__button-wrapper">
                  <Button
                  onClick={this._onClickNext}
                  title="Next"
                />
                 </div>
               </div>
                )
              }
              {
                page === 2 ? (
                  
            <div className="student-list__header">
                <div className="student-list__button">
                <Button
                    onClick={this._onClickBack}
                    title="Kembali"
                  />
                </div>
                <div className="student-list__button">
               
                   {this.state.id? 
                 <Button
                 type="button"
                 title="Update"
                 onClick={this.onEdit}
               />: 
                  <Button
                    onClick={this._onSubmit}
                    title="Simpan"
                />}
              </div>
           </div>
                ) : (
                 ''
                )
              }
           
        </form>
      </div>
    );
  }
}
ManageRegistration.propTypes = {
  getParamOptions: PropTypes.func,

  handleManageRegistration: PropTypes.func,
  history: PropTypes.object.isRequired,
  handleGetCitizenships: PropTypes.func,
  handleGetReligions: PropTypes.func,
  handleGetEducations: PropTypes.func,
  handleGetOccupations: PropTypes.func,
  handleGetClasses: PropTypes.func,
   
  handleGetProvinces: PropTypes.func,

  handleGetCities: PropTypes.func,

  handleGetDistricts:  PropTypes.func,

  handleGetSubDistricts: PropTypes.func,

  user: PropTypes.object,
};
ManageRegistration.defaultProps = {
  getParamOptions: noop,
  handleManageRegistration: noop,
  handleGetProvinces: noop,

  handleGetCities: noop,

  handleGetDistricts: noop,

  handleGetSubDistricts: noop,


  handleGetCitizenships: noop,
  handleGetReligions: noop,
  handleGetEducations: noop,
  handleGetOccupations: noop,
  handleGetClasses: noop,
  handleGetGenders: noop,
  handleGetBloodTypes: noop,
  handleGetLevels: noop,
  user: null,
};
import React, { Component } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import PropTypes from 'prop-types';
import {
  map, isEmpty,
  filter, find, pickBy,
  cloneDeep,
} from 'lodash';
import logo from '../../../../assets/img/logo-kopsurat-ricci.png';
import {
  commaFormatted, normalizeAmount, datetimeFormat,
} from '../../../../utils/transformer.util';
import {
  Select, Input, SearchSelect2, Button, Textarea,
} from '../../../../components/base';
import MonthlyCheckList from './components/MonthlyCheckList.component';
import MonthlyActivityPlanFormTableHeaders from './components/MonthlyActivityPlanFormTableHeaders.component';
import language from '../../../../languages';
import { printRKB } from '../../../../utils/print.util';
import { errorAlert } from '../../../../utils/alert.util';

const MySwal = withReactContent(Swal);

const calculateTotal = (list = null) => {
  let total = 0;
  if (!isEmpty(list)) {
    Object.keys(list).forEach((key) => {
      const item = list[key];
      let amount = 0;
      if (item && item.amount) {
        amount = parseFloat(item.amount);
      }
      total += amount;
    });
  }
  return total;
};

const Tema = tema => ((
  <>
    {map(tema, o => (
      <>
        <td>{o.code}</td>
        <td>{o.tema}</td>
      </>
    ))}
  </>
));

const _setSelectedDetail = (state = {}, checked = false, detailId = null) => {
  const { form } = state;
  const { details = {} } = form.value;
  const newState = { ...state };
  const newRecommendations = newState.form.value.recommendations;

  if (!checked) {
    delete newState.checkedDetails[detailId];
    delete newRecommendations[detailId];
  } else {
    const detail = find(details, (o, key) => parseInt(key, 10) === parseInt(detailId, 10));
    newState.checkedDetails[detailId] = detail;
    newRecommendations[detailId] = detail.amount;
  }
  newState.form.value.recommendations = newRecommendations;

  return newState;
};

const _renderWorkflowHistory = (workflow) => {
  const rows = [];
  const roleMap = {
    'Kepala Sekolah': 'Kepala Sekolah',
    'Manager Opsdik': 'Manager Opsdik',
    RT: 'RT',
    Pengadaan: 'Pengadaan',
    Keuangan: 'Keuangan',
    Opsdik: 'Opsdik',
    Hrd: 'Hrd',
    Sekretariat: 'Sekretariat',
    'Manager Keuangan': 'Manager Keuangan',
    Bendahara: 'Bendahara',
  };

  if (workflow) {
    workflow.forEach((item, idx) => {
      const {
        prev_role, next_role, action, updated_at, remarks, is_done,
      } = item;
      let flowAction = '';
      // let flowAction = (action === 'reject') ?
      //   'mengembalikan ke' : ' mengajukan ke';
      if (action === 'reject') {
        flowAction = 'mengembalikan ke';
      } else if (action === 'submit' && roleMap[prev_role] === 'Manager Opsdik') {
        flowAction = 'menyetujui, diserahkan ke';
      } else if (roleMap[next_role] === 'Manager Opsdik') {
        flowAction = 'mengajukan ke';
      } else {
        flowAction = 'mengetahui, diserahkan ke';
      }
      let flowValue = `${roleMap[prev_role]} ${flowAction} ${roleMap[next_role]}`;

      if (is_done) {
        flowValue = `${roleMap[next_role]} mengetahui dan RKB telah selesai di approve dan di setujui `;
      }

      rows.push([
        <tr key={`workflow_history_item_${idx}`}>
          <td>{idx + 1}</td>
          <td>{flowValue}</td>
          <td>{remarks}</td>
          <td>{datetimeFormat(updated_at)}</td>
        </tr>,
      ]);
    });
    return (
      <table className="table">
        <thead>
          <tr>
            <th>No.</th>
            <th>Flow</th>
            <th>Catatan</th>
            <th>Tanggal</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </table>
    );
  }

  return false;
};


const TotalRAPBS = (allocations = []) => {
  let totalPlafond = 0;
  let name = '';
  let rutinValue = 0;
  let kegiatanValue = 0;
  let danPemValue = 0;
  let biPengValue = 0;

  allocations.forEach((allocation) => {
    name = allocation.plafond.plafond;
    const alo = allocation.nominal;
    if (name === 'Rutin') {
      rutinValue = parseInt(rutinValue, 10) + parseInt(alo, 10);
    } else if (name === 'Kegiatan') {
      kegiatanValue = parseInt(kegiatanValue, 10) + parseInt(alo, 10);
    } else if (name === 'Dana Pemerintah') {
      danPemValue = parseInt(danPemValue, 10) + parseInt(alo, 10);
    } else if (name === 'Biaya Pengembangan Pendidikan') {
      biPengValue = parseInt(biPengValue, 10) + parseInt(alo, 10);
    }
    totalPlafond = rutinValue + kegiatanValue + biPengValue + danPemValue;
  });
  return totalPlafond;
};


export default class ManageMonthlyActivityActivity extends Component {
  constructor(props) {
    super(props);

    const { user } = props;
    const { user_group } = user;
    const { permissions = {} } = user_group;

    this.setForm = this.setForm.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this._renderButtons = this._renderButtons.bind(this);
    this._renderFundChecklist = this._renderFundChecklist.bind(this);
    this._getTemaOptions = this._getTemaOptions.bind(this);
    this._renderFormHeader = this._renderFormHeader.bind(this);
    this.onSearchTema = this.onSearchTema.bind(this);
    this._showModal = this._showModal.bind(this);
    this._onCheckAll = this._onCheckAll.bind(this);
    this.renderButtonTtd = this.renderButtonTtd.bind(this);
    this._onCheckDetail = this._onCheckDetail.bind(this);
    this._onCloseModal = this._onCloseModal.bind(this);
    this._onDelete = this._onDelete.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this.TotalRAPBS2 = this.TotalRAPBS2.bind(this);
    this._addListItem = this._addListItem.bind(this);
    this._setRemarks = this._setRemarks.bind(this);
    this.onClickUnit = this.onClickUnit.bind(this);
    this._setRef = this._setRef.bind(this);
    this._onReject = this._onReject.bind(this);
    this._confirmReject = this._confirmReject.bind(this);
    this._onSubmitApproval = this._onSubmitApproval.bind(this);
    this._setTableConfig = this._setTableConfig.bind(this);
    this._getData = this._getData.bind(this);
    this.School_unit = this.School_unit.bind(this);
    this.onClickTransfer = this.onClickTransfer.bind(this);
    this.onClickCetak = this.onClickCetak.bind(this);
    this.setOpen = this.setOpen.bind(this);
    this.Total = this.Total.bind(this);
    this.remarks = React.createRef();
    this.checkboxRefs = [];
    this.onFileChange = this.onFileChange.bind(this);

    this.state = {
      autocomplete: {
        loading: false,
        open: false,
      },
      filters: {
        head: null,
        periode: new Date().getFullYear(),
        tema: null,
      },
      form: {
        value: {
          amount: 0,
          total_approval: 0,
          total: 0,
          periode: null,
          selectedBudget: null,
          details: {},
          recommendations: {},
          month: null,
          tema: [],
          needed_funds: '',
          difference: 0,
          payment_of_funds: '',
          keterangan: '',
          totalplafond: 0,
          totalRapbs: 0,
          type: 'activity',
        },
        error: {
          tema: '',
          amount: 0,
          month: '',
        },
      },
      tema: [],
      workflow: [],
      needed_funds: '',
      amount: '',
      difference: 0,
      payment_of_funds: '',
      keterangan: '',
      user_id: {},
      history: '',
      fund_request_detail: {},
      selectedTema: {},
      setWorkingUnit: {},
      selectedBudgetDetails: {},
      availableBudgetDetailsRKB: {},
      availableBudgetDetailsRKB2: {},
      checkedAll: false,
      is_show: false,
      checkedDetails: [],
      remarks: '',
      is_approved: false,
      budgets: {},
      school_unit: {},

    };

    this.tableConfig = {
      showEdit: false,
      showApprovalCol: false,
      showEditRecommendation: false,
      showRecommendation: false,
      showView: false,
    };

    this.permissions = {
      canEditRecommendation:
        permissions.includes('edit_fund_request_recommendation'),
      canApprove:
        permissions.includes('approve_fund_request'),
      canEdit:
        permissions.includes('edit_fund_request'),
      canView:
        permissions.includes('view_fund_request'),
    };
  }

  componentDidMount() {
    const { location, handleGetMonthOptions, onSearchUnit } = this.props;
    const { data = {} } = location.state;
    if (data.id) {
      this._getData(data.id);
    } else {
      this._setTableConfig();
    }
    this._getTemaOptions();
    handleGetMonthOptions();
    onSearchUnit();
  }

  async onFileChange(e) {
    const { target } = e;
    const { dataset, files } = target;
    const file = files[0];
    const { index } = dataset;
    const { form } = this.state;
    const { handleUploadFileRKB } = this.props;
    const { value } = form;
    const newValue = JSON.parse(JSON.stringify(value));
    const { details = [] } = newValue;

    details[index].file = file;

    await handleUploadFileRKB({
      file,
      filename: file.name,
    });

    this.setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: newValue,
      },
    }));
  }

  onClickCetak(val) {
    const { history } = this.props;
    history.push('/dashboard/keuangan/penganggaran/cetak-rencana-kegiatan-bulanan',
      { data: val });
  }

  onClickUnit(event) {
    const { target } = event;

    // console.log(event)
    target.value.id = target.value.value;
    target.value.title = target.value.label;
    this.setState({ setWorkingUnit: event.target.value });
    // setWorkingUnit(target.value);
  }

  onClickTransfer() {
    const { location, handleTransferFund } = this.props;
    const { data } = location.state;
    MySwal.fire({
      title: 'Anda yakin akan mentransfer dana?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
      showCancelButton: true,
    }).then(async (result) => {
      if (result.value) {
        const res = await handleTransferFund({ id: data.id });
        this.setForm(res);
        location.history.goBack();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.close();
      }
    });
  }

  async onSearchTema(event) {
    const { target } = event;
    const { value } = target;
    const { form } = this.state;
    const { id, month } = form.value;
    const { handleGetAvailableBudgetDetailsRKBTema } = this.props;
    const budgetDetails = await handleGetAvailableBudgetDetailsRKBTema({
      tema: value.code,
      month,
      id,
    });

    this.setState(prevState => ({
      ...prevState,
      selectedTema: value,
      availableBudgetDetailsRKB2: {
        [value.code]: { ...budgetDetails },
      },
    }));
  }

  async setForm(data) {
    const {
      id = null, month = null, details = {}, workflow = [], user_id,
      fund_request_detail, selectedBudgetDetails = {}, recommendations = {},
      amount = 0, total_approval = 0, is_approved = false, school_unit,
      is_transferred = 0,
    } = data;
    let checkedAll = false;
    const notEmptyRecommendations = pickBy(recommendations, value => value > 0);
    if (Object.keys(selectedBudgetDetails).length
    === Object.keys(notEmptyRecommendations).length) {
      checkedAll = true;
    }
    // console.log(selectedBudgetDetails)
    const checkedDetails = notEmptyRecommendations || {};
    this.setState({
      form: {
        value: {
          id,
          amount,
          month,
          details: Array.isArray(details) ? {} : details,
          recommendations,
          total_approval,
        },
        error: {
          tema: '',
        },
      },
      workflow,
      selectedBudgetDetails,
      availableBudgetDetailsRKB: {},
      availableBudgetDetailsRKB2: {},
      checkedAll,
      checkedDetails,
      is_approved,
      is_transferred,
      school_unit,
      user_id,
      fund_request_detail,
    }, () => {
      this._getTemaOptions(data.school_unit.prm_school_units_id);
      this._setTableConfig();
    });
  }

  _setRef(el) {
    if (el === null || !el) {
      return;
    }
    const { input } = el;
    const { value } = input;
    this.checkboxRefs[value] = input;
  }

  _setTableConfig() {
    const { permissions } = this;
    let { tableConfig } = this;
    const { location, user } = this.props;
    const { user_group } = user;
    const userGroup = user_group.name;
    const { data } = location.state || {};
    const { is_approved = false } = data || {};
    const { workflow } = this.state;
    const workflowLength = workflow.length || 0;

    if (is_approved) {
      tableConfig = {
        showEdit: false,
        showEditRecommendation: false,
        showRecommendation: true,
        showApprovalCol: true,
      };
    } else if (workflowLength > 0) {
      const { next_role, prev_role } = workflow[workflowLength - 1];

      if (userGroup !== next_role) {
        tableConfig.showEdit = false;
        tableConfig.showEditRecommendation = false;
        tableConfig.showView = true;

        if (permissions.canEditRecommendation) {
          if (userGroup === prev_role) {
            tableConfig.showRecommendation = true;
          }
        }
      } else {
        if (permissions.canEditRecommendation) {
          tableConfig.showEditRecommendation = true;
          tableConfig.showRecommendation = true;
        }

        if (permissions.canApprove) {
          tableConfig.showApprovalCol = true;
          tableConfig.showRecommendation = true;
        }

        if (permissions.canEdit) {
          tableConfig.showEdit = true;
        }
      }
    } else if (permissions.canEdit) {
      tableConfig.showEdit = true;
    }
    this.tableConfig = tableConfig;
  }

  _addListItem() {
    const { form } = this.state;
    const newForm = { ...form };
    const { value } = newForm;

    if (typeof value.details === 'undefined') {
      value.details = {};
    }

    this.setState(prevState => ({
      ...prevState,
      form: {
        value: {
          ...prevState.form.value,
          ...newForm,
        },
      },
    }), () => {
      this.calculateTotal();
    });
  }

  async _onFormChange(event, fieldName) {
    const {
      name,
      value,
      dataset,
    } = event.target;
    const {
      inputType = 'text', arrayPosition = null,
    } = dataset;
    const { form } = this.state;
    const newForm = cloneDeep(form);
    const showDiffAlert = false;

    this.setState((prevState) => {
      let formattedValue = value;
      let allowedFundRequest = 0;
      let newDetail = {};

      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }

      if (arrayPosition !== null) {
        if (!newForm.value.details[arrayPosition]) {
          newForm.value.details[arrayPosition] = {};
        }
        newDetail = newForm.value.details[arrayPosition];
        if (name === 'amount') {
          let diff = 0;
          allowedFundRequest = parseInt(newDetail.allowed_fund_request, 10);
          diff = allowedFundRequest - parseInt(formattedValue, 10);
          newDetail[name] = formattedValue;
          newDetail.difference = diff;
          newForm.value.amount = calculateTotal(newForm.value.details);
        } else {
          newDetail[name] = formattedValue;
        }
        newForm.value.details[arrayPosition] = newDetail;
      } else {
        newForm.value[name] = formattedValue;
      }

      const error = {
        ...prevState.form.error,
        [name]: '',
      };

      if (fieldName) {
        error[fieldName] = filter(error[fieldName], (err) => {
          if (err) {
            return err.length;
          }
          return {};
        });
      }

      return {
        ...prevState,
        form: {
          value: {
            ...prevState.form.value,
            ...newForm.value,
          },
          error: {
            ...prevState.form.error,
            [name]: '',
          },

        },
      };
    }, () => {
      if (name === 'month') {
        this._getTemaOptions();
      }
      if (showDiffAlert) {
        MySwal.fire({
          title: 'Saldo tidak mencukupi',
          icon: 'warning',
          buttons: true,
          dangerMode: true,
        });
      }
    });
  }

  async _getTemaOptions(unitId = null, keyword = '') {
    const { handleMonthlyActivityPlantTema } = this.props;
    const { form } = this.state;
    const { value } = form;
    const { id, month } = form.value;
    const res = await handleMonthlyActivityPlantTema({
      keyword,
      periode: value.periode,
      unit_id: unitId,
      month,
      id,
    });
    // console.log(res)

    return res;
  }

  _onCloseModal(detailState) {
    const { selectedBudgetDetails = {}, form } = this.state;
    const { value } = form;
    const { details } = value;
    const { selected, unchecked } = detailState;

    if (selected) {
      Object.keys(selected).forEach((key) => {
        selectedBudgetDetails[key] = selected[key];

        details[key] = {
          ...selectedBudgetDetails[key],
          ...details[key],
          difference: details[key]
            ? details[key].difference : selectedBudgetDetails[key].allowed_fund_request,
          allowed_fund_request: selectedBudgetDetails[key].allowed_fund_request,
          totalPlafond: TotalRAPBS(selectedBudgetDetails[key].allocations),
        };
      });
    }
    if (unchecked) {
      Object.keys(unchecked).forEach((key) => {
        delete selectedBudgetDetails[key];
        delete details[key];
      });
    }
    this.setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          amount: calculateTotal(details),
          details,
        },
      },
      selectedBudgetDetails: {
        ...selectedBudgetDetails,
        ...selected,
      },
    }));
  }

  _setRemarks(event) {
    const { value } = event.target;

    this.setState(prevState => ({
      ...prevState,
      remarks: value,
    }));
  }

  _onSubmit(e) {
    e.preventDefault();
    const { form } = this.state;
    const { value } = form;
    const {
      handleAddFundRequest,
      history, location,
    } = this.props;
    const error = null;

    const { isEdit = false } = location.state;
    if (isEmpty(error)) {
      if (isEdit) {
        handleAddFundRequest(value, history.goBack);
      } else {
        handleAddFundRequest(value, history.goBack);
      }
    } else {
      errorAlert({
        title: isEdit ? 'Gagal Mengubah' : 'Gagal Membuat',
        errorMessage: 'Periksa lengkapi form',
      });
    }
  }

  _onCheckDetail(event) {
    const { target } = event;
    const { value, checked } = target;
    let newState = { ...this.state };

    newState = _setSelectedDetail(newState, checked, value);

    this.setState({
      ...newState,
    });
  }

  async _onDelete(id) {
    const { handleDeleteFundRequestDetail } = this.props;
    const { form, selectedBudgetDetails } = this.state;
    const { value } = form;
    const newDetails = { ...value.details };
    delete newDetails[id];
    delete selectedBudgetDetails[id];
    if (value.id) {
      await handleDeleteFundRequestDetail({
        budget_detail_id: id,
        id: value.id,
      });
    }
    this.setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          amount: calculateTotal(newDetails),
          details: { ...newDetails },
        },
      },
      selectedBudgetDetails,
    }));
  }

  async _getData(id) {
    const { handleGetFundRequestById } = this.props;
    const res = await handleGetFundRequestById({ id });
    this.setForm(res);
  }

  _showModal() {
    const { selectedTema } = this.state;

    if (selectedTema && selectedTema.code) {
      MySwal.fire({
        size: 22000,
        title: 'Pilih Anggaran',
        width: '4000px',
        height: '1000px',
        footer: '',
        html: this._renderFundChecklist(),
      });
    }
    return false;
  }


  _renderFilters(form, isEdit) {
    const { value, error } = form;
    const { month = {} } = this.props;

    return (
      <div className="manage-fund-request__row-form">
        <div className="manage-fund-request__form-content">
          <div>
            <Select
              disabled={!isEdit}
              name="month"
              data={month.list}
              onChange={this._onFormChange}
              placeholder="Pilih periode"
              value={value.month || ''}
              error={error.month || ''}
            />
          </div>
        </div>
      </div>
    );
  }

  async _onSubmitApproval() {
    const { user } = this.props;
    const { permissions } = user.user_group;
    const { form } = this.state;
    const {
      handleSubmitApproval, location, history, handleAddFundRequest,
    } = this.props;
    const { data } = location.state;
    const error = null;
    const { isEdit = false } = location.state;

    if (isEmpty(error)) {
      if (typeof data === 'undefined') {
        const res = await handleAddFundRequest(form.value);
        await handleSubmitApproval({
          id: res.id,
        }, history.goBack);
      } else if (permissions.includes('edit_fund_request_recommendation')) {
        await handleAddFundRequest(form.value);
        await handleSubmitApproval({
          id: data.id,
        }, history.goBack);
      } else if (permissions.includes('edit_fund_request')) {
        await handleAddFundRequest(form.value);
        await handleSubmitApproval({
          id: data.id,
        }, history.goBack);
      } else {
        await handleSubmitApproval({
          id: data.id,
        }, history.goBack);
      }
    } else {
      errorAlert({
        title: isEdit ? 'Gagal Mengubah' : 'Gagal Membuat',
        errorMessage: 'Periksa lengkapi form',
      });
    }
  }


  _renderButtons() {
    const { user, location } = this.props;
    const { is_transferred = 0 } = this.state;
    const { data = {} } = location.state;
    const { user_groups_id, user_group } = user;
    const { workflow = [] } = data;
    const lastWorkflow = workflow[workflow.length - 1] || {};
    const WorkflowPrev_role = workflow[0] ? workflow[0].prev_role : '';
    const { permissions = [] } = user_group;
    const canApprove = permissions.includes('approve_fund_request');
    const roleMap = {
      'Kepala Sekolah': 'Kepala Sekolah',
      'Manager Opsdik': 'Manager Opsdik',
      RT: 'RT',
      Pengadaan: 'Pengadaan',
      Keuangan: 'Keuangan',
      Opsdik: 'Opsdik',
      Hrd: 'Hrd',
      Sekretariat: 'Sekretariat',
    };

    if (!lastWorkflow.next_role && !lastWorkflow.is_done) {
      return [
        <div key="button_save">
          <Button
            type="submit"
            title="Save"
          />
        </div>,
        <div key="button_submit">
          <Button
            type="button"
            onClick={this._onSubmitApproval}
            title={(user_group.name === 'Hrd' || user_group.name === 'Kepala Sekolah' || user_group.name === 'Opsdik'
                    || user_group.name === 'Sekretariat') ? 'Submit' : 'Approve'}
          />
        </div>,
      ];
    }
    if ((lastWorkflow.next_role === user_group.name) && !lastWorkflow.is_done) {
      const buttons = [];

      if (permissions.includes('edit_fund_request') && user_group.name === WorkflowPrev_role) {
        buttons.push([
          <div key="button_save">
            <Button
              type="submit"
              title="Save"
            />
          </div>,
        ]);
      }

      buttons.push([
        <div key="button_submit">
          <Button
            type="button"
            onClick={this._onSubmitApproval}
            title={(!canApprove) ? 'Submit' : 'Approve'}
          />
        </div>,
      ]);

      if (permissions.includes('reject_fund_request') && !lastWorkflow.is_done && user_group.name !== WorkflowPrev_role) {
        buttons.push([
          <div key="button_save">
            <Button
              type="button"
              onClick={this._confirmReject}
              title="Reject"
            />
          </div>]);
      }

      if (user_groups_id) {
        return buttons;
      }
    } else {
      if (lastWorkflow.is_done) {
        if (is_transferred) {
          return (
            <div>
              <h2><i>Permohonan dana sudah di transfer</i></h2>
            </div>
          );
        }
        return (
          <>
            {
              user_group.name === 'Keuangan' && (
              <div key="button_save">
                <Button
                  type="button"
                  onClick={this.onClickTransfer}
                  title="Transfer Dana"
                />
              </div>
              )
            }
          </>
        );
      }
      let status = 'RKB telah diajukan ke ';
      if (lastWorkflow.action === 'reject') {
        status = 'RKB telah dikembalikan ke ';
      }
      return (
        <div>
          <h2><i>{`${status}${roleMap[lastWorkflow.next_role]}`}</i></h2>
        </div>
      );
    }

    return false;
  }

  _renderFormHeader() {
    const {
      school_unit = {}, form,
    } = this.state;
    const { location } = this.props;
    const { data = {} } = location.state;
    const { workflow = [] } = data;
    const months = [
      '', 'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli',
      'Agustus', 'September', 'Oktober', 'November', 'Desember',
    ];
    const years = new Date().getFullYear();

    if (typeof form.value.id === 'undefined') {
      return false;
    }
    return (
      <div>
        <div className="form_header2">
          <h1>Rencana Kegiatan Bulanan</h1>
        </div>
        <table className="fund-request-bottom__table-header">
          <tbody>
            <tr>
              <td width="10%">Tanggal Pengajuan</td>
              <td>: {(workflow[0]) ? workflow[0].created_at : ''} </td>
            </tr>
            <tr>
              <td width="10%">Unit</td>
              <td>: {school_unit.school_unit.name}</td>
            </tr>
            <tr>
              <td width="12%">Periode Pelaksanaan</td>
              <td>:{months[form.value.month]}&nbsp;{years}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  _onReject(remarks) {
    const { handleReject, history, location } = this.props;
    const { data } = location.state;

    handleReject({
      id: data.id,
      remarks,
    }, history.goBack);
  }

  _onCheckAll(event) {
    const { target } = event;
    const detailIds = this.checkboxRefs;
    let newState = { ...this.state };
    const isCheckedAll = target.checked;

    detailIds.forEach((detailId) => {
      detailIds[detailId].checked = isCheckedAll;
      newState = _setSelectedDetail(newState, isCheckedAll, detailId);
    });

    this.setState(() => ({
      ...newState,
      checkedAll: target.checked,
    }));
  }


  School_unit() {
    const {
      form, user_id = {},
    } = this.state;
    const { user } = this.props;
    if (typeof form.value.id === 'undefined') {
      return (
        <td>{user.name}</td>
      );
    }
    return (

      <td>{user_id[0] ? (user_id[0].name) : ''}</td>

    );
  }

  setOpen(isOpen) {
    const { autocomplete } = this.state;
    autocomplete.open = isOpen;
    this.setState(prevState => ({
      ...prevState,
      autocomplete,
    }));
  }

  TotalRAPBS2(selectedBudgetDetails) {
    const { form } = this.state;
    const { details } = form.value;
    let totalRapbs = 0;
    Object.keys(selectedBudgetDetails).forEach((key) => {
      const detail = details[key];
      totalRapbs += parseInt(detail.totalPlafond, 10);
    });
    return (<td>{commaFormatted(totalRapbs)}</td>);
  }

  Total(allocations) {
    const totalplafond = 0;
    let name = '';
    let rutinValue = 0;
    let kegiatanValue = 0;
    let danPemValue = 0;
    let biPengValue = 0;

    allocations.forEach((allocation) => {
      name = allocation.plafond.plafond;
      const alo = parseInt(allocation.nominal, 10);
      if (name === 'Rutin') {
        rutinValue = parseInt(rutinValue, 10) + alo;
      } else if (name === 'Kegiatan') {
        kegiatanValue = parseInt(kegiatanValue, 10) + alo;
      } else if (name === 'Dana Pemerintah') {
        danPemValue = parseInt(danPemValue, 10) + alo;
      } else if (name === 'Biaya Pengembangan Pendidikan') {
        biPengValue = parseInt(biPengValue, 10) + alo;
      }
      this.state.totalplafond = rutinValue + kegiatanValue + biPengValue + danPemValue;
    });

    return totalplafond;
  }

  _confirmReject() {
    const el = this.remarks.current.input;
    el.classList.remove('hidden');
    MySwal.fire({
      title: 'Masukkan remarks untuk menolak RKB',
      html: el,
      icon: 'warning',
      buttons: true,
      dangerMode: true,
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        this._onReject(el.value);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.close();
      }
    });
  }


  _renderFundChecklist() {
    const {
      selectedTema = {}, availableBudgetDetailsRKB2 = {}, selectedBudgetDetails = {},
    } = this.state;
    const budgetDetails2 = { ...availableBudgetDetailsRKB2[selectedTema.code] } || {};

    return (
      <MonthlyCheckList
        budgets={budgetDetails2}
        selectedBudgetDetails={selectedBudgetDetails}
        onChange={this._onCloseModal}
      />
    );
  }

  renderButtonTtd() {
    const {
      form, workflow = {},
    } = this.state;
    const approvers = {};
    let creator = '';
    workflow.forEach((item, index) => {
      const { user } = item;
      if (index === 0) {
        creator = user.name;
      } else if (item.action === 'submit') {
        approvers[item.prev_role] = user.name;
      }
    });

    if (typeof form.value.id === 'undefined') {
      return false;
    }
    return (
      <table className="fund-request-bottom__table-test">
        <tbody>
          <tr>
            <td />
            <td>Hormat kami,</td>
            <td>Menyetujui,</td>
            <td />
            <td>Mengetahui,</td>
            <td />
          </tr>
          <tr>
            <td className="bottom" />
            <td className="bottom" />
            <td className="bottom" />
            <td className="bottom" />
            <td className="bottom" />
            <td className="bottom" />
          </tr>
          <tr>
            <td className="bottom" />
            <td className="bottom" />
            <td className="bottom" />
            <td className="bottom" />
            <td className="bottom" />
            <td className="bottom" />
          </tr>
          <tr>
            <td className="bottom" />
            <td className="bottom" />
            <td className="bottom" />
            <td className="bottom" />
            <td className="bottom" />
            <td className="bottom" />
          </tr>
          <tr>
            <td className="bottom" />
            <td className="bottom" />
            <td className="bottom" />
            <td className="bottom" />
            <td className="bottom" />
            <td className="bottom" />
          </tr>
          <tr>
            <td />
            <td style={{ textDecorationLine: 'underline' }}>{creator}</td>
            <td style={{ textDecorationLine: 'underline' }}>{approvers['Manager Opsdik']}</td>
            <td style={{ textDecorationLine: 'underline' }}>{approvers.Keuangan}</td>
            <td style={{ textDecorationLine: 'underline' }}>{approvers.Pengadaan}</td>
            <td style={{ textDecorationLine: 'underline' }}>{approvers.RT}</td>
          </tr>
          <tr>
            <td />
            <td>{ workflow[0] && workflow[0].prev_role}</td>
            <td>Manager OPSDIK</td>
            <td>Keuangan</td>
            <td>Pengadaan</td>
            <td>Rumah Tangga</td>
          </tr>
        </tbody>
      </table>
    );
  }

  render() {
    const {
      form, workflow = [],
      selectedBudgetDetails, checkedAll, remarks, selectedTema,
    } = this.state;

    const lastWorkflow = workflow[workflow.length - 1] || {};
    const {
      showEdit,
      showEditRecommendation,
      showRecommendation,
      showApprovalCol,
    } = this.tableConfig;
    const { details } = form.value;
    const {
      user, location, tema,
    } = this.props;
    const locationState = location.state;
    const { user_group } = user;
    const { permissions } = user_group;
    const isEdit = permissions.includes('edit_fund_request') && locationState.isEdit;
    let idx = 1;

    const approvers = {};
    let creator = '';
    workflow.forEach((item, index) => {
      const { user: u } = item;
      if (index === 0) {
        creator = u.name;
      }
    });
    return (
      <div>
        {
            typeof form.value.id === 'undefined' && (
              <h1>
                Rencana Kegiatan Bulanan - Perbaikan
              </h1>
            )
          }
        { typeof form.value.id !== 'undefined' && showEdit === false && (
        <div className="print">
          <Button
            title="Cetak"
            onClick={() => {
              printRKB(
                'fund-request-bottom__table-head', 'home__logo2', 'form_header2', 'fund-request-bottom__table-header', 'table', 'fund-request-bottom__table-test',
                creator,
                approvers,
                workflow,
              );
            }}
          />

        </div>
        )
            }
        {this._renderFormHeader()}
        <form onSubmit={this._onSubmit}>

          <Textarea
            onChange={this._setRemarks}
            ref={this.remarks}
            classNames="hidden remarks"
            value={remarks}
          />

          { this._renderFilters(form, isEdit) }

          <table className="table">
            <MonthlyActivityPlanFormTableHeaders
              showEdit={showEdit}
              onCheckAll={this._onCheckAll}
              checkAll={checkedAll}
              workflow={workflow}
              user={user}
              showEditRecommendation={showEditRecommendation}
              showRecommendation={showRecommendation}
              showApprovalCol={showApprovalCol}
              type="activity"
            />

            <tbody>
              { showEdit === true && workflow.length === 0
              && (
              <tr>

                <td colSpan={(showEdit) ? 10 : 7}>
                  <div className="manage-budget__add-more">
                    <div className="manage-fund-request__form-content">
                      <SearchSelect2
                        noMargin
                        async={false}
                        name="tema"
                        list={tema.list}
                        onChange={this.onSearchTema}
                        placeholder="Pilih Tema"
                        value={selectedTema}
                        labelName="name"
                        valueName="code"
                      />&nbsp;

                      <Button
                        disabled={Object.keys(selectedTema).length === 0}
                        onClick={this._showModal}
                        title={language.translate.FINANCE__BUDGETING__ADD_LINE__TITLE}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              )}

              { showEdit === true && lastWorkflow.action === 'reject'
              && (
              <tr>

                <td colSpan={(showEdit) ? 10 : 7}>
                  <div className="manage-budget__add-more">
                    <div className="manage-fund-request__form-content">
                      <SearchSelect2
                        noMargin
                        async={false}
                        name="tema"
                        list={tema.list}
                        onChange={this.onSearchTema}
                        placeholder="Pilih Tema"
                        value={selectedTema}
                        labelName="name"
                        valueName="code"
                      />&nbsp;

                      <Button
                        disabled={Object.keys(selectedTema).length < 0}
                        onClick={this._showModal}
                        title={language.translate.FINANCE__BUDGETING__ADD_LINE__TITLE}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              )}
              {

              map(selectedBudgetDetails, (data, key) => (
                <tr key={key}>
                  <td className="col_sm">{idx++}</td>
                  {Tema(data.tema)}
                  <td className="amount">
                    <Input
                      noMargin
                      isNumber={false}
                      data-input-array
                      data-array-position={key}
                      edit={lastWorkflow.action === 'reject' || workflow.length === 0 ? showEdit : false}
                      name="keterangan"
                      placeholder="isi Payment of funds"
                      onChange={this._onFormChange}
                      value={details[key] ? details[key].keterangan : ''}
                    />

                  </td>
                  {this.School_unit()}
                  <td className="amount">
                    <Input
                      noMargin
                      width="6%"
                      data-input-array
                      data-array-position={key}
                          // edit={showEdit}
                      edit={lastWorkflow.action === 'reject' || workflow.length === 0 ? showEdit : false}
                      type="date"
                      name="needed_funds"
                      onChange={this._onFormChange}
                      value={
                            details[key] ? details[key].needed_funds : ''
                          }
                    />
                  </td>
                  <td>
                    {data.execution_time}
                  </td>
                  <td>
                    {(details[key]) ? commaFormatted(details[key].totalPlafond) : 0}
                  </td>
                  <td className="amount">
                    <Input
                      input="number"
                      noMargin
                      data-input-array
                      data-array-position={key}
                      prefix="Rp. "
                      data-input-type="number"
                      isNumber
                        // edit={showEdit}
                      edit={lastWorkflow.action === 'reject' || workflow.length === 0 ? showEdit : false}
                      name="amount"
                      onChange={this._onFormChange}
                      value={
                          details[key] ? details[key].amount : ''
                        }
                    />
                  </td>
                  <td>
                    {(details[key]) ? commaFormatted(details[key].difference) : ''}
                  </td>
                  <td className="amount">
                    <Input
                      noMargin
                      isNumber={false}
                      data-input-array
                      data-array-position={key}
                      data-input-type="text"
                      edit={lastWorkflow.action === 'reject' || workflow.length === 0 ? showEdit : false}
                      name="payment_of_funds"
                      placeholder="isi Payment of funds"
                      onChange={this._onFormChange}
                      value={
                          details[key] ? details[key].payment_of_funds : ''
                        }
                    />
                  </td>
                  <td>{data.desc}</td>
                  <td>
                   <input type="file" data-index={key} onChange={this.onFileChange} />
                  </td>

                  {showApprovalCol && (
                    <Input
                      noMargin
                      isNumber={false}
                      data-input-array
                      data-array-position={key}
                      data-input-type="text"
                      edit={lastWorkflow.action === 'reject' || workflow.length === 0 ? showEdit : false}
                      name="approval"
                      placeholder="Isi Persetujuan"
                      onChange={this._onFormChange}
                      value={
                          details[key] ? details[key].approval : ''
                        }
                    />
                  )}
                  { workflow.length === 0 && showEdit && (
                  <td>
                    <Button onClick={() => this._onDelete(key)} icon="icon-trash-o" />
                  </td>
                  )
                    }
                  { lastWorkflow.action === 'reject' && showEdit && (
                    <td>
                      <Button onClick={() => this._onDelete(key)} icon="icon-trash-o" />
                    </td>
                  )
                    }
                </tr>
              ))}
              <tr className="total">
                <td colSpan={7}>Total</td>
                {this.TotalRAPBS2(selectedBudgetDetails)}
                <td> {commaFormatted(form.value.amount)}</td>
                <td />
                <td />
                <td />
                <td />
                { showApprovalCol && (
                  <td />
                )}
              </tr>
            </tbody>
          </table>
          <div />
          <br />
          <div className="workflow_history">
            <h2>Workflow</h2>
            {_renderWorkflowHistory(workflow)}
          </div>
          <div className="buttons-wrapper">
            {this._renderButtons()}
          </div>
        </form>

      </div>
    );
  }
}
ManageMonthlyActivityActivity.propTypes = {
  handleGetAvailableBudgetDetailsRKBTema: PropTypes.func,
  handleTransferFund: PropTypes.func,
  handleAddFundRequest: PropTypes.func,
  handleGetFundRequestById: PropTypes.func,
  handleSubmitApproval: PropTypes.func,
  handleReject: PropTypes.func,
  handleDeleteFundRequestDetail: PropTypes.func,
  handleGetMonthOptions: PropTypes.func,
  handleMonthlyActivityPlantTema: PropTypes.func,
  history: PropTypes.object.isRequired,
  onSearchUnit: PropTypes.func,
  location: PropTypes.object.isRequired,
  month: PropTypes.object,
  tema: PropTypes.object,
  user: PropTypes.object,
  handleUploadFileRKB: PropTypes.object.isRequired,
};

ManageMonthlyActivityActivity.defaultProps = {
  handleGetAvailableBudgetDetailsRKBTema: () => {},
  handleTransferFund: () => {},
  handleAddFundRequest: () => {},
  handleGetFundRequestById: () => {},
  handleSubmitApproval: () => {},
  handleReject: () => {},
  handleDeleteFundRequestDetail: () => {},
  handleGetMonthOptions: () => {},
  onSearchUnit: () => {},
  handleMonthlyActivityPlantTema: () => {},
  month: {},
  tema: {},
  user: {},
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import budgeting from '../../assets/img/finance/anggaran.png';
import bookkeeping from '../../assets/img/bookkeeping/jurnal_bank.png';
import { map } from 'lodash';

export default class Student extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickPendaftaran = this.onClickPendaftaran.bind(this);
    this.onClickDataSiswa = this.onClickDataSiswa.bind(this);
    this.onClickLaporanKesiswaan = this.onClickLaporanKesiswaan.bind(this);
    this.onClickAbsensiSiswa = this.onClickAbsensiSiswa.bind(this);
    this.onClickDispliner = this.onClickDispliner.bind(this);
    this.onClickEkstrakulikuler = this.onClickEkstrakulikuler.bind(this);
    this.onClickBimbinganKonseling = this.onClickBimbinganKonseling.bind(this);
    
  }

  onClickPendaftaran() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/list-pendaftaran-siswa');
  }

  onClickDataSiswa() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/data-siswa');
  }

  onClickLaporanKesiswaan() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/laporan')
  }

  onClickAbsensiSiswa() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/absensi-siswa')
  }

  onClickDispliner() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/displiner')
  }

  onClickEkstrakulikuler() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/ekstrakulikuler')
  }

  onClickBimbinganKonseling() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/bimbingan-konseling/konseling-siswa')
  }


  render() {
    const { user } = this.props;
    const { permissions } = user.user_group;
    return (
      <div>
      {
      map (permissions, (item) => {

        if (item == "view_form_TU") {
          return (
            <div className="akademi">
              <div className="akademi__list">
                <button onClick={this.onClickPendaftaran}>
                  <img src={budgeting} alt="DataKelas" />
                  <h3>List Pendaftaran Siswa</h3>
                </button>
              </div>
              <div className="akademi__list">
                <button onClick={this.onClickDataSiswa}>
                  <img src={budgeting} alt="DataKelas" />
                  <h3>Data Siswa</h3>
                </button>
              </div>
              
              <div className="akademi__list">
                <button onClick={this.onClickAbsensiSiswa}>
                  <img src={budgeting} alt="AbsensiSiswa" />
                  <h3>Absensi Siswa</h3>
                </button>
              </div>
              <div className="akademi__list">
                <button onClick={this.onClickDispliner}>
                  <img src={budgeting} alt="displiner" />
                  <h3>Displiner</h3>
                </button>
              </div>
              <div className="akademi__list">
                <button onClick={this.onClickEkstrakulikuler}>
                  <img src={budgeting} alt="ekstrakulikuler" />
                  <h3>Ekstrakulikuler</h3>
                </button>
              </div>
              {/* <div className="akademi__list">
                <button onClick={this.onClickBimbinganKonseling}>
                  <img src={budgeting} alt="bimbingankonseling" />
                  <h3>Bimbingan Konseling</h3>
                </button>
              </div> */}
              <div className="akademi__list">
                <button onClick={this.onClickLaporanKesiswaan}>
                  <img src={budgeting} alt="LaporanKesiswaan" />
                  <h3>Laporan Kesiswaan</h3>
                </button>
              </div>
            
            </div>
            )
          }

      if (item == "view_ekskul_guru") {
        return(
          <div className="akademi">
          {/* <div className="akademi__list">
            <button onClick={this.onClickPendaftaran}>
              <img src={budgeting} alt="DataKelas" />
              <h3>List Pendaftaran Siswa</h3>
            </button>
          </div> */}
          <div className="akademi__list">
            <button onClick={this.onClickDataSiswa}>
              <img src={budgeting} alt="DataKelas" />
              <h3>Data Siswa</h3>
            </button>
          </div>
          
          <div className="akademi__list">
            <button onClick={this.onClickAbsensiSiswa}>
              <img src={budgeting} alt="AbsensiSiswa" />
              <h3>Absensi Siswa</h3>
            </button>
          </div>
          <div className="akademi__list">
            <button onClick={this.onClickDispliner}>
              <img src={budgeting} alt="displiner" />
              <h3>Displiner</h3>
            </button>
          </div>
          <div className="akademi__list">
            <button onClick={this.onClickEkstrakulikuler}>
              <img src={budgeting} alt="ekstrakulikuler" />
              <h3>Ekstrakulikuler</h3>
            </button>
          </div>
          <div className="akademi__list">
            <button onClick={this.onClickBimbinganKonseling}>
              <img src={budgeting} alt="bimbingankonseling" />
              <h3>Bimbingan Konseling</h3>
            </button>
          </div>
          <div className="akademi__list">
            <button onClick={this.onClickLaporanKesiswaan}>
              <img src={budgeting} alt="LaporanKesiswaan" />
              <h3>Laporan Kesiswaan</h3>
            </button>
          </div>
        
        </div>
        )
        }
      })
    }
  </div>
    );
  }
}
Student.propTypes = {
  history: PropTypes.object.isRequired,
};

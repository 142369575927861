import { connect } from 'react-redux';
import StaffList from '../../views/Personnel/StaffList.personnel.view';
import { getStaff, deleteStaff } from '../../states/thunks/personnel.thunk';

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetStaff: payload => dispatch(getStaff(payload)),
    handleDeleteStaff: (payload, goBack) => dispatch(deleteStaff(payload, goBack)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StaffList);

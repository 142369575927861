import { connect } from 'react-redux';
import CreateInvoice from '../../../../views/Finance/Spp/CreateInvoice/CreateInvoice.view';
import { getAdjustmentJournal } from '../../../../states/thunks/finance.thunk';
import { getClasses, getNoInvoices } from '../../../../states/thunks/options.thunk';
import { listCreateInvoices } from '../../../../states/thunks/academics.thunk';

function mapStateToProps(state) {
  return {
    result: state.result,
    user: state.user || {},
  }
}
function mapDispatchToProps(dispatch) {
  return {
    handleGetClasses: payload => dispatch(getClasses(payload)),
    getNoInvoices: (payload) => dispatch(getNoInvoices(payload)),
    handleListCreateInvoices: (payload, goback) => dispatch(listCreateInvoices(payload, goback)),
   
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateInvoice);




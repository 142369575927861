import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise';
import rootReducer from './reducers';

const enhancers = [];
const { devToolsExtension } = window;
if (typeof devToolsExtension === 'function') {
  enhancers.push(devToolsExtension());
}

const enhancer = compose(applyMiddleware(thunk, promise), ...enhancers);

function initStore() {
  return createStore(rootReducer, {}, enhancer);
}

export default initStore;

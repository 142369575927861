import  RaportKBK from '../../../views/Kurikulum/Raport/RaportKBK.view';
import { connect } from 'react-redux';
import { getStudent } from '../../../states/thunks/student.thunk';
import { getClasses, getPeriodsOptions } from '../../../states/thunks/options.thunk';

function mapStateToProps(state){
    return{
        classes: state.options.classes
    }
}


function mapDispatchToProps(dispatch){ 
    return{
        handleGetClasses: payload => dispatch(getClasses(payload)),
        handleGetStudent: () => dispatch(getStudent()),
        getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps) (RaportKBK);
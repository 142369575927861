import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop } from "lodash";
import StudentReportFilter from "../../../components/Filter/StudentReport.filter.component";
import ContentTable from "../../../components/ContentTable/ContentTable.component";
import ListForm from "../../../components/ListForm/ListForm.component";
import { STUDENT_UNIT_2_FORM_FIELDS } from "../../../constants/student.constant";
import { DAFTAR_NILAI_FORM_FIELDS } from "../../../constants/kurikulum/ulangan.constant";
import { validateRequiredFields } from "../../../utils/validation.util";
import {
  commaFormatted,
  normalizeAmount,
} from "../../../utils/transformer.util";
import { Button } from "../../../components/base/index";
import LembarHarianForm from "./components/LembarHarianForm.component";
import DaftarNilaiPraktekForm from "./components/DaftarNilaiPraktekForm.component";
import NilaiAfekTable from "./components/NilaiAfekTable.component";
import NilaiKognitifForm from "./components/NilaiKognitifForm.component";
import LembarHarianForm2 from "./components/LembarHarianForm2.component";

export default class PengisianDaftarNilai extends PureComponent {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._onClickNext = this._onClickNext.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._setForm = this._setForm.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);
    const { user } = this.props;
    const { organizations_id } = user;
    this.state = {
      form: {
        value: {
          organizations_id: organizations_id,
        },
        error: {},
      },
      param: {},
    };
  }

  componentDidMount() {
    const { location, getLevels, getclassrooms, user } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";
    const paramTypes = ["classrooms", "levels"];
    console.log(state)
    if (isEdit && id) {
      // this.setState({students_id:id})
      // this.setState(prevState => ({
      //   form: {
      //     value: {
      //       ...prevState.form.value,
      //       students_id:id,
      //       organizations_id: 4,
      //     },
      //     error: {
      //       ...prevState.form.error,
      //     }
      //   }
      // }));
      this._setForm(id, data);
    }
    console.log(this.state);
    paramTypes.forEach((type) => {
      // this._getParamOptionsAcademics(type);
    });
    // this._getStudentOptions();
    // this._getFolderOptions();

    // this._getclassrooms();
    // getclassrooms();
    // getLevels();
    // if (id) {
    //   this.setState({id:id})
    //   this._handleLoadData(id)
    // }

    this._getPeriodsOptions();
  }

  async onEdit() {
    const { history, handleEditDaftarNilai } = this.props;
    const { form } = this.state;
    const { value } = form;
    let canSaveA = false;
    let canSaveB = false;
    const dataTabel = value.pelajaran;
    const dataForm = form.value;
    let a;
    await handleEditDaftarNilai(value);
    history.push(
      "/dashboard/kurikulum/ulangan/pengisian-lembar-observasi-harian"
    );
    // }
  }

  async _getPeriodsOptions() {
    const { getPeriodsOptions } = this.props;
    const res = await getPeriodsOptions();
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  async _setForm(id, data = {}) {
    const { handleGetDaftarNilai, user } = this.props;
    const { organizations_id } = user;
    try {
      const payload = await handleGetDaftarNilai({ id });
      console.log(id);
      this.setState({
        form: {
          value: {
            id: payload.id,
            students_id: payload.students_id,
            assessment_date: payload.assessment_date,
            location: payload.location,
            academics_type: payload.academics_type,
            semester: payload.semester,
            content_type: payload.content_type,
            indicator_value: payload.indicator_value,
            observation_result: payload.observation_result,
            expectation: payload.expectation,
            indicator_value_kognitif: payload.indicator_value_kognitif,
            observation_result_kognitif: payload.observation_result_kognitif,
            teacher_notes: payload.teacher_notes,
            organizations_id: organizations_id,
            period: payload.period,
            indicator_value_social: payload.indicator_value_social,
            observation_result_social: payload.observation_result_social,

            indicator_value_language: payload.indicator_value_language,
            observation_result_language: payload.observation_result_language,

            indicator_value_fismot: payload.indicator_value_fismot,
            observation_result_fismot: payload.observation_result_fismot,

            indicator_value_art: payload.indicator_value_art,
            observation_result_art: payload.observation_result_art,
          },
          error: {
            // details: [],
          },
        },
      });
    } catch (err) {
      // err action
    }
  }

  async onSubmit() {
    const { history, handleSaveDaftarNilai } = this.props;
    const { form } = this.state;
    const { value } = form;
    let canSaveA = false;
    const dataForm = form.value;
    await handleSaveDaftarNilai(value);
    history.push("/dashboard/kurikulum/ulangan/pengisian-observasi-harian");
    history.go(0);
  }

  _onFormChange(event) {
    const { name, value, dataset, files } = event.target;
    const { inputType = "text" } = dataset;
    this.setState((prevState) => {
      let formattedValue = value;
      if (inputType === "number") {
        formattedValue = commaFormatted(value);
      }
      if (inputType === "file") {
        [formattedValue] = files;
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            [name]: formattedValue,
          },
          error: {
            ...prevState.form.error,
            [name]: "",
          },
        },
      };
    });
  }

  _onClickNext() {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/laporan/daftar-siswa-kelas-unit");
  }

  render() {
    const { location, getLevels, getclassrooms, user } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";
    const { form, param } = this.state;
    console.log(data);
    return (
      <div className="student-list">
        <div className="budget__title">
          <h1>Pengisian Lembar Daftar Nilai / Report </h1>
          <hr></hr>
        </div>
        <form onSubmit={this._onSubmit}>
          <div className="result__content">
            <LembarHarianForm
              param={param}
              form={form}
              data={data}
              onFormChange={this._onFormChange}
            />
          </div>
          <br></br>
          <br></br>

          <LembarHarianForm2 form={form} onFormChange={this._onFormChange} />

          <div className="manage-registration__footer">
            {this.state.id ? (
              <Button type="button" title="Update" onClick={this.onEdit} />
            ) : (
              <Button type="button" title="Simpan" onClick={this.onSubmit} />
            )}
          </div>
        </form>
      </div>
    );
  }
}
PengisianDaftarNilai.propTypes = {
  handleGetKlapper: PropTypes.func,
  handleDeleteKlapper: PropTypes.func,
};
PengisianDaftarNilai.defaultProps = {
  handleGetKlapper: noop,
  handleDeleteKlapper: noop,
};

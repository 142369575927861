import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop, map, isEqual, has, find } from 'lodash';
import StudentReportFilter from '../../components/Filter/StudentReport.filter.component';
import ListForm from '../../components/ListForm/ListForm.component';
import ContentTable from '../../components/ContentTable/ContentTable.component';
import { ABSENSI_HARIAN } from '../../constants/student.constant';
import { ABSENSI_HARIAN_TABLE } from '../../constants/student.constant';
import { validateRequiredFields } from '../../utils/validation.util';
import { commaFormatted, normalizeAmount, formatData } from '../../utils/transformer.util';
import { Button, Input, Checkbox, Pagination, Select } from '../../components/base/index';
import { TAMBAH_TEMPAT_KONSELING_LIST_TABLE_FIELDS } from '../../constants/kurikulum/kurikulum.constant';
import language from '../../languages';

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class TambahTempatKonseling extends Component {
        constructor(props) {
          super(props);
          this._onClickUpload = this._onClickUpload.bind(this);
          this._onFormChange = this._onFormChange.bind(this);
          this.onSubmit = this.onSubmit.bind(this);
          // this.isMount = false;
          this._onSearchContent = this._onSearchContent.bind(this);
          this._onClickNext = this._onClickNext.bind(this);
          this._onClickAdd = this._onClickAdd.bind(this);
          this.handleGetData = this.handleGetData.bind(this);
          this.onChangePage = this.onChangePage.bind(this);
          this._onCheckDetail = this._onCheckDetail.bind(this);
          this._onChangeFilter = this._onChangeFilter.bind(this);
          this._onChangeListAmount = this._onChangeListAmount.bind(this);
          this._setForm = this._setForm.bind(this);
          this.onClickEdit = this.onClickEdit.bind(this);
          this._renderButtons = this._renderButtons.bind(this);
          this.onEdit = this.onEdit.bind(this);
          const {user} = this.props;
          const { organizations_id } = user;
          this.state = {
            page: 1,
            selected: {},
            content: initialContent,
            unchecked: {},
            filters:{},
            list:[],
            form: {
              value: {
                organizations_id,
                absensiharian: [
                  {
                    students_id: '',
                    period: '',
                    classes_id: '',
                    date: '',
                    status: '',
                    description: '',
                  }
                ],
              },
              error: {
                classes: '',
                levels: '',
                absensiharian:''
              },
            },
            param:{},
            
          };
          this._handleFormChange = this._handleFormChange.bind(this);
          this._onSearch = this._onSearch.bind(this);
          this._getParamOptions = this._getParamOptions.bind(this);
        }
      
        componentDidMount() {
          const { location } = this.props;
          const { state = {} } = location;
          const {isEdit =  false, data} = location.state;
          const { id } = state.data || '';
         
          const paramTypes = ['classrooms', 'levels', 'classes'];
          if (isEdit && id) {
            this.setState({id:id})
            this._setForm(data.id);
          }
          
          this.handleGetData();
          // if (id) {
          //   this.setState({id:id})
          //   this._handleLoadData(id)
          // }
          
  
        }
      
        componentDidUpdate(prevProps) {
          const { location } = this.props;
          const { state = {} } = location;
          const {isEdit =  false, data} = state;
          const { id } = state.data || '';
         console.log(state)
          if ((!isEdit || !id) && !isEqual(prevProps, this.props)) {
            this._onSearchContent({ page: 1 });
          }
        }

        async _setForm(id) {
          const { handleGetCounselingPlace } = this.props;
          try {
            const payload = await handleGetCounselingPlace({ id });
            this.setState({
              form: {
                value: {
                  id: payload.id,
                  code_place: payload.code_place,
                  place_name:payload.place_name,
                  description: payload.description,
                  folder_active: payload.folder_active,
                  
                },
                error: {
                  // details: [],
                },
              },
            });
          } catch (err) {
            // err action
          }
        }
  

        async _onSearchContent(params = {}) {
          const { filters } = this.state;
          
            try {
              const { handleListStudentAttendance, user } = this.props;
              const { units_id, workingUnit, id, user_group, organizations_id } = user;
              const result = await handleListStudentAttendance({ ...params, organizations_id, filters });
              // if (this.isMount) {
                if (result) {
                  result.forEach(item => {
                    if (!item.description) {
                      item.description = '';
                    }
                  });
                } 
                this.setState(prevState => ({
                  ...prevState,
                  form: {
                    ...prevState.form,
                    value: {
                      ...prevState.form.value,
                      absensiharian: result,
                    }
                  }
                }));
                
              // }
            } catch (err) {
              // if (this.isMount) {
                this.setState(prevState => ({
                  ...prevState,
                  form: {
                    ...prevState.form,
                    value: {
                      ...prevState.form.value,
                      absensiharian: [],
                    }
                  }
                }));
                
            }
            // }
        }

        _onChangeListAmount(event) {
          const { keywords } = this.state;
          this.setState({
            listAmount: Number(event.target.value),
          });
          this._onSearchContent({ limit: event.target.value, keywords, page: 1 });
        }

        async onEdit() {
          const { history, handleEditCounselingPlace } = this.props;
          const { form } = this.state;
          const { value } = form;
          const res = await handleEditCounselingPlace(value);        
          history.push('/dashboard/kesiswaan/bimbingan-konseling/konseling-siswa');
        }

        _onChangeFilter(e) {
          const { user } = this.props;
          const { organizations_id } = user;
          const { target } = e;
          const { value, name } = target;
          this.setState(prevState => ({
            ...prevState,
            filters: {
              ...prevState.filters,
              [name]: value,
            },
          }), () =>{
            if (name === 'levels_id') {
              this._getParamOptions( 'classes',  { levels_id: value, organizations_id } );
            } this._onSearchContent()});
        
        }
        _onChangePage(page) {
          const { listAmount, keywords } = this.state;
          const offset = listAmount * (page - 1);
          this.handleGetData({
            limit: listAmount,
            keywords,
            offset,
            page,
          });
        }


        async onSubmit() {
          const { history, handleSaveCounselingPlace } = this.props;
          const { form } = this.state;
          const { value } = form;        
          let canSaveA = false;
          const dataForm = form.value;
          // if(dataForm.folder_code && dataForm.folder_name && dataForm.folder_description){
          //   canSaveA = true;
          // } else {
          //   canSaveA = false;
          // }
          // if (canSaveA == false){
          //   alert("Harap lengkapi data form. Bagian yang harus diisi ditandai dengan tanda bintang (*).");
          // } else {
            await handleSaveCounselingPlace(value);
            history.push('/dashboard/kesiswaan/bimbingan-konseling/konseling-siswa');
          // }
        }
        async handleGetData(payload = {}) {
          const { handleListCounselingPlace, user } = this.props;
          const { units_id, workingUnit, id, user_group, organizations_id } = user;
          let unit_id = units_id;
      
          if (unit_id === null && workingUnit && workingUnit.id) {
            unit_id = workingUnit.id;
          }
          console.log(res)
          // if(user_group.id === 2){
            const res = await handleListCounselingPlace({...payload, user_id: id, organizations_id});
      
            this.setState({
              list: res,
            });
            console.log(this.state.list)
          }
        async onClickDelete(id) {
          const { handleDeleteCounselingPlace } = this.props;
          const res = await handleDeleteCounselingPlace(id);
          if(res){
            this.handleGetData();
          }
        }

        _handleFormChange(event) {
            const {
              name,
              value,
            } = event.target;
            this.setState(prevState => ({
              form: {
                value: {
                  ...prevState.form.value,
                  [name]: value,
                },
                error: {
                  ...prevState.form.error,
                  [name]: '',
                },
              },
            }));
          }
        
          _onSearch() {
            const { form } = this.state;
            const error = validateRequiredFields(form.value, ['year']);
            this.setState(prevState => ({
              form: {
                value: prevState.form.value,
                error,
              },
            }));
            if (isEmpty(error)) {
              this.setState({ filtered: true });
            }
          }

          _renderButtons(data) {
            const { user } = this.props;
            const button = {};
            
            return (
              <td>
                <div className="table__actions">
                 
                  <Button
                    onClick={() => this.onClickEdit(data)}
                    title='Edit'
                  />
                  <Button
                    onClick={() => this.onClickDelete(data)}
                    title='Delete'
                  />
                </div>
              </td>
            );
          }

        _onFormChange(event) {
          const { user } = this.props;
          const { organizations_id } = user;
          const {
            name,
            value,
            dataset,
            checked,
            type,
          } = event.target;
          const {
            inputType = 'text', inputArray = false, arrayPosition = 0,
            fieldName,
          } = dataset;
          this.setState((prevState) => {
            let newList = [];
            let newListError = [];
            let formattedValue = value;
      
            if (inputType === 'number') {
              formattedValue = normalizeAmount(value);
            }
            if (inputArray) {
              if (type === 'checkbox') {
                formattedValue = checked ? value : '';
              }
              newList = prevState.form.value[fieldName];
              newListError = prevState.form.error[fieldName] || [];
              newList[arrayPosition][name] = formattedValue;
              if (name === 'code_of_account') {
                newList[arrayPosition].isCredit = value.type;
              }
              if (!isEmpty(newListError[arrayPosition])) {
                newListError[arrayPosition][name] = '';
              }
            }
            return {
              form: {
                value: {
                  ...prevState.form.value,
                  ...(inputArray
                    ? { [fieldName]: newList }
                    : { [name]: formattedValue }),
                },
                error: {
                  ...prevState.form.error,
                  ...(inputArray
                    ? { [fieldName]: newListError }
                    : { [name]: '' }),
                },
              },
            };
          }, () => {
            if (name === 'levels_id') {
              this._getParamOptions( 'classes', { filters: { levels_id: value, organizations_id }} );
            }
            // if (name === 'classes_id') {
            //   this._getParamOptions( 'classes', { filters: { levels_id: value, organizations_id: 4 }} );
            // }
            // if (name === 'classes') {
            //   this._getStudentOptions({ filters: { classes_id: value.map(item => item.value)}} );
            // }
          });
        }
        _onClickUpload() {
          const { history } = this.props;
          history.push('/dashboard/kesiswaan/absensi-siswa/upload-absensi-harian');
        }
      
        _onClickAdd(data) {
          const { form } = this.state;
          const { plafon } = form.value;
          
          plafon.push({
            tipePlafon: '',
            // divisi: '',
            nominal: 0,
            //period:'',
          });
          
          this.setState(prevState => ({
            form: {
              value: {
                ...prevState.form.value,
                plafon,
              },
              error: {
                ...prevState.form.error,
              }
            }
          }));
          console.log(plafon)
        }
      
        onChangePage(page) {
          const { listAmount, keywords } = this.state;
          const offset = listAmount * (page - 1);
          this.handleGetData({
            limit: listAmount,
            keywords,
            offset,
            page,
          });
        }
      
        

        _onCheckDetail(event) {
          const { target } = event;
          const { value, checked } = target;
          const { budgets, selectedBudgetDetails } = this.props;
          const { selected, unchecked } = this.state;
          let newState = { ...this.state };
      
          newState = this._setNewState(newState, checked, value);
      
          this.setState({
            ...newState
          });
        }
      
        onClickEdit(val) {
          const { history } = this.props;
          history.push('/dashboard/kesiswaan/tambah-tempat-konseling', { data: val, isEdit: true });
          history.go(0)
        }
       
        
        _onClickNext() {
            window.scrollTo(0, 0);
            this.setState(prevState => ({
              page: prevState.page + 1,
            }));
          }
          async _getParamOptions(type, filters={}) {
            const {getParamOptions, user} = this.props;
            const { organizations_id } = user;
  
            if (type === 'classes' || type === 'classrooms') {
              filters.organizations_id = organizations_id;
            } else {
              delete filters.organizations_id;
            }
            const res = await getParamOptions(filters, type);
            this.setState(prevState => ({
              ...prevState,
              param: {
                ...prevState.param,
                [type]: res,
              },
            }));
          }
      
        render() {
          const {param, form,
                 page, list = {}, selected, checkedAll, content, filters, listAmount } = this.state;
          const {absensiharian} = form.value;
          const {
            place_name = '',
            description='',
            code_place='',
          } = filters;
          const {  location } = this.props;
          const loadingProps = { show: content.loading };
          const academic_year = [
            { label: '2019', value: '1'},
            { label: '2020', value: '2'},
            { label: '2021', value: '3'},
          ]
          const folder_active = [
            { label: 'Y', value: 'y'},
            { label: 'N', value: 'n'},
          ]
          return (
            <div className="manage-registration">
                
               <div className="kode-matpel__custom-form">
        <div className="kode-matpel__custom-form-row">
        <div className="kode-matpel__custom-form-row__field">
       
             <Input
                label = "Kode Tempat"
                name="code_place"
                value={form.value.code_place}
                onChange={this._onFormChange}
              />
        </div>
         
        
        <div className="kode-matpel__custom-form-column__field">
      
          
             <Input
                label = "Nama Tempat"
                name="place_name"
                value={form.value.place_name}
                onChange={this._onFormChange}
              />
       
         
        </div>
        </div>
        <div className="kode-matpel__custom-form-row">
        <div className="kode-matpel__custom-form-row__field">
                 
             <Input
                label = "Description"
                name="description"
                value={form.value.description}
                onChange={this._onFormChange}
              />
        </div>
        
        <div className="kode-matpel__custom-form-column__field">
            
             <Select
                placeholder="Pilihan"
                label = "Folder Active"
                name="folder_active"
                data={folder_active}
                value={form.value.folder_active}
                onChange={this._onFormChange}
              />
       
         
        </div>
       
        </div>

       </div>
       <div className="student-list__header">
       <div className="student-list__button">
        
       {this.state.id?
              <Button
                type="button"
                title="Update"
                onClick={this.onEdit}
              />:
              <Button
                type="button"
                title="Simpan"
                onClick={this.onSubmit}
              />}
          </div>
          </div>
                  

       <div className="fund-request__content">
          <table className="table">
            <thead>
              <tr>
                {
                    map(TAMBAH_TEMPAT_KONSELING_LIST_TABLE_FIELDS.label, (field, idx) => (
                      <th key={`table_th_${idx}`}>{field}</th>
                   ))
                  }
              </tr>
            </thead>
            <tbody>
              {
                  map(list.result, (data, idx) => (
                    <tr key={`budget_row_${idx}`}>
                      {
                      map(TAMBAH_TEMPAT_KONSELING_LIST_TABLE_FIELDS.value, (field, fieldIdx) => {
                        if (fieldIdx === 0) {
                          return (
                            <td key={`table_index_${fieldIdx}_${idx}`}>{idx + 1}</td>
                          );
                        }
                        if (field.type === 'link') {
                          return (
                            <td key={`table_${fieldIdx}_${idx}`}><a href={"https://"+data.hyperlink} target="_blank" rel="noopener noreferrer">{data.hyperlink}</a></td>
                              // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                          );
                        }
                        return (
                          <td className={(field.type === 'number') ? 'nominal' : ''} key={`table_${fieldIdx}_${idx}`}>
                            {formatData(data, field)}
                          </td>
                        );
                      })
                      }
                      {this._renderButtons(data)}
                    </tr>
                  ))
                }
            </tbody>
          </table>
        </div>
    <div className="user-management__table-footer">
  <p className="user-management__page-info">
    {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${list.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${list.totalPage}`}
  </p>
  <Pagination
    totalPage={list.totalPage}
    currentPage={list.currentPage}
    onClick={this.onChangePage}
  />
</div>
          <div className="student-list__header">
          
      
          </div>
              
            </div>
          );
        }
}
TambahTempatKonseling.propTypes = {
  getParamOptions: PropTypes.func,  
  history: PropTypes.object.isRequired,
  handleGetCounselingPlace: PropTypes.func,
  // handleGetLevels: PropTypes.func,
};
TambahTempatKonseling.defaultProps = {
  getParamOptions: noop,
  handleGetCounselingPlace: noop,
  // handleGetLevels: noop,
};
import UploadNilai from '../../../views/Kurikulum/RencanaNilai/UploadNilai.view.js';
import { connect } from 'react-redux';
import { getStudent } from '../../../states/thunks/student.thunk';
import { getClasses, getPeriodsOptions, getParamOptions, getSubjectOptions, getParamOptionsAcademics, getRppOptions, getClassesOptions } from '../../../states/thunks/options.thunk';

function mapStateToProps(state) {
    return {
        user: state.user || {},
    };
}

function mapDispatchToProps(dispatch){
    return{
        handleGetStudent: () => dispatch(getStudent()),
        handleGetClasses: payload => dispatch(getClasses(payload)),
        getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
        getClassesOptions: (payload) => dispatch(getClassesOptions(payload)),
        getSubjectOptions: (payload) => dispatch(getSubjectOptions(payload)),
        getRppOptions: (payload) => dispatch(getRppOptions(payload)),
        getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
        
    };
}
export default connect(mapStateToProps, mapDispatchToProps) (UploadNilai);
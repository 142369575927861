import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop, isEqual, map} from 'lodash';
import StudentReportFilter from '../../../components/Filter/StudentReport.filter.component';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted, normalizeAmount, formatData } from '../../../utils/transformer.util';
import { Button, Select, Pagination,SearchSelect , Input} from '../../../components/base/index';
import language from '../../../languages';
import { NILAI_RAPORT_LIST_TABLE_FIELDS } from '../../../constants/kurikulum/kurikulum.constant';

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class NilaiRaport extends Component {
  constructor(props) {
    super(props);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._onClickNext = this._onClickNext.bind(this);
    this.onClickAddGrafik = this.onClickAddGrafik.bind(this);
    this._renderButtons = this._renderButtons.bind(this);
    this._getStudentOptions = this._getStudentOptions.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onChangePage = this._onChangePage.bind(this);
    this._onChangeListAmount = this._onChangeListAmount.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this.onClickPrint = this.onClickPrint.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    //this.deletePlafon = this.deletePlafon.bind(this);
    this._getPeriodsOptions= this._getPeriodsOptions.bind(this);
    
    const {user} = this.props;
    const { school_unit } = user;
    const { levels_id } = school_unit;
    this.state = {
      page: 1,
      selected: {},
      content: initialContent,
      unchecked: {},
      filters:{},
      list:[],
      form: {
        value: {
          levels_id,
          classrooms: [],
          levels: [],
          raportsiswa: [],
          classrooms_id: '',
        },
        error: {
          classrooms: '',
          levels: '',
          raportsiswa: [],

        },
      },
      param:{},
      filtered: false,
    };
    this._handleFormChange = this._handleFormChange.bind(this);
    this._onSearch = this._onSearch.bind(this);
  }

  componentDidMount() {
    const { handleGetCodeOfAccount, location, handleGetclassrooms, handleGetLevels } = this.props;
    const paramTypes = ['classrooms','levels', 'classrooms'];
    const { content } = this.state;
    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
      this._getStudentOptions();
    });
    if (isEmpty(content.list)) {
      this._onSearchContent({ page: 1 });
    }
    handleGetclassrooms();
    handleGetLevels();
    this._getPeriodsOptions();       
        
  }

  // on formchange untuk form
  componentDidUpdate(prevProps) {

    if (!isEqual(prevProps, this.props)) {
      this._onSearchContent({ page: 1 });
    }
  }

  async _getPeriodsOptions() {
    const { getPeriodsOptions } = this.props;
    const res = await getPeriodsOptions();
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
    
  }
  
  _handleFormChange(event) {
    const {
      name,
      value,
    } = event.target;
    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          [name]: value,
        },
        error: {
          ...prevState.form.error,
          [name]: '',
        },
      },
    }));
  }

  async _getStudentOptions(filters={}) {
    const { getStudentOptions } = this.props;
    const res = await getStudentOptions(filters);
    // console.log(res)
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        student: res,
      },
    }));
    
  }

  _onSearch() {
    const { form } = this.state;
    const error = validateRequiredFields(form.value, ['year']);
    this.setState(prevState => ({
      form: {
        value: prevState.form.value,
        error,
      },
    }));
    if (isEmpty(error)) {
      this.setState({ filtered: true });
    }
  }
    
  async _onSearchContent(params = {}) {
    const { filters, form } = this.state;
    const { value } = form;
    const { subject_id } = value;
    this.setState({
      content: initialContent,
    }, async () => {
      try {
        const { handleListRaportSiswa , handleListReportStudent, handleListAchievement, user} = this.props;
        const { workingUnit, id, user_group, organizations_id, school_unit } = user;
        const { units_id } = school_unit;
          const result = await handleListRaportSiswa({ ...params, organizations_id, units_id, filters });
        // }
        this.setState(prevState => ({
          ...prevState,
          form: {
            ...prevState.form,
            value: {
              ...prevState.form.value,
              raportsiswa: result,
            }
          }
        }));
      } catch (err) {
        // if (this.isMount) {
          this.setState(prevState => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                raportsiswa: [],
              }
            }
          }));
          
      }
      // }
    });
  }

  _onChangeListAmount(event) {
    const { keywords } = this.state;
    this.setState({
      listAmount: Number(event.target.value),
    });
    this._onSearchContent({ limit: event.target.value, keywords, page: 1 });
  }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    this.setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        [name]: value,
      },
    }), () => this._onSearchContent());
  }
  
  _onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    // this.handleGetData({
    this._onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }


  // on formchange untuk tabel
  _onFormChange(event) {
    const {
      name,
      value,
      dataset,
      checked,
      type,
    } = event.target;
    const {
      inputType = 'text', inputArray = false, arrayPosition = 0,
      fieldName,
    } = dataset;
    const { user } = this.props;
    const { organizations_id } = user;
    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        if (type === 'checkbox') {
          formattedValue = checked ? value : '';
        }
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName] || [];
        newList[arrayPosition][name] = formattedValue;
        if (name === 'code_of_account') {
          newList[arrayPosition].isCredit = value.type;
        }
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = '';
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray
              ? { [fieldName]: newListError }
              : { [name]: '' }),
          },
        },
      };
    }, () => {
      if (name === 'levels_id') {
        this._getParamOptionsAcademics( 'classrooms', {levels_id: value, organizations_id });
      }
      if (name === 'classrooms_id' && !inputArray ) {
        this._setClassrooms(value)
      }
      this._onSearchContent();
    });
  }

  onSubmit(e) {
    e.preventDefault();
    const {history} = this.props;
    history.push('/dashboard/kesiswaan/absensi-siswa/absensi-rekap/cetak-absensi-rekap');
  }

  onClickAddGrafik() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/absensi-siswa/absensi-rekap/grafik', { isEdit: true });
  }
  
  _onClickNext() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/laporan/cetak-laporan-8355');
  }

  onClickEdit(val) {
    const { history } = this.props;
    // history.push('/dashboard/kesiswaan/absensi-siswa/absensi-harian', { data: val, isEdit: true });
    history.push('/dashboard/kurikulum/raport/daftar-nilai-raport/edit', { data: val, isEdit: true });
  }

  onClickPrint(val) {
    const { history } = this.props;
    // history.push('/dashboard/kesiswaan/absensi-siswa/absensi-harian', { data: val, isEdit: true });
    history.push('/dashboard/kurikulum/raport/daftar-nilai-raport/cetak', { data: val, isEdit: true});
  }

  _renderButtons(data) {
    const { user } = this.props;
    const { user_group } = user;
    const { permissions = [] } = user_group;

    return (
      <td>
        <div className="table__actions">
          <Button
            onClick={() => this.onClickEdit(data)}
            title='Edit'
          />
          <Button
            onClick={() => this.onClickPrint(data)}
            title='Print'
          />
        </div>
        
      </td>
    );
  }

  async _getParamOptionsAcademics(type, filters={}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;

    if (type === 'classrooms' || type === 'classrooms') {
      filters.organizations_id = organizations_id;
      filters.levels_id = levels_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getParamOptions(type, filters={}) {
    const {getParamOptions, user } = this.props;
    const { organizations_id } = user;
    if (type === 'classrooms' || type === 'classrooms') {
      filters.organizations_id = organizations_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptions(filters, type);
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }
      
  render() {
    
    const {param, form,
           page, list = {},
           selected, checkedAll, content, 
           filters, listAmount } = this.state;
    const {raportsiswa} = form.value;
    const {
      classrooms_id = '',
      semester = '',
      period = '',
      students_id = '',
      date='',
      date_to = '',
    } = filters;
    const { classrooms = {}, levels = {} } = this.props;
    const academic_year = [
      { label: '2019', value: '2019'},
      { label: '2020', value: '2020'},
      { label: '2021', value: '2021'},
    ]
    const semester_data = [
      { label: 'Ganjil', value: 'ganjil'},
      { label: 'Genap', value: 'genap'},
    ]
    return (
      <div className="manage-registration">
        <form onSubmit={this.onSubmit}>
      
          <div className="kode-matpel__custom-form">
              <div className="nilai-raport__custom-form-row">
                <div className="nilai-raport__custom-form-row__field">
                  <Select
                    label="Tahun Ajaran"
                    name="period"
                    onChange={this._onFormChange}
                    placeholder="Pilihan"
                    data={param.periods}
                    value={form.value.period}
                    error={form.error.period || ''}
                  />
                </div>
                <div className="nilai-raport__custom-form-column__field">
                  <Select
                    type="text"
                    name="levels_id"
                    label="Unit"
                    disabled
                    placeholder="Pilih Unit"
                    data={param.levels}
                    value={form.value.levels_id}
                    onChange={this._onFormChange}
                    error={form.error.levels_id || ''}
                  />
                </div>
              </div>

              <div className="nilai-raport__custom-form-row">
                <div className="nilai-raport__custom-form-row__field">
                  <Select
                    name="semester"
                    label="Semester"
                    placeholder={'Pilihan'}
                    onChange={this._onChangeFilter}
                    data={semester_data}
                    value={semester}
                    error={form.error.semester || ''}
                  />
                </div>
              
                <div className="nilai-raport__custom-form-column__field">
                  <Select
                    type="text"
                    name="classrooms_id"
                    label="Kelas"
                    placeholder="Pilih Kelas"
                    data={param.classrooms}
                    onChange={this._onChangeFilter}
                    value={classrooms_id}
                  />
                </div>
              </div>
            </div>

            <div className="manage-registration">
              <div className="fund-request__content">
                <table className="table" >
                  <thead>
                    <tr>
                      {
                          map(NILAI_RAPORT_LIST_TABLE_FIELDS.label, (field, idx) => (
                            <th key={`table_th_${idx}`}>{field}</th>
                          ))
                        }
                    </tr>
                  </thead>
                  <tbody>
                  {
                      map(raportsiswa, (list, idx) => (
                        <tr key={`budget_row_${idx}`}>
                          {
                          map(NILAI_RAPORT_LIST_TABLE_FIELDS.value, (field, fieldIdx) => {
                            if (fieldIdx === 0) {
                              return (
                                <td key={`table_index_${fieldIdx}_${idx}`}>{idx + 1}</td>
                              );
                            }
                            return (
                              <td className={(field.type === 'number') ? 'nominal' : ''} key={`table_${fieldIdx}_${idx}`}>
                                {formatData(list, field)}
                              </td>
                            );
                          })
                          }
                          {this._renderButtons(list)}
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
        </form>
      </div>
    );
  }
}

NilaiRaport.propTypes = {
  handleGetLevels: PropTypes.func,
  handleGetclassrooms: PropTypes.func,
  history: PropTypes.object.isRequired,
  getStudentOptions: PropTypes.func,
};
NilaiRaport.defaultProps = {
  handleGetLevels: noop,  
  handleGetclassrooms: noop,
  getStudentOptions: noop,
};
import { connect } from 'react-redux';
import FundRequest from '../../../../views/Finance/Budgeting/FundRequest/FundRequest.finance.view';
import { getFundRequest, deleteFundRequest } from '../../../../states/thunks/finance.thunk';

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetFundRequest: (payload, unit_id) => dispatch(getFundRequest(payload, unit_id)),
    handleDelete: (payload) => dispatch(deleteFundRequest(payload)),
    handleGet: (payload) => dispatch(getFundRequest(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FundRequest);

import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea } from '../../../components/base/index';
import { GENDER } from '../../../variables/common.variable';


export default function BuatPrestasiForm(props) {
  const { onFormChange, form, param } = props;
  const include_class = [{ label : 'Ya', value : 'y'},
                    { label : 'Tidak', value : 'n'}];
  const include_ekskul = [{ label : 'Ya', value : 'y'},
                    { label : 'Tidak', value : 'n'}];
  return (
    <div className="buat-pelanggaran__custom-form">
      <div className="buat-pelanggaran__custom-form-column">
        <div className="buat-pelanggaran__custom-form-column__field">
          <Input
           type="text"
           name="name"
           label="Nama Prestasi"
           onChange={onFormChange}
           value={form.value.name}
           error={form.error.name || ''}
          />
          
          
          <Select
            name="achievement_group_id"
            label="Group Prestasi"
            onChange={onFormChange}
            data={param.prm_achievement_group}
            placeholder={'Pilihan'}
            value={form.value.achievement_group_id}
            error={form.error.achievement_group_id || ''}
          />
         
          <Select
            name="achievement_in_class"
            label="Merupakan Prestasi Dikelas"
            onChange={onFormChange}
            data={include_class}
            placeholder={'Pilihan'}
            value={form.value.achievement_in_class}
            error={form.error.achievement_in_class || ''}
          />

`          <Select
            name="achievement_in_ekskul"
            label="Merupakan Ekstrakulikuler"
            onChange={onFormChange}
            data={include_ekskul}
            placeholder={'Pilihan'}
            value={form.value.achievement_in_ekskul}
            error={form.error.achievement_in_ekskul || ''}
          />
        <div className="buat-pelanggaran__content">
         <Input
            type="text"
            name="score"
            label="Score"
            onChange={onFormChange}
            value={form.value.score}
            error={form.error.score || ''}
          />
         
        
        <Input
            type="text"
            name="sort"
            label="Urutan"
            onChange={onFormChange}
            value={form.value.sort}
            error={form.error.sort || ''}
          />
           </div>
         </div>
      </div>
    </div>
  );
}
BuatPrestasiForm.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
BuatPrestasiForm.defaultProps = {
  onFormChange: noop,
};

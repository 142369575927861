import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, noop } from 'lodash';
import { Input, Button, Select } from '../../../../../components/base';

export default class BudgetUnit extends Component {
    constructor(props) {
        super(props);
        this._onFormChange = this._onFormChange.bind(this);
    }

    _onFormChange(event) {
        const { onChange } = this.props;
        onChange(event);
    }

    render () {
        const {
            list, onChange, form,
        } = this.props;
        const { value } = form;
        const { school_unit_code = null } = value;
        return(
            
            <Select
            data={list}
            data-field-name='school_unit_code'
            label='Unit'
            name='school_unit_code'
            onChange={this._onFormChange}
            value={school_unit_code}
            />
        );
    }
}

BudgetUnit.propTypes = {
    list: PropTypes.array.isRequired,
    error: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    school_unit_code :PropTypes.array,
    form: PropTypes.object,
}

BudgetUnit.defaultProps = {
    onChange: noop,
    school_unit_code :[],
    form: {},
}
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  isEmpty, noop, map, filter, dropRight,
} from 'lodash';
import {
  FileInput, Input, Select, SearchSelect, Checkbox, Textarea,
  Option,
} from '../../../../../components/base';
import {
  FINANCE_CASH_JOURNAL_FORM_FIELDS,
} from '../../../../../constants/finance/finance.constant';
import ListForm from '../../../../../components/ListForm/ListForm.component';

export default class CashJournalFormHeader extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      form, coa, showEdit, onFormChange, isDebit, onSearchUnit,
      isReconciliation, plafond
    } = this.props;
    const { value } = form;
    const { tipe = 0 } = value;
    let fields = FINANCE_CASH_JOURNAL_FORM_FIELDS;
    let journalType = filter(fields, (o, i) => {
      return o.name === 'type';
    });
    let formType = filter(fields, (o, i) => {
      return o.name === 'tipe'
    });
    let dateSelector = filter(fields, (o, i) => {
      return o.name === 'date'
    });

    dateSelector[0].disabled = isReconciliation ? true : false;
    journalType[0].disabled = isReconciliation ? true : false;
    formType[0].disabled = isReconciliation ? true : false;

    if (tipe === "2") {
      let unitSelector = filter(fields, (o, i) => {
        return o.name === 'unit'
      });
      unitSelector[0].label = (isDebit) ? unitSelector[0].label : 'Unit Sumber';
      unitSelector[0].handleSearchContent = onSearchUnit;
      unitSelector[0].async = true;
      unitSelector[0].disabled = isReconciliation ? true : false;
    } else {
      fields = filter(fields, (o) => {
        return o.name !== 'unit'
      });
    }

    if (tipe === "2") {
      fields = filter(fields, field => field.name !== 'pos');
    }

    if (showEdit) {
      //fields = dropRight(fields);
    }

    return (
      <ListForm
        form={form}
        coa={coa}
        plafond={plafond}
        formFields={fields}
        onFormChange={onFormChange}
      />
    );
  }
}

CashJournalFormHeader.propTypes = {
  form: PropTypes.object,
  coa: PropTypes.object,
  showEdit: PropTypes.bool,
  onFormChange: PropTypes.func,
  isDebit: PropTypes.bool,
  onSearchUnit: PropTypes.func,
  isReconciliation: PropTypes.bool,
};

CashJournalFormHeader.defaultProps = {
  form: {},
  coa: {},
  showEdit: true,
  onFormChange: noop,
  isDebit: false,
  onSearchUnit: noop,
  isReconciliation: false,
};

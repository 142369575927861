import Student from '../../views/Student/Student.view';
import { connect } from 'react-redux';

function mapStateToProps(state) {
    return {
        user: state.user || {},
      };
  }


export default connect(mapStateToProps, null)(Student);
import { GET_OPTIONS_REQUESTED, GET_OPTIONS_SUCCEEDED, GET_OPTIONS_FAILED } from '../actions/common.action';

const optionsState = {
  budget: {},
  periode: {},
  coa: {},
  code_group: {},
  provinces: [],
  sub_districts: [],
  cities: [],
  districts: [],
};

export default function options(state = optionsState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_OPTIONS_REQUESTED: {
      return state;
    }
    case GET_OPTIONS_SUCCEEDED: {
      const newState = { ...state };
      newState[payload.entity] = {
        loading: false,
        list: payload.data,
        error: '',
      };
      return newState;
    }
    case GET_OPTIONS_FAILED: {
      const newState = { ...state };
      return newState;
    }
    default: {
      return state;
    }
  }
}

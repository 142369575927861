import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import budgeting from '../../../assets/img/finance/anggaran.png';
import bookkeeping from '../../../assets/img/bookkeeping/jurnal_bank.png';

export default class Ulangan extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickDaftarNilai = this.onClickDaftarNilai.bind(this);
    this.onClickNilaiKognitif = this.onClickNilaiKognitif.bind(this);
    this.onClickPsik = this.onClickPsik.bind(this);
    this.onClickHarian = this.onClickHarian.bind(this);
    this.onClickSikap = this.onClickSikap.bind(this);

  }

  onClickDaftarNilai() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/ulangan/daftar-nilai');
  }

  onClickNilaiKognitif() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/ulangan/pengisian-nilai-kognitif');
  }

  onClickPsik() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/ulangan/pengisian-psik-afek');
  }

  onClickPengisianKompetensiDasar() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/ulangan/kompetensi-dasar');
  }

  onClickHarian() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/ulangan/pengisian-observasi-harian');
  }

  onClickSikap() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/ulangan/pengisian-indikator-sikap');
  }


  render() {
    return (
      <div className="finance">
        <div className="finance__list">
          <button onClick={this.onClickSikap}>
            <img src={budgeting} alt="psikafek" />
            <h3>Pengisian Indikator Sikap</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickNilaiKognitif}>
            <img src={budgeting} alt="NilaiKognitif" />
            <h3>Pengisian Nilai Kognitif, Psik & Afek</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickPengisianKompetensiDasar}>
            <img src={budgeting} alt="Pengisiankompetensidasar" />
            <h3>Pengisian Kompetensi Dasar</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickHarian}>
            <img src={budgeting} alt="observasiharian" />
            <h3>Pengisian Observasi & Daftar Nilai</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickDaftarNilai}>
            <img src={budgeting} alt="DaftarNilai" />
            <h3>Daftar Nilai</h3>
          </button>
        </div>
      </div>
    );
  }
}
Ulangan.propTypes = {
  history: PropTypes.object.isRequired,
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop, map } from 'lodash';
import StudentReportFilter from '../../../components/Filter/StudentReport.filter.component';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { ABSENSI_HARIAN } from '../../../constants/student.constant';
import { ABSENSI_HARIAN_TABLE } from '../../../constants/student.constant';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted, normalizeAmount } from '../../../utils/transformer.util';
import { Button, Input, Select } from '../../../components/base/index';
import { ANALISA_DISPLINER_FORM_FIELDS} from '../../../constants/Student/displiner.constant';
import KodeMatpelTable from './components/KodeMatpelTable.component';
import JadwalMatpelTable1 from './components/JadwalMatpelTable1.component';
import IsiJadwalTable from './components/IsiJadwalTable.component';
import { param } from 'jquery';

export default class IsiJadwal extends Component {
    constructor(props) {
        super(props);
        this._onClickAdd = this._onClickAdd.bind(this);
        this._getParamOptions = this._getParamOptions.bind(this);
        this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
        this._onDeleteList = this._onDeleteList.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this._getSubjectOptions= this._getSubjectOptions.bind(this);
        this._getTeacherOptions = this._getTeacherOptions.bind(this);
        this._setForm = this._setForm.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this._getDayOptions = this._getDayOptions.bind(this);
        this._getClassesOptions= this._getClassesOptions.bind(this);

        const { user } = this.props;
        const { organizations_id, school_unit } = user;
        const { levels_id } = school_unit;
        this.state = {
            form: {
                value: {
                  levels_id,
                  organizations_id,
                  classrooms_id: '',
                  day: '',
                  jadwal_matpel: [
                    {
                    subject_id: '',
                    teacher_id: '',
                    start_hour: '',
                    start_minute: '',
                    end_hour: '',
                    end_minute: '',
                    }                                      
                  ],
                },
                error: {
                  jadwal_matpel: '',
        
                },
              },
              param:{},
        }
       
       
      }

      async _getParamOptionsAcademics(type, filters={}) {
        const { getParamOptionsAcademics } = this.props;
        if (type === 'classes' || type === 'classrooms') {
          filters.organizations_id = 4;
        } else {
          delete filters.organizations_id;
        }
        const res = await getParamOptionsAcademics(filters, type);
        this.setState(prevState => ({
          ...prevState,
          param: {
            ...prevState.param,
            [type]: res,
          },
        }));
      }

      componentDidMount() {
        const { location , user } = this.props;
        const { organizations_id, school_unit } = user;
        const { levels_id, units_id } = school_unit;
        const { state = {} } = location;
        const {isEdit = false, data} = location.state;
        const { id } = state.data || '';
       
        const paramTypes = ['levels', 'classrooms'];
        if (isEdit && id) {
          this.setState({id:id})
          this._setForm(data.id);
        }
    
        paramTypes.forEach((type) => {
          this._getParamOptionsAcademics(type);
        });
        this._getSubjectOptions({ filters: { units_id: [units_id] }});
        this._getTeacherOptions();
        this._getDayOptions();
        this._getClassesOptions({ filters: { levels_id: [levels_id] }});

      }

      async _setForm(id) {
        const { handleGetScheduleSubject } = this.props;
        try {
          const payload = await handleGetScheduleSubject({ id });
       
      console.log(payload)
          this.setState({
            form: {
              value: {
                id: payload.id,
                classrooms_id: payload.classrooms_id,
                day: payload.day,
                jadwal_matpel: map(payload.jadwal_matpel, detail => ({
                  subject_id: detail.subject_id,
                  teacher_id: detail.teacher_id,
                  start_hour: detail.start_hour,
                  start_minute: detail.start_minute,
                  end_hour: detail.end_hour,
                  end_minute: detail.end_minute,
                 
                })),
              },
              error: {
                jadwal_matpel: [],
              },
            },
          });
        } catch (err) {
          // err action
        }
      }

      async _getParamOptions(type, filters={}) {
        const {getParamOptions, user} = this.props;
        const { organizations_id } = user;
        if (type === 'classrooms' || type === 'classrooms') {
          filters.organizations_id = organizations_id;
        } else {
          delete filters.organizations_id;
        }
        const res = await getParamOptions(filters, type);
        this.setState(prevState => ({
          ...prevState,
          param: {
            ...prevState.param,
            [type]: res,
          },
        }));
      }

      async _getClassesOptions(filters={}) {
        const { getClassesOptions, user } = this.props;
        const {school_unit} = user;
        const { levels_id } = school_unit;
        filters.levels_id = levels_id;

        const res = await getClassesOptions(filters);
        // console.log(res)
        this.setState(prevState => ({
          ...prevState,
          param: {
            ...prevState.param,
            classes: res,
          },
        }));
       
      }

      async _getSubjectOptions(filters={}) {
        const { getSubjectOptions, user } = this.props;
        const { organizations_id, school_unit } = user;
        const { levels_id, units_id } = school_unit;
        const res = await getSubjectOptions(filters);
        filters.units_id = units_id;
        // console.log(res)
        this.setState(prevState => ({
          ...prevState,
          param: {
            ...prevState.param,
            subject: res,
          },
        }));
       
      }

      async _getDayOptions() {
        const { getDayOptions } = this.props;
        const res = await getDayOptions();
        // console.log(res)
        this.setState(prevState => ({
          ...prevState,
          param: {
            ...prevState.param,
            day: res,
          },
        }));
       
      }

      async _getTeacherOptions() {
        const { getTeacherOptions } = this.props;
        const res = await getTeacherOptions();
        // console.log(res)
        this.setState(prevState => ({
          ...prevState,
          param: {
            ...prevState.param,
            teacher: res,
          },
        }));
       
      }

      async onSubmit() {
        const { history, handleSaveScheduleSubject } = this.props;
        const { form } = this.state;
        const { value } = form;        
        let canSaveA = false;
        const dataForm = form.value;
        // if(dataForm.name && dataForm.publish_date && dataForm.tgl_selesai && dataForm.levels && dataForm.classrooms){
        //   canSaveA = true;
        // } else {
        //   canSaveA = false;
        // }
        // if (canSaveA == false){
        //   alert("Harap lengkapi data form. Bagian yang harus diisi ditandai dengan tanda bintang (*).");
        // } else {
          await handleSaveScheduleSubject(value);
          history.push('/dashboard/kurikulum/mata-pelajaran/jadwal-mata-pelajaran');
        // }
      }

      async onEdit() {
        const { history, handleEditScheduleSubject } = this.props;
        const { form } = this.state;
        const { value } = form;
        const res = await handleEditScheduleSubject(value);        
        history.push('/dashboard/kurikulum/mata-pelajaran/jadwal-mata-pelajaran');
      }


    

      async _getParamOptionsAcademics(type, filters={}) {
        const { getParamOptionsAcademics, user } = this.props;
        const { organizations_id, school_unit } = user;
        const { levels_id } = school_unit;
        if (type === 'classrooms' || type === 'classrooms') {
          filters.organizations_id = organizations_id;
          filters.levels_id = levels_id;
          } else {
          delete filters.organizations_id;
        }
        const res = await getParamOptionsAcademics(filters, type);
        this.setState(prevState => ({
          ...prevState,
          param: {
            ...prevState.param,
            [type]: res,
          },
        }));
      }
    
      _onClickAdd(data) {
        const { form } = this.state;
        const { jadwal_matpel } = form.value;
        
        jadwal_matpel.push({
          Kelas: '',
          Matpel:'',
          // divisi: '',
          nominal: 0,
          //period:'',
        });
        
        this.setState(prevState => ({
          form: {
            value: {
              ...prevState.form.value,
              jadwal_matpel,
            },
            error: {
              ...prevState.form.error,
            }
          }
        }));
        console.log(jadwal_matpel)
      }
    
      _onDeleteList(idx) {
        this.setState((prevState) => {
          const { jadwal_matpel } = prevState.form.value;
          const list = prevState.form.value.jadwal_matpel;
    
          list.splice(idx, 1);
          return {
            ...prevState,
            jadwal_matpel: list
          };
        });
      }
    
      _onClickAddGroup() {
        const { history } = this.props;
        history.push('/dashboard/kesiswaan/displiner/buat-group');
      }
      _onFormChange(event) {
        const {
          name,
          value,
          dataset,
          checked,
          type,
          files
        } = event.target;
        const {
          inputType = 'text', inputArray = false, arrayPosition = 0,
          fieldName,
        } = dataset;
        const { user } = this.props;
        const { organizations_id } = user;
    
        this.setState((prevState) => {
          let newList = [];
          let newListError = [];
          let formattedValue = value;          
          if(type==="file"){
            formattedValue = files[0]
          }
          if (inputType === 'number') {
            formattedValue = normalizeAmount(value);
          }
          if (inputArray) {
            if (type === 'checkbox') {
              formattedValue = checked;
            }
            newList = prevState.form.value[fieldName];
            newListError = prevState.form.error[fieldName];
            newList[arrayPosition][name] = formattedValue;
            if (name === 'code_of_account') {
              newList[arrayPosition].isCredit = value.type;
            }
            if (!isEmpty(newListError[arrayPosition])) {
              newListError[arrayPosition][name] = '';
            }
          }
          return {
            form: {
              value: {
                ...prevState.form.value,
                ...(inputArray
                  ? { [fieldName]: newList }
                  : { [name]: formattedValue }),
              },
              error: {
                ...prevState.form.error,
                ...(inputArray
                  ? { [fieldName]: newListError }
                  : { [name]: '' }),
              },
            },
          };
        }, () => {
          if (name === 'levels') {
            this._getParamOptionsAcademics( 'classrooms', { levels_id: value, organizations_id } );
          }
          if (name === 'classrooms') {
            this._getStudentOptions({ filters: { classrooms_id: value.map(item => item.value) }} );
          }
          if (name === 'classes_id') {
            this._getParamOptionsAcademics( 'classrooms', {classes_id: value, organizations_id } );
            this._getSubjectOptions({ filters: {classes_id: value} } );
          
          }
        });
      }
    
      
    
      render() {
        const {form, param} = this.state;
        const {value, error} = form;
        const { jadwal_matpel } = form.value;
        const { handleGetKelas, handleDeleteKelas,coa, classrooms,hari } = this.props;
        return (
        <div className="add-budget">
           
        <div className="nilai-kognitif__custom-form-row">
            <div className="nilai-kognitif__custom-form-row__field">
            <div className="data-pelanggaran__custom-form-row__field">
              <Select
                name="classes_id"
                label="Tingkat Kelas"
                onChange={this._onFormChange}
                data={param.classes}
                placeholder={'Pilihan'}
                value={form.value.classes_id}
                error={form.error.classes_id || ''}
              />
          <div className="data-pelanggaran__custom-form-row__field"></div>
            <Select
              name="classrooms_id"
              label="Kelas"
              onChange={this._onFormChange}
              placeholder="Pilih Kelas"
              data={param.classrooms}
              value={form.value.classrooms_id}
              error={form.error.classrooms_id || ''}
            />
           <div className="data-pelanggaran__custom-form-row__field"></div>
              <Select
                  name="day"
                  label="Hari"
                  onChange={this._onFormChange}
                  placeholder="Pilihan"
                  data={param.day}
                  value={form.value.day}
                  error={form.error.day || ''}
              />
            </div>
           
                
            </div>
        
            {/* <div className="nilai-kognitif__custom-form-column__field">
  
                <Select
                    name="day"
                    label="Hari"
                    onChange={this._onFormChange}
                    placeholder="Pilihan"
                    data={param.day}
                    value={form.value.day}
                    error={form.error.day || ''}
                />
            
        
            
            </div> */}
        </div>
         
            
            <form onSubmit={this.onSubmit}>
                
                    <IsiJadwalTable
                    list={jadwal_matpel}
                    param={param}

                    onAddList={this._onClickAdd}
                    onFormChange={this._onFormChange}                    
                    onDeleteList={this._onDeleteList}
                    />
             
            </form>
            <div className="manage-registration__footer">
                {this.state.id?
              <Button
                type="button"
                title="Update"
                onClick={this.onEdit}
              />:
              <Button
                type="button"
                title="Simpan"
                onClick={this.onSubmit}
              />}
                </div>
            
         </div>
        );
      }
}
IsiJadwal.propTypes = {
    
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
  handleGetScheduleSubject: PropTypes.func,
};
IsiJadwal.defaultProps = {
    
  handleGetStudent: noop,
  handleDeleteStudent: noop,
  handleGetScheduleSubject: noop,
};

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button } from '../../../../../components/base';

export default class DashboardPrint extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick(type) {
    const { location, history } = this.props;
    const { head_unique_id } = location.state;

    if (type === 'DETAIL_RAPBU') {
      history.push('/dashboard/keuangan/penganggaran/detail-rapbs', {
        type,
        code: '',
        head_unique_id,
      });
    } else if (type === 'REKAP_RAPBU') {
      history.push('/dashboard/keuangan/penganggaran/rekap-rapbs', {
        type,
        code: '',
        head_unique_id,
      });
    }
  }

  render() {
    return (
      <main>
        <Button className="print" onClick={() => this.onClick('REKAP_RAPBU', '')}>Cetak RAPBS</Button>
      </main>

    );
  }
}
const container = document.createElement('div');
document.body.appendChild(container);

DashboardPrint.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

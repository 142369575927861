import React, { Component } from "react";
import PropTypes from "prop-types";
import { noop, map, isEmpty } from "lodash";
import {
  Input,
  Select,
  Textarea,
  Button,
} from "../../../../components/base/index";
import { GENDER } from "../../../../variables/common.variable";

export default class PengisianDaftarNilaiTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      edit: props.status === !isEmpty(props.data),
    };
    this._onAddList1B = this._onAddList1B.bind(this);
    this._onDeleteList1B = this._onDeleteList1B.bind(this);
    this._onAddList2B = this._onAddList2B.bind(this);
    this._onDeleteList2B = this._onDeleteList2B.bind(this);
    this._onAddList3B = this._onAddList3B.bind(this);
    this._onDeleteList3B = this._onDeleteList3B.bind(this);
    this._onAddList4B = this._onAddList4B.bind(this);
    this._onDeleteList4B = this._onDeleteList4B.bind(this);
    this._onAddList5B = this._onAddList5B.bind(this);
    this._onDeleteList5B = this._onDeleteList5B.bind(this);
  }

  _onAddList1B() {
    const { onAddList1B, fieldName } = this.props;
    onAddList1B(fieldName);
  }
  _onDeleteList1B(idx) {
    const { onDeleteList1B, fieldName } = this.props;
    onDeleteList1B(idx, fieldName);
  }

  _onAddList2B() {
    const { onAddList2B, fieldName } = this.props;
    onAddList2B(fieldName);
  }
  _onDeleteList2B(idx) {
    const { onDeleteList2B, fieldName } = this.props;
    onDeleteList2B(idx, fieldName);
  }

  _onAddList3B() {
    const { onAddList3B, fieldName } = this.props;
    onAddList3B(fieldName);
  }
  _onDeleteList3B(idx) {
    const { onDeleteList3B, fieldName } = this.props;
    onDeleteList3B(idx, fieldName);
  }

  _onAddList4B() {
    const { onAddList4B, fieldName } = this.props;
    onAddList4B(fieldName);
  }
  _onDeleteList4B(idx) {
    const { onDeleteList4B, fieldName } = this.props;
    onDeleteList4B(idx, fieldName);
  }

  _onAddList5B() {
    const { onAddList5B, fieldName } = this.props;
    onAddList5B(fieldName);
  }
  _onDeleteList5B(idx) {
    const { onDeleteList5B, fieldName } = this.props;
    onDeleteList5B(idx, fieldName);
  }

  render() {
    const { onFormChange, form, param, list1b, list2b, list3b, list4b, list5b } =
      this.props;
    const {development_aspect_id_b} = form.value;
    const rating = [
      { label: "1", value: "1" },
      { label: "2", value: "2" },
      { label: "3", value: "3" },
      { label: "4", value: "4" },
      { label: "5", value: "5" },
    ];
    // const pengisian = [
    //   { label: 'Sikap', value: 'sikap'},
    //   { label: 'Kognitif', value: 'kognitif'},
    // ]
    return (
      <div className="lembar-harian2__custom-form">
        <div className="lembar-harian2__custom-form-row">
          <div className="lembar-harian2__custom-form-width__field">
            
            <Select
              name="development_aspect_id_b"
              onChange={onFormChange}
              placeholder={"Pilihan"}
              data={param.prm_aspect}
              value={development_aspect_id_b}
              disabled
            />
          </div>
          <div className="lembar-harian2__custom-form-width__field"></div>
        </div>

        <br />
        <div className="lembar-harian2__custom-form-row">
          <div className="lembar-harian2__custom-form-width__field">
            <Select
              name="kd_1b"
              value={form.value.kd_1b}
              label="1. Kompetensi Dasar"
              onChange={onFormChange}
              data={param.kd_prm_b}
              placeholder={"Pilihan"}
            />
            <p>Muatan Materi </p>
            <table className="manage-rapbs-plafon__form-table table">
              <tbody>
                {map(list1b, (data, idx) => (
                  <tr key={`plafon_journal_data__${idx}`}>
                    <td>{idx + 1}</td>
                    <td>
                      <Textarea
                        type="text"
                        value={data.muatan_1b}
                        data-input-array
                        arrayPosition={idx}
                        data-array-position={idx}
                        data-field-name="muatan_materi_1b"
                        name="muatan_1b"
                        onChange={onFormChange}
                      />
                    </td>
                    <td>
                      <Button
                        onClick={() => this._onDeleteList1B(idx)}
                        icon="icon-trash-o"
                      />
                    </td>
                  </tr>
                ))}
                <tr className="manage-rapbs-plafon__add-more">
                  <td colSpan={10}>
                    <div>
                      <Button icon="icon-plus" onClick={this._onAddList1B} />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="lembar-harian2__custom-form-width__field">
            <Select
              name="kd_2b"
              value={form.value.kd_2b}
              label="2. Kompetensi Dasar"
              onChange={onFormChange}
              data={param.kd_prm_b}
              placeholder={"Pilihan"}
            />
            <p>Muatan Materi </p>
            <table className="manage-rapbs-plafon__form-table table">
              <tbody>
                {map(list2b, (data, idx) => (
                  <tr key={`plafon_journal_data__${idx}`}>
                    <td>{idx + 1}</td>
                    <td>
                      <Textarea
                        type="text"
                        value={data.muatan_2b}
                        data-input-array
                        arrayPosition={idx}
                        data-array-position={idx}
                        data-field-name="muatan_materi_2b"
                        name="muatan_2b"
                        onChange={onFormChange}
                      />
                    </td>
                    <td>
                      <Button
                        onClick={() => this._onDeleteList2B(idx)}
                        icon="icon-trash-o"
                      />
                    </td>
                  </tr>
                ))}
                <tr className="manage-rapbs-plafon__add-more">
                  <td colSpan={10}>
                    <div>
                      <Button icon="icon-plus" onClick={this._onAddList2B} />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <br />
        <div className="lembar-harian2__custom-form-row">
          <div className="lembar-harian2__custom-form-width__field">
            <Select
              name="kd_3b"
              value={form.value.kd_3b}
              label="3. Kompetensi Dasar"
              onChange={onFormChange}
              data={param.kd_prm_b}
              placeholder={"Pilihan"}
            />
            <p>Muatan Materi </p>
            <table className="manage-rapbs-plafon__form-table table">
              <tbody>
                {map(list3b, (data, idx) => (
                  <tr key={`plafon_journal_data__${idx}`}>
                    <td>{idx + 1}</td>
                    <td>
                      <Textarea
                        type="text"
                        value={data.muatan_3b}
                        data-input-array
                        arrayPosition={idx}
                        data-array-position={idx}
                        data-field-name="muatan_materi_3b"
                        name="muatan_3b"
                        onChange={onFormChange}
                      />
                    </td>
                    <td>
                      <Button
                        onClick={() => this._onDeleteList3B(idx)}
                        icon="icon-trash-o"
                      />
                    </td>
                  </tr>
                ))}
                <tr className="manage-rapbs-plafon__add-more">
                  <td colSpan={10}>
                    <div>
                      <Button icon="icon-plus" onClick={this._onAddList3B} />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="lembar-harian2__custom-form-width__field">
            <Select
              name="kd_4b"
              value={form.value.kd_4b}
              label="4. Kompetensi Dasar"
              onChange={onFormChange}
              data={param.kd_prm_b}
              placeholder={"Pilihan"}
            />
            <p>Muatan Materi </p>
            <table className="manage-rapbs-plafon__form-table table">
              <tbody>
                {map(list4b, (data, idx) => (
                  <tr key={`plafon_journal_data__${idx}`}>
                    <td>{idx + 1}</td>
                    <td>
                      <Textarea
                        type="text"
                        value={data.muatan_4b}
                        data-input-array
                        arrayPosition={idx}
                        data-array-position={idx}
                        data-field-name="muatan_materi_4b"
                        name="muatan_4b"
                        onChange={onFormChange}
                      />
                    </td>
                    <td>
                      <Button
                        onClick={() => this._onDeleteList4B(idx)}
                        icon="icon-trash-o"
                      />
                    </td>
                  </tr>
                ))}
                <tr className="manage-rapbs-plafon__add-more">
                  <td colSpan={10}>
                    <div>
                      <Button icon="icon-plus" onClick={this._onAddList4B} />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <br />
        <div className="lembar-harian2__custom-form-row">
          <div className="lembar-harian2__custom-form-width__field">
            <Select
              name="kd_5b"
              value={form.value.kd_5b}
              label="5. Kompetensi Dasar"
              onChange={onFormChange}
              data={param.kd_prm_b}
              placeholder={"Pilihan"}
            />
            <p>Muatan Materi </p>
            <table className="manage-rapbs-plafon__form-table table">
              <tbody>
                {map(list5b, (data, idx) => (
                  <tr key={`plafon_journal_data__${idx}`}>
                    <td>{idx + 1}</td>
                    <td>
                      <Textarea
                        type="text"
                        value={data.muatan_5b}
                        data-input-array
                        arrayPosition={idx}
                        data-array-position={idx}
                        data-field-name="muatan_materi_5b"
                        name="muatan_5b"
                        onChange={onFormChange}
                      />
                    </td>
                    <td>
                      <Button
                        onClick={() => this._onDeleteList5B(idx)}
                        icon="icon-trash-o"
                      />
                    </td>
                  </tr>
                ))}
                <tr className="manage-rapbs-plafon__add-more">
                  <td colSpan={10}>
                    <div>
                      <Button icon="icon-plus" onClick={this._onAddList5B} />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="lembar-harian2__custom-form-width__field"></div>
        </div>

        <br />
        <Textarea
          type="text"
          label="Hasil Observasi"
          name="obs_result_b"
          value={form.value.obs_result_b}
          onChange={onFormChange}
          rows={5}
          style={{ backgroundColor: "yellow" }}
        />
      </div>
    );
  }
}
PengisianDaftarNilaiTable.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
PengisianDaftarNilaiTable.defaultProps = {
  onFormChange: noop,
};

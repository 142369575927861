import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import * as Http from '../../../../utils/axios.util';
import Button from '../../../../components/base/Button/Button.component';
import { Input } from '../../../../components/base/index';
import { normalizeAmount, commaFormatted } from '../../../../utils/transformer.util';

const updatedId = [];
let timer = window.setTimeout(() => {}, 5000);

export default function RealizationForm({ location, handleAddRealization }) {
  const [data, setData] = useState({});
  const [state, setState] = useState({});
  const [total, setTotal] = useState(0);

  const { state: locationState = {} } = location;
  const { id } = locationState.data || null;
  const months = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];

  const getData = async () => {
    const res = await Http.POST('BUDGET_REQUEST', { id }, '/get');
    setData(res.data);
  };

  const getBreakdown = () => {
    if (Object.keys(data).length > 0 && data.fund_request_detail) {
      const { fund_request_detail = [] } = data;
      let newState = JSON.parse(JSON.stringify(state));
      fund_request_detail.forEach(async (item) => {
        const res = await Http.POST('REALIZATION', { fund_request_detail_id: item.id }, '/get');
        newState = {
          ...newState,
          [item.id]: res,
        };
        setState(newState);
      });
    }
  };

  const updateData = () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      let item = '';
      updatedId.forEach(async (itemId) => {
        Object.keys(state).forEach((key) => {
          const temp = state[key].find(n => n.id === parseInt(itemId, 10));
          if (temp) {
            item = temp;
          }
        });
        updatedId.shift();
        if (item) {
          const res = await Http.POST('REALIZATION', { ...item }, '/edit');
          return res;
        }
        return false;
      });
    }, 2000);
  };

  const calcBalance = (temp) => {
    const newState = JSON.parse(JSON.stringify(temp));
    const { fund_request_detail = [] } = data;
    fund_request_detail.forEach((o) => {
      const { id: detailId, amount: requestAmount = 0 } = o;
      let totalBalance = parseFloat(requestAmount, 10);
      if (newState[detailId]) {
        newState[detailId].forEach((item, index) => {
          totalBalance -= item.amount;
          newState[detailId][index].balance = totalBalance;
        });
      }
    });
    return newState;
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { dataset, name, value } = e.target;
    const {
      index,
      fundRequestDetailsId,
      type,
      id: itemId,
    } = dataset;

    let newState = JSON.parse(JSON.stringify(state));
    const detail = newState[fundRequestDetailsId][index];

    if (typeof detail[name] === 'undefined') {
      detail[name] = '';
    }
    detail[name] = type === 'number' ? normalizeAmount(value) : value;
    newState = calcBalance(newState);

    updatedId.push(itemId);
    setState(newState);

    return true;
  };

  const addDetail = async (detailId) => {
    let newState = JSON.parse(JSON.stringify(state));

    const res = await handleAddRealization({
      fund_request_detail_id: detailId,
    });

    if (typeof newState[detailId] === 'undefined') {
      newState = {
        ...newState,
        [detailId]: [],
      };
    }

    newState[detailId].push(res);

    setState(newState);
  };

  const deleteDetail = (fund_request_details_id, index) => {
    let newState = JSON.parse(JSON.stringify(state));
    const details = newState[fund_request_details_id];

    details.splice(index, 1);

    newState[fund_request_details_id] = details;

    newState = calcBalance(newState);
    setState(newState);
  };

  const renderBreakdown = (fund_request_details_id = null, isPosted) => {
    const components = [];
    const items = state[fund_request_details_id] || [];
    if (items.length === 0) {
      return false;
    }
    items.forEach((item, index) => {
      const {
        id: itemId = null,
        amount = 0,
        remarks = '',
        description = '',
        balance = 0,
      } = item;
      let component = '';
      if (isPosted) {
        component = (
          <tr>
            <td />
            <td />
            <td>{amount}</td>
            <td />
            <td>{remarks}</td>
          </tr>
        );
      } else {
        component = (
          <tr>
            <td />
            <td>
              <Input
                type="text"
                name="description"
                data-index={index}
                data-id={itemId}
                data-fund-request-details-id={fund_request_details_id}
                onChange={handleChange}
                placeholder="Rincian"
                value={description}
              />
            </td>
            <td />
            <td className="nominal">
              <div className="input-group mb-3">
                <Input
                  isNumber
                  data-type="number"
                  name="amount"
                  data-id={itemId}
                  data-index={index}
                  data-fund-request-details-id={fund_request_details_id}
                  onChange={handleChange}
                  defaultValue={0}
                  value={commaFormatted(amount)}
                />
              </div>
            </td>
            <td className="nominal">
              {commaFormatted(balance)}
            </td>
            <td>
              <div className="input-group mb-3">
                <Input
                  type="text"
                  name="remarks"
                  data-id={itemId}
                  data-index={index}
                  data-fund-request-details-id={fund_request_details_id}
                  onChange={handleChange}
                  value={remarks}
                />
              </div>
            </td>
            <td>
              <Button
                title="X"
                alt="Hapus Rincian"
                onClick={() => { deleteDetail(fund_request_details_id, index); }}
              />
            </td>
          </tr>
        );
      }
      components.push(component);
    });
    return components;
  };

  const renderDetails = () => {
    const { fund_request_detail = [] } = data;
    const child = [];
    let counter = 0;
    fund_request_detail.forEach((detail) => {
      counter += 1;
      const {
        id: detailId,
        amount = 0,
        balance = 0,
        budget_detail = {},
      } = detail;

      const component = (
        <tr>
          <th scope="row">{counter}</th>
          <td>{ budget_detail.desc }</td>
          <td className="nominal">{commaFormatted(amount)}</td>
          <td />
          <td className="nominal">{commaFormatted(balance)}</td>
          <td />
          <td width="5%">
            <Button
              title="+"
              alt="Tambah Rincian"
              onClick={() => { addDetail(detailId); }}
            />
          </td>
        </tr>
      );
      child.push(component);

      const breakdown = renderBreakdown(detailId);
      child.push(breakdown);
    });
    return child;
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (Object.keys(state).length > 0) {
      updateData(updatedId);

      let totalRealization = 0;
      Object.keys(state).forEach((o) => {
        const breakdown = state[o];
        if (breakdown.length > 0) {
          breakdown.forEach((item) => {
            totalRealization += item.amount;
          });
        }
      });
      setTotal(totalRealization);
    }
  }, [state]);

  useEffect(() => {
    getBreakdown();
  }, [data]);

  return (
    <div>
      <h1>
        Realisasi Rencana Kegiatan Bulanan
      </h1>
      <h2>
        Periode {months[data.month]}
      </h2>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th scope="col">No.</th>
            <th scope="col">POS PERKIRAAN</th>
            <th scope="col">ANGGARAN</th>
            <th scope="col">REALISASI</th>
            <th scope="col">SALDO</th>
            <th scope="col">KETERANGAN</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {renderDetails()}
        </tbody>
        <tfoot>
          <tr className="total">
            <td colSpan="3"><h3>Total</h3></td>
            <td className="nominal">{commaFormatted(total)}</td>
            <td />
            <td />
            <td />
          </tr>
        </tfoot>
      </table>
      <div className="buttons-wrapper">
        <div>
          <Button
            type="submit"
            title="Save"
          />
        </div>
        <div>
          <Button
            type="submit"
            title="Submit"
          />
        </div>
      </div>
    </div>
  );
}

RealizationForm.propTypes = {
  location: PropTypes.object.isRequired,
  handleAddRealization: PropTypes.func.isRequired,
};

import { connect } from 'react-redux';
import BuatPelanggaran from '../../../views/Student/displiner/BuatPelanggaran.student.view';
import {listViolationsTable, deleteViolationsTable, saveViolationsTable, editViolationsTable, getViolationsTable } from '../../../states/thunks/academics.thunk';
import { getViolationsGroupOptions, getParamOptionsAcademics } from '../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    result: state.result,
    user: state.user || {},
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getViolationsGroupOptions: (payload) => dispatch(getViolationsGroupOptions(payload)),
    handleGetViolationsTable: payload => dispatch(getViolationsTable(payload)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    handleSaveViolationsTable: (payload, goback) => dispatch(saveViolationsTable(payload, goback)),
    handleListViolationsTable: (payload, goback) => dispatch(listViolationsTable(payload, goback)),
    handleEditViolationsTable: (payload, goback) => dispatch(editViolationsTable(payload, goback)), 
    
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BuatPelanggaran);

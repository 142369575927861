import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import budgeting from '../../../assets/img/finance/anggaran.png';
import bookkeeping from '../../../assets/img/bookkeeping/jurnal_bank.png';
import { map } from 'lodash';

export default class DataSiswa extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickCatatSiswaKeluar = this.onClickCatatSiswaKeluar.bind(this);
    this.onClickPembatalanSiswa = this.onClickPembatalanSiswa.bind(this);
    this.onClickDaftarSiswaKelas = this.onClickDaftarSiswaKelas.bind(this);

    
  }

  onClickCatatSiswaKeluar() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/data-siswa/catat-siswa-keluar');
  }

  onClickPembatalanSiswa() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/data-siswa/pembatalan-siswa-keluar');
  }
  onClickDaftarSiswaKelas() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/laporan/daftar-siswa-kelas');
  }
 


  render() {
    const { user } = this.props;
    const { permissions } = user.user_group;
    return (
      <div>
        {
          map(permissions, (item) => {
            if (item == "view_form_TU") {
              return (
                <div className="akademi">
                  <div className="akademi__list">
                  <button onClick={this.onClickDaftarSiswaKelas}>
                    <img src={budgeting} alt="DataKelas" />
                    <h3>Daftar Siswa Kelas</h3>
                  </button>
                </div>
                <div className="akademi__list">
                  <button onClick={this.onClickCatatSiswaKeluar}>
                    <img src={budgeting} alt="DataKelas" />
                    <h3>Catat Siswa Keluar</h3>
                  </button>
                </div>
                <div className="akademi__list">
                  <button onClick={this.onClickPembatalanSiswa}>
                    <img src={budgeting} alt="DataKelas" />
                    <h3>Pembatalan Siswa Keluar</h3>
                  </button>
                </div>
              </div>
              )
            }
            if (item == "view_ekskul_guru") {
              return (
                <div className="akademi">
                  <div className="akademi__list">
                  <button onClick={this.onClickDaftarSiswaKelas}>
                    <img src={budgeting} alt="DataKelas" />
                    <h3>Daftar Siswa Kelas</h3>
                  </button>
                </div>
              </div>
              )
            }
          })
        }
      </div>
     
    );
  }
}
DataSiswa.propTypes = {
  history: PropTypes.object.isRequired,
};

import React, { useEffect, useState } from 'react';
// import i18n from '../i18n';
import language from '../languages';

export const AlertContext = React.createContext();

export default function AlertContextProvider({ children }) {
  const [alert, setAlert] = useState({});
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (alert.icon && alert.className && alert.message) {
      setVisible(true);
    }
    return () => setVisible(false);
  }, [alert]);

  const showAlert = (type, message) => {
    let errorMessage = '';
    switch (type) {
      case 'success':
        setAlert({
          icon: 'icon-ok',
          className: 'alert-success',
          message,
        });
        break;
      case 'error':
        errorMessage = message;
        if (typeof message === 'object') {
          let concatMessage = '';
          Object.values(message).forEach((item) => {
            concatMessage = `${item[0]}\r\n`;
          });
          errorMessage = concatMessage;
        } else {
          // errorMessage = i18n.t(`apiErrors:${errorMessage}`);
        }
        setAlert({
          icon: 'icon-attention-circled',
          className: 'alert-danger',
          message: errorMessage,
        });
        break;
      default:
        setAlert({
          icon: 'icon-attention',
          className: 'alert-warning',
          message,
        });
        break;
    }
  };

  window.addEventListener('click', () => {
    setVisible(false);
  });

  return (
    <AlertContext.Provider value={{ showAlert }}>
      { visible && (
        <div className={`alert ${alert.className}`} role="alert">
          <span className={`fontello-icon fontello-${alert.icon}`}>{alert.message}</span>
          <button
            type="button"
            data-dismiss="alert"
            aria-label="Close"
            onClick={() => setVisible(false)}
          >
            <span aria-hidden="true" className="fontello-icon fontello-icon-cancel" />
          </button>
        </div>
      )}
      { children }
    </AlertContext.Provider>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import { noop, map, isEqual } from 'lodash';
import { Button, Pagination } from '../../../../components/base';
import BaseTable from '../../../../components/BaseTable/BaseTable.component';
import ContentTable from '../../../../components/ContentTable/ContentTable.component';
import { formatData } from '../../../../utils/transformer.util';
import language from '../../../../languages';
import { FINANCE_FUND_REQUEST_TABLE_FIELDS } from '../../../../constants/finance/budgeting/fundRequest.constant';

export default class FundRequest extends BaseTable {
  constructor(props) {
    super(props);
    this.onClickAdd = this.onClickAdd.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this.onClickTransferFund = this.onClickTransferFund.bind(this);
    this._renderButtons = this._renderButtons.bind(this);
    this.getData = this.getData.bind(this);
    this.onChangePage = this.onChangePage.bind(this);

    this.state = {
      list: [],
      workflow: {},
      listAmount: 5,
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    const { workingUnit } = user;
    const { user: prevUser } = prevProps;
    const { workingUnit: prevWorkingUnit } = prevUser;

    if (!isEqual(workingUnit, prevWorkingUnit)) {
      this.getData();
    }
  }

  async getData(payload = {}) {
    const { handleGetFundRequest, user } = this.props;
    const { prm_school_units_id, workingUnit } = user;
    let unit_id = prm_school_units_id;

    if (unit_id === null && workingUnit && workingUnit.id) {
      unit_id = workingUnit.id;
    }

    const res = await handleGetFundRequest(payload);

    this.setState({
      list: res,
    });
  }

  onClickAdd() {
    const { history } = this.props;
    history.push('/dashboard/keuangan/penganggaran/tambah-permohonan-dana', { isEdit: false });
  }

  onClickEdit(val) {
    const { history } = this.props;
    history.push('/dashboard/keuangan/penganggaran/edit-permohonan-dana', { data: val, isEdit: true });
  }

  async onClickTransferFund(id) {
    const { handleTransferFund } = this.props;
    await handleTransferFund({ id });
    this.getData();
  }

  onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this.getData({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  _renderButtons(data) {
    const { user } = this.props;
    const { user_group } = user;
    const { permissions } = user_group;
    const { workflow } = data;

    if (workflow === null) {
      return (
        <td>
          <div className="table__actions">
            <Button
              onClick={() => this.onClickEdit(data)}
              title="Edit"
            />
            {(user_group.name === 'Keuangan Sekolah' || user_group.name === 'Admin' || user_group.name === 'Super Admin')
              && (
              <Button
                onClick={() => { this.onClickDelete(data.id); this.getData(); }}
                title="Hapus"
              />
              )
            }
            {(user_group.permissions === 'transfer_fund') && (
              <Button
                onClick={() => { this.onClickTransferFund(data.id); }}
                title="Transfer"
              />
            )}
          </div>
        </td>
      );
    }
    if (user_group && user_group.name === workflow.next_role) {
      return (
        <td>
          <div className="table__actions">
            <Button
              onClick={() => this.onClickEdit(data)}
              title="Edit"
            />
          </div>
        </td>
      );
    }
    return (
      <td>
        <i>Telah di submit ke {workflow.next_role}</i>
      </td>
    );
  }

  render() {
    const { list } = this.state;
    const { handleGetFundRequest, handleDeleteFundRequest, user } = this.props;
    const { user_group } = user;
    return (
      <div className="fund-request">
        { user_group.name === 'Keuangan Sekolah' && (
          <Button
            title="Tambah Permohonan Dana"
            onClick={this.onClickAdd}
          />
        )
        }
        <div className="fund-request__content">
          <table className="table">
            <thead>
              <tr>
                {
                    map(FINANCE_FUND_REQUEST_TABLE_FIELDS.label, (field, idx) => (
                      <th key={`table_th_${idx}`}>{field}</th>
                    ))
                  }
              </tr>
            </thead>
            <tbody>
              {
                  map(list.result, (data, idx) => (
                    <tr key={`budget_row_${idx}`}>
                      {
                      map(FINANCE_FUND_REQUEST_TABLE_FIELDS.value, (field, fieldIdx) => {
                        if (fieldIdx === 0) {
                          return (
                            <td key={`table_index_${fieldIdx}_${idx}`}>{idx + 1}</td>
                          );
                        }
                        return (
                          <td className={(field.type === 'number') ? 'nominal' : ''} key={`table_${fieldIdx}_${idx}`}>
                            {formatData(data, field)}
                          </td>
                        );
                      })
                    }
                      {this._renderButtons(data)}
                    </tr>
                  ))
                }
            </tbody>
          </table>
        </div>
        <div className="user-management__table-footer">
          <p className="user-management__page-info">
            {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${list.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${list.totalPage}`}
          </p>
          <Pagination
            totalPage={list.totalPage}
            currentPage={list.currentPage}
            onClick={this.onChangePage}
          />
        </div>
      </div>
    );
  }
}
FundRequest.propTypes = {
  handleGetFundRequest: PropTypes.func,
  handleDelete: PropTypes.func,
  handleTransferFund: PropTypes.func,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
};
FundRequest.defaultProps = {
  handleGetFundRequest: noop,
  handleDelete: noop,
  handleTransferFund: noop,
  user: null,
};

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { noop, map, isEqual, isEmpty } from "lodash";
import { normalizeAmount, formatData } from "../../../utils/transformer.util";
import { Button, Pagination, Select } from "../../../components/base/index";
import { TABLE_LIST_TEMA_TABLE_FIELDS } from "../../../constants/kurikulum/kurikulum.constant";
import language from "../../../languages";

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class LembarCatatanAnekdot extends PureComponent {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._setForm = this._setForm.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onChangeListAmount = this._onChangeListAmount.bind(this);
    this._setForm = this._setForm.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);

    const { user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;
    this.state = {
      page: 1,
      selected: {},
      content: initialContent,
      unchecked: {},
      filters: {},
      list: [],
      listAmount: 5,
      form: {
        value: {
          organizations_id,
        },
        error: {
          classrooms: "",
        },
      },
      param: {},
      filtered: false,
    };
  }

  componentDidMount() {
    const { location, getLevels, getClasses, user } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";
    const { content } = this.state;
    const paramTypes = ["classes", "levels", "classrooms"];

    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
    if (isEdit && id) {
      // this.setState({students_id:id})
      // this.setState(prevState => ({
      //   form: {
      //     value: {
      //       ...prevState.form.value,
      //       students_id:id,
      //       organizations_id: 4,
      //     },
      //     error: {
      //       ...prevState.form.error,
      //     }
      //   }
      // }));
      this._setForm(id, data);
    } else if (isEmpty(content.list)) {
      this._onSearchContent({ page: 1 });
    }
    this._onSearchContent({ page: 1 });
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";
    if (!isEqual(prevProps, this.props)) {
      this._onSearchContent({ page: 1 });
    }
  }

  async _getPeriodsOptions() {
    const { getPeriodsOptions } = this.props;
    const res = await getPeriodsOptions();
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  async _onSearchContent(params = {}) {
    const { filters, form } = this.state;
    const { value } = form;
    this.setState(
      {
        content: initialContent,
      },
      async () => {
        try {
          const { handleListTemaCatatanAnekdot, user } = this.props;
          const { organizations_id, school_unit } = user;
          const { units_id } = school_unit;
          let res = {};
          res = await handleListTemaCatatanAnekdot({
            ...params,
            organizations_id,
            units_id,
            filters,
          });

          const {
            result = [],
            currentPage = 1,
            total = 0,
            totalPage = 1,
          } = res;

          if (result) {
            this.setState({
              content: {
                loading: false,
                list: result,
                currentPage,
                total,
                totalPage,
              },
            });
          }
        } catch (err) {
          // if (this.isMount) {
          this.setState({
            content: {
              loading: false,
              list: [],
              currentPage: 1,
              total: 1,
              totalPage: 1,
              error: true,
            },
          });
        }
        // }
      }
    );
  }

  _onChangeListAmount(event) {
    const { keywords } = this.state;
    this.setState({
      listAmount: Number(event.target.value),
    });
    this._onSearchContent({ limit: event.target.value, keywords, page: 1 });
  }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    const { user } = this.props;
    const { organizations_id } = user;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => {
        if (name === "levels_id") {
          this._getParamOptionsAcademics("classrooms", {
            filters: { levels_id: value, organizations_id },
          });
        }
        this._onSearchContent();
      }
    );
  }

  async _setForm(id, data = {}) {
    const { handleGetLembarObsHarian, user } = this.props;
    const { organizations_id } = user;
    try {
      const payload = await handleGetLembarObsHarian({ id });
      this.setState({
        form: {
          value: {
            // id: data.id,
            students_id: data.id,
            organizations_id: organizations_id,

          },
          error: {
            // details: [],
          },
        },
      });
    } catch (err) {
      // err action
    }
  }

  _renderButtons(list) {
    return (
      <td>
        <div className="table__actions">
          <Button onClick={() => this.onClickEdit(list)} title="Edit" />
          {/* <Button onClick={() => this.onClickView(list)} title="View" /> */}
        </div>
      </td>
    );
  }

  onClickEdit(val) {
    const { history, location } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const data_val = {...val, ...data}
    history.push("/dashboard/kurikulum/ulangan/catatan-anekdot/pengisian-catatan-anekdot/lembar/tema", {
      data: data_val,
      isEdit: true,
    });
  }

  onClickView(val) {
    const { history, location } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const data_val = {...val, ...data}
    history.push("/dashboard/kurikulum/ulangan/catatan-anekdot/pengisian-catatan-anekdot/lembar/view", {
      data: data_val,
      isEdit: true,
    });
  }

  async onEdit() {
    const { history, handleEditLembarObsHarian } = this.props;
    const { form } = this.state;
    const { value } = form;
    let a;
    await handleEditLembarObsHarian(value);
    history.push(
      "/dashboard/kurikulum/ulangan/pengisian-lembar-daftar-nilai/report"
    );
    // }
  }

  _handleFormChange(event) {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          [name]: value,
        },
        error: {
          ...prevState.form.error,
          [name]: "",
        },
      },
    }));
  }

  _onFormChange(event) {
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          if (type === "checkbox") {
            formattedValue = checked;
          }
          newList = prevState.form.value[fieldName];
          newListError = prevState.form.error[fieldName];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
    );
  }

  onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this._onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;
    if (type === "classrooms" || type === "classrooms") {
      filters.organizations_id = organizations_id;
      filters.levels_id = levels_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  render() {
    const { location } = this.props;
    const { state = {} } = location;
    const { data } = state;
    const { form, param, list = {}, filters, content } = this.state;
    const { semester = ''} = filters;
    const semester_data = [
      { label: "Ganjil", value: "ganjil" },
      { label: "Genap", value: "genap" },
    ];
    const loadingProps = { show: content.loading };
    return (
      <div className="student-list">
        <div className="budget__title">
          <h1>Lembar Catatan Anekdot </h1>
          <hr></hr>
        </div>
        <form onSubmit={this._onSubmit}>
          <div className="laporan-ki4__header-title3">
            <div className="laporan-ki4__header-title3--left">
              <table className="laporan-ki4__table-test">
                <tr>
                  <td>NIS </td>
                  <td> : {data ? data.nis : ""}</td>
                </tr>
                <tr>
                  <td>Nama</td>
                  <td> : {data ? data.name : ""}</td>
                </tr>
              </table>
            </div>
          </div>
        </form>
  
        <div className="fund-request__content">
          <Select
            name="semester"
            label="Semester"
            placeholder={"Pilihan"}
            onChange={this._onChangeFilter}
            data={semester_data}
            value={semester}
            error={form.error.semester || ""}
          />
          <table className="table">
            <thead>
              <tr>
                {map(
                  TABLE_LIST_TEMA_TABLE_FIELDS.label,
                  (field, idx) => (
                    <th key={`table_th_${idx}`}>{field}</th>
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {map(content.list, (list, idx) => (
                <tr key={`budget_row_${idx}`}>
                  {map(
                    TABLE_LIST_TEMA_TABLE_FIELDS.value,
                    (field, fieldIdx) => {
                      if (fieldIdx === 0) {
                        return (
                          <td key={`table_index_${fieldIdx}_${idx}`}>
                            {idx + 1}
                          </td>
                        );
                      }
                      return (
                        <td
                          className={field.type === "number" ? "nominal" : ""}
                          key={`table_${fieldIdx}_${idx}`}
                        >
                          {formatData(list, field)}
                        </td>
                      );
                    }
                  )}
                  {this._renderButtons(list)}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="user-management__table-footer">
          <p className="user-management__page-info">
            {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${list.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${list.totalPage}`}
          </p>
          <Pagination
            totalPage={list.totalPage}
            currentPage={list.currentPage}
            onClick={this.onChangePage}
          />
        </div>
      </div>
    );
  }
}
LembarCatatanAnekdot.propTypes = {
  handleGetKlapper: PropTypes.func,
  handleDeleteKlapper: PropTypes.func,
};
LembarCatatanAnekdot.defaultProps = {
  handleGetKlapper: noop,
  handleDeleteKlapper: noop,
};

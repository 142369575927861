import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop, map, isEqual } from 'lodash';
import { Button, Pagination } from '../../../../components/base';
import { formatData } from '../../../../utils/transformer.util';
import language from '../../../../languages';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
import { JENIS_SPP_FORM_TABLE } from '../../../../constants/kurikulum/kurikulum.constant';


const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class JenisSPP extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickAdd = this.onClickAdd.bind(this);
    this.onClickAddStatus = this.onClickAddStatus.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this._renderButtons = this._renderButtons.bind(this);
    this.handleGetData = this.handleGetData.bind(this);
    this.onChangePage = this.onChangePage.bind(this);


    this.state = {  
      content: initialContent,
      list: [],
      workflow: {},
      listAmount: 5,
    };
  }

  componentDidMount() {
    const { content } = this.state;
    this.handleGetData();
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    const { workingUnit } = user;
    const { user: prevUser } = prevProps;
    const { workingUnit: prevWorkingUnit } = prevUser;

    if (!isEqual(workingUnit, prevWorkingUnit)) {
      this.handleGetData();
    }
  }
  async onClickDownload(id) {
    const { handleDownloadAnnouncement } = this.props;
    const res = await handleDownloadAnnouncement(id.id, id.upload)
  }


  async handleGetData(payload = {}) {
    const { handleListJenisSPP, handleListAnnouncementStudent, user } = this.props;
    const { units_id, workingUnit, id, user_group, profile } = user;
    let unit_id = units_id;

    if (unit_id === null && workingUnit && workingUnit.id) {
      unit_id = workingUnit.id;
    }

    // if(user_group.id === 2){
      const res = await handleListJenisSPP({...payload, organizations_id: 4});

    this.setState({
      list: res,
    });
    // }

    if(user_group.id === 1){
      const res = await handleListAnnouncementStudent({...payload, user_id: id, students_id: profile.id });

      this.setState({
        list: res,
      });
    }
    
  }

 onClickAdd() {
    const { history, location } = this.props;
    const { state } = location;
    history.push('/dashboard/keuangan/spp/tambah-jenis-spp');
  }

  onClickAddStatus() {
    const { history } = this.props;
    history.push('/dashboard/keuangan/spp/tambah-status', { isEdit: true });
  }

  onClickEdit(val) {
    const { history } = this.props;
    history.push('/dashboard/keuangan/spp/tambah-jenis-spp', { data: val, isEdit: true });
  }
  async onClickDelete(id) {
    const { handleDeleteJenisSPP } = this.props;
    const res = await handleDeleteJenisSPP(id);
    if(res){
      this.handleGetData();
    }
  }

 


  onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this.handleGetData({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  _renderButtons(data) {
    const { user } = this.props;
    const {user_group} = user;
    const button = {};
    
    return (
      <td>
        <div className="table__actions">
        
          {data.upload ? <Button
            onClick={() => this.onClickDownload(data)}
            title='Download'
          /> : <div></div>}
          {/* {user_group.id === 2? */}
             <Button
             onClick={() => this.onClickEdit(data)}
             title='Edit'
           />
           {/* :'' */}
          {/* } */}
            {/* {user_group.id === 2? */}
          
           <Button
             onClick={() => this.onClickDelete(data)}
             title='Delete'
           />
           {/* :'' */}
          {/* } */}
         
        </div>
      </td>
    );
  }

  render() {
    const { list = {} } = this.state;
    const { handleGetFundRequest, handleDeleteFundRequest, user } = this.props;
    const { user_group } = user;
    const { permissions } = user_group;
    const dataset = {
      toggle: "modal"
    };
    return (
      <div className="fund-request">
        
        <div className="beginning-balance__button-wrapper">
        <Button
          title="Tambah Jenis SPP"
          onClick={this.onClickAdd}
        />
        <Button
          title="Tambah Status"
          onClick={this.onClickAddStatus}
          // data={dataset}
        />

         </div>
         

        
        
        
        <div className="fund-request__content">
          <table className="table">
            <thead>
              <tr>
                {
                    map(JENIS_SPP_FORM_TABLE.label, (field, idx) => (
                      <th key={`table_th_${idx}`}>{field}</th>
                   ))
                  }
              </tr>
            </thead>
            <tbody>
              {
                  map(list.result, (data, idx) => (
                    <tr key={`budget_row_${idx}`}>
                      {
                      map(JENIS_SPP_FORM_TABLE.value, (field, fieldIdx) => {
                        if (fieldIdx === 0) {
                          return (
                            <td key={`table_index_${fieldIdx}_${idx}`}>{idx + 1}</td>
                          );
                        }
                        if (field.type === 'link') {
                          return (
                            <td key={`table_${fieldIdx}_${idx}`}><a href={data.hyperlink} target="_blank" rel="noopener noreferrer">{data.hyperlink}</a></td>
                              // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                          );
                        }
                        return (
                          <td className={(field.type === 'number') ? 'nominal' : ''} key={`table_${fieldIdx}_${idx}`}>
                            {formatData(data, field)}
                          </td>
                        );
                      })
                      }
                      {this._renderButtons(data)}
                    </tr>
                  ))
                }
            </tbody>
          </table>
        </div>
        <div className="user-management__table-footer">
          <p className="user-management__page-info">
            {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${list.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${list.totalPage}`}
          </p>
          <Pagination
            totalPage={list.totalPage}
            currentPage={list.currentPage}
            onClick={this.onChangePage}
          />
        </div>
      </div>
    );
  }
}
JenisSPP.propTypes = {
  handleListAnnouncement: PropTypes.func,
  handleListAnnouncementStudent: PropTypes.func,
  handleDelete: PropTypes.func,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
};
JenisSPP.defaultProps = {
  handleListAnnouncement: noop,
  handleListAnnouncementStudent:PropTypes.func,
  handleDelete: noop,
  user: null,
};

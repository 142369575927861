import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea } from '../../../../components/base/index';
import { GENDER } from '../../../../variables/common.variable';


export default function RaportKBKForm(props) {


  const { onFormChange, form, classes, param } = props;
  return (
    <div className="raport-kbk__custom-form">
      
        <div className="raport-kbk__custom-form-row">
        <div className="raport-kbk__custom-form-row__field">
        <Select
            name="classes_year"
            label="Tahun Ajaran"
            onChange={onFormChange}
            data={ param.periods}
            placeholder={''}
            value={form.value.classes_year}
            error={form.error.classes_year || ''}
          />
        </div>
        
        <div className="raport-kbk__custom-form-column__field">
            
          <Select
            name="bentuk"
            label="Bentuk"
            onChange={onFormChange}
            value={form.value.bentuk || ''}
            error={form.error.bentuk || ''}
            placeholder={'Pilihan'}
          />
          
       
         
        </div>
        </div>
        <div className="raport-kbk__custom-form-row">
        <div className="raport-kbk__custom-form-row__field">
        <Select
            name="penamaan"
            label="Penamaan Kelas"
            onChange={onFormChange}
            // data={classes.list}
            placeholder={'Pilihan'}
            value={form.value.penamaan || ''}
            error={form.error.penamaan || ''}
          />
        </div>
        
        <div className="raport-kbk__custom-form-column__field">
            
          <Select
           
            name="periode"
            label="Periode"
            onChange={onFormChange}
            value={form.value.periode || ''}
            error={form.error.periode || ''}
            placeholder={'Pilihan'}
          />
        </div>
        </div>

        <div className="raport-kbk__custom-form-row">
        <div className="raport-kbk__custom-form-row__field">
          <Select
            name="id"
            label="ID Raport"
            onChange={onFormChange}
            data={ '7A', '2019-2020'}
            placeholder={''}
            value={form.value.id || ''}
            error={form.error.id || ''}
          />
        </div>
        
        <div className="raport-kbk__custom-form-column__field">
            
          <Select
           
            name="status"
            label="Status Kenaikan"
            onChange={onFormChange}
            value={form.value.status || ''}
            error={form.error.status || ''}
            placeholder={'Pilihan'}
          />
        </div>
        </div>

        <div className="raport-kbk__custom-form-row">
       
          
         <div className="nilai-raport__custom-form-row2__field">
        <div className="nilai-raport__custom-form-row3"> 
          <Select
            name="jenis"
            label="Jenis Nilai"
            onChange={onFormChange}
            value={form.value.jenis || ''}
            error={form.error.jenis || ''}
            placeholder={'Pilihan'}
          />
          </div>
          <div className="nilai-raport__custom-form-row4">
           {/* <Select
            name="tahunajaran"
            label="Urutan di Raport"
            onChange={onFormChange}
            value={form.value.tempatLahirSaudara1 || ''}
            error={form.error.tempatLahirSaudara1 || ''}
            placeholder={'Pilihan'}
          /> */}
           <div className="raport-kbk__custom-form-row-check">
           
          <div className="button-ekskul__button-wrapper">
             
               <Input
                 type="checkbox"
                 name="kkm"
                 onChange={onFormChange}
                 value={form.value.kkm || ''}
                 error={form.error.kkm || ''}
                 
              /> 
          </div>
          &nbsp;<p>Dibawah KKM Merah </p> &nbsp;
        </div>
       </div>
         
        </div>
        
        <div className="raport-kbk__custom-form-column__field">
            
          <Select
           
            name="terbilang"
            label="Terbilang"
            onChange={onFormChange}
            value={form.value.terbilang || ''}
            error={form.error.terbilang || ''}
            placeholder={'Pilihan'}
          />
        </div>
        </div>
        

         <div className="raport-kbk__custom-form-row">
        <div className="raport-kbk__custom-form-row__field">
        <Select
            name="classes"
            label="Kelas"
            onChange={onFormChange}
            data={classes.list}
            placeholder={'Pilihan'}
            value={form.value.classes || ''}
            error={form.error.classes || ''}
          />
        </div>

        <div className="raport-kbk__custom-form-column__field">
            
        <Select
           name="absensi"
           label="Absensi"
           onChange={onFormChange}
           value={form.value.absensi || ''}
           error={form.error.absensi || ''}
           placeholder={'Pilihan'}
         />
          </div>
        </div>

        <div className="raport-kbk__custom-form-row">
        <div className="raport-kbk__custom-form-row__field">
        <Select
           
           name="ttd"
           label="Tanda Tangan Kepsek"
           onChange={onFormChange}
           value={form.value.ttd || ''}
           error={form.error.ttd || ''}
           placeholder={'Pilihan'}
         />
        </div>

        <div className="raport-kbk__custom-form-column__field">
            
        <Input
           type="text"
           name="title"
           label="Dengan Titel"
           onChange={onFormChange}
           value={form.value.title || ''}
           error={form.error.title || ''}
           placeholder={'Pilihan'}
         />
          </div>
        </div>

        <div className="raport-kbk__custom-form-row">
        <div className="raport-kbk__custom-form-row__field">
        <Input
           type="text"
           name="nama_ortu"
           label="Nama Orang Tua "
           onChange={onFormChange}
           value={form.value.nama_ortu || ''}
           error={form.error.nama_ortu || ''}
         />
        </div>

        <div className="raport-kbk__custom-form-column__field">
            
        <Input
           type="text"
           name="nama_walikls"
           label="Nama Wali Kelas"
           onChange={onFormChange}
           value={form.value.nama_walikls || ''}
           error={form.error.nama_walikls || ''}
         />
          </div>
        </div>

        

        </div>
        
 
   
  );
}
RaportKBKForm.propTypes = {
  classes: PropTypes.array,
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
RaportKBKForm.defaultProps = {
  onFormChange: noop,
  classes:[],
};

import UploadRPP from '../../../views/Kurikulum/RencanaNilai/UploadRPP.view.js';
import { connect } from 'react-redux';
import { getStudent } from '../../../states/thunks/student.thunk';
import { getClasses, getPeriodsOptions, getParamOptions, getSubjectOptions, getParamOptionsAcademics, getRppOptions, getClassesOptions } from '../../../states/thunks/options.thunk';
function mapStateToProps(state){
    return{
        classes: state.options.classes,
        user: state.user || {},
    }
}
function mapDispatchToProps(dispatch){
    return{
        handleGetClasses: payload => dispatch(getClasses(payload)),
        handleGetStudent: () => dispatch(getStudent()),
        getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
        getClassesOptions: (payload) => dispatch(getClassesOptions(payload)),
        getRppOptions: (payload) => dispatch(getRppOptions(payload)),
        getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),

    };
}
export default connect(mapStateToProps, mapDispatchToProps) (UploadRPP);
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop, map } from 'lodash';
import { Button, Pagination } from '../../../../components/base';
import { formatData,  normalizeAmount } from '../../../../utils/transformer.util';
import ListForm from '../../../../components/ListForm/ListForm.component';
import ContentTable from '../../../../components/ContentTable/ContentTable.component';
import { KELAS_DATAKELAS_TABLE_FIELDS } from '../../../../constants/Akademi/DataKelas/kelas.constant';
import { SEARCH_FIELDS} from '../../../../constants/Akademi/DataKelas/kelas.constant';
import language from '../../../../languages';
import {TAMBAH_UANG_SEKOLAH_FORM}   from '../../../../constants/finance/spp/tambah-uang-sekolah.constant';
import TambahJenisForm from './components/TambahJenisForm.component';
import ControlTable from './components/ControlTable.component';
import { JENIS_SPP_FORM_TABLE } from '../../../../constants/finance/spp/tambah-uang-sekolah.constant';
import TableTambahStatus from './components/TableTambahStatus.component';
import { STATUS_LIST_TABLE_FIELDS } from '../../../../constants/finance/spp/jenis_spp.constant';


export default class TambahStatus extends Component {
  constructor(props) {
    super(props);
    this.onEdit = this.onEdit.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._getParamOptions = this._getParamOptions.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this._onClickAdd = this._onClickAdd.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this._onClickAddStatus = this._onClickAddStatus.bind(this);
    this._onDeleteListStatus = this._onDeleteListStatus.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this._renderButtons = this._renderButtons.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
    this._setForm = this._setForm.bind(this);
    

    this.state = {
      loading: true,
      list: [],
      listAmount: 5,
      
      form: {
        value: {
          tabelstatus: [
            {
              organizations_id: 1,
              name: '',
              is_discount:'',

            }
          ],
        },
        error: {
          tabelstatus: '',
      },
    },
      param: {},
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const { state = {} } = location;
    const {isEdit =  false, data} = location.state;
    const { id } = state.data || '';
   
    if (isEdit && id) {
      this.setState({id:id})
      this._setForm(data.id);
    }
    this.handleGetData();
  }



  _onClearForm() {
    this.setState({
      form: {
        value: {},
        error: {},
      },
    });
  }

  async _setForm(id) {
    const { handleGetStatusPayments } = this.props;
    try {
      const payload = await handleGetStatusPayments({ id });
      // console.log(payload.tabelstatus)
      this.setState({
        form: {
          value: {
            id: payload.id,
            tabelstatus: map(payload.tabelstatus, detail => ({
              name: detail.name,
              is_discount: detail.is_discount,
            })),
           
            
          },
          error: {
            tabelstatus: [],
          },
        },
        
      });
      
    } catch (err) {
      // err action
    }
  }


  async _getParamOptions(type, filters = {}) {
    const { getParamOptions } = this.props;
    const res = await getParamOptions(filters, type);
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }
  _onClickAdd(data) {
    const { form } = this.state;
    const { jenisspp } = form.value;
    jenisspp.push({
      date: '',
      activities_name: '',
      teacher_id: '',
      start_hour: '',
      start_minute:'',
      end_hour: '',
      end_minute: '',
    });        
    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          jenisspp,
        },
        error: {
          ...prevState.form.error,
        }
      }
    }));
  }
  async onSubmit() {
    const { history, handleSaveStatusPayments } = this.props;
    const { form } = this.state;
    const { value } = form;
    let canSaveA = false;
    const dataTabel = value.tabelstatus;
    let a;
    for(a=0; a<dataTabel.length; a++){
      if(dataTabel[a].name){
        canSaveA = true;
      } else{
        canSaveA = false;
        break;
      }
    }
   
    if (canSaveA == false){
      alert("Harap isi tabel secara lengkap, atau hapus jika tidak diperlukan.");
    }  else {
      await handleSaveStatusPayments(value);
      history.push('/dashboard/keuangan/spp/jenis-spp');
    }
  }


  async onEdit() {
    const { history, handleEditStatusPayments } = this.props;
    const { form } = this.state;
    const { value } = form;
    let canSaveA = false;
    let canSaveB = false;
    const dataTabel = value.tabelstatus;
    let a;
    for(a=0; a<dataTabel.length; a++){
      if(dataTabel[a].name || dataTabel[a].is_discount){
        canSaveA = true;
      } else{
        canSaveA = false;
        break;
      }
    }
    
    if (canSaveA == false){
      alert("Harap isi tabel secara lengkap, atau hapus jika tidak diperlukan.");
    }
     else {
      await handleEditStatusPayments(value);
      history.push('/dashboard/keuangan/spp/jenis-spp');
    }
   
  }

  _onDeleteList(idx) {
    this.setState((prevState) => {
      const { jenisspp } = prevState.form.value;
      const list = prevState.form.value.jenisspp;
      list.splice(idx, 1);
      return {
        ...prevState,
        jenisspp: list
      };
    });
  }

  async handleGetData(payload = {}) {
    const { handleListStatusPayments, user } = this.props;
    const { units_id, workingUnit } = user;
    let unit_id = units_id;

    if (unit_id === null && workingUnit && workingUnit.id) {
      unit_id = workingUnit.id;
    }

    const res = await handleListStatusPayments({...payload});

    this.setState({
      list: res,
    });
    console.log(this.state.list)
  }

  _onDeleteListStatus(idx) {
    this.setState((prevState) => {
      const { tabelstatus } = prevState.form.value;
      const list = prevState.form.value.tabelstatus;
      list.splice(idx, 1);
      return {
        ...prevState,
        tabelstatus: list
      };
    });
  }

  _onClickAddStatus(data) {
    const { form } = this.state;
    const { tabelstatus } = form.value;
    tabelstatus.push({
      organizations_id: 1,
      name: '',
      is_discount:'',
    });        
    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          tabelstatus,
        },
        error: {
          ...prevState.form.error,
        }
      }
    }));
  }

  onClickEdit(val) {
    const { history } = this.props;
    history.push('/dashboard/keuangan/spp/tambah-status', { data: val, isEdit: true });
    history.go(0)
  }

  async onClickDelete(id) {
    const { handleDeleteStatusPayments } = this.props;
    const res = await handleDeleteStatusPayments(id);
    if(res){
      this.handleGetData();
    }
  }



  onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this.handleGetData({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  _renderButtons(data) {
    const { user } = this.props;
    const button = {};
    
    return (
      <td>
        <div className="table__actions">
         
          <Button
            onClick={() => this.onClickEdit(data)}
            title='Edit'
          />
          <Button
            onClick={() => this.onClickDelete(data)}
            title='Delete'
          />
        </div>
      </td>
    );
  }

  _onFormChange(event) {
    const {
      name,
      value,
      dataset,
      checked,
      type,
      files
    } = event.target;
    const {
      inputType = 'text', inputArray = false, arrayPosition = 0,
      fieldName,
    } = dataset;

    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;          
      if(type==="file"){
        formattedValue = files[0]
      }
      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        if (type === 'checkbox') {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
        if (name === 'code_of_account') {
          newList[arrayPosition].isCredit = value.type;
        }
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = '';
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray
              ? { [fieldName]: newListError }
              : { [name]: '' }),
          },
        },
      };
    });
  }


  render() {
    const { form, list={} } = this.state;
    const { handleGetKelas, handleUploadScheduleFile, location } = this.props;
    const { tabelstatus, jenisspp } = form.value;
    const { isEdit = true } = location.state;
    return (
      <div className="student-list">
        <div className="budget__title">
            <h1>Tambah Status</h1>
            <hr></hr>
          </div>
          <div className="cash-journal__content">
          <div className="content-table">
          
           {/* <h2>Table Tambah Status</h2> */}
           <TableTambahStatus
            list={tabelstatus}
            onAddList={this._onClickAddStatus}
            onFormChange={this._onFormChange}
            onDeleteList={this._onDeleteListStatus}
          />     
          </div>
        </div>
        <div className="student-list__header">
        <div className="student-list__button">
          {this.state.id?
           ''
          :
          <div className="student-list__button">
          <Button
          title="Tambah"
          onClick={this._onClickAddStatus}
        />
        </div>
          }
            
          </div>
          <div className="student-list__button">
          {this.state.id?
              <Button
                type="button"
                title="Update"
                onClick={this.onEdit}
              />:
              <Button
                type="button"
                title="Simpan"
                onClick={this.onSubmit}
              />
            }
          </div>
          </div>
          <br></br>
          <div className="fund-request__content">
          <table className="table">
            <thead>
              <tr>
                {
                    map(STATUS_LIST_TABLE_FIELDS.label, (field, idx) => (
                      <th key={`table_th_${idx}`}>{field}</th>
                   ))
                  }
              </tr>
            </thead>
            <tbody>
              {
                  map(list.result, (data, idx) => (
                    <tr key={`budget_row_${idx}`}>
                      {
                      map(STATUS_LIST_TABLE_FIELDS.value, (field, fieldIdx) => {
                        if (fieldIdx === 0) {
                          return (
                            <td key={`table_index_${fieldIdx}_${idx}`}>{idx + 1}</td>
                          );
                        }
                        return (
                          <td className={(field.type === 'number') ? 'nominal' : ''} key={`table_${fieldIdx}_${idx}`}>
                            {formatData(data, field)}
                          </td>
                        );
                      })
                    }
                      {this._renderButtons(data)}
                    </tr>
                  ))
                }
            </tbody>
          </table>
        </div>
        <div className="user-management__table-footer">
          <p className="user-management__page-info">
            {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${list.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${list.totalPage}`}
          </p>
          <Pagination
            totalPage={list.totalPage}
            currentPage={list.currentPage}
            onClick={this.onChangePage}
          />
        </div>
        
      </div>
    );
  }
}
TambahStatus.propTypes = {
  getParamOptions: PropTypes.func,
  handleListStatusPayments: PropTypes.func,
  handleGetStatusPayments: PropTypes.func,
  history: PropTypes.object.isRequired,
};
TambahStatus.defaultProps = {
  handleListStatusPayments: noop,
  getParamOptions: noop,
  handleGetStatusPayments: noop,
};
import React from 'react';
import PropTypes from 'prop-types';
import { noop, map, isEqual } from 'lodash';
import { Button, Pagination } from '../../../../components/base';
import BaseTable from '../../../../components/BaseTable/BaseTable.component';
import { formatData } from '../../../../utils/transformer.util';
import language from '../../../../languages';
import { MONTHLY_ACTIVITY_PLANT_TABLE_FIELDS } from '../../../../constants/finance/budgeting/MonthlyActivityPlan.constant';

export default class MonthlyActivityPlan extends BaseTable {
  constructor(props) {
    super(props);
    this.onClickAdd = this.onClickAdd.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this.onClickView = this.onClickView.bind(this);
    this.onClickRealisasi = this.onClickRealisasi.bind(this);
    this._renderButtons = this._renderButtons.bind(this);
    this.handleGetData = this.handleGetData.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.renderStatus = this.renderStatus.bind(this);

    this.state = {
      list: [],
      workflow: {},
      listAmount: 5,
    };
  }

  componentDidMount() {
    this.handleGetData();
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    const { workingUnit } = user;
    const { user: prevUser } = prevProps;
    const { workingUnit: prevWorkingUnit } = prevUser;

    if (!isEqual(workingUnit, prevWorkingUnit)) {
      this.handleGetData();
    }
  }

  async handleGetData(payload = {}) {
    const { handleGetFundRequest, user } = this.props;
    const { prm_school_units_id, workingUnit } = user;
    let unit_id = prm_school_units_id;

    if (unit_id === null && workingUnit && workingUnit.id) {
      unit_id = workingUnit.id;
    }

    const res = await handleGetFundRequest(payload);

    this.setState({
      list: res,
    });
  }

  onClickAdd() {
    const { history, type } = this.props;
    history.push('/dashboard/keuangan/penganggaran/tambah-kegiatan-bulanan', {
      isEdit: true,
      type,
    });
  }

  onClickEdit(val) {
    const { history, type } = this.props;
    history.push('/dashboard/keuangan/penganggaran/edit-rencana-kegiatan-bulanan',
      { data: val, isEdit: true, type });
  }

  onClickView(val) {
    const { history, type } = this.props;
    history.push('/dashboard/keuangan/penganggaran/edit-rencana-kegiatan-bulanan', { data: val, isEdit: false, type });
  }

  onClickRealisasi(val) {
    const { history, type } = this.props;
    history.push('/dashboard/keuangan/penganggaran/realisasi', { data: val, isEdit: false, type });
  }

  onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this.handleGetData({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  renderStatus({ is_transferred = 0, workflow = null }) {
    if (!workflow || workflow.length === 0) {
      return (
        <td><i>Belum diajukan</i></td>
      );
    }
    const { user } = this.props;
    const { user_group } = user;
    const userGroupName = user_group.name;
    const workflowLength = workflow.length;
    const lastWorkflow = workflow[workflowLength - 1] || {};
    const {
      next_role = '',
      prev_role = '',
      is_done = false,
      action = '',
    } = lastWorkflow;
    const roleMap = {
      'Kepala Sekolah': 'Kepala Sekolah',
      'Manager Opsdik': 'Manager Opsdik',
      RT: 'RT',
      Pengadaan: 'Pengadaan',
      'Manager Keuangan': 'Manager Keuangan',
      Keuangan: 'Keuangan',
      Opsdik: 'Opsdik',
      Hrd: 'Hrd',
      Sekretariat: 'Sekretariat',
      Bendahara: 'Bendahara',
    };

    let status = 'Telah mengajukan ke';

    if (is_done) {
      if (is_transferred) {
        status = 'Telah ditransfer oleh';
        return (
          <td><i>{`${status} ${prev_role}`}</i></td>
        );
      }
      status = 'Telah disetujui oleh';
      return (
        <td><i>{`${status} ${prev_role}`}</i></td>
      );
    }

    if (next_role !== userGroupName) {
      if (action === 'reject') {
        status = 'Telah dikembalikan ke';
      }

      return (
        <td><i>{`${roleMap[prev_role]} ${status} ${roleMap[next_role]}`}</i></td>
      );
    }
    status = 'Telah diajukan oleh';
    if (action === 'reject') {
      status = 'Telah dikembalikan oleh';
    }
    return (
      <td><i>{`${status} ${roleMap[prev_role]}`}</i></td>
    );
  }

  _renderButtons(data) {
    const { user } = this.props;
    const { user_group } = user;
    const { permissions = [] } = user_group;
    const { workflow } = data;
    const lastWorkflow = workflow[workflow.length - 1] || {};
    const firstWorkflow = workflow[0] || {};
    const allowRealization = lastWorkflow.is_done
      && data.is_transferred
      && user_group.name === firstWorkflow.prev_role;
    if (workflow === null || workflow.length === 0 || (
      !lastWorkflow.is_done && user_group
      && user_group.name === lastWorkflow.next_role
      && lastWorkflow.action === 'reject'
      && permissions.includes('edit_fund_request')
    )) {
      return (
        <td>
          <div className="table__actions">
            <Button
              onClick={() => this.onClickEdit(data)}
              title="Edit"
            />
            { permissions.includes('delete_fund_request')
              && (
              <Button
                onClick={async () => { await this.onClickDelete(data.id); }}
                title="Hapus"
              />
              )
            }
          </div>
        </td>
      );
    }

    if (allowRealization) {
      return (
        <td>
          <div className="table__actions">
            <Button
              onClick={() => this.onClickView(data)}
              title="View"
            />
            <Button
              onClick={() => this.onClickRealisasi(data)}
              title="Realisasi"
            />
          </div>
        </td>
      );
    }

    return (
      <td>
        <div className="table__actions">
          <Button
            onClick={() => this.onClickView(data)}
            title="View"
          />
        </div>
      </td>
    );
  }

  render() {
    const { list } = this.state;
    const { user, type } = this.props;
    const { user_group } = user;
    const { permissions } = user_group;
    let title = '';

    if (type === 'procurement') {
      title = 'Pengadaan';
    } else if (type === 'maintenance') {
      title = 'Perbaikan Sarana/Prasarana';
    } else if (type === 'activity') {
      title = 'Kegiatan';
    }

    return (
      <div className="fund-request">
        { permissions.includes('create_fund_request') && (
        <Button
          title={`Tambah Pengajuan ${title}`}
          onClick={this.onClickAdd}
        />
        )}
        <div className="fund-request__content">
          <table className="table">
            <thead>
              <tr>
                {map(MONTHLY_ACTIVITY_PLANT_TABLE_FIELDS.label,
                  (field, idx) => (
                    <th key={`table_th_${idx}`}>{field}</th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {map(list.result, (data, idx) => (
                <tr key={`budget_row_${idx}`}>
                  {map(MONTHLY_ACTIVITY_PLANT_TABLE_FIELDS.value,
                    (field, fieldIdx) => {
                      if (fieldIdx === 0) {
                        return (
                          <td key={`table_index_${fieldIdx}_${idx}`}>
                            {idx + 1}
                          </td>
                        );
                      }
                      return (
                        <td
                          className={field.type === 'number' ? 'nominal' : ''}
                          key={`table_${fieldIdx}_${idx}`}
                        >
                          {formatData(data, field)}
                        </td>

                      );
                    })}
                  {this.renderStatus(data)}
                  {this._renderButtons(data)}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="user-management__table-footer">
          <p className="user-management__page-info">
            {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${list.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${list.totalPage}`}
          </p>
          <Pagination
            totalPage={list.totalPage}
            currentPage={list.currentPage}
            onClick={this.onChangePage}
          />
        </div>
      </div>
    );
  }
}
MonthlyActivityPlan.propTypes = {
  handleGetFundRequest: PropTypes.func,
  handleDelete: PropTypes.func,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
  type: PropTypes.string.isRequired,
};
MonthlyActivityPlan.defaultProps = {
  handleGetFundRequest: noop,
  handleDelete: noop,
  user: null,
};

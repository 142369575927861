import { connect } from 'react-redux';
import ManageGeneralJournal from '../../../../views/Finance/Bookkeeping/GeneralJournal/ManageGeneralJournal.bookkeeping.view';
import { getGeneralJournalById, manageGeneralJournal } from '../../../../states/thunks/finance.thunk';
import { getJournalCoaOptions, getCoaOptions } from '../../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    coa: state.options.coa,
    unit: state.options.unit,
    user: state.user || {},
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetCoa: payload => dispatch(getCoaOptions(payload)),
    handleGetJournalCoaOptions: payload => dispatch(getJournalCoaOptions(payload)),
    handleGetGeneralJournal: payload => dispatch(getGeneralJournalById(payload)),
    handleManageGeneralJournal: (payload, goBack) => (
      dispatch(manageGeneralJournal(payload, goBack))
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageGeneralJournal);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import bankJournal from '../../../assets/img/bookkeeping/jurnal_bank.png';
import bankCash from '../../../assets/img/bookkeeping/jurnal_kas.png';

export default class Bookkeeping extends PureComponent {
  constructor(props) {
    super(props);
    this.clickHandlers = {};
    this.onClick = this.onClick.bind(this);
  }

  onClick(link) {
    const { history } = this.props;
    history.push(`/dashboard/keuangan/pembukuan/${link}`);
  }

  getClickHandler(link) {
    if (!Object.prototype.hasOwnProperty.call(this.clickHandlers, link)) {
      this.clickHandlers[link] = () => this.onClick(link);
    }
    return this.clickHandlers[link];
  }

  render() {
    return (
      <div className="bookkeeping">
         <div className="bookkeeping__list">
          <button onClick={this.getClickHandler('saldo-awal')}>
            <img src={bankJournal} alt="Saldo Awal" />
            <h3>Saldo Awal</h3>
          </button>
        </div>
        <div className="bookkeeping__list">
          <button onClick={this.getClickHandler('jurnal-bank')}>
            <img src={bankJournal} alt="Jurnal Bank" />
            <h3>Jurnal Bank</h3>
          </button>
        </div>
        <div className="bookkeeping__list">
          <button onClick={this.getClickHandler('jurnal-kas')}>
            <img src={bankCash} alt="Jurnal Kas" />
            <h3>Jurnal Kas</h3>
          </button>
        </div>
        {/* <div className="bookkeeping__list">
          <button onClick={this.getClickHandler('jurnal-pembayaran')}>
            <img src={bankCash} alt="Jurnal Pembayaran" />
            <h3>Jurnal Pembayaran</h3>
          </button>
        </div> */}
        <div className="bookkeeping__list">
          <button onClick={this.getClickHandler('jurnal-umum')}>
            <img src={bankCash} alt="Jurnal Umum" />
            <h3>Jurnal Umum</h3>
          </button>
        </div>
        {/* <div className="bookkeeping__list">
          <button onClick={this.getClickHandler('jurnal-penutup')}>
            <img src={bankCash} alt="Jurnal Penutup" />
            <h3>Jurnal Penutup</h3>
          </button>
        </div> */}
        {/* <div className="bookkeeping__list">
          <button onClick={this.getClickHandler('jurnal-non-kas')}>
            <img src={bankCash} alt="Jurnal Non Kas" />
            <h3>Jurnal Non Kas</h3>
          </button>
        </div> */}
      </div>
    );
  }
}
Bookkeeping.propTypes = {
  history: PropTypes.object.isRequired,
};

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, Textarea } from '../../../components/base/index';
import { GENDER } from '../../../variables/common.variable';

const padStr = (num) => {
  
  if (num !== "" && num !== null && typeof num !== 'undefined') {
    return num.toString().padStart(2, '0');
    
  }
  
  return null;
}
export default class PengisianDataPelanggaran extends Component {
  constructor(props){
      super(props);
      this._onFormChange = this._onFormChange.bind(this);
    
    this.state= {
      form: {
        values: {
          classrooms: '',
          nissiswa: '',
          namasiswa: '',
          tanggalpelanggaran: '',
          keterangan: '',
          alasan:'',
          sanksi:'',
          jmlpoint: '',
        },
        error: {
          classrooms: '',
        }
      }
    }
  
    }
  
    _onFormChange(event) {
      const { onChange } = this.props;
      onChange(event);
    }

  render() {
    const { onChange, form, classrooms, param, onFormChange } = this.props;
    const { value } = form;
    const Matpel = [
      { label: 'Bahasa Indonesia', value: '1' },
      { label: 'Matematika', value: '2' },
      { label: 'Pendidikan Kewarganegaraan', value: '3' },
      { label: 'Pendidikan Jasmani Olahraga Kesehatan', value: '4' },
    ];
    const hour = [
      { label: '00', value: '00'}, { label: '01', value: '01'}, { label: '02', value: '02'}, { label: '03', value: '03'}, { label: '04', value: '04'}, { label: '05', value: '05'}, { label: '06', value: '06'}, { label: '07', value: '07'}, { label: '08', value: '08'}, { label: '09', value: '09'},
      { label: '10', value: '10'}, { label: '11', value: '11'}, { label: '12', value: '12'}, { label: '13', value: '13'}, { label: '14', value: '14'}, { label: '15', value: '15'}, { label: '16', value: '16'}, { label: '17', value: '17'}, { label: '18', value: '18'}, { label: '19', value: '19'},
      { label: '20', value: '20'}, { label: '21', value: '21'}, { label: '22', value: '22'}, { label: '23', value: '23'},
    ]
    const minute = [
      { label: '00', value: '00'}, { label: '01', value: '01'}, { label: '02', value: '02'}, { label: '03', value: '03'}, { label: '04', value: '04'}, { label: '05', value: '05'}, { label: '06', value: '06'}, { label: '07', value: '07'}, { label: '08', value: '08'}, { label: '09', value: '09'},
      { label: '10', value: '10'}, { label: '11', value: '11'}, { label: '12', value: '12'}, { label: '13', value: '13'}, { label: '14', value: '14'}, { label: '15', value: '15'}, { label: '16', value: '16'}, { label: '17', value: '17'}, { label: '18', value: '18'}, { label: '19', value: '19'},
      { label: '20', value: '20'}, { label: '21', value: '21'}, { label: '22', value: '22'}, { label: '23', value: '23'}, { label: '24', value: '24'}, { label: '25', value: '25'}, { label: '26', value: '26'}, { label: '27', value: '27'}, { label: '28', value: '28'}, { label: '29', value: '29'},
      { label: '30', value: '30'}, { label: '31', value: '31'}, { label: '32', value: '32'}, { label: '33', value: '33'}, { label: '34', value: '34'}, { label: '35', value: '35'}, { label: '36', value: '36'}, { label: '37', value: '37'}, { label: '38', value: '38'}, { label: '39', value: '39'},
      { label: '40', value: '40'}, { label: '41', value: '41'}, { label: '42', value: '42'}, { label: '43', value: '43'}, { label: '44', value: '44'}, { label: '45', value: '45'}, { label: '46', value: '46'}, { label: '47', value: '47'}, { label: '48', value: '48'}, { label: '49', value: '49'},
      { label: '50', value: '50'}, { label: '51', value: '51'}, { label: '52', value: '52'}, { label: '53', value: '53'}, { label: '54', value: '54'}, { label: '55', value: '55'}, { label: '56', value: '56'}, { label: '57', value: '57'}, { label: '58', value: '58'}, { label: '59', value: '59'},
    ]
    const Period = [
      { label: '2019', value: '2019'},
      { label: '2020', value: '2020'},
      { label: '2021', value: '2021'},
    ];
    const order_of_violation = [
      { label: '1', value: '1' },{ label: '2', value: '2' },{ label: '3', value: '3' },
      { label: '4', value: '4' },{ label: '5', value: '5' },{ label: '6', value: '6' },
      { label: '7', value: '7' },{ label: '8', value: '8' },{ label: '9', value: '9' },
      { label: '10', value: '10' },
      
      
    ];
    return (
      <div className="data-pelanggaran__custom-form">
        <div className="data-pelanggaran__custom-form-column">
          <div className="data-pelanggaran__custom-form-row__field">
          <Select
            type="text"
            name="period"
            label="Tahun Ajaran"
            placeholder="Pilih Tahun Ajaran"
            onChange={onFormChange}
            data={param.periods}
            value={form.value.period}
            error={form.error.period || ''}
          />
          <div className="data-pelanggaran__custom-form-row__field"></div>
          <Select
            name="classes_id"
            label="Tingkat Kelas"
            onChange={onFormChange}
            data={param.classes}
            placeholder={'Pilihan'}
            value={form.value.classes_id}
            error={form.error.classes_id || ''}
          />
           </div>
           <div className="data-pelanggaran__custom-form-row">
            <div className="data-pelanggaran__custom-form-data-row">  
              <Select
                  type="text"
                  name="classrooms_id"
                  label="Kelas"
                  placeholder="Pilih Kelas"
                  data={param.classrooms}
                  onChange={onFormChange}
                  value={form.value.classrooms_id}
                  error={form.error.classrooms_id || ''}
                />
            </div>
            <div className="data-pelanggaran__custom-form-row__field">
              <Select
                name="hour"
                label="Jam Pelanggaran(Jam)"
                onChange={onFormChange}
                data={hour}
                placeholder="Pilih Jam"
                value={padStr(form.value.hour)}
                error={form.error.hour || ''}
              />
          <div className="data-pelanggaran__custom-form-row__field"></div>
              <Select
                name="minute"
                label="(menit)"
                onChange={onFormChange}
                data={minute}
                placeholder="Pilih Menit"
                value={padStr(form.value.minute)}
                error={form.error.minute || ''}
              />
            </div>
           </div>
           <div className="data-pelanggaran__custom-form-row">
            <Input
                type="date"
                name="date"
                label="Tanggal Pelanggaran"
                onChange={onFormChange}
                value={form.value.date}
                error={form.error.date || ''}
              />
           
          <div className="data-pelanggaran__custom-form-row__field"></div>
            <Select
              name="students_id"
              label="Nama Siswa"
              onChange={onFormChange}
              data={param.student}
              placeholder={'Pilihan'}
              value={form.value.students_id}
              error={form.error.students_id || ''}
            />  
         
            </div>
          <div className="data-pelanggaran__custom-form-row">
            <Select
              name="type_violation"
              label="Jenis Pelanggaran"
              onChange={onFormChange}
              placeholder="Pilihan"
              data={param.prm_violations}
              value={form.value.type_violation}
              error={form.error.type_violation || ''}
            />
           
          <div className="data-pelanggaran__custom-form-row__field"></div>
            <Select
              name="subject_id"
              label="Mata Pelajaran"
              onChange={onFormChange}
              data={param.subject}
              placeholder="Pilih Matapelajaran"
              value={form.value.subject_id}
              error={form.error.subject_id || ''}
            />
            </div>
  
            <div className="data-pelanggaran__custom-form-row">
            <Input
              type="number"
              name="score"
              label="Score"
              onChange={onFormChange}
              value={form.value.score}
              error={form.error.score || ''}
            />
           
          <div className="data-pelanggaran__custom-form-row__field"></div>
          <Input
              type="text"
              name="penalty"
              label="Sanksi Pelanggaran"
              onChange={onFormChange}
              value={form.value.penalty}
              error={form.error.penalty || ''}
            />
          
            </div>
            <div className="absensi-rekap__custom-form-row">
              <div className="absensi-rekap__custom-form-row__field">
                <Select
                  name="order_of_violation"
                  label="Pelanggaran Ke"
                  onChange={onFormChange}
                  data={order_of_violation}
                  placeholder="Pilihan"
                  value={form.value.order_of_violation}
                  error={form.error.order_of_violation || ''}
                />
              
              </div> 
              <div className="absensi-rekap__custom-form-column__field">  
                <Textarea
                  type="text"
                  name="description"
                  label="Keterangan"
                  style={{backgroundColor:'yellow'}}
                  onChange={onFormChange}
                  value={form.value.description}
                  error={form.error.description || ''}
                />
              </div>
            </div>
        </div>
      </div>
    );
  }
 
}
PengisianDataPelanggaran.propTypes = {
  onChange: PropTypes.func,
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
  classrooms: PropTypes.array,
};
PengisianDataPelanggaran.defaultProps = {
  onChange: noop,
  onFormChange: noop,
  classrooms: [],
};
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import { Input, Select, Textarea, SelectMultiple } from '../../../../components/base/index';
import { GENDER } from '../../../../variables/common.variable';


export default class BuatKodeMatpelForm extends Component{
  constructor(props) {
    super(props);
    this.state = {
      edit: props.status === !isEmpty(props.data),
      allOption: {
        label: "Select all",
        value: "*"
      }
    };
    this.multiselectRef = React.createRef();
    this._onFormChange = this._onFormChange.bind(this);
    this._onChangeMulti = this._onChangeMulti.bind(this);
    this._onRemoveMulti = this._onRemoveMulti.bind(this);
  }

  _onFormChange(event) {
    const { onChange } = this.props;
    onChange(event);
  }

  _onChangeMulti(value, name) {
    const{ onFormChange } = this.props;
    onFormChange({ target: {
      name,
      value,
      dataset: {},
    }});
  }
  _onRemoveMulti(selectedList, removedItem) {
    const{ onFormChange } = this.props;
    onFormChange({ target: {
      name: removedItem,
      value: selectedList,
      dataset: {},
    }});
  }

  render(){
  const spirit = [
    { label: 'SIKAP SPIRITUAL', value: 'spiritual' },
    { label: 'SIKAP SOSIAL', value: 'sosial' },
  ];
  const EKSKUL = [
    { label: 'ya', value: 'ya'},
    { label: 'tidak', value: 'tidak'},
  ]
  const tahun = [
    { label: '2019', value: '2019'},
    { label: '2020', value: '2020'},
    { label: '2021', value: '2021'},
  ]
  const tipe_matpel = [
    { label: 'Kognitif', value: 'kognitif'},
    { label: 'Praktek', value: 'praktek'},
    { label: 'Afektif', value: 'afektif'},
  ]
  const major = [
    { label: 'IPA', value: '11'},
    { label: 'IPS', value: '12'},
    { label: 'Umum', value: '13'},
    { label: 'PLAYGROUP', value: '17'},
    { label: 'KINDERGARTEN', value: '18'},
  ]
  const { onFormChange, form, classrooms, param } = this.props;
  return (
    <div className="kode-matpel__custom-form">
     <div className="kode-matpel__custom-form-row">
        <div className="kode-matpel__custom-form-row__field">
        <Input
            type="text"
            name="code_subject"
            label="Kode Pelajaran"
            onChange={onFormChange}
            value={form.value.code_subject}
            error={form.error.code_subject ||''}
           
          />
        </div>
        
        <div className="kode-matpel__custom-form-column__field">
            
          <Select
            type="text"
            name="academic_year"
            label="Tahun Ajaran"
            data= {param.periods}
            placeholder="Pilihan"
            onChange={onFormChange}
            value={form.value.academic_year}
            error={form.error.academic_year || ''}
          />
          
        </div>
        </div>
        
        <div className="kode-matpel__custom-form-row">
        <div className="kode-matpel__custom-form-row__field">
          <Input
             type="text"
             name="name"
             label="Nama Mata Pelajaran"
             onChange={onFormChange}
             value={form.value.name}
             error={form.error.name ||''}
             placeholder={'Praktek/Keterampilan/Kl 4'}
          />
        </div>
        
        <div className="kode-matpel__custom-form-column__field">
            
          <Input
            type="text"
            name="nick_name"
            label="Nama Singkat"
            onChange={onFormChange}
            value={form.value.nick_name}
            error={form.error.nick_name || ''}
          />
          
       
         
        </div>
        </div>
        <div className="kode-matpel__custom-form-row">
        <div className="kode-matpel__custom-form-row__field">
        <Select
            name="levels_id"
            label="Unit"
            disabled
            onChange={onFormChange}
            data={param.levels}
            value={form.value.levels_id}
            placeholder={'Pilihan'}
            error={form.error.levels_id || ''}
          />
         
        </div>
        
        <div className="kode-matpel__custom-form-column__field">
        <Select
            name="classes_id"
            label="Tingkat Kelas"
            onChange={onFormChange}
            data={param.classes}
            placeholder={'Pilihan'}
            value={form.value.classes_id}
            error={form.error.classes_id || ''}
          />
        </div>
        </div>
        <div className="kode-matpel__custom-form-row">
        <div className="kode-matpel__custom-form-row__field">
        {/* <Select
            name="classrooms_id"
            label="Kelas"
            onChange={onFormChange}
            data={param.classrooms}
            placeholder={'Pilihan'}
            value={form.value.classrooms_id}
            error={form.error.classrooms_id || ''}
          /> */}
          <Select
            type="text"
            name="group"
            label="Group Pelajaran"
            onChange={onFormChange}
            placeholder={'Pilihan'}
            data={param.group_matpel}
            value={form.value.group }
            error={form.error.group || ''}
          />

        
        </div>

        <div className="kode-matpel__custom-form-column__field">
          <Select
            name="major_id"
            label="Jurusan"
            onChange={onFormChange}
            data = {major}
            value={form.value.major_id}
            error={form.error.major_id || ''}
            placeholder={'Pilihan'}
          />
          </div>
        </div>
        <div className="kode-matpel__custom-form-row">
        <div className="kode-matpel__custom-form-row__field">
        <Select
           label="Pilih Sikap"
           name="sikap"
           onChange={onFormChange}
           data={spirit}
           value={form.value.sikap}
           error={form.error.sikap || ''}
           placeholder={'Pilihan'}
         />
        
        </div>

        <div className="kode-matpel__custom-form-column__field">
        <Input
            type="number"
            name="kkm"
            label="Nilai KKM"
            onChange={onFormChange}
            value={form.value.kkm }
            error={form.error.kkm || ''}
          />
        
          </div>
        </div>
        <p>Tipe Mata Pelajaran</p>
        <div className="kode-matpel__custom-form-row">
        <div className="kode-matpel__custom-form-row__field">
        {/* <Select
            type="text"
            name="tipe_matpel"
            label="Tipe Mata Pelajaran"
            data={tipe_matpel}
            placeholder="Pilihan"
            onChange={onFormChange}
            value={form.value.tipe_matpel }
            error={form.error.tipe_matpel || ''}
          /> */}
        
          
        <SelectMultiple
          name="tipe_matpel"
           style={{width:"50%"}}
           options={param.tipe_matpel}
           label="Tipe Mata Pelajaran"
           // disable	={form.value.classrooms?false:true}
           displayValue="label" // Property name to display in the dropdown options
           showCheckbox={true}
           values={form.value.tipe_matpel}
           //  selectAllText="Select All" 
           // ref={this.selectAllItems()}
           // selectedValues={form.value.students}
           // value={form.value.students}
           onRemove={value => this._onRemoveMulti(value, 'tipe_matpel')}
           // error={form.error.students || ''}
           onSelect={value => this._onChangeMulti(value, 'tipe_matpel')} 
         />
        </div>

        <div className="kode-matpel__custom-form-column-sikap__field">
        <Select
           
           name="include_ekskul"
           label="Merupakan Ekskul"
           onChange={onFormChange}
           data={EKSKUL}
           value={form.value.include_ekskul}
           error={form.error.include_ekskul || ''}
           placeholder={'Pilihan'}
         />
       
            
         
           
          </div>
        </div>
        <div className="kode-matpel__custom-form-row">
        <div className="kode-matpel__custom-form-row__field">
        {/* <Select
            type="text"
            name="tipe_matpel"
            label="Tipe Mata Pelajaran"
            data={tipe_matpel}
            placeholder="Pilihan"
            onChange={onFormChange}
            value={form.value.tipe_matpel }
            error={form.error.tipe_matpel || ''}
          /> */}
        
          
       
        </div>

        <div className="kode-matpel__custom-form-column__field">
        
          </div>
        </div>

        </div>
        
 
   
  );
        }
}
BuatKodeMatpelForm.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
  classrooms: PropTypes.array,
};
BuatKodeMatpelForm.defaultProps = {
  onFormChange: noop,
  classrooms:[],
};

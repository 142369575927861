import KomposisiSiswa from '../../../views/Kurikulum/Analisa/KomposisiSiswa.kurikulum.view.js';
import { connect } from 'react-redux';
import { getStudent } from '../../../states/thunks/student.thunk';
import { getPeriodsOptions} from '../../../states/thunks/options.thunk';


function mapDispatchToProps(dispatch){
    return{
        handleGetStudent: () => dispatch(getStudent()),
        getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
    };
}
export default connect(null, mapDispatchToProps) (KomposisiSiswa);
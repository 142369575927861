import language from '../languages';
import homeIcon from '../assets/img/menu/beranda.png';
import financeIcon from '../assets/img/menu/penganggaran.png';
import settingIcon from '../assets/img/menu/pengaturan.png';

export const SIDEBAR__DASHBOARD_MENU = [
  {
    title: language.translate.SIDEBAR__HOME,
    iconImage: homeIcon,
    path: '/dashboard',
  },
  {
    title: language.translate.SIDEBAR__FINANCE,
    iconImage: financeIcon,
    path: '/dashboard/keuangan',
    menu: [
      {
        title: language.translate.SIDEBAR__FINANCE__BUDGETING,
        icon: 'icon-minus',
        className: 'menu__left-icon--small',
        padding: 20,
        path: '/dashboard/keuangan/penganggaran',
        menu: [
          {
            title: language.translate.SIDEBAR__FINANCE__BUDGETING__BUDGET,
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/keuangan/penganggaran/anggaran',
          },
          {
            title:
              language.translate
                .SIDEBAR__FINANCE__BUDGETING__MONTHLY_ACTIVITY_PROCUREMENT,
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/keuangan/penganggaran/pengajuan-pengadaan',
          },
          {
            title:
              language.translate
                .SIDEBAR__FINANCE__BUDGETING__MONTHLY_ACTIVITY_MAINTENANCE,
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/keuangan/penganggaran/pengajuan-perbaikan',
          },
          {
            title:
              language.translate
                .SIDEBAR__FINANCE__BUDGETING__MONTHLY_ACTIVITY_ACTIVITY,
            icon: 'icon-angle-right',
            className: 'menu__left-icon--small',
            padding: 40,
            path: '/dashboard/keuangan/penganggaran/pengajuan-kegiatan',
          },
        ],
      },
    ],
  },
  {
    title: language.translate.SIDEBAR__SETTING,
    iconImage: settingIcon,
    menu: [
      {
        title: 'Menu',
        path: '/dashboard/menu',
      },
    ],
  },
  {
    title: 'Report Logged-in',
    iconImage: settingIcon,
    path: '/dashboard/report-logged-in',
  },
];

export const SIDEBAR_ADMISSION_MENU = [
  {
    title: language.translate.SIDEBAR__HOME,
    iconImage: homeIcon,
    path: '/admission',
    menu: [
      {
        title: language.translate.SIDEBAR__FINANCE__REPORT__BALANCE,
        icon: 'icon-angle-right',
        className: 'menu__left-icon--small',
        padding: 40,
        path: '/dashboard/keuangan/laporan/neraca',
      },
      {},
    ],
  },
];

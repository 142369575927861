import React, { PureComponent } from 'react';
import { isEmpty } from 'lodash';
import PersonalReportFilter from '../../../components/Filter/PersonalReport.filter.component';
import { validateRequiredFields } from '../../../utils/validation.util';

export default class TeacherEmployee extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        value: {},
        error: {},
      },
      filtered: false,
    };
    this._handleFormChange = this._handleFormChange.bind(this);
    this._onSearch = this._onSearch.bind(this);
  }

  _handleFormChange(event) {
    const {
      name,
      value,
    } = event.target;
    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          [name]: value,
        },
        error: {
          ...prevState.form.error,
          [name]: '',
        },
      },
    }));
  }

  _onSearch() {
    const { form } = this.state;
    const error = validateRequiredFields(form.value, ['year']);
    this.setState(prevState => ({
      form: {
        value: prevState.form.value,
        error,
      },
    }));
    if (isEmpty(error)) {
      this.setState({ filtered: true });
    }
  }

  render() {
    const { filtered, form } = this.state;
    return (
      <div className="teacher-employee">
        <h1>Data Guru dan Karyawan Ricci</h1>
        {
          filtered ? (
            <PersonalReportFilter
              year={form.value.year}
              error={form.error.year}
              onSearch={this._onSearch}
              onChange={this._handleFormChange}
            />
          ) : (
            <div className="teacher-employee__content">
              <table className="teacher-employee__form-table">
                <thead>
                  <tr>
                    <th rowSpan={2}>Unit</th>
                    <th colSpan={9}>Status</th>
                    <th>&nbsp;</th>
                    <th colSpan={2}>L / P</th>
                    <th>&nbsp;</th>
                    <th colSpan={2}>T / K</th>
                    <th>&nbsp;</th>
                    <th colSpan={10}>Pendidikan</th>
                    <th>&nbsp;</th>
                    <th colSpan={7}>Usia</th>
                    <th>&nbsp;</th>
                    <th colSpan={5}>Agama</th>
                  </tr>
                  <tr>
                    <td>Guru</td>
                    <td>TU</td>
                    <td>Pus</td>
                    <td>Lab</td>
                    <td>Sekre</td>
                    <td>Kary</td>
                    <td>Driver</td>
                    <td>Satpam</td>
                    <td>Jumlah</td>
                    <td>&nbsp;</td>
                    <td>-</td>
                    <td>-</td>
                    <td>&nbsp;</td>
                    <td>-</td>
                    <td>-</td>
                    <td>&nbsp;</td>
                    <td>SD</td>
                    <td>SMP</td>
                    <td>SMA</td>
                    <td>DII</td>
                    <td>DIII</td>
                    <td>SPG</td>
                    <td>S1</td>
                    <td>S2</td>
                    <td>S3</td>
                    <td>Jml</td>
                    <td>&nbsp;</td>
                    <td>&#60; 26</td>
                    <td>26-35</td>
                    <td>36-45</td>
                    <td>46-55</td>
                    <td>56-65</td>
                    <td>&#62; 65</td>
                    <td>Jml</td>
                    <td>&nbsp;</td>
                    <td>Kat</td>
                    <td>Kr</td>
                    <td>Bud</td>
                    <td>Is</td>
                    <td>Jml</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>TK I</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )
        }
      </div>
    );
  }
}
TeacherEmployee.propTypes = {};
TeacherEmployee.defaultProps = {};

import React, { Component } from "react";
import PropTypes from "prop-types";
import { noop, map, isEmpty } from "lodash";
import {
  Input,
  Select,
  Textarea,
  Button,
} from "../../../../components/base/index";
import { GENDER } from "../../../../variables/common.variable";

export default class PengisianDaftarNilaiTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      edit: props.status === !isEmpty(props.data),
    };
    this._onAddList1A = this._onAddList1A.bind(this);
    this._onDeleteList1A = this._onDeleteList1A.bind(this);
    this._onAddList2A = this._onAddList2A.bind(this);
    this._onDeleteList2A = this._onDeleteList2A.bind(this);
    this._onAddList3A = this._onAddList3A.bind(this);
    this._onDeleteList3A = this._onDeleteList3A.bind(this);
    this._onAddList4A = this._onAddList4A.bind(this);
    this._onDeleteList4A = this._onDeleteList4A.bind(this);
    this._onAddList5A = this._onAddList5A.bind(this);
    this._onDeleteList5A = this._onDeleteList5A.bind(this);
    this._onAddList6A = this._onAddList6A.bind(this);
    this._onDeleteList6A = this._onDeleteList6A.bind(this);
    this._onAddList7A = this._onAddList7A.bind(this);
    this._onDeleteList7A = this._onDeleteList7A.bind(this);
  }

  _onAddList1A() {
    const { onAddList1A, fieldName } = this.props;
    onAddList1A(fieldName);
  }
  _onDeleteList1A(idx) {
    const { onDeleteList1A, fieldName } = this.props;
    // console.log(fieldName)
    onDeleteList1A(idx, fieldName);
  }

  _onAddList2A() {
    const { onAddList2A, fieldName } = this.props;
    onAddList2A(fieldName);
  }
  _onDeleteList2A(idx) {
    const { onDeleteList2A, fieldName } = this.props;
    // console.log(fieldName)
    onDeleteList2A(idx, fieldName);
  }

  _onAddList3A() {
    const { onAddList3A, fieldName } = this.props;
    onAddList3A(fieldName);
  }
  _onDeleteList3A(idx) {
    const { onDeleteList3A, fieldName } = this.props;
    // console.log(fieldName)
    onDeleteList3A(idx, fieldName);
  }

  _onAddList4A() {
    const { onAddList4A, fieldName } = this.props;
    onAddList4A(fieldName);
  }
  _onDeleteList4A(idx) {
    const { onDeleteList4A, fieldName } = this.props;
    // console.log(fieldName)
    onDeleteList4A(idx, fieldName);
  }

  _onAddList5A() {
    const { onAddList5A, fieldName } = this.props;
    onAddList5A(fieldName);
  }
  _onDeleteList5A(idx) {
    const { onDeleteList5A, fieldName } = this.props;
    // console.log(fieldName)
    onDeleteList5A(idx, fieldName);
  }

  _onAddList6A() {
    const { onAddList6A, fieldName } = this.props;
    onAddList6A(fieldName);
  }
  _onDeleteList6A(idx) {
    const { onDeleteList6A, fieldName } = this.props;
    // console.log(fieldName)
    onDeleteList6A(idx, fieldName);
  }

  _onAddList7A() {
    const { onAddList7A, fieldName } = this.props;
    onAddList7A(fieldName);
  }
  _onDeleteList7A(idx) {
    const { onDeleteList7A, fieldName } = this.props;
    // console.log(fieldName)
    onDeleteList7A(idx, fieldName);
  }

  render() {
    const {
      onFormChange,
      form,
      param,
      list1a,
      list2a,
      list3a,
      list4a,
      list5a,
      list6a,
      list7a,
    } = this.props;
    const {development_aspect_id} = form.value;
    const rating = [
      { label: "A", value: "1" },
      { label: "B", value: "2" },
      { label: "C", value: "3" },
      { label: "D", value: "4" },
      { label: "E", value: "5" },
    ];
    // const pengisian = [
    //   { label: 'Sikap', value: 'sikap'},
    //   { label: 'Kognitif', value: 'kognitif'},
    // ]
    console.log(development_aspect_id)
    return (
      <div className="lembar-harian2__custom-form">
        <div className="lembar-harian2__custom-form-row">
          <div className="lembar-harian2__custom-form-width__field">
            
            <Select
              name="development_aspect_id"
              onChange={onFormChange}
              placeholder={"Pilihan"}
              data={param.prm_aspect}
              value={development_aspect_id}
              disabled
            />
          </div>
          <div className="lembar-harian2__custom-form-width__field"></div>
        </div>

        <br />
        <div className="lembar-harian2__custom-form-row">
          <div className="lembar-harian2__custom-form-width__field">
            <Select
              name="kd_1a"
              value={form.value.kd_1a}
              label="1. Kompetensi Dasar"
              onChange={onFormChange}
              data={param.kd_prm}
              placeholder={"Pilihan"}
            />
            <p>Muatan Materi </p>
            <table className="manage-rapbs-plafon__form-table table">
              <tbody>
                {map(list1a, (data, idx) => (
                  <tr key={`plafon_journal_data__${idx}`}>
                    <td>{idx + 1}</td>
                    <td>
                      <Textarea
                        type="text"
                        value={data.muatan_1a}
                        data-input-array
                        arrayPosition={idx}
                        data-array-position={idx}
                        data-field-name="muatan_materi_1a"
                        name="muatan_1a"
                        onChange={onFormChange}
                      />
                    </td>
                    <td>
                      <Button
                        onClick={() => this._onDeleteList1A(idx)}
                        icon="icon-trash-o"
                      />
                    </td>
                  </tr>
                ))}
                <tr className="manage-rapbs-plafon__add-more">
                  <td colSpan={10}>
                    <div>
                      <Button icon="icon-plus" onClick={this._onAddList1A} />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="lembar-harian2__custom-form-width__field">
            <Select
              name="kd_2a"
              value={form.value.kd_2a}
              label="2. Kompetensi Dasar"
              onChange={onFormChange}
              data={param.kd_prm}
              placeholder={"Pilihan"}
            />
            <p>Muatan Materi </p>
            <table className="manage-rapbs-plafon__form-table table">
              <tbody>
                {map(list2a, (data, idx) => (
                  <tr key={`plafon_journal_data__${idx}`}>
                    <td>{idx + 1}</td>
                    <td>
                      <Textarea
                        type="text"
                        value={data.muatan_2a}
                        data-input-array
                        arrayPosition={idx}
                        data-array-position={idx}
                        data-field-name="muatan_materi_2a"
                        name="muatan_2a"
                        onChange={onFormChange}
                      />
                    </td>
                    <td>
                      <Button
                        onClick={() => this._onDeleteList2A(idx)}
                        icon="icon-trash-o"
                      />
                    </td>
                  </tr>
                ))}
                <tr className="manage-rapbs-plafon__add-more">
                  <td colSpan={10}>
                    <div>
                      <Button icon="icon-plus" onClick={this._onAddList2A} />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <br />
        <div className="lembar-harian2__custom-form-row">
          <div className="lembar-harian2__custom-form-width__field">
            <Select
              name="kd_3a"
              value={form.value.kd_3a}
              label="3. Kompetensi Dasar"
              onChange={onFormChange}
              data={param.kd_prm}
              placeholder={"Pilihan"}
            />
            <p>Muatan Materi </p>
            <table className="manage-rapbs-plafon__form-table table">
              <tbody>
                {map(list3a, (data, idx) => (
                  <tr key={`plafon_journal_data__${idx}`}>
                    <td>{idx + 1}</td>
                    <td>
                      <Textarea
                        type="text"
                        value={data.muatan_3a}
                        data-input-array
                        arrayPosition={idx}
                        data-array-position={idx}
                        data-field-name="muatan_materi_3a"
                        name="muatan_3a"
                        onChange={onFormChange}
                      />
                    </td>
                    <td>
                      <Button
                        onClick={() => this._onDeleteList3A(idx)}
                        icon="icon-trash-o"
                      />
                    </td>
                  </tr>
                ))}
                <tr className="manage-rapbs-plafon__add-more">
                  <td colSpan={10}>
                    <div>
                      <Button icon="icon-plus" onClick={this._onAddList3A} />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="lembar-harian2__custom-form-width__field">
            <Select
              name="kd_4a"
              value={form.value.kd_4a}
              label="4. Kompetensi Dasar"
              onChange={onFormChange}
              data={param.kd_prm}
              placeholder={"Pilihan"}
            />
            <p>Muatan Materi </p>
            <table className="manage-rapbs-plafon__form-table table">
              <tbody>
                {map(list4a, (data, idx) => (
                  <tr key={`plafon_journal_data__${idx}`}>
                    <td>{idx + 1}</td>
                    <td>
                      <Textarea
                        type="text"
                        value={data.muatan_4a}
                        data-input-array
                        arrayPosition={idx}
                        data-array-position={idx}
                        data-field-name="muatan_materi_4a"
                        name="muatan_4a"
                        onChange={onFormChange}
                      />
                    </td>
                    <td>
                      <Button
                        onClick={() => this._onDeleteList4A(idx)}
                        icon="icon-trash-o"
                      />
                    </td>
                  </tr>
                ))}
                <tr className="manage-rapbs-plafon__add-more">
                  <td colSpan={10}>
                    <div>
                      <Button icon="icon-plus" onClick={this._onAddList4A} />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <br />
        <div className="lembar-harian2__custom-form-row">
          <div className="lembar-harian2__custom-form-width__field">
            <Select
              name="kd_5a"
              value={form.value.kd_5a}
              label="5. Kompetensi Dasar"
              onChange={onFormChange}
              data={param.kd_prm}
              placeholder={"Pilihan"}
            />
            <p>Muatan Materi </p>
            <table className="manage-rapbs-plafon__form-table table">
              <tbody>
                {map(list5a, (data, idx) => (
                  <tr key={`plafon_journal_data__${idx}`}>
                    <td>{idx + 1}</td>
                    <td>
                      <Textarea
                        type="text"
                        value={data.muatan_5a}
                        data-input-array
                        arrayPosition={idx}
                        data-array-position={idx}
                        data-field-name="muatan_materi_5a"
                        name="muatan_5a"
                        onChange={onFormChange}
                      />
                    </td>
                    <td>
                      <Button
                        onClick={() => this._onDeleteList5A(idx)}
                        icon="icon-trash-o"
                      />
                    </td>
                  </tr>
                ))}
                <tr className="manage-rapbs-plafon__add-more">
                  <td colSpan={10}>
                    <div>
                      <Button icon="icon-plus" onClick={this._onAddList5A} />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="lembar-harian2__custom-form-width__field">
            <Select
              name="kd_6a"
              value={form.value.kd_6a}
              label="6. Kompetensi Dasar"
              onChange={onFormChange}
              data={param.kd_prm}
              placeholder={"Pilihan"}
            />
            <p>Muatan Materi </p>
            <table className="manage-rapbs-plafon__form-table table">
              <tbody>
                {map(list6a, (data, idx) => (
                  <tr key={`plafon_journal_data__${idx}`}>
                    <td>{idx + 1}</td>
                    <td>
                      <Textarea
                        type="text"
                        value={data.muatan_6a}
                        data-input-array
                        arrayPosition={idx}
                        data-array-position={idx}
                        data-field-name="muatan_materi_6a"
                        name="muatan_6a"
                        onChange={onFormChange}
                      />
                    </td>
                    <td>
                      <Button
                        onClick={() => this._onDeleteList6A(idx)}
                        icon="icon-trash-o"
                      />
                    </td>
                  </tr>
                ))}
                <tr className="manage-rapbs-plafon__add-more">
                  <td colSpan={10}>
                    <div>
                      <Button icon="icon-plus" onClick={this._onAddList6A} />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <br />
        <div className="lembar-harian2__custom-form-row">
          <div className="lembar-harian2__custom-form-width__field">
            <Select
              name="kd_7a"
              value={form.value.kd_7a}
              label="7. Kompetensi Dasar"
              onChange={onFormChange}
              data={param.kd_prm}
              placeholder={"Pilihan"}
            />
            <p>Muatan Materi </p>
            <table className="manage-rapbs-plafon__form-table table">
              <tbody>
                {map(list7a, (data, idx) => (
                  <tr key={`plafon_journal_data__${idx}`}>
                    <td>{idx + 1}</td>
                    <td>
                      <Textarea
                        type="text"
                        value={data.muatan_7a}
                        data-input-array
                        arrayPosition={idx}
                        data-array-position={idx}
                        data-field-name="muatan_materi_7a"
                        name="muatan_7a"
                        onChange={onFormChange}
                      />
                    </td>
                    <td>
                      <Button
                        onClick={() => this._onDeleteList7A(idx)}
                        icon="icon-trash-o"
                      />
                    </td>
                  </tr>
                ))}
                <tr className="manage-rapbs-plafon__add-more">
                  <td colSpan={10}>
                    <div>
                      <Button icon="icon-plus" onClick={this._onAddList7A} />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="lembar-harian2__custom-form-width__field"></div>
        </div>

        <br />
        <Textarea
          type="text"
          label="Hasil Observasi"
          name="obs_result"
          value={form.value.obs_result}
          onChange={onFormChange}
          rows={5}
          style={{ backgroundColor: "yellow" }}
        />
      </div>
    );
  }
}
PengisianDaftarNilaiTable.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
PengisianDaftarNilaiTable.defaultProps = {
  onFormChange: noop,
};

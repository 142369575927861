import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, map, isEqual } from "lodash";
import StudentReportFilter from "../../../components/Filter/StudentReport.filter.component";
import ContentTable from "../../../components/ContentTable/ContentTable.component";
import ListForm from "../../../components/ListForm/ListForm.component";
import { STUDENT_UNIT_2_FORM_FIELDS } from "../../../constants/student.constant";
import { DAFTAR_NILAI_FORM_FIELDS } from "../../../constants/kurikulum/ulangan.constant";
import { validateRequiredFields } from "../../../utils/validation.util";
import {
  commaFormatted,
  normalizeAmount,
  formatData,
  dateFormat,
} from "../../../utils/transformer.util";
import { Button } from "../../../components/base/index";
import { Input, Select, Textarea } from "../../../components/base/index";
import { MATA_PELAJARAN_LIST_TABLE_FIELDS } from "../../../constants/kurikulum/kurikulum.constant";
import ReactToPrint from "react-to-print";
import logo from '../../../assets/img/logo_ricci.png';

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class CetakRaport extends PureComponent {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._onClickNext = this._onClickNext.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._setForm = this._setForm.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    const { user } = this.props;
    const { organizations_id } = user;

    this.state = {
      page: 1,
      selected: {},
      content: initialContent,
      unchecked: {},
      filters: {},
      list: [],
      form: {
        value: {
          organizations_id: organizations_id,
          listraport: [
            {
              score: "",
              subject_id: "",
              descriptions: "",
            },
          ],
        },
        error: {
          listraport: "",
        },
      },
      param: {},
    };
  }

  componentDidMount() {
    const { location, getLevels, getclassrooms, user } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { content } = this.state;
    const { id } = state.data || "";
    const paramTypes = ["classrooms", "levels"];
    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
    // console.log(data)
    if (isEdit && id) {
      // this.setState({students_id:id})
      // this.setState(prevState => ({
      //   form: {
      //     value: {
      //       ...prevState.form.value,
      //       students_id:id,
      //       organizations_id: 4,
      //     },
      //     error: {
      //       ...prevState.form.error,
      //     }
      //   }
      // }));
      this._setForm(id, data);
    }

    // this._getStudentOptions();
    // this._getFolderOptions();

    // this._getclassrooms();
    // getclassrooms();
    // getLevels();
    // if (id) {
    //   this.setState({id:id})
    //   this._handleLoadData(id)
    // }

    this._getPeriodsOptions();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";
    if (!isEqual(prevProps, this.props)) {
      this._onSearchContent({ page: 1 });
    }
  }

  async _onSearchContent(params = {}, id, data) {
    const { filters, form } = this.state;
    const { value } = form;
    const { subject_id } = value;
    this.setState(
      {
        content: initialContent,
      },
      async () => {
        try {
          const { handleGetRaportSiswa, user } = this.props;
          const { organizations_id, school_unit } = user;
          const { units_id } = school_unit;
          if (id) {
            const payload = await handleGetRaportSiswa({
              id,
              organizations_id: organizations_id,
              units_id,
            });
            this.setState({
              form: {
                value: {
                  id: payload.id,
                  students_id: payload.students_id,
                  classrooms_id: data.classrooms_id,
                  semester: payload.semester,
                  organizations_id: organizations_id,
                  name_period: payload.name_period,
                  homeroom_teacher: payload.homeroom_teacher,
                  subtitute_home_teacher_name:payload.subtitute_home_teacher_name,
                  location: payload.location,
                  period: payload.period,
                  average_value: payload.average_value,
                  listraport: map(payload.listraport, (detail) => ({
                    subject_id: detail.subject_id,
                    name: detail.name,
                    kkm: detail.kkm,
                    score: detail.score,
                    descriptions: detail.descriptions,
                  })),
                },
                error: {
                  listraport: [],
                },
              },
            });
          }
        } catch (err) {
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listraport: [],
              },
            },
          }));
        }
      }
    );
  }

  _onChangeListAmount(event) {
    const { keywords } = this.state;
    this.setState({
      listAmount: Number(event.target.value),
    });
    this._onSearchContent({ limit: event.target.value, keywords, page: 1 });
  }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => this._onSearchContent()
    );
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id } = user;
    if (type === "classrooms" || type === "classrooms") {
      filters.organizations_id = organizations_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async onEdit() {
    const { history, handleEditLembarObsHarian } = this.props;
    const { form } = this.state;
    const { value } = form;
    let canSaveA = false;
    let canSaveB = false;
    const dataTabel = value.pelajaran;
    const dataForm = form.value;
    let a;
    await handleEditLembarObsHarian(value);
    history.push(
      "/dashboard/kurikulum/ulangan/pengisian-lembar-observasi-harian"
    );
    // }
  }

  async _getPeriodsOptions() {
    const { getPeriodsOptions } = this.props;
    const res = await getPeriodsOptions();
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  async _setForm(id, data = {}) {
    const { handleGetDaftarNilai, user } = this.props;
    const { organizations_id } = user;
    try {
      const payload = await handleGetDaftarNilai({ id });
      console.log(id);
      this.setState({
        form: {
          value: {
            id: payload.id,
            students_id: payload.students_id,
            assessment_date: payload.assessment_date,
            academics_type: payload.academics_type,
            semester: payload.semester,
            content_type: payload.content_type,
            indicator_value: payload.indicator_value,
            observation_result: payload.observation_result,
            expectation: payload.expectation,
            indicator_value_kognitif: payload.indicator_value_kognitif,
            observation_result_kognitif: payload.observation_result_kognitif,
            expectation_kognitif: payload.expectation_kognitif,
            teacher_notes: payload.teacher_notes,
            organizations_id: organizations_id,
            period: payload.period,
            name_period: payload.name_period,
            homeroom_teacher: payload.homeroom_teacher,
            subtitute_home_teacher_name: payload.subtitute_home_teacher_name,
            location: payload.location,
            indicator_value_social: payload.indicator_value_social,
            observation_result_social: payload.observation_result_social,

            indicator_value_language: payload.indicator_value_language,
            observation_result_language: payload.observation_result_language,

            indicator_value_fismot: payload.indicator_value_fismot,
            observation_result_fismot: payload.observation_result_fismot,

            indicator_value_art: payload.indicator_value_art,
            observation_result_art: payload.observation_result_art,
          },
          error: {
            // details: [],
          },
        },
      });
    } catch (err) {
      // err action
    }
  }

  async onSubmit() {
    const { history, handleSaveRaportSiswa } = this.props;
    const { form } = this.state;
    const { value } = form;
    let canSaveA = false;
    const dataForm = form.value;
    await handleSaveRaportSiswa(value);
    history.push("/dashboard/kurikulum/raport/daftar-nilai-raport");
    history.go(0);
  }

  _onFormChange(event) {
    const { user } = this.props;
    const { organizations_id } = user;
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          if (type === "checkbox") {
            formattedValue = checked ? value : "";
          }
          newList = prevState.form.value[fieldName];
          newListError = prevState.form.error[fieldName] || [];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {
        if (name === "levels_id") {
          this._getParamOptionsAcademics("classrooms", {
            levels_id: value,
            organizations_id,
          });
        }
        // if (name === 'classes_id') {
        //   this._getParamOptions( 'classes', { filters: { levels_id: value, organizations_id: 4 }} );
        // }
        // if (name === 'classes') {
        //   this._getStudentOptions({ filters: { classes_id: value.map(item => item.value)}} );
        // }
      }
    );
  }

  _onClickNext() {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/laporan/daftar-siswa-kelas-unit");
  }

  render() {
    const { location, getLevels, getclassrooms, user } = this.props;
    const { form, param } = this.state;
    const { state = {} } = location;
    const { listraport } = form.value;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";
    const { school_unit } = user;
    const semester_data = [
      { label: "Ganjil", value: "ganjil" },
      { label: "Genap", value: "genap" },
    ];
    const componentRef = React.createRef();
    var sem;
    if (form.value.semester === "ganjil"){
      sem="I (Satu)";
    }else if (form.value.semester === "genap"){
      sem="II (Dua)";
    }
    
    return (
      <div className="student-list">
        <div className="budget__title">
          <h1>Nilai Raport </h1>
          <hr></hr>
        </div>
        <ReactToPrint
          trigger={() => (
            <div className="form-group-member">
              <Button title="Cetak" />
            </div>
          )}
          content={() => componentRef.current}
        />
        <form style={{ padding: '20px' }} onSubmit={this._onSubmit} ref={componentRef}>
          <div className="manage-registration">
            <table className="table-report-header">
              <tr>
                <th rowSpan="2">
                  <img className="home__logo3" src={logo} />
                </th>
                <th style={{ paddingRight: '10rem' }}>
                  <h4>{school_unit.name}</h4>
                </th>
              </tr>
              <tr>
                <th style={{ paddingRight: '10rem' }}>
                  <h3>LAPORAN PERKEMBANGAN PESERTA DIDIK</h3>
                  </th>
              </tr>
            </table>
          </div>
          <div className="laporan-ki4__header-title3">
            <div className="laporan-ki4__header-title3--left">
              <table className="laporan-ki4__table-test">
                <tr>
                  <td></td>
                  <td>Nama </td>
                  <td> : {data ? data.name : ""}</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Kelas</td>
                  <td> : {data ? data.classrooms_name : ""}</td>
                </tr>
              </table>
            </div>
            <div className="laporan-ki4__header-title3--right">
              <table className="laporan-ki4__table-test">
                <tr>
                  <td></td>
                  <td>Semester</td>
                  <td> : {sem}</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Tahun Pelajaran</td>
                  <td> : {form.value.name_period}</td>
                </tr>
              </table>
            </div>
          </div>
          <div className="manage-registration">
            <div className="fund-request__content">
              <table className="table">
                <thead>
                  <tr>
                    <th>PERKEMBANGAN MORAL</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p>{form.value.observation_result}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="manage-registration">
            <div className="fund-request__content">
              <table className="table">
                <thead>
                  <tr>
                    <th>PERKEMBANGAN SOSIAL EMOSIONAL</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p>{form.value.observation_result_social}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="manage-registration">
            <div className="fund-request__content">
              <table className="table">
                <thead>
                  <tr>
                    <th>PERKEMBANGAN BAHASA</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p>{form.value.observation_result_language}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="manage-registration">
            <div className="fund-request__content">
              <table className="table">
                <thead>
                  <tr>
                    <th>PERKEMBANGAN FISIK MOTORIK</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p>{form.value.observation_result_fismot}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="manage-registration">
            <div className="fund-request__content">
              <table className="table">
                <thead>
                  <tr>
                    <th>PERKEMBANGAN KOGNITIF</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p>{form.value.observation_result_kognitif}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="manage-registration">
            <div className="fund-request__content">
              <table className="table">
                <thead>
                  <tr>
                    <th>PERKEMBANGAN SENI</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p>{form.value.observation_result_art}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="manage-registration">
            <div className="fund-request__content">
              <table className="table-raport">
                <thead>
                  <tr>
                    <th colSpan="2">KEHADIRAN </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Sakit </td>
                    <td> : 0 hari </td>
                  </tr>
                  <tr>
                    <td>Ijin </td>
                    <td> : 0 hari </td>
                  </tr>
                  <tr>
                    <td>Tanpa Keterangan </td>
                    <td> : 0 hari </td>
                  </tr>
                </tbody>
                <tfoot>
                  <th colSpan="2">JUMLAH : 0 HARI </th>
                </tfoot>
              </table>
            </div>
          </div>

          <div className="laporan-ki4__header-title3">
            <div className="laporan-ki4__header-title3--left">
              <table className="laporan-ki4__table-test">
                <tr>
                  <th>&emsp;</th>
                </tr>
                <tr>
                  <th>&emsp;</th>
                </tr>
                <tr>
                  <th>&emsp;</th>
                </tr>
                <tr>
                  <th>Orang Tua</th>
                </tr>
              </table>
            </div>
            <div className="laporan-ki4__header-title3--right">
              <table className="laporan-ki4__table-test">
                <tr>
                  <th>&emsp;</th>
                </tr>
                <tr>
                  <th>&emsp;</th>
                </tr>
                <tr>
                  <th>&emsp;</th>
                </tr>
                <tr>
                  <th colSpan="2">Wali Kelas</th>
                </tr>
                <tr>
                  <th>&emsp;</th>
                </tr>
                <tr>
                  <th>&emsp;</th>
                </tr>
                <tr>
                  <th>&emsp;</th>
                </tr>
                <tr>
                  <th>{form.value.homeroom_teacher}</th>
                  <th>{form.value.subtitute_home_teacher_name}</th>
                </tr>
              </table>
            </div>
            <div className="laporan-ki4__header-title3--right">
              <table className="laporan-ki4__table-test">
                <tr>
                  <th>
                    {form.value.location},{" "}
                    {dateFormat(form.value.assessment_date)}
                  </th>
                </tr>
                <tr>
                  <th>&emsp;</th>
                </tr>
                <tr>
                  <th>Mengetahui,</th>
                </tr>
                <tr>
                  <th>Kepala {school_unit.name}</th>
                </tr>
                <tr>
                  <th>&emsp;</th>
                </tr>
                <tr>
                  <th>&emsp;</th>
                </tr>
                <tr>
                  <th>&emsp;</th>
                </tr>
                <tr>
                  <th>{school_unit.headmaster}</th>
                </tr>
              </table>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
CetakRaport.propTypes = {
  handleGetKlapper: PropTypes.func,
  handleDeleteKlapper: PropTypes.func,
};
CetakRaport.defaultProps = {
  handleGetKlapper: noop,
  handleDeleteKlapper: noop,
};

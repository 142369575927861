import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { map, noop, isEmpty } from 'lodash';
import { commaFormatted, dateFormat } from '../../../../utils/transformer.util';
import { Button } from '../../../../components/base';
import { printJournalKAS } from '../../../../utils/print.util';

function onClickPrint(header, content, footer) {
  printJournalKAS(header, content, footer);
}

const counter = (index, sub)  => (index !== 0) ? index + 2 + sub : sub + 1

export default class GeneralJournal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      journal: {},
    };
    this._onGetDetails = this._onGetDetails.bind(this);
    this._renderRows = this._renderRows.bind(this);
  }

  componentDidMount() {
    this._onGetDetails();
  }

  async _onGetDetails() {
    const { handleGetGeneralJournal, match } = this.props;
    const { id } = match.params;
    try {
      const payload = await handleGetGeneralJournal({ journal_number: id });
      this.setState({
        journal: payload,
      });
    } catch (err) {
      // err action
    }
  }

  _renderRows() {
    const { journal } = this.state;
    const { details = [] } = journal;
    const rows = [];

    details.forEach((row, index) => {
      const comp = (
        <tr key={`journal_details_${index}`}>
          <td>{index+1}</td>
          <td>{row.code_of_account}</td>
          <td>{row.description}</td>
          <td>{commaFormatted(row.debit)}</td>
          <td>{commaFormatted(row.credit)}</td>
        </tr>
      );
      rows.push(comp);
    });
    return rows;
  }

  render() {
    const { journal } = this.state;
    return (
      <div>
        <Button
          title="Print"
          onClick={() => {
            onClickPrint('detail-bank-journal__header',
              'detail-bank-journal__table',
              'detail-bank-journal__footer');
          }}
        />
        <div id="detail-bank-journal" className="detail-bank-journal">
          {
            !isEmpty(journal) && (
              <div className="detail-bank-journal__content">
                <div className="detail-bank-journal__header">
                  <div className="detail-bank-journal__header-top">
                    <div className="detail-bank-journal__header-top--left">
                    </div>
                    <div className="detail-bank-journal__header-title">
                      <h1>{'BUKTI UMUM'}</h1>
                    </div>
                    <div className="detail-bank-journal__header-top--right">
                      <p>Nomor: {journal.journal_number}</p>
                      <p>Tanggal: {dateFormat(journal.date)}</p>
                    </div>
                  </div>
                </div>
                <div className="detail-bank-journal__body">
                  <table className="detail-bank-journal__table">
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th>Kode</th>
                        <th>Keterangan Transaksi</th>
                        <th>Debit</th>
                        <th>Kredit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this._renderRows()}
                    </tbody>
                  </table>
                </div>
                {/* <div className="pagebreak" /> */}
                <div className="detail-bank-journal__footer">
                  <div className="detail-bank-journal__footer-bottom">
                    <div>
                      <p>Diserahkan oleh:</p>
                      <p>{journal.submitted_by}</p>
                    </div>
                    <div>
                      <p>Diterima oleh:</p>
                      <p>{journal.accepted_by}</p>
                    </div>
                    <div>
                      <p>Dibukukan oleh:</p>
                      <p>{journal.booked_by}</p>
                    </div>
                    <div>
                      <p>Diketahui oleh:</p>
                      <p>Name 4</p>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        </div>
      </div>
    );
  }
}
GeneralJournal.propTypes = {
  handleGetGeneralJournal: PropTypes.func,
  match: PropTypes.object.isRequired,
};
GeneralJournal.defaultProps = {
  handleGetGeneralJournal: noop,
};

import { connect } from 'react-redux';
import AddUploadRapbu from '../../../../views/Finance/Budgeting/Budget/AddUploadRapbu.finance.view';
import { uploadRapbuFile } from '../../../../states/thunks/finance.thunk';
import { getPeriodeOptions, getBudgetOptions } from '../../../../states/thunks/options.thunk';


function mapDispatchToProps(dispatch) {
  return {
    handleUploadRapbuFile: (payload, goBack) => dispatch(uploadRapbuFile(payload, goBack)),
  };
}

export default connect(null, mapDispatchToProps)(AddUploadRapbu);

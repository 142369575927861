import React, { Component } from 'react';
import swal from 'sweetalert';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Button, Pagination } from '../../../../components/base';
import BudgetRapbuRekap from './components/BudgetRapbuRekap.component';
import language from '../../../../languages';
import { normalizeAmount } from '../../../../utils/transformer.util';
import { printRekapRapbs } from '../../../../utils/print.util';

export default class ManageBudgetRekapRapby extends Component {
  constructor(props) {
    super(props);
    this.onSetForm = this.onSetForm.bind(this);
    this.onClickExcel = this.onClickExcel.bind(this);
    this.onChangeRecommendation = this.onChangeRecommendation.bind(this);
    this.onSaveRecommendations = this.onSaveRecommendations.bind(this);
    this.onSubmitApproval = this.onSubmitApproval.bind(this);
    this.onReject = this.onReject.bind(this);
    this.onSelectBudgetDetail = this.onSelectBudgetDetail.bind(this);
    this.setRemarks = this.setRemarks.bind(this);
    this.confirmReject = this.confirmReject.bind(this);
    this.onSearchTema = this.onSearchTema.bind(this);
    this.hasRecommendations = this.hasRecommendations.bind(this);
    this.remarks = React.createRef();
    this.onChangePage = this.onChangePage.bind(this);
    this.getData = this.getData.bind(this);
    this.clickHandlers = {};

    this.state = {
      list: [],
      listAmount: 5,
      tema: [],
      modalVisible: false,
      budgetloop: [],
      dataloop: [],
      recommendations: {},
      selectedBudgetDetails: [],
      remarks: '',
    };
    // console.log(this.state)
  }

  componentDidMount() {
    this.getData();
    this.onSetForm();
    this.onSearchTema();
  }

  onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this.getData({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  onClickExcel(type) {
    const { location, history } = this.props;
    const { head_unique_id } = location.state;
    if (type === 'REKAP_RAPBU') {
      history.push('/dashboard/keuangan/penganggaran/rekap-rapby-excel', {
        type,
        code: '',
        head_unique_id,
      });
    }
  }

  async onSetForm(payload) {
    const { handleGetBudgetRapbuRekapDetail, location } = this.props;
    const { account_unique_id, head_unique_id } = location.state;
    const res = await handleGetBudgetRapbuRekapDetail({
      account_unique_id, ...payload, head_unique_id, type: 'REKAP_RAPBU',
    });
    // let divisiValue = parseInt(divisi[0].value);

    this.setState({
      list: res,
      budgetloop: res.result.budget,
      dataloop: res.result.data,
    });
  }

  onSearchTema(keyword = null) {
    const { handleGetTemaOptions } = this.props;
    handleGetTemaOptions({
      keyword,
    });
  }

  onChangeRecommendation(event) {
    const { target } = event;
    const { dataset, value, name } = target;
    const { referenceid } = dataset;
    const { recommendations } = this.state;

    if (recommendations[name] === null) {
      recommendations[name] = {};
    }

    recommendations[name][referenceid] = normalizeAmount(value);

    this.setState(prevState => ({
      ...prevState,
      recommendations: {
        ...recommendations,
      },
    }));
  }

  onSubmitApproval() {
    const { recommendations, selectedBudgetDetails } = this.state;
    const { handleSubmitApproval, location, history } = this.props;
    const { head_unique_id } = location.state;

    handleSubmitApproval({
      head: head_unique_id,
      recommendations,
      selectedBudgetDetails,
    }, history.goBack);
  }

  onSaveRecommendations() {
    const { recommendations } = this.state;
    const { handleSaveRecommendations, history } = this.props;

    handleSaveRecommendations({
      recommendations,
    }, history.goBack);
  }

  onReject(remarks) {
    const { handleReject, history, location } = this.props;
    const { recommendations } = this.state;
    const { head_unique_id } = location.state;

    handleReject({
      head: head_unique_id,
      recommendations,
      remarks,
    }, history.goBack);
  }

  onSelectBudgetDetail(event) {
    const { target } = event;
    const { value, checked } = target;
    const { selectedBudgetDetails } = this.state;
    const index = selectedBudgetDetails.indexOf(value);
    const newArray = selectedBudgetDetails.slice(0, selectedBudgetDetails.length);

    if (index !== -1) {
      if (checked === false) {
        newArray.splice(index, 1);
      }
    } else if (checked) {
      newArray.push(value);
    }

    this.setState(prevState => ({
      ...prevState,
      selectedBudgetDetails: newArray,
    }));
  }

  async getData(payload = {}) {
    const { handleGetBudgetRapbuRekapDetail, location } = this.props;
    const { account_unique_id, head_unique_id } = location.state;
    const res = await handleGetBudgetRapbuRekapDetail({
      account_unique_id,
      ...payload,
      head_unique_id,
      type: 'REKAP_RAPBU',
    });

    this.setState({
      list: res,
      budgetloop: res.result.budget,
      dataloop: res.result.data,
    });
  }

  getClickHandler(type, code) {
    if (!Object.prototype.hasOwnProperty.call(this.clickHandlers, type)) {
      this.clickHandlers[type] = () => this.onClickExcel(type, code);
    }
    return this.clickHandlers[type];
  }

  setRemarks(event) {
    const { value } = event.target;

    this.setState(prevState => ({
      ...prevState,
      remarks: value,
    }));
  }

  setModalVisible(visible) {
    this.setState({ modalVisible: visible });
  }

  confirmReject() {
    const el = this.remarks.current.input;
    el.classList.remove('hidden');
    return swal({
      title: language.translate.ALERT__ARE_YOU_SURE,
      text: 'Masukkan remarks untuk menolak RAPBS',
      content: el,
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((reject) => {
      if (reject) {
        this.onReject(el.value);
      }
    });
  }

  hasRecommendations() {
    const { recommendations } = this.state;

    return Object.keys(recommendations).some((key) => {
      const items = recommendations[key];
      if (items !== null && Object.keys(items).length > 0) {
        return Object.keys(items).some((referenceid) => {
          const value = items[referenceid];
          return value !== null && value > 0;
        });
      }
      return false;
    });
  }


  render() {
    const {
      list, dataloop, budgetloop, recommendations, currentPage, totalPage,
    } = this.state;

    const {
      location, user, tema = {},
    } = this.props;
    const { type, code } = location.state;
    return (


      <div className="content_wrapper">
        <div className="table_title">
          <h1>
            {language.translate.FINANCE__BUDGETING__MANAGE_BUDGET_REKAP__TITLE}&nbsp;
            {language.translate[`FINANCE__BUDGETING__MANAGE_BUDGET__${type}`]}
          </h1>
          <h2>
            {language.translate.FINANCE__BUDGETING__MANAGE_BUDGET_REKAP__RAPBY}&nbsp;
          </h2><br />
        </div>


        <div className="print">
          <Button
            title="Print"
            onClick={() => {
              printRekapRapbs('table_title', 'table', {
                pageSize: 'A4 landscape',
                printHeader: {
                  repeated: false,
                },
              });
            }}
          />
        </div>
        <br />
        <div className="content_wrapper">
          <Button
            title="Export"
            style={{ width: 175 }}
            onClick={this.getClickHandler('REKAP_RAPBU', '')}
          />
        </div>
        <br />

        <BudgetRapbuRekap
          code={code}
          budgetLoop={budgetloop}
          dataLoop={dataloop}
          recommendations={recommendations}
          onChangeRecommendation={this.onChangeRecommendation}
          onSelectBudgetDetail={this.onSelectBudgetDetail}
          user={user}
          onSetForm={this.onSetForm}
          onSearchTema={this.onSearchTema}
          tema={tema}
          totalPage={totalPage}
          currentPage={currentPage}
        />
        <div className="user-management__table-footer">
          <p className="user-management__page-info">
            {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${list.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${list.totalPage}`}
          </p>
          <Pagination
            totalPage={list.totalPage}
            currentPage={list.currentPage}
            onClick={this.onChangePage}
          />
        </div>
      </div>
    );
  }
}
ManageBudgetRekapRapby.propTypes = {
  handleGetBudgetRapbuRekapDetail: PropTypes.func,
  handleSaveRecommendations: PropTypes.func,
  handleSubmitApproval: PropTypes.func,
  handleReject: PropTypes.func,
  tema: PropTypes.object,
  handleGetTemaOptions: PropTypes.func,
  location: PropTypes.object.isRequired,
  user: PropTypes.object,
  history: PropTypes.object,
};
ManageBudgetRekapRapby.defaultProps = {
  handleGetBudgetRapbuRekapDetail: noop,
  handleSaveRecommendations: noop,
  handleSubmitApproval: noop,
  handleReject: noop,
  handleGetTemaOptions: noop,
  tema: noop,
  user: null,
  history: null,
};

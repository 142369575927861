import { connect } from 'react-redux';
import ManageMonthlyActivityPlan from '../../../../views/Finance/Budgeting/MonthlyActivityPlan/ManageMonthlyActivityPlan.finance.view';
import {
  getMonthlyActivityPlantCoa, getAvailableBudgetDetailsRKB, addFundRequest,
  getAvailableBudgetDetailsRKBTema, submitFundRequest, getFundRequestById,
  rejectFundRequest, getBudgetCheckList, getMonthlyActivityPlantTema,
  transferFundRequest, uploadFileRKB,
} from '../../../../states/thunks/finance.thunk';
import {
  getCoaOptions,
  getMonthOptions,
  getTemaFilterOptions,
  getUnitOptions,
} from '../../../../states/thunks/options.thunk';
import { setWorkingUnit } from '../../../../states/thunks/auth.thunk';

function mapStateToProps(state) {
  return {
    options: state.options || {},
    month: state.options.month,
    coa: state.options.coa,
    tema: state.options.tema,
    unit: state.options.unit,
    user: state.user || {},
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetTemaOptions: payload => dispatch(getTemaFilterOptions(payload)),
    handleGetCoaOptions: payload => dispatch(getCoaOptions(payload)),
    handleGetMonthOptions: payload => dispatch(getMonthOptions(payload)),
    handleMonthlyActivityPlantCoa: payload => dispatch(getMonthlyActivityPlantCoa(payload)),
    handleMonthlyActivityPlantTema: payload => dispatch(getMonthlyActivityPlantTema(payload)),
    handleGetAvailableBudgetDetailsRKB: payload => dispatch(getAvailableBudgetDetailsRKB(payload)),
    handleTransferFund: payload => dispatch(transferFundRequest(payload)),
    handleGetAvailableBudgetDetailsRKBTema:
      payload => dispatch(getAvailableBudgetDetailsRKBTema(payload)),
    handleAddFundRequest: (payload, goBack) => dispatch(addFundRequest(payload, goBack)),
    handleSubmitApproval: (payload, goBack) => dispatch(submitFundRequest(payload, goBack)),
    handleGetFundRequestById: payload => dispatch(getFundRequestById(payload)),
    handleReject: (payload, goBack) => dispatch(rejectFundRequest(payload, goBack)),
    onSearchUnit: payload => dispatch(getUnitOptions(payload)),
    handleSetWorkingUnit: payload => dispatch(setWorkingUnit(payload)),
    handleGetBudgetCheckList: payload => dispatch(getBudgetCheckList(payload)),
    handleUploadFileRKB: payload => dispatch(uploadFileRKB(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageMonthlyActivityPlan);

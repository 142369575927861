import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import StudentReportFilter from '../../../components/Filter/StudentReport.filter.component';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { SEARCH_SISWA } from '../../../constants/student.constant';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted, normalizeAmount } from '../../../utils/transformer.util';
import { Button } from '../../../components/base/index';
import StudentNumberPage1 from './components/StudentNumberPage1.component';


export default class SearchSiswa extends Component {
        constructor(props) {
          super(props);
          this._onFormChange = this._onFormChange.bind(this);
          this.onSubmit = this.onSubmit.bind(this);
          this._onClickNext = this._onClickNext.bind(this);
          // this._searchCoa = this._searchCoa.bind(this);
          //this.deletePlafon = this.deletePlafon.bind(this);
          //this._onAddList = this._onAddList.bind(this);
          this._getAllStudentOptions = this._getAllStudentOptions.bind(this);
          this._onClickAdd = this._onClickAdd.bind(this);
          this.onCetak = this.onCetak.bind(this);
          this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
          this._getClassesOptions= this._getClassesOptions.bind(this);
          this._getPeriodsOptions= this._getPeriodsOptions.bind(this);
          this.state = {
            page: 1,
            form: {
              value: {
                
              },
              error: {},
            },
            param: {},
            filtered: false,
          };
          this._handleFormChange = this._handleFormChange.bind(this);
          this._onSearch = this._onSearch.bind(this);
        }
      
        componentDidMount() {
          const { handleGetCodeOfAccount, location, handleGetClasses, handleGetLevels } = this.props;
          const paramTypes = ['classrooms','levels', 'classrooms'];
          const { content } = this.state;
          paramTypes.forEach((type) => {
            this._getParamOptionsAcademics(type);
            
          });
          this._getAllStudentOptions();
          this._getClassesOptions();
          this._getPeriodsOptions();       
          handleGetClasses();
         handleGetLevels();
        }



        async _getClassesOptions(filters={}) {
          const { getClassesOptions } = this.props;
          const res = await getClassesOptions(filters);
          // console.log(res)
          this.setState(prevState => ({
            ...prevState,
            param: {
              ...prevState.param,
              classes: res,
            },
          }));
        }

        async _getAllStudentOptions(filters={}) {
          const { getAllStudentOptions } = this.props;
          const res = await getAllStudentOptions(filters);
          // console.log(res)
          this.setState(prevState => ({
            ...prevState,
            param: {
              ...prevState.param,
              student: res,
            },
          }));
        }

        async _getPeriodsOptions() {
          const { getPeriodsOptions } = this.props;
          const res = await getPeriodsOptions();
          this.setState(prevState => ({
            ...prevState,
            param: {
              ...prevState.param,
              periods: res,
            },
          }));
          
        }
      
        // on formchange untuk form
        _handleFormChange(event) {
            const {
              name,
              value,
            } = event.target;
            this.setState(prevState => ({
              form: {
                value: {
                  ...prevState.form.value,
                  [name]: value,
                },
                error: {
                  ...prevState.form.error,
                  [name]: '',
                },
              },
            }));
          }
          async _getParamOptionsAcademics(type, filters={}) {
            const { getParamOptionsAcademics } = this.props;
            if (type === 'classes' || type === 'classrooms') {
              filters.organizations_id = 4;
            } else {
              delete filters.organizations_id;
            }
            const res = await getParamOptionsAcademics(filters, type);
            this.setState(prevState => ({
              ...prevState,
              param: {
                ...prevState.param,
                [type]: res,
              },
            }));
          }
        
          _onSearch() {
            const { form } = this.state;
            const error = validateRequiredFields(form.value, ['year']);
            this.setState(prevState => ({
              form: {
                value: prevState.form.value,
                error,
              },
            }));
            if (isEmpty(error)) {
              this.setState({ filtered: true });
            }
          }
        _onFormChange(event) {
          const {
            name,
            value,
            dataset,
            checked,
            type,
          } = event.target;
          const {
            inputType = 'text', inputArray = false, arrayPosition = 0,
            fieldName,
          } = dataset;
          this.setState((prevState) => {
            let newList = [];
            let newListError = [];
            let formattedValue = value;
      
            if (inputType === 'number') {
              formattedValue = normalizeAmount(value);
            }
            if (inputArray) {
              if (type === 'checkbox') {
                formattedValue = checked;
              }
              newList = prevState.form.value[fieldName];
              newListError = prevState.form.error[fieldName];
              newList[arrayPosition][name] = formattedValue;
              if (name === 'code_of_account') {
                newList[arrayPosition].isCredit = value.type;
              }
              if (!isEmpty(newListError[arrayPosition])) {
                newListError[arrayPosition][name] = '';
              }
            }
            return {
              form: {
                value: {
                  ...prevState.form.value,
                  ...(inputArray
                    ? { [fieldName]: newList }
                    : { [name]: formattedValue }),
                },
                error: {
                  ...prevState.form.error,
                  ...(inputArray
                    ? { [fieldName]: newListError }
                    : { [name]: '' }),
                },
              },
            };
          },  () => {
           
            if (name === 'levels') {
              this._getClassesOptions({ filters: { levels_id: [value] }} );
            }
            if (name === 'classes') {
              this._getParamOptionsAcademics( 'classrooms', {classes_id: value, organizations_id: 4 } );
            }
          });
        }
      
        _onClickAdd(data) {
          const { form } = this.state;
          const { plafon } = form.value;
          
          plafon.push({
            tipePlafon: '',
            // divisi: '',
            nominal: 0,
            //period:'',
          });
          
          this.setState(prevState => ({
            form: {
              value: {
                ...prevState.form.value,
                plafon,
              },
              error: {
                ...prevState.form.error,
              }
            }
          }));
          console.log(plafon)
        }
      
       
      
        // on formchange untuk tabel
        onFormChange(event) {
          const {
            name,
            value,
            dataset,
          } = event.target;
          console.log(name)
          const { inputArray = false, arrayPosition = 0, inputType = 'text' } = dataset;
          this.setState((prevState) => {
            let formattedValue = value;
            let newPlafon = [];
            if (inputType === 'number') {
              formattedValue = normalizeAmount(value);
            }
      
            if (inputArray) {
              newPlafon = prevState.form.value.plafon;
              newPlafon[arrayPosition][name] = value;
            }
            return {
              form: {
                value: {
                  ...prevState.form.value,
                  ...(inputArray ? 
                    { plafon: newPlafon } : 
                    { [name]: formattedValue }
                  )
                },
                error: {
                  ...prevState.form.error,
                  form:'',
                  [name]: '',
                },
              },
            };
          });
        }
      
        onSubmit(e) {
          e.preventDefault();
          const {history} = this.props;
          history.push('/dashboard/administrasi/data-kelas/kelola-siswa-pindah-kelas');
          // window.open("/dashboard/administrasi/data-kelas/kelola-siswa-pindah-kelas", '_blank');
        }

        onCetak(val) {
          const { history } = this.props;
          const { form } = this.state;
          const { value } = form;
          history.push('/dashboard/kesiswaan/laporan/cetak-buku-induk', {data: value}); 
        }
      
        
        _onClickNext() {
            window.scrollTo(0, 0);
            this.setState(prevState => ({
              page: prevState.page + 1,
            }));
          }
      
        render() {
          const { form, page, param } = this.state;
          const {plafon} = form.value;
          const { coa, classes = {}, levels = {} } = this.props;
          return (
            <div className="manage-registration">
            
  
                    <StudentNumberPage1
                    param={param}
                    classes = {classes}
                    levels={levels}
                    form={form}
                    onFormChange={this._onFormChange}
                    />
                       
                  
                  <Button
                    onClick={this.onCetak}
                    title="Cari"
                  />
              </div>
            
          );
        }
}
SearchSiswa.propTypes = {
    
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
  handleGetClasses: PropTypes.func,
  handleGetLevels: PropTypes.func,
};
SearchSiswa.defaultProps = {
  handleGetClasses: noop,
  handleGetLevels: noop,
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};

import { connect } from 'react-redux';
import Login from '../views/Login/Login.view';
import { handleLogin } from '../states/thunks/auth.thunk';

function mapDispatchToProps(dispatch) {
  return {
    handleLogin: (payload, push) => dispatch(handleLogin(payload, push)),
  };
}

export default connect(null, mapDispatchToProps)(Login);

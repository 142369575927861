import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  isEmpty, noop, map, isEqual
} from 'lodash';
import {
  Pagination,
  Loading,
  Button,
  Select,
  Input
} from '../../../../components/base';
import language from '../../../../languages';
import { formatData } from '../../../../utils/transformer.util';
import { CREATE_INVOICE_TABLE_FIELD_LIST } from '../../../../constants/finance/spp/create_invoice.constant';


const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};


export default class CreateInvoice extends PureComponent {
  constructor(props) {
    super(props);
    this._onClickAdd = this._onClickAdd.bind(this);
    this._onClickEdit = this._onClickEdit.bind(this);
    this._onClickActionButton = this._onClickActionButton.bind(this);
    this.isMount = false;
    this._onSearchContent = this._onSearchContent.bind(this);
    this._onChangeListAmount = this._onChangeListAmount.bind(this);
    this._onChangePage = this._onChangePage.bind(this);
    this._renderButtons = this._renderButtons.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onClickPreview = this._onClickPreview.bind(this);
    this._getNoInvoices = this._getNoInvoices.bind(this);
    this.handleGetData = this.handleGetData.bind(this);
    // this.customAction = [{
    //   Component: Button,
    //   props: {
    //     title: 'Preview',
    //     className: 'cash-journal__action-button',
    //     onClick: this._onClickActionButton,
    //   },
    // }, {
    //   Component: Button,
    //   props: {
    //     title: 'Posting',
    //     className: 'cash-journal__posting-button',
    //     onClick: this._onClickPostingButton,
    //   },
    // }];

    this.state = {
      content: initialContent,
      list: [],
      listAmount: 5,
      filters: {},
      workflow: {},
      param: {},
    };
  }
 _onClickPreview(){
  const { history } = this.props;
  history.push(`/dashboard/keuangan/spp/cetak-slip-inv/kwitansi/detail`);
 }

  componentDidMount() {
    const { content } = this.state;
   
    this.isMount = true;
    // this.handleGetData();
    if (isEmpty(content.list)) {
      this._onSearchContent({ page: 1 });

    }
    this._getNoInvoices();
  }
  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps, this.props)) {
      this._onSearchContent({ page: 1 });
    }
    // const { user } = this.props;
    // const { workingUnit } = user;
    // const { user: prevUser } = prevProps;
    // const { workingUnit: prevWorkingUnit } = prevUser;

    // if (!isEqual(workingUnit, prevWorkingUnit)) {
    //   this.handleGetData();
    // }
  }

  _onClickAdd() {
    const { history, location } = this.props;
    const { state } = location;
    history.push('/dashboard/keuangan/spp/create-invoice/proses-invoice', { isEdit: false, ...state });
  }

  _onClickEdit(val) {
    const { history } = this.props;
    history.push('/dashboard/keuangan/pembukuan/kelola-jurnal-kas', { data: { id: val.id }, isEdit: true });
  }

  _onClickActionButton(val) {
    const { history } = this.props;
    history.push(`/dashboard/keuangan/pembukuan/jurnal-kas/${val.id}`);
  }
 _onClickPreview(){
  const { history } = this.props;
  history.push(`/dashboard/keuangan/spp/cetak-slip-inv/kwitansi/detail`);
 }

 async _getNoInvoices() {
  const { getNoInvoices } = this.props;
  const res = await getNoInvoices();
  // console.log(res)
  this.setState(prevState => ({
    ...prevState,
    param: {
      ...prevState.param,
      no_invoices: res,
    },
  }));
 
}

async handleGetData(payload = {}) {
  const { handleListCreateInvoices, user } = this.props;
  const { units_id, workingUnit } = user;
  let unit_id = units_id;

  if (unit_id === null && workingUnit && workingUnit.id) {
    unit_id = workingUnit.id;
  }

  const res = await handleListCreateInvoices({...payload});

  this.setState({
    list: res,
  });
  // console.log(this.state.list)
}

  

  _onSearchContent(params = {}) {
    const { filters } = this.state;
    this.setState({
      content: initialContent,
    }, async () => {
      try {
        const { handleListCreateInvoices } = this.props;
        const {
          result = [], currentPage = 1, total = 0, totalPage = 1,
        } = await handleListCreateInvoices({ ...params, filters,  organizations_id: 4 });
        if (this.isMount) {
          this.setState({
            content: {
              loading: false,
              list: result,
              currentPage,
              total,
              totalPage,
            },
          });
        }
      } catch (err) {
        if (this.isMount) {
          this.setState({
            content: {
              loading: false,
              list: [],
              currentPage: 1,
              total: 1,
              totalPage: 1,
              error: true,
            },
          });
        }
      }
    });
  }

  _onChangeListAmount(event) {
    const { keywords } = this.state;
    this.setState({
      listAmount: Number(event.target.value),
    });
    this._onSearchContent({ limit: event.target.value, keywords, page: 1 });
  }

  _onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    // this.handleGetData({
    this._onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }
  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    this.setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        [name]: value,
      },
    }), () => this._onSearchContent());
  }

  _renderButtons(data) {
    const { user } = this.props;
    const { user_group } = user;
    const { permissions = [] } = user_group;
  
    return (
      <td>
        <div className="table__actions">
        
          {/* {data.payments_date ?  */}
          <Button
            onClick={() => this._onClickPreview(data)}
            title='Preview'
          /> 
        {/* <div className="table__actions"> */}
             <Button
             onClick={() => this.onClickEdit(data)}
             title='Edit'
           />
             <Button
              onClick={() => this.onClickDelete(data)}
              title='Delete'
          />
           {/* </div> */}
        {/* } */}
           
  
         
        </div>
      </td>
    );
  }

  render() {
    const {
      listAmount,
      content,
      filters,
      param,
      list = {}
    } = this.state;
    const {
      no_invoices = '',
      tgl_invoices = '',
    } = filters;
    console.log(content.list)
    const loadingProps = { show: content.loading };
    return (
      <div className="fund-request">
        {/* { user_group.name === 'teacher'? */}
        <div className="beginning-balance__button-wrapper">
        <Button
          title="Create Invoice"
          onClick={this._onClickAdd}
        />

         </div>
         <br></br>
         <div className="table-filters">
              <Input
                label="Tanggal Invoice"
                type="date"
                name="tgl_invoices"
                value={tgl_invoices}
                onChange={this._onChangeFilter}
              />
             
              <Select
                label="Nomor Invoice"
                name="no_invoices"
                placeholder = "Pilih No invoices"
                value={no_invoices}
                data={param.no_invoices}
                onChange={this._onChangeFilter}
              />
            </div>
        
        
        <div className="fund-request__content">
          <table className="table">
            <thead>
              <tr>
                {
                    map(CREATE_INVOICE_TABLE_FIELD_LIST.label, (field, idx) => (
                      <th key={`table_th_${idx}`}>{field}</th>
                   ))
                  }
              </tr>
            </thead>
            <tbody>
              {
                  map(content.list, (list, idx) => (
                    <tr key={`budget_row_${idx}`}>
                      {
                      map(CREATE_INVOICE_TABLE_FIELD_LIST.value, (field, fieldIdx) => {
                        if (fieldIdx === 0) {
                          return (
                            <td key={`table_index_${fieldIdx}_${idx}`}>{(idx + 1) + ((content.currentPage - 1) * listAmount)}</td>
                          );
                        }
                        if (field.type === 'link') {
                          return (
                            <td key={`table_${fieldIdx}_${idx}`}><a href={"https://"+list.hyperlink} target="_blank" rel="noopener noreferrer">{list.hyperlink}</a></td>
                              // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                          );
                        }
                        return (
                          <td className={(field.type === 'number') ? 'nominal' : ''} key={`table_${fieldIdx}_${idx}`}>
                            {formatData(list, field)}
                          </td>
                        );
                      })
                      }
                      {this._renderButtons(list)}
                    </tr>
                  ))
                }
            </tbody>
          </table>
        </div>
        <div className="user-management__table-footer">
          <p className="user-management__page-info">
            {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${content.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${content.totalPage}`}
          </p>
          <Pagination
            totalPage={content.totalPage}
            currentPage={content.currentPage}
            onClick={this._onChangePage}
          />
        </div>
      </div>
    );
  }
}

CreateInvoice.propTypes = {
  handleGetCashJournal: PropTypes.func,
  handleListCreateInvoices: PropTypes.func,
  handlePostJournalUnposting: PropTypes.func,
  getNoInvoices: PropTypes.func,
  history: PropTypes.object.isRequired,
};
CreateInvoice.defaultProps = {
  handleListCreateInvoices: noop,
  handleGetCashJournal: noop,
  handlePostJournalUnposting: noop,
  getNoInvoices: noop,
};

import React, { Component } from "react";
import PropTypes from "prop-types";
import { noop, map, isEmpty } from "lodash";
import {
  Input,
  Select,
  Textarea,
  Button,
} from "../../../../components/base/index";
import { GENDER } from "../../../../variables/common.variable";

export default class PengisianDaftarNilaiTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      edit: props.status === !isEmpty(props.data),
    };
    this._onAddList1C = this._onAddList1C.bind(this);
    this._onDeleteList1C = this._onDeleteList1C.bind(this);
    this._onAddList2C = this._onAddList2C.bind(this);
    this._onDeleteList2C = this._onDeleteList2C.bind(this);
    this._onAddList3C = this._onAddList3C.bind(this);
    this._onDeleteList3C = this._onDeleteList3C.bind(this);
    this._onAddList4C = this._onAddList4C.bind(this);
    this._onDeleteList4C = this._onDeleteList4C.bind(this);
    this._onAddList5C = this._onAddList5C.bind(this);
    this._onDeleteList5C = this._onDeleteList5C.bind(this);
    this._onAddList6C = this._onAddList6C.bind(this);
    this._onDeleteList6C = this._onDeleteList6C.bind(this);
    this._onAddList7C = this._onAddList7C.bind(this);
    this._onDeleteList7C = this._onDeleteList7C.bind(this);
    this._onAddList8C = this._onAddList8C.bind(this);
    this._onDeleteList8C = this._onDeleteList8C.bind(this);
    this._onAddList9C = this._onAddList9C.bind(this);
    this._onDeleteList9C = this._onDeleteList9C.bind(this);
    this._onAddList10C = this._onAddList10C.bind(this);
    this._onDeleteList10C = this._onDeleteList10C.bind(this);
    this._onAddList11C = this._onAddList11C.bind(this);
    this._onDeleteList11C = this._onDeleteList11C.bind(this);
    this._onAddList12C = this._onAddList12C.bind(this);
    this._onDeleteList12C = this._onDeleteList12C.bind(this);
  }

  _onAddList1C() {
    const { onAddList1C, fieldName } = this.props;
    onAddList1C(fieldName);
  }
  _onDeleteList1C(idx) {
    const { onDeleteList1C, fieldName } = this.props;
    onDeleteList1C(idx, fieldName);
  }

  _onAddList2C() {
    const { onAddList2C, fieldName } = this.props;
    onAddList2C(fieldName);
  }
  _onDeleteList2C(idx) {
    const { onDeleteList2C, fieldName } = this.props;
    onDeleteList2C(idx, fieldName);
  }

  _onAddList3C() {
    const { onAddList3C, fieldName } = this.props;
    onAddList3C(fieldName);
  }
  _onDeleteList3C(idx) {
    const { onDeleteList3C, fieldName } = this.props;
    onDeleteList3C(idx, fieldName);
  }

  _onAddList4C() {
    const { onAddList4C, fieldName } = this.props;
    onAddList4C(fieldName);
  }
  _onDeleteList4C(idx) {
    const { onDeleteList4C, fieldName } = this.props;
    onDeleteList4C(idx, fieldName);
  }

  _onAddList5C() {
    const { onAddList5C, fieldName } = this.props;
    onAddList5C(fieldName);
  }
  _onDeleteList5C(idx) {
    const { onDeleteList5C, fieldName } = this.props;
    onDeleteList5C(idx, fieldName);
  }

  _onAddList6C() {
    const { onAddList6C, fieldName } = this.props;
    onAddList6C(fieldName);
  }
  _onDeleteList6C(idx) {
    const { onDeleteList6C, fieldName } = this.props;
    onDeleteList6C(idx, fieldName);
  }

  _onAddList7C() {
    const { onAddList7C, fieldName } = this.props;
    onAddList7C(fieldName);
  }
  _onDeleteList7C(idx) {
    const { onDeleteList7C, fieldName } = this.props;
    onDeleteList7C(idx, fieldName);
  }

  _onAddList8C() {
    const { onAddList8C, fieldName } = this.props;
    onAddList8C(fieldName);
  }
  _onDeleteList8C(idx) {
    const { onDeleteList8C, fieldName } = this.props;
    onDeleteList8C(idx, fieldName);
  }

  _onAddList9C() {
    const { onAddList9C, fieldName } = this.props;
    onAddList9C(fieldName);
  }
  _onDeleteList9C(idx) {
    const { onDeleteList9C, fieldName } = this.props;
    onDeleteList9C(idx, fieldName);
  }

  _onAddList10C() {
    const { onAddList10C, fieldName } = this.props;
    onAddList10C(fieldName);
  }
  _onDeleteList10C(idx) {
    const { onDeleteList10C, fieldName } = this.props;
    onDeleteList10C(idx, fieldName);
  }

  _onAddList11C() {
    const { onAddList11C, fieldName } = this.props;
    onAddList11C(fieldName);
  }
  _onDeleteList11C(idx) {
    const { onDeleteList11C, fieldName } = this.props;
    onDeleteList11C(idx, fieldName);
  }

  _onAddList12C() {
    const { onAddList12C, fieldName } = this.props;
    onAddList12C(fieldName);
  }
  _onDeleteList12C(idx) {
    const { onDeleteList12C, fieldName } = this.props;
    onDeleteList12C(idx, fieldName);
  }

  render() {
    const { onFormChange, form, param, list1c, list2c, list3c, list4c, list5c } =
      this.props;
    const {development_aspect_id_c} = form.value;
    const rating = [
      { label: "1", value: "1" },
      { label: "2", value: "2" },
      { label: "3", value: "3" },
      { label: "4", value: "4" },
      { label: "5", value: "5" },
    ];
    // const pengisian = [
    //   { label: 'Sikap', value: 'sikap'},
    //   { label: 'Kognitif', value: 'kognitif'},
    // ]
    return (
      <div className="lembar-harian2__custom-form">
        <div className="lembar-harian2__custom-form-row">
          <div className="lembar-harian2__custom-form-width__field">
            
            <Select
              name="development_aspect_id_c"
              onChange={onFormChange}
              placeholder={"Pilihan"}
              data={param.prm_aspect}
              value={development_aspect_id_c}
              disabled
            />
          </div>
          <div className="lembar-harian2__custom-form-width__field"></div>
        </div>

        <br />
        <div className="lembar-harian2__custom-form-row">
          <div className="lembar-harian2__custom-form-width__field">
            <Select
              name="kd_1c"
              value={form.value.kd_1c}
              label="1. Kompetensi Dasar"
              onChange={onFormChange}
              data={param.kd_prm_c}
              placeholder={"Pilihan"}
            />
            <p>Muatan Materi </p>
            <table className="manage-rapbs-plafon__form-table table">
              <tbody>
                {map(list1c, (data, idx) => (
                  <tr key={`plafon_journal_data__${idx}`}>
                    <td>{idx + 1}</td>
                    <td>
                      <Textarea
                        type="text"
                        value={data.muatan_1c}
                        data-input-array
                        arrayPosition={idx}
                        data-array-position={idx}
                        data-field-name="muatan_materi_1c"
                        name="muatan_1c"
                        onChange={onFormChange}
                      />
                    </td>
                    <td>
                      <Button
                        onClick={() => this._onDeleteList1C(idx)}
                        icon="icon-trash-o"
                      />
                    </td>
                  </tr>
                ))}
                <tr className="manage-rapbs-plafon__add-more">
                  <td colSpan={10}>
                    <div>
                      <Button icon="icon-plus" onClick={this._onAddList1C} />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="lembar-harian2__custom-form-width__field">
            <Select
              name="kd_2c"
              value={form.value.kd_2c}
              label="2. Kompetensi Dasar"
              onChange={onFormChange}
              data={param.kd_prm_c}
              placeholder={"Pilihan"}
            />
            <p>Muatan Materi </p>
            <table className="manage-rapbs-plafon__form-table table">
              <tbody>
                {map(list2c, (data, idx) => (
                  <tr key={`plafon_journal_data__${idx}`}>
                    <td>{idx + 1}</td>
                    <td>
                      <Textarea
                        type="text"
                        value={data.muatan_2c}
                        data-input-array
                        arrayPosition={idx}
                        data-array-position={idx}
                        data-field-name="muatan_materi_2c"
                        name="muatan_2c"
                        onChange={onFormChange}
                      />
                    </td>
                    <td>
                      <Button
                        onClick={() => this._onDeleteList2C(idx)}
                        icon="icon-trash-o"
                      />
                    </td>
                  </tr>
                ))}
                <tr className="manage-rapbs-plafon__add-more">
                  <td colSpan={10}>
                    <div>
                      <Button icon="icon-plus" onClick={this._onAddList2C} />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <br />
        <div className="lembar-harian2__custom-form-row">
          <div className="lembar-harian2__custom-form-width__field">
            <Select
              name="kd_3c"
              value={form.value.kd_3c}
              label="3. Kompetensi Dasar"
              onChange={onFormChange}
              data={param.kd_prm_c}
              placeholder={"Pilihan"}
            />
            <p>Muatan Materi </p>
            <table className="manage-rapbs-plafon__form-table table">
              <tbody>
                {map(list3c, (data, idx) => (
                  <tr key={`plafon_journal_data__${idx}`}>
                    <td>{idx + 1}</td>
                    <td>
                      <Textarea
                        type="text"
                        value={data.muatan_3c}
                        data-input-array
                        arrayPosition={idx}
                        data-array-position={idx}
                        data-field-name="muatan_materi_3c"
                        name="muatan_3c"
                        onChange={onFormChange}
                      />
                    </td>
                    <td>
                      <Button
                        onClick={() => this._onDeleteList3C(idx)}
                        icon="icon-trash-o"
                      />
                    </td>
                  </tr>
                ))}
                <tr className="manage-rapbs-plafon__add-more">
                  <td colSpan={10}>
                    <div>
                      <Button icon="icon-plus" onClick={this._onAddList3C} />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="lembar-harian2__custom-form-width__field">
            <Select
              name="kd_4c"
              value={form.value.kd_4c}
              label="4. Kompetensi Dasar"
              onChange={onFormChange}
              data={param.kd_prm_c}
              placeholder={"Pilihan"}
            />
            <p>Muatan Materi </p>
            <table className="manage-rapbs-plafon__form-table table">
              <tbody>
                {map(list4c, (data, idx) => (
                  <tr key={`plafon_journal_data__${idx}`}>
                    <td>{idx + 1}</td>
                    <td>
                      <Textarea
                        type="text"
                        value={data.muatan_4c}
                        data-input-array
                        arrayPosition={idx}
                        data-array-position={idx}
                        data-field-name="muatan_materi_4c"
                        name="muatan_4c"
                        onChange={onFormChange}
                      />
                    </td>
                    <td>
                      <Button
                        onClick={() => this._onDeleteList4C(idx)}
                        icon="icon-trash-o"
                      />
                    </td>
                  </tr>
                ))}
                <tr className="manage-rapbs-plafon__add-more">
                  <td colSpan={10}>
                    <div>
                      <Button icon="icon-plus" onClick={this._onAddList4C} />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <br />
        <div className="lembar-harian2__custom-form-row">
          <div className="lembar-harian2__custom-form-width__field">
            <Select
              name="kd_5c"
              value={form.value.kd_5c}
              label="5. Kompetensi Dasar"
              onChange={onFormChange}
              data={param.kd_prm_c}
              placeholder={"Pilihan"}
            />
            <p>Muatan Materi </p>
            <table className="manage-rapbs-plafon__form-table table">
              <tbody>
                {map(list5c, (data, idx) => (
                  <tr key={`plafon_journal_data__${idx}`}>
                    <td>{idx + 1}</td>
                    <td>
                      <Textarea
                        type="text"
                        value={data.muatan_5c}
                        data-input-array
                        arrayPosition={idx}
                        data-array-position={idx}
                        data-field-name="muatan_materi_5c"
                        name="muatan_5c"
                        onChange={onFormChange}
                      />
                    </td>
                    <td>
                      <Button
                        onClick={() => this._onDeleteList5C(idx)}
                        icon="icon-trash-o"
                      />
                    </td>
                  </tr>
                ))}
                <tr className="manage-rapbs-plafon__add-more">
                  <td colSpan={10}>
                    <div>
                      <Button icon="icon-plus" onClick={this._onAddList5C} />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="lembar-harian2__custom-form-width__field"></div>
        </div>

        {/* <br />
        <div className="lembar-harian2__custom-form-row">
          <div className="lembar-harian2__custom-form-width__field">
            <Select
              name="kd_6c"
              value={form.value.kd_6c}
              label="6. Kompetensi Dasar"
              onChange={onFormChange}
              data={param.kd_prm_c}
              placeholder={"Pilihan"}
            />
            <p>Muatan Materi </p>
            <table className="manage-rapbs-plafon__form-table table">
              <tbody>
                {map(list6c, (data, idx) => (
                  <tr key={`plafon_journal_data__${idx}`}>
                    <td>{idx + 1}</td>
                    <td>
                      <Textarea
                        type="text"
                        value={data.muatan_6c}
                        data-input-array
                        arrayPosition={idx}
                        data-array-position={idx}
                        data-field-name="muatan_materi_6c"
                        name="muatan_6c"
                        onChange={onFormChange}
                      />
                    </td>
                    <td>
                      <Button
                        onClick={() => this._onDeleteList6C(idx)}
                        icon="icon-trash-o"
                      />
                    </td>
                  </tr>
                ))}
                <tr className="manage-rapbs-plafon__add-more">
                  <td colSpan={10}>
                    <div>
                      <Button icon="icon-plus" onClick={this._onAddList6C} />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="lembar-harian2__custom-form-width__field"></div>
        </div>

        <br />
        <div className="lembar-harian2__custom-form-row">
          <div className="lembar-harian2__custom-form-width__field">
            <Select
              name="kd_7c"
              value={form.value.kd_7c}
              label="7. Kompetensi Dasar"
              onChange={onFormChange}
              data={param.kd_prm_c}
              placeholder={"Pilihan"}
            />
            <p>Muatan Materi </p>
            <table className="manage-rapbs-plafon__form-table table">
              <tbody>
                {map(list7c, (data, idx) => (
                  <tr key={`plafon_journal_data__${idx}`}>
                    <td>{idx + 1}</td>
                    <td>
                      <Textarea
                        type="text"
                        value={data.muatan_7c}
                        data-input-array
                        arrayPosition={idx}
                        data-array-position={idx}
                        data-field-name="muatan_materi_7c"
                        name="muatan_7c"
                        onChange={onFormChange}
                      />
                    </td>
                    <td>
                      <Button
                        onClick={() => this._onDeleteList7C(idx)}
                        icon="icon-trash-o"
                      />
                    </td>
                  </tr>
                ))}
                <tr className="manage-rapbs-plafon__add-more">
                  <td colSpan={10}>
                    <div>
                      <Button icon="icon-plus" onClick={this._onAddList7C} />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="lembar-harian2__custom-form-width__field"></div>
        </div>

        <br />
        <div className="lembar-harian2__custom-form-row">
          <div className="lembar-harian2__custom-form-width__field">
            <Select
              name="kd_8c"
              value={form.value.kd_8c}
              label="8. Kompetensi Dasar"
              onChange={onFormChange}
              data={param.kd_prm_c}
              placeholder={"Pilihan"}
            />
            <p>Muatan Materi </p>
            <table className="manage-rapbs-plafon__form-table table">
              <tbody>
                {map(list8c, (data, idx) => (
                  <tr key={`plafon_journal_data__${idx}`}>
                    <td>{idx + 1}</td>
                    <td>
                      <Textarea
                        type="text"
                        value={data.muatan_8c}
                        data-input-array
                        arrayPosition={idx}
                        data-array-position={idx}
                        data-field-name="muatan_materi_8c"
                        name="muatan_8c"
                        onChange={onFormChange}
                      />
                    </td>
                    <td>
                      <Button
                        onClick={() => this._onDeleteList8C(idx)}
                        icon="icon-trash-o"
                      />
                    </td>
                  </tr>
                ))}
                <tr className="manage-rapbs-plafon__add-more">
                  <td colSpan={10}>
                    <div>
                      <Button icon="icon-plus" onClick={this._onAddList8C} />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="lembar-harian2__custom-form-width__field"></div>
        </div> */}

        <br />
        <Textarea
          type="text"
          label="Hasil Observasi"
          name="obs_result_c"
          value={form.value.obs_result_c}
          onChange={onFormChange}
          rows={5}
          style={{ backgroundColor: "yellow" }}
        />
      </div>
    );
  }
}
PengisianDaftarNilaiTable.propTypes = {
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
};
PengisianDaftarNilaiTable.defaultProps = {
  onFormChange: noop,
};

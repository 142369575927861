import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted, normalizeAmount } from '../../../utils/transformer.util';
import { Input, Select, Button } from '../../../components/base/index';
import TableDataPegawai from './components/TableDataPegawai.component';

export default class LaporanAbsensiPegawaiDetail extends Component {
    constructor(props) {
        super(props);
        this._onClickSubmit = this._onClickSubmit.bind(this);
        this._onSubmit = this._onSubmit.bind(this);
        this._onSubmit2 = this._onSubmit2.bind(this);
        this._onSubmit3 = this._onSubmit3.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
        this._getPeriodsOptions= this._getPeriodsOptions.bind(this);
          
        this.state= {
          coa: {
            loading: true,
            list: [],
    
          },
          form: {
            value: {},
            error: {},
          },
          param:{},
        };
      
      }

      componentDidMount() {
        const { location, getLevels, getClasses, user } = this.props;
        const { state = {} } = location;
        const {isEdit =  false, data} = state;
        const { id } = state.data || '';
          const { content } = this.state;
          const paramTypes = ['classes','levels', 'classrooms'];
      
          // paramTypes.forEach((type) => {
          //   this._getParamOptionsAcademics(type);
          // });
          // if (isEdit && id) {
          //   this.setState({id:id})
          //   this._setForm(data.id);
          // } else
          // if (isEmpty(content.list)) {
          //   this._onSearchContent({ page: 1 });
          // }
          // this._getClassesOptions();
          // this._getSubjectOptions();       
          // this._getRppOptions();       
          this._getPeriodsOptions();       
        
        }
    
      _onClickSubmit() {
        const { history } = this.props;
        history.push('/dashboard/kurikulum/laporan/daftar-nilai-siswa-ktsp');
      }

      _onSubmit() {
        const { history } = this.props;
        history.push('/dashboard/kurikulum/rencana-penilaian/upload');
      }

      _onSubmit2() {
        const { history } = this.props;
        history.push('/dashboard/kurikulum/ulangan/pengisian-indikator-sikap');
      }
      _onSubmit3() {
        const { history } = this.props;
        history.push('/dashboard/kurikulum/laporan/psikomotorik-siswa');
      }

      async _getPeriodsOptions() {
        const { getPeriodsOptions } = this.props;
        const res = await getPeriodsOptions();
        this.setState(prevState => ({
          ...prevState,
          param: {
            ...prevState.param,
            periods: res,
          },
        }));
        
      }

      _onChange(e) {
        const { onChange } = this.props;
        const { target } = e;
        const { name, value, dataset } = target;
        let formattedValue = value;
    
        if (dataset.inputType === 'number') {
          formattedValue = normalizeAmount(value);
        }
    
        onChange(name, formattedValue);
      }
    
    
      _onFormChange(event) {
        const {
          name,
          value,
          dataset,
          checked,
          type,
        } = event.target;
        const {
          inputType = 'text', inputArray = false, arrayPosition = 0,
          fieldName,
        } = dataset;
        this.setState((prevState) => {
          let newList = [];
          let newListError = [];
          let formattedValue = value;
    
          if (inputType === 'number') {
            formattedValue = normalizeAmount(value);
          }
          if (inputArray) {
            if (type === 'checkbox') {
              formattedValue = checked;
            }
            newList = prevState.form.value[fieldName];
            newListError = prevState.form.error[fieldName];
            newList[arrayPosition][name] = formattedValue;
            if (name === 'code_of_account') {
              newList[arrayPosition].isCredit = value.type;
            }
            if (!isEmpty(newListError[arrayPosition])) {
              newListError[arrayPosition][name] = '';
            }
          }
          return {
            form: {
              value: {
                ...prevState.form.value,
                ...(inputArray
                  ? { [fieldName]: newList }
                  : { [name]: formattedValue }),
              },
              error: {
                ...prevState.form.error,
                ...(inputArray
                  ? { [fieldName]: newListError }
                  : { [name]: '' }),
              },
            },
          };
        });
      }
      
    
      render() {
        const {form, param} = this.state;
        const { handleGetKelas} = this.props;
        return (
        <div className="student-list">
            <div className="budget__title">
                <h1>Laporan Absensi Detail</h1>
                <hr></hr>
            </div>
       
            <div className="komposisi-siswa__custom-form-row">
                <div className="komposisi-siswa__custom-form-row__field">
                    <Select
                        label="Tahun Ajaran"
                        // value={from}
                        name="tahunajaran"
                        onChange={this._onFormChange}
                        data={param.periods}
                        value={form.value.tahunajaran}
                        error={form.error.tahunajaran || ''}

                    />
                </div>
                <div className="komposisi-siswa__custom-form-column__field">
                    <Select
                        label="Bulan"
                        // value={to}
                        name="kelas"
                        onChange={this._onChange}
                    />
                </div>
            </div>
            <div className="manage-registration__footer">
                <Button
                onClick={this._onSubmit}
                title="Cari"
                />
            </div>
            
            <div className="laporan-pegawai__body">
                <table className="laporan-pegawai__table">
                    <thead>
                        <tr>
                            <th>No</th>
                            <th>Unit</th>
                            <th>Nama dan NIK</th>
                            <th>Hadir</th>
                            <th>Ijin</th>
                            <th>Cuti</th>
                            <th>Tidak Hadir</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>Yayasan</td>
                            <td>Nama Lengkap Pegawai/123123123</td>
                            <td>25</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Yayasan</td>
                            <td>Nama Lengkap Pegawai/12412412</td>
                            <td>23</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>


        </div>
        );
      }
}
LaporanAbsensiPegawaiDetail.propTypes = {
    
  handleGetStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
LaporanAbsensiPegawaiDetail.defaultProps = {
    
  handleGetStudent: noop,
};

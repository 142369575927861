import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, map, noop, isEqual } from 'lodash';
import { Button, Pagination, Input, Select, } from '../../../components/base';
import { formatData,  normalizeAmount } from '../../../utils/transformer.util';
import language from '../../../languages';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { JURUSANKELAS_DATAJURUSAN_TABLE_FIELDS } from '../../../constants/Akademi/DataKelas/jurusankelas.constant';
import { SEARCH_FIELDS} from '../../../constants/Akademi/DataKelas/kelas.constant';
import { LIST_TAMBAH_JURUSAN_LIST_TABLE_FIELDS } from '../../../constants/Akademi/Akademi.constant';
import { param } from 'jquery';

export default class JurusanKelas extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickAdd = this.onClickAdd.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this._renderButtons = this._renderButtons.bind(this);
    this._setForm = this._setForm.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this._getMajorGroupOptions= this._getMajorGroupOptions.bind(this);
    const {user} = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit
    this.state ={
      loading: true,
      list: [],
      listAmount: 5,
      form:{
      value: {
        units_id:units_id,
        major:'',
        group:'',
      },
      error: {
        major:'',
        group:'',
      },
    },
    param:{},
  };
  }

  componentDidMount() {
    const { location, user } = this.props;
    const { school_unit } = user;
    const { units_id, levels_id } = school_unit;
    const { state = {} } = location;
    const {isEdit =  false, data} = state;
    const { id } = state.data || '';
   
    const paramTypes = ['classrooms', 'levels', 'classes'];
    if (isEdit && id) {
      this.setState({id:id})
      this._setForm(data.id);
    }

    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });

    
    this.handleGetData();
    this._getMajorGroupOptions({filters: {units_id: units_id}});
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { state = {} } = location;
    const {isEdit =  false, data} = state;
    const { id } = state.data || '';
    // const { user } = this.props;
    // const { workingUnit } = user;
    // const { user: prevUser } = prevProps;
    // const { workingUnit: prevWorkingUnit } = prevUser;

    // if (!isEqual(workingUnit, prevWorkingUnit)) {
    //   this.handleGetData();
    // }
    if ((!isEdit || !id) && !isEqual(prevProps, this.props)) {
      this.handleGetData();
    }
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics } = this.props;
    const res = await getParamOptionsAcademics(filters, type);
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getMajorGroupOptions(filters={}) {
    const { getMajorGroupOptions, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id, units_id } = school_unit;
    const res = await getMajorGroupOptions(filters);
    filters.units_id = units_id;
    // console.log(res)
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        major_group: res,
      },
    }));
   
  }


  async _setForm(id) {
    const { handleGetMajor,user} = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit
    try {
      const payload = await handleGetMajor({ id });
      this.setState({
        form: {
          value: {
            id: payload.id,
            major_groups_id: payload.major_groups_id,
            major:payload.major,
           units_id:units_id,
            
          },
          error: {
            // details: [],
          },
        },
      });
    } catch (err) {
      // err action
    }
  }

  async handleGetData(payload = {}) {
    const { handleListMajor, user } = this.props;
    const { school_unit, workingUnit } = user;
    const { levels_id, units_id } = school_unit;

    const res = await handleListMajor({...payload, units_id});

    this.setState({
      list: res,
    });
  }
  onClickAdd() {
    const { history } = this.props;
    history.push('/dashboard/administrasi/data-kelas/buat-data-jurusan-kelas');
  }

  _renderButtons(data) {
    const { user } = this.props;
    const button = {};
    
    return (
      <td>
        <div className="table__actions">
         
          <Button
            onClick={() => this.onClickEdit(data)}
            title='Edit'
          />
          <Button
            onClick={() => this.onClickDelete(data)}
            title='Delete'
          />
        </div>
      </td>
    );
  }

  async onClickDelete(id) {
    const { handleDeleteMajor } = this.props;
    const res = await handleDeleteMajor(id);
    if(res){
      this.handleGetData();
    }
  }
  onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this.handleGetData({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  async onSubmit() {
    const { history, handleSaveMajor } = this.props;
    const { form } = this.state;
    const { value } = form;        
    let canSaveA = false;
    const dataForm = form.value;
    // if(dataForm.folder_code && dataForm.folder_name && dataForm.folder_description){
    //   canSaveA = true;
    // } else {
    //   canSaveA = false;
    // }
    // if (canSaveA == false){
    //   alert("Harap lengkapi data form. Bagian yang harus diisi ditandai dengan tanda bintang (*).");
    // } else {
      await handleSaveMajor(value);
      history.push('/dashboard/administrasi/data-kelas/jurusan-kelas');
      history.go(0)
    // }
  }
  async onEdit() {
    const { history, handleEditMajor } = this.props;
    const { form } = this.state;
    const { value } = form;
    const res = await handleEditMajor(value);        
    history.push('/dashboard/administrasi/data-kelas/jurusan-kelas');
    history.go(0)
  }

  onClickEdit(val) {
    const { history } = this.props;
    history.push('/dashboard/administrasi/data-kelas/jurusan-kelas', { data: val, isEdit: true });
    history.go(0)
  }
  _onFormChange(event) {
    const {
      name,
      value,
      dataset,
      checked,
      type,
    } = event.target;
    const {
      inputType = 'text', inputArray = false, arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        if (type === 'checkbox') {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
        if (name === 'code_of_account') {
          newList[arrayPosition].isCredit = value.type;
        }
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = '';
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray
              ? { [fieldName]: newListError }
              : { [name]: '' }),
          },
        },
      };
    });
  }
  onFormChange(event) {
    const {
      name,
      value,
      dataset,
    } = event.target;
    // console.log(name)
    const { inputArray = false, arrayPosition = 0, inputType = 'text' } = dataset;
    this.setState((prevState) => {
      let formattedValue = value;
      let newPlafon = [];
      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }

      if (inputArray) {
        newPlafon = prevState.form.value.plafon;
        newPlafon[arrayPosition][name] = value;
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray ? 
              { plafon: newPlafon } : 
              { [name]: formattedValue }
            )
          },
          error: {
            ...prevState.form.error,
            form:'',
            [name]: '',
          },
        },
      };
    });
  }

  render() {
    //console.log(this.props.handleDeleteBudget);
    const { form, list, param } = this.state;
    const { handleGetBudget, handleDeleteBudget,coa } = this.props;
    const group = [
      { label: 'IPA1', value: 'IPA1'},
      { label: 'IPA2', value: 'IPA2'},
      { label: 'IPA3', value: 'IPA3'},
      { label: 'IPS1', value: 'IPS1'},
      { label: 'IPS2', value: 'IPS2'},
      { label: 'IPS3', value: 'IPS3'},
      { label: 'UMUM', value: 'UMUM'},
      { label: 'BAHASA', value: 'BAHASA'},
    ]
    const major = [
      { label: 'IPA', value: '2'},
      { label: 'IPS', value: '3'},
      { label: 'Umum', value: '5'},
    ]
    return (
      <div className="add-budget">
        
      <Input
        name="major"
        label="Nama Jurusan"
        onChange={this._onFormChange}
        value={form.value.major}
        error={form.error.major || ''}
      />
      <Select
        name="major_groups_id"
        label="Kelompok"
        onChange={this._onFormChange}
        value={form.value.major_groups_id}
        error={form.error.major_groups_id || ''}
        placeholder={'Pilihan'}
        rightIcon="icon-search"
        data={major}
      />
      <div className="add-budget">
      {this.state.id?
              <Button
                type="button"
                title="Update"
                onClick={this.onEdit}
              />:
              <Button
                type="button"
                title="Simpan"
                onClick={this.onSubmit}
              />}
      </div>
      <div className="fund-request__content">
      <table className="table">
        <thead>
          <tr>
            {
                map(LIST_TAMBAH_JURUSAN_LIST_TABLE_FIELDS.label, (field, idx) => (
                  <th key={`table_th_${idx}`}>{field}</th>
               ))
              }
          </tr>
        </thead>
        <tbody>
          {
              map(list.result, (data, idx) => (
                <tr key={`budget_row_${idx}`}>
                  {
                  map(LIST_TAMBAH_JURUSAN_LIST_TABLE_FIELDS.value, (field, fieldIdx) => {
                    if (fieldIdx === 0) {
                      return (
                        <td key={`table_index_${fieldIdx}_${idx}`}>{idx + 1}</td>
                      );
                    }
                    if (field.type === 'link') {
                      return (
                        <td key={`table_${fieldIdx}_${idx}`}><a href={"https://"+data.hyperlink} target="_blank" rel="noopener noreferrer">{data.hyperlink}</a></td>
                          // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                      );
                    }
                    return (
                      <td className={(field.type === 'number') ? 'nominal' : ''} key={`table_${fieldIdx}_${idx}`}>
                        {formatData(data, field)}
                      </td>
                    );
                  })
                  }
                  {this._renderButtons(data)}
                </tr>
              ))
            }
        </tbody>
      </table>
    </div>
    <div className="user-management__table-footer">
          <p className="user-management__page-info">
            {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${list.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${list.totalPage}`}
          </p>
          <Pagination
            totalPage={list.totalPage}
            currentPage={list.currentPage}
            onClick={this.onChangePage}
          />
        </div>
  </div>
    );
  }
}


// Budget.propTypes = {
//   handleGetBudget: PropTypes.func,
//   handleDeleteBudget: PropTypes.func,
//   user: PropTypes.object,
//   history: PropTypes.object.isRequired,
// };
// Budget.defaultProps = {
//   handleGetBudget: noop,
//   handleDeleteBudget: noop,
//   user: null,
// };

JurusanKelas.propTypes = {
  handleGetBudget: PropTypes.func,
  handleDeleteBudget: PropTypes.func,
  user: PropTypes.object,
  history: PropTypes.object.isRequired,
};
JurusanKelas.defaultProps = {
  handleGetBudget: noop,
  handleDeleteBudget: noop,
  user: null,
};

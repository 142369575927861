import { connect } from 'react-redux';
import AddBudget from '../../../../views/Finance/Budgeting/Budget/EditBudget.finance.view';
import { editBudget } from '../../../../states/thunks/finance.thunk';




// import { getCodeClassOptions,getSchoolUnitOptions , getPlafondOptions } from '../../../../states/thunks/options.thunk';
import {
  getUnitOptions, getPlafondOptions, getCodeClassOptions, getPeriodeOptions,
} from '../../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    user: state.user,
    code_class: state.options.code_class,
    unit: state.options.unit,
    plafond: state.options.plafond,
    periode: state.options.periode,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetCodeClassOptions: payload => dispatch(getCodeClassOptions(payload)),
    handleGetPlafondOptions: payload => dispatch(getPlafondOptions(payload)),
    handleGetPeriodeOptions: payload => dispatch(getPeriodeOptions(payload)),
    handleGetSchoolUnitOptions: payload => dispatch(getUnitOptions(payload)),
    handleEditBudget: (payload, goBack, unit_id) => dispatch(editBudget(payload, goBack, unit_id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddBudget);

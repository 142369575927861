import download from 'downloadjs';
import * as api from '../../utils/api.util';
import * as commonActions from '../actions/common.action';
import { get, set, storageKeys } from '../../utils/storage.util';
import { setCurrentLang, DEFAULT_LANGUAGE } from '../../languages';
// import { setCurrentLang, DEFAULT_LANGUAGE } from '../../languages';

export function initialSetup() {
  return async (dispatch) => {
    try {
      const setup = get(storageKeys.INITIAL_SETUP);
      if (!setup) {
        set(storageKeys.INITIAL_SETUP, true);
        set(storageKeys.LANGUAGE, DEFAULT_LANGUAGE);
      }
      const currentLanguage = get(storageKeys.LANGUAGE);
      dispatch(commonActions.setLanguage(currentLanguage));
    } catch (err) {
      // err action
    }
  };
}

export function setCurrentLanguage(languageId) {
  return (dispatch) => {
    let lang = languageId;
    if (!['id', 'en'].includes(languageId)) {
      lang = 'id';
    }
    setCurrentLang(lang);
    return dispatch(commonActions.setLanguage(lang));
  };
}

export function getCoa(code = '') {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getCoaRequested());
      const requestBody = new FormData();
      requestBody.append('code', code);
      const res = await api.PARAM(requestBody, '/code/list');
      dispatch(commonActions.getCoaSucceeded(res.result.data || []));
    } catch (err) {
      dispatch(commonActions.getCoaFailed());
    }
  };
}

export function uploadFile(file) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const requestBody = new FormData();
      requestBody.append('file', file);
      const res = await api.FILE(requestBody, '/upload');
      return res.data;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function downloadFile(filename) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.DOWNLOAD(`/download/${filename}`, {
        method: 'GET',
        responseType: 'blob',
      });
      // console.log(res);
      download(res, filename);
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getCoaOptions(payload) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getCoaRequested());
      const res = await api.OPTIONS(payload, '/code-of-account');
      dispatch(commonActions.getCoaSucceeded(res.data || []));
      return res.data || [];

      // console.log(res)
    } catch (err) {
      dispatch(commonActions.getCoaFailed());
    }
    return [];
  };
}

export function getCodeOfAccount(code) {
  return async () => {
    try {
      const requestBody = new FormData();
      requestBody.append('code', code);
      const res = await api.PARAM(requestBody, '/code/list');
      return res.result.data;
    } catch (err) {
      throw err;
    }
  };
}

export function getDetailHead(payload = {}) {
  return async (dispatch, getState) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id } = user;
      let unit_id = prm_school_units_id;

      if (unit_id === null && workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      const res = await api.OPTIONS({ ...payload, unit_id }, '/budget');
      return res.data || [];
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

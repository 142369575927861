import React, { PureComponent, Component } from 'react';
import PropTypes from 'prop-types';
import { map, noop, isEmpty } from 'lodash';
import { commaFormatted, dateFormat } from '../../../../utils/transformer.util';
import Button from '../../../../components/base/Button/Button.component';
import { printBukuInduk } from '../../../../utils/print.util';
import ListForm from '../../../../components/ListForm/ListForm.component';
import { LAPORAN_8355_FORM } from '../../../../constants/student.constant';
import { Input, Select } from '../../../../components/base/index';
import { GENDER } from '../../../../variables/common.variable';
import ReactToPrint from "react-to-print";

function onClickPrint(header, content, footer) {
  printBukuInduk(header, content, footer);
}

class ComponentToPrint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      journal: {},
    };
    // this.onGetDetails = this.onGetDetails.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
  }

  // componentDidMount() {
  //   this.onGetDetails();
  // }

  // async onGetDetails() {
  //   const { handleGetCashJournal, match } = this.props;
  //   const { id } = match.params;
  //   try {
  //     const payload = await handleGetCashJournal({ id });
  //     this.setState({
  //       journal: payload,
  //     });
  //   } catch (err) {
  //     // err action
  //   }
  // }

  _onFormChange(event) {
      const { onChange } = this.props;
      onChange(event);
    }
  


  render() {
    const { data } = this.props;
    const { journal } = this.state;
    console.log(data)
    return (
      <div id="divPrint" style={{width:800, border: '0px solid black'}}>
      <div >
     
      {/* <div className="report__content2">
        <Select
        name="kelas"
        onChange={this._onFormChange}
        data={ '7A', 'kelas7a'}
        placeholder={'Kelas 7A'}
        />  
        </div> */}
        {/* <Button
          title="Cetak Ke Printer"
          onClick={() => {
            onClickPrint('detail-cash-journal__header',
              'detail-cash-journal__table',
              'detail-cash-journal__footer');
          }}
        /> */}
        <div id="cetak-buku-induk" className="cetak-buku-induk">
          {
              <div className="cetak-buku-induk__content">
                <div className="cetak-buku-induk__header">
                    <div className="cetak-buku-induk__header-title">
                    <div className="cetak-buku-induk__header-title--column">
                    <div className="cetak-buku-induk__header-title--bold">
                      {/* <h1>{journal.isCredit === false ? 'BUKTI KAS KELUAR' : 'BUKTI KAS MASUK'}</h1> */}
                      <p>II. LEMBAR BUKU INDUK SISWA SMP</p>
                      </div>
                      <table className="cetak-buku-induk__table-test_nisn">
                                <tr>     
                                    <td>NOMOR INDUK SISWA/NISN: </td>
                                    <td style={{fontWeight:'bold'}}>{data.nisn?data.nisn:data.nis}</td> 
                                </tr> 
                      </table>
                      </div>
                    </div>
                 
                    <div className="cetak-buku-induk__title">  
                      <p >A. KETERANGAN TENTANG DIRI SISWA</p>
                    </div>
                    <div className="cetak-buku-induk__header-title3">
                        <div className="cetak-buku-induk__header-title3--left">
                            <table className="cetak-buku-induk__table-test">
                                <tr> 
                                    <td></td>    
                                    <td>1. Nama Siswa </td>
                                    <td>  </td> 
                                </tr> 
                                <tr>
                                    <td></td>
                                    <td>&emsp;Nama Lengkap </td>
                                    <td>  : {data.first_name} &nbsp; {data.last_name}</td>  
                                    
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>&emsp;Nama Panggilan</td>
                                    <td>  : {data.first_name}</td>   
                                    
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>2. Jenis Kelamin</td>
                                    <td>  : {data.genders_id}</td>  
                                    
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>3. Tempat dan Tanggal Lahir</td>
                                    <td>  : {data.pob}, &nbsp; {data.dob}</td>  
                                    
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>4. Agama</td>
                                    <td>  : {data.religions_id}</td>  
                                    
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>5. Kewarganegaraan</td>
                                    <td>  : {data.citizenships_id}</td>  
                                    
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>6. Anak Keberapa</td>
                                    <td>  : {data.nth_child} </td>  
                                    
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>7. Jumlah Saudara Kandung</td>
                                    <td>  : {data.siblings} </td>  
                                    
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>8. Jumlah Saudara Tiri</td>
                                    <td>  : -</td> 
                                    
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>9. Jumlah Saudara Angkat</td>
                                    <td>  : -</td> 
                                    
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>10. Anak Yatim Piatu</td>
                                    <td>  : -</td> 
                                    
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>11. Bahasa Sehari-hari Dirumah</td>
                                    <td>  : {data.language} </td>  
                                    
                                </tr>
                            </table> 
                        </div>
                      <div className="cetak-buku-induk__title4">
                          <div className="cetak-buku-induk__box--square2">
                          <img alt="Foto" style={{ width: '100%', height: '100%' }} src={data.upload_image}/>
                          </div>
                          <div className="cetak-buku-induk__box--square">
                            Cap Tiga Jari Kiri
                          </div>
                      </div>
                  </div>

                     <div className="cetak-buku-induk__title">  
                      <p >B. KETERANGAN TEMPAT TINGGAL</p>
                    </div>
                      <div className="cetak-buku-induk__header-title4">
                            <table className="cetak-buku-induk__table-test2">
                                <tr> 
                                    <td></td>    
                                    <td width ='63%'>12. Alamat </td>
                                    <td width ='50%'>   : {data.address}</td> 
                                </tr> 
                                <tr>
                                    <td></td>
                                    <td>13. Nomor Telepon </td>
                                    <td>  : {data.phone}</td>  
                                    
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>14. Tinggal dengan orang tua/</td>
                                    <td>  : </td>  
                                    
                                </tr>
                                <tr>
                                  <td></td>
                                  <td>&emsp;saudara/di Asrama/kost</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>15. Jarak tempat tinggal - Rumah</td>
                                    <td>  : {data.school_house_distance} km</td>  
                                    
                                </tr>
                            </table> 
                      </div>

                      <div className="cetak-buku-induk__title">  
                        <p >C. KETERANGAN KESEHATAN</p>
                      </div>
                      <div className="cetak-buku-induk__header-title4">
                            <table className="cetak-buku-induk__table-test3">
                                <tr> 
                                    <td></td>    
                                    <td width ='63%'>16. Golongan Darah </td>
                                    <td width ='50%'>  : {data.blood_types_id}</td> 
                                </tr> 
                                <tr>
                                    <td></td>
                                    <td>17. Penyakit yang pernah diderita </td>
                                    <td>  : {data.disabilities}</td>  
                                    
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>18. Kelainan Jasman</td>
                                    <td>  : -</td> 
                                    
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>19. Tinggi dan Berat Badan</td>
                                    <td>  : {data.height} cm &emsp; &emsp; {data.weight}kg</td>  
                                    
                                </tr>
                            </table> 
                      </div>

                      <div className="cetak-buku-induk__title">  
                        <p >D. KETERANGAN PENDIDIKAN</p>
                      </div>
                      <div className="cetak-buku-induk__header-title4">
                            <table className="cetak-buku-induk__table-test4">
                                <tr> 
                                    <td></td>    
                                    <td >20. Pendidikan Sebelumnya </td>
                                </tr> 
                                <tr>
                                    <td></td>
                                    <td width ='60%'>&emsp; &nbsp; a. Lulusan dari </td>
                                    <td width ='50%'>  : {data.previous_school_name} </td>  
                                    
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>&emsp; &nbsp; b. Tanggal dan Nomor STTB </td>
                                    <td>  : {data.date_of_transfer}</td>  
                                    
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>&emsp; &nbsp; c. Lama Belajar </td>
                                    <td>  : {data.long_study} </td>  
                                    
                                </tr>
                                <tr> 
                                    <td></td>    
                                    <td>21. Pindahan </td>
                                </tr> 
                                <tr>
                                    <td></td>
                                    <td>&emsp; &nbsp; a. Dari Sekolah</td>
                                    <td>  : {data.previous_school_name} </td> 
                                    
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>&emsp; &nbsp; b. Alasan </td>
                                    <td>  : {data.reason_move} </td>

                                </tr>
                                <tr> 
                                    <td></td>    
                                    <td>22. DIterima di Sekolah ini </td>
                                </tr> 
                                <tr>
                                    <td></td>
                                    <td>&emsp; &nbsp; a. Dikelas</td>
                                    <td>  : {data.classes_id} {data.levels_id}</td>
                                    
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>&emsp; &nbsp; b. Program </td>
                                    <td>  :  </td> 
                                    
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>&emsp; &nbsp; c. Tanggal </td>
                                    <td>  :  </td> 
                                    
                                </tr>
                               
                            </table> 
                      </div>

                      <div className="cetak-buku-induk__title">  
                        <p >E. KETERANGAN TENTANG AYAH KANDUNG</p>
                      </div>
                      <div className="cetak-buku-induk__header-title4">
                            <table className="cetak-buku-induk__table-test5">
                                <tr> 
                                    <td></td>    
                                    <td width ='60%'>23. Nama </td>
                                    <td width ='50%'>  : {data.father_name}</td> 
                                </tr> 
                                <tr>
                                    <td></td>
                                    <td>24. Tempat dan Tanggal Lahir </td>
                                    <td>  : {data.father_pob}, {data.father_dob}</td>  
                                    
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>25. Agama</td>
                                    <td>  : {data.father_religions_id}</td>  
                                    
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>26. Kewarganegaraan</td>
                                    <td>  : {data.father_citizenships_id}</td>  
                                    
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>27. Pendidikan</td>
                                    <td>  : {data.father_educations_id}</td>  
                                    
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>28. Pekerjaan</td>
                                    <td>  : {data.father_occupations_id}</td>  
                                    
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>29. Penghasilan / bulan</td>
                                    <td>  : Rp. {commaFormatted(data.father_salary)}</td>  
                                    
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>30. Alamat rumah dan telepon</td>
                                    <td>  : {data.father_address}, {data.father_phone}</td>  
                                    
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>31. Masih hidup / meninggal</td>
                                    <td>  : {data.father_status}</td>  
                                    
                                </tr>
                            </table> 
                      </div>

                      <div className="cetak-buku-induk__title">  
                        <p >F. KETERANGAN TENTANG IBU KANDUNG</p>
                      </div>
                      <div className="cetak-buku-induk__header-title4">
                            <table className="cetak-buku-induk__table-test5">
                                <tr> 
                                    <td></td>    
                                    <td width ='60%'>32. Nama </td>
                                    <td width ='50%'>  : {data.mother_name}</td> 
                                </tr> 
                                <tr>
                                    <td></td>
                                    <td>33. Tempat dan Tanggal Lahir </td>
                                    <td>  : {data.mother_pob}, {data.mother_dob}</td>  
                                    
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>34. Agama</td>
                                    <td>  : {data.mother_religions_id}</td>  
                                    
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>35. Kewarganegaraan</td>
                                    <td>  : {data.mother_citizenships_id}</td>  
                                    
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>36. Pendidikan</td>
                                    <td>  : {data.mother_educations_id}</td>  
                                    
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>37. Pekerjaan</td>
                                    <td>  : {data.mother_occupations_id}</td> 
  
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>38. Penghasilan / bulan</td>
                                    <td>  : Rp. {commaFormatted(data.mother_salary)}</td>  
                                    
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>39. Alamat rumah dan telepon</td>
                                    <td>  : {data.mother_address}, {data.mother_phone}</td>  
                                    
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>40. Masih hidup / meninggal</td>
                                    <td>  : {data.mother_status}</td>  
                                    
                                </tr>
                            </table> 
                      </div>

                      <div className="cetak-buku-induk__title">  
                        <p >G. KETERANGAN TENTANG WALI</p>
                      </div>
                      <div className="cetak-buku-induk__header-title4">
                            <table className="cetak-buku-induk__table-test5">
                                <tr> 
                                    <td></td>    
                                    <td width ='60%'>41. Nama </td>
                                    <td width ='50%'>  : {data.guardian_name?data.guardian_name:'-'}</td>  
                                </tr> 
                                <tr>
                                    <td></td>
                                    <td>42. Tempat dan Tanggal Lahir </td>
                                    <td>  : {data.guardian_pob}, {data.guardian_dob}</td>  
                                    
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>43. Agama</td>
                                    <td>  : {data.guardian_religions_id}</td>  
                                    
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>44. Kewarganegaraan</td>
                                    <td>  : {data.guardian_citizenships_id}</td> 
                                    
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>45. Pendidikan</td>
                                    <td>  : {data.guardian_educations_id}</td>   
                                    
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>46. Pekerjaan</td>
                                    <td>  : {data.guardian_occupations_id}</td>  
                                    
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>47. Penghasilan / bulan</td>
                                    <td>  : Rp. {commaFormatted(data.guardian_salary)}</td>  
                                    
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>48. Alamat rumah dan telepon</td>
                                    <td>  : {data.guardian_address}, {data.guardian_phone}</td>  
                                    
                                </tr>
                            </table> 
                      </div>

                      <div className="cetak-buku-induk__title">  
                        <p >H. KEGEMARAN SISWA</p>
                      </div>
                      <div className="cetak-buku-induk__header-title4">
                            <table className="cetak-buku-induk__table-test6">
                                <tr> 
                                    <td></td>    
                                    <td width ='60%'>49. Hobi </td>
                                    <td width ='50%'>  : {data.hobby} </td>  
                                </tr> 
                                {/* <tr>
                                    <td></td>
                                    <td>50. Olahraga </td>
                                    <td>  : </td> 
                                    
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>51. Kemasyarakatan / Organisasi</td>
                                    <td>  : </td> 
                                    
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>52. Lain-lain</td>
                                    <td>  : </td> 
                                    
                                </tr> */}
                               
                            </table> 
                      </div>

                      {/* <div className="cetak-buku-induk__title">  
                        <p >I. KETERANGAN PERKEMBANGAN SISWA</p>
                      </div>
                      <div className="cetak-buku-induk__header-title4">
                            <table className="cetak-buku-induk__table-test7">
                                <tr> 
                                    <td></td>    
                                    <td>53. Menerima Beasiswa</td>
                                    <td>  : &emsp; &emsp; Tahun </td> 
                                    <td> &emsp; /Kelas</td>
                                    <td>&emsp;dari</td>
                                </tr> 
                                <tr> 
                                    <td></td>    
                                    <td>&emsp; &emsp; &emsp;</td>
                                    <td>  &nbsp; &emsp; &emsp; Tahun </td> 
                                    <td> &emsp; /Kelas</td>
                                    <td>&emsp;dari</td>
                                </tr> 
                                <tr> 
                                    <td></td>    
                                    <td>&emsp; &emsp; &emsp;</td>
                                    <td>  &nbsp; &emsp; &emsp; Tahun </td> 
                                    <td> &emsp; /Kelas</td>
                                    <td>&emsp;dari</td>
                                </tr> 
                                <tr>
                                    <td></td>
                                    <td>54. Meninggalkan sekolah</td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td>&emsp; &nbsp; a. Tgl Meninggalkan Sekolah</td>
                                  <td> : {data.date_of_exit}</td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td>&emsp; &nbsp; b. Alasan</td>
                                  <td> : {data.transfer_to}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>55. Akhir Pendidikan</td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td>&emsp; &nbsp; a. Tamat Belajar</td>
                                  <td> : &emsp; &nbsp;Tahun </td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td>&emsp; &nbsp; b. STTB Nomor</td>
                                  <td> : </td>
                                </tr>
                               
                            </table> 
                      </div>

                      <div className="cetak-buku-induk__title">  
                        <p >J. KETERANGAN SETELAH SELESAI PENDIDIKAN</p>
                      </div>
                      <div className="cetak-buku-induk__header-title4">
                            <table className="cetak-buku-induk__table-test8">
                                
                                <tr>
                                    <td></td>
                                    <td>56. Melanjutkan di</td>
                                    <td> : </td>
                                </tr>
                                
                                <tr>
                                    <td></td>
                                    <td>57. Bekerja</td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td>&emsp; &nbsp; a. Tanggal mulai kerja</td>
                                  <td> :  </td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td>&emsp; &nbsp; b. nama perusahaan / lembaga</td>
                                  <td> : </td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td>&emsp; &nbsp; c. Penghasilan</td>
                                  <td> : </td>
                                </tr>
                               
                            </table> 
                      </div> */}


                </div>
                
                <div className="cetak-buku-induk__body">
                <table className="cetak-buku-induk__table">
                  <thead>
                    <tr>
                      <th rowSpan={5}>No. </th>
                      <th rowSpan={5}>Mata Pelajaran</th>
                      <th colSpan={8}>Tahun Pelajaran</th>
                      <th rowSpan={4} colSpan={2}>Nilai Ujian Akhir</th>
                    </tr>
                    <tr>
                      <th colSpan={2}>.../...</th>
                      <th colSpan={2}>.../...</th>
                      <th colSpan={2}>.../...</th>
                      <th colSpan={2}>.../...</th>
                    </tr>
                    <tr>
                      <th colSpan={2}>Kelas...</th>
                      <th colSpan={2}>Kelas...</th>
                      <th colSpan={2}>Kelas...</th>
                      <th colSpan={2}>Kelas...</th>
                    </tr>
                    <tr>
                      <th colSpan={2}>Nilai Prestasi</th>
                      <th colSpan={2}>Nilai Prestasi</th>
                      <th colSpan={2}>Nilai Prestasi</th>
                      <th colSpan={2}>Nilai Prestasi</th>
                    </tr>
                    <tr>
                      <th>Smt-1</th>
                      <th>Smt-2</th>
                      <th>Smt-1</th>
                      <th>Smt-2</th>
                      <th>Smt-1</th>
                      <th>Smt-2</th>
                      <th>Smt-1</th>
                      <th>Smt-2</th>
                      <th>Tulis</th>
                      <th>Praktik</th>
                    </tr>
                    

                  </thead>
                  <tbody>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan="2" className="nominal">Jumlah</td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                    </tr>
                    <tr>
                      <td colSpan="2" className="nominal">Rata-rata</td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                    </tr>
                    <tr>
                      <td colSpan="1" rowSpan="3" className="nominal">Kegiatan Ekstrakulikuler</td>
                      <td className="nominal">1............</td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                    </tr>
                    <tr>
                    <td className="nominal">2............</td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>

                    </tr>
                    <tr>
                    <td className="nominal">3............</td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>

                    </tr>
                    <tr>
                      <td colSpan="1" rowSpan="4" className="nominal">Perilaku</td>
                      <td className="nominal">1. Kelakuan</td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                    </tr>
                    <tr>
                    <td className="nominal">2. Kerajinan/Kedisiplinan</td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>

                    </tr>
                    <tr>
                    <td className="nominal">3. Kerjainan/Kebersihan</td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>

                    </tr>
                    <tr>
                    <td className="nominal">4. Ketaatan</td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>

                    </tr>

                    <tr>
                      <td colSpan="1" rowSpan="3" className="nominal">Ketidakhadiran</td>
                      <td className="nominal">1. Sakit</td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                    </tr>
                    <tr>
                    <td className="nominal">2. Izin</td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>

                    </tr>
                    <tr>
                    <td className="nominal">3. Alpha</td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>
                      <td className="nominal"></td>

                    </tr>
                    <tr>
                      <td colSpan="2" rowSpan="2" className="nominal">Keputusan Hasil Belajar</td>
                      <td colSpan="2" rowSpan="2" className="nominal"></td>
                      <td colSpan="2" rowSpan="2" className="nominal"></td>
                      <td colSpan="2" rowSpan="2" className="nominal"></td>
                      <td colSpan="2" rowSpan="2" className="nominal"></td>
                      <td colSpan="2" rowSpan="2" className="nominal"></td>
                      
                     
                      
                    </tr>
                    
                  </tfoot>
                </table>
              </div>
                {/* <div className="cetak-8355__footer">
                  <div className="cetak-8355__footer-bottom">
                    <div>
                      <p>Diserahkan oleh:</p>
                      <p>{journal.submitted_by}</p>
                    </div>
                    <div>
                      <p>Diterima oleh:</p>
                      <p>{journal.accepted_by}</p>
                    </div>
                    <div>
                      <p>Dibukukan oleh:</p>
                      <p>{journal.booked_by}</p>
                    </div>
                    <div>
                      <p>Diketahui oleh:</p>
                      <p>Name 4</p>
                    </div>
                  </div>
                </div> */}
              </div>
           
          }
        </div>
      </div>
      </div>
    );
  }

}
export default class CetakBukuSiswa extends Component {
  constructor(props){
    super(props);
    this._setForm = this._setForm.bind(this);
    this.state={
      data: {},
    }
  }

  componentDidMount() {
    const { location, getLevels, getclassrooms, user } = this.props;
    const { state = {} } = location;
    const {isEdit =  false, id} = state;
    const { students_id } = state.data || '';
    const paramTypes = ['classrooms', 'levels'];
    console.log('cek state ' +state)
    if (id) {
      this._setForm(id);
    }
  }

  async _setForm(id, data = {}) {
    const { handleGetStudentNumber } = this.props;
    try {
      const payload = await handleGetStudentNumber({ id });
      console.log(id)
      this.setState({
        data: payload || {}
      });
    } catch (err) {
      // err action
    }
  }

  componentDidMount() {
    const { location, getLevels, getclassrooms, user } = this.props;
    const { state = {} } = location;
    const {isEdit =  false, id} = state;
    const { students_id } = state.data || '';
    const paramTypes = ['classrooms', 'levels'];
    // console.log(id)
    if (id) {
      this._setForm(id);
    }
  }

  async _setForm(id, data = {}) {
    const { handleGetStudentNumber } = this.props;
    try {
      const payload = await handleGetStudentNumber({ id });
      console.log(id)
      this.setState({
        data: payload || {}
      });
    } catch (err) {
      // err action
    }
  }


  

  render() {
    const { data } = this.state
    return (
      <div className="content-wrapper">
          <section className="content-header">
              <div className="row">
                  <div className="col-md-12">
                      <div className="box">

                        <div className="divContainter">
                        <h1>Laporan Kesiswaan</h1>
                          <hr></hr>
                          <div>
                            <ReactToPrint
                              trigger={() =>  <Button style={{marginBottom:10}} block>Print</Button>}
                              content={() => this.componentRef}
                            />
                            <ComponentToPrint data={data} ref={el => (this.componentRef = el)} />
                          </div>

                          
                        </div>
                      </div>
                  </div>
              </div>
          </section>
      </div>
      
    );
  }
}
  CetakBukuSiswa.propTypes = {
    handleGetCashJournal: PropTypes.func,
    match: PropTypes.object.isRequired,
  };
  CetakBukuSiswa.defaultProps = {
    handleGetCashJournal: noop,
  };
  
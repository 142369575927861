import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, noop } from 'lodash';
import { Input, Button, Select } from '../../../components/base';

export default class StaffTableForm extends Component {
  constructor(props) {
    super(props);
    this._onAddList = this._onAddList.bind(this);
  }

  _onAddList() {
    const { onAddList, fieldName } = this.props;
    onAddList(fieldName);
  }

  render() {
    const {
      list, error, onChange,
      fieldName, listField, title,
    } = this.props;
    return (
      <div>
        <h1>{title}</h1>
        <table className="manage-staff__form-table">
          <thead>
            <tr>
              {
                map(listField.tableHead, (field, fieldIdx) => (
                  <th key={`manage_staff_form_table_head_${fieldIdx}`}>{field}</th>
                ))
              }
            </tr>
          </thead>
          <tbody>
            {
              map(list, (data, idx) => (
                <tr key={`manage_staff_list__${idx}`}>
                  {
                    map(listField.name, (field, nameIdx) => {
                      const { type = 'text' } = field;
                      if (nameIdx === 0) {
                        return (
                          <td key={`manage_staff_form_table_body_${idx}_${nameIdx}`}>{idx + 1}</td>
                        );
                      }
                      if (type === 'select') {
                        return (
                          <td key={`manage_staff_form_table_body_${idx}_${nameIdx}`}>
                            <Select
                              noMargin
                              data-input-array
                              data-array-position={idx}
                              data-field-name={fieldName}
                              name={field.name}
                              data={field.data}
                              onChange={onChange}
                              placeholder={field.placeholder}
                              value={data[field.name] || ''}
                              error={error[idx] ? error[idx][field.name] : ''}
                            />
                          </td>
                        );
                      }
                      return (
                        <td key={`manage_staff_form_table_body_${idx}_${nameIdx}`}>
                          <Input
                            noMargin
                            data-input-array
                            data-array-position={idx}
                            data-field-name={fieldName}
                            name={field.name}
                            type={type}
                            placeholder=""
                            onChange={onChange}
                            value={data[field.name] || ''}
                            error={error[idx] ? error[idx][field.name] : ''}
                          />
                        </td>
                      );
                    })
                  }
                </tr>
              ))
            }
            <tr className="manage-staff__add-more">
              <td colSpan={listField.tableHead.length}>
                <div>
                  <Button
                    icon="icon-plus"
                    onClick={this._onAddList}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
StaffTableForm.propTypes = {
  list: PropTypes.array.isRequired,
  error: PropTypes.array.isRequired,
  onAddList: PropTypes.func,
  onChange: PropTypes.func,
  title: PropTypes.string,
  fieldName: PropTypes.string,
  listField: PropTypes.object.isRequired,
};
StaffTableForm.defaultProps = {
  onAddList: noop,
  onChange: noop,
  title: '',
  fieldName: '',
};

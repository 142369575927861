import { connect } from 'react-redux';
import CetakLaporan8355 from '../../../../views/Student/report/cetak/CetakLaporan8355.student.view';
import { manageRegistration } from '../../../../states/thunks/student.thunk';
import {getParamOptions, getSubjectOptions} from '../../../../states/thunks/options.thunk';


function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    result: state.result,
      user: state.user || {},
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getSubjectOptions: (payload) => dispatch(getSubjectOptions(payload)),
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
    handleManageRegistration: (payload, push) => dispatch(manageRegistration(payload, push)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CetakLaporan8355);

import React, { Component, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  map, noop, isEmpty, filter, sortBy
} from 'lodash';
import ReactExport from 'react-export-excel';
import language from '../../../../../languages';
import { Button, Input, Pagination } from '../../../../../components/base';
import SelectCoa from '../../../../../components/SelectCoa/SelectCoa.component';
import { commaFormatted } from '../../../../../utils/transformer.util';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

// import {Datatable} from "@o2xp/react-datatable";
const userGroupPusat = [
  'Korektor Pusat',
  'Manager Keuangan',
  'Bendahara',
];

export default class BudgetRapbuDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      listAmount: 5,
      childVisible: false,

      form: {
        value: {
          tema: '',
        },
      },
      selectedTema: '',
    };
    this.handlePageClick = this.handlePageClick.bind(this);
    this.setRef = this.setRef.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this.renderRows = this.renderRows.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
    this.onChangePengeluaran = this.onChangePengeluaran.bind(this);
    this.onSearchTema = this.onSearchTema.bind(this);
    // this.CompateDate = this.CompateDate.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.getData = this.getData.bind(this);

    this.renderExcel = this.renderExcel.bind(this);

    // this.renderRowsPendapatan = this.renderRowsPendapatan.bind(this);

    this.checkboxRefs = {
      ypl: {},
      committee: {},
      intern: {},
      bos: {},
    };
    // console.log(this.state.form.value.tema)
  }

  onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this.getData({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }


  getData() {
    const { pengeluaranSummary } = this.props;
    const { pengeluaranloop } = pengeluaranSummary;
    const res = this.renderRows(pengeluaranSummary.pengeluaranloop);

    // console.log(pengeluaranSummary.pengeluaranloop)x

    // console.log(typeof res);

    this.setState({
      list: res,
    });
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState({
      currentPage: selectedPage,
      offset,
    }, () => {
      this.renderRows();
    });
  };

  onChangePengeluaran(event) {
    const { onChange, selectedDivisi } = this.props;
    onChange(event, 'Pengeluaran', selectedDivisi);
  }

  _onFormChange(event) {
    const { onChange } = this.props;
    onChange(event);
  }


  onFormChange(event) {
    const {
      name,
      value,
      dataset,
    } = event.target;
    const { onChange, idx } = this.props;
    // console.log(onChange)
    // console.log(idx)

    onChange({
      target: {
        name,
        value,
        dataset: {
          ...dataset,
          inputArray: true,
          arrayPosition: idx,
        },
      },
    });
    // console.log(dataset.arrayPosition)
  }

  onSearchTema(tema = null) {
    // let divisiValue = this.state.selectedDivisi?this.state.selectedDivisi:2;
    const { handleGetTemaOptions } = this.props;
    const { target } = tema;
    const { value } = target;

    this.setState({ selectedTema: target.value });
    // console.log(this.props)
    // console.log(value)
    // console.log(divisiValue)
    // console.log(this.state.selectedTema)
  }

  setRef(el) {
    const { input } = el;
    const { dataset } = input;
    const { pos, referenceid } = dataset;
    this.checkboxRefs[pos][referenceid] = input;
  }

  Total(list, tempcode) {
    const totalRutin = 0;
    const totalKegiatan = 0;
    const totalBiaya = 0;
    const totalDana = 0;
    let totalAll = 0;
    let tempNameTema = '';
    // console.log(list);
    if (list !== null) {
      map(list, (pengeluaran, idx) => {
        if (pengeluaran.code_tema == tempcode) {
          let totalpengeluaran = 0;
          for (let a = 0; a < pengeluaran.data.length; a++) {
            const { allocations } = pengeluaran.data[a];
            const keys = Object.keys(allocations) || [];
            let totalAlo = 0;

            keys.forEach((key) => {
              totalAlo += parseInt(allocations[key].nominal);
            });

            totalpengeluaran += totalAlo;
          }

          totalAll += totalpengeluaran;
          // console.log(totalpengeluaran)
        }
      });
      // console.log(totalAll)
    }

    return totalAll;
  }



  renderExcel(list) {
    const {
      tema,
    } = this.props;
    const { list: temaList } = tema;
    const { selectedTema } = this.state;
    const { code: selectedTemaCode } = selectedTema;
    const filteredTema = selectedTemaCode ?
      temaList.filter(o => o.code === selectedTemaCode) : temaList;
    const excelTbl = [];
    // console.log(filteredTema)
    if (filteredTema && filteredTema !== null) {
      filteredTema.forEach((item, itemIndex) => {
        const filteredList = Object.values(list).filter(o => o.code_tema === item.code);
        if (filteredList.length > 0) {
          excelTbl.push({
            name_tema: `${item.code} - ${item.title}`
          });
          let namaTema = item.title;

          // console.log(namaTema)

          Object.keys(filteredList).forEach((listKey) => {
            const listItem = filteredList[listKey];
            const { data: listItemData } = listItem;
            let filteredData = listItemData;
            if (selectedTemaCode) {
              filteredData = listItemData.filter(o => o.tema_code === selectedTemaCode);
            }
            filteredData.forEach((row, rowIndex) => {
              const { allocations } = row;
              if (allocations) {
                Object.keys(allocations).forEach((key) => {
                  const allocation = allocations[key];
                  const { nominal } = allocation;
                  if (nominal) {
                    excelTbl.push({
                      name_tema: namaTema,
                      execution_time: row.execution_time,
                      desc: row.desc,
                      quantity: row.quantity,
                      price: commaFormatted(row.price),
                      term: row.term,
                      plafond: allocation.plafond,
                      nominal: commaFormatted(nominal),
                    });
                    namaTema = '';

                  }
                });
              }
            });
          });
          excelTbl.push({
            nominal: commaFormatted(this.Total(list, item.code)),
          });
        }
      });

      return (
        <ExcelFile element={<Button block="true" style={{ height: 30, width: 300 }}>Export Excel</Button>}>
          <ExcelSheet data={excelTbl} name="excel">
            {/* <ExcelColumn label="Kode" value="code_tema"/> */}
            <ExcelColumn label="Kegiatan" value="name_tema" />
            <ExcelColumn label="Waktu Pelaksanaan" value="execution_time" />
            <ExcelColumn label="Rincian Anggaran" value="desc" />
            <ExcelColumn label="Kuantitas" value="quantity" />
            <ExcelColumn label="Nominal" value="price" />
            <ExcelColumn label="Periode" value="term" />
            <ExcelColumn label="Anggaran" value="plafond" />
            <ExcelColumn label="Total" value="nominal" />
          </ExcelSheet>
        </ExcelFile>
      );
    }

    return false;
  }

  renderRows(list) {
    const {
      tema,
    } = this.props;
    const { list: temaList } = tema;
    const { selectedTema } = this.state;
    const { code: selectedTemaCode } = selectedTema;
    const filteredTema = selectedTemaCode ?
      temaList.filter(o => o.code === selectedTemaCode) : temaList;
    const rows = [];


    if (filteredTema && filteredTema !== null) {
      filteredTema.forEach((item, itemIndex) => {
        const filteredList = Object.values(list).filter(o => o.code_tema === item.code);
        if (filteredList.length > 0) {
          rows.push((
            <tr key={`budget_rapbu_rows_${item.code}`}>
              <td colSpan="8"><b> {`${item.code} - ${item.title}`} </b></td>
            </tr>
          ));
          let namaTema = item.title;
          let tmpDate = "";
          let tmpDate2 = "";

          Object.keys(filteredList).forEach((listKey) => {
            const listItem = filteredList[listKey];
            const { data: listItemData } = listItem;
            let filteredData = listItemData;
            if (selectedTemaCode) {
              filteredData = listItemData.filter(o => o.tema_code === selectedTemaCode);
            }

            // console.log(filteredData);

            // let prevData2 = filteredData.execution_time;
            let dateCounter = 0


            filteredData.forEach((row, rowIndex) => {
              const { allocations } = row;

              // console.log(row.execution_time);
            
              
              if (tmpDate !== row.execution_time ) {
                tmpDate = row.execution_time;
              } else {
                tmpDate = ""
              }

              if (tmpDate !== row.execution_time) { dateCounter++ } else {dateCounter = 0}
           
              if (allocations) {

                Object.keys(allocations).forEach((key) => {
                  const allocation = allocations[key];
                  const { nominal } = allocation;
                  if (nominal) {
                    // if(prevData != row.execution_time){
                    // console.log(prevData2 + " => " + row.execution_time)
                    rows.push((
                      <tr key={`budget_rapbu_rows_${Date.now() * key * Math.random(100)}`}>
                        <td>{namaTema}</td>
                        <td>{dateCounter === 0 ? row.execution_time : ""}</td>
                        <td>{row.desc}</td>
                        <td>{row.quantity}</td>
                        <td className="nominal">Rp.{commaFormatted(row.price)}</td>
                        <td>{row.term}</td>
                        <td>{allocation.plafond}</td>
                        <td className="nominal">Rp.{commaFormatted(nominal)}</td>
                      </tr>
                    ))
                    namaTema = '';
                  }
                });
              }
              

            });
          });
          rows.push((
            <tr key={`${Date.now() * itemIndex * Math.random(100)}`}>
              <td colSpan="10" className="nominal">Rp.{commaFormatted(this.Total(list, item.code))}</td>
            </tr>
          ));
        }
      });
    }
    return rows;
  }

  renderHeader() {
    const {
      workflow, user,
    } = this.props;
    const { user_group } = user;

    const isBendahara = !!((user_group && user_group.name === 'Bendahara'));
    const isPusat = user_group && userGroupPusat.includes(user_group.name);


    return (
      <thead>
        <tr>
          <th className="manage-budget__row table" style={{ width: '50%' }} rowSpan={2}>Kegiatan</th>
          <th className="manage-budget__row table" style={{ width: '5%' }} rowSpan={2}>Waktu Pelaksanaan</th>
          <th className="manage-budget__row table" rowSpan={2}>Rincian Anggaran</th>
          <th className="manage-budget__row table" rowSpan={2}>Kuantitas</th>
          <th className="manage-budget__row table" rowSpan={2}>Nominal</th>
          <th className="manage-budget__row table" rowSpan={2}>Period</th>
          <th className="manage-budget__row table" rowSpan={2}>Anggaran</th>
          <th className="manage-budget__row table" rowSpan={2}>Total</th>
        </tr>
      </thead>

    );
  }

  render() {
    const {
      paging, currentPage, totalPage, tema, idx, data, error, type, status, target,
      pengeluaranSummary, pendapatanSummary, budgetSummary, recommendations, onSearchTema,
      user, onSearchCoa,
    } = this.props;
    const {
      content, edit, selectedDivisi, list,
    } = this.state;
    const editing = status === 'ADD' || edit;
    const canEdit = editing && type !== 'RAPBU';

    const { pengeluaranloop } = pengeluaranSummary;
    // const {pendapatanloop} = pendapatanSummary;
    const { budgetloop } = budgetSummary;
    const { user_group } = user;

    const isBendahara = !!((user_group && user_group.name === 'Bendahara'));

    const isPusat = user_group && userGroupPusat.includes(user_group.name);

    const tableColSpan = (isPusat) ? 6 : 6;
    const summaryRowColSpan = 2;

    const pengeluaranData = filter(pengeluaranSummary.pengeluaranloop, (o, index) => {
      if (o.divisi_id === selectedDivisi) {
        o.realIndex = index;
        return true;
      }
      return false;
    });

    return (

      <div className="manage-budget__table-wrapper">
        <SelectCoa
          noMargin
          edit={canEdit}
          name="tema"
          value={this.state.selectedTema}
          target={tema}
          tema={target}
          error={error.tema}
          coa={tema}
          onClick={this.onSearchTema}
          placeholder="Pilih tema"
          rightIcon="icon-search"
          onSearchCoa={onSearchTema}
        />
        <br />
        {this.renderExcel(pengeluaranSummary.pengeluaranloop)}
        <br />
        <table className="table">
          {this.renderHeader()}
          <tbody>
            {this.renderRows(pengeluaranSummary.pengeluaranloop)}
          </tbody>
        </table>
      </div>
    );
  }
}
BudgetRapbuDetail.propTypes = {
  onChangeRecommendation: PropTypes.func,
  onSelectBudgetDetail: PropTypes.func,
  rapbuSummary: PropTypes.object.isRequired,
  idx: PropTypes.number.isRequired,
  user: PropTypes.object,
  tema: PropTypes.object.isRequired,
  recommendations: PropTypes.object,
  status: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  handleGetTemaOptions: PropTypes.func,
  onSearchTema: PropTypes.func,
  // onSearchTema:PropTypes.func,
  workflow: PropTypes.object,
  tema: PropTypes.object.isRequired,
  error: PropTypes.object,
  data: PropTypes.object,
};
BudgetRapbuDetail.defaultProps = {
  handleGetTemaOptions: noop,
  onSearchCoa: noop,
  onChange: noop,
  onSearchTema: noop,
  error: {},
  data: {},
  onChangeRecommendation: noop,
  onSelectBudgetDetail: noop,
  user: null,
  recommendations: {},
  workflow: null,
  // tema: [],
};
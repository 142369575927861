import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, isEqual, map } from "lodash";
import StudentReportFilter from "../../../components/Filter/StudentReport.filter.component";
import ContentTable from "../../../components/ContentTable/ContentTable.component";
import ListForm from "../../../components/ListForm/ListForm.component";
import { STUDENT_UNIT_2_FORM_FIELDS } from "../../../constants/student.constant";
import { DAFTAR_NILAI_FORM_FIELDS } from "../../../constants/kurikulum/ulangan.constant";
import { validateRequiredFields } from "../../../utils/validation.util";
import {
  commaFormatted,
  normalizeAmount,
} from "../../../utils/transformer.util";
import { Button } from "../../../components/base/index";
import LembarMingguanForm from "./components/LembarMingguanForm.component";
import DaftarNilaiPraktekForm from "./components/DaftarNilaiPraktekForm.component";
import NilaiAfekTable from "./components/NilaiAfekTable.component";
import NilaiKognitifForm from "./components/NilaiKognitifForm.component";
import LembarMingguanForm2 from "./components/LembarMingguanForm2.component";
import PengisianDaftarNilaiForm from "./components/PengisianDaftarNilaiForm.component";
import PengisianDaftarNilaiTable from "./components/PengisianDaftarNilaiTable.component";
import PengisianDaftarNilaiTableB from "./components/PengisianDaftarNilaiTableB.component";
import PengisianDaftarNilaiTableC from "./components/PengisianDaftarNilaiTableC.component";

export default class LembarObsHarian extends PureComponent {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._onClickNext = this._onClickNext.bind(this);
    this._onClickBack = this._onClickBack.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._setForm = this._setForm.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);
    this._getPrmAspectOptions = this._getPrmAspectOptions.bind(this);
    this._getKDMaterial = this._getKDMaterial.bind(this);
    this._getKDMaterial2 = this._getKDMaterial2.bind(this);

    this._onClickAdd1A = this._onClickAdd1A.bind(this);
    this._onDeleteList1A = this._onDeleteList1A.bind(this);
    this._onClickAdd2A = this._onClickAdd2A.bind(this);
    this._onDeleteList2A = this._onDeleteList2A.bind(this);
    this._onClickAdd3A = this._onClickAdd3A.bind(this);
    this._onDeleteList3A = this._onDeleteList3A.bind(this);
    this._onClickAdd4A = this._onClickAdd4A.bind(this);
    this._onDeleteList4A = this._onDeleteList4A.bind(this);
    this._onClickAdd5A = this._onClickAdd5A.bind(this);
    this._onDeleteList5A = this._onDeleteList5A.bind(this);
    this._onClickAdd6A = this._onClickAdd6A.bind(this);
    this._onDeleteList6A = this._onDeleteList6A.bind(this);
    this._onClickAdd7A = this._onClickAdd7A.bind(this);
    this._onDeleteList7A = this._onDeleteList7A.bind(this);

    this._onClickAdd1B = this._onClickAdd1B.bind(this);
    this._onDeleteList1B = this._onDeleteList1B.bind(this);
    this._onClickAdd2B = this._onClickAdd2B.bind(this);
    this._onDeleteList2B = this._onDeleteList2B.bind(this);
    this._onClickAdd3B = this._onClickAdd3B.bind(this);
    this._onDeleteList3B = this._onDeleteList3B.bind(this);
    this._onClickAdd4B = this._onClickAdd4B.bind(this);
    this._onDeleteList4B = this._onDeleteList4B.bind(this);
    this._onClickAdd5B = this._onClickAdd5B.bind(this);
    this._onDeleteList5B = this._onDeleteList5B.bind(this);

    const { user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;
    const { development_aspect_id } = 1;
    const { development_aspect_id_b } = 1;
    this.state = {
      page: 1,
      form: {
        value: {
          development_aspect_id,
          development_aspect_id_b,
          levels_id,
          organizations_id: organizations_id,

          muatan_materi_1a: [{ muatan_1a: "" }],
          muatan_materi_2a: [{ muatan_2a: "" }],
          muatan_materi_3a: [{ muatan_3a: "" }],
          muatan_materi_4a: [{ muatan_4a: "" }],
          muatan_materi_5a: [{ muatan_5a: "" }],
          muatan_materi_6a: [{ muatan_6a: "" }],
          muatan_materi_7a: [{ muatan_7a: "" }],

          muatan_materi_1b: [{ muatan_1b: "" }],
          muatan_materi_2b: [{ muatan_2b: "" }],
          muatan_materi_3b: [{ muatan_3b: "" }],
          muatan_materi_4b: [{ muatan_4b: "" }],
          muatan_materi_5b: [{ muatan_5b: "" }],
        },
        error: {
          muatan_materi_1a: "",
          muatan_materi_2a: "",
          muatan_materi_3a: "",
          muatan_materi_4a: "",
          muatan_materi_5a: "",
          muatan_materi_6a: "",
          muatan_materi_7a: "",

          muatan_materi_1b: "",
          muatan_materi_2b: "",
          muatan_materi_3b: "",
          muatan_materi_4b: "",
          muatan_materi_5b: "",
        },
      },
      param: {},
    };
  }

  componentDidMount() {
    const { location, getLevels, getclassrooms, user } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";
    const { form } = this.state;
    // const { development_aspect_id, development_aspect_id_b } = form.value;
    const paramTypes = ["classrooms", "levels"];
    // console.log(state)
    if (isEdit && id) {
      // this.setState({students_id:id})
      // this.setState(prevState => ({
      //   form: {
      //     value: {
      //       ...prevState.form.value,
      //       students_id:id,
      //       organizations_id: 4,
      //     },
      //     error: {
      //       ...prevState.form.error,
      //     }
      //   }
      // }));
      this._setForm(id, data);
    }
    console.log(this.state);
    paramTypes.forEach((type) => {
      // this._getParamOptionsAcademics(type);
    });
    // this._getStudentOptions();
    // this._getFolderOptions();

    // this._getclassrooms();
    // getclassrooms();
    // getLevels();
    // if (id) {
    //   this.setState({id:id})
    //   this._handleLoadData(id)
    // }

    this._getPeriodsOptions();
    this._getPrmAspectOptions();
    this._getKDMaterial();
    this._getKDMaterial2();
  }

  // componentDidUpdate(prevProps) {
  //   const { location } = this.props;
  //   const { state = {} } = location;
  //   const { isEdit = false, data } = state;
  //   const { id } = state.data || "";
  //   if ((!isEdit || !id) && !isEqual(prevProps, this.props)) {
  //     this._onSearchContent({ page: 1 });
  //   }
  // }

  async _getPeriodsOptions() {
    const { getPeriodsOptions } = this.props;
    const res = await getPeriodsOptions();
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  async _getPrmAspectOptions() {
    const { getPrmAspectOptions } = this.props;
    const res = await getPrmAspectOptions();
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        prm_aspect: res,
      },
    }));
  }

  async _setForm(id, data = {}) {
    const { handleGetLembarObsHarian, user } = this.props;
    const { organizations_id } = user;
    try {
      const payload = await handleGetLembarObsHarian({ id });
      // if (name === 'development_aspect_id') {
      this._getKDMaterial({ filters: { development_aspect_id: [1] } });
      this._getKDMaterial2({ filters: { development_aspect_id: [2] } });
      // }
      this.setState({
        form: {
          value: {
            id: payload.id,
            students_id: payload.students_id,
            conclusion: payload.conclusion,
            conclusion_kognitif: payload.conclusion_kognitif,
            rating_star: payload.rating_star,
            rating_star_kognitif: payload.rating_star_kognitif,
            attitude_notes: payload.attitude_notes,
            kognitif_notes: payload.kognitif_notes,
            descriptions_notes_report: payload.descriptions_notes_report,
            assessment_date: payload.assessment_date,
            academics_type: payload.academics_type,
            semester: payload.semester,
            content_type: payload.content_type,
            teacher_notes: payload.teacher_notes,
            organizations_id: organizations_id,
            period: payload.period,

            muatan_materi_1a: map(payload.muatan_materi_1a, (detail) => ({
              muatan_1a: detail.muatan_1a,
            })),
            muatan_materi_2a: map(payload.muatan_materi_2a, (detail) => ({
              muatan_2a: detail.muatan_2a,
            })),
            muatan_materi_3a: map(payload.muatan_materi_3a, (detail) => ({
              muatan_3a: detail.muatan_3a,
            })),
            muatan_materi_4a: map(payload.muatan_materi_4a, (detail) => ({
              muatan_4a: detail.muatan_4a,
            })),
            muatan_materi_5a: map(payload.muatan_materi_5a, (detail) => ({
              muatan_5a: detail.muatan_5a,
            })),
            muatan_materi_6a: map(payload.muatan_materi_6a, (detail) => ({
              muatan_6a: detail.muatan_6a,
            })),
            muatan_materi_7a: map(payload.muatan_materi_7a, (detail) => ({
              muatan_7a: detail.muatan_7a,
            })),

            muatan_materi_1b: map(payload.muatan_materi_1b, (detail) => ({
              muatan_1b: detail.muatan_1b,
            })),
            muatan_materi_2b: map(payload.muatan_materi_2b, (detail) => ({
              muatan_2b: detail.muatan_2b,
            })),
            muatan_materi_3b: map(payload.muatan_materi_3b, (detail) => ({
              muatan_3b: detail.muatan_3b,
            })),
            muatan_materi_4b: map(payload.muatan_materi_4b, (detail) => ({
              muatan_4b: detail.muatan_4b,
            })),
            muatan_materi_5b: map(payload.muatan_materi_5b, (detail) => ({
              muatan_5b: detail.muatan_5b,
            })),

            development_aspect_id: 1,
            development_aspect_id_b: 2,
          },
          error: {
            // details: [],
          },
        },
      });
    } catch (err) {
      // err action
    }
  }

  async onEdit() {
    const { history, handleEditLembarObsHarian } = this.props;
    const { form } = this.state;
    const { value } = form;
    let canSaveA = false;
    let canSaveB = false;
    const dataTabel = value.pelajaran;
    const dataForm = form.value;
    let a;
    await handleEditLembarObsHarian(value);
    history.push(
      "/dashboard/kurikulum/ulangan/pengisian-lembar-daftar-nilai/report"
    );
    // }
  }

  async onSubmit() {
    const { history, handleSaveLembarObsHarian } = this.props;
    const { form } = this.state;
    const { value } = form;
    let canSaveA = false;
    const dataForm = form.value;
    await handleSaveLembarObsHarian(value);
    history.push("/dashboard/kurikulum/ulangan/pengisian-observasi-harian");
    // history.go(0);
  }

  async _getKDMaterial(filters = {}) {
    const { getKDMaterial } = this.props;
    const { form } = this.state;
    const { development_aspect_id } = form.value;
    const res = await getKDMaterial(filters);
    filters.development_aspect_id = development_aspect_id;

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        kd_prm: res,
      },
    }));
  }

  async _getKDMaterial2(filters = {}) {
    const { getKDMaterial } = this.props;
    const { form } = this.state;
    const { development_aspect_id_b } = form.value;
    const res = await getKDMaterial(filters);
    filters.development_aspect_id = development_aspect_id_b;

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        kd_prm_b: res,
      },
    }));
  }

  _onFormChange(event) {
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          if (type === "checkbox") {
            formattedValue = checked;
          }
          newList = prevState.form.value[fieldName];
          newListError = prevState.form.error[fieldName];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          // if (!isEmpty(newListError[arrayPosition])) {
          //   newListError[arrayPosition][name] = "";
          // }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {
        if (name === "development_aspect_id") {
          this._getKDMaterial({ filters: { development_aspect_id: [1] } });
        }
        if (name === "development_aspect_id_b") {
          this._getKDMaterial2({ filters: { development_aspect_id: [2] } });
        }
      }
    );
  }

  // _onClickNext() {
  //   const { history } = this.props;
  //   history.push("/dashboard/kesiswaan/laporan/daftar-siswa-kelas-unit");
  // }

  _onClickNext() {
    window.scrollTo(0, 0);
    this.setState((prevState) => ({
      page: prevState.page + 1,
    }));
  }

  _onClickBack() {
    window.scrollTo(0, 0);
    this.setState((prevState) => ({
      page: prevState.page - 1,
    }));
  }

  _onClickAdd1A(data) {
    const { form } = this.state;
    const { muatan_materi_1a } = form.value;

    muatan_materi_1a.push({
      muatan_1a: "",
    });

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          muatan_materi_1a,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }
  _onDeleteList1A(idx) {
    this.setState((prevState) => {
      const { muatan_materi_1a } = prevState.form.value;
      const list1a = prevState.form.value.muatan_materi_1a;
      list1a.splice(idx, 1);
      return {
        ...prevState,
        muatan_materi_1a: list1a,
      };
    });
  }

  _onClickAdd2A(data) {
    const { form } = this.state;
    const { muatan_materi_2a } = form.value;

    muatan_materi_2a.push({
      muatan_2a: "",
    });

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          muatan_materi_2a,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }
  _onDeleteList2A(idx) {
    this.setState((prevState) => {
      const { muatan_materi_2a } = prevState.form.value;
      const list2a = prevState.form.value.muatan_materi_2a;
      list2a.splice(idx, 1);
      return {
        ...prevState,
        muatan_materi_2a: list2a,
      };
    });
  }

  _onClickAdd3A(data) {
    const { form } = this.state;
    const { muatan_materi_3a } = form.value;

    muatan_materi_3a.push({
      muatan_3a: "",
    });

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          muatan_materi_3a,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }
  _onDeleteList3A(idx) {
    this.setState((prevState) => {
      const { muatan_materi_3a } = prevState.form.value;
      const list3a = prevState.form.value.muatan_materi_3a;
      list3a.splice(idx, 1);
      return {
        ...prevState,
        muatan_materi_3a: list3a,
      };
    });
  }

  _onClickAdd4A(data) {
    const { form } = this.state;
    const { muatan_materi_4a } = form.value;

    muatan_materi_4a.push({
      muatan_4a: "",
    });

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          muatan_materi_4a,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }
  _onDeleteList4A(idx) {
    this.setState((prevState) => {
      const { muatan_materi_4a } = prevState.form.value;
      const list4a = prevState.form.value.muatan_materi_4a;
      list4a.splice(idx, 1);
      return {
        ...prevState,
        muatan_materi_4a: list4a,
      };
    });
  }

  _onClickAdd5A(data) {
    const { form } = this.state;
    const { muatan_materi_5a } = form.value;

    muatan_materi_5a.push({
      muatan_5a: "",
    });

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          muatan_materi_5a,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }
  _onDeleteList5A(idx) {
    this.setState((prevState) => {
      const { muatan_materi_5a } = prevState.form.value;
      const list5a = prevState.form.value.muatan_materi_5a;
      list5a.splice(idx, 1);
      return {
        ...prevState,
        muatan_materi_5a: list5a,
      };
    });
  }

  _onClickAdd6A(data) {
    const { form } = this.state;
    const { muatan_materi_6a } = form.value;

    muatan_materi_6a.push({
      muatan_6a: "",
    });

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          muatan_materi_6a,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }
  _onDeleteList6A(idx) {
    this.setState((prevState) => {
      const { muatan_materi_6a } = prevState.form.value;
      const list6a = prevState.form.value.muatan_materi_6a;
      list6a.splice(idx, 1);
      return {
        ...prevState,
        muatan_materi_6a: list6a,
      };
    });
  }

  _onClickAdd7A(data) {
    const { form } = this.state;
    const { muatan_materi_7a } = form.value;

    muatan_materi_7a.push({
      muatan_7a: "",
    });

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          muatan_materi_7a,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }
  _onDeleteList7A(idx) {
    this.setState((prevState) => {
      const { muatan_materi_7a } = prevState.form.value;
      const list7a = prevState.form.value.muatan_materi_7a;
      list7a.splice(idx, 1);
      return {
        ...prevState,
        muatan_materi_7a: list7a,
      };
    });
  }

  _onClickAdd1B(data) {
    const { form } = this.state;
    const { muatan_materi_1b } = form.value;

    muatan_materi_1b.push({
      muatan_1b: "",
    });

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          muatan_materi_1b,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }
  _onDeleteList1B(idx) {
    this.setState((prevState) => {
      const { muatan_materi_1b } = prevState.form.value;
      const list1b = prevState.form.value.muatan_materi_1b;
      list1b.splice(idx, 1);
      return {
        ...prevState,
        muatan_materi_1b: list1b,
      };
    });
  }

  _onClickAdd2B(data) {
    const { form } = this.state;
    const { muatan_materi_2b } = form.value;

    muatan_materi_2b.push({
      muatan_2b: "",
    });

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          muatan_materi_2b,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }
  _onDeleteList2B(idx) {
    this.setState((prevState) => {
      const { muatan_materi_2b } = prevState.form.value;
      const list2b = prevState.form.value.muatan_materi_2b;
      list2b.splice(idx, 1);
      return {
        ...prevState,
        muatan_materi_2b: list2b,
      };
    });
  }

  _onClickAdd3B(data) {
    const { form } = this.state;
    const { muatan_materi_3b } = form.value;

    muatan_materi_3b.push({
      muatan_3b: "",
    });

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          muatan_materi_3b,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }
  _onDeleteList3B(idx) {
    this.setState((prevState) => {
      const { muatan_materi_3b } = prevState.form.value;
      const list3b = prevState.form.value.muatan_materi_3b;
      list3b.splice(idx, 1);
      return {
        ...prevState,
        muatan_materi_3b: list3b,
      };
    });
  }

  _onClickAdd4B(data) {
    const { form } = this.state;
    const { muatan_materi_4b } = form.value;

    muatan_materi_4b.push({
      muatan_4b: "",
    });

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          muatan_materi_4b,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }
  _onDeleteList4B(idx) {
    this.setState((prevState) => {
      const { muatan_materi_4b } = prevState.form.value;
      const list4b = prevState.form.value.muatan_materi_4b;
      list4b.splice(idx, 1);
      return {
        ...prevState,
        muatan_materi_4b: list4b,
      };
    });
  }

  _onClickAdd5B(data) {
    const { form } = this.state;
    const { muatan_materi_5b } = form.value;

    muatan_materi_5b.push({
      muatan_5b: "",
    });

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          muatan_materi_5b,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }
  _onDeleteList5B(idx) {
    this.setState((prevState) => {
      const { muatan_materi_5b } = prevState.form.value;
      const list5b = prevState.form.value.muatan_materi_5b;
      list5b.splice(idx, 1);
      return {
        ...prevState,
        muatan_materi_5b: list5b,
      };
    });
  }

  render() {
    const { location, getLevels, getclassrooms, user } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";
    const { form, param, page } = this.state;
    // const { development_aspect_id } = form.value;
    const {
      muatan_materi_1a,
      muatan_materi_2a,
      muatan_materi_3a,
      muatan_materi_4a,
      muatan_materi_5a,
      muatan_materi_6a,
      muatan_materi_7a,

      muatan_materi_1b,
      muatan_materi_2b,
      muatan_materi_3b,
      muatan_materi_4b,
      muatan_materi_5b,
    } = form.value;
    console.log(form);
    return (
      <div className="student-list">
        <div className="budget__title">
          <h1>Pengisian Lembar Observasi </h1>
          <hr></hr>
        </div>
        <form onSubmit={this._onSubmit}>
          <div className="result__content">
            {page === 1 ? (
              <PengisianDaftarNilaiForm
                form={form}
                param={param}
                data={data}
                onFormChange={this._onFormChange}
              />
            ) : (
              ""
            )}
          </div>
          <br />

          {page === 1 ? (
            <PengisianDaftarNilaiTable
              form={form}
              onFormChange={this._onFormChange}
              param={param}
              onAddList1A={this._onClickAdd1A}
              onDeleteList1A={this._onDeleteList1A}
              list1a={muatan_materi_1a}
              onAddList2A={this._onClickAdd2A}
              onDeleteList2A={this._onDeleteList2A}
              list2a={muatan_materi_2a}
              onAddList3A={this._onClickAdd3A}
              onDeleteList3A={this._onDeleteList3A}
              list3a={muatan_materi_3a}
              onAddList4A={this._onClickAdd4A}
              onDeleteList4A={this._onDeleteList4A}
              list4a={muatan_materi_4a}
              onAddList5A={this._onClickAdd5A}
              onDeleteList5A={this._onDeleteList5A}
              list5a={muatan_materi_5a}
              onAddList6A={this._onClickAdd6A}
              onDeleteList6A={this._onDeleteList6A}
              list6a={muatan_materi_6a}
              onAddList7A={this._onClickAdd7A}
              onDeleteList7A={this._onDeleteList7A}
              list7a={muatan_materi_7a}
            />
          ) : page === 2 ? (
            <PengisianDaftarNilaiTableB
              form={form}
              onFormChange={this._onFormChange}
              param={param}
              onAddList1B={this._onClickAdd1B}
              onDeleteList1B={this._onDeleteList1B}
              list1b={muatan_materi_1b}
              onAddList2B={this._onClickAdd2B}
              onDeleteList2B={this._onDeleteList2B}
              list2b={muatan_materi_2b}
              onAddList3B={this._onClickAdd3B}
              onDeleteList3B={this._onDeleteList3B}
              list3b={muatan_materi_3b}
              onAddList4B={this._onClickAdd4B}
              onDeleteList4B={this._onDeleteList4B}
              list4b={muatan_materi_4b}
              onAddList5B={this._onClickAdd5B}
              onDeleteList5B={this._onDeleteList5B}
              list5b={muatan_materi_5b}
            />
          ) : (
            ""
          )}

          {page === 1 ? (
            <div className="manage-registration__footer">
              <div className="manage-registration__button-wrapper">
                <Button onClick={this._onClickNext} title="Next" />
              </div>
            </div>
          ) : page === 7 ? (
            <div className="student-list__header">
              <div className="student-list__button">
                <Button onClick={this._onClickBack} title="Kembali" />
              </div>
              <div className="student-list__button">
                {this.state.id ? (
                  <Button type="button" title="Update" onClick={this.onEdit} />
                ) : (
                  <Button onClick={this.onSubmit} title="Simpan" />
                )}
              </div>
            </div>
          ) : (
            <div className="student-list__header">
              <div className="student-list__button">
                <Button onClick={this._onClickBack} title="Kembali" />
              </div>
              <div className="student-list__button">
                <Button onClick={this._onClickNext} title="Next" />
              </div>
            </div>
          )}
        </form>
      </div>
    );
  }
}
LembarObsHarian.propTypes = {
  handleGetKlapper: PropTypes.func,
  handleDeleteKlapper: PropTypes.func,
};
LembarObsHarian.defaultProps = {
  handleGetKlapper: noop,
  handleDeleteKlapper: noop,
};

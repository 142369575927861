import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import budgeting from '../../assets/img/finance/anggaran.png';
import bookkeeping from '../../assets/img/bookkeeping/jurnal_bank.png';
import { map } from 'lodash';

export default class Kurikulum extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickPengumuman = this.onClickPengumuman.bind(this);
    this.onClickJadwal = this.onClickJadwal.bind(this);
    this.onClickUlangan = this.onClickUlangan.bind(this);
    this.onClickMataPelajaran = this.onClickMataPelajaran.bind(this);
    this.onClickBobotPenilaian = this.onClickBobotPenilaian.bind(this);
    this.onClickRencanaPenilaian = this.onClickRencanaPenilaian.bind(this);
    this.onClickRaport = this.onClickRaport.bind(this);
  }

  onClickPengumuman() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/pengumuman');
  }
  onClickJadwal() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/jadwal');
  }
  onClickUlangan() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/ulangan');
  }

  onClickMataPelajaran() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/mata-pelajaran');
  }

  onClickBobotPenilaian() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/bobot-penilaian');
  }
  onClickRencanaPenilaian() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/rencana-penilaian');
  }

  onClickRaport() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/raport');
  }

  render() {
    const { user } = this.props;
    const { permissions } = user.user_group;
    return (
      <div>
        {
          map(permissions, (item) => {
            if(item == "view_form_TU"){
              return (
                <div className="akademi">
                  <div className="akademi__list">
                    <button onClick={this.onClickPengumuman}>
                      <img src={budgeting} alt="Pengumuman"/>
                      <h3>Pengumuman</h3>
                      </button>
                  </div>
                  <div className="akademi__list">
                    <button onClick={this.onClickJadwal}>
                      <img src={budgeting} alt="Jadwal"/>
                      <h3>Jadwal Kelas & Karyawan</h3>
                      </button>
                  </div>
                  {/* <div className="akademi__list">
                    <button onClick={this.onClickUlangan}>
                      <img src={budgeting} alt="Ulangan" />
                      <h3>Ulangan</h3>
                    </button>
                  </div> */}
                  <div className="akademi__list">
                    <button onClick={this.onClickMataPelajaran}>
                      <img src={budgeting} alt="Matapelajaran" />
                      <h3>Mata Pelajaran</h3>
                    </button>
                  </div>
                  <div className="akademi__list">
                    <button onClick={this.onClickBobotPenilaian}>
                      <img src={budgeting} alt="BobotPenilaian" />
                      <h3>Bobot Penilaian</h3>
                    </button>
                  </div>
                  {/* <div className="akademi__list">
                    <button onClick={this.onClickRaport}>
                      <img src={budgeting} alt="Raport" />
                      <h3>Raport</h3>
                    </button>
                  </div> */}
                </div>
              )
            }
            if(item == "view_ulangan_guru"){
              return(
                <div className="akademi">
                  <div className="akademi__list">
                  <button onClick={this.onClickUlangan}>
                    <img src={budgeting} alt="Ulangan" />
                    <h3>Ulangan</h3>
                  </button>
                </div>
                {/* <div className="akademi__list">
                  <button onClick={this.onClickPengumuman}>
                    <img src={budgeting} alt="Pengumuman"/>
                    <h3>Pengumuman</h3>
                    </button>
                </div> */}
                <div className="akademi__list">
                  <button onClick={this.onClickJadwal}>
                    <img src={budgeting} alt="Jadwal"/>
                    <h3>Jadwal Kelas & Karyawan</h3>
                    </button>
                </div>
                <div className="akademi__list">
                  <button onClick={this.onClickMataPelajaran}>
                    <img src={budgeting} alt="Matapelajaran" />
                    <h3>Mata Pelajaran</h3>
                  </button>
                </div>
                <div className="akademi__list">
                  <button onClick={this.onClickBobotPenilaian}>
                    <img src={budgeting} alt="BobotPenilaian" />
                    <h3>Bobot Penilaian</h3>
                  </button>
                </div>
                <div className="akademi__list">
                  <button onClick={this.onClickRaport}>
                    <img src={budgeting} alt="Raport" />
                    <h3>Raport</h3>
                  </button>
                </div>
              </div>
              )
            }
          })
        }
      </div>
      
    );
  }
}
Kurikulum.propTypes = {
  history: PropTypes.object.isRequired,
};

import { connect } from 'react-redux';
import ManageBudget from '../../../../views/Finance/Budgeting/Budget/ManageBudget.finance.view';
import { getCoa, getCoaOptions, uploadFile, downloadFile  } from '../../../../states/thunks/common.thunk';
import {
  getBudgetDetail, addBudgetDetail, editBudgetDetail, 
  deleteBudgetDetail, getFlaponDetail,
} from '../../../../states/thunks/finance.thunk';
import { getDivisiOptions, getDivisiDetailListOptions, 
  getPlafondOptions, getTemaOptions } 
  from '../../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  // console.log(tema)
  return {
    coa: state.coa,
    user: state.user,
    divisi: state.options.divisi,
    plafond: state.options.plafond,
    tema : state.options.tema,
    
  };
  
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetCoaOptions: payload => dispatch(getCoaOptions(payload)),
    handleGetDivisiDetailsList: payload => dispatch(getDivisiDetailListOptions(payload)),
    handleGetDivisiOptions: payload => dispatch(getDivisiOptions(payload)),
    handleGetCoa: payload => dispatch(getCoa(payload)),
    handleGetBudgetDetail: payload => dispatch(getBudgetDetail(payload)),
    handleGetFlaponDetail: payload => dispatch(getFlaponDetail(payload)),
    handleGetPlafondOptions: payload => dispatch(getPlafondOptions(payload)),
    handleGetTemaOptions: payload => dispatch(getTemaOptions(payload)),
    handleAddBudgetDetail: (payload, status) => dispatch(addBudgetDetail(payload, status)),
    handleEditBudgetDetail: (payload, status) => dispatch(editBudgetDetail(payload, status)),
    handleDeleteBudgetDetail: (payload, status) => dispatch(deleteBudgetDetail(payload, status)),
    handleFileUpload: (file) => dispatch(uploadFile(file)),
    handleFileDownload: (filename) => dispatch(downloadFile(filename)),
  };
  
}


export default connect(mapStateToProps, mapDispatchToProps)(ManageBudget);

import React, { Component } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import PropTypes from 'prop-types';
import logo from '../../../../../src/assets/img/logo-kopsurat-ricci.png';
import CurrencyFormat from 'react-currency-format';
import {
  map, noop, isEmpty,
  filter, isEqual, find, has, pickBy, keys,
  cloneDeep,
} from 'lodash';
import Header2 from '../../../../components/Header/Header2.component'
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb.component';
import ListForm from '../../../../components/ListForm/ListForm.component';
import { commaFormatted, generateYearList, normalizeAmount, datetimeFormat } from '../../../../utils/transformer.util';
import {
  Select, Input, SearchSelect2,SearchSelect, Button, Textarea, Checkbox 
} from '../../../../components/base';
import SelectCoa from '../../../../components/SelectCoa/SelectCoa.component';
import MonthlyCheckList from './components/MonthlyCheckList.component';
import MonthlyActivityPlanFormTableHeaders from './components/MonthlyActivityPlanFormTableHeaders.component';
import language from '../../../../languages';
import { printRKB } from '../../../../utils/print.util';
import { errorAlert } from '../../../../utils/alert.util';
import { RAPBS_RAPBY} from '../../../../constants/finance/budgeting/budget.constant';
import { setWorkingUnit } from '../../../../states/thunks/auth.thunk';
import {validateRKB} from '../../../../utils/validation.util';
// import PrintPDF from '../../../../pages/Print.page';
// import { PDFDownloadLink } from '@react-pdf/renderer';

const MySwal = withReactContent(Swal);

const calculateTotal = (list = null) => {
  let total = 0;
  if (!isEmpty(list)) {
    for (let key in list) {
      let item = list[key];

      if(list[key] && list[key].hasOwnProperty('amount')) {
        item = list[key]['amount'];
      }
      if (item) {
        total += parseFloat(item);
      }
    };
  }
  return total;
};






export default class ManageMonthlyActivityPlan extends Component {
  constructor(props) {
    super(props);

    const { user, location } = props;
    const { user_group } = user;
    const permissions = user_group.permissions;
// console.log(user_group);
    this.setForm = this.setForm.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this._renderButtons = this._renderButtons.bind(this);
    this._renderFundChecklist = this._renderFundChecklist.bind(this);
    this._renderFundChecklist2 = this._renderFundChecklist2.bind(this);
    this._renderWorkflowHistory = this._renderWorkflowHistory.bind(this);
    this._getCoaOptions = this._getCoaOptions.bind(this);
    this._getTemaOptions = this._getTemaOptions.bind(this);
    this._renderFormHeader = this._renderFormHeader.bind(this);
    this._onSelectCoa = this._onSelectCoa.bind(this);
    this.onSearchTema = this.onSearchTema.bind(this);
    this._showModal = this._showModal.bind(this);
    this._onCheckAll = this._onCheckAll.bind(this);
    this.renderButtonTtd = this.renderButtonTtd.bind(this);
    this._setSelectedDetail = this._setSelectedDetail.bind(this);
    this._onCheckDetail = this._onCheckDetail.bind(this);
    this._onCloseModal = this._onCloseModal.bind(this);
    this._onDelete = this._onDelete.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this.TotalRAPBS = this.TotalRAPBS.bind(this);
    this.TotalRAPBS2 = this.TotalRAPBS2.bind(this);
    this._addListItem = this._addListItem.bind(this);
    this._setRemarks = this._setRemarks.bind(this);
    this.onClickUnit = this.onClickUnit.bind(this);
    this.Tema = this.Tema.bind(this);
    this._setRef = this._setRef.bind(this);
    this._onReject = this._onReject.bind(this);
    this._confirmReject = this._confirmReject.bind(this);
    this._onSubmitApproval = this._onSubmitApproval.bind(this);
    this._setTableConfig = this._setTableConfig.bind(this);
    this._getData = this._getData.bind(this);
    this.School_unit = this.School_unit.bind(this);
    this.onClickCetak = this.onClickCetak.bind(this);
    this.Total=this.Total.bind(this);
    this.renderFundrequestDetails = this.renderFundrequestDetails.bind(this);
    this.remarks = React.createRef();
    this.checkboxRefs = [];

    this.state = {
      filters: {
        head: null,
        periode: new Date().getFullYear(),
        tema:null,
        coa: null,
        coa2:null,
      },
      form: {
        value: {
          amount: 0,
          total_approval: 0,
          total: 0,
          periode: null,
          selectedBudget: null,
          details: {},
          recommendations: {},
          month:null,
          tema:[],
          coa:[],
          coa2:[],
          needed_funds:'',
          amount :'',
          difference :0,
          payment_of_funds :'',
          keterangan :'',
          totalplafond:0,
          totalRapbs:0

        },
        error: {coa: '',
                tema: '',
                amount: 0,
                month:'',    },
      },
      tema: [],
      coa: [],
      workflow: [],
      needed_funds:'',
      amount:'',
      difference:0,
      payment_of_funds:'',
      keterangan:'',
      user_id: {},
      history:'',
      fund_request_detail: {},

      coa2: [],
      selectedTema :{},
      setWorkingUnit:{},
      selectedCoa: {},
      selectedBudgetDetails: {},
      availableBudgetDetailsRKB: {},
      availableBudgetDetailsRKB2: {},
      checkedAll: false,
      is_show : false,
      checkedDetails: [],
      remarks: '',
      is_approved: false,
      budgets: {},
      school_unit: {},
      
    };

    this.tableConfig = {
      showEdit: false,
      showApprovalCol: false,
      showEditRecommendation: false,
      showRecommendation: false,
      showView: false,
    }

    this.permissions = {
      canEditRecommendation:
        permissions.includes('edit_fund_request_recommendation'),
      canApprove:
        permissions.includes('approve_fund_request'),
      canEdit:
        permissions.includes('edit_fund_request'),
      canView:
        permissions.includes('view_fund_request'),
    };
  }

  _setTableConfig() {
    let { tableConfig, permissions } = this;
    const { location, user } = this.props;
    const { user_group } = user;
    const userGroup = user_group.name;
    const { data } = location.state || {};
    const { is_approved = false } = data || {};
    const { workflow, form } = this.state;
    const { recommendations } = form.value;
    const hasRecommendations = keys(recommendations).length > 0;
    const workflowLength = workflow.length || 0;

    if (is_approved) {
        tableConfig = {
          showEdit: false,
          showEditRecommendation: false,
          showRecommendation: true,
          showApprovalCol: true,
        };
    } else if (workflowLength > 0) {
      const { next_role, prev_role } = workflow[workflowLength-1];

      if(userGroup !== next_role) {
        tableConfig.showEdit = false;
        tableConfig.showEditRecommendation = false;
        tableConfig.showView = true;

        if (permissions.canEditRecommendation) {
          if(userGroup === prev_role) {
            tableConfig.showRecommendation = true;
          }
        }
      } else {
        if (permissions.canEditRecommendation) {
          tableConfig.showEditRecommendation = true;
          tableConfig.showRecommendation = true;
        }

        if (permissions.canApprove) {
          tableConfig.showApprovalCol = true;
          tableConfig.showRecommendation = true;
        }

        if (permissions.canEdit) {
          tableConfig.showEdit = true;
        }
      }
    } else {
      if (permissions.canEdit) {
        tableConfig.showEdit = true;
      }
    }
    this.tableConfig = tableConfig;
  }
  onClickCetak(val) {
    const { history } = this.props;
    history.push(
      "/dashboard/keuangan/penganggaran/cetak-rencana-kegiatan-bulanan",
      { data: val, isEdit: false }
    );
  }
  
  _onCheckAll(event) {
    const { target } = event;
    const detailIds = this.checkboxRefs;
    let newState = { ...this.state };
    const isCheckedAll = target.checked;

    for (let detailId in detailIds) {
      detailIds[detailId].checked = isCheckedAll;

      newState = this._setSelectedDetail(newState, isCheckedAll, detailId);
    }

    this.setState(prevState => ({
      ...newState,
      checkedAll: target.checked,
    }));
  }

  async setForm(data) {
    const {
      id = null, periode = null,month = null, details = {}, workflow = [], user_id, budgets, fund_request_detail,
      selectedBudgetDetails = {}, recommendations = {}, amount = 0, difference ={},
      total_approval = 0, is_approved = false, school_unit} = data;
    let checkedAll = false;
    let notEmptyRecommendations = pickBy(recommendations, (value, key) => {
      return value > 0;
    });
    if(Object.keys(selectedBudgetDetails).length ===
    Object.keys(notEmptyRecommendations).length) {
      checkedAll = true;
    }
      // console.log(selectedBudgetDetails)
    const checkedDetails = notEmptyRecommendations || {};
    this.setState({
      form: {
        value: {
          id,
          amount,
          month,
          details:  Array.isArray(details) ? {} : details ,
          recommendations,
          total_approval,
        },
        error: {
          coa:'',
          tema:''
        },
      },
      workflow,
      selectedBudgetDetails,
      availableBudgetDetailsRKB: {},
      availableBudgetDetailsRKB2: {},
      checkedAll,
      checkedDetails,
      is_approved,
      school_unit,
      user_id,
      budgets,
      fund_request_detail,
    }, () => {
      this._getCoaOptions(data.school_unit.prm_school_units_id);
      this._getTemaOptions(data.school_unit.prm_school_units_id);
      this._setTableConfig();
    });
  }

  _addListItem() {
    const { form } = this.state;
    const newForm = { ...form };
    const { value } = newForm;

    if (typeof value.details === 'undefined') {
      value.details = {};
    }

    this.setState(prevState => ({
      ...prevState,
      form: {
        value: {
          ...prevState.form.value,
          ...newForm
        },
      },
    }), () => {
      this.calculateTotal();
    });
  }

  async _onFormChange(event, fieldName) {
    const {
      name,
      value,
      dataset,
    } = event.target;
    const {
      inputType = 'text', inputArray = false,  arrayPosition = null,
    } = dataset;
    const { form , totalplafond, selectedBudgetDetails } = this.state;
    const newForm = cloneDeep(form);

    this.setState((prevState) => {
      let formattedValue = value;

      // console.log(inputType)

      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }
  
      if (arrayPosition !== null) {
        if(!newForm.value.details[arrayPosition]) {
          newForm.value.details[arrayPosition] = {};
        }
        if (name === 'amount') {
          
          newForm.value.details[arrayPosition][name] = formattedValue;
            map(selectedBudgetDetails, (data, key) => {
              newForm.value.details[arrayPosition].difference = parseInt(newForm.value.details[arrayPosition].allowed_fund_request) - parseInt(formattedValue);
              
              newForm.value.amount = calculateTotal(newForm.value.details);
            });
          // console.log("ini on form "+ totalplafond)
        }
        else {
          newForm.value.details[arrayPosition][name] = formattedValue;
        }
      } else {
        newForm.value[name] = formattedValue;
      }

      const error = {
        ...prevState.form.error,
        [name]: '',
      };

      if (fieldName) {
        error[fieldName] = filter(error[fieldName], (err) => {
          if (err) {
            return err.length;
          }
          return {};
        });
      }

      return {
        ...prevState,
        form: {
          value: {
            ...prevState.form.value,
            ...newForm.value,
          },
          error: {
            ...prevState.form.error,
            [name]: '',
          },
          
        },
      };
    });
    
  }

  async _getCoaOptions(unitId = null, keyword = null, head = null) {
    const { handleMonthlyActivityPlantCoa } = this.props;
    const { form } = this.state;
    const { value } = form;
    const { periode, id, month } = form.value;
    const res = await handleMonthlyActivityPlantCoa({
      keyword,
      periode: value.periode,
      unit_id: unitId,
      id
    });
    // console.log(res)

    return res;
    
  }

  async _getTemaOptions(unitId = null, keyword = null, head = null) {
    const { handleMonthlyActivityPlantTema } = this.props;
    const { form } = this.state;
    const { value } = form;
    const res = await handleMonthlyActivityPlantTema({
      keyword,
      periode: value.periode,
      unit_id: unitId,
    });
    // console.log(res)

    return res;
    
  }

  _onCloseModal(detailState) {
    const { selectedBudgetDetails, form } = this.state;
    const { value } = form;
    const { details } = value;
    const { selected, unchecked } = detailState;

    if (selected) {
      for(let key in selected) {
        selectedBudgetDetails[key] = selected[key];
        details[key] = {
          amount: 0,
          allowed_fund_request: selectedBudgetDetails[key].allowed_fund_request,
          totalPlafond: this.TotalRAPBS(selectedBudgetDetails[key].allocations, key),
        };
      }
    }
    if (unchecked) {
      for(let key in unchecked) {
        delete selectedBudgetDetails[key];
        delete details[key];
      }
    }
    this.setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          amount: calculateTotal(details),
          details,
        }
      },
      selectedBudgetDetails: {  
        ...selectedBudgetDetails,
        ...selected
      }
    }));
  }
  _setRemarks(event) {
    const { value } = event.target;

    this.setState(prevState => ({
      ...prevState,
      remarks: value,
    }));
  }

  _setSelectedDetail(state = {}, checked = false, detailId = null) {
    const { budgets, selectedBudgetDetails } = this.props;
    const { checkedDetails = {}, form } = state;
    const { details = {} } = form.value;
    const newState = { ...state };
    let newRecommendations = newState.form.value.recommendations;

    if(!checked) {
      delete newState.checkedDetails[detailId];
      delete newRecommendations[detailId];
    } else {
      const detail = find(details, (o, key) => {
        return parseInt(key) === parseInt(detailId)
      });
      newState.checkedDetails[detailId] = detail;
      newRecommendations[detailId] = detail.amount;
    }
    newState.form.value.recommendations = newRecommendations;

    return newState;
  }

  _onSubmit(e) {
    e.preventDefault();
    const { form } = this.state;
    const {
      handleAddFundRequest,
      history, location,
    } = this.props;
    const error = null;

    const { isEdit = false } = location.state;
    if (isEmpty(error)) {
      if (isEdit) {
        handleAddFundRequest(form.value, history.goBack);
      } else {
        handleAddFundRequest(form.value, history.goBack);
      }
    } else {
      errorAlert({
        title: isEdit ? 'Gagal Mengubah' : 'Gagal Membuat',
        errorMessage: 'Periksa lengkapi form',
      });
    }
  }

  _onCheckDetail(event) {
    const { target } = event;
    const { value, checked } = target;
    let newState = { ...this.state }

    newState = this._setSelectedDetail(newState, checked, value);

    this.setState({
      ...newState
    });
  }
 

  _setRef(el) {
    if(el === null || !el) {
      return;
    }
    const { input } = el;
    const { value } = input;
    this.checkboxRefs[value] = input;
  }

  TotalRAPBS(allocations, arrayPosition = null) {
    let totalPlafond= 0;
    let totalRapbs= 0;
    let name = "";
    let rutinValue = 0;
    let kegiatanValue = 0;
    let danPemValue = 0;
    let biPengValue = 0;

    for(let i=0; i<allocations.length; i++){

      name = allocations[i].plafond.plafond;
      let alo = allocations[i].nominal;
      if(name == "Rutin")
      {
        rutinValue = parseInt(rutinValue) + parseInt(alo);
      } 
      else if(name == "Kegiatan")
      {
        kegiatanValue = parseInt(kegiatanValue) + parseInt(alo);
      }else if(name == "Dana Pemerintah")
      {
        danPemValue = parseInt(danPemValue) + parseInt(alo);
      }else if(name == "Biaya Pengembangan Pendidikan")
      {
        biPengValue = parseInt(biPengValue) + parseInt(alo);
      }
      
      totalPlafond = rutinValue+kegiatanValue+biPengValue+danPemValue;
      // this.setState((prevState) => {
      //   const newDetails = prevState.form.value.details;
      //   newDetails[arrayPosition]['totalPlafond'] = rutinValue+kegiatanValue+biPengValue+danPemValue;
      //   return {
      //     ...prevState,
      //     form: {
      //       ...prevState.form,
      //       value: {
      //         ...prevState.form.value,
      //         details: newDetails,
      //       }
      //     },
      //   };
      // });
    }
  
    //let rows=[]

    // rows.push([
    //   <td className="nominal">Rp.{commaFormatted(this.state.totalplafond)}</td>
    // ]);   
    
    return totalPlafond;
  }

  Total(allocations){
    let totalplafond= 0;
    let totalRapbs= 0;
    let name = "";
    let rutinValue = 0;
    let kegiatanValue = 0;
    let danPemValue = 0;
    let biPengValue = 0;

    for(let i=0; i<allocations.length; i++){

      name = allocations[i].plafond.plafond;
      let alo = allocations[i].nominal;
      if(name == "Rutin")
      {
        rutinValue = parseInt(rutinValue) + parseInt(alo);
      } 
      else if(name == "Kegiatan")
      {
        kegiatanValue = parseInt(kegiatanValue) + parseInt(alo);
      }else if(name == "Dana Pemerintah")
      {
        danPemValue = parseInt(danPemValue) + parseInt(alo);
      }else if(name == "Biaya Pengembangan Pendidikan")
      {
        biPengValue = parseInt(biPengValue) + parseInt(alo);
      }
      totalplafond =  rutinValue+kegiatanValue+biPengValue+danPemValue;

    }
    return(totalplafond)
  }


 
  TotalRAPBS2(selectedBudgetDetails) {
    const { form, selectedCoa, showModal = false, workflow = [],
   remarks,
      is_approved
    } = this.state;
    const { value, error } = form;
    const { details } = form.value;
    let totalRapbs= 0;
    let totalPengajuan = 0;
    let difference =0;
    let totalPlafond = 0;
    {
                
      map(selectedBudgetDetails, (data, key) => (

        
          <tr key={key}>
          
            <td>
              {data.code_of_account}
            </td>
            {this.Tema(data.tema)}
            <td>{data.desc}</td>
           
            <td>
              <div className="manage-budget__row manage-budget__row--30">
                <Input
                  noMargin
                  data-input-array
                  data-array-position={key}
                  type="date"
                  name="needed_funds"
                  onChange={this._onFormChange}
                  value={
                    details[key] ? details[key]['needed_funds'] : ''
                  }
                />
              </div>
            </td>
            <td className="nominal">
             {data.execution_time}
            </td>
           
            { commaFormatted(totalPlafond += details[key]['totalPlafond']) }
          
            <td className="nominal">
              
           
            
            </td>
           
            <td className="manage-budget__row manage-budget__row--30">
            <Input
                noMargin
                isNumber={false}
                data-input-array
                data-array-position={key}
                data-input-type="text"
                name="payment_of_funds"
              
                placeholder="isi Payment of funds"
                onChange={this._onFormChange}
                value={
                  details[key] ? details[key]['payment_of_funds'] : ''
                }
                // value={data.payment_of_funds}
              />
            </td>
            <td className="nominal">Rp.
                   {/* {difference = commaFormatted((parseInt(this.state.totalplafond) - parseInt(details[key] ? details[key]['amount'] : 0)))} */}
            </td>
            <td className="manage-budget__row manage-budget__row--30">
              <Input
                noMargin
                isNumber={false}
                data-input-array
                data-array-position={key}
                // data-input-type='text'
                name="keterangan"
                // data-input-type="number"
                placeholder="isi Payment of funds"
                onChange={this._onFormChange}
                value={
                  details[key] ? details[key]['keterangan'] : ''
                }
              />
              
            </td>
           {  totalRapbs += parseInt(details[key].totalPlafond)}
           
            { 
              <td>
                <Button onClick={() => this._onDelete(key)} icon="icon-trash-o" />
              </td>
            // )
            }
          </tr>
       
      

      ))}
    
    
    let rows=[]
  
    rows.push([
      <td>{commaFormatted(totalRapbs)}</td>
    ]);   
    
    return rows;
  }

  _confirmReject() {
    const el = this.remarks.current.input;
    el.classList.remove('hidden');
    MySwal.fire({
      title: "Masukkan remarks untuk menolak RKB",
      html: el,
      icon: "warning",
      buttons: true,
      dangerMode: true,
      showCancelButton: true,
    }).then((result) => {
      if(result.value) {
        this._onReject(el.value);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.close();
      }
    });
  }

  _onReject(remarks) {
    const { handleReject, history, location } = this.props;
    const { data } = location.state;

    handleReject({
      id: data.id,
      remarks
    }, history.goBack);
  }

  School_unit(){
    const { school_unit = {}, form, budget = {}, user_id={} } = this.state;
    const {user, location} = this.props;
    if(typeof form.value.id === 'undefined') {
      return (
        <td>{user.name}</td>
      );
    } else  {
      return (
       
         <td>{user_id[0]?(user_id[0].name):''}</td>  
          
      );
    }
  }
  Tema(tema) {

    let code =""
    let name = "";

    for(let i=0; i<tema.length; i++){

       code =tema[i].code;
       name = tema[i].name;
    }
    
    let rows=[]

    rows.push([
      <td>{code}</td>,
    <td>{name}</td>,

    ]);   
    return rows;
  }
  
  _onCheckAll(event) {
    const { target } = event;
    const detailIds = this.checkboxRefs;
    let newState = { ...this.state };
    const isCheckedAll = target.checked;

    for (let detailId in detailIds) {
      detailIds[detailId].checked = isCheckedAll;

      newState = this._setSelectedDetail(newState, isCheckedAll, detailId);
    }

    this.setState(prevState => ({
      ...newState,
      checkedAll: target.checked,
    }));
  }

  async setForm(data) {
    const {
      id = null, periode = null,month = null, details = {}, workflow = [], user_id, budgets, fund_request_detail,
      selectedBudgetDetails = {}, recommendations = {}, amount = 0, difference ={},
      total_approval = 0, is_approved = false, school_unit} = data;
    let checkedAll = false;
    let notEmptyRecommendations = pickBy(recommendations, (value, key) => {
      return value > 0;
    });
    if(Object.keys(selectedBudgetDetails).length ===
    Object.keys(notEmptyRecommendations).length) {
      checkedAll = true;
    }
      // console.log(selectedBudgetDetails)
    const checkedDetails = notEmptyRecommendations || {};
    this.setState({
      form: {
        value: {
          id,
          amount,
          month,
          details:  Array.isArray(details) ? {} : details ,
          recommendations,
          total_approval,
        },
        error: {
          coa:'',
          tema:'',
           },
      },
      workflow,
      selectedBudgetDetails,
      availableBudgetDetailsRKB: {},
      availableBudgetDetailsRKB2: {},
      checkedAll,
      checkedDetails,
      is_approved,
      school_unit,
      user_id,
      budgets,
      fund_request_detail,
    }, () => {
      this._getCoaOptions(data.school_unit.prm_school_units_id);
      this._getTemaOptions(data.school_unit.prm_school_units_id);
      this._setTableConfig();
    });
  }

  _addListItem() {
    const { form } = this.state;
    const newForm = { ...form };
    const { value } = newForm;

    if (typeof value.details === 'undefined') {
      value.details = {};
    }

    this.setState(prevState => ({
      ...prevState,
      form: {
        value: {
          ...prevState.form.value,
          ...newForm
        },
      },
    }), () => {
      this.calculateTotal();
    });
  }

  async _onFormChange(event, fieldName) {
    const {
      name,
      value,
      dataset,
    } = event.target;
    const {
      inputType = 'text', inputArray = false,  arrayPosition = null,
    } = dataset;
    const { form , totalplafond, selectedBudgetDetails } = this.state;
    const newForm = cloneDeep(form);

    this.setState((prevState) => {
      let formattedValue = value;

      // console.log(inputType)

      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }
  
      if (arrayPosition !== null) {
        if(!newForm.value.details[arrayPosition]) {
          newForm.value.details[arrayPosition] = {};
        }
        if (name === 'amount') {
          
          newForm.value.details[arrayPosition][name] = formattedValue;
            map(selectedBudgetDetails, (data, key) => {
              newForm.value.details[arrayPosition].difference = parseInt(newForm.value.details[arrayPosition].allowed_fund_request) - parseInt(formattedValue);
              
              newForm.value.amount = calculateTotal(newForm.value.details);
            });
          // console.log("ini on form "+ totalplafond)
        }
        else {
          newForm.value.details[arrayPosition][name] = formattedValue;
        }
      } else {
        newForm.value[name] = formattedValue;
      }

      const error = {
        ...prevState.form.error,
        [name]: '',
      };

      if (fieldName) {
        error[fieldName] = filter(error[fieldName], (err) => {
          if (err) {
            return err.length;
          }
          return {};
        });
      }

      return {
        ...prevState,
        form: {
          value: {
            ...prevState.form.value,
            ...newForm.value,
          },
          error: {
            ...prevState.form.error,
            [name]: '',
          },
          
        },
      };
    }, () => {
      if(name === 'month') {
        this._getCoaOptions();
        this._getTemaOptions();
      }
    });
    
  }

  async _getCoaOptions(unitId = null, keyword = null) {
    const { handleMonthlyActivityPlantCoa } = this.props;
    const { form } = this.state;
    const { value } = form;
    const { periode, id, month } = form.value;
    const res = await handleMonthlyActivityPlantCoa({
      keyword,
      periode: value.periode,
      unit_id: unitId,
      month: month,
      id
    });
    // console.log(res)

    return res;
    
  }

  async _getTemaOptions(unitId = null, keyword ='') {
    const { handleMonthlyActivityPlantTema } = this.props;
    const { form } = this.state;
    const { value } = form;
    const {id, month} = form.value;
    const res = await handleMonthlyActivityPlantTema({
      keyword,
      periode: value.periode,
      unit_id: unitId,
      month: month,
      id
    });
    // console.log(res)

    return res;
    
  }

  _onCloseModal(detailState) {
    const { selectedBudgetDetails, form } = this.state;
    const { value } = form;
    const { details } = value;
    const { selected, unchecked } = detailState;

    if (selected) {
      for(let key in selected) {
        selectedBudgetDetails[key] = selected[key];
       
        details[key] = {
          ...selectedBudgetDetails[key],
          ...details[key],
          difference: details[key]?details[key].difference:selectedBudgetDetails[key].allowed_fund_request,
          allowed_fund_request: selectedBudgetDetails[key].allowed_fund_request,
          totalPlafond: this.TotalRAPBS(selectedBudgetDetails[key].allocations, key),
        }
      }
    }
    if (unchecked) {
      for(let key in unchecked) {
        delete selectedBudgetDetails[key];
        delete details[key];
      }
    }
    this.setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          amount: calculateTotal(details),
          details,
        }
      },
      selectedBudgetDetails: {  
        ...selectedBudgetDetails,
        ...selected
      }
    }));
  }
  _setRemarks(event) {
    const { value } = event.target;

    this.setState(prevState => ({
      ...prevState,
      remarks: value,
    }));
  }

  _setSelectedDetail(state = {}, checked = false, detailId = null) {
    const { budgets, selectedBudgetDetails } = this.props;
    const { checkedDetails = {}, form } = state;
    const { details = {} } = form.value;
    const newState = { ...state };
    let newRecommendations = newState.form.value.recommendations;

    if(!checked) {
      delete newState.checkedDetails[detailId];
      delete newRecommendations[detailId];
    } else {
      const detail = find(details, (o, key) => {
        return parseInt(key) === parseInt(detailId)
      });
      newState.checkedDetails[detailId] = detail;
      newRecommendations[detailId] = detail.amount;
    }
    newState.form.value.recommendations = newRecommendations;

    return newState;
  }

  _onSubmit(e) {
    e.preventDefault();
    const { form } = this.state;
    const{value} = form;
    const {
      handleAddFundRequest,
      history, location,
    } = this.props;
    const error = null;
    const {flag} = validateRKB(form);
  
    const { isEdit = false } = location.state;
    if (isEmpty(error)) {
      if (isEdit) {
        handleAddFundRequest(form.value, history.goBack);
      } else {
        handleAddFundRequest(form.value, history.goBack);
      }
    } else {
      errorAlert({
        title: isEdit ? 'Gagal Mengubah' : 'Gagal Membuat',
        errorMessage: 'Periksa lengkapi form',
      });
    }
  }

  _onCheckDetail(event) {
    const { target } = event;
    const { value, checked } = target;
    let newState = { ...this.state }

    newState = this._setSelectedDetail(newState, checked, value);

    this.setState({
      ...newState
    });
  }

  async _onDelete(id) {
    const { handleDeleteFundRequestDetail } = this.props;
    const { form, selectedBudgetDetails } = this.state;
    const { value } = form;
    const newDetails = {...value.details};
    delete newDetails[id];
    delete selectedBudgetDetails[id];
    if (value.id) {
      await handleDeleteFundRequestDetail({
        budget_detail_id: id,
        id: value.id,
      });
    }
    this.setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          amount: calculateTotal(newDetails),
          details: {...newDetails}
        }
      },
      selectedBudgetDetails
    }));
  }


  componentDidMount() {
    const {location, handleGetMonthOptions, onSearchUnit } = this.props;
    const { data = {} } = location.state;
    if (data.id) {
      this._getData(data.id);
    } else {
      this._setTableConfig();
    }
    this._getTemaOptions();
    this._getCoaOptions();
    handleGetMonthOptions();
    onSearchUnit();
  }

  async _getData(id) {
    const { handleGetFundRequestById } = this.props;
    const res = await handleGetFundRequestById({ id });
    this._getCoaOptions();
    this.setForm(res);
  }

  _showModal() {
    const { selectedCoa, selectedTema } = this.state;
    // console.log(Object.keys(selectedCoa).length)
    // console.log(Object.keys(selectedTema).length + 'INI Tema')
    if (selectedCoa && selectedCoa.code) {
      MySwal.fire({
        size: 22000,
        title: <h2>Pilih anggaran</h2>,
        width: '4000px',
        height: '1000px',
        footer: '',
        html: this._renderFundChecklist(),
      });
    }
     
    else if(selectedTema && selectedTema.code) {
      MySwal.fire({
        size: 22000,
        title: <h2>Pilih anggaran</h2>,
        width: '4000px',
        height: '1000px',
        footer: '',
        html: this._renderFundChecklist2(),
      });
    }
    // else {}

   
    return false;
  }

  

  _renderFilters(form, isEdit) {
    const { value, error } = form;
    const { periode, } = value;
    const { options,month = {},coa ={}} = this.props;
    const filters = [];
    

    return (
      <div className="manage-fund-request__row-form">
        <div className="manage-fund-request__form-content">
          <div>
          
          <Select
            disabled={!isEdit}
            name="month"
            data={month.list}
            onChange={this._onFormChange}
            placeholder="Pilih periode"
            value={value.month}
            error={error.month || ''}
          />
         
          </div>
        </div> 
      </div>
    );
  }

  async _onSubmitApproval(e) {
    const { target } = e;
    const { user } = this.props;
    const { permissions } = user.user_group;
    const { revisions, form } = this.state;
    const { handleSubmitApproval, location, history, handleAddFundRequest } = this.props;
    const { data } = location.state;
    const error = null;
    const {flag} = validateRKB(form);
    const { isEdit = false } = location.state;

if(isEmpty(error)){
  if(typeof data === 'undefined') {
    const res = await handleAddFundRequest(form.value);
    await handleSubmitApproval({
      id: res.id,
    }, history.goBack);

  } else {
    if(permissions.includes('edit_fund_request_recommendation')) {
      const res = await handleAddFundRequest(form.value);
      await handleSubmitApproval({
        id: data.id,
      }, history.goBack);

    } else {
      if(permissions.includes('edit_fund_request')) {
        const res = await handleAddFundRequest(form.value);
        await handleSubmitApproval({
          id: data.id,
        }, history.goBack);

      } else {
        await handleSubmitApproval({
          id: data.id,
        }, history.goBack);

      }

    }
  }
}

else {
  errorAlert({
    title: isEdit ? 'Gagal Mengubah' : 'Gagal Membuat',
    errorMessage: 'Periksa lengkapi form',
  });
}
   
  }



  _renderButtons() {
    const { user, location } = this.props;
    const { data = {} } = location.state
    const { user_groups_id, user_group } = user;
    const { workflow = [] } = data;
    const lastWorkflow = workflow[workflow.length - 1] || {};
    const firstWorkflow = workflow[0] || {};
    const WorkflowPrev_role = workflow[0]?workflow[0].prev_role:'';
    const { permissions = [] } = user_group;
    let canApprove = permissions.includes('approve_fund_request');
    const roleMap = {
      'Kepala Sekolah': 'Kepala Sekolah',
      'Manager Opsdik': 'Manager Opsdik',
      'RT': 'RT',
      'Pengadaan': 'Pengadaan',
      'Keuangan': 'Keuangan',
      'Opsdik': 'Opsdik',
      'Hrd': 'Hrd',
      'Sekretariat': 'Sekretariat',
    };
// console.log(workflow[0]?workflow[0].prev_role:'')
    // if(user_group.name === 'Ketua Perwakilan') {
    //   canApprove = firstWorkflow.prev_role !== 'Bendahara Perwakilan';
    // }

    if (!lastWorkflow.next_role && !lastWorkflow.is_done ) {
      return [
        <div key="button_save">
          <Button
            type="submit"
            title="Save"
          />
        </div>,
        <div key="button_submit">
          <Button
            type="button"
            onClick={this._onSubmitApproval}
            title={(user_group.name == 'Hrd' || user_group.name =='Kepala Sekolah' || user_group.name =='Opsdik'
                    || user_group.name =='Sekretariat') ? 'Submit' : 'Approve'}
          />
        </div>,
      ];
    }
    if ((lastWorkflow.next_role === user_group.name) && !lastWorkflow.is_done) {
      const buttons = []

      if (permissions.includes('edit_fund_request') && user_group.name === WorkflowPrev_role) {
        buttons.push([
          <div key="button_save">
            <Button
              type="submit"
              title="Save"
            />
          </div>,
        ]);
      }

      buttons.push([
        <div key="button_submit">
          <Button
            type="button"
            onClick={this._onSubmitApproval}
            title={(!canApprove) ? 'Submit' : 'Approve'}
          />
        </div>
      ]);

      if (permissions.includes('reject_fund_request') && !lastWorkflow.is_done && user_group.name != WorkflowPrev_role ) {
        buttons.push([
          <div key="button_save">
            <Button
              type="button"
              onClick={this._confirmReject}
              title="Reject"
            />
          </div>]);
      }

      if (user_groups_id) {
        return buttons;
      }
    } else {
      if (lastWorkflow.is_done) {
        return (
          <div>
            <i>RKB telah setujui oleh {roleMap[lastWorkflow.next_role]}</i>
          </div>
        );
      }
      let status = 'RKB telah diajukan ke ';
      if (lastWorkflow.action === 'reject') {
        status = 'RKB telah dikembalikan ke ';
      }
      return (
        <div>
          <i>{`${status}${roleMap[lastWorkflow.next_role]}`}</i>
        </div>
      );
    }


    return false;
  }

  _renderFormHeader() {
    const { school_unit = {}, form, budgets = {}, fund_request_detail = {} } = this.state;
    const{create_at} = school_unit;
    const {user, location} = this.props;
    const { data = {} } = location.state
    const { workflow = [] } = data;
    const months = [
      '','Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli',
      'Agustus', 'September', 'Oktober', 'November', 'Desember'
    ];
    const years = new Date().getFullYear();

    if(typeof form.value.id === 'undefined') {
      return false;
    } else  {
      return (
        <div>
           <table className="fund-request-bottom__table-head">
        <tr>
          <th style={{ width: "1%" }} rowSpan={3}><img className="home__logo2" src={`${window.location.protocol}//${window.location.host}${logo}`} alt="" /></th>
          <th rowSpan={3}></th>
          <th className="test">YAYAYASAN RICCI</th>
          {/* <td></td>
          <td>Menyetujui,</td> */}
          <th style={{ width: "10%" }}></th>

        </tr>
      
        <tr>
          <th className="test">Jl. Kemenangan III/47, Glodok, Tamansari, Jakarta Barat 11120</th>
          {/* <td style={{textDecorationLine: 'underline'}}>{approvers['Keuangan']}</td>
          <td style={{textDecorationLine: 'underline'}}>{approvers['Pengadaan']}</td>
          <td style={{textDecorationLine: 'underline'}}>{approvers['RT']}</td> */}
         <th style={{ width: "10%" }}></th>
        </tr>
   
       
        <tr>
          <th className="test">  Telp. (021) 6392495 - 6260049 Fax. (021) 6491705</th>
          {/* <td>Keuangan</td>
          <td>Pengadaan</td>
          <td>Rumah Tangga</td> */}
          <th style={{ width: "10%" }}></th>
          
        </tr>
    
    </table>
         
        <div className="form_header2">
        <h1>Rencana Kegiatan Bulanan</h1>
        </div>
         <table className="fund-request-bottom__table-header">
            {/* <tr>
            <td >Rencana Kegiatan Bulanan</td>
            </tr> */}
             <tr>
               <td width="10%" >Tanggal Pengajuan</td>
               <td>: {(workflow[0])  ? workflow[0].created_at : ''} </td>
             </tr>
             <tr>
               <td width="10%">Unit</td>
               <td>: {school_unit.school_unit.name}</td>
             </tr>
             {/* { map(budgets, (budget, idx) => {
              console.log(budget);
            })} */}
             <tr>
               <td width="12%" >Periode Pelaksanaan</td>
               <td >:{months[form.value.month]}&nbsp;{years}</td>
               {/* <td>: {fund_request_detail[0].needed_funds}</td> */}
             </tr>
             <td>
             </td>

           </table>
        </div>
      );
    }
  }

  renderButtonTtd(){
    const {  form, budget = {}, school_unit = {}, user_id = {}, workflow = {} } = this.state;
    const {user, location} = this.props;
    const { data = {} } = location.state
    // const { workflow = [] } = data;
    const lastWorkflow = workflow[workflow.length - 1] || {};
    const { user_group, workingUnit = {} } = user;
    const approvers = {};
    let creator = '';
    workflow.forEach((item, index) => {
      const { user } = item;
      if (index === 0) {
        creator = user.name;
      } else if (item.action === 'submit') {
        approvers[item.prev_role] = user.name;
      }
    });
  
    if(typeof form.value.id === 'undefined') {
      return false;
    } else  {
      return (
        <table className="fund-request-bottom__table-test">
        <tr>
          <td></td>
          <td>Hormat kami,</td>
          <td>Menyetujui,</td>
          <td></td>
          <td>Mengetahui,</td>
          <td></td>
        </tr>
        <tr>
          <td className="bottom"></td>
          <td className="bottom"></td>
          <td className="bottom"></td>
          <td className="bottom"></td>
          <td className="bottom"></td>
          <td className="bottom"></td>
        </tr>
        <tr>
          <td className="bottom"></td>
          <td className="bottom"></td>
          <td className="bottom"></td>
          <td className="bottom"></td>
          <td className="bottom"></td>
          <td className="bottom"></td>
        </tr>
        <tr>
          <td className="bottom"></td>
          <td className="bottom"></td>
          <td className="bottom"></td>
          <td className="bottom"></td>
          <td className="bottom"></td>
          <td className="bottom"></td>
        </tr>
        <tr>
          <td className="bottom"></td>
          <td className="bottom"></td>
          <td className="bottom"></td>
          <td className="bottom"></td>
          <td className="bottom"></td>
          <td className="bottom"></td>
        </tr>
        
        <tr>
          <td></td>
          <td style={{textDecorationLine: 'underline'}}>{creator}</td>
          <td style={{textDecorationLine: 'underline'}}>{approvers['Manager Opsdik']}</td>
          <td style={{textDecorationLine: 'underline'}}>{approvers['Keuangan']}</td>
          <td style={{textDecorationLine: 'underline'}}>{approvers['Pengadaan']}</td>
          <td style={{textDecorationLine: 'underline'}}>{approvers['RT']}</td>
        </tr>
   
       
        <tr>
          <td></td>
          <td>{ workflow[0] && workflow[0].prev_role}</td>
          <td>Manager OPSDIK</td>
          <td>Keuangan</td>
          <td>Pengadaan</td>
          <td>Rumah Tangga</td>
        </tr>
    
    </table>
    );
  }
 }

  _renderFundChecklist() {
    const { selectedCoa = {}, selectedTema = {},  availableBudgetDetailsRKB = {},availableBudgetDetailsRKB2 = {},
      selectedBudgetDetails = {} , form} = this.state;
    const { value } = form;
    const budgetDetails = {...availableBudgetDetailsRKB[selectedCoa.code]} || {};

    return (
      <MonthlyCheckList
        budgets={budgetDetails}
        selectedBudgetDetails={selectedBudgetDetails}
        onChange={this._onCloseModal}
      />);
  }

  _renderFundChecklist2() {
    const { selectedCoa = {}, selectedTema = {},  availableBudgetDetailsRKB = {},availableBudgetDetailsRKB2 = {},
      selectedBudgetDetails = {} , form} = this.state;
    const { value } = form;
    const budgetDetails2 = {...availableBudgetDetailsRKB2[selectedTema.code]} || {};

    return (
      <MonthlyCheckList
        budgets={budgetDetails2}
        selectedBudgetDetails={selectedBudgetDetails}
        onChange={this._onCloseModal}
      />);
  }

  renderFundrequestDetails(fund_request_details){
    let difference ='';
      for(let i=0; i<fund_request_details.length; i++){
        if(fund_request_details[i].difference){
          difference =fund_request_details[i].difference;
        }
        
      
    }

    let rows=[]

    rows.push([
      // console.log(difference)
      // <td className="nominal">Rp.{commaFormatted(difference)}</td>
      (difference)
    ]);   
    return rows;
    
  }

  _renderWorkflowHistory(workflow) {
    const rows = [];
    const roleMap = {
      'Kepala Sekolah': 'Kepala Sekolah',
      'Manager Opsdik': 'Manager Opsdik',
      'RT': 'RT',
      'Pengadaan': 'Pengadaan',
      'Keuangan': 'Keuangan',
      'Opsdik': 'Opsdik',
      'Hrd': 'Hrd',
      'Sekretariat': 'Sekretariat',
    };
    
    if(workflow) {
      workflow.forEach((item, idx) => {
       
        const { prev_role, next_role, action, updated_at, remarks, is_done} = item;
        let flowAction = ''
        // let flowAction = (action === 'reject') ?
        //   'mengembalikan ke' : ' mengajukan ke';
          if(action === 'reject'){
            flowAction = 'mengembalikan ke'
          }
          else if(action === 'submit' && roleMap[prev_role] ==='Manager Opsdik'){
            flowAction = 'menyetujui, diserahkan ke' 
          }
        //   else if (action === 'submit' && roleMap[prev_role] !='Manager Opsdik' )
        //   { flowAction = 'mengetahui diserahkan ke'
        // }
        else if(roleMap[next_role] ==='Manager Opsdik'){
          flowAction = 'mengajukan ke'
        }
          else{
            flowAction = 'mengetahui, diserahkan ke'
          }
        let flowValue = `${roleMap[prev_role]} ${flowAction} ${roleMap[next_role]}`;
        
        if(is_done) {
          flowValue = `${roleMap[next_role]} mengetahui dan RKB telah selesai di approve dan di setujui `;
        }
        rows.push([
          <tr key={`workflow_history_item_${idx}`}>
            <td>{idx+1}</td>
            <td>{flowValue}</td>
            <td>{remarks}</td>
            <td>{datetimeFormat(updated_at)}</td>
          </tr>
        ])
      });
      return (
        <table className="table">
          <thead>
            <tr>
              <th>No.</th>
              <th>Flow</th>
              <th>Catatan</th>
              <th>Tanggal</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      )
    }

    return false;
  }
  async onSearchTema(event){
    const { target } = event;
    const { value } = target;
    const { selectedTema, form } = this.state;
    const { periode, id, month } = form.value;
    const { handleGetAvailableBudgetDetailsRKBTema } = this.props;
    const budgetDetails = await handleGetAvailableBudgetDetailsRKBTema({
      tema: value.code,
      month: month,
      id
    });
   

    this.setState(prevState => ({
      ...prevState,
      selectedTema: value,
      availableBudgetDetailsRKB2: {
        [value.code]: {...budgetDetails},
      }
    }));
 
  }
  async _onSelectCoa(event) {
    const { target } = event;
    const { value } = target;
    const { selectedCoa, form } = this.state;
    const { periode, id, month } = form.value;
    const { handleGetAvailableBudgetDetailsRKB } = this.props;
    const budgetDetails = await handleGetAvailableBudgetDetailsRKB({
      coa: value.code,
      month: month,
      id
    });
   

    this.setState(prevState => ({
      ...prevState,
      selectedCoa: value,
      availableBudgetDetailsRKB: {
        [value.code]: {...budgetDetails},
      }
    }));
    

  
 }

  _renderFundChecklist() {
    const { selectedCoa = {}, selectedTema = {},  availableBudgetDetailsRKB = {},availableBudgetDetailsRKB2 = {},
      selectedBudgetDetails = {} , form} = this.state;
    const { value } = form;
    const budgetDetails = {...availableBudgetDetailsRKB[selectedCoa.code]} || {};

    return (
      <MonthlyCheckList
        budgets={budgetDetails}
        selectedBudgetDetails={selectedBudgetDetails}
        onChange={this._onCloseModal}
      />);
  }

  _renderFundChecklist2() {
    const { selectedCoa = {}, selectedTema = {},  availableBudgetDetailsRKB = {},availableBudgetDetailsRKB2 = {},
      selectedBudgetDetails = {} , form} = this.state;
    const { value } = form;
    const budgetDetails2 = {...availableBudgetDetailsRKB2[selectedTema.code]} || {};

    return (
      <MonthlyCheckList
        budgets={budgetDetails2}
        selectedBudgetDetails={selectedBudgetDetails}
        onChange={this._onCloseModal}
      />);
  }

  renderFundrequestDetails(fund_request_details){
    let difference ='';
      for(let i=0; i<fund_request_details.length; i++){
        if(fund_request_details[i].difference){
          difference =fund_request_details[i].difference;
        }
        
      
    }

    let rows=[]

    rows.push([
      // console.log(difference)
      // <td className="nominal">Rp.{commaFormatted(difference)}</td>
      (difference)
    ]);   
    return rows;
    
  }

  _renderWorkflowHistory(workflow) {
    const rows = [];
    const roleMap = {
      'Kepala Sekolah': 'Kepala Sekolah',
      'Manager Opsdik': 'Manager Opsdik',
      'RT': 'RT',
      'Pengadaan': 'Pengadaan',
      'Keuangan': 'Keuangan',
      'Opsdik': 'Opsdik',
      'Hrd': 'Hrd',
      'Sekretariat': 'Sekretariat',
    };
    
    if(workflow) {
      workflow.forEach((item, idx) => {
       
        const { prev_role, next_role, action, updated_at, remarks, is_done} = item;
        let flowAction = ''
        // let flowAction = (action === 'reject') ?
        //   'mengembalikan ke' : ' mengajukan ke';
          if(action === 'reject'){
            flowAction = 'mengembalikan ke'
          }
          else if(action === 'submit' && roleMap[prev_role] ==='Manager Opsdik'){
            flowAction = 'menyetujui, diserahkan ke' 
          }
        //   else if (action === 'submit' && roleMap[prev_role] !='Manager Opsdik' )
        //   { flowAction = 'mengetahui diserahkan ke'
        // }
        else if(roleMap[next_role] ==='Manager Opsdik'){
          flowAction = 'mengajukan ke'
        }
          else{
            flowAction = 'mengetahui, diserahkan ke'
          }
        let flowValue = `${roleMap[prev_role]} ${flowAction} ${roleMap[next_role]}`;
        
        if(is_done) {
          flowValue = `${roleMap[next_role]} mengetahui dan RKB telah selesai di approve dan di setujui `;
        }
        rows.push([
          <tr key={`workflow_history_item_${idx}`}>
            <td>{idx+1}</td>
            <td>{flowValue}</td>
            <td>{remarks}</td>
            <td>{datetimeFormat(updated_at)}</td>
          </tr>
        ])
      });
      return (
        <table className="table">
          <thead>
            <tr>
              <th>No.</th>
              <th>Flow</th>
              <th>Catatan</th>
              <th>Tanggal</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      )
    }

    return false;
  }
  async onSearchTema(event){
    const { target } = event;
    const { value } = target;
    const { selectedTema, form } = this.state;
    const { periode, id, month } = form.value;
    const { handleGetAvailableBudgetDetailsRKBTema } = this.props;
    const budgetDetails = await handleGetAvailableBudgetDetailsRKBTema({
      tema: value.code,
      month: month,
      id
    });
   

    this.setState(prevState => ({
      ...prevState,
      selectedTema: value,
      availableBudgetDetailsRKB2: {
        [value.code]: {...budgetDetails},
      }
    }));
 
  }
  async _onSelectCoa(event) {
    const { target } = event;
    const { value } = target;
    const { selectedCoa, form } = this.state;
    const { periode, id, month } = form.value;
    const { handleGetAvailableBudgetDetailsRKB } = this.props;
    const budgetDetails = await handleGetAvailableBudgetDetailsRKB({
      coa: value.code,
      month: month,
      id
    });
   

    this.setState(prevState => ({
      ...prevState,
      selectedCoa: value,
      availableBudgetDetailsRKB: {
        [value.code]: {...budgetDetails},
      }
    }));
    

  }

  render() {
      const { form, selectedCoa, showModal = false, workflow = [],
      selectedBudgetDetails, checkedDetails, checkedAll, remarks,
      is_approved, selectedTema, setWorkingUnit, is_show,amount
    } = this.state;
    // const { data = {} } = location.state
    // const { workflow = [] } = data;
    const lastWorkflow = workflow[workflow.length - 1] || {};
    const {
      showEdit,
      showApprovalCol,
      showEditRecommendation,
      showRecommendation,
    } = this.tableConfig;
    const workflowLength = workflow.length || 0;
     if (workflowLength > 0) {
    const { prev_role } = workflow[workflowLength-1];
    // console.log(workflow[0].prev_role)
     }
    const { value, error } = form;
    const {
      canEditRecommendation,
      canApprove,
      canEdit,
      canView,
    } = this.permissions;
    const { details, recommendations, total_approval } = form.value;
    const {
      options, user, location, coa,coa2={},target, onSearchCoa, onSearchTema, 
      onClickMenu, pathname, onClickBreadcrumb, onSearchUnit,tema, handleSetWorkingUnit, units
    } = this.props;
    const locationState = location.state;
    const { user_group, workingUnit = {}, school_unit = {} } = user;
    const { permissions } = user_group;
    const isSubmitted = workflow.length > 0;
    const WorkflowPrev_role = workflow[0]?workflow[0].prev_role:''
    let isEdit = permissions.includes('edit_fund_request') && locationState.isEdit   ;
    let idx = 1;
    // let difference = 0;
    let totalRapbs=0;
    const fundChecklist = this._renderFundChecklist();

    return (
      <div>
         {/* <table className="table"> */}
         
         {/* <div className="fund-request-bottom__content-header">
            <h1>Rencana Kegiatan Bulanan</h1>
         </div> */}
          {
            typeof form.value.id === 'undefined' && (
              <h1>
                Rencana Kegiatan Bulanan
              </h1>
            )
          }
         {/* {
              (user_group.name === 'Manager Opsdik' || user_group.name === 'RT' || user_group.name === 'Pengadaan' 
              || user_group.name=== 'Keuangan'
                // ||  school_unit.id === 14 || school_unit.id === 2
                ) && (
               
                <Header2
                onSearchUnit={onSearchUnit}
                setWorkingUnit={handleSetWorkingUnit}
                user={user}
                units={units}
              />
              )
            }    */}
            { typeof form.value.id !== 'undefined' && showEdit===false && (
                <div className="print">
                  <Button
                    title="Cetak"
                    onClick={() => { printRKB('fund-request-bottom__table-head','home__logo2', 'form_header2','fund-request-bottom__table-header','table', 'fund-request-bottom__table-test', {
                        pageSize: 'A4 landscape',
                        printHeader: {
                          repeated: false,
                        }
                      });
                  }}
                  />
                  
                </div>
              )
            }
        {this._renderFormHeader()}
        <form onSubmit={this._onSubmit}>
         
          <Textarea
            onChange={this._setRemarks}
            ref={this.remarks}
            classNames="hidden remarks"
            value={remarks}
          />

          { this._renderFilters(form, isEdit) }
           
          <table className ="table">
            <MonthlyActivityPlanFormTableHeaders
              showEdit={showEdit}
              onCheckAll={this._onCheckAll}
              checkAll={checkedAll}
              workflow={workflow}
              user={user}
              showEditRecommendation={showEditRecommendation}
              showRecommendation={showRecommendation}
            />
            
            <tbody>
              { showEdit === true && workflow.length === 0  &&
              (
                  <tr>
                  
                  <td colSpan={ (showEdit) ? 10 : 7  }>
                    <div className="manage-budget__add-more">
                      <div className="manage-fund-request__form-content">
                        <SearchSelect2
                         noMargin
                        //  disabled={selectedTema.length>0? true : false}
                         async={false}
                         name="parameter_code"
                         list={options.coa.list}
                         onChange={this._onSelectCoa}
                         placeholder="Pilih Kode Akun"
                         value={selectedCoa}
                         labelName="title"
                         valueName="code"
                        />&nbsp;
                       
                        <SearchSelect2
                         noMargin
                         async={false}
                         name="tema"
                         list={tema.list}
                         onChange={this.onSearchTema}
                         placeholder="Pilih Tema"
                         value={selectedTema}
                         labelName="name"
                         valueName="code"
                        />&nbsp;

                        <Button
                          disabled={(Object.keys(selectedCoa).length > 0) || (Object.keys(selectedTema).length > 0)? false : true}
                          onClick={this._showModal}
                          title={language.translate.FINANCE__BUDGETING__ADD_LINE__TITLE}
                        />
                      </div>                      
                    </div>
                  </td>
                </tr>
                 )} 

              { showEdit === true && lastWorkflow.action === 'reject'  &&
              (
                  <tr>
                  
                  <td colSpan={ (showEdit) ? 10 : 7  }>
                    <div className="manage-budget__add-more">
                      <div className="manage-fund-request__form-content">
                        <SearchSelect2
                         noMargin
                        //  disabled={selectedTema.length>0? true : false}
                         async={false}
                         name="parameter_code"
                         list={options.coa.list}
                         onChange={this._onSelectCoa}
                         placeholder="Pilih Kode Akun"
                         value={selectedCoa}
                         labelName="title"
                         valueName="code"
                        />&nbsp;
                       
                        <SearchSelect2
                         noMargin
                         async={false}
                         name="tema"
                         list={tema.list}
                         onChange={ this.onSearchTema}
                         placeholder="Pilih Tema"
                         value={selectedTema}
                         labelName="name"
                         valueName="code"
                        />&nbsp;

                        <Button
                          disabled={(Object.keys(selectedCoa).length > 0) || (Object.keys(selectedTema).length > 0)? false : true}
                          onClick={this._showModal}
                          title={language.translate.FINANCE__BUDGETING__ADD_LINE__TITLE}
                        />
                      </div>                      
                    </div>
                  </td>
                </tr>
                 )} 

              {
                
              map(selectedBudgetDetails, (data, key) => (
                  <tr key={key}>
                  
                    <td className="col_sm">{idx++}</td>
                    <td className="amount" >
                      {data.code_of_account}
                    </td>
                    {this.Tema(data.tema)}
                    <td className="amount">
                      <Input
                        noMargin
                        isNumber={false}
                        data-input-array
                        data-array-position={key}
                        // data-input-type='text'
                        edit={lastWorkflow.action === 'reject' || workflow.length===0?showEdit:''}
                        // edit={showEdit}
                        name="keterangan"
                        // data-input-type="number"
                        placeholder="isi Payment of funds"
                        onChange={this._onFormChange}
                        value={
                          details[key] ? details[key]['keterangan'] : ''
                        }
                        // error={error.keterangan || ''}
                      />
                      
                    </td>
                    {/* {console.log(this.state.totalplafond)} */}
                    {this.School_unit()}
                   
                    <td className="amount">
                     
                        <Input
                          noMargin
                          width="6%"
                          data-input-array
                          data-array-position={key}
                          // edit={showEdit}
                          edit={lastWorkflow.action === 'reject' || workflow.length===0?showEdit:''}
                          type="date"
                          name="needed_funds"
                          onChange={this._onFormChange}
                          value={
                            details[key] ? details[key]['needed_funds'] : ''
                          }
                          // error={error.needed_funds||''}
                        />
                    </td>
                    <td>
                     {data.execution_time}
                    </td>
                    <td>
                    {(details[key]) ? commaFormatted(details[key]['totalPlafond']) : 0}
                    </td> 
                    <td className="amount">
                      {/* <span className="gbp">Rp. */}
                      <Input
                        input="number"
                        noMargin
                        data-input-array
                        data-array-position={key}
                        prefix={'Rp. '}
                        data-input-type="number"
                        isNumber={true}
                        // edit={showEdit}
                        edit={lastWorkflow.action === 'reject' || workflow.length===0?showEdit:''}
                        name="amount"
                        onChange={this._onFormChange}
                        value={
                          details[key] ? details[key]['amount'] : ''
                        }
                        // error={error.details[key]['amount']|| ''}
                        
                        
                      />
                      {/* </span> */}
                    </td>
                    {/* {console.log(details[key])} */}
                    <td>
                    {(details[key]) ? commaFormatted(details[key]['difference']) : ''}
                    {/* {this.renderFundrequestDetails(data.fin)} */}
                    </td>
                   
                   
                    
                  {/* { form.value.difference = commaFormatted((parseInt(this.state.totalplafond) - parseInt(details[key] ? details[key]['amount'] : 0)))} */}
                  {/* {console.log(details[key] ? details[key]['difference']:0)} */}
                    
                  <td className="amount">
                    <Input
                        noMargin
                        isNumber={false}
                        data-input-array
                        data-array-position={key}
                        data-input-type="text"
                        // edit={showEdit}
                        edit={lastWorkflow.action === 'reject' || workflow.length===0?showEdit:''}
                        name="payment_of_funds"
                      
                        placeholder="isi Payment of funds"
                        onChange={this._onFormChange}
                        value={
                          details[key] ? details[key]['payment_of_funds'] : ''
                        }
                        // error={error.payment_of_funds||''}
                        
                      />
                    </td>
                    
                    <td>{data.desc}</td>
                 
                   
                    { workflow.length === 0 && showEdit && (
                      <td>
                        <Button onClick={() => this._onDelete(key)} icon="icon-trash-o" />
                      </td>
                    )
                    }
                     { lastWorkflow.action === 'reject' && showEdit && (
                      <td>
                        <Button onClick={() => this._onDelete(key)} icon="icon-trash-o" />
                      </td>
                    )
                    }
                     <Button
                     onClick={() => this.onClickCetak(data)}
                    title='Print'
            />
                  </tr>
              ))}
              <tr className="total">
                <td colSpan={8}>Total</td>
               {this.TotalRAPBS2(selectedBudgetDetails)}
               <td> {commaFormatted(form.value.amount)}</td>
                <td ></td>
                <td ></td>
                <td ></td>
                
                {
                /* <td colSpan={(showEditRecommendation) ? 8 : (showRecommendation || showEdit ) ? 7 : 7}>Total</td>
                <td className="nominal">{commaFormatted(form.value.amount)}</td>
                  { showEdit && (
                  <td></td>
                )}
                {
                  showRecommendation &&
                  (
                    <td className="nominal">{ commaFormatted(total_approval) }</td>
                  )
                } */
                }
              </tr>
            </tbody>
            {/* </div> */}
     
          </table>
          <div />
         
        {this.renderButtonTtd()}
          
        
            
          <br/>
         
          <div className="workflow_history">
            <h2>Workflow</h2>
            {this._renderWorkflowHistory(workflow)}
          </div>
          {/* <div className="buttons-wrapper">
            {this._renderButtons()}

           
          </div> */}
        </form>
        
      </div>
    );
  }
}
ManageMonthlyActivityPlan.propTypes = {
  onChange: PropTypes.func,
  handleGetTemaOptions : PropTypes.func,
  handleGetAvailableBudgetDetailsRKB: PropTypes.func,
  handleGetAvailableBudgetDetailsRKBTema: PropTypes.func,
  handleGetBudgetOptions: PropTypes.func,
  handleGetPeriodeOptions: PropTypes.func,
  handleGetFundRequestAvailableCoa: PropTypes.func,
  handleAddFundRequest: PropTypes.func,
  handleGetFundRequestById: PropTypes.func,
  handleSubmitApproval: PropTypes.func,
  handleReject: PropTypes.func,
  handleGetSchoolUnitOptions: PropTypes.func,
  handleGetFundRequestPeriodeOptions: PropTypes.func,
  handleDeleteFundRequestDetail: PropTypes.func,
  handleGetMonthOptions: PropTypes.func,
  handleGetCoaOptions: PropTypes.func,
  handleMonthlyActivityPlantCoa:PropTypes.func,
  handleMonthlyActivityPlantTema:PropTypes.func,
  onSearchUnit: PropTypes.func,
  setWorkingUnit: PropTypes.func,
  onSearchTema: PropTypes.object,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  options: PropTypes.object,
  month: PropTypes.array,
  coa: PropTypes.array,
  coa2: PropTypes.array,
  tema: PropTypes.array,

  user: PropTypes.object,
};

ManageMonthlyActivityPlan.defaultProps = {
  handleGetCoaOptions: noop,
  handleGetAvailableBudgetDetailsRKB: noop,
  handleGetAvailableBudgetDetailsRKBTema: noop,
  handleGetBudgetOptions: noop,
  handleGetPeriodeOptions: noop,
  handleGetFundRequestAvailableCoa: noop,
  handleAddFundRequest: noop,
  handleGetFundRequestById: noop,
  handleSubmitApproval: noop,
  handleReject: noop,
  handleGetFundRequestPeriodeOptions: noop,
  handleDeleteFundRequestDetail: noop,
  handleGetMonthOptions: noop,
  onChange: noop,
  handleGetTemaOptions : noop,
  onSearchTema: noop,
  onSearchUnit: noop,
  setWorkingUnit: noop,
  handleGetSchoolUnitOptions: noop,
  handleMonthlyActivityPlantCoa:noop,
  handleMonthlyActivityPlantTema:noop,
  options: {},
  month: [],
  coa:[],
  coa2:[],
  tema:[],
  user: {},
};

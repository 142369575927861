import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop, map, isEqual} from 'lodash';
import StudentReportFilter from '../../../components/Filter/StudentReport.filter.component';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { BIDANG_STUDY} from '../../../constants/student.constant';
import { BIDANG_STUDY_TABLE } from '../../../constants/student.constant';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted, normalizeAmount, formatData } from '../../../utils/transformer.util';
import { Button, Select, Input, Pagination } from '../../../components/base/index';
import IsiPerBidangForm from '../components/IsiPerBidangForm.component';
import IsiPerBidangPage1 from '../components/IsiPerBidangPage1.component';
import IsiPerBidangTable from '../components/IsiPerBidangTable.component';
import language from '../../../languages';
import { Link } from 'react-router-dom';
import { TAMBAH_ABSEN_PERBIDANG_STUDI_TABLE_FIELDS} from '../../../constants/Student/student.constant';

const padStr = (num) => {
  
  if (num !== "" && num !== null && typeof num !== 'undefined') {
    return num.toString().padStart(2, '0');
    
  }
  
  return null;
}

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};


export default class IsiPerbidangStudy extends Component {
        constructor(props) {
          super(props);
          this._onFormChange = this._onFormChange.bind(this);
          this._onClickNext = this._onClickNext.bind(this);
          this._setForm = this._setForm.bind(this);
          this._onClickAdd = this._onClickAdd.bind(this);
          this.onClickAdd = this.onClickAdd.bind(this);
          this.onClickEdit = this.onClickEdit.bind(this);
          this._handleFormChange = this._handleFormChange.bind(this);
          this._onSearch = this._onSearch.bind(this);
          this._onSearchContent = this._onSearchContent.bind(this);
          this._onChangeListAmount = this._onChangeListAmount.bind(this);
          this._onChangePage = this._onChangePage.bind(this);
          this._onChangeFilter = this._onChangeFilter.bind(this);
          this._getStudentOptions = this._getStudentOptions.bind(this);
          this._getParamOptions = this._getParamOptions.bind(this);
          this._getSubjectOptions= this._getSubjectOptions.bind(this);
          this._renderButtons = this._renderButtons.bind(this);
          this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
          this._getPeriodsOptions= this._getPeriodsOptions.bind(this);
          this._getClassesOptions= this._getClassesOptions.bind(this);

        this.state = {
          page: 1,
          content: initialContent,
          unchecked: {},
          filters:{},
          list:[],
          form: {
            value: {
              absen_bidangstudy:[],
              classrooms: [],
            },
            error: {
              absen_bidangstudy: '',
              classrooms: '',              
            },
          },
          filtered: false,
          param:'',
        };
      }

        onClickAdd() {
          const { history } = this.props;
          history.push('/dashboard/kesiswaan/absensi-siswa/tambah-absen-perbidang-study', { isEdit: true });
        }

        componentDidMount() {
          const {location, handleGetclassrooms, user } = this.props;
          const {school_unit} = user;
          const { units_id, levels_id } = school_unit;
          const { content } = this.state;
          const { state = {} } = location;
          const {isEdit =  false, data} = state;
          const { id } = state.data || '';
          const paramTypes = ['classes','levels', 'classrooms'];
          paramTypes.forEach((type) => {
            this._getParamOptionsAcademics(type);
          });
          if (isEdit && id) {
            this.setState({id:id})
            this._setForm(data.id);
          } else
          this._getStudentOptions();
          if (isEmpty(content.list)) {
            this._onSearchContent({ page: 1 });
          }
          this._getSubjectOptions({ filters: { units_id: [units_id] }});      
          this._getClassesOptions({ filters: { levels_id: [levels_id] }});
          this._getPeriodsOptions();       

        }

        componentDidUpdate(prevProps) {
          const { location } = this.props;
          const { state = {} } = location;
          const {isEdit =  false, data} = state;
          const { id } = state.data || '';
          // const { user } = this.props;
          // const { workingUnit } = user;
          // const { user: prevUser } = prevProps;
          // const { workingUnit: prevWorkingUnit } = prevUser;
      
          // if (!isEqual(workingUnit, prevWorkingUnit)) {
          //   this.handleGetData();
          // }
          if ((!isEdit || !id) && !isEqual(prevProps, this.props)) {
            this._onSearchContent({ page: 1 });
          }
        }

        async _getClassesOptions(filters={}) {
          const { getClassesOptions, user } = this.props;
          const {school_unit} = user;
          const { levels_id } = school_unit;
          filters.levels_id = levels_id;

          const res = await getClassesOptions(filters);
          // console.log(res)
          this.setState(prevState => ({
            ...prevState,
            param: {
              ...prevState.param,
              classes: res,
            },
          }));
         
        }

        async _getPeriodsOptions(filters={}) {
          const { getPeriodsOptions ,user} = this.props;
          const { organizations_id, school_unit } = user;
          const { units_id, levels_id } = school_unit
          const res = await getPeriodsOptions(filters);
          filters.organizations_id = organizations_id;

          this.setState(prevState => ({
            ...prevState,
            param: {
              ...prevState.param,
              periods: res,
            },
          }));
          
        }



        // on formchange untuk form
        _handleFormChange(event) {
            const {
              name,
              value,
            } = event.target;
            this.setState(prevState => ({
              form: {
                value: {
                  ...prevState.form.value,
                  [name]: value,
                },
                error: {
                  ...prevState.form.error,
                  [name]: '',
                },
              },
            }));
          }
        
          _onSearch() {
            const { form } = this.state;
            const error = validateRequiredFields(form.value, ['year']);
            this.setState(prevState => ({
              form: {
                value: prevState.form.value,
                error,
              },
            }));
            if (isEmpty(error)) {
              this.setState({ filtered: true });
            }
          }

          async _getParamOptionsAcademics(type, filters={}) {
            const { getParamOptionsAcademics, user } = this.props;
            const { organizations_id, school_unit } = user;
            const { levels_id } = school_unit;
  
            if (type === 'classrooms') {
              filters.organizations_id = organizations_id;
              filters.levels_id = levels_id;

            } else {
              delete filters.organizations_id;
            }
            const res = await getParamOptionsAcademics(filters, type);
            this.setState(prevState => ({
              ...prevState,
              param: {
                ...prevState.param,
                [type]: res,
              },
            }));
          }
        _onFormChange(event) {
          const { user } = this.props;
          const { organizations_id } = user;
          const {
            name,
            value,
            dataset,
            checked,
            type,
          } = event.target;
          const {
            inputType = 'text', inputArray = false, arrayPosition = 0,
            fieldName,
          } = dataset;
          this.setState((prevState) => {
            let newList = [];
            let newListError = [];
            let formattedValue = value;
      
            if (inputType === 'number') {
              formattedValue = normalizeAmount(value);
            }
            if (inputArray) {
              if (type === 'checkbox') {
                formattedValue = checked;
              }
              newList = prevState.form.value[fieldName];
              newListError = prevState.form.error[fieldName];
              newList[arrayPosition][name] = formattedValue;
              if (name === 'code_of_account') {
                newList[arrayPosition].isCredit = value.type;
              }
              if (!isEmpty(newListError[arrayPosition])) {
                newListError[arrayPosition][name] = '';
              }
            }
            return {
              form: {
                value: {
                  ...prevState.form.value,
                  ...(inputArray
                    ? { [fieldName]: newList }
                    : { [name]: formattedValue }),
                },
                error: {
                  ...prevState.form.error,
                  ...(inputArray
                    ? { [fieldName]: newListError }
                    : { [name]: '' }),
                },
              },
            };
          },() => {
            if (name === 'classes_id') {
              this._getParamOptionsAcademics( 'classrooms', {classes_id: value, organizations_id } );
              this._getSubjectOptions({ filters: {classes_id: value} } );
            
            }
            
          });
        }

        _onChangeListAmount(event) {
          const { keywords } = this.state;
          this.setState({
            listAmount: Number(event.target.value),
          });
          this._onSearchContent({ limit: event.target.value, keywords, page: 1 });
        }

        _onChangePage(page) {
          const { listAmount, keywords } = this.state;
          const offset = listAmount * (page - 1);
          // this.handleGetData({
          this._onSearchContent({
            limit: listAmount,
            keywords,
            offset,
            page,
          });
        }

        _onChangeFilter(e) {
          const { target } = e;
          const { value, name } = target;
          this.setState(prevState => ({
            ...prevState,
            filters: {
              ...prevState.filters,
              [name]: value,
            },
          // },()=>{
          //   if (name === 'classes_id') {
          //     this._getSubjectOptions({ filters: {classes_id: value} } );
          //   }
          //   this._onSearchContent()
          // })
          // );
        }), () => this._onSearchContent());
        }
      
        _onClickAdd(data) {
          const { form } = this.state;
          const { plafon } = form.value;
          
          plafon.push({
            tipePlafon: '',
            // divisi: '',
            nominal: 0,
            //period:'',
          });
          
          this.setState(prevState => ({
            form: {
              value: {
                ...prevState.form.value,
                plafon,
              },
              error: {
                ...prevState.form.error,
              }
            }
          }));
          console.log(plafon)
        }

        async _getParamOptions(type, filters={}) {
          const {getParamOptions, user} = this.props;
          const { organizations_id } = user;

          if (type === 'classes' || type === 'classrooms') {
            filters.organizations_id = organizations_id;
          } else {
            delete filters.organizations_id;
          }
          const res = await getParamOptions(filters, type);
          this.setState(prevState => ({
            ...prevState,
            param: {
              ...prevState.param,
              [type]: res,
            },
          }));
        }

        async _getSubjectOptions(filters={}) {
          const { getSubjectOptions, user } = this.props;
          const { organizations_id, school_unit } = user;
          const { levels_id, units_id } = school_unit;
          const res = await getSubjectOptions(filters);
          filters.units_id = units_id;
          // console.log(res)
          this.setState(prevState => ({
            ...prevState,
            param: {
              ...prevState.param,
              subject: res,
            },
          }));
         
        }

        async _getStudentOptions(filters={}) {
          const { getStudentOptions } = this.props;
          const res = await getStudentOptions(filters);
          // console.log(res)
          this.setState(prevState => ({
            ...prevState,
            param: {
              ...prevState.param,
              student: res,
            },
          }));
          
        }

        async _setForm(id) {
          const { handleGetAttendance } = this.props;
          try {
            const payload = await handleGetAttendance({ id });
         
        console.log(payload)
            this.setState({
              filters: {
                classrooms_id: payload.classrooms_id,
                period: payload.period,
                date: payload.date,
              },
              form: {
                value: {
                  id: payload.id,
                  date: payload.date,
                  period: payload.period,
                  absen_bidangstudy: map(payload.absen_bidangstudy, detail => ({
                    classrooms_id: detail.classrooms_id,
                    name: detail.name,
                    date: detail.date,
                    students_id: detail.students_id,
                    status: detail.status,
                    description: detail.description,
                   
                  })),
                },
                error: {
                  absen_bidangstudy: [],
                },
              },
            });
          } catch (err) {
            // err action
          }
        }
      
        async _onSearchContent(params = {}) {
          const { filters } = this.state;
          this.setState({
            content: initialContent,
          }, async () => {
            try {
              const { handleListStudyField, user } = this.props;
              const { school_unit, workingUnit, id, user_group, organizations_id } = user;
              const { levels_id, units_id } = school_unit;

              const result = await handleListStudyField({ ...params, organizations_id, units_id, filters });
              // if (this.isMount) {
                this.setState(prevState => ({
                  ...prevState,
                  form: {
                    ...prevState.form,
                    value: {
                      ...prevState.form.value,
                      absen_bidangstudy: result,
                    }
                  }
                }));
                console.log(result)
              // }
            } catch (err) {
              // if (this.isMount) {
                this.setState(prevState => ({
                  ...prevState,
                  form: {
                    ...prevState.form,
                    value: {
                      ...prevState.form.value,
                      absen_bidangstudy: [],
                    }
                  }
                }));
              }
            // }
          });
        }

        // on formchange untuk tabel
        onFormChange(event) {
          const {
            name,
            value,
            dataset,
          } = event.target;
          console.log(name)
          const { inputArray = false, arrayPosition = 0, inputType = 'text' } = dataset;
          this.setState((prevState) => {
            let formattedValue = value;
            let newPlafon = [];
            if (inputType === 'number') {
              formattedValue = normalizeAmount(value);
            }
      
            if (inputArray) {
              newPlafon = prevState.form.value.plafon;
              newPlafon[arrayPosition][name] = value;
            }
            return {
              form: {
                value: {
                  ...prevState.form.value,
                  ...(inputArray ? 
                    { plafon: newPlafon } : 
                    { [name]: formattedValue }
                  )
                },
                error: {
                  ...prevState.form.error,
                  form:'',
                  [name]: '',
                },
              },
            };
          });
        }
      
        onClickEdit(val) {
          const { history } = this.props;
          history.push('/dashboard/kesiswaan/absensi-siswa/tambah-absen-perbidang-study', { date: val.date, subject_id: val.subject_id, classrooms_id: val.classrooms_id, isEdit: true });
        }

      
        _renderButtons(data) {
          const { user } = this.props;
          const { user_group } = user;
          const { permissions = [] } = user_group;
        
          return (
            <td>
              <div className="table__actions">
              
               
                   <Button
                   onClick={() => this.onClickEdit(data)}
                   title='Edit'
                 />
                   
                 {/* </div> */}
              {/* } */}
                 
        
               
              </div>
            </td>
          );
        }
        
        _onClickNext() {
            window.scrollTo(0, 0);
            this.setState(prevState => ({
              page: prevState.page + 1,
            }));
          }
      
        render() {
          const { form, page, content, param, filters} = this.state;
          const {plafon, absen_bidangstudy} = form.value;
          const { classrooms = {} } = this.props;
          const {
            classrooms_id = '',
            date='',
            period='',
            subject_id='',
          } = filters;
          const dataset = {
            toggle: "modal"
          };
          const academic_year = [
            { label: '2019', value: '2019'},
            { label: '2020', value: '2020'},
            { label: '2021', value: '2021'},
          ]
          const periods = [
            { label: 'Genap', value: 'genap'},
            { label: 'Ganjil', value: 'ganjil'},
          ]
          return (
       
        <div className="manage-registration">
          <div className="beginning-balance__button-wrapper">
         <Button
          title="Tambah Absen Perbidang Study"
          onClick={this.onClickAdd}
          data={dataset}
        />
        </div>
        <br/>
          <div className="absensi-rekap__custom-form">
            <div className="absensi-rekap__custom-form-row">
              <div className="absensi-rekap__custom-form-row__field">
              <Select
                  name="classes_id"
                  label="Tingkat Kelas"
                  onChange={this._onFormChange}
                  data={param.classes}
                  placeholder={'Pilihan'}
                  value={form.value.classes_id}
                  error={form.error.classes_id || ''}
                />
                </div>
              <div className="absensi-rekap__custom-form-column__field">
                <Select
                  name="classrooms_id"
                  label="Kelas"
                  onChange={this._onChangeFilter}
                  data={ param.classrooms}
                  placeholder={'Pilihan'}
                  value={classrooms_id}
                />
              </div>
          </div>
              
          <div className="absensi-rekap__custom-form-row">
              <div className="absensi-rekap__custom-form-row__field">   
              <Select
                  label="Tahun Ajaran"
                  name="period"
                  onChange={this._onChangeFilter}
                  placeholder="Pilihan"
                  data={param.periods}
                  value={period}
                />
               
              </div>  
             
              <div className="absensi-rekap__custom-form-column__field">
                <Input
                  type='date'
                  name="date"
                  label="Tanggal"
                  onChange={this._onChangeFilter}
                  value={date}
                />      
              </div>
            </div>
            <div className="absensi-rekap__custom-form-row">
              <div className="absensi-rekap__custom-form-row__field">   
              <Select
                  label="Mata Pelajaran"
                  name="subject_id"
                  placeholder="Pilihan"
                  data={param.subject}
                  onChange={this._onChangeFilter}
                  value={subject_id}
                />
               
              </div>  
             
              <div className="absensi-rekap__custom-form-column__field">
                 
              </div>
            </div>
              
        
              </div>
              <br/>

              <div className="manage-registration">
                <div className="fund-request__content">
                  <table className="table">
                    <thead>
                      <tr>
                        {
                            map(TAMBAH_ABSEN_PERBIDANG_STUDI_TABLE_FIELDS.label, (field, idx) => (
                              <th key={`table_th_${idx}`}>{field}</th>
                            ))
                          }
                      </tr>
                    </thead>
                    <tbody>
                      {
                          map(absen_bidangstudy, (list, idx) => (
                            <tr key={`budget_row_${idx}`}>
                              {
                              map(TAMBAH_ABSEN_PERBIDANG_STUDI_TABLE_FIELDS.value, (field, fieldIdx) => {
                                if (fieldIdx === 0) {
                                  return (
                                    <td key={`table_index_${fieldIdx}_${idx}`}>{idx + 1}</td>
                                  );
                                }
                                if (field.type === 'link') {
                                  return (
                                    <td key={`table_${fieldIdx}_${idx}`}><Link to={'/dashboard/kesiswaan/ekstrakulikuler/pembagian-kelas-ekskul'}>{list.name}</Link></td>
                                      // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                                  );
                                }
                                return (
                                  <td className={(field.type === 'number') ? 'nominal' : ''} key={`table_${fieldIdx}_${idx}`}>
                                    {formatData(list, field)}
                                  </td>
                                );
                              })
                              }
                              {this._renderButtons(list)}
                            </tr>
                          ))
                        }
                    </tbody>
                  </table>
                </div>
              </div>
              {/* <div className="user-management__table-footer">
                <p className="user-management__page-info">
                  {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${content.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${content.totalPage}`}
                </p>
                <Pagination
                  totalPage={content.totalPage}
                  currentPage={content.currentPage}
                  onClick={this._onChangePage}
                />
              </div> */}
           
        </div>

          );
        }
}
IsiPerbidangStudy.propTypes = {
    
  handleGetclassrooms: PropTypes.func,
  history: PropTypes.object.isRequired,
};
IsiPerbidangStudy.defaultProps = {
    
  handleGetclassrooms: noop,
};
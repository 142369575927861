import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop, map, isEqual, has, find } from 'lodash';
import StudentReportFilter from '../../../components/Filter/StudentReport.filter.component';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { ABSENSI_HARIAN } from '../../../constants/student.constant';
import { ABSENSI_HARIAN_TABLE } from '../../../constants/student.constant';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted, normalizeAmount, formatData } from '../../../utils/transformer.util';
import { Button, Input, Checkbox, Pagination, Select } from '../../../components/base/index';
import language from '../../../languages';
import { UPLOAD_RPP_LIST_TABLE_FIELDS } from '../../../constants/Student/student.constant';
import { PENGISIAN_PSIK__AFEK_LIST_TABLE_FIELDS } from '../../../constants/Student/student.constant';
import { Link } from 'react-router-dom';
import PengisianKDTable from './components/PengisianKDTable.component';


const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class PengisianKD extends Component {
  constructor(props) {
    super(props);
    // this.onEdit = this.onEdit.bind(this);
    this._onClickUpload = this._onClickUpload.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._onUpload = this._onUpload.bind(this);
    // this.onSubmit = this.onSubmit.bind(this);
    this._getSubjectOptions= this._getSubjectOptions.bind(this);
    this._getRppOptions= this._getRppOptions.bind(this);
    this._getPeriodsOptions= this._getPeriodsOptions.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._onClickAdd = this._onClickAdd.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    // this.onClickAddFolder = this.onClickAddFolder.bind(this);
    this.handleGetData = this.handleGetData.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this._onCheckDetail = this._onCheckDetail.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onChangeListAmount = this._onChangeListAmount.bind(this);
    this._setForm = this._setForm.bind(this);
    this._renderButtons = this._renderButtons.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this._getClassesOptions= this._getClassesOptions.bind(this);
    
    const { user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;
    this.state = {
      page: 1,
      selected: {},
      indicator: {},
      classrooms: {},
      content: initialContent,
      unchecked: {},
      filters:{},
      list:[],
      form: {
        value: {
          levels_id,
          // indicator:[],
          kd_list: [
            // {
            //   bentuk_penilaian:'',
            //   date_a:'',
            //   date_b:'',
            //   date_c:'',
            // }
          ],
          // description: '',
          organizations_id,
          nilaikognitif: [
            {
              id:'',
              score_students:'',
              students_id: '',

            }
          ],
        },
        error: {
          kd_list: '',
          classes: '',
          levels: '',
          nilaikognitif:''
        },
      },
      param:{},
      filtered: false,

    };
    this._handleFormChange = this._handleFormChange.bind(this);
    this._onSearch = this._onSearch.bind(this);
    this._getParamOptions = this._getParamOptions.bind(this);
  }

  componentDidMount() {
  const { location, getLevels, getClasses, user } = this.props;
  const { school_unit } = user;
  const { levels_id } = school_unit;
  const { state = {} } = location;
  const {isEdit =  false, data} = state;
  const { id } = state.data || '';
    const { content } = this.state;
    const paramTypes = ['classes','levels', 'classrooms'];

    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
    if (isEdit && id) {
      this.setState({id:id})
      this._setForm(data.id);
    } else
    if (isEmpty(content.list)) {
      this._onSearchContent({ page: 1 });
    }
    this._getClassesOptions({ filters: { levels_id: [levels_id] }});
    this._getSubjectOptions();       
    this._getRppOptions();       
    this._getPeriodsOptions();       
  
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { state = {} } = location;
    const {isEdit =  false, data} = state;
    const { id } = state.data || '';
    if (!isEqual(prevProps, this.props)) {
      this._onSearchContent({ page: 1 });
    }
  }

  async _getClassesOptions(filters={}) {
    const { getClassesOptions, user } = this.props;
    const { school_unit } = user;
    const { levels_id } = school_unit;
    filters.levels_id = levels_id;
    const res = await getClassesOptions(filters);
    // console.log(res)
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        classes: res,
      },
    }));
    
  }

  async _setForm(id) {
    const { handleGetNilaiKognitif, user } = this.props;
    const { organizations_id } = user;
    try {
      const payload = await handleGetNilaiKognitif({ id });
      const { classes, levels_id, classes_id } = payload;
      this._getClassesOptions({ filters: { levels_id: [levels_id] }} );
      this._getParamOptionsAcademics( 'classrooms',  { classes_id: classes_id, organizations_id } );            
      this.setState({
        filters: {
          classrooms_id: payload.classrooms_id,
          period: payload.period,
          date: payload.date,
        },
        form: {
          value: {
            // indicator: payload.indicator,
            // description: data.description,
            
            // nilaikognitif: map(payload.nilaikognitif, detail => ({
            //   id: detail.id,
            //   score_students: detail.score_students,
            //   students_id: detail.students_id,
              
            // })),
            kd_list: map(payload.nilaikognitif, detail => ({
              id: detail.id,
              score_students: detail.score_students,
              students_id: detail.students_id,
              bentuk_penilaian:detail.bentuk_penilaian,
              date_a:detail.date_a,
              date_b:detail.date_b,
              date_c:detail.date_c,
              
            })),
          },
          error: {
            nilaikognitif: [],
            kd_list: [],
          },
        },
      });
    } catch (err) {
    }
  }

  async _onSearchContent(params = {}) {

    const { filters } = this.state;
    
      try {
    console.log("res")

        const { handleListStudentPsikAfek, handleListIndicatorPsikAfek, user } = this.props;
        const { organizations_id, school_unit } = user;
        const { units_id } = school_unit;
        const result = await handleListStudentPsikAfek({ ...params, organizations_id, units_id, filters });
        const res = await handleListIndicatorPsikAfek({...params, filters});
        console.log(res)
          if (result) {
            result.forEach(item => {
              if (!item.remedial) {
                item.remedial = '';
              }
              if (!item.score) {
                item.score = '';
              }
            });
          } 
          this.setState(prevState => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                details: result,
                
              }
            },
            indicator: res,
          }));
          
      } catch (err) {
          this.setState(prevState => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                details: [],
              }
            },
            indicator:[],
            
          }));
      }
  }


  onClickEdit(val) {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/ulangan/tambah-penilaian-nilai-kognitif', { data: val, isEdit: true });
  }

  onClickDelete(idx) {
    this.setState((prevState) => {
      const { nilaikognitif } = prevState.form.value;
      const list = prevState.form.value.nilaikognitif;
      list.splice(idx, 1);
      return {
        ...prevState,
        nilaikognitif: list
      };
    });
  }

  _onChangeListAmount(event) {
    const { keywords } = this.state;
    this.setState({
      listAmount: Number(event.target.value),
    });
    this._onSearchContent({ limit: event.target.value, keywords, page: 1 });
  }



  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    this.setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        [name]: value,
      },
    }), () => this._onSearchContent());
  }

  async _getSubjectOptions() {
    const { getSubjectOptions } = this.props;
    const res = await getSubjectOptions();
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        subject: res,
      },
    }));
    
  }

  async _getRppOptions() {
    const { getRppOptions } = this.props;
    const res = await getRppOptions();
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        rpp_types: res,
      },
    }));
    
  }

  async _getPeriodsOptions() {
    const { getPeriodsOptions } = this.props;
    const res = await getPeriodsOptions();
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
    
  }

  async handleGetData(payload = {}) {
    const { handleListIndicatorPsikAfek,  user } = this.props;
    const { form } = this.state;
    const { units_id, workingUnit, id, user_group, profile } = user;
    let unit_id = units_id;

    if (unit_id === null && workingUnit && workingUnit.id) {
      unit_id = workingUnit.id;
    }
      const res = await handleListIndicatorPsikAfek({payload});

      this.setState(prevState => ({ 
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value, 
          }
        },
        indicator: res,
      }));

  }
  _handleFormChange(event) {
      const {
        name,
        value,
      } = event.target;
      this.setState(prevState => ({
        form: {
          value: {
            ...prevState.form.value,
            [name]: value,
          },
          error: {
            ...prevState.form.error,
            [name]: '',
          },
        },
      }));
    }
  
    _onSearch() {
      const { form } = this.state;
      const error = validateRequiredFields(form.value, ['year']);
      this.setState(prevState => ({
        form: {
          value: prevState.form.value,
          error,
        },
      }));
      if (isEmpty(error)) {
        this.setState({ filtered: true });
      }
    }


    _onFormChange(event) {
      const {
        name,
        value,
        dataset,
        checked,
        type,
        files
      } = event.target;
      const {
        inputType = 'text', inputArray = false, arrayPosition = 0,
        fieldName,
      } = dataset;
      const { user } = this.props;
      const { organizations_id } = user;
  
      this.setState((prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;          
        if(type==="file"){
          formattedValue = files[0]
        }
        if (inputType === 'number') {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          if (type === 'checkbox') {
            formattedValue = checked ? value : '';
          }
          newList = prevState.form.value[fieldName];
          newListError = prevState.form.error[fieldName];
          newList[arrayPosition][name] = formattedValue;
          if (name === 'code_of_account') {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = '';
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray
                ? { [fieldName]: newListError }
                : { [name]: '' }),
            },
          },
        };
      }, () => {
        if (name === 'levels_id') {
          this._getClassesOptions({ filters: { levels_id: [value] }} );
        }
        // if (name === 'levels') {
        //   this._getParamOptionsAcademics( 'classrooms', { levels_id: value, organizations_id: 4 } );
        // }
        if (name === 'classes_id') {
          this._getParamOptionsAcademics( 'classrooms', {classes_id: value, organizations_id } );
        }
        if (name === 'classrooms') {
          this._getStudentOptions({ filters: { classrooms_id: value.map(item => item.value) }} );
        }
      });
    }

    
  
  _renderButtons(data) {
    const { user } = this.props;
    const {user_group} = user;
    const button = {};
    
    return (
      <td>
        <div className="table__actions">
        
              <Button
              onClick={() => this.onClickEdit(data)}
              title='Edit'
            />
        </div>
      </td>
    );
  }

  _onClickUpload() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/absensi-siswa/upload-absensi-harian');
  }

  _onClickAdd(data) {
    const { form } = this.state;
    // const { plafon } = form.value;
    const { kd_list } = form.value;
    
    // plafon.push({
    //   tipePlafon: '',
    //   nominal: 0,
    // });
    kd_list.push({
      bentuk_penilaian: '',
      date_a: '',
      date_b: '',
      date_c: '',
    });
    
    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          kd_list,
        },
        error: {
          ...prevState.form.error,
        }
      }
    }));
  }

  _onDeleteList(idx) {
    this.setState((prevState) => {
        const { kd_list } = prevState.form.value;
        const list = prevState.form.value.kd_list;

        list.splice(idx, 1);
        return {
            ...prevState,
            kd_list: list
        };
    });
  }

  onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this.handleGetData({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  _onUpload() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/rencana-penilaian/upload-document');
  }
  _onSubmit() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/rencana-penilaian/upload');
  }

  // onClickAddFolder() {
  //   const { history } = this.props;
  //   history.push('/dashboard/kurikulum/ulangan/tambah-penilaian-nilai-kognitif', { isEdit: true });
  // }

  _onCheckDetail(event) {
    const { target } = event;
    const { value, checked } = target;
    const { budgets, selectedBudgetDetails } = this.props;
    const { selected, unchecked } = this.state;
    let newState = { ...this.state };

    newState = this._setNewState(newState, checked, value);

    this.setState({
      ...newState
    });
  }

  // async onSubmit() {
  //   const { history, handleSaveAttendance } = this.props;
  //   const { form } = this.state;
  //   const { value } = form;        
  //   let canSaveA = false;
  //   const dataForm = form.value;
  //     await handleSaveAttendance(value);
  //     history.push('/dashboard/kesiswaan/absensi-siswa/absensi-rekap');
  // }

  
  _onClickNext() {
      window.scrollTo(0, 0);
      this.setState(prevState => ({
        page: prevState.page + 1,
      }));
    }
  async _getParamOptions(type, filters={}) {
    const {getParamOptions, user } = this.props;
    const { organizations_id } = user;
    if (type === 'classes' || type === 'classrooms') {
      filters.organizations_id = organizations_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptions(filters, type);
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getParamOptionsAcademics(type, filters={}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id } = user;
    if (type === 'classrooms' || type === 'classrooms') {
      filters.organizations_id = organizations_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  render() {
    const {param, form, indicator,
            page, list = {}, selected, checkedAll, content, filters, listAmount } = this.state;
    const {absensiharian} = form.value;
    const componentRef = React.createRef();
    const {
      classrooms_id = '',
      Semester = '',
      Menu = '',
      subject_id='',
      // kompetisi_tema='',
    } = filters;
    const {  location } = this.props;
    const loadingProps = { show: content.loading };
    const academic_year = [
      { label: '2019', value: '2019'},
      { label: '2020', value: '2020'},
      { label: '2021', value: '2021'},
    ]
    const semester = [
      { label: 'Ganjil', value: 'ganjil'},
      { label: 'Genap', value: 'genap'},
    ]
    const menu = [
      { label: 'Nilai Kognitif', value: '1'},
      { label: 'Nilai PSIK & AFEK', value: '2'},
    ]
    const kompetisi_tema = [
      { label: 'Tema 1', value: '1'},
      { label: 'Tema 2', value: '2'},
      { label: 'Tema 3', value: '3'},
      { label: 'Tema 4', value: '4'},
      { label: 'Tema 5', value: '5'},
      { label: 'Tema 6', value: '6'},
      { label: 'Tema 7', value: '7'},
      { label: 'Tema 8', value: '8'},
      { label: 'Tema 9', value: '9'},
      { label: 'Tema 10', value: '10'},
      { label: 'Tema 11', value: '11'},
      { label: 'Tema 12', value: '12'},
      { label: 'Tema 13', value: '13'},
      { label: 'Tema 14', value: '14'},
      { label: 'Tema 15', value: '15'},
      { label: 'Tema 16', value: '16'},
      { label: 'Tema 17', value: '17'},
      { label: 'Tema 18', value: '18'},
      { label: 'Tema 19', value: '19'},
      { label: 'Tema 20', value: '20'},
    ]
    const { kd_list } = form.value;
    console.log(indicator)
    console.log(form.value.menu)
    return (
      <div className="student-list">

        
        <br></br>
        <br></br>
        <div className="nilai-kognitif__custom-form">
          <div className="nilai-kognitif__custom-form-row">
            <div className="nilai-kognitif__custom-form-row__field">
              <Select
                label="Tahun Ajaran"
                name="periods"
                onChange={this._onFormChange}
                placeholder="Pilihan"
                data={param.periods}
                value={form.value.periods}
                error={form.error.periods || ''}
              />
            </div>
            <div className="nilai-kognitif__custom-form-column__field">
              <Select
                name="Semester"
                label="Semester"
                onChange={this._onChangeFilter}
                value={Semester}
                data={semester}
                placeholder={'Pilihan'}
              />
            </div>
          </div>
          
          <div className="nilai-kognitif__custom-form-row">
            <div className="nilai-kognitif__custom-form-column__field">
              <Select
                name="levels_id"
                label="Unit"
                disabled
                onChange={this._onFormChange}
                data={param.levels}
                value={form.value.levels_id}
                placeholder={'Pilihan'}
                error={form.error.levels_id || ''}
              />
            </div>
            <div className="nilai-kognitif__custom-form-column__field">
              <Select
                name="classes_id"
                label="Tingkat Kelas"
                onChange={this._onFormChange}
                data={param.classes}
                placeholder={'Pilihan'}
                value={form.value.classes_id}
                error={form.error.classes_id || ''}
              />
            
            </div>
          </div>

          <div className="nilai-kognitif__custom-form-row">
            <div className="nilai-kognitif__custom-form-column__field">
              <Select
                name="subject_id"
                label="Mata Pelajaran"
                onChange={this._onChangeFilter}
                data={param.subject}
                placeholder="Pilih Matapelajaran"
                value={subject_id}
                error={form.error.subject_id || ''}
              />
            
            </div>
            <div className="nilai-kognitif__custom-form-column__field">
              <div className="nilai-kognitif__custom-form-row__field">
                <Select
                  name="rpp_types"
                  label="Jenis"
                  onChange={this._onFormChange}
                  data={param.rpp_types}
                  placeholder="Pilih Jenis"
                  value={form.value.rpp_types}
                  error={form.error.rpp_types || ''}
                />              
              </div>
            
            </div>
          </div>

          <div className="nilai-kognitif__custom-form-row">
            <div className="nilai-kognitif__custom-form-column__field">
              <div className="nilai-kognitif__custom-form-row__field">
                <Input
                  // disabled={!isEdit}
                  name="tujuan_pembelajaran"
                  label="Tujuan Pembelajaran"
                  onChange={this._onFormChange}
                  value={form.value.tujuan_pembelajaran}
                  error={form.error.tujuan_pembelajaran || ''}
                  style={{backgroundColor: 'yellow'}}
                />
              
              </div>
            
            </div>
          
            <div className="nilai-kognitif__custom-form-column__field">
                          
            </div>
          
          </div>

          
          <div className="nilai-kognitif__custom-form-row">
        
          </div>
        </div>
        
        <br></br>
        <br></br>
        <div className="budget__title">
            <center><h1>PENGISIAN KOMPETISI DASAR KD / TEMA</h1></center>
        </div>

        <br></br>
        <div className="nilai-kognitif__custom-form">
          
          <div className="nilai-kognitif__custom-form-row">
            <div className="nilai-kognitif__custom-form-column__field">
              <Select
                name="kompetisi_tema"
                label="Kompetisi Dasar DK/TEMA ke"
                onChange={this._onChangeFilter}
                value={kompetisi_tema}
                data={kompetisi_tema}
                placeholder={'Pilih Tema'}
              />
            </div>
            <div className="nilai-kognitif__custom-form-column__field">
              <Input
                // disabled={!isEdit}
                name="materi"
                label="Materi KD/TEMA"
                onChange={this._onFormChange}
                value={form.value.materi}
                error={form.error.materi || ''}
                style={{backgroundColor: 'yellow'}}
              /> 
            
            </div>
          </div>

          <div className="nilai-kognitif__custom-form-row">
            <div className="nilai-kognitif__custom-form-column__field">
              <Input
                // disabled={!isEdit}
                name="kkm"
                label="KKM KD"
                onChange={this._onFormChange}
                value={form.value.kkm}
                error={form.error.kkm || ''}
              />     
            
            </div>
            <div className="nilai-kognitif__custom-form-column__field">
              <div className="nilai-kognitif__custom-form-row__field">
                <Input
                  // disabled={!isEdit}
                  name="indikator"
                  label="Indikator KD/TEMA/Bukti Belajar"
                  onChange={this._onFormChange}
                  value={form.value.indikator}
                  error={form.error.indikator || ''}
                  style={{backgroundColor: 'yellow'}}
                />            
              </div>
            
            </div>
          </div>

          <div className="nilai-kognitif__custom-form-row">
            <div className="nilai-kognitif__custom-form-column__field">
              <div className="nilai-kognitif__custom-form-row__field">
                <Input
                  // disabled={!isEdit}
                  name="bobot"
                  label="Bobot"
                  onChange={this._onFormChange}
                  value={form.value.bobot}
                  error={form.error.bobot || ''}
                />
              
              </div>
            
            </div>
          
            <div className="nilai-kognitif__custom-form-column__field">
                          
            </div>
          
          </div>

          
          <div className="nilai-kognitif__custom-form-row">
        
          </div>
        </div>
        
           
        <div className="user-management__table-footer">
        </div>
        <br></br>
        <br></br>

        <PengisianKDTable
          tema={this.state.tema}
          // list={absensiharian}
          param={param}
          onAddList={this._onClickAdd}
          list={kd_list}
          onDeleteList={this._onDeleteList}
          onFormChange={this._onFormChange}
          // onChange={this._onFormChange}
        />

        <br></br>
        <div className="beginning-balance__button-wrapper">
          <Button
            title="SIMPAN"
            onClick={this._onSubmit}
          />

        </div> 
      </div>
    );
  }
}

PengisianKD.propTypes = { 
  getParamOptions: PropTypes.func,  
  history: PropTypes.object.isRequired,
  handleGetNilaiKognitif: PropTypes.func,
};
PengisianKD.defaultProps = {
  getParamOptions: noop,
  handleGetNilaiKognitif: noop,
};
import { connect } from 'react-redux';
import BigBookReport from '../../../views/Finance/Report/BigBook.report.view';
import { getGeneralLedgerReport } from '../../../states/thunks/report.thunk';
import { getCoa } from '../../../states/thunks/common.thunk';
import { getCoaOptions, getPerwakilanOptions, getUnitOptions } from '../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    coa: state.options.coa,
    perwakilan: state.options.perwakilan,
    user: state.user || {},
    unit: state.options.units,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetPerwakilan: payload => dispatch(getPerwakilanOptions(payload)),
    handleGetUnit: payload => dispatch(getUnitOptions(payload)),
    handleGetCoa: payload => dispatch(getCoaOptions(payload)),
    handleGetGeneralLedgerReport: payload => dispatch(getGeneralLedgerReport(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BigBookReport);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import budgeting from '../../assets/img/finance/anggaran.png';
import bookkeeping from '../../assets/img/bookkeeping/jurnal_bank.png';

export default class DataPerpus extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickTblControlPerpus = this.onClickTblControlPerpus.bind(this);
    this.onClickKlasifikasi = this.onClickKlasifikasi.bind(this);
    this.onClickCatat = this.onClickCatat.bind(this);
    this.onClickUpload = this.onClickUpload.bind(this);
    this.onClickAsalBuku = this.onClickAsalBuku.bind(this);
    this.onClickTop10 = this.onClickTop10.bind(this);
    this.onClickDataEntry = this.onClickDataEntry.bind(this);
    this.onClickLaporanPemasukan =  this.onClickLaporanPemasukan.bind(this);
    this.onClickLaporanPengunjung =  this.onClickLaporanPengunjung.bind(this);
    this.onClickLaporanPeminjam =  this.onClickLaporanPeminjam.bind(this);
    this.onClickPengembalian =  this.onClickPengembalian.bind(this);
    this.onClickPeminjaman =  this.onClickPeminjaman.bind(this);
    this.onClickSearchBuku =  this.onClickSearchBuku.bind(this);
  }

  onClickTblControlPerpus() {
    const { history } = this.props;
    history.push('/dashboard/perpustakaan/data-perpustakaan/table-control-perpustakaan');
  }

  onClickKlasifikasi() {
    const { history } = this.props;
    history.push('/dashboard/perpustakaan/data-perpustakaan/buat-klasifikasi');
  }
  onClickCatat() {
    const { history } = this.props;
    history.push('/dashboard/perpustakaan/data-perpustakaan/catat-buku-atau-catat-majalah');
  }
  onClickUpload() {
    const { history } = this.props;
    history.push('/dashboard/perpustakaan/data-perpustakaan/upload');
  }
  onClickAsalBuku() {
    const { history } = this.props;
    history.push('/dashboard/perpustakaan/data-perpustakaan/buat-asal-buku-atau-majalah');
  }
  onClickTop10() {
    const { history } = this.props;
    history.push('/dashboard/perpustakaan/data-perpustakaan/top-10-analysis');
  }

  onClickDataEntry() {
    const { history } = this.props;
    history.push('/dashboard/perpustakaan/data-perpustakaan/entry-data-pengunjung');
  }
  onClickLaporanPemasukan() {
    const { history } = this.props;
    history.push('/dashboard/perpustakaan/data-perpustakaan/laporan-pemasukan-uang');
  }
  onClickLaporanPengunjung() {
    const { history } = this.props;
    history.push('/dashboard/perpustakaan/data-perpustakaan/laporan-pengunjung');
  }
  onClickLaporanPeminjam() {
    const { history } = this.props;
    history.push('/dashboard/perpustakaan/data-perpustakaan/laporan-peminjaman');
  }
  onClickPeminjaman() {
    const { history } = this.props;
    history.push('/dashboard/perpustakaan/data-perpustakaan/peminjaman-buku');
  }

  onClickPengembalian() {
    const { history } = this.props;
    history.push('/dashboard/perpustakaan/data-perpustakaan/pengembalian-buku');
  }

  onClickSearchBuku() {
    const { history } = this.props;
    history.push('/dashboard/perpustakaan/data-perpustakaan/search-buku');
  }

  render() {
    return (
      <div className="finance">
        <div className="finance__list">
          <button onClick={this.onClickTblControlPerpus}>
            <img src={budgeting} alt=" Control Peprus" />
            <h3> Control Perpustakaan</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickKlasifikasi}>
            <img src={budgeting} alt="Buat Klasifikasi" />
            <h3>Buat Klasifikasi</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickCatat}>
            <img src={budgeting} alt="Catat Buku" />
            <h3>Catat Buku atau Catat Majalah</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickUpload}>
            <img src={budgeting} alt="Upload" />
            <h3>Upload</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickAsalBuku}>
            <img src={budgeting} alt="Buat Asal Buku" />
            <h3>Buat Asal Buku atau Majalah</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickTop10}>
            <img src={budgeting} alt="Top 10 Analysis" />
            <h3>Top 10 Analysis</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickDataEntry}>
            <img src={budgeting} alt=" Control Peprus" />
            <h3> Entry Data Pengunjung</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickLaporanPemasukan}>
            <img src={budgeting} alt="Buat Klasifikasi" />
            <h3>Laporan Pemasukan Uang</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickLaporanPengunjung}>
            <img src={budgeting} alt="Catat Buku" />
            <h3>Laporan Pengunjung</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickLaporanPeminjam}>
            <img src={budgeting} alt="Upload" />
            <h3>Laporan Peminjaman</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickPeminjaman}>
            <img src={budgeting} alt="Buat Asal Buku" />
            <h3>Peminjaman Buku</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickPengembalian}>
            <img src={budgeting} alt="Top 10 Analysis" />
            <h3>Pengembalian Buku</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickSearchBuku}>
            <img src={budgeting} alt="Top 10 Analysis" />
            <h3>Search Buku</h3>
          </button>
        </div>
      
      </div>
    );
  }
}
DataPerpus.propTypes = {
  history: PropTypes.object.isRequired,
};

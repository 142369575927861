import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import budgeting from '../../../assets/img/finance/anggaran.png';
import bookkeeping from '../../../assets/img/bookkeeping/jurnal_bank.png';
import { map } from 'lodash';

export default class MataPelajaran extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickKodeMatpel = this.onClickKodeMatpel.bind(this);
    this.onClickDaftarGuru = this.onClickDaftarGuru.bind(this);
    this.onClickJadwalMatpel = this.onClickJadwalMatpel.bind(this);
    this.onClickNilaiKognitif = this.onClickNilaiKognitif.bind(this);
    this.onClickPsik = this.onClickPsik.bind(this);
    this.onClickHarian = this.onClickHarian.bind(this);
    this.onClickMingguan = this.onClickMingguan.bind(this);
    this.onClickDaftarNilaiReport = this.onClickDaftarNilaiReport.bind(this);
  }

  onClickKodeMatpel() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/mata-pelajaran/kode-mata-pelajaran');
  }

  onClickDaftarGuru() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/mata-pelajaran/daftar-guru');
  }

  onClickJadwalMatpel() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/mata-pelajaran/jadwal-mata-pelajaran');
  }

  onClickNilaiKognitif() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/ulangan/pengisian-nilai-kognitif');
  }

  onClickPsik() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/ulangan/pengisian-psik-afek');
  }

  onClickHarian() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/ulangan/pengisian-observasi-harian');
  }

  onClickMingguan() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/ulangan/pengisian-observasi-mingguan');
  }

  onClickDaftarNilaiReport() {
    const {history} = this.props;
    history.push('/dashboard/kurikulum/ulangan/daftar-nilai/report');
  }

  render() {
    const { user } = this.props;
    const { permissions } = user.user_group;
    return (
      <div>
        {
          map(permissions, (item) => {
            if(item == "view_form_TU"){
              return(
                <div className="finance">
                  <div className="finance__list">
                    <button onClick={this.onClickKodeMatpel}>
                      <img src={budgeting} alt="kode" />
                      <h3>Kode Mata Pelajaran</h3>
                    </button>
                  </div>
          
                  <div className="finance__list">
                    <button onClick={this.onClickJadwalMatpel}>
                      <img src={budgeting} alt="kode" />
                      <h3>Jadwal Mata Pelajaran</h3>
                    </button>
                  </div>
          
                  <div className="finance__list">
                    <button onClick={this.onClickDaftarGuru}>
                      <img src={budgeting} alt="kode" />
                      <h3>Daftar Guru</h3>
                    </button>
                  </div>
                </div>
                );
            }
            if(item == "view_ekskul_guru"){
              return(
              <div className="finance">
                <div className="finance__list">
                    <button onClick={this.onClickJadwalMatpel}>
                      <img src={budgeting} alt="kode" />
                      <h3>Jadwal Mata Pelajaran</h3>
                    </button>
                  </div>
              </div>
              )
            }
          })
        }
      </div>
    );
  }
}
MataPelajaran.propTypes = {
  history: PropTypes.object.isRequired,
};

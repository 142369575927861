import React, { Component } from 'react';
import PropTypes from 'prop-types';

const filterCoa = (original, filter) => original.filter(o => !filter.includes(o.id.toString())) || [];

export default class TemaCoaMapping extends Component {
  constructor(props) {
    super(props);
    this.get = this.get.bind(this);
    this.update = this.update.bind(this);
    this.renderRow = this.renderRow.bind(this);
    this.init = this.init.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      value: {},
      coa: [],
      tema: [],
      filteredCoa: [],
      filteredTema: [],
    };
  }

  componentDidMount() {
    this.init();
  }

  async init() {
    const { handleGetCoaOptions, handleGetTemaOptions, handleGetTemaCoaMap } = this.props;
    const tema = await handleGetTemaOptions();
    const coa = await handleGetCoaOptions();
    const temaCoaList = await handleGetTemaCoaMap() || [];
    let valueCoa = [];
    let filteredCoa = coa || [];

    if ( temaCoaList && temaCoaList.length > 0) {
       valueCoa = temaCoaList.map(o => o.coa);
       filteredCoa = filterCoa(coa, valueCoa);
    }

    this.setState({
      value: temaCoaList,
      tema,
      coa,
      filteredCoa,
    });
  }

  async get() {
    const { handleGetTemaCoaMap } = this.props;
    const value = await handleGetTemaCoaMap();

    this.setState({ value });
  }

  async update() {
    const { handleUpdateTemaCoaMap } = this.props;
    const { form } = this.state;
    const { value } = form;
    const res = await handleUpdateTemaCoaMap({value});
  }

  handleChange(e) {
    const { target } = e;
    const { dataset, value } = target;
    const { tema } = dataset;
    const { value: currentValue, coa } = this.state;
    const newValue = Object.assign({}, currentValue);
    let valueCoa = [];

    if (tema) {
      if (!newValue[tema]) {
        newValue[tema] = {};
      }
      newValue[tema].coa = value;
    }

    Object.keys(newValue).forEach((o) => { valueCoa.push(newValue[o].coa); });
    console.log(valueCoa);

    this.setState(prevState => ({
      ...prevState,
      value: newValue,
      filteredCoa: filterCoa(coa, valueCoa)
    }));
  }

  async handleSubmit(e) {
    e.preventDefault();
    const { handleUpdateTemaCoaMap } = this.props;
    const { value } = this.state;
    const res = await handleUpdateTemaCoaMap({map: value});

  }

  renderRow() {
    const { value, filteredCoa, tema } = this.state;

    if (tema.length > 0) {
      return tema.map((i, index) => (
        <tr key={`tableRow${index}`}>
          <td>{i.title}</td>
          <td>
            <select data-tema={i.id} onChange={this.handleChange}>
              {filteredCoa.map((o, idx) => (
                <option key={`option${index}${idx}`} value={o.id}>
                  {o.id} - {o.title}
                </option>
              ))}
            </select>
          </td>
        </tr>
      ));
    }

  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <table className="table">
          <thead>
            <tr>
              <th>Tema</th>
              <th>Kode Akun</th>
            </tr>
          </thead>
          <tbody>
            {this.renderRow()}
          </tbody>
        </table>
      </form>
    )
  }
}

TemaCoaMapping.propTypes = {
  handleGetTemaCoaMap: PropTypes.func,
  handleUpdateTemaCoaMap: PropTypes.func,
  handleGetTemaOptions: PropTypes.func,
  handleGetCoaOptions: () => {},
};
TemaCoaMapping.defaultProps = {
  handleGetTemaCoaMap: () => {},
  handleUpdateTemaCoaMap: () => {},
  handleGetTemaOptions: () => {},
  handleGetCoaOptions: () => {},
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, Button } from '../../../../../components/base';
import SelectCoa from '../../../../../components/SelectCoa/SelectCoa.component';
import { commaFormatted, normalizeAmount } from '../../../../../utils/transformer.util';


export default class GeneralJournalFieldGroups extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    const { onChange, index, fieldName } = this.props;
    const { name, value } = e.target;
    onChange({
      target: {
        dataset: {
          index,
          fieldName,
          inputArray: true,
          arrayPosition: index,
        },
        name,
        value: (name === 'debit' || name === 'credit') ? normalizeAmount(value) : value,
      },
    });
  }

  render() {
    const {
      index, error, preview, onDelete, data, coa,
    } = this.props;
    const rowIndex = index + 1;
    return (
      <>
        <tr key={`adjustment_journal_data__${index}__${rowIndex}`}>
          <td>{rowIndex}</td>
          <td>
            <SelectCoa
              noMargin
              inputArray
              arrayPosition={rowIndex}
              name="code_of_account"
              coa={coa}
              onClick={this.onChange}
              value={data.code_of_account}
              error={error[rowIndex] ? error[rowIndex].code_of_account : ''}
              rightIcon="icon-search"
              edit={!preview}
            />
          </td>
          <td>
            <Input
              noMargin
              data-input-array
              data-array-position={rowIndex}
              name="description"
              onChange={this.onChange}
              value={data.description || ''}
              error={error[rowIndex] ? error[rowIndex].description : ''}
              edit={!preview}
            />
          </td>
          <td className="nominal">
            <Input
              noMargin
              data-input-array
              data-array-position={rowIndex}
              data-input-type="number"
              name="debit"
              onChange={this.onChange}
              value={commaFormatted(data.debit) || ''}
              error={error[rowIndex] ? error[rowIndex].debit : ''}
              edit={!preview}
            />
          </td>
          <td className="nominal">
            <Input
              noMargin
              data-input-array
              data-array-position={rowIndex}
              data-input-type="number"
              name="credit"
              onChange={this.onChange}
              value={commaFormatted(data.credit) || ''}
              error={error[rowIndex] ? error[rowIndex].credit : ''}
              edit={!preview}
            />
          </td>
          <td>
            <Button onClick={() => onDelete(index)} icon="icon-trash-o" />
          </td>
        </tr>
      </>
    );
  }
}

GeneralJournalFieldGroups.propTypes = {
  coa: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  preview: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  error: PropTypes.array,
  onDelete: PropTypes.func.isRequired,
};

GeneralJournalFieldGroups.defaultProps = {
  error: [],
};

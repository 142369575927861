import { connect } from 'react-redux';
import DetailGeneralJournal from '../../../../views/Finance/Bookkeeping/GeneralJournal/DetailGeneralJournal.finance.component';
import { getGeneralJournalPreview } from '../../../../states/thunks/finance.thunk';

function mapDispatchToProps(dispatch) {
  return {
    handleGetGeneralJournal: payload => dispatch(getGeneralJournalPreview(payload)),
  };
}

export default connect(null, mapDispatchToProps)(DetailGeneralJournal);

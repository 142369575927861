export const CREATE_INVOICE_TABLE_FIELD_LIST = {
    label: [
      'No',
      'Nomor Bukti',
      'Tanggal',
      'Nama',
      'Jumlah',
      '',
    ],
    value: [
      {},
      { type: 'string', attribute: 'no_bukti' },
      { type: 'date', attribute: 'date' },
      { type: 'string', attribute: 'name' },
      { type: 'number', attribute: 'total' },
    ],
  };

  export const PROCESS_CREATE_INVOICE_TABLE_FIELD_FORM = {
    tableHead: ['No', 'Nis', 'Nama siswa', 'nama lengkap/nama tertagih', 'Alamat', 
                'Rincian U Pangkal & U.Sekolah', 'Periode', 'Nomor invoice', 
                'keterangan uang sek'],
    name: [{}, {
      type: 'text',
      name: 'nis',
      label: 'Nis',
      
    }, {
        type: 'text',
        name: 'name_student',
        label: 'Nama Siswa',
    }, 
    {
        type: 'text',
        name: 'name',
        label: 'nama lengkap/nama tertagih',
    },{
        type: 'text',
        name: 'address',
        label: 'Alamat',
    },
    {
        type: 'text',
        name: 'rincian',
        label: 'Rincian U Pangkal & U.Sekolah',
    },  {
        type: 'text',
        name: 'periode',
        label: 'Periode',
    },  {
        type: 'text',
        name: 'no_invoice',
        label: 'Nomor Invoice',
    },  {
        type: 'text',
        name: 'desc',
        label: 'Keterangan Uang Sek',
    },{
      type: 'text',
      inputType: 'number',
      name: 'nominal',
    }],
  };

  export const PEMBAYARAN_TABLE_FIELD_LIST = {
    label: [
      'No',
      'Nomor Bukti',
      'Tanggal',
      'Nama',
      'Jumlah',
      '',
    ],
    value: [
      {},
      { type: 'string', attribute: 'no_bukti' },
      { type: 'date', attribute: 'date' },
      { type: 'string', attribute: 'name' },
      { type: 'number', attribute: 'total' },
    ],
  };
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { map, noop, isEmpty } from "lodash";
import { commaFormatted, dateFormat } from "../../../utils/transformer.util";
import Button from "../../../components/base/Button/Button.component";
import { printLaporanDispliner } from "../../../utils/print.util";
import { Input, Select } from "../../../components/base/index";
import { GENDER } from "../../../variables/common.variable";
import { Link } from "react-router-dom";
import { Bar, Pie } from "react-chartjs-2";
import 'chart.piecelabel.js';
import 'chartjs-plugin-labels'; 

function onClickPrint(header, content, footer) {
  printLaporanDispliner(header, content, footer);
}

export default class AbsensiRekapGrafik extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      journal: {},
      chartData: {
        labels: ["Jumlah Hadir", "Jumlah Sakit", "Jumlah Izin", "Jumlah Alpha"],
        datasets: [
          {
            label: "Population",
            data: [617594, 181045, 153060, 106519, 105162, 95072],
            backgroundColor: [
              "rgba(255, 99, 132, 0.6)",
              "rgba(54, 162, 235, 0.6)",
              "rgba(255, 206, 86, 0.6)",
              "rgba(75, 192, 192, 0.6)",
              "rgba(153, 102, 255, 0.6)",
              "rgba(255, 159, 64, 0.6)",
              "rgba(255, 99, 132, 0.6)"
            ],
            borderWith: 1,
            borderColor: "#777",
            responsive: true,
            maintainAspectRatio: false,
            layout: {
              padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
              }
            },
          }
        ]
      }
    };
    this.onGetDetails = this.onGetDetails.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
  }

  componentDidMount() {
    this.onGetDetails();
  }

  async onGetDetails() {
    const { handleGetCashJournal, match } = this.props;
    const { id } = match.params;
    try {
      const payload = await handleGetCashJournal({ id });
      this.setState({
        journal: payload
      });
    } catch (err) {
      // err action
    }
  }

  _onFormChange(event) {
    const { onChange } = this.props;
    onChange(event);
  }

  render() {
    const { journal } = this.state;
    return (
      <div>
        <h1>Analisa Displiner Pelanggaran </h1>
        <hr></hr>
        


        <div className="nilai-kognitif__custom-form">
          <div className="nilai-kognitif__custom-form-row">
            <div className="nilai-kognitif__custom-form-column__field">
              <Bar
                  data={this.state.chartData}
                  options ={{
                    title:{
                      display:false,
                      text:"Test Bar",
                      fontSize:25
                    },
                    legend:{
                      display:false,
                      position:"bottom"
                    },
                    plugins: {
                      labels: {
                        render: 'value'
                      }
                    }
                  }}
              />
            </div>
          </div>
        </div>

      </div>
    );
  }
}
AbsensiRekapGrafik.propTypes = {
  handleGetStudent: PropTypes.func,
  match: PropTypes.object.isRequired
};
AbsensiRekapGrafik.defaultProps = {
  handleGetCashStudent: noop
};

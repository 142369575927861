import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop, isEqual, map } from 'lodash';
import { validateRequiredFields } from '../../../../utils/validation.util';
import { commaFormatted, normalizeAmount, formatData } from '../../../../utils/transformer.util';
import { Button, Select, Pagination,SearchSelect , Input } from '../../../../components/base/index';
import language from '../../../../languages';
import ReactToPrint from "react-to-print";
import { LAPORAN_8355_TABLE_UNIT_FIELDS} from '../../../../constants/student.constant';

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class CetakLaporan8355 extends Component {
        constructor(props) {
          super(props);
          this._onFormChange = this._onFormChange.bind(this);
          this._onClickNext = this._onClickNext.bind(this);
          this._onChangeFilter = this._onChangeFilter.bind(this);
          this._onSearchContent = this._onSearchContent.bind(this);
          //this._onAddList = this._onAddList.bind(this);
          this._onClickAdd = this._onClickAdd.bind(this);
          this._handleFormChange = this._handleFormChange.bind(this);
          this._onSearch = this._onSearch.bind(this);
          this._getSubjectOptions= this._getSubjectOptions.bind(this);
          this._onChangePage = this._onChangePage.bind(this);

          this.state = {
            page: 1,
            selected: {},
            content: initialContent,
            unchecked: {},
            filters:{},
            list:[],
            form: {
              value: {
                classes: [],
                levels: [],
                classes_id: '',
              },
              error: {
                classes: '',
                levels: '',
              },
            },
            param:{},
            filtered: false,
          };
         
        }
      
        componentDidMount() {
          const { handleGetCodeOfAccount, location, handleGetClasses, handleGetLevels } = this.props;
          const paramTypes = ['classes','levels', 'classrooms'];
          const { content } = this.state;
          paramTypes.forEach((type) => {
            this._getParamOptions(type);
          });
           if (isEmpty(content.list)) {
            this._onSearchContent({ page: 1 });
          }
          this._getSubjectOptions();
        }
      
        componentDidUpdate(prevProps) {
          // const { user } = this.props;
         // const { workingUnit } = user;
         // const { user: prevUser } = prevProps;
         // const { workingUnit: prevWorkingUnit } = prevUser;
     
         // if (!isEqual(workingUnit, prevWorkingUnit)) {
         //   this.handleGetData();
         // }
         if (!isEqual(prevProps, this.props)) {
           this._onSearchContent({ page: 1 });
         }
       }

        // on formchange untuk form
        _handleFormChange(event) {
            const {
              name,
              value,
            } = event.target;
            this.setState(prevState => ({
              form: {
                value: {
                  ...prevState.form.value,
                  [name]: value,
                },
                error: {
                  ...prevState.form.error,
                  [name]: '',
                },
              },
            }));
          }
        
          _onSearch() {
            const { form } = this.state;
            const error = validateRequiredFields(form.value, ['year']);
            this.setState(prevState => ({
              form: {
                value: prevState.form.value,
                error,
              },
            }));
            if (isEmpty(error)) {
              this.setState({ filtered: true });
            }
          }

          async _getParamOptions(type, filters={}) {
            const {getParamOptions, user} = this.props;
            const {organizations_id} = user;
            if (type === 'classes' || type === 'classrooms') {
              filters.organizations_id = organizations_id;
            } else {
              delete filters.organizations_id;
            }
            const res = await getParamOptions(filters, type);
            this.setState(prevState => ({
              ...prevState,
              param: {
                ...prevState.param,
                [type]: res,
              },
            }));
          }

          async _getSubjectOptions() {
            const { getSubjectOptions } = this.props;
            const res = await getSubjectOptions();
            // console.log(res)
            this.setState(prevState => ({
              ...prevState,
              param: {
                ...prevState.param,
                subject: res,
              },
            }));
           
          }

        _onFormChange(event) {
          const {
            name,
            value,
            dataset,
            checked,
            type,
          } = event.target;
          const {
            inputType = 'text', inputArray = false, arrayPosition = 0,
            fieldName,
          } = dataset;
          this.setState((prevState) => {
            let newList = [];
            let newListError = [];
            let formattedValue = value;
      
            if (inputType === 'number') {
              formattedValue = normalizeAmount(value);
            }
            if (inputArray) {
              if (type === 'checkbox') {
                formattedValue = checked;
              }
              newList = prevState.form.value[fieldName];
              newListError = prevState.form.error[fieldName];
              newList[arrayPosition][name] = formattedValue;
              if (name === 'code_of_account') {
                newList[arrayPosition].isCredit = value.type;
              }
              if (!isEmpty(newListError[arrayPosition])) {
                newListError[arrayPosition][name] = '';
              }
            }
            return {
              form: {
                value: {
                  ...prevState.form.value,
                  ...(inputArray
                    ? { [fieldName]: newList }
                    : { [name]: formattedValue }),
                },
                error: {
                  ...prevState.form.error,
                  ...(inputArray
                    ? { [fieldName]: newListError }
                    : { [name]: '' }),
                },
              },
            };
          }, () => {
            if (name === 'subject_id') {
              this._onSearchContent()
            }

            // if (name === 'ekskul_id') {
            //   this._getTeacherEkskulOptions({ filters: { ekskul_id: value }} );
            // }
          
          });
        }
      
        _onClickAdd(data) {
          const { form } = this.state;
          const { plafon } = form.value;
          
          plafon.push({
            tipePlafon: '',
            // divisi: '',
            nominal: 0,
            //period:'',
          });
          
          this.setState(prevState => ({
            form: {
              value: {
                ...prevState.form.value,
                plafon,
              },
              error: {
                ...prevState.form.error,
              }
            }
          }));
          console.log(plafon)
        }
      
        async _onSearchContent(params = {}) {
          const { user } = this.props;
          const { organizations_id } = user;
          const { filters, form } = this.state;
          const { value } = form;
          const { subject_id } = value;
          this.setState({
            content: initialContent,
          }, async () => {
            try {
              const { handleListReportStudy , handleListReportStudent, handleListAchievement} = this.props;
              let res = {};
              if (subject_id) {
                 res = await handleListReportStudy({ ...params, organizations_id: organizations_id, subject_id: value.subject_id, filters });
              } else {
                res = await handleListReportStudent({ ...params, organizations_id: organizations_id , filters });
              }

              console.log(res);
              const {
                result = [], currentPage = 1, total = 0, totalPage = 1,
              } = res;
              
              if (result) {
                this.setState({
                  content: {
                    loading: false,
                    list: result,
                    currentPage,
                    total,
                    totalPage,
                  },
                });
              }
            } catch (err) {
              // if (this.isMount) {
                this.setState({
                  content: {
                    loading: false,
                    list: [],
                    currentPage: 1,
                    total: 1,
                    totalPage: 1,
                    error: true,
                  },
                });
              }
            // }
          });
        }
      
        // on formchange untuk tabel
        onFormChange(event) {
          const {
            name,
            value,
            dataset,
          } = event.target;
          console.log(name)
          const { inputArray = false, arrayPosition = 0, inputType = 'text' } = dataset;
          this.setState((prevState) => {
            let formattedValue = value;
            let newPlafon = [];
            if (inputType === 'number') {
              formattedValue = normalizeAmount(value);
            }
      
            if (inputArray) {
              newPlafon = prevState.form.value.plafon;
              newPlafon[arrayPosition][name] = value;
            }
            return {
              form: {
                value: {
                  ...prevState.form.value,
                  ...(inputArray ? 
                    { plafon: newPlafon } : 
                    { [name]: formattedValue }
                  )
                },
                error: {
                  ...prevState.form.error,
                  form:'',
                  [name]: '',
                },
              },
            };
          });
        }
      
       

        _onChangeFilter(e) {
          const { target } = e;
          const { value, name } = target;
          this.setState(prevState => ({
            ...prevState,
            filters: {
              ...prevState.filters,
              [name]: value,
            },
          }), () => this._onSearchContent());
        }
        _onChangePage(page) {
          const { listAmount, keywords } = this.state;
          const offset = listAmount * (page - 1);
          // this.handleGetData({
          this._onSearchContent({
            limit: listAmount,
            keywords,
            offset,
            page,
          });
        }
      
        
    _onClickNext() {
        window.scrollTo(0, 0);
        this.setState(prevState => ({
          page: prevState.page + 1,
        }));
      }
  
      render() {
        const componentRef = React.createRef();
        const {param, form,
                page, list = {},
                selected, checkedAll, content, 
                filters, listAmount } = this.state;
        const {
          classes_id = '',
          status_id = '',
          period = '',
        } = filters;
        const { classes = {}, levels = {} } = this.props;
       
        return (
          <div className="manage-registration">
            <form onSubmit={this.onSubmit}>
            <div>
      <div className="cash-journal-report">
              
              <ReactToPrint
              trigger={() => (
                <div className="form-group-member">
                  <Button title="Cetak" />
                </div>
              )}
              content={() => componentRef.current}
            />
            </div>

            </div>
           
            <div className="manage-registration">
        <div className="fund-request__content">
    <table className="cash-journal-report__table" ref={componentRef}>

        <table className="table">
          <thead>
            <tr>
              {
                  map(LAPORAN_8355_TABLE_UNIT_FIELDS.label, (field, idx) => (
                    <th key={`table_th_${idx}`}>{field}</th>
                  ))
                }
            </tr>
          </thead>
          <tbody>
            {
                map(content.list, (list, idx) => (
                  <tr key={`budget_row_${idx}`}>
                    {
                    map(LAPORAN_8355_TABLE_UNIT_FIELDS.value, (field, fieldIdx) => {
                      if (fieldIdx === 0) {
                        return (
                          <td key={`table_index_${fieldIdx}_${idx}`}>{idx + 1}</td>
                        );
                      }
                      if (field.type === 'link') {
                        return (
                          <td key={`table_${fieldIdx}_${idx}`}><a href={"https://"+list.hyperlink} target="_blank" rel="noopener noreferrer">{list.hyperlink}</a></td>
                            // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                        );
                      }
                      return (
                        <td className={(field.type === 'number') ? 'nominal' : ''} key={`table_${fieldIdx}_${idx}`}>
                          {formatData(list, field)}
                        </td>
                      );
                    })
                    }
                  </tr>
                ))
              }
          </tbody>
        </table>
        </table>
      </div>
              
                
              
            </div>
            <div className="user-management__table-footer">
        <p className="user-management__page-info">
          {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${content.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${content.totalPage}`}
        </p>
        <Pagination
          totalPage={content.totalPage}
          currentPage={content.currentPage}
          onClick={this._onChangePage}
        />
      </div>
    
            </form>
          </div>
        );
      }
}
CetakLaporan8355.propTypes = {
    
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
CetakLaporan8355.defaultProps = {
    
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};

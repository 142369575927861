import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, isEqual, map } from "lodash";
import { commaFormatted, normalizeAmount } from "../../../utils/transformer.util";
import { Button, Select, SearchSelect, Input } from "../../../components/base/index";
import CatatanAnekdotTemaForm from "./components/CatatanAnekdotTemaForm.component";
import AnekdotSubTemaForm from "./components/AnekdotSubTemaForm.component";

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class CatatanAnekdotTema extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._onClickNext = this._onClickNext.bind(this);
    this._onClickBack = this._onClickBack.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._setForm = this._setForm.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);
    this._onClickAddSubTema = this._onClickAddSubTema.bind(this);
    this._onDeleteListSubTema = this._onDeleteListSubTema.bind(this);
    this._onClickAdd = this._onClickAdd.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this._getPrmSubTema = this._getPrmSubTema.bind(this);
    this._getKDMaterial = this._getKDMaterial.bind(this);
    this._getPrmTema = this._getPrmTema.bind(this);

    const { user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;
    this.state = {
      page: 1,
      content: initialContent,
      unchecked: {},
      filters: {},
      list: [],
      form: {
        value: {
          levels_id,
          organizations_id: organizations_id,
          sub_tema: [
            {
              sub_tema_id:'',
              date:'',
              indikator: [
                {
                  kd:'',
                  name:'',
                  note:'',
                },
              ],
            }
          ],
        },
        error: {
          // indikator: "",
          // sub_tema: "",
        },
      },
      param: {},
    };
  }

  componentDidMount() {
    const { location, getLevels, getclassrooms, user } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data,  } = state;
    const { id, tema_id } = state.data || "";
    const { form } = this.state;
    const paramTypes = ["classrooms", "levels"];
    if (isEdit && id) {
      this._setForm(id, data);
    }
    // else
    
    // this._getPrmSubTema();
    this._getPrmSubTema({ filters: {tema_id: tema_id} });
    this._getKDMaterial();
    this._getPeriodsOptions();
    this._getPrmTema();
  }

  _onClickAddSubTema(data) {
    const { form } = this.state;
    const { sub_tema } = form.value;

    sub_tema.push({
      sub_tema_id: "",
      date: "",
      indikator: [],
    });

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          sub_tema,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }
  
  _onDeleteListSubTema(idx) {
    this.setState((prevState) => {
      const { sub_tema } = prevState.form.value;
      const list = prevState.form.value.sub_tema;
      list.splice(idx, 1);
      return {
        ...prevState,
        sub_tema: list,
      };
    });
  }

  _onClickAdd(data) {
    const { form } = this.state;
    const { sub_tema } = form.value;
    const [{ indikator }] = sub_tema;

    sub_tema[data].indikator.push({
      kd: "",
      name: "",
      note: "",
    });

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          ...prevState.sub_tema,
          indikator,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }
  
  _onDeleteList(data) {
    this.setState((prevState) => {
      const { sub_tema } = prevState.form.value;
      const [{ indikator }] = sub_tema;
      const idxsub = data.idxsub;
      const idx = data.idx;

      const list = prevState.form.value.sub_tema[idxsub].indikator;
      list.splice(idx, 1);
      return {
        ...prevState,
        indikator: list,
      };
    });
  }

  async _getPrmTema(filters = {}) {
    const { getPrmTema, user } = this.props;
    const { organizations_id, school_unit } = user;
    const res = await getPrmTema(filters);
    filters.organizations_id = organizations_id;

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        prm_tema: res,
      },
    }));
  }

  async _getPrmSubTema(filters = {}) {
    const { getPrmSubTema, user } = this.props;
    const { organizations_id, school_unit } = user;
    const res = await getPrmSubTema(filters);
    filters.organizations_id = organizations_id;

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        prm_sub_tema: res,
      },
    }));
  }

  async _getKDMaterial(filters = {}) {
    const { getKDMaterial } = this.props;
    const res = await getKDMaterial(filters);

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        kd_prm: res,
      },
    }));
  }

  // componentDidUpdate(prevProps) {
  //   const { location } = this.props;
  //   const { state = {} } = location;
  //   const { isEdit = false, data } = state;
  //   const { id } = state.data || "";
  //   if ((!isEdit || !id) && !isEqual(prevProps, this.props)) {
  //     this._onSearchContent({ page: 1 });
  //   }
  // }

  async _getPeriodsOptions() {
    const { getPeriodsOptions } = this.props;
    const res = await getPeriodsOptions();
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  async _getPrmAspectOptions() {
    const { getPrmAspectOptions } = this.props;
    const res = await getPrmAspectOptions();
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        prm_aspect: res,
      },
    }));
  }

  async _setForm(id, data = {}) {
    const { handleGetCatatanAnekdot, user } = this.props;
    const { organizations_id } = user;
    const { tema_id, semester } = data;
    try {
      const payload = await handleGetCatatanAnekdot({ id, tema_id, semester });
      this.setState({
        form: {
          value: {
            id: payload.id,
            students_id: data.id,
            semester: data.semester,
            organizations_id: organizations_id,
            period_id: payload.period_id,
            tema_id: data.tema_id,
            sub_tema: map(payload.sub_tema, detail => ({
              sub_tema_id: detail.sub_tema_id,
              date:detail.date,
              indikator: map(detail.indikator, detail_indikator => ({
                kd: detail_indikator.kd,
                name:detail_indikator.name,
                note: detail_indikator.note,
              })),
            })),
          },
          error: {
            // details: [],
          },
        },
      });
    } catch (err) {
      // err action
    }

  }

  async onEdit() {
    const { history, handleEditLembarObsHarian } = this.props;
    const { form } = this.state;
    const { value } = form;
    let canSaveA = false;
    let canSaveB = false;
    const dataTabel = value.pelajaran;
    const dataForm = form.value;
    let a;
    await handleEditLembarObsHarian(value);
    history.push(
      "/dashboard/kurikulum/ulangan/pengisian-lembar-daftar-nilai/report"
    );
    // }
  }

  async onSubmit() {
    const { history, handleSaveCatatanAnekdot } = this.props;
    const { form } = this.state;
    const { value } = form;
    let canSaveA = false;
    const dataForm = form.value;
    await handleSaveCatatanAnekdot(value);
    history.push("/dashboard/kurikulum/ulangan/catatan-anekdot/pengisian-catatan-anekdot");
    // history.go(0);
  }

  _onFormChange(event) {
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
      arraySub,
    } = dataset;

    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (inputType === "number") {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        if (type === "checkbox") {
          formattedValue = checked;
        }
        if(fieldName === "indikator"){
          newList = prevState.form.value.sub_tema[arraySub][fieldName];
          newListError = prevState.form.error[fieldName] || [];
          newList[arrayPosition][name] = formattedValue;
        } else{
          newList = prevState.form.value[fieldName] || [];
          newListError = prevState.form.error[fieldName] || [];
          newList[arrayPosition][name] = formattedValue;
        }
        if (name === "code_of_account") {
          newList[arrayPosition].isCredit = value.type;
        }
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = '';
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
          },
        },
      };
    });
  }

  // _onClickNext() {
  //   const { history } = this.props;
  //   history.push("/dashboard/kesiswaan/laporan/daftar-siswa-kelas-unit");
  // }

  _onClickNext() {
    window.scrollTo(0, 0);
    this.setState((prevState) => ({
      page: prevState.page + 1,
    }));
  }

  _onClickBack() {
    window.scrollTo(0, 0);
    this.setState((prevState) => ({
      page: prevState.page - 1,
    }));
  }
  
  render() {
    const { location, getLevels, getclassrooms, user, list } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";
    const { form, param, page } = this.state;
    const { sub_tema } = form.value;
    const { indikator } = sub_tema;
    return (
      <div className="student-list">
        <div className="budget__title">
          <h1>Pengisian Lembar Observasi </h1>
          <hr></hr>
        </div>
        <form onSubmit={this._onSubmit}>
          <div className="result__content">
            {page === 1 ? (
              <CatatanAnekdotTemaForm
                form={form}
                param={param}
                data={data}
                onFormChange={this._onFormChange}
              />
            ) : (
              ""
            )}
          </div>
          <br/>
          <table className="manage-rapbs-plafon__form-table table-anekdot">
            <tbody>
              {map(sub_tema, (data, idxsub) => (
                <tr key={`plafon_journal_data__${idxsub}`}>
                  <td>
                    <h3>Sub Tema {idxsub + 1}</h3>
                    <hr/>
                    <br/>
                    <div className="absensi-rekap__custom-form-row">
                      <div className="absensi-rekap__custom-form-row__field">
                        <Select
                          label="Nama Sub Tema"
                          name="sub_tema_id"
                          onChange={this._onFormChange}
                          placeholder="Pilihan"
                          data={param.prm_sub_tema}
                          value={data.sub_tema_id}
                          data-input-array
                          arrayPosition={idxsub}
                          data-array-position={idxsub}
                          data-field-name="sub_tema"
                        />
                      </div>
                      <div className="absensi-rekap__custom-form-column__field">
                        <Input
                          type='date'
                          name="date"
                          label="Tgl Penilaian"
                          onChange={this._onFormChange}
                          value={data.date}
                          data-input-array
                          arrayPosition={idxsub}
                          data-array-position={idxsub}
                          data-field-name="sub_tema"
                        />
                      </div>
                    </div>
                    
                    <AnekdotSubTemaForm
                      indikator={indikator}
                      onDeleteList={this._onDeleteList}
                      // onDeleteList={this._onDeleteList()}
                      onAddList={() =>this._onClickAdd(idxsub)}
                      onFormChange={this._onFormChange}
                      param={param}
                      data-input-array
                      arrayPosition={idxsub}
                      data-array-position={idxsub}
                      data-field-name="sub_tema"
                      subdata={sub_tema}
                      idxsub={idxsub}
                      // inputArray
                      // fieldName='sub_tema'
                      // array_position={idxsub}
                    />

                  </td>
                  <td>
                    <Button onClick={() => this._onDeleteListSubTema(idxsub)} icon="icon-trash-o">Hapus<br/>Sub Tema</Button>
                    {/* <Button onClick={() => this._onDeleteList()} icon="icon-trash-o"></Button> */}
                  </td>
                  
                </tr>
                
              ))}
              <tr className="manage-rapbs-plafon__add-more">
                <td colSpan={7}>
                  <div>
                    <Button icon="icon-plus" onClick={this._onClickAddSubTema}> Tambah Sub Tema</Button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <br />
          <div className="student-list__header">
            <div className="student-list__button">
              {this.state.id ? (
                <Button type="button" title="Update" onClick={this.onEdit} />
              ) : (
                <Button onClick={this.onSubmit} title="Simpan" />
              )}
            </div>
          </div>
        </form>
      </div>
    );
  }
}
CatatanAnekdotTema.propTypes = {
  handleGetKlapper: PropTypes.func,
  handleDeleteKlapper: PropTypes.func,
};
CatatanAnekdotTema.defaultProps = {
  handleGetKlapper: noop,
  handleDeleteKlapper: noop,
};

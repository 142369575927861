import React, { PureComponent } from 'react';
import swal from 'sweetalert';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Button } from '../../../../components/base';
import BudgetRapbuRekapExcel from './components/BudgetRapbuRekapExcel.component';
import language from '../../../../languages';
import { normalizeAmount } from '../../../../utils/transformer.util';
import { printRekapRapbs } from '../../../../utils/print.util';

export default class ManageBudgetRekapExcel extends PureComponent {
  constructor(props) {
    super(props);
    this.onSetForm = this.onSetForm.bind(this);
    this.onChangeRecommendation = this.onChangeRecommendation.bind(this);
    this.onSaveRecommendations = this.onSaveRecommendations.bind(this);
    this.onSubmitApproval = this.onSubmitApproval.bind(this);
    this.onReject = this.onReject.bind(this);
    this.onSelectBudgetDetail = this.onSelectBudgetDetail.bind(this);
    this.setRemarks = this.setRemarks.bind(this);
    this.confirmReject = this.confirmReject.bind(this);
    this.onSearchTema = this.onSearchTema.bind(this);
    this.hasRecommendations = this.hasRecommendations.bind(this);
    this.remarks = React.createRef();


    this.state = {
      data: {
        budget: [],
        data: [],
      },
      budgetloop: [],
      dataloop: [],
      tema: [],
      modalVisible: false,

      recommendations: {},
      selectedBudgetDetails: [],
      remarks: '',
    };
    // console.log(this.state)
  }

  componentDidMount() {
    this.onSetForm();
    this.onSearchTema();
  }

  async onSetForm() {
    const { handleGetBudgetRapbuRekapExcelDetail, location } = this.props;
    const { account_unique_id, head_unique_id } = location.state;
    const res = await handleGetBudgetRapbuRekapExcelDetail({ account_unique_id, head_unique_id, type: 'REKAP_RAPBU' });

    this.setState({
      budget: res.budget,
      data: res.data,
      budgetloop: res.result.budget,
      dataloop: res.result.data,
    });

    // console.log(res.budget)
  }

  onSearchTema(keyword = null) {
    const { handleGetTemaOptions } = this.props;
    handleGetTemaOptions({
      keyword,
    });
  }

  onChangeRecommendation(event) {
    const { target } = event;
    const { dataset, value, name } = target;
    const { referenceid } = dataset;
    const { recommendations } = this.state;

    if (recommendations[name] === null) {
      recommendations[name] = {};
    }

    recommendations[name][referenceid] = normalizeAmount(value);

    this.setState(prevState => ({
      ...prevState,
      recommendations: {
        ...recommendations,
      },
    }));
  }

  onSubmitApproval() {
    const { recommendations, selectedBudgetDetails } = this.state;
    const { handleSubmitApproval, location, history } = this.props;
    const { head_unique_id } = location.state;

    handleSubmitApproval({
      head: head_unique_id,
      recommendations,
      selectedBudgetDetails,
    }, history.goBack);
  }

  onSaveRecommendations() {
    const { recommendations } = this.state;
    const { handleSaveRecommendations, history } = this.props;

    handleSaveRecommendations({
      recommendations,
    }, history.goBack);
  }

  onReject(remarks) {
    const { handleReject, history, location } = this.props;
    const { recommendations } = this.state;
    const { head_unique_id } = location.state;

    handleReject({
      head: head_unique_id,
      recommendations,
      remarks,
    }, history.goBack);
  }

  onSelectBudgetDetail(event) {
    const { target } = event;
    const { value, checked } = target;
    const { selectedBudgetDetails } = this.state;
    const index = selectedBudgetDetails.indexOf(value);
    const newArray = selectedBudgetDetails.slice(0, selectedBudgetDetails.length);

    if (index !== -1) {
      if (checked === false) {
        newArray.splice(index, 1);
      }
    } else if (checked) {
      newArray.push(value);
    }

    this.setState(prevState => ({
      ...prevState,
      selectedBudgetDetails: newArray,
    }));
  }

  setModalVisible(visible) {
    this.setState({ modalVisible: visible });
  }

  setRemarks(event) {
    const { value } = event.target;

    this.setState(prevState => ({
      ...prevState,
      remarks: value,
    }));
  }

  confirmReject() {
    const el = this.remarks.current.input;
    el.classList.remove('hidden');
    return swal({
      title: language.translate.ALERT__ARE_YOU_SURE,
      text: 'Masukkan remarks untuk menolak RAPBS',
      content: el,
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((reject) => {
      if (reject) {
        this.onReject(el.value);
      }
    });
  }


  hasRecommendations() {
    const { recommendations } = this.state;

    return Object.keys(recommendations).some((key) => {
      const items = recommendations[key];
      if (items !== null && Object.keys(items).length > 0) {
        return Object.keys(items).some((referenceid) => {
          const value = items[referenceid];
          return value !== null && value > 0;
        });
      }
      return false;
    });
  }


  render() {
    const {
      dataloop, budgetloop, recommendations,
    } = this.state;
    const {
      location, user, tema = {},
    } = this.props;
    const { type, code } = location.state;

    return (
      <div className="content_wrapper">
        <div className="table_title">
          <h1>
            {language.translate.FINANCE__BUDGETING__MANAGE_BUDGET_REKAP__TITLE}&nbsp;
            {language.translate[`FINANCE__BUDGETING__MANAGE_BUDGET__${type}`]}
          </h1>
          <h2>
            {language.translate.FINANCE__BUDGETING__MANAGE_BUDGET_REKAP__RAPBU}&nbsp;
          </h2><br />
        </div>
        <br />
        <BudgetRapbuRekapExcel
          code={code}
          budgetloop={budgetloop}
          dataloop={dataloop}
          recommendations={recommendations}
          onChangeRecommendation={this.onChangeRecommendation}
          onSelectBudgetDetail={this.onSelectBudgetDetail}
          user={user}
          onSearchTema={this.onSearchTema}
          tema={tema}
        />
      </div>

    );
  }
}
ManageBudgetRekapExcel.propTypes = {
  handleGetBudgetRapbuRekapExcelDetail: PropTypes.func,
  handleSaveRecommendations: PropTypes.func,
  handleSubmitApproval: PropTypes.func,
  handleReject: PropTypes.func,
  tema: PropTypes.object,
  handleGetTemaOptions: PropTypes.func,
  location: PropTypes.object.isRequired,
  user: PropTypes.object,
  history: PropTypes.object,
};
ManageBudgetRekapExcel.defaultProps = {
  handleGetBudgetRapbuRekapExcelDetail: noop,
  handleSaveRecommendations: noop,
  handleSubmitApproval: noop,
  handleReject: noop,
  handleGetTemaOptions: noop,
  tema: noop,
  user: null,
  history: null,
};

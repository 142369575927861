import { connect } from 'react-redux';
import ManageFundRequest from '../../../../views/Finance/Budgeting/FundRequest/ManageFundRequest.finance.view';
import {
  addFundRequest,getDetailFund,
  getBudgetCheckList, getFundRequestById, getFundRequestAvailableCoa, getAvailableBudgetDetails,
  rejectFundRequest, submitFundRequest,
} from '../../../../states/thunks/finance.thunk';
import { getDetailHead } from '../../../../states/thunks/common.thunk';
import { getBudgetOptions, getCoaOptions, getExistingPeriodeOptions } from '../../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    options: state.options,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetBudgetOptions: (payload, unit_id) => dispatch(getBudgetOptions(payload, unit_id)),
    handleGetPeriodeOptions: payload => dispatch(getExistingPeriodeOptions(payload)),
    handleGetCoaOptions: payload => dispatch(getCoaOptions(payload)),
    handleGetAvailableBudgetDetails: payload => dispatch(getAvailableBudgetDetails(payload)),
    handleGetFundRequestAvailableCoa: payload => dispatch(getFundRequestAvailableCoa(payload)),
    handleGetFundRequestById: payload => dispatch(getFundRequestById(payload)),
    handleGetDetailHead: payload => dispatch(getDetailHead(payload)),
    handleGetBudgetCheckList: payload => dispatch(getBudgetCheckList(payload)),
    handleGetDetailFund: payload => dispatch(getDetailFund(payload)),
    handleAddFundRequest: (payload, goBack) => dispatch(addFundRequest(payload, goBack)),
    handleReject: (payload, goBack) => dispatch(rejectFundRequest(payload, goBack)),
    handleSubmitApproval: (payload, goBack) => dispatch(submitFundRequest(payload, goBack)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageFundRequest);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop, map } from 'lodash';
import { Button } from '../../../../components/base';
import { formatData,  normalizeAmount } from '../../../../utils/transformer.util';
import ListForm from '../../../../components/ListForm/ListForm.component';
import ContentTable from '../../../../components/ContentTable/ContentTable.component';
import { KELAS_DATAKELAS_TABLE_FIELDS } from '../../../../constants/Akademi/DataKelas/kelas.constant';
import { SEARCH_FIELDS} from '../../../../constants/Akademi/DataKelas/kelas.constant';

import {TAMBAH_UANG_SEKOLAH_FORM}   from '../../../../constants/finance/spp/tambah-uang-sekolah.constant';
import TambahJenisForm from './components/TambahJenisForm.component';
import ControlTable from './components/ControlTable.component';
import { JENIS_SPP_FORM_TABLE } from '../../../../constants/finance/spp/tambah-uang-sekolah.constant';
import TableTambahStatus from './components/TableTambahStatus.component';
const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};
export default class TambahJenisSPP extends Component {
  constructor(props) {
    super(props);
    this.onEdit = this.onEdit.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._getClassesOptions= this._getClassesOptions.bind(this);
    this._getParamOptions = this._getParamOptions.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this._onClickAdd = this._onClickAdd.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this.onRemoveFile = this.onRemoveFile.bind(this);
    this._onClickAddStatus = this._onClickAddStatus.bind(this);
    this._onDeleteListStatus = this._onDeleteListStatus.bind(this);
    this._setForm = this._setForm.bind(this);
    this._getStatusOptions= this._getStatusOptions.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    const {user} = this.props;
    const { organizations_id } = user;

    this.state = {
      page: 1,
      content: initialContent,
      unchecked: {},
      filters:{},
      list:[],
      form: {
        value: {
          organizations_id: organizations_id,
          status_id:'',
          jenisspp: [
            {
             classes_id :'',
             nominal :'',
            }
          ],
        },
        error: {
          jenisspp:''
        },
      },
      filtered: false,
      param:'',
    };
  }
  componentDidMount() {
    const {location, handleGetclassrooms } = this.props;
    
    const { state = {} } = location;
    const {isEdit =  false, data} = state;
    const { id } = state.data || '';
    const { content } = this.state;
    const paramTypes = ['levels', 'classrooms'];
    
    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
    if (isEdit && id) {
      this.setState({id:id})
      this._setForm(data.id);
    } 
    this._getStatusOptions();
    this._getClassesOptions();
  }


  async _getClassesOptions(filters={}) {
    const { getClassesOptions } = this.props;
    const res = await getClassesOptions(filters);
    // console.log(res)
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        classes_prm: res,
      },
    }));
   
  }

  async _getStatusOptions() {
    const { getStatusOptions } = this.props;
    const res = await getStatusOptions();
    // console.log(res)
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        prm_status: res,
      },
    }));
   
  }

  async _getParamOptionsAcademics(type, filters={}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id } = user;
    if (type === 'classes' || type === 'classrooms') {
      filters.organizations_id = organizations_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }
 


  async _getParamOptions(type, filters = {}) {
    const { getParamOptions } = this.props;
    const res = await getParamOptions(filters, type);
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }
  _onClickAdd(data) {
    const { form } = this.state;
    const { jenisspp } = form.value;
    jenisspp.push({
     classes_id:'',
     nominal: '',
    });        
    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          jenisspp,
        },
        error: {
          ...prevState.form.error,
        }
      }
    }));
  }
  async onSubmit() {
    const { history, handleSaveJenisSPP } = this.props;
    const { form } = this.state;
    const { value } = form;        
    let canSaveA = false;
    const dataForm = form.value;
    // if(dataForm.name && dataForm.publish_date && dataForm.tgl_selesai && dataForm.levels && dataForm.classes){
    //   canSaveA = true;
    // } else {
    //   canSaveA = false;
    // }
    // if (canSaveA == false){
    //   alert("Harap lengkapi data form. Bagian yang harus diisi ditandai dengan tanda bintang (*).");
    // } else {
      await handleSaveJenisSPP(value);
      history.push('/dashboard/keuangan/spp/jenis-spp');
    // }
  }

  async onEdit() {
    const { history, handleEditJenisSPP } = this.props;
    const { form } = this.state;
    const { value } = form;
    let canSaveA = false;
    let canSaveB = false;
    const dataTabel = value.jenisspp;
    const dataForm = form.value;
    let a;
      await handleEditJenisSPP(value);
      history.push('/dashboard/keuangan/spp/jenis-spp');
    
   
  }

  async _setForm(id) {
    const { handleGetJenisSPP } = this.props;
    try {
      const {user} = this.props;
      const { organizations_id } = user;
      const payload = await handleGetJenisSPP({ id });
      const {classrooms, levels_id, classes_id} = payload;
      this._getClassesOptions({ filters: { levels_id: [levels_id] }} );
      this._getParamOptionsAcademics('classrooms', { classes_id: classes_id, organizations_id:organizations_id }  );
      this.setState({
       
        form: {
          value: {
            id: payload.id,
            code: payload.code,
            is_discount: payload.is_discount,
            name: payload.name,
            levels_id: payload.levels_id,
            status_id: payload.status_id,
            jenisspp: map(payload.jenisspp, detail => ({
              classes_id: detail.classes_id, 
              nominal: detail.nominal, 
              prm_payments_id: detail.prm_payments_id,
            })),
          },
          error: {
            jenisspp: [],
          },
        },
      });
    } catch (err) {
      // err action
    }
  }


  _onDeleteList(idx) {
    this.setState((prevState) => {
      const { jenisspp } = prevState.form.value;
      const list = prevState.form.value.jenisspp;
      list.splice(idx, 1);
      return {
        ...prevState,
        jenisspp: list
      };
    });
  }
  _onClickSubmit() {
    const { history } = this.props;
    //history.push('/dashboard/kurikulum/laporan/daftar-nilai-siswa-ktsp');
    //history.push('/dashboard/kurikulum/laporan/daftar-nilai-siswa-k13');
    //history.push('/dashboard/kurikulum/laporan/analisa-nilai-siswa');
    //history.push('/dashboard/kurikulum/laporan/psikomotorik-siswa');
  }

  onRemoveFile() {
    this.setState((prevState) => {
      return {
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            upload: '',
            file:'',
          }
        }
      }
    });
  }

  _onDeleteListStatus(idx) {
    this.setState((prevState) => {
      const { tabelstatus } = prevState.form.value;
      const list = prevState.form.value.tabelstatus;
      list.splice(idx, 1);
      return {
        ...prevState,
        tabelstatus: list
      };
    });
  }

  _onClickAddStatus(data) {
    const { form } = this.state;
    const { tabelstatus } = form.value;
    tabelstatus.push({
      kode_jenis_spp: '',
      nama_jenis_spp: '',
    });        
    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          tabelstatus,
        },
        error: {
          ...prevState.form.error,
        }
      }
    }));
  }

  _onFormChange(event) {
    const { user } = this.props;
    const { organizations_id } = user;
    const {
      name,
      value,
      dataset,
      checked,
      type,
    } = event.target;
    const {
      inputType = 'text', inputArray = false, arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        if (type === 'checkbox') {
          formattedValue = checked ? value : '';
        }
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName] || [];
        newList[arrayPosition][name] = formattedValue;
        if (name === 'code_of_account') {
          newList[arrayPosition].isCredit = value.type;
        }
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = '';
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray
              ? { [fieldName]: newListError }
              : { [name]: '' }),
          },
        },
      };
    }, () => {
      if (name === 'levels_id') {
        this._getParamOptionsAcademics( 'classrooms', {levels_id: value, organizations_id:organizations_id});
      }
      if (name === 'ekskul_id') {
        this._onSearchContent()
      }
      if (name === 'levels_id') {
        this._getClassesOptions({ filters: { levels_id: [value] }} );
      }
    
    });
  }
  render() {
    const { form, param, file, name, publish_date, tgl_selesai,  classes } = this.state;
    const { handleGetKelas, handleUploadScheduleFile, location } = this.props;
    const { tabelstatus, jenisspp } = form.value;
   
    return (
      <div className="student-list">
        <div className="budget__title">
            <h1>Jenis SPP</h1>
            <hr></hr>
          </div>
         
          <br></br>
          <form onSubmit={this.onSubmit}>
                <h2>Data</h2>
                <TambahJenisForm
                  form={form}
                  onChange={this._onFormChange}
                  param = {param}
                />
          <br></br>
          
          <div>
          <ControlTable
            list={jenisspp}
            param={param}
            onAddList={this._onClickAdd}
            onFormChange={this._onFormChange}
            onDeleteList={this._onDeleteList}
          />
          </div>
          <div className="manage-registration__footer">
            {this.state.id?
              <Button
                type="button"
                title="Update"
                onClick={this.onEdit}
              />:
              <Button
                type="button"
                title="Simpan"
                onClick={this.onSubmit}
              />
            }
          </div>
        </form>
      </div>
    );
  }
}
TambahJenisSPP.propTypes = {
  getParamOptions: PropTypes.func,
  handleGetSchedule: PropTypes.func,
  getStudentOptions: PropTypes.func,
  handleGetStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
  handleGetJenisSPP: PropTypes.func,
};
TambahJenisSPP.defaultProps = {
  getStudentOptions: noop,
  getParamOptions: noop,
  handleGetStudent: noop,
  handleGetSchedule: noop,
  handleGetJenisSPP: noop,
};
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop, map, isEqual} from 'lodash';
import StudentReportFilter from '../../../components/Filter/StudentReport.filter.component';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { BIDANG_STUDY} from '../../../constants/student.constant';
import { BIDANG_STUDY_TABLE } from '../../../constants/student.constant';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted, normalizeAmount, formatData } from '../../../utils/transformer.util';
import { Button, Select, Input, Pagination } from '../../../components/base/index';
import IsiPerBidangForm from '../components/IsiPerBidangForm.component';
import IsiPerBidangPage1 from '../components/IsiPerBidangPage1.component';
import IsiPerBidangTable from '../components/IsiPerBidangTable.component';
import language from '../../../languages';
import { Link } from 'react-router-dom';
import { ABSENSI_PERBIDANG_STUDI_TABLE_FIELDS} from '../../../constants/Student/student.constant';

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class TambahAbsenPerbidangStudy extends Component {
        constructor(props) {
          super(props);
          this._onClickUpload = this._onClickUpload.bind(this);
          this._onFormChange = this._onFormChange.bind(this);
          this.onSubmit = this.onSubmit.bind(this);
          // this.isMount = false;
          this._onSearchContent = this._onSearchContent.bind(this);
          this._onClickNext = this._onClickNext.bind(this);
          this._onClickAdd = this._onClickAdd.bind(this);
          this.handleGetData = this.handleGetData.bind(this);
          this.onChangePage = this.onChangePage.bind(this);
          this._onCheckDetail = this._onCheckDetail.bind(this);
          this._onChangeFilter = this._onChangeFilter.bind(this);
          this._onChangeListAmount = this._onChangeListAmount.bind(this);
          this._setForm = this._setForm.bind(this);
          this._getSubjectOptions= this._getSubjectOptions.bind(this);
          this.onEdit = this.onEdit.bind(this);
          this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
          this._getClassesOptions= this._getClassesOptions.bind(this);
          this._getPeriodsOptions= this._getPeriodsOptions.bind(this);

          const {user} = this.props;
          const { organizations_id, school_unit} = user;
          const { units_id, levels_id} = school_unit;
          this.state = {
            page: 1,
            selected: {},
            content: initialContent,
            unchecked: {},
            filters:{},
            list:[],
            form: {
              value: {
                organizations_id,
                levels_id,
                absen_bidangstudy: [
                  {
                    students_id: '',
                    period: '',
                    classrooms_id: '',
                    date: '',
                    status: '',
                    description: '',
                  }
                ],
              },
              error: {
                classrooms: '',
                levels: '',
                absen_bidangstudy:''
              },
            },
            param:{},
            
          };
          this._handleFormChange = this._handleFormChange.bind(this);
          this._onSearch = this._onSearch.bind(this);
          this._getParamOptions = this._getParamOptions.bind(this);
        }
      
       componentDidMount() {
        const { location, getLevels, getclassrooms, user } = this.props;
        const { state = {} } = location;
        const {isEdit =  false, data, date, subject_id, classrooms_id} = state;
        // const { id } = state.data || '';
        const { school_unit } = user;
        const { levels_id, units_id} = school_unit;
          const { content } = this.state;
          const paramTypes = ['classrooms','levels', 'classrooms'];

          console.log(location)
          paramTypes.forEach((type) => {
            this._getParamOptionsAcademics(type);
          });
          if (isEdit) {
            // this.setState({id:id})
            this._setForm(date, subject_id, classrooms_id);
          // } else
          // this.handleGetData();
          // this.isMount = true;
          // if (isEmpty(content.list)) {
          //   this._onSearchContent({ page: 1 }, date, subject_id);
          // }
        }
          this._getSubjectOptions({ filters: { units_id: [units_id] }});      
          this._getClassesOptions({ filters: { levels_id: [levels_id] }});
          this._getPeriodsOptions();       

        }
      
        componentDidUpdate(prevProps) {
          const { location } = this.props;
          const { state = {} } = location;
          const {isEdit =  false, data} = state;
          const { id } = state.data || '';
         console.log(state)
          if ((!isEdit || !id) && !isEqual(prevProps, this.props)) {
            this._onSearchContent({ page: 1 });
          }
        }

        async _getClassesOptions(filters={}) {
          const { getClassesOptions, user } = this.props;
          const {school_unit} = user;
          const { levels_id } = school_unit;
          filters.levels_id = levels_id;

          const res = await getClassesOptions(filters);
          // console.log(res)
          this.setState(prevState => ({
            ...prevState,
            param: {
              ...prevState.param,
              classes: res,
            },
          }));
         
        }

        async _getPeriodsOptions(filters={}) {
          const { getPeriodsOptions ,user} = this.props;
          const { organizations_id, school_unit } = user;
          const { units_id, levels_id } = school_unit
          const res = await getPeriodsOptions(filters);
          filters.organizations_id = organizations_id;

          this.setState(prevState => ({
            ...prevState,
            param: {
              ...prevState.param,
              periods: res,
            },
          }));
          
        }

        async _setForm(date, subject_id, classrooms_id) {
          const { handleGetAddAttendanceStudyField, user } = this.props;
          const { organizations_id } = user;
          try {
            const payload = await handleGetAddAttendanceStudyField({ date, subject_id, classrooms_id });
            const { classes, levels_id, classes_id } = payload;
            // this._getClassesOptions({ filters: { levels_id: [levels_id] }} );
            // this._getParamOptionsAcademics( 'classrooms',  { classes_id: classes_id, organizations_id } );            
            this.setState({
              form: {
                value: {
                  // id: payload.id,
                  date: payload.date,
                  period: payload.period,
                  subject_id: payload.subject_id,
                  classrooms_id: payload.classrooms_id,
                  competitions: payload.competitions,
                  levels_id: payload.levels_id,
                  classes_id:payload.classes_id,
                  organizations_id:organizations_id,
                  description_competitions: payload.description_competitions,
                  absen_bidangstudy: map(payload.absen_bidangstudy, detail => ({
                    name: detail.name,
                    students_id: detail.students_id,
                    nis: detail.nis,
                    status: detail.status,
                    description: detail.description,
                  
                  })),
                },
                error: {
                  absen_bidangstudy: [],
                },
              },
            });
          } catch (err) {
            // err action
          }
        }

        async _onSearchContent(params = {}, date, subject_id) {
          const { form } = this.state;
          const { value } = form;
          
            try {
              const { handleListStudentAddAttendanceStudyField,handleGetAddAttendanceStudyField, user } = this.props;
              const { school_unit, workingUnit, id, user_group, organizations_id } = user;
              const { levels_id, units_id } = school_unit;

              const result = await handleListStudentAddAttendanceStudyField({ ...params,units_id,  organizations_id, classrooms_id: value.classrooms_id  });
              // if (this.isMount) {
              const payload = await handleGetAddAttendanceStudyField({ date: value.date, classrooms_id: value.classrooms_id, organizations_id });
              if (result) {
                result.forEach(item => {
                  if (!item.description) {
                    item.description = '';
                  }
                  if (!item.status) {
                    item.status = 'HADIR';
                  }
                });
              } 
              this.setState(prevState => ({
                ...prevState,
                form: {
                  ...prevState.form,
                  value: {
                    ...prevState.form.value,
                    
                    absen_bidangstudy: payload.absen_bidangstudy.length > 0 ? map(payload.absen_bidangstudy, detail => ({
                      name: detail.name,
                      students_id: detail.students_id,
                      nis: detail.nis,
                      status: detail.status,
                      description: detail.description,
                    
                    }))
                    :
                    result,
                  }
                }
                
              }));
              
            } catch (err) {
              // if (this.isMount) {
                this.setState(prevState => ({
                  ...prevState,
                  form: {
                    ...prevState.form,
                    value: {
                      ...prevState.form.value,
                      absen_bidangstudy: [],
                    }
                  }
                }));
                
            }
            // }
        }

        async _getParamOptionsAcademics(type, filters={}) {
          const { getParamOptionsAcademics, user } = this.props;
          const { organizations_id } = user;

          if (type === 'classrooms' || type === 'classrooms') {
            filters.organizations_id = organizations_id;
          } else {
            delete filters.organizations_id;
          }
          const res = await getParamOptionsAcademics(filters, type);
          this.setState(prevState => ({
            ...prevState,
            param: {
              ...prevState.param,
              [type]: res,
            },
          }));
        }

        _onChangeListAmount(event) {
          const { keywords } = this.state;
          this.setState({
            listAmount: Number(event.target.value),
          });
          this._onSearchContent({ limit: event.target.value, keywords, page: 1 });
        }

        async onEdit() {
          const { history, handleEditAddAttendanceStudyField } = this.props;
          const { form } = this.state;
          const { value } = form;
          const res = await handleEditAddAttendanceStudyField(value);        
          history.push('/dashboard/kesiswaan/absensi-siswa/isi-perbidang-study');
        }

        _onChangeFilter(e) {
          const { user } = this.props;
          const { organizations_id } = user;
          const { target } = e;
          const { value, name } = target;
          this.setState(prevState => ({
            ...prevState,
            filters: {
              ...prevState.filters,
              [name]: value,
            },
          }), () =>{
            if (name === 'levels_id') {
              this._getParamOptions( 'classrooms',  { levels_id: value, organizations_id } );
            } this._onSearchContent()});
        
        }
        _onChangePage(page) {
          const { listAmount, keywords } = this.state;
          const offset = listAmount * (page - 1);
          // this.handleGetData({
          this._onSearchContent({
            limit: listAmount,
            keywords,
            offset,
            page,
          });
        }

        async handleGetData(payload = {}) {
          const { handleListStudentAddAttendanceStudyField, handleListAnnouncementStudent, user } = this.props;
          const { units_id, workingUnit, id, user_group, organizations_id } = user;
          let unit_id = units_id;
      
          if (unit_id === null && workingUnit && workingUnit.id) {
            unit_id = workingUnit.id;
          }
          console.log(user)
          // if(user_group.id === 2){
            const res = await handleListStudentAddAttendanceStudyField({...payload, user_id: id, organizations_id});
      
          this.setState(prevState => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                absensiharian: res,
              }
            }
          }));
      
         
          
        }
        _handleFormChange(event) {
            const {
              name,
              value,
            } = event.target;
            this.setState(prevState => ({
              form: {
                value: {
                  ...prevState.form.value,
                  [name]: value,
                },
                error: {
                  ...prevState.form.error,
                  [name]: '',
                },
              },
            }));
          }
        
          _onSearch() {
            const { form } = this.state;
            const error = validateRequiredFields(form.value, ['year']);
            this.setState(prevState => ({
              form: {
                value: prevState.form.value,
                error,
              },
            }));
            if (isEmpty(error)) {
              this.setState({ filtered: true });
            }
          }


        _onFormChange(event) {
          const { user } = this.props;
          const { organizations_id } = user;
          const {
            name,
            value,
            dataset,
            checked,
            type,
          } = event.target;
          const {
            inputType = 'text', inputArray = false, arrayPosition = 0,
            fieldName,
          } = dataset;
          this.setState((prevState) => {
            let newList = [];
            let newListError = [];
            let formattedValue = value;
      
            if (inputType === 'number') {
              formattedValue = normalizeAmount(value);
            }
            if (inputArray) {
              if (type === 'checkbox') {
                formattedValue = checked ? value : '';
              }
              newList = prevState.form.value[fieldName];
              newListError = prevState.form.error[fieldName] || [];
              newList[arrayPosition][name] = formattedValue;
              if (name === 'code_of_account') {
                newList[arrayPosition].isCredit = value.type;
              }
              if (!isEmpty(newListError[arrayPosition])) {
                newListError[arrayPosition][name] = '';
              }
            }
            return {
              form: {
                value: {
                  ...prevState.form.value,
                  ...(inputArray
                    ? { [fieldName]: newList }
                    : { [name]: formattedValue }),
                },
                error: {
                  ...prevState.form.error,
                  ...(inputArray
                    ? { [fieldName]: newListError }
                    : { [name]: '' }),
                },
              },
            };
          }, () => {
            if (name === 'classrooms_id' || name === 'date' || name === 'subject_id' ) {
              this._onSearchContent()
            }
            // if (name === 'levels_id') {
            //   this._getClassesOptions({ filters: { levels_id: [value] }} );
            // }
            if (name === 'classes_id') {
              this._getParamOptionsAcademics( 'classrooms', {classes_id: value, organizations_id } );
              this._getSubjectOptions({ filters: {classes_id: value} } );
              
            }
          });
        }
        _onClickUpload() {
          const { history } = this.props;
          history.push('/dashboard/kesiswaan/absensi-siswa/upload-absensi-harian');
        }
      
        _onClickAdd(data) {
          const { form } = this.state;
          const { plafon } = form.value;
          
          plafon.push({
            tipePlafon: '',
            // divisi: '',
            nominal: 0,
            //period:'',
          });
          
          this.setState(prevState => ({
            form: {
              value: {
                ...prevState.form.value,
                plafon,
              },
              error: {
                ...prevState.form.error,
              }
            }
          }));
          console.log(plafon)
        }
      
        onChangePage(page) {
          const { listAmount, keywords } = this.state;
          const offset = listAmount * (page - 1);
          this.handleGetData({
            limit: listAmount,
            keywords,
            offset,
            page,
          });
        }
      
        async _getSubjectOptions(filters={}) {
          const { getSubjectOptions, user } = this.props;
          const { organizations_id, school_unit } = user;
          const { levels_id, units_id } = school_unit;
          const res = await getSubjectOptions(filters);
          filters.units_id = units_id;
          // console.log(res)
          this.setState(prevState => ({
            ...prevState,
            param: {
              ...prevState.param,
              subject: res,
            },
          }));
         
        }
      

        _onCheckDetail(event) {
          const { target } = event;
          const { value, checked } = target;
          const { budgets, selectedBudgetDetails } = this.props;
          const { selected, unchecked } = this.state;
          let newState = { ...this.state };
      
          newState = this._setNewState(newState, checked, value);
      
          this.setState({
            ...newState
          });
        }
      
        async onSubmit() {
          const { history, handleSaveAddAttendanceStudyField } = this.props;
          const { form } = this.state;
          const { value } = form;        
          let canSaveA = false;
          const dataForm = form.value;
          // if(dataForm.name && dataForm.publish_date && dataForm.tgl_selesai && dataForm.levels && dataForm.classrooms){
          //   canSaveA = true;
          // } else {
          //   canSaveA = false;
          // }
          // if (canSaveA == false){
          //   alert("Harap lengkapi data form. Bagian yang harus diisi ditandai dengan tanda bintang (*).");
          // } else {
            await handleSaveAddAttendanceStudyField(value);
            history.push('/dashboard/kesiswaan/absensi-siswa/isi-perbidang-study');
          // }
        }
      
        
        _onClickNext() {
            window.scrollTo(0, 0);
            this.setState(prevState => ({
              page: prevState.page + 1,
            }));
          }
          async _getParamOptions(type, filters={}) {
            const {getParamOptions, user} = this.props;
            const { organizations_id } = user;
  
            if (type === 'classes' || type === 'classrooms') {
              filters.organizations_id = organizations_id;
            } else {
              delete filters.organizations_id;
            }
            const res = await getParamOptions(filters, type);
            this.setState(prevState => ({
              ...prevState,
              param: {
                ...prevState.param,
                [type]: res,
              },
            }));
          }
      
        render() {
          const { form, page, content, param} = this.state;
          const {plafon} = form.value;
          const { classrooms = {} } = this.props;
          const {absen_bidangstudy} = form.value;
          const academic_year = [
            { label: '2019', value: '2019'},
            { label: '2020', value: '2020'},
            { label: '2021', value: '2021'},
          ]
          return (
           

        <div className="manage-registration">
          <div className="absensi-rekap__custom-form">
            <div className="absensi-rekap__custom-form-row">
              <div className="absensi-rekap__custom-form-row__field">
              
              <Select
                  label="Tahun Ajaran"
                  name="period"
                  onChange={this._onFormChange}
                  placeholder="Pilihan"
                  data={param.periods}
                  value={form.value.period}
                  error={form.error.period || ''}
                />
                </div>
              <div className="absensi-rekap__custom-form-column__field">
                <Select
                name="levels_id"
                label="Unit"
                onChange={this._onFormChange}
                data={param.levels}
                disabled
                value={form.value.levels_id}
                placeholder={'Pilihan'}
                error={form.error.levels_id || ''}
              />
              </div>
          </div>
          <div className="absensi-rekap__custom-form-row">
              <div className="absensi-rekap__custom-form-row__field">
                <Select
                  name="classes_id"
                  label="Tingkat Kelas"
                  onChange={this._onFormChange}
                  data={param.classes}
                  placeholder={'Pilihan'}
                  value={form.value.classes_id}
                  error={form.error.classes_id || ''}
                />
              </div>
              <div className="absensi-rekap__custom-form-column__field">
            
              <Select
                placeholder="Pilih Kelas"
                label = "Kelas"
                name="classrooms_id"
                value={form.value.classrooms_id}
                data={param.classrooms}
                onChange={this._onFormChange}
              />
                
              </div>
          </div>
          <div className="absensi-rekap__custom-form-row">
                        <div className="absensi-rekap__custom-form-row__field">
                        <Input
                            type="date"
                            name="date"
                            label="Tanggal"
                            onChange={this._onFormChange}
                            value={form.value.date}
                            error={form.error.date || ''}
                        />
                        </div>  
                      
                        <div className="absensi-rekap__custom-form-column__field">
                        <Select
                            label="Mata Pelajaran"
                            name="subject_id"
                            placeholder="Pilihan"
                            data={param.subject}
                            onChange={this._onFormChange}
                            value={form.value.subject_id}
                            error={form.error.subject_id|| ''}
                         />
                  </div>
                  </div>
                  <div className="absensi-rekap__custom-form-row">
                        <div className="absensi-rekap__custom-form-row__field">
                        <Input
                          type="text"
                          label="Kompetisi yang diajar"
                          name="competitions"
                          style={{backgroundColor:'yellow'}}
                          onChange={this._onFormChange}
                          placeholder="Pilihan"
                          value={form.value.competitions}
                          error={form.error.competitions || ''}
                        />
                        </div>  
                    </div>
                        <div className="absensi-rekap__custom-form-row">
                        <div className="absensi-rekap__custom-form-row__field">
                        <Input
                          label="Keterangan"
                          name="description_competitions"
                          onChange={this._onFormChange}
                          placeholder="Pilihan"
                          value={form.value.description_competitions}
                          error={form.error.description_competitions || ''}
                        />
                       
                        </div>  
                        </div>
                        
                        
                </div>
             
              <br/>

              <div className="fund-request__content">
      <table className="table">
        <thead>
          <tr>
            {
                map(ABSENSI_PERBIDANG_STUDI_TABLE_FIELDS.label, (field, idx) => (
                  <th key={`table_th_${idx}`}>{field}</th>
              ))
              }
          </tr>
        </thead>
        <tbody>
          {
              map(absen_bidangstudy, (list, idx) => (
                <tr key={`budget_row_${idx}`}>
                  {
                  map(ABSENSI_PERBIDANG_STUDI_TABLE_FIELDS.value, (field, fieldIdx) => {
                    if (fieldIdx === 0) {
                      return (
                        <td key={`table_index_${fieldIdx}_${idx}`}>{(idx + 1)}</td>
                      );
                    }
                    if (field.attribute === 'status') {
                      return (
                        <td key={`table_${fieldIdx}_${idx}`}>
                        <Input
                        type="checkbox"
                        name="status"
                        data-input-array={true}
                        data-array-position={idx}
                        data-field-name='absen_bidangstudy'
                        onChange={this._onFormChange}
                        checked={!field.value  || field.value === 'HADIR'? list.status === 'HADIR' : list.status === field.value}
                        value={field.value}
                        // ref={this._setRef}
                      />  </td>
                          // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                      );
                    }
                    if (field.attribute === 'description') {
                      return (
                        <td key={`table_${fieldIdx}_${idx}`}><Input
                        type="text"
                        name="description"
                        data-input-array={true}
                        data-array-position={idx}
                        data-field-name='absen_bidangstudy'
                        onChange={this._onFormChange}
                        value={list.description}
                      />  </td>
                          // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                      );
                    }

                    return (
                      <td className={(field.type === 'number') ? 'nominal' : ''} key={`table_${fieldIdx}_${idx}`}>
                        {formatData(list, field)}
                      </td>
                    );
                  })
                  }
                </tr>
              ))
            }
        </tbody>
      </table>
    </div>
    {this.state.date?
              <Button
                type="button"
                title="Update"
                onClick={this.onEdit}
              />:
              <Button
                type="button"
                title="Simpan"
                onClick={this.onSubmit}
              />}
        </div>

          );
        }
}
TambahAbsenPerbidangStudy.propTypes = {
    
  handleGetclassrooms: PropTypes.func,
  history: PropTypes.object.isRequired,
};
TambahAbsenPerbidangStudy.defaultProps = {
    
  handleGetclassrooms: noop,
};

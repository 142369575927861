import { connect } from 'react-redux';
import RealizationForm from '../../../../views/Finance/Budgeting/MonthlyActivityPlan/RealizationForm.finance.view';
import { addRealization, getDetailRealization, editRealization } from '../../../../states/thunks/finance.thunk';
import { getCoa } from '../../../../states/thunks/common.thunk';

function mapStateToProps(state) {
  return {
    coa: state.coa,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetCoa: payload => dispatch(getCoa(payload)),
    handleGetDetailRealization: payload => dispatch(getDetailRealization(payload)),
    handleAddRealization: (payload, goBack) => dispatch(addRealization(payload, goBack)),
    handleEditRealization: (payload, goBack) => dispatch(editRealization(payload, goBack)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RealizationForm);

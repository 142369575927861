import * as api from '../../utils/api.util';
import * as commonActions from '../actions/common.action';
import { errorAlert, successAlert, successAlertReload, successAlertBack } from '../../utils/alert.util';
import { STUDENT_RESULT_MOCK, KLAPPER_RESULT_MOCK } from '../../constants/student.constant';
import {manageGetRegistration} from '../../utils/middleware.util'

export function manageRegistration(payload = {}, push) {
  // return async (dispatch) => {
  //   try {
  //     console.log(payload)
  //     dispatch(commonActions.setLoading({ show: true }));
  //     await api.STUDENTS_POST(manageGetRegistration(payload,false),'/save');
  //     push('/admission/results');
  //   } catch (err) {
  //     errorAlert({
  //       title: 'Registrasi Gagal',
  //     });
  //   } finally {
  //     dispatch(commonActions.setLoading({ show: false }));
  //   }
  // };
  return async (dispatch, getState) => {
    try {
        const storeState = getState();
        const { user } = storeState;
        const { workingUnit, prm_school_units_id, user_group } = user;
        let unit_id = prm_school_units_id;

       
        if (workingUnit && workingUnit.id) {
            unit_id = workingUnit.id;
        }

        const formatted = { ...payload };
        // formatted.students = formatted.students.map(item => item.value);
        // formatted.classes = formatted.classes.map(item => item.value);
        const res = await api.ACADEMICS(payload, 'students/profile/save');
        // console.log(res);
        return res.data || [];
    } catch (err) {
        throw err;
    }
};
}

export function getManageRegistration(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { id } = payload;
      const res = await api.ACADEMICS({ id }, 'students/profile/get');
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
};
}

export function editManageRegistration(payload) {
  return async (dispatch, getState) => 
  {
      try {
        const storeState = getState();
        const { user } = storeState;
        const { workingUnit, prm_school_units_id } = user;
        let unit_id = prm_school_units_id;

        if (workingUnit && workingUnit.id) {
            unit_id = workingUnit.id;
        }
        const formatted = { ...payload };

        const res = await api.ACADEMICS({create_user_id:user.id, create_user_name:user.name, ...formatted}, 'students/profile/edit');
          return res || [];
      } catch (err) {
          throw err;
      }
  };
}

export function uploadImageFileStudents(payload = {}, goBack) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { file } = payload;
      const requestBody = new FormData();
      requestBody.append('file', file);
      const res = await api.ACADEMICS(requestBody, 'students/profile/upload');
      return res.data;
    } catch (err) {
      errorAlert({
        title: 'Tambah File Gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
};
}

export function getStudent() {
  return async () => {
    try {
      return {
        result: STUDENT_RESULT_MOCK,
        currentPage: 1,
        totalPage: 1,
        total: 1,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function getKlapper() {
  return async () => {
    try {
      return {
        result: KLAPPER_RESULT_MOCK,
        currentPage: 1,
        totalPage: 1,
        total: 1,
      };
    } catch (err) {
      throw err;
    }
  };
}

export function uploadStudent(payload = {}, goBack) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { file } = payload;
      const requestBody = new FormData();
      requestBody.append('file', file);
      // await api.FILE(requestBody, '/upload');
      goBack();
    } catch (err) {
      errorAlert({
        title: 'Tambah File Gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}


/*
UPLOAD DOCUMENT START HERE
*/
export function uploadDocumentFile(payload = {}, goBack) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const { file, periode, head } = payload;
      const requestBody = new FormData();
      requestBody.append('periode', periode);
      requestBody.append('head', head);
      requestBody.append('file', file);
      const res = await api.BUDGET(requestBody, '/detail/upload');
      
      if(res.message=="Failed"){
        errorAlert({
          title: res.data,
        });
      }else{
        successAlertBack({
          title: res.message +" & document",
          goBack
        });
      }

      //goBack();
    } catch (err) {
      errorAlert({
        title: 'Tambah File Gagal',
      });
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function deleteDocumentFile(id) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const requestBody = new FormData();
      requestBody.append('unique_id', id);
      await api.BUDGET(requestBody, '/head/delete');
      return id;
    } catch (err) {
      errorAlert({
        title: 'Hapus File Gagal',
      });
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}
/*
UPLOAD DOCUMENT END HERE
*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop, map, isEqual, has, find } from 'lodash';
import StudentReportFilter from '../../../components/Filter/StudentReport.filter.component';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { ABSENSI_HARIAN } from '../../../constants/student.constant';
import { ABSENSI_HARIAN_TABLE } from '../../../constants/student.constant';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted, normalizeAmount, formatData } from '../../../utils/transformer.util';
import { Button, Input, Checkbox, Pagination, Select } from '../../../components/base/index';
import ParameterDeskForm from './components/ParameterDeskForm.component';
import TableControl from './components/TableControl.component';
import TableRangeNon from './components/TableRangeNon.component';
import { BOBOT_NILAI_TABLE_FIELDS } from '../../../constants/kurikulum/kurikulum.constant';
import IsiBobotForm3 from './components/IsiBobotForm3.component';


const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class IsiBobotNilai extends Component {
        constructor(props) {
          super(props);
          this._onClickUpload = this._onClickUpload.bind(this);
          this._onFormChange = this._onFormChange.bind(this);
          this.onSubmit = this.onSubmit.bind(this);
          // this.isMount = false;
          this._onSearchContent = this._onSearchContent.bind(this);
          this._onClickNext = this._onClickNext.bind(this);
          this._onClickAdd = this._onClickAdd.bind(this);
          this._onClickAddRange = this._onClickAddRange.bind(this);
          this.handleGetData = this.handleGetData.bind(this);
          this.onChangePage = this.onChangePage.bind(this);
          this._onCheckDetail = this._onCheckDetail.bind(this);
          this._onChangeFilter = this._onChangeFilter.bind(this);
          this._onChangeListAmount = this._onChangeListAmount.bind(this);
          this._setForm = this._setForm.bind(this);
          this.onEdit = this.onEdit.bind(this);
          this._getTypeExam= this._getTypeExam.bind(this);
          this._getTypeFormula= this._getTypeFormula.bind(this);
          this._onDeleteList = this._onDeleteList.bind(this);
          this._onDeleteListRange = this._onDeleteListRange.bind(this);
          this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
          this._getPeriodsOptions= this._getPeriodsOptions.bind(this);
          
          const {user} = this.props;
          const { school_unit } = user;
          const { levels_id } = school_unit;

          this.state = {
            content: initialContent,
            unchecked: {},
            filters:{},
            list:[],
            form: {
              value: {
                levels_id,
                students_id:'',
                bobotnilai: [
                  {
                    bobot_nilai: '',
                    detail_nilai:'',
                    poin: '',
                  }                                      
                ],
                rangenilai: [
                  {
                    id_range: '',
                    range_awal:'',
                    range_akhir: '',
                    desc_1:'',
                    desc_2: '',

                  }                                      
                ],
              },
              error: {
                bobotnilai: '',
                rangenilai: '',
      
              },
            },
            param:{},
          };
        
      
          this._handleFormChange = this._handleFormChange.bind(this);
          this._onSearch = this._onSearch.bind(this);
          this._getParamOptions = this._getParamOptions.bind(this);
        }
      
       componentDidMount() {
        const { location, getLevels, getClasses, user } = this.props;
        const { state = {} } = location;
        const {isEdit =  false, data} = state;
        const { id } = state.data || '';
          const { content } = this.state;
          const paramTypes = ['classes','levels', 'classrooms'];
          paramTypes.forEach((type) => {
            this._getParamOptionsAcademics(type);

          });
          console.log('test state ' + state)
          
            this.setState({id:1})
            this._setForm(data);
          
          this.isMount = true;
          
          this._getTypeExam();       
          this._getTypeFormula();
          this._getPeriodsOptions(); 
        }
      
        componentDidUpdate(prevProps) {
          const { location } = this.props;
          const { state = {} } = location;
          const {isEdit =  false, data} = state;
          const { id } = state.data || '';
         console.log(state)
          if ((!isEdit || !id) && !isEqual(prevProps, this.props)) {
            this._onSearchContent({ page: 1 });
          }
        }

        async _getPeriodsOptions() {
          const { getPeriodsOptions } = this.props;
          const res = await getPeriodsOptions();
          this.setState(prevState => ({
            ...prevState,
            param: {
              ...prevState.param,
              periods: res,
            },
          }));
          
        }

        async _setForm(id) {
          const { handleGetAttendance } = this.props;
          try {
            const payload = await handleGetAttendance({ id });
         
        // console.log(payload)
            this.setState({
              filters: {
                classrooms_id: payload.classrooms_id,
                period: payload.period,
                date: payload.date,
              },
              form: {
                value: {
                  id: payload.id,
                  date: payload.date,
                  period: payload.period,
                  absensiharian: map(payload.absensiharian, detail => ({
                    classrooms_id: detail.classrooms_id,
                    name: detail.name,
                    date: detail.date,
                    students_id: detail.students_id,
                    status: detail.status,
                    description: detail.description,
                   
                  })),
                },
                error: {
                  absensiharian: [],
                },
              },
            });
          } catch (err) {
            // err action
          }
        }

        async _onSearchContent(params = {}) {
          const { filters } = this.state;
          
            try {
              const { handleListStudentAttendance, user } = this.props;
              const { organizations_id, school_unit } = user;
              const { units_id } = school_unit;
              const result = await handleListStudentAttendance({ ...params, organizations_id, units_id, filters });
              // if (this.isMount) {
                if (result) {
                  result.forEach(item => {
                    if (!item.description) {
                      item.description = '';
                    }
                  });
                } 
                this.setState(prevState => ({
                  ...prevState,
                  form: {
                    ...prevState.form,
                    value: {
                      ...prevState.form.value,
                      absensiharian: result,
                    }
                  }
                }));
                
              // }
            } catch (err) {
              // if (this.isMount) {
                this.setState(prevState => ({
                  ...prevState,
                  form: {
                    ...prevState.form,
                    value: {
                      ...prevState.form.value,
                      absensiharian: [],
                    }
                  }
                }));
                
            }
            // }
        }

        _onChangeListAmount(event) {
          const { keywords } = this.state;
          this.setState({
            listAmount: Number(event.target.value),
          });
          this._onSearchContent({ limit: event.target.value, keywords, page: 1 });
        }

       
        _onChangeFilter(e) {
          const { target } = e;
          const { value, name } = target;
          const { user } = this.props;
          const { organizations_id } = user;
          this.setState(prevState => ({
            ...prevState,
            filters: {
              ...prevState.filters,
              [name]: value,
            },
          }), () =>{
            if (name === 'levels_id') {
              this._getParamOptionsAcademics( 'classrooms', { filters: { levels_id: value, organizations_id }} );
            } this._onSearchContent()});
        
        }
        _onChangePage(page) {
          const { listAmount, keywords } = this.state;
          const offset = listAmount * (page - 1);
          // this.handleGetData({
          this._onSearchContent({
            limit: listAmount,
            keywords,
            offset,
            page,
          });
        }

        async handleGetData(payload = {}) {
          const { handleListStudentAttendance, handleListAnnouncementStudent, user } = this.props;
          const { units_id, workingUnit, id, user_group, profile, organizations_id } = user;
          let unit_id = units_id;
      
          if (unit_id === null && workingUnit && workingUnit.id) {
            unit_id = workingUnit.id;
          }
          // if(user_group.id === 2){
            const res = await handleListStudentAttendance({...payload, user_id: id, organizations_id});
      
          this.setState(prevState => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                absensiharian: res,
              }
            }
          }));
        }

        async _getParamOptionsAcademics(type, filters={}) {
          const { getParamOptionsAcademics, user } = this.props;
          const { organizations_id, school_unit } = user;
          const { levels_id } = school_unit;
          if (type === 'classrooms' || type === 'classrooms') {
            filters.organizations_id = organizations_id;
            filters.levels_id = levels_id;
          } else {
            delete filters.organizations_id;
          }
          const res = await getParamOptionsAcademics(filters, type);
          this.setState(prevState => ({
            ...prevState,
            param: {
              ...prevState.param,
              [type]: res,
            },
          }));
        }

        _handleFormChange(event) {
            const {
              name,
              value,
            } = event.target;
            this.setState(prevState => ({
              form: {
                value: {
                  ...prevState.form.value,
                  [name]: value,
                },
                error: {
                  ...prevState.form.error,
                  [name]: '',
                },
              },
            }));
          }
        
          _onSearch() {
            const { form } = this.state;
            const error = validateRequiredFields(form.value, ['year']);
            this.setState(prevState => ({
              form: {
                value: prevState.form.value,
                error,
              },
            }));
            if (isEmpty(error)) {
              this.setState({ filtered: true });
            }
          }


          _onFormChange(event) {
            const {
              name,
              value,
              dataset,
              checked,
              type,
            } = event.target;
            const {
              inputType = 'text', inputArray = false, arrayPosition = 0,
              fieldName,
            } = dataset;
            this.setState((prevState) => {
              let newList = [];
              let newListError = [];
              let formattedValue = value;
        
              if (inputType === 'number') {
                formattedValue = normalizeAmount(value);
              }
              if (inputArray) {
                if (type === 'checkbox') {
                  formattedValue = checked;
                }
                newList = prevState.form.value[fieldName];
                newListError = prevState.form.error[fieldName];
                newList[arrayPosition][name] = formattedValue;
                if (name === 'code_of_account') {
                  newList[arrayPosition].isCredit = value.type;
                }
                if (!isEmpty(newListError[arrayPosition])) {
                  newListError[arrayPosition][name] = '';
                }
              }
              return {
                form: {
                  value: {
                    ...prevState.form.value,
                    ...(inputArray
                      ? { [fieldName]: newList }
                      : { [name]: formattedValue }),
                  },
                  error: {
                    ...prevState.form.error,
                    ...(inputArray
                      ? { [fieldName]: newListError }
                      : { [name]: '' }),
                  },
                },
              };
            });
          }


        _onClickUpload() {
          const { history } = this.props;
          history.push('/dashboard/kesiswaan/absensi-siswa/upload-absensi-harian');
        }
      
     
      
        onChangePage(page) {
          const { listAmount, keywords } = this.state;
          const offset = listAmount * (page - 1);
          this.handleGetData({
            limit: listAmount,
            keywords,
            offset,
            page,
          });
        }
      
        

        _onCheckDetail(event) {
          const { target } = event;
          const { value, checked } = target;
          const { budgets, selectedBudgetDetails } = this.props;
          const { selected, unchecked } = this.state;
          let newState = { ...this.state };
      
          newState = this._setNewState(newState, checked, value);
      
          this.setState({
            ...newState
          });
        }
      
        async _getTypeExam() {
          const { getTypeExam } = this.props;
          const res = await getTypeExam();
          // console.log(res)
          this.setState(prevState => ({
            ...prevState,
            param: {
              ...prevState.param,
              type_exam: res,
            },
          }));
         
        }

        async _getTypeFormula() {
          const { getTypeFormula } = this.props;
          const res = await getTypeFormula();
          // console.log(res)
          this.setState(prevState => ({
            ...prevState,
            param: {
              ...prevState.param,
              type_formula: res,
            },
          }));
         
        }

       
        async onSubmit() {
          const { history, handleSaveBobotNilai } = this.props;
          const { form } = this.state;
          const { value } = form;        
          let canSaveA = false;
          const dataForm = form.value;
            await handleSaveBobotNilai(value);
            history.push('/dashboard/kurikulum/bobot-penilaian/pengisian-bobot-penilaian');
            history.go(0)
        }

        async onEdit() {
          const { history, handleEditBobotNilai } = this.props;
          const { form } = this.state;
          const { value } = form;
          let canSaveA = false;
          let canSaveB = false;
          const dataTabel = value.pelajaran;
          const dataForm = form.value;
          let a;
            await handleEditBobotNilai(value);
            history.push('/dashboard/kurikulum/ulangan/daftar-nilai');
          // }
         
        }
      
      
        
        
        _onClickNext() {
            window.scrollTo(0, 0);
            this.setState(prevState => ({
              page: prevState.page + 1,
            }));
          }
        async _getParamOptions(type, filters={}) {
          const {getParamOptions, user} = this.props;
          const { organizations_id } = user;
          if (type === 'classes' || type === 'classrooms') {
            filters.organizations_id = organizations_id;
          } else {
            delete filters.organizations_id;
          }
          const res = await getParamOptions(filters, type);
          this.setState(prevState => ({
            ...prevState,
            param: {
              ...prevState.param,
              [type]: res,
            },
          }));
        }

        _onDeleteList(idx) {
          this.setState((prevState) => {
            const { bobotnilai } = prevState.form.value;
            const list = prevState.form.value.bobotnilai;
            list.splice(idx, 1);
            return {
              ...prevState,
              bobotnilai: list
            };
          });
        }

        _onDeleteListRange(idx) {
          this.setState((prevState) => {
            const { rangenilai } = prevState.form.value;
            const list = prevState.form.value.rangenilai;
            list.splice(idx, 1);
            return {
              ...prevState,
              rangenilai: list
            };
          });
        }

        _onClickAdd(data) {
          const { form } = this.state;
          const { bobotnilai } = form.value;
          
          bobotnilai.push({
            bobot_nilai: '',
            detail_nilai:'',
            poin: '',
          });
          
          this.setState(prevState => ({
            form: {
              value: {
                ...prevState.form.value,
                bobotnilai,
              },
              error: {
                ...prevState.form.error,
              }
            }
          }));
        }

        _onClickAddRange(data) {
          const { form } = this.state;
          const { rangenilai } = form.value;
          
          rangenilai.push({
            id_range: '',
            range_awal:'',
            range_akhir: '',
            desc_1:'',
            desc_2: '',

          });
          
          this.setState(prevState => ({
            form: {
              value: {
                ...prevState.form.value,
                rangenilai,
              },
              error: {
                ...prevState.form.error,
              }
            }
          }));
        }

      
      
        render() {
            const {param, form,
                   page, list = {}, selected, checkedAll, content, filters, listAmount } = this.state;
         
            const {bobotnilai, rangenilai} = form.value;
            const {
              classrooms_id = '',
              date='',
              period='',
              levels_id='',
            } = filters;
            const {  location } = this.props;
            const loadingProps = { show: content.loading };
            const academic_year = [
              { label: '2019', value: '1'},
              { label: '2020', value: '2'},
              { label: '2021', value: '3'},
            ]
          
            return (
              <div className="manage-registration">
                 <div className="kode-matpel__custom-form">
          <div className="kode-matpel__custom-form-row">
          <div className="kode-matpel__custom-form-row__field">
                <Select
                    label="Tahun Ajaran"
                    name="period"
                    onChange={this._onFormChange}
                    placeholder="Pilihan"
                    data={param.periods}
                    value={form.value.period}
                    error={form.error.period || ''}
                  />
               
          </div>
           
          
          <div className="kode-matpel__custom-form-column__field">
        
                   <Select
                      type="text"
                      name="levels_id"
                      label="Unit"
                      disabled
                      placeholder="Pilih Unit"
                      data={param.levels}
                      value={form.value.levels_id}
                      onChange={this._onFormChange}
                      error={form.error.levels_id || ''}
                 />
           
          </div>
          </div>
          <div className="kode-matpel__custom-form-row">
          <div className="kode-matpel__custom-form-row__field">
                <Select
                  placeholder="Pilih Kelas"
                  label = "Kelas"
                  name="classrooms_id"
                  value={form.value.classrooms_id}
                  data={param.classrooms}
                  onChange={this._onFormChange}
                />
          </div>
          
          <div className="kode-matpel__custom-form-column__field">
          
                  <Select
                    name="type_of_formula_id"
                    label="Tipe Rumus"
                    onChange={this._onFormChange}
                    placeholder="Pilihan"
                    data={param.type_formula}
                    value={form.value.type_of_formula_id}
                    error={form.error.tipe_rumus || ''}
  
    
                  />
         
           
          </div>
         
          </div>
  
         </div>
  
         <IsiBobotForm3
              form={form}
              onAddList={this._onClickAdd}
              list={bobotnilai}
              onDeleteList={this._onDeleteList}
              onFormChange={this._onFormChange}
              param={param}
              //items={this.state.items} _handleDelete={this.delete.bind(this)}
            />
                  
  
         <div className="student-list">
            <div className="budget__title">
            </div>
  
                   
                    <br></br>
  
                    <br></br>
  
  
                  <div className="result__content">
                    <ParameterDeskForm
                        form={form}
                        onFormChange={this._onFormChange}
                    />
                
                  </div>
                
  
                  <br></br>
                  <div className="result__content">
                    <TableControl
                        form={form}
                        onFormChange={this._onFormChange}
                    />
                  </div>
                
  
                  <div className="result__content">
                    <TableRangeNon
                        form={form}
                        onAddListRange={this._onClickAddRange}
                        list={rangenilai}
                        onDeleteListRange={this._onDeleteListRange}
                        onFormChange={this._onFormChange}
                        param={param}
                    />
                  </div>
                  <div className="fund-request">
              <div className="beginning-balance__button-wrapper">
                {/* <Button
                  type="button"
                  title="Kembali"
                  onClick={this._onClickGenerate}
              /> */}
              <Button
                  type="button"
                  title="Simpan"
                  onClick={this.onSubmit}
              />
              </div>
                </div>
                 
  
                  
          </div>
  
        
              </div>
            );
          }
  }
IsiBobotNilai.propTypes = {
  getParamOptions: PropTypes.func,  
  history: PropTypes.object.isRequired,
  handleGetAttendance: PropTypes.func,
  // handleGetLevels: PropTypes.func,
};
IsiBobotNilai.defaultProps = {
  getParamOptions: noop,
  handleGetAttendance: noop,
  // handleGetLevels: noop,
};
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import budgeting from '../../../assets/img/finance/anggaran.png';
import bookkeeping from '../../../assets/img/bookkeeping/jurnal_bank.png';
import { map } from 'lodash';

export default class Report extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickNIS = this.onClickNIS.bind(this);
    this.onClickDaftarSiswa = this.onClickDaftarSiswa.bind(this);
    this.onClickKlapper = this.onClickKlapper.bind(this);
    this.onClickLaporan8355 = this.onClickLaporan8355.bind(this);
    this.onClickLaporanDispliner = this.onClickLaporanDispliner.bind(this);
    this.onClickLaporanKonseling = this.onClickLaporanKonseling.bind(this);
    this.onClickLaporanAbsensiKls = this.onClickLaporanAbsensiKls.bind(this);
    this.onClickLaporanPersensi = this.onClickLaporanPersensi.bind(this);
    this.onClickSearchSiswa = this.onClickSearchSiswa.bind(this);
  }

  onClickNIS() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/laporan/nis');
  }

  onClickDaftarSiswa() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/laporan/daftar-siswa-kelas');
  }

  onClickKlapper() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/laporan/klapper')
  }

  onClickLaporan8355() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/laporan/laporan-8355')
  }

  onClickLaporanDispliner() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/laporan/laporan-displiner')
  }

  onClickLaporanKonseling() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/laporan/laporan-konseling')
  }

  onClickLaporanAbsensiKls() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/laporan/laporan-absensi-kelas')
  }
  onClickLaporanPersensi() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/laporan/laporan-persensi')
  }
  onClickSearchSiswa() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/laporan/search-siswa')
  }


  render() {
    const { user } = this.props;
    const { permissions } = user.user_group;
    return (
      <div>
        {
          map(permissions, (item) => {
            if(item == "view_form_TU"){
              return(
              <div className="akademi">
                <div className="akademi__list">
                  <button onClick={this.onClickKlapper}>
                    <img src={budgeting} alt="klapper" />
                    <h3>Klapper</h3>
                  </button>
                </div>
                <div className="akademi__list">
                  <button onClick={this.onClickLaporan8355}>
                    <img src={budgeting} alt="laporan8355" />
                    <h3>Laporan 8355</h3>
                  </button>
                </div>
                <div className="akademi__list">
                  <button onClick={this.onClickLaporanDispliner}>
                    <img src={budgeting} alt="laporandispliner" />
                    <h3>Laporan Displiner</h3>
                  </button>
                </div>
                {/* <div className="akademi__list">
                  <button onClick={this.onClickLaporanKonseling}>
                    <img src={budgeting} alt="laporankonseling" />
                    <h3>Laporan Konseling</h3>
                  </button>
                </div> */}
               </div>
              )
            }
            if(item == 'view_bimbingan_konseling') {
              return (
                <div className="akademi">
                  <div className="akademi__list">
                    <button onClick={this.onClickLaporanKonseling}>
                      <img src={budgeting} alt="laporankonseling" />
                      <h3>Laporan Konseling</h3>
                    </button>
                  </div>
                </div>
              )
            }
          })
        }
      </div>
     
    );
  }
}
Report.propTypes = {
  history: PropTypes.object.isRequired,
};

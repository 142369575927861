import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import StudentReportFilter from '../../../components/Filter/StudentReport.filter.component';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted, normalizeAmount } from '../../../utils/transformer.util';
import { Input, Select, Button } from '../../../components/base/index';

export default class LaporanpemasukanUang extends Component {
    constructor(props) {
        super(props);
        this._onClickSubmit = this._onClickSubmit.bind(this);
        this._onSubmit = this._onSubmit.bind(this);
        this._onSubmit2 = this._onSubmit2.bind(this);
        this._onSubmit3 = this._onSubmit3.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
        this._getPeriodsOptions= this._getPeriodsOptions.bind(this);
          
        this.state= {
          coa: {
            loading: true,
            list: [],
    
          },
          form: {
            value: {},
            error: {},
          },
          param:{},
        };
      
      }
      componentDidMount() {
        // const { location, getLevels, getClasses, user } = this.props;
        // const { state = {} } = location;
        // const {isEdit =  false, data} = state;
        // const { id } = state.data || '';
        // const { content } = this.state;
        // const paramTypes = ['classes','levels', 'classrooms'];
    
        // paramTypes.forEach((type) => {
        //   this._getParamOptionsAcademics(type);
        // });
        // if (isEdit && id) {
        //   this.setState({id:id})
        //   this._setForm(data.id);
        // } else
        // if (isEmpty(content.list)) {
        //   this._onSearchContent({ page: 1 });
        // }
        // this._getClassesOptions();
        // this._getSubjectOptions();       
        // this._getRppOptions();       
        this._getPeriodsOptions();       
      
      }

      async _getPeriodsOptions() {
        const { getPeriodsOptions } = this.props;
        const res = await getPeriodsOptions();
        this.setState(prevState => ({
          ...prevState,
          param: {
            ...prevState.param,
            periods: res,
          },
        }));
        
      }
    
      _onClickSubmit() {
        const { history } = this.props;
        history.push('/dashboard/kurikulum/laporan/daftar-nilai-siswa-ktsp');
      }

      _onSubmit() {
        const { history } = this.props;
        history.push('/dashboard/perpustakaan/data-perpustakaan/laporan-pemasukan-uang/cetak');
      }

      _onSubmit2() {
        const { history } = this.props;
        history.push('/dashboard/kurikulum/ulangan/pengisian-indikator-sikap');
      }
      _onSubmit3() {
        const { history } = this.props;
        history.push('/dashboard/kurikulum/laporan/psikomotorik-siswa');
      }
    
    
      _onFormChange(event) {
        const {
          name,
          value,
          dataset,
          checked,
          type,
        } = event.target;
        const {
          inputType = 'text', inputArray = false, arrayPosition = 0,
          fieldName,
        } = dataset;
        this.setState((prevState) => {
          let newList = [];
          let newListError = [];
          let formattedValue = value;
    
          if (inputType === 'number') {
            formattedValue = normalizeAmount(value);
          }
          if (inputArray) {
            if (type === 'checkbox') {
              formattedValue = checked;
            }
            newList = prevState.form.value[fieldName];
            newListError = prevState.form.error[fieldName];
            newList[arrayPosition][name] = formattedValue;
            if (name === 'code_of_account') {
              newList[arrayPosition].isCredit = value.type;
            }
            if (!isEmpty(newListError[arrayPosition])) {
              newListError[arrayPosition][name] = '';
            }
          }
          return {
            form: {
              value: {
                ...prevState.form.value,
                ...(inputArray
                  ? { [fieldName]: newList }
                  : { [name]: formattedValue }),
              },
              error: {
                ...prevState.form.error,
                ...(inputArray
                  ? { [fieldName]: newListError }
                  : { [name]: '' }),
              },
            },
          };
        });
      }
      
    
      render() {
        const {form, param} = this.state;
        const { handleGetKelas} = this.props;
        return (
          <div className="student-list">
               
                   <div className="nilai-kognitif__custom-form">
                    <div className="nilai-kognitif__custom-form-row">
                      <div className="nilai-kognitif__custom-form-row__field">
                        <Select
                          name="periods"
                          label="Tahun Ajaran"
                          onChange={this._onFormChange}
                          data={ param.periods}
                          placeholder={'Pilihan'}
                          value={form.value.periods}
                          error={form.error.periods || ''}
                        
                          // error={form.error.namaSaudara1 || ''}
                        />
                      </div>
                      <div className="nilai-kognitif__custom-form-column__field">
                        <Select
                          name="tahunajaran"
                          label="Unit"
                          onChange={this._onFormChange}
                          value={form.value.tempatLahirSaudara1 || ''}
                          error={form.error.tempatLahirSaudara1 || ''}
                          placeholder={'2019 - 2020'}
                        />
                      </div>
                      </div>

                      <div className="data-buku__custom-form-row__field">
                <div className="data-buku__custom-form-row11"> 
                    <Input
                        type="date"
                        name="tahunajaran"
                        label="Dari Tanggal"
                        onChange={this._onFormChange}
                        // value={form.value.tempatLahirSaudara1 || ''}
                        // error={form.error.tempatLahirSaudara1 || ''}
                        // placeholder={'Pilihan'}
                    />
                  
                </div>
                <p className="laporan-pegawai__custom-p">S/D</p>
                <div className="data-buku__custom-form-row15">
                    <Input
                        type="date"
                        name="tahunajaran"
                        onChange={this._onFormChange}
                        // value={form.value.tempatLahirSaudara1 || ''}
                        // error={form.error.tempatLahirSaudara1 || ''}
                        // placeholder={'Pilihan'}
                    />
                </div>
               
            </div> 

                      
                    </div>

                      <div className="manage-registration__footer">
                          <Button
                          onClick={this._onSubmit}
                          title="Cari"
                          />
                      </div>

                     
          </div>
        );
      }
}
LaporanpemasukanUang.propTypes = {
    
  handleGetStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
LaporanpemasukanUang.defaultProps = {
    
  handleGetStudent: noop,
};

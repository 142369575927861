import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, noop } from 'lodash';
import { Input, Button } from '../../../../../components/base';
import SelectCoa from '../../../../../components/SelectCoa/SelectCoa.component';
import GeneralJournalFieldGroups from './GeneralJournalFieldGroups.component';

export default class GeneralJournalForm extends Component {
  constructor(props) {
    super(props);
    this._onAddList = this._onAddList.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
  }

  _onAddList() {
    const { onAddList, fieldName } = this.props;
    onAddList(fieldName);
  }

  _onDeleteList(idx) {
    const { onDeleteList, fieldName } = this.props;
    onDeleteList(idx, fieldName);
  }

  render() {
    const {
      list, error, onChange,
      fieldName, coa,
    } = this.props;
    return (
      <table className="manage-adjustment-journal__form-table">
        <thead>
          <tr>
            <th>No.</th>
            <th>Kode Akun</th>
            <th>Keterangan</th>
            <th>Debet</th>
            <th>Kredit</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {
          map(list, (data, idx) => (
            <GeneralJournalFieldGroups
              coa={coa}
              onChange={onChange}
              index={idx}
              fieldName={fieldName}
              data={data}
              error={error}
              onDelete={this._onDeleteList}
              key={`generalJournalFieldGroups_${idx}`}
            />
          ))
        }
          <tr className="manage-adjustment-journal__add-more">
            <td colSpan={7}>
              <div>
                <Button
                  icon="icon-plus"
                  onClick={this._onAddList}
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}
GeneralJournalForm.propTypes = {
  list: PropTypes.array.isRequired,
  error: PropTypes.array.isRequired,
  onAddList: PropTypes.func,
  onDeleteList: PropTypes.func,
  onChange: PropTypes.func,
  fieldName: PropTypes.string.isRequired,
  coa: PropTypes.object.isRequired,
};
GeneralJournalForm.defaultProps = {
  onAddList: noop,
  onDeleteList: noop,
  onChange: noop,
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, map, noop } from 'lodash';
import ListForm from '../../../../components/ListForm/ListForm.component';
import GeneralJournalForm from './components/GeneralJournalForm.component';
import { commaFormatted, dateFormat } from '../../../../utils/transformer.util';
import { FINANCE_GENERAL_JOURNAL_FORM_FIELDS } from '../../../../constants/finance/bookeeping.constant';
import Button from '../../../../components/base/Button/Button.component';

export default class ManageGeneralJournal extends PureComponent {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._setForm = this._setForm.bind(this);
    this._onAddList = this._onAddList.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this._onClearForm = this._onClearForm.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this.state = {
      form: {
        value: {
          date: dateFormat(new Date(), 'YYYY-MM-DD'),
          details: [{
            debit: 0,
            credit: 0,
            description: '',
            code_of_account: {},
          }, {
            debit: 0,
            credit: 0,
            description: '',
            code_of_account: {},
          }],
        },
        error: {
          details: [],
        },
      },
    };
  }

  async componentDidMount() {
    const { handleGetCoa, location } = this.props;
    const { isEdit = false, data } = location.state;
    if (isEdit) {
      this._setForm(data.id);
    }
    await handleGetCoa();
    this.setState(prevState => ({
      ...prevState,
    }));
  }

  async _setForm(id) {
    const { handleGetGeneralJournal } = this.props;
    try {
      const payload = await handleGetGeneralJournal({ id });
      this.setState({
        form: {
          value: {
            id: payload.id,
            type: 'UMUM',
            journal_number: payload.journal_number,
            date: payload.date,
            details: map(payload.details, detail => ({
              code_of_account: detail.parameter_code,
              description: detail.description,
              debit: commaFormatted(detail.debit),
              credit: commaFormatted(detail.credit),
            })),
          },
          error: {
            details: [],
          },
        },
      });
    } catch (err) {
      // err action
    }
  }

  _onFormChange(event) {
    const {
      name,
      value,
      dataset,
      checked,
      type,
    } = event.target;
    const {
      inputType = 'text', inputArray = false, arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (inputType === 'number') {
        formattedValue = commaFormatted(value);
      }
      if (inputArray) {
        if (type === 'checkbox') {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray
              ? { [fieldName]: newListError }
              : { [name]: '' }),
          },
        },
      };
    });
  }

  _onAddList(fieldName) {
    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          [fieldName]: [
            ...prevState.form.value[fieldName],
            {
              debit: 0,
              credit: 0,
              description: '',
              code_of_account: {},
            },
          ],
        },
        error: {
          ...prevState.form.error,
          [fieldName]: [
            ...prevState.form.error[fieldName],
            {},
          ],
        },
      },
    }));
  }

  _onDeleteList(idx, fieldName) {
    this.setState((prevState) => {
      const list = prevState.form.value[fieldName];
      const listError = prevState.form.error[fieldName];
      list.splice(idx, 1);
      return {
        form: {
          value: {
            ...prevState.form.value,
            [fieldName]: [...list],
          },
          error: {
            ...prevState.form.error,
            [fieldName]: [...listError],
          },
        },
      };
    });
  }

  _onClearForm() {
    this.setState({
      form: {
        value: {},
        error: {},
      },
    });
  }

  _onSubmit(e) {
    e.preventDefault();
    const { form } = this.state;
    const {
      handleManageGeneralJournal,
      history,
    } = this.props;
    handleManageGeneralJournal(form.value, history.goBack);
  }

  render() {
    const { form } = this.state;
    const { location, coa } = this.props;
    const { state } = location;
    const { preview = false } = state;
    const { details, is_posted } = form.value;
    const { details: detailsErrors } = form.error;
    return (
      <div className="manage-general-journal">
        <form onSubmit={this._onSubmit}>
          <ListForm
            form={form}
            formFields={FINANCE_GENERAL_JOURNAL_FORM_FIELDS}
            onFormChange={this._onFormChange}
          >
            <GeneralJournalForm
              isPosted={is_posted}
              fieldName="details"
              coa={coa}
              error={detailsErrors}
              preview={preview}
              list={details}
              onAddList={this._onAddList}
              onDeleteList={this._onDeleteList}
              onChange={this._onFormChange}
            />
          </ListForm>
          <div className="manage-general-journal">
            <Button
              type="submit"
              title="Simpan"
            />
          </div>
        </form>
      </div>
    );
  }
}
ManageGeneralJournal.propTypes = {
  handleManageGeneralJournal: PropTypes.func,
  handleGetGeneralJournal: PropTypes.func,
  handleGetCoa: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  coa: PropTypes.object.isRequired,
};
ManageGeneralJournal.defaultProps = {
  handleManageGeneralJournal: noop,
  handleGetGeneralJournal: noop,
};

import axios from 'axios';
import { map } from 'lodash';
import * as api from '../../utils/api.util';
import * as commonActions from '../actions/common.action';

const { CancelToken } = axios;
let source;

export function getBudgetOptions(payload = {}) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id } = user;
      let unit_id = prm_school_units_id;

      if (unit_id === null && workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      dispatch(commonActions.getOptionsRequested());
      const res = await api.OPTIONS({
        ...payload,
        unit_id,
      }, '/budget');
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'budget',
        data: res.data,
      }));
      return res.data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getPeriodeOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.OPTIONS(payload, '/periode');
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'periode',
        data: res.data,
      }));
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}


export function getExistingPeriodeOptions() {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id } = user;
      let unit_id = prm_school_units_id;

      if (unit_id === null && workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      dispatch(commonActions.getOptionsRequested());
      const res = await api.OPTIONS({ unit_id }, '/existing-periode');
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'periode',
        data: res.data,
      }));
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}


export function getCodeClassOptions(payload = {}) {
  return (dispatch) => {
    if (source) {
      source();
    }
    dispatch(commonActions.getOptionsRequested());
    const options = {};
    api.OPTIONS(payload, '/code-class', options)
      .then((res) => {
        if (res) {
          dispatch(commonActions.getOptionsSucceeded({
            entity: 'code_class', // artibut di state
            data: res.data,
          }));
        }
      })
      .catch(() => {
        dispatch(commonActions.getOptionsFailed());
      });
  };
}

export function getSchoolUnitOptions(payload = {}) {
  return (dispatch) => {
    if (source) {
      source();
    }
    dispatch(commonActions.getOptionsRequested());
    const options = {};
    api.OPTIONS(payload, '/unit', options)
      .then((res) => {
        if (res) {
          dispatch(commonActions.getOptionsSucceeded({
            entity: 'unit', // artibut di state
            data: res.data,
          }));
        }
      })
      .catch(() => {
        dispatch(commonActions.getOptionsFailed());
      });
  };
}

export function getCoaOptions(payload = {}) {
  return async (dispatch) => {
    if (source) {
      source();
    }
    dispatch(commonActions.getOptionsRequested());
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };
    let data = [];
    await api.OPTIONS(payload, '/code-of-account', options)
      .then((res) => {
        if (res) {
          dispatch(commonActions.getOptionsSucceeded({
            entity: 'coa',
            data: res.data,
          }));
          data = res.data;
        }
      })
      .catch(() => {
        dispatch(commonActions.getOptionsFailed());
      });
    return data;
  };
}

export function getJournalCoaOptions(payload = {}) {
  return async (dispatch, getState) => {
    try {
      if (source) {
        source();
      }

      dispatch(commonActions.setLoading({ show: true }));
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      dispatch(commonActions.getOptionsRequested());

      const res = await api.FINANCE_JOURNAL_POST({ ...payload, unit_id }, '/coa')
        .then((result) => {
          if (result) {
            dispatch(commonActions.getOptionsSucceeded({
              entity: 'coa',
              data: result.data,
            }));
          }
          return result;
        })
        .catch(() => {
          dispatch(commonActions.getOptionsFailed());
        });
      if (res) {
        return res.data;
      }
      return {};
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getMonthOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.OPTIONS(payload, '/month');
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'month',
        data: res.data,
      }));
      return res.data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}


export function getRapbuCoa(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.OPTIONS(payload, '/rapbu-coa');
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'coa',
        data: res.data,
      }));
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getCodeGroupOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.OPTIONS(payload, '/code-group');
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'code_group',
        data: res.data,
      }));
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getBankAccountOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.OPTIONS(payload, '/bank-account');
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'bank_account',
        data: res.data,
      }));
      return res.data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getPerwakilanOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.OPTIONS(payload, '/perwakilan');
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'perwakilan',
        data: res.data,
      }));
      return res.data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}
export function getUnitOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.OPTIONS(payload, '/unit');
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'unit',
        data: res.data,
      }));
      return res.data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getDivisiOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.OPTIONS(payload, '/divisi');
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'divisi',
        data: res.data,
      }));
      return res.data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getDivisiDetailListOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.OPTIONS(payload, '/divisi');
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'divisi',
        data: res.data,
      }));
      return res.data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getPlafondOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.OPTIONS(payload, '/plafond');
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'plafond',
        data: res.data,
      }));
      return res.data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getTemaOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.OPTIONS(payload, '/tema');
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'tema',
        data: res.data,
      }));

      return res.data || [];
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getTemaFilterOptions(payload) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.OPTIONS(payload, '/tema2');
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'tema',
        data: res.data,
      }));
      // console.log("ini isi tema")
      // console.log(res.data)
      return res.data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

// api staff start here by topan kece//
export function getAgamaOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM_STAFF(payload, '/agama');
      const data = map(res.result, item => ({
        label: item.name,
        value: item.id_agama,
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'agama',
        data,
      }));
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getGolonganDarahOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM_STAFF(payload, '/golongandarah');
      const data = map(res.result, item => ({
        label: item.name,
        value: item.id_golongan_darah,
      }));

      dispatch(commonActions.getOptionsSucceeded({
        entity: 'golongandarah',
        data,
      }));
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getStaffJabatanOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM_STAFF(payload, '/jabatan');
      const data = map(res.result, item => ({
        label: item.name,
        value: item.id_jabatan,
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'jabatan',
        data,
      }));

      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getJenjangPendidikanOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM_STAFF(payload, '/jenjangpendidikan');
      const data = map(res.result, item => ({
        label: item.name,
        value: item.id_jenjang_pendidikan,
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'jenjangpendidikan',
        data,
      }));

      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getRegionOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM_STAFF(payload, '/region');
      const data = map(res.result, item => ({
        label: item.name,
        value: item.id_region,
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'region',
        data,
      }));

      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getAktifNonAktifOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM_STAFF(payload, '/aktifnonaktif');
      const data = map(res.result, item => ({
        label: item.name,
        value: item.id_aktif_non_aktif,
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'aktifnonaktif',
        data,
      }));

      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getPangkatGolonganOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM_STAFF(payload, '/golongan');
      const data = map(res.result, item => ({
        label: item.name,
        value: item.id_golongan,
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'golongan',
        data,
      }));

      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getStatusKepegawaianOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM_STAFF(payload, '/statuskepegawaian');
      const data = map(res.result, item => ({
        label: item.name,
        value: item.id_status_kepegawaian,
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'statuskepegawaian',
        data,
      }));

      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getPTKPOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM_STAFF(payload, '/ptkp');
      const data = map(res.result, item => ({
        label: item.keterangan,
        value: item.id_ptkp,
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'ptkp',
        data,
      }));
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getUnitKerjaOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM_STAFF(payload, '/unitkerja');
      const data = map(res.result, item => ({
        label: item.name,
        value: item.id_unit_kerja,
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'unitkerja',
        data,
      }));

      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}


// api staff end here by topan kece//


// api pendaftaran kesiswaan akademi yaww//
export function getClasses(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM_AKADEMI(payload, '/classes');
      const data = map(res, item => ({
        label: item.name,
        value: item.id,
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'classes',
        data,
      }));
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getProvinces(payload = {}) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { options = {} } = storeState;
      const { provinces = {} } = options;
      let data = provinces.list || [];
      console.log(data, data.length, options);
      if (data.length == 0) {
        dispatch(commonActions.getOptionsRequested());
        const { id } = payload;
        const res = await api.PARAM_AKADEMI({
          filters: {
            id,
          },
        },
        '/provinces');

        data = map(res, item => ({
          label: item.name,
          value: item.id,
          data: { ...item },
        }));
        dispatch(commonActions.getOptionsSucceeded({
          entity: 'provinces',
          data,
        }));
      }
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getCities(payload = {}) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { options = {} } = storeState;
      const { cities = {} } = options;
      let data = cities.list || [];
      console.log(data, data.length, options);

      if (data.length === 0) {
        dispatch(commonActions.getOptionsRequested());
        const { filters } = payload;

        const res = await api.PARAM_AKADEMI({
          filters,
        },
        '/cities');
        data = map(res, item => ({
          label: item.name,
          value: item.id,
          data: { ...item },
        }));
        dispatch(commonActions.getOptionsSucceeded({
          entity: 'cities',
          data,
        }));
      }
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getDistricts(payload = {}) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { options = {} } = storeState;
      const { districts = {} } = options;
      let data = districts.list || [];
      console.log(data, data.length, options);

      if (data.length === 0) {
        dispatch(commonActions.getOptionsRequested());
        const { filters } = payload;

        const res = await api.PARAM_AKADEMI({
          filters,
        },
        '/districts');
        data = map(res, item => ({
          label: item.name,
          value: item.id,
          data: { ...item },
        }));
        dispatch(commonActions.getOptionsSucceeded({
          entity: 'districts',
          data,
        }));
      }
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getSubDistricts(payload = {}) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { options = {} } = storeState;
      const { sub_districts = {} } = options;
      let data = sub_districts.list || [];
      console.log(data, data.length, options);

      if (data.length === 0) {
        // dispatch(commonActions.setLoading({ show: true }));
        const { filters } = payload;

        const res = await api.PARAM_AKADEMI({
          filters,
        },
        '/sub_districts');
        data = map(res, item => ({
          label: item.name,
          value: item.id,
          data: { ...item },
        }));
        dispatch(commonActions.getOptionsSucceeded({
          entity: 'sub_districts',
          data,
        }));
      }
      return data;
    } catch (err) {
      // dispatch(commonActions.setLoading({ show: false }));
      throw err;
    }
  };
}

export function getCitizenships(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM_AKADEMI(payload, '/citizenships');
      const data = map(res, item => ({
        label: item.name,
        value: item.id,
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'citizenships',
        data,
      }));
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getReligions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM_AKADEMI(payload, '/religions');
      const data = map(res, item => ({
        label: item.name,
        value: item.id,
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'religions',
        data,
      }));
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getEducations(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM_AKADEMI(payload, '/educations');
      const data = map(res, item => ({
        label: item.name,
        value: item.id,
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'educations',
        data,
      }));
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getOccupations(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM_AKADEMI(payload, '/occupations');
      const data = map(res, item => ({
        label: item.name,
        value: item.id,
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'occupations',
        data,
      }));
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getGenders(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM_AKADEMI(payload, '/genders');
      const data = map(res, item => ({
        label: item.name,
        value: item.id,
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'genders',
        data,
      }));
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getBloodTypes(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM_AKADEMI(payload, '/blood_types');
      const data = map(res, item => ({
        label: item.name,
        value: item.id,
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'blood_types',
        data,
      }));
      console.log(data);
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getLevels(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM_AKADEMI(payload, '/levels');
      const data = map(res, item => ({
        label: item.name,
        value: item.id,
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'levels',
        data,
      }));
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getGuardians(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM_AKADEMI(payload, '/guardians');
      const data = map(res, item => ({
        label: item.name,
        value: item.id,
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'guardians',
        data,
      }));
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getParamOptions(payload = {}, type = '') {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      // if(type === 'classes'){
      //   payload.filters = {
      //     organizations_id: 2
      //   };
      // }
      const defaultValue = {
        id: '0',
        title: 'SEMUA',
        value: '0',
        label: 'SEMUA',
      };
      const res = await api.PARAM_AKADEMI({
        filters: {
          ...payload,
        },
      }, `/${type}`);
      const data = map(res, item => ({
        label: item.name,
        value: item.id || item.name,
        data: { ...item },
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: type,
        data,
      }));
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getParamOptionsAcademics(payload = {}, type = '') {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      // if(type === 'classes'){
      //   payload.filters = {
      //     organizations_id: 2
      //   };
      // }
      const defaultValue = {
        id: '0',
        title: 'SEMUA',
        value: '0',
        label: 'SEMUA',
      };
      const res = await api.ACADEMICS_PARAM({
        filters: {
          ...payload,
        },
      }, `/param/${type}`);
      const data = map(res, item => ({
        label: item.name,
        value: item.id || item.name,
        data: { ...item },
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: type,
        data,
      }));
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getStudentOptions(payload = {}) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id } = user;
      let unit_id = prm_school_units_id;
      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      // console.log(organizations_id)
      // let {user.organizations_id} = organizations_id
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM({ ...payload, organizations_id }, `/${organizations_id}/student/student`)
        .then(result => result)
        .catch();
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}


export function getAllStudentOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id } = user;
      let unit_id = prm_school_units_id;
      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM({ ...payload, organizations_id }, `/${organizations_id}/student/all_student`)
        .then(result => result)
        .catch();
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getStudentExitOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id } = user;
      let unit_id = prm_school_units_id;
      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM({ payload, organizations_id }, `/${organizations_id}/student/student_exit`)
        .then(result => result)
        .catch();
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getFolderOptions(payload = {}) {
  return async (dispatch) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS(payload, '/options/folder')
        .then(result => result)
        .catch();
      console.log(res);
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getPrmClassesOptions(payload = {}) {
  return async (dispatch) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS(payload, '/options/prm_classes')
        .then(result => result)
        .catch();
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getClassesOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id } = user;
      let unit_id = prm_school_units_id;
      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM({ ...payload, organizations_id }, `/${organizations_id}/classes`)
        .then(result => result)
        .catch();
      console.log(res);
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getTeacherDetailOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id } = user;
      let unit_id = prm_school_units_id;
      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM({ ...payload, organizations_id }, `/${organizations_id}/teacher_detail`)
        .then(result => result)
        .catch();
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getTeacherOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id } = user;
      let unit_id = prm_school_units_id;
      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM({ ...payload, organizations_id }, `/${organizations_id}/teacher`)
        .then(result => result)
        .catch();
      console.log(res);
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getTeacherUserIDOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id } = user;
      let unit_id = prm_school_units_id;
      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM({ ...payload, organizations_id }, `/${organizations_id}/teacher_userid`)
        .then(result => result)
        .catch();
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getMajorOptions(payload = {}) {
  return async (dispatch) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS(payload, '/options/major')
        .then(result => result)
        .catch();
      console.log(res);
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getMajorGroupOptions(payload = {}) {
  return async (dispatch) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS(payload, '/options/major-group')
        .then(result => result)
        .catch();
      console.log(res);
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getSubjectOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id } = user;
      let unit_id = prm_school_units_id;
      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM({ ...payload, organizations_id }, `/${organizations_id}/subject`)
      // const res = await api.ACADEMICS(payload, '/options/subject')
        .then(result => result)
        .catch();
      console.log(res);
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getSubjectTypeOptions(payload = {}) {
  return async (dispatch) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS(payload, '/options/subject_type')
        .then(result => result)
        .catch();
      console.log(res);
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getEkskulOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id } = user;
      let unit_id = prm_school_units_id;
      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      dispatch(commonActions.setLoading({ show: true }));
      // const res = await api.ACADEMICS(payload, '/options/ekstrakulikuler')
      const res = await api.ACADEMICS_PARAM({ ...payload, organizations_id }, `/${organizations_id}/ekstrakulikuler`)
        .then(result => result)
        .catch();
      console.log(res);
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getTeacherEkskulOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id } = user;
      let unit_id = prm_school_units_id;
      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      dispatch(commonActions.setLoading({ show: true }));
      // const res = await api.ACADEMICS(payload, '/options/teacher_ekskul')
      const res = await api.ACADEMICS_PARAM({ ...payload, organizations_id }, `/${organizations_id}/teacher_ekskul`)
        .then(result => result)
        .catch();
      console.log(res);
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}
export function getEkskulDayOptions(payload = {}) {
  return async (dispatch) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS(payload, '/options/ekskul_day')
        .then(result => result)
        .catch();
      console.log(res);
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getCounselingPlaceOptions(payload = {}) {
  return async (dispatch) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS(payload, '/options/counseling_place')
        .then(result => result)
        .catch();
      console.log(res);
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getClassroomsOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id } = user;
      let unit_id = prm_school_units_id;
      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM({ ...payload, organizations_id }, `/${organizations_id}/classrooms`)
        .then(result => result)
        .catch();
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getNoInvoices(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id } = user;
      let unit_id = prm_school_units_id;
      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM({ ...payload, organizations_id }, `/${organizations_id}/no_invoices`)
        .then(result => result)
        .catch();
      console.log(res);
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getStatusJenisSPP(payload = {}) {
  return async (dispatch) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS(payload, '/options/status_jenisspp')
        .then(result => result)
        .catch();
      console.log(res);
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getStudentUserIDOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id } = user;
      let unit_id = prm_school_units_id;
      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM({ ...payload, organizations_id }, `/${organizations_id}/student/student_userid`)
        .then(result => result)
        .catch();
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getClassroomOptions(payload = {}) {
  return async (dispatch, getState) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id } = user;
      let unit_id = prm_school_units_id;
      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      const res = await api.ACADEMICS_PARAM({
        filters: {
          ...payload,
          organizations_id,
        },
      }, `/${organizations_id}/prm_classrooms`);
      const data = map(res, item => ({
        label: item.name,
        value: item.id || item.name,
        attributes: item.attributes || [],
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'prm_classrooms',
        data,
      }));
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getStatusOptions(payload = {}) {
  return async (dispatch) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS(payload, '/options/prm_status')
        .then(result => result)
        .catch();
      console.log(res);
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getNewStudentOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id } = user;
      let unit_id = prm_school_units_id;
      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM({ ...payload, organizations_id }, `/${organizations_id}/student/new_student`)
        .then(result => result)
        .catch();
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getTypeExam(payload = {}) {
  return async (dispatch) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS(payload, '/options/type_exam')
        .then(result => result)
        .catch();
      console.log(res);
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getTeacherSubjectsOptions(payload = {}) {
  return async (dispatch) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS(payload, '/options/teacher_subjects')
        .then(result => result)
        .catch();
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getTypeFormula(payload = {}) {
  return async (dispatch) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS(payload, '/options/type_formula')
        .then(result => result)
        .catch();
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getSubjectPsikAfekOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id } = user;
      let unit_id = prm_school_units_id;
      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      dispatch(commonActions.setLoading({ show: true }));
      // const res = await api.ACADEMICS(payload, '/options/subject_psik')
      const res = await api.ACADEMICS_PARAM({ ...payload, organizations_id }, `/${organizations_id}/subject_psik`)
        .then(result => result)
        .catch();
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getViolationsGroupOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id } = user;
      let unit_id = prm_school_units_id;
      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM({ ...payload, organizations_id }, `/${organizations_id}/prm_violations_group`)
        .then(result => result)
        .catch();
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getAchievementGroupOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id } = user;
      let unit_id = prm_school_units_id;
      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM({ ...payload, organizations_id }, `/${organizations_id}/prm_achievement_group`)
        .then(result => result)
        .catch();
      console.log(res);
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getViolationsOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id } = user;
      let unit_id = prm_school_units_id;
      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM({ ...payload, organizations_id }, `/${organizations_id}/prm_violations`)
        .then(result => result)
        .catch();
      console.log(res);
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getAchievementOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id } = user;
      let unit_id = prm_school_units_id;
      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM({ ...payload, organizations_id }, `/${organizations_id}/prm_achievement`)
        .then(result => result)
        .catch();
      console.log(res);
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getSubjectGroupOptions(payload = {}) {
  return async (dispatch) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS(payload, '/options/subject_group')
        .then(result => result)
        .catch();
      console.log(res);
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getPrmPeriodsOptions(payload = {}) {
  return async (dispatch) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS(payload, '/options/periods')
        .then(result => result)
        .catch();
      console.log(res);
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getRppOptions(payload = {}) {
  return async (dispatch) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS(payload, '/options/rpp_types')
        .then(result => result)
        .catch();
      console.log(res);
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getKdThemeOptions(payload = {}) {
  return async (dispatch) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS(payload, '/options/kd_theme_types')
        .then(result => result)
        .catch();
      console.log(res);
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getPeriodsOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id } = user;
      let unit_id = prm_school_units_id;
      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM({ ...payload, organizations_id }, `/${organizations_id}/periods`)
        .then(result => result)
        .catch();
      console.log(res);
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getTypeExamScoreList(payload = {}) {
  return async (dispatch) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS(payload, '/options/type_exam_score_list')
        .then(result => result)
        .catch();
      console.log(res);
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getDayOptions(payload = {}) {
  return async (dispatch) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS(payload, '/options/day')
        .then(result => result)
        .catch();
      console.log(res);
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getUnitsOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id } = user;
      let unit_id = prm_school_units_id;
      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      dispatch(commonActions.setLoading({ show: true }));
      // const res = await api.ACADEMICS(payload, '/options/teacher_ekskul')
      const res = await api.ACADEMICS_PARAM({ ...payload, organizations_id }, `/${organizations_id}/prm_units`)
        .then(result => result)
        .catch();
      console.log(res);
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getMonthAcademicsOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.ACADEMICS_PARAM(payload, '/get_month');
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'month',
        data: res.data,
      }));
      return res;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getPrmAspectOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id } = user;
      let unit_id = prm_school_units_id;
      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM({ ...payload }, '/prm_aspect')
        .then(result => result)
        .catch();
      console.log(res);
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}


export function getKDMaterial(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.ACADEMICS_PARAM(payload, '/prm_kd');

      return res;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getPrmTema(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id } = user;
      let unit_id = prm_school_units_id;
      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM({ ...payload, organizations_id }, `/${organizations_id}/prm_tema`)
        .then(result => result)
        .catch();
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getYearsOfEducationsOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id } = user;
      let unit_id = prm_school_units_id;
      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM({ ...payload, organizations_id }, `/${organizations_id}/years_of_education`)
        .then(result => result)
        .catch();
      console.log(res);
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getPrmSubTema(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id } = user;
      let unit_id = prm_school_units_id;
      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM({ ...payload }, '/prm_sub_tema')
        .then(result => result)
        .catch();
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import budgeting from '../../../assets/img/finance/anggaran.png';
import bookkeeping from '../../../assets/img/bookkeeping/jurnal_bank.png';

export default class Finance extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickIdentitas = this.onClickIdentitas.bind(this);
    this.onClickJurusan = this.onClickJurusan.bind(this);
    this.onClickKenaikanKelas = this.onClickKenaikanKelas.bind(this);
    this.onClickKelas = this.onClickKelas.bind(this);
    this.onClickKelasKhusus = this.onClickKelasKhusus.bind(this);
    this.onClickPembagianKelas = this.onClickPembagianKelas.bind(this);
    this.onClickPembagianKelasKhusus = this.onClickPembagianKelasKhusus.bind(this);
    this.onClickSiswaPindahKelas = this.onClickSiswaPindahKelas.bind(this);
    this.onClickPindahUnit = this.onClickPindahUnit.bind(this);
    this.onClickDaftarPindahUnit = this.onClickDaftarPindahUnit.bind(this);
    this.onClickPermohonanPindahUnit = this.onClickPermohonanPindahUnit.bind(this);
  }

  onClickIdentitas() {
    const { history } = this.props;
    history.push('/dashboard/administrasi/data-kelas/identitas-unit-sekolah');
  }

  onClickJurusan() {
    const { history } = this.props;
    history.push('/dashboard/administrasi/data-kelas/jurusan-kelas');
  }

  onClickKenaikanKelas() {
    const { history } = this.props;
    history.push('/dashboard/administrasi/data-kelas/kenaikan-kelas-siswa');
  }

  onClickKelas(){
    const { history } = this.props;
    history.push('/dashboard/administrasi/data-kelas/kelas');
  }

  onClickKelasKhusus(){
    const { history } = this.props;
    history.push('/dashboard/administrasi/data-kelas/kelas-khusus');
  }
  onClickPembagianKelas(){
    const { history } = this.props;
    history.push('/dashboard/administrasi/data-kelas/pembagian-kelas');
  }
  onClickPembagianKelasKhusus(){
    const { history } = this.props;
    history.push('/dashboard/administrasi/data-kelas/pembagian-kelas-khusus');
  }
  onClickSiswaPindahKelas(){
    const { history } = this.props;
    history.push('/dashboard/administrasi/data-kelas/siswa-pindah-kelas');
  }
  onClickPindahUnit(){
    const { history } = this.props;
    history.push('/dashboard/administrasi/data-kelas/pindah-unit');
  }
  onClickDaftarPindahUnit(){
    const { history } = this.props;
    history.push('/dashboard/administrasi/data-kelas/daftar-pindah-unit');
  }
  onClickPermohonanPindahUnit(){
    const { history } = this.props;
    history.push('/dashboard/administrasi/data-kelas/permohonan-pindah-unit');
  }

  render() {
    return (
      <div className="akademi">
        <div className="akademi__list">
          <button onClick={this.onClickIdentitas}>
            <img src={budgeting} alt="identitas" />
            <h3>Identitas Unit Sekolah</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickJurusan}>
            <img src={budgeting} alt="jurusan" />
            <h3>Jurusan Kelas</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickKelas}>
            <img src={budgeting} alt="kelas" />
            <h3>Kelas</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickPembagianKelas}>
            <img src={budgeting} alt="pembagiankelas" />
            <h3>Pembagian Kelas</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickKenaikanKelas}>
            <img src={budgeting} alt="kenaikankelas" />
            <h3>Kenaikan Kelas Siswa</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickSiswaPindahKelas}>
            <img src={budgeting} alt="siswapindahkls" />
            <h3>Siswa Pindah Kelas</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickPindahUnit}>
            <img src={budgeting} alt="pindahunit" />
            <h3>Pindah Unit</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickDaftarPindahUnit}>
            <img src={budgeting} alt="daftarpindahunit" />
            <h3>Daftar Pindah Unit</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickPermohonanPindahUnit}>
            <img src={budgeting} alt="permohonanpindahunit" />
            <h3>Permohonan Pindah Unit</h3>
          </button>
        </div>
      </div>
    );
  }
}
Finance.propTypes = {
  history: PropTypes.object.isRequired,
};

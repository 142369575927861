import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop, map, isEqual, has, find } from 'lodash';
import StudentReportFilter from '../../../components/Filter/StudentReport.filter.component';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { ABSENSI_HARIAN } from '../../../constants/student.constant';
import { ABSENSI_HARIAN_TABLE } from '../../../constants/student.constant';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted, normalizeAmount, formatData } from '../../../utils/transformer.util';
import { Button, Input, Checkbox, Pagination, Select } from '../../../components/base/index';
import AbsensiHarianForm from '../components/AbsensiHarianForm.component';
import AbsensiHarianPage1 from '../components/AbsensiHarianPage1.component';
import AbsensiHarianPage2 from '../components/AbsensiHarianPage2.component';
import TabelAbsensiHarian from '../components/TabelAbsensiHarian.component';
import { LAPORAN_KONSELING_LIST_TABLE_FIELDS } from '../../../constants/Student/student.constant';
import language from '../../../languages';

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class LaporanKonseling extends Component {
        constructor(props) {
          super(props);
          this._onClickUpload = this._onClickUpload.bind(this);
          this._onFormChange = this._onFormChange.bind(this);
          this.onSubmit = this.onSubmit.bind(this);
          // this.isMount = false;
          this._onSearchContent = this._onSearchContent.bind(this);
          this._onClickNext = this._onClickNext.bind(this);
          this._onClickAdd = this._onClickAdd.bind(this);
          this.handleGetData = this.handleGetData.bind(this);
          this.onChangePage = this.onChangePage.bind(this);
          this._onCheckDetail = this._onCheckDetail.bind(this);
          this._onChangeFilter = this._onChangeFilter.bind(this);
          this._onChangeListAmount = this._onChangeListAmount.bind(this);
          this._setForm = this._setForm.bind(this);
          this.onEdit = this.onEdit.bind(this);
          this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
          const { user } = this.props;
          const { organizations_id, school_unit } = user;
          const { levels_id } = school_unit;
          this.state = {
            page: 1,
            selected: {},
            content: initialContent,
            unchecked: {},
            filters:{},
            list:[],
            form: {
              value: {
                levels_id,
                organizations_id: 4,
                absensiharian: [
                  {
                    students_id: '',
                    period: '',
                    classes_id: '',
                    date: '',
                    status: '',
                    description: '',
                  }
                ],
              },
              error: {
                classes: '',
                levels: '',
                absensiharian:''
              },
            },
            param:{},
            
          };
          this._handleFormChange = this._handleFormChange.bind(this);
          this._onSearch = this._onSearch.bind(this);
          this._getParamOptions = this._getParamOptions.bind(this);
        }
      
       componentDidMount() {
        const { location, getLevels, getClasses, user } = this.props;
        const { state = {} } = location;
        const {isEdit =  false, data} = state;
        const { id } = state.data || '';
          const { content } = this.state;
          const paramTypes = ['classes','levels', 'classrooms'];

          console.log(location)
          paramTypes.forEach((type) => {
            this._getParamOptionsAcademics(type);
          });
          if (isEdit && id) {
            this.setState({id:id})
            this._setForm(data.id);
          } else
          // this.handleGetData();
          // this.isMount = true;
          if (isEmpty(content.list)) {
            this._onSearchContent({ page: 1 });
          }
       
        }
      
        componentDidUpdate(prevProps) {
          const { location } = this.props;
          const { state = {} } = location;
          const {isEdit =  false, data} = state;
          const { id } = state.data || '';
         console.log(state)
          if ((!isEdit || !id) && !isEqual(prevProps, this.props)) {
            this._onSearchContent({ page: 1 });
          }
        }

        async _getParamOptionsAcademics(type, filters={}) {
          const { getParamOptionsAcademics, user } = this.props;
          const { organizations_id, school_unit } = user;
          const { levels_id } = school_unit;

          if (type === 'classrooms' || type === 'classrooms') {
            filters.organizations_id = organizations_id;
            filters.levels_id = levels_id;
          } else {
            delete filters.organizations_id;
          }
          const res = await getParamOptionsAcademics(filters, type);
          this.setState(prevState => ({
            ...prevState,
            param: {
              ...prevState.param,
              [type]: res,
            },
          }));
        }

        async _setForm(id) {
          const { handleGetAttendance } = this.props;
          try {
            const payload = await handleGetAttendance({ id });
         
        // console.log(payload)
            this.setState({
              filters: {
                classes_id: payload.classes_id,
                period: payload.period,
                date: payload.date,
              },
              form: {
                value: {
                  id: payload.id,
                  date: payload.date,
                  period: payload.period,
                  absensiharian: map(payload.absensiharian, detail => ({
                    classes_id: detail.classes_id,
                    name: detail.name,
                    date: detail.date,
                    students_id: detail.students_id,
                    status: detail.status,
                    description: detail.description,
                   
                  })),
                },
                error: {
                  absensiharian: [],
                },
              },
            });
          } catch (err) {
            // err action
          }
        }

        async _onSearchContent(params = {}) {
          const { filters } = this.state;
          
            try {
              const { handleListCounselingReport, user } = this.props;
              const { school_unit, workingUnit, id, user_group, organizations_id } = user;
              const { levels_id, units_id } = school_unit;
              const result = await handleListCounselingReport({ ...params, organizations_id, filters, units_id });
              // if (this.isMount) {
                console.log(result)
                if (result) {
                  result.forEach(item => {
                    if (!item.description) {
                      item.description = '';
                    }
                  });
                } 
                this.setState(prevState => ({
                  ...prevState,
                  form: {
                    ...prevState.form,
                    value: {
                      ...prevState.form.value,
                      laporankonseling: result,
                    }
                  }
                }));
                
              // }
            } catch (err) {
              // if (this.isMount) {
                this.setState(prevState => ({
                  ...prevState,
                  form: {
                    ...prevState.form,
                    value: {
                      ...prevState.form.value,
                      laporankonseling: [],
                    }
                  }
                }));
                
            }
            // }
        }

        _onChangeListAmount(event) {
          const { keywords } = this.state;
          this.setState({
            listAmount: Number(event.target.value),
          });
          this._onSearchContent({ limit: event.target.value, keywords, page: 1 });
        }

        async onEdit() {
          const { history, handleEditAttendance } = this.props;
          const { form } = this.state;
          const { value } = form;
          const res = await handleEditAttendance(value);        
          history.push('/dashboard/kesiswaan/absensi-siswa/absensi-rekap');
        }

        _onChangeFilter(e) {
          const { user } = this.props;
          const { organizations_id } = user;
          const { target } = e;
          const { value, name } = target;
          this.setState(prevState => ({
            ...prevState,
            filters: {
              ...prevState.filters,
              [name]: value,
            },
          }), () =>{
             this._onSearchContent()});
        
        }
        _onChangePage(page) {
          const { listAmount, keywords } = this.state;
          const offset = listAmount * (page - 1);
          // this.handleGetData({
          this._onSearchContent({
            limit: listAmount,
            keywords,
            offset,
            page,
          });
        }

        async handleGetData(payload = {}) {
          const { handleListStudentAttendance, handleListAnnouncementStudent, user } = this.props;
          const { units_id, workingUnit, id, user_group, organizations_id } = user;
          let unit_id = units_id;
      
          if (unit_id === null && workingUnit && workingUnit.id) {
            unit_id = workingUnit.id;
          }
          console.log(user)
          // if(user_group.id === 2){
            const res = await handleListStudentAttendance({...payload, user_id: id, organizations_id});
      
          this.setState(prevState => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                absensiharian: res,
              }
            }
          }));
      
         
          
        }
        _handleFormChange(event) {
            const {
              name,
              value,
            } = event.target;
            this.setState(prevState => ({
              form: {
                value: {
                  ...prevState.form.value,
                  [name]: value,
                },
                error: {
                  ...prevState.form.error,
                  [name]: '',
                },
              },
            }));
          }
        
          _onSearch() {
            const { form } = this.state;
            const error = validateRequiredFields(form.value, ['year']);
            this.setState(prevState => ({
              form: {
                value: prevState.form.value,
                error,
              },
            }));
            if (isEmpty(error)) {
              this.setState({ filtered: true });
            }
          }


        _onFormChange(event) {
          const { user } = this.props;
          const { organizations_id } = user;

          const {
            name,
            value,
            dataset,
            checked,
            type,
          } = event.target;
          const {
            inputType = 'text', inputArray = false, arrayPosition = 0,
            fieldName,
          } = dataset;
          this.setState((prevState) => {
            let newList = [];
            let newListError = [];
            let formattedValue = value;
      
            if (inputType === 'number') {
              formattedValue = normalizeAmount(value);
            }
            if (inputArray) {
              if (type === 'checkbox') {
                formattedValue = checked ? value : '';
              }
              newList = prevState.form.value[fieldName];
              newListError = prevState.form.error[fieldName] || [];
              newList[arrayPosition][name] = formattedValue;
              if (name === 'code_of_account') {
                newList[arrayPosition].isCredit = value.type;
              }
              if (!isEmpty(newListError[arrayPosition])) {
                newListError[arrayPosition][name] = '';
              }
            }
            return {
              form: {
                value: {
                  ...prevState.form.value,
                  ...(inputArray
                    ? { [fieldName]: newList }
                    : { [name]: formattedValue }),
                },
                error: {
                  ...prevState.form.error,
                  ...(inputArray
                    ? { [fieldName]: newListError }
                    : { [name]: '' }),
                },
              },
            };
          }, () => {
            // if (name === 'levels_id') {
            //   this._getParamOptions( 'classes', { filters: { levels_id: value, organizations_id}} );
            // }
          });
        }
        _onClickUpload() {
          const { history } = this.props;
          history.push('/dashboard/kesiswaan/absensi-siswa/upload-absensi-harian');
        }
      
        _onClickAdd(data) {
          const { form } = this.state;
          const { plafon } = form.value;
          
          plafon.push({
            tipePlafon: '',
            // divisi: '',
            nominal: 0,
            //period:'',
          });
          
          this.setState(prevState => ({
            form: {
              value: {
                ...prevState.form.value,
                plafon,
              },
              error: {
                ...prevState.form.error,
              }
            }
          }));
          console.log(plafon)
        }
      
        onChangePage(page) {
          const { listAmount, keywords } = this.state;
          const offset = listAmount * (page - 1);
          this.handleGetData({
            limit: listAmount,
            keywords,
            offset,
            page,
          });
        }
      
        

        _onCheckDetail(event) {
          const { target } = event;
          const { value, checked } = target;
          const { budgets, selectedBudgetDetails } = this.props;
          const { selected, unchecked } = this.state;
          let newState = { ...this.state };
      
          newState = this._setNewState(newState, checked, value);
      
          this.setState({
            ...newState
          });
        }
      
        async onSubmit() {
          const { history, handleSaveAttendance } = this.props;
          const { form } = this.state;
          const { value } = form;        
          let canSaveA = false;
          const dataForm = form.value;
          // if(dataForm.name && dataForm.publish_date && dataForm.tgl_selesai && dataForm.levels && dataForm.classes){
          //   canSaveA = true;
          // } else {
          //   canSaveA = false;
          // }
          // if (canSaveA == false){
          //   alert("Harap lengkapi data form. Bagian yang harus diisi ditandai dengan tanda bintang (*).");
          // } else {
            await handleSaveAttendance(value);
            history.push('/dashboard/kesiswaan/absensi-siswa/absensi-rekap');
          // }
        }
      
        
        _onClickNext() {
            window.scrollTo(0, 0);
            this.setState(prevState => ({
              page: prevState.page + 1,
            }));
          }
          async _getParamOptions(type, filters={}) {
            const {getParamOptions, user} = this.props;
            const { organizations_id } = user;
  
            if (type === 'classes' || type === 'classrooms') {
              filters.organizations_id = organizations_id;
            } else {
              delete filters.organizations_id;
            }
            const res = await getParamOptions(filters, type);
            this.setState(prevState => ({
              ...prevState,
              param: {
                ...prevState.param,
                [type]: res,
              },
            }));
          }
      
        render() {
          const {param, form,
                 page, list = {}, selected, checkedAll, content, filters, listAmount } = this.state;
          const {laporankonseling} = form.value;
          const {
            classes_id = '',
            from='',
            to='',
            levels_id='',
          } = filters;
          const {  location } = this.props;
          const loadingProps = { show: content.loading };
          const academic_year = [
            { label: '2019', value: '1'},
            { label: '2020', value: '2'},
            { label: '2021', value: '3'},
          ]
          return (
            <div className="manage-registration">
               
               <div className="kode-matpel__custom-form">
        <div className="kode-matpel__custom-form-row">
        <div className="kode-matpel__custom-form-row__field">
       
        <Select
                placeholder="Unit"
                label = "Unit"
                name="levels_id"
                value={form.value.levels_id}
                data={param.levels}
                disabled
                onChange={this._onFormChange}
              />
        </div>
         
        
        <div className="kode-matpel__custom-form-column__field">
        <Select
                name="classes"
                label="Kelas"
                onChange={this._onFormChange}
                data={ param.classrooms}
                placeholder={'Pilihan'}
                value={form.value.classes}
                error={form.error.classes || ''}
              />
          
            
       
         
        </div>
        </div>
        <div className="kode-matpel__custom-form-row">
        <div className="kode-matpel__custom-form-row__field">
                 
                <Input
                  type="date"
                  name="from"
                  label="Dari Tanggal"
                  onChange={this._onChangeFilter}
                  //data={levels.list}
                  value={from}
              />
        </div>
        
        <div className="kode-matpel__custom-form-column__field">
            <Input
                  type="date"
                  name="to"
                  label="Sampai Tanggal"
                  onChange={this._onChangeFilter}
                  //data={levels.list}
                  value={to}
            />
       
         
        </div>
       
        </div>

       </div>
            
            

<div className="fund-request__content">
      <table className="table">
        <thead>
          <tr>
            {
                map(LAPORAN_KONSELING_LIST_TABLE_FIELDS.label, (field, idx) => (
                  <th key={`table_th_${idx}`}>{field}</th>
              ))
              }
          </tr>
        </thead>
        <tbody>
          {
              map(laporankonseling, (list, idx) => (
                <tr key={`budget_row_${idx}`}>
                  {
                  map(LAPORAN_KONSELING_LIST_TABLE_FIELDS.value, (field, fieldIdx) => {
                    if (fieldIdx === 0) {
                      return (
                        <td key={`table_index_${fieldIdx}_${idx}`}>{(idx + 1)}</td>
                      );
                    }
                   
                   
                    return (
                      <td className={(field.type === 'number') ? 'nominal' : ''} key={`table_${fieldIdx}_${idx}`}>
                        {formatData(list, field)}
                      </td>
                    );
                  })
                  }
                </tr>
              ))
            }
        </tbody>
      </table>
    </div>
  </div>
          );
        }
}
LaporanKonseling.propTypes = {
  getParamOptions: PropTypes.func,  
  history: PropTypes.object.isRequired,
  handleGetAttendance: PropTypes.func,
  // handleGetLevels: PropTypes.func,
};
LaporanKonseling.defaultProps = {
  getParamOptions: noop,
  handleGetAttendance: noop,
  // handleGetLevels: noop,
};
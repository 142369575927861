import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  isEmpty, map, noop, has, find,
} from 'lodash';
import { Checkbox, Input } from '../../../../../components/base';
import { commaFormatted } from '../../../../../utils/transformer.util';

export default class CashJournalFundRequest extends Component {
  constructor(props) {
    super(props);
    this._onCheckDetail = this._onCheckDetail.bind(this);
    this._onCheckAll = this._onCheckAll.bind(this);
    this._setRef = this._setRef.bind(this);
    this._setNewState = this._setNewState.bind(this);
    this.state = {
      selected: {},
      unchecked: {},
      checkedAll: false,
      selectedCoa: props.selectedCoa,
    };
    this.checkboxRefs = [];
  }

  componentDidMount() {
    const { selectedFundRequestDetails, fundRequests } = this.props;

    this.setState({
      selected: { ...selectedFundRequestDetails },
      unchecked: {},
      checkedAll:
        Object.keys(fundRequests).length === Object.keys(selectedFundRequestDetails).length,
    });
  }

  componentWillUnmount() {
    const { onChange } = this.props;
    onChange(this.state);
  }

  _setRef(el) {
    if (el === null || !el) {
      return;
    }
    const { input } = el;
    const { value } = input;
    this.checkboxRefs[value] = input;
  }

  _setNewState(state = {}, checked = false, detailId = null) {
    const { fundRequests, selectedFundRequestDetails } = this.props;
    const { selected, unchecked } = state;
    const newState = { ...state };

    if (!checked) {
      if (has(selectedFundRequestDetails, detailId)) {
        newState.unchecked[detailId] = { ...selectedFundRequestDetails[detailId] };
      }
      delete newState.selected[detailId];
    } else {
      newState.selected[detailId] = find(fundRequests, o => parseInt(o.id) === parseInt(detailId));
      delete newState.unchecked[detailId];
    }

    return newState;
  }

  _onCheckAll(event) {
    const { target } = event;
    const detailIds = this.checkboxRefs;
    let newState = { ...this.state };
    const isCheckedAll = target.checked;

    for (const detailId in detailIds) {
      detailIds[detailId].checked = isCheckedAll;

      newState = this._setNewState(newState, isCheckedAll, detailId);
    }

    this.setState(prevState => ({
      ...prevState,
      ...newState,
      checkedAll: target.checked,
    }));
  }

  _onCheckDetail(event) {
    const { target } = event;
    const { value, checked } = target;
    const { fundRequests, selectedFundRequestDetails } = this.props;
    const { selected, unchecked } = this.state;
    let newState = { ...this.state };

    newState = this._setNewState(newState, checked, value);

    this.setState({
      ...newState,
    });
  }

  render() {
    const {
      fundRequests, onChange,
      selectedFundRequest, amount,
    } = this.props;
    const { selected, checkedAll } = this.state;
    const months = [
      'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli',
      'Agustus', 'September', 'Oktober', 'November', 'Desember',
    ];
    return (
      <div className="manage-fund-request__form-table">
        <table cellPadding="0" cellSpacing="0" border="0" className="table">
          <thead>
            <tr>
              <th>
                <Checkbox
                  noMargin
                  name="checkAll"
                  onChange={this._onCheckAll}
                  checked={checkedAll}
                />
              </th>
              <th>No.</th>
              <th>Kode</th>
              <th>Uraian</th>
              <th>Selisih</th>
            </tr>
          </thead>
          <tbody>
            {
              map(fundRequests, (fundRequest, idx) => (
                <tr key={`manage-fund-request_transaction__${idx}`}>
                  <td>
                    <Checkbox
                      noMargin
                      data-input-array
                      data-array-position={idx}
                      name="selectedFundRequest"
                      onChange={this._onCheckDetail}
                      value={fundRequest.id}
                      checked={!!has(selected, fundRequest.id)}
                      ref={this._setRef}
                    />
                  </td>
                  <td>
                    {parseInt(idx) + 1}
                  </td>
                 
                  <td>
                    {fundRequest.code}
                  </td>
                  <td>
                    {fundRequest.desc}
                  </td>
                  <td className="nominal">
                    {commaFormatted(fundRequest.fund_request_remains)}
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    );
  }
}
CashJournalFundRequest.propTypes = {
  fundRequests: PropTypes.array,
  onChange: PropTypes.func,
  selectedFundRequest: PropTypes.object,
  amount: PropTypes.string,
  selectedFundRequestDetails: PropTypes.object,
};
CashJournalFundRequest.defaultProps = {
  fundRequests: [],
  onChange: noop,
  selectedFundRequest: {},
  amount: '',
  selectedFundRequestDetails: {},
};

import { connect } from 'react-redux';
import Home from '../views/Home/Home.view';
import {studentCount} from '../states/thunks/academics.thunk';
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    handleStudentCount: (payload) => dispatch(studentCount(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop, result, map } from 'lodash';
import { Button, Input, Textarea, FileInput, Select, Checkbox } from '../../../../../components/base';
import SelectCoa from '../../../../../components/SelectCoa/SelectCoa.component';
import SelectTema from '../../../../../components/SelectTema/SelectTema.component';
import {
  commaFormatted, sumActualSemesterAmount,
  sumExpectedSemesterAmount, normalizeAmount, toCamelCase,
} from '../../../../../utils/transformer.util';
import { BUDGET_REQUIRED_FIELD,BUDGET_REQUIRED_FIELD_AllO, BUDGET_KELOLA_ALOKASI_PENGELUARAN } from '../../../../../constants/finance/budgeting/budget.constant';
import { validateRequiredFields,validateAllocationPlafond } from '../../../../../utils/validation.util';
import { deleteConfirmation, errorAlert } from '../../../../../utils/alert.util';

export default class BudgetField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: {},
      unchecked: {},
      checked:false,
      //isChecked:true,
      // isc:false,
      form:{
        value:{
          tema:'',
          execution_time:'',
        },
      },
      edit: props.status === !isEmpty(props.data),

    };
    
    

    this.tempValue = JSON.stringify(props.data);
    this.onToggleEdit = this.onToggleEdit.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
    this.onClickCheckbox = this.onClickCheckbox.bind(this);
    this.onDeleteSemester = this.onDeleteSemester.bind(this);
    this.onChangeRecommendation = this.onChangeRecommendation.bind(this);
    this.onFileUpload = this.onFileUpload.bind(this);
    this.removeFile = this.removeFile.bind(this);
    // console.log(this.state.form.value.tema)
  }

  onToggleEdit() {
    const { onUpdateForm, idx } = this.props;
    this.setState((prevState) => {
      if (prevState.edit) {
        onUpdateForm(JSON.parse(this.tempValue), idx);
      }
      return {
        edit: !prevState.edit,
      };
    });
  }

  onFormChange(event) {
    const {
      name,
      value,
      dataset,
    } = event.target;
    const { onChange, idx } = this.props;
    // console.log(onChange)
    // console.log(idx)
    // console.log(this.props)

    if(name=="file"){
      // console.log(value)
    }
    onChange({
      target: {
        name,
        value,
        dataset: {
          ...dataset,
          inputArray: true,
          arrayPosition: idx,
        },
      },
    });

    // console.log(dataset.arrayPosition)
  }

  async onFileUpload(event) {
    const { handleFileUpload, onChange, idx } = this.props;
    const { target } = event;
    const { files, dataset } = target;
    const file = files[0];

    if(file) {
      const filename = file.name;
      const res = await handleFileUpload(file);

      onChange({
        target: {
          name: 'file',
          value: {
            path: res.path,
            filename
          },
          dataset: {
            ...dataset,
            inputArray: true,
            arrayPosition: idx,
          },
        },
      });
    }
  }

  removeFile(event) {
    const { handleFileUpload, onRemoveFile, idx } = this.props;
    const { target } = event;

    onRemoveFile(idx);
  }

  
  // _onCheckDetail(event) {
  //   const { target } = event;
  //   const { value, checked } = target;
  //   const { budgets, selectedBudgetDetails } = this.props;
  //   const { selected, unchecked } = this.state;
  //   let newState = { ...this.state };

  //   newState = this._setNewState(newState, checked, value);

  //   this.setState({
  //     ...newState
  //   });
  // }

  

  onClickCheckbox(event, param, index  ) {
    const {
      name,
      value ,
      dataset, 
      checked ,
    } = event.target;

    let newState = { ...this.state };
    const { onChange, idx, data } = this.props;
    const { selected, unchecked } = this.state;

    // console.log(checked)
    // this.state["check_"+param] = !this.state["check_"+param];
    if(checked=== true){
      // console.log(data.allocations[index])
     data.allocations[index] = sumExpectedSemesterAmount(data)
    }else{
    data.allocations[index] = 0
    // console.log("tes")

    }
    this.setState({
          ...newState
        });
    
    // onChange({
    //   target: {
    //     name,
    //     value,

    //     dataset: {
    //       ...dataset,
    //       inputArray: true,
    //       arrayPosition: idx,
    //       },
    //   },
    // });

    // console.log("Event : " + event)
  }

  onChangeRecommendation(event) {
    const {
      name,
      value,
      dataset,
    } = event.target;
    const { onChangeRecommendation, idx } = this.props;
    onChangeRecommendation({
      target: {
        name,
        value: normalizeAmount(value),
        dataset: {
          ...dataset,
          inputArray: true,
          arrayPosition: idx,
        },
      },
    });
  }

  async onSave() {
    const {
      handleEditBudgetDetail, code, onUpdateForm,
      idx, data, onUpdateError,
    } = this.props;
    let error = validateRequiredFields(data, BUDGET_REQUIRED_FIELD,BUDGET_KELOLA_ALOKASI_PENGELUARAN);
    let allocationError = validateAllocationPlafond(data);
    error = Object.assign( {}, error, allocationError);
    const expectedAmount = sumExpectedSemesterAmount(data);
    const actualAmount = sumActualSemesterAmount(data);
    if (expectedAmount !== actualAmount) {
      error.amount = 'Jumlah salah';
    }
   
    if (!isEmpty(error)) {
      onUpdateError(data, error, idx)
      errorAlert({
        title: 'Data Ada Yang Salah',
      });
    } else {
      try {
        const res = await handleEditBudgetDetail({ ...data, code });
        if (!res) {
          onUpdateForm(data, idx);
          this.tempValue = JSON.stringify(data);
          this.onToggleEdit();
        }
      } catch (err) {
        // err
      }
    }

    // console.log(!isEmpty(error))
  }

  async onDeleteSemester() {
    const {
      status, onDeleteSemester, handleDeleteBudgetDetail,
      data, isNew,
    } = this.props;
    if (status === 'ADD' || isNew) {
      onDeleteSemester();
    } else {
      const willDelete = await deleteConfirmation();
      if (willDelete) {
        try {
          await handleDeleteBudgetDetail(data.unique_id);
          onDeleteSemester();
        } catch (err) {
          // err
        }
      }
    }
  }
  

  render() {
    const { edit , form } = this.state;
    const { value } = form;
    const {disabled} = false;
    const {
      idx, error, data, coa,
      type, status, tema,
      isNew, onSearchCoa, onSearchTema, user, recommendations, workflow, plafonds, handleFileUpload, handleFileDownload
    } = this.props;

    const { user_group } = user;
    const editing = status === 'ADD' || edit || isNew;
    const canEdit = editing && type !== 'RAPBU';
    const expectedAmount = type === 'RAPBU' ? commaFormatted(data.total) : sumExpectedSemesterAmount(data);
    const canEditRecommendation = user_group.name === 'Korektor Perwakilan' || user_group.name === 'Manager Keuangan';
    const isRapbu = (type === 'INCOME' || type === 'OUTCOME') ? false : true;
    const isBendahara = (user_group && user_group.name === 'Bendahara') ? true : false;

    // console.log(onSearchTema)
    // console.log(idx)


    // var msg;
    // if (this.state.isChecked) {
    //   msg = "checked";
    // } else {
    //   msg = "unchecked";
    // }
    // console.log("cek Jumlah"+expectedAmount)

    return (
      <tr>
        { (type === 'OUTCOME') && (
        <td>
          <div className="manage-budget__row manage-budget__row--30">
            <SelectCoa
              noMargin
              inputArray
              arrayPosition={idx}
              edit={canEdit}
              name="tema"
              value={data.tema || {}}
              error={error.tema}
              coa={tema}
              onClick={this.onFormChange}
              placeholder="Pilih tema"
              rightIcon="icon-search"
              onSearchCoa={onSearchTema}
            />
          </div>
        </td>
        )}
        { (type === 'OUTCOME') && (
        <td>
          <div className="manage-budget__row manage-budget__row--30">
            <Textarea
              noMargin
              data-input-array
              data-array-position={idx}
              edit={canEdit}
              name="target"
              placeholder="Isi Deskripsi"
              onChange={this.onFormChange}
              value={data.target || ''}
            />
          </div>
        </td>
        )}

        { (type === 'OUTCOME') && (
          <td>
            <div className="manage-budget__row manage-budget__row--30">
              <Input
                noMargin
                data-input-array
                data-array-position={idx}
                type="date"
                name="execution_time"
                onChange={this.onFormChange}
                value={data.execution_time}
              />
            </div>
          </td>
        )}

        <td>
          <div className="manage-budget__row manage-budget__row--30">
            <Textarea
              noMargin
              data-input-array
              data-array-position={idx}
              edit={canEdit}
              name="desc"
              placeholder="Isi Deskripsi"
              onChange={this.onFormChange}
              value={data.desc}
            />
          </div>
        </td>
        <td key="budget_field_td_arr_1" className="nominal">
          <div className="manage-budget__row nominal">
            <Input
              noMargin
              isNumber
              data-input-array
              data-array-position={idx}
              edit={canEdit}
              name="quantity"
              data-input-type="number"
              placeholder="Isi Kuantitas"
              onChange={this.onFormChange}
              value={data.quantity}
              error={error.quantity}
            />
          </div>
        </td>
        <td key="budget_field_td_arr_2" className="nominal">
          <div className="manage-budget__row nominal">
            <Input
              noMargin
              isNumber
              data-input-array
              data-array-position={idx}
              edit={canEdit}
              name="price"
              data-input-type="number"
              placeholder="Isi Satuan"
              onChange={this.onFormChange}
              value={data.price}
              error={error.price}
            />
          </div>
        </td>
        <td key="budget_field_td_arr_3" className="nominal">
          <div className="manage-budget__row nominal">
            <Input
              noMargin
              isNumber
              data-input-array
              data-array-position={idx}
              edit={canEdit}
              name="term"
              data-input-type="number"
              placeholder="Isi Periode"
              onChange={this.onFormChange}
              value={data.term}
              error={error.term}
            />
          </div>
        </td>
        {
          map(plafonds, plafond => (
            <td key={`budget_field_td_arr_${plafond.value}`} className="nominal">
              <div className="manage-budget__custom-checkbox__field">
                <div className="manage-budget__row nominal">
                  <Input
                    style={{color:'black'}}
                    noMargin
                    isNumber
                    disabled
                    data-input-array
                    data-array-position={idx}
                    edit={canEdit}
                    name={toCamelCase(plafond.label)}
                    data-input-type="number"
                    data-countable
                    data-plafond-id={plafond.value}
                    onChange={this.onFormChange}
                    value={data.allocations[plafond.value]}
                    error={error.plafond}
                  />
                </div>
                <div className="budget-field__button-wrapper">
                  {
                    (isNew || edit)
                    && (

                      <Input
                        type="checkbox"
                        name={`${toCamelCase(plafond.label)}_1`}
                        onChange={event => this.onClickCheckbox(event, toCamelCase(plafond.label), plafond.value)}
                        defaultChecked={this.state[`check_${toCamelCase(plafond.label)}`]}
                        value={data.allocations[plafond.value]}
                        error={error.plafond}
                      />
                    )
                 }
              </div>
              </div>
            </td>
          ))
        }
        <td className="nominal">
          <div className="manage-budget__row nominal">
            
            <Input
              noMargin
              name="total"
              edit={canEdit}
              value={commaFormatted(expectedAmount)}
              error={error.amount}
            />
          </div>
        </td>
        <td >
          <div className="icon2">
          <FileInput
            style={{width:"300px"}}
              data-input-type="file"
              noMargin
              type="file"
              name="file"
              edit={canEdit}
              onChange={this.onFileUpload}
              fileName={data.file ? data.file.name : ''}
              displayName={data.file ? data.file.display_name : 'default'}
              onRemoveFile={this.removeFile}
              onDownloadFile={handleFileDownload}
            />
          </div>
        </td>
        <td>
          <div className="icon">
            {
              isNew
              && <Button onClick={this.onDeleteSemester} icon="icon-trash-o" />
            }
            {
              (!isNew && !edit)
              && (
                <div className="manage-budget__action-button">
                  <Button onClick={this.onToggleEdit} icon="icon-file-o" />
                  <Button onClick={this.onDeleteSemester} icon="icon-trash-o" />
                </div>
              )
            }
            {
              (!isNew && edit)
              && (
                <div className="manage-budget__action-button">
                  <Button onClick={this.onSave} icon="icon-check" />
                  <Button onClick={this.onToggleEdit} icon="icon-close" />
                </div>
              )
            }
          </div>
        </td>
      </tr>
    );
  }
}
BudgetField.propTypes = {
  isNew: PropTypes.bool,
  // idx: PropTypes.number.isRequired,
  onDeleteSemester: PropTypes.func,
  onChange: PropTypes.func,
  onChangeRecommendation: PropTypes.func,
  handleEditBudgetDetail: PropTypes.func,
  handleDeleteBudgetDetail: PropTypes.func,
  handleFileUpload: PropTypes.func,
  handleFileDownload: PropTypes.func,
  onRemoveFile: PropTypes.func,
  onUpdateForm: PropTypes.func,
  onUpdateError: PropTypes.func,
  error: PropTypes.object,
  data: PropTypes.object,
  execution_time: PropTypes.object.isRequired,
  tema: PropTypes.object.isRequired,
  coa: PropTypes.object.isRequired,
  code: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  type: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  onSearchTema:PropTypes.func,
  onSearchCoa: PropTypes.func,
  onSearchTema: PropTypes.func,
  user: PropTypes.object,
  recommendations: PropTypes.object,
  workflow: PropTypes.array,
  handleGetTemaOptions :PropTypes.array,
  plafonds: PropTypes.array,
};
BudgetField.defaultProps = {
  isNew: false,
  onDeleteSemester: noop,
  onChange: noop,
  onChangeRecommendation: noop,
  handleEditBudgetDetail: noop,
  handleDeleteBudgetDetail: noop,
  onUpdateForm: noop,
  onUpdateError: noop,
  error: {},
  data: {},
  onSearchTema: noop,
  onSearchCoa: noop,
  user: null,
  recommendations: {},
  workflow: null,
  handleGetTemaOptions :noop,
  plafonds: null,
  handleFileUpload: noop,
  handleFileDownload: noop,
  onRemoveFile: noop,
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, map, noop } from 'lodash';
import ListForm from '../../../components/ListForm/ListForm.component';
import { commaFormatted, normalizeAmount } from '../../../utils/transformer.util';
import {  PEMBAGIAN_KELASKHUSUS_FORM_FIELDS  } from '../../../constants/Akademi/DataKelas/kelas.constant';
import { Button, Select } from '../../../components/base';


export default class PembagianKelasKhusus extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this._getPeriodsOptions= this._getPeriodsOptions.bind(this);

    //this.deletePlafon = this.deletePlafon.bind(this);
    //this._onAddList = this._onAddList.bind(this);
    this._onClickAdd = this._onClickAdd.bind(this);
    
    this.state = {
      form: {
        value: {
          plafon: [
            {
              tipePlafon: '',
               //divisi: '',
              nominal: 0,
            }                                      
          ],
        },
        error: {
          plafon: '',

        },
      },
      param: {},
    };
  }

  componentDidMount(){
    const{handleGetClasses, handleGetLevels } = this.props
    handleGetClasses();
    handleGetLevels();
    this._getPeriodsOptions();       
  }

  async _getPeriodsOptions() {
    const { getPeriodsOptions } = this.props;
    const res = await getPeriodsOptions();
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
    
  }

  // on formchange untuk form
  _onFormChange(event) {
    const {
      name,
      value,
      dataset,
      checked,
      type,
    } = event.target;
    const {
      inputType = 'text', inputArray = false, arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        if (type === 'checkbox') {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
        if (name === 'code_of_account') {
          newList[arrayPosition].isCredit = value.type;
        }
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = '';
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray
              ? { [fieldName]: newListError }
              : { [name]: '' }),
          },
        },
      };
    });
  }

  _onClickAdd(data) {
    const { form } = this.state;
    const { plafon } = form.value;
    
    plafon.push({
      tipePlafon: '',
      // divisi: '',
      nominal: 0,
      //period:'',
    });
    
    this.setState(prevState => ({
      form: {
        value: {
          ...prevState.form.value,
          plafon,
        },
        error: {
          ...prevState.form.error,
        }
      }
    }));
    console.log(plafon)
  }

  _onDeleteList(idx) {
    this.setState((prevState) => {
      const { plafon } = prevState.form.value;
      const list = prevState.form.value.plafon;

      list.splice(idx, 1);
      return {
        ...prevState,
        plafon: list
      };
    });
  }

  // on formchange untuk tabel
  onFormChange(event) {
    const {
      name,
      value,
      dataset,
    } = event.target;
    console.log(name)
    const { inputArray = false, arrayPosition = 0, inputType = 'text' } = dataset;
    this.setState((prevState) => {
      let formattedValue = value;
      let newPlafon = [];
      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }

      if (inputArray) {
        newPlafon = prevState.form.value.plafon;
        newPlafon[arrayPosition][name] = value;
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray ? 
              { plafon: newPlafon } : 
              { [name]: formattedValue }
            )
          },
          error: {
            ...prevState.form.error,
            form:'',
            [name]: '',
          },
        },
      };
    });
  }

  onSubmit(e) {
    e.preventDefault();
    const {history} = this.props;
    history.push('/dashboard/administrasi/data-kelas/kelola-pembagian-kelas-khusus');
  }

  deletePlafon(e) {
    var array = [...this.state.people]; // make a separate copy of the array
    var index = array.indexOf(e.target.value)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({people: array});
    }
  }

  render() {
    const { form, param } = this.state;
    const {value} = form;
    const { classes={}, levels={}}= this.props;
    return (
      <div className="add-budget">
        
        <form onSubmit={this.onSubmit}>
          {/* <ListForm
            form={form}
            formFields={ KENAIKANKELAS_DATAKELAS_FORM_FIELDS}
            onFormChange={this._onFormChange}
          /> */}
           <Select
            name="period"
            label="Tahun Ajaran(Kelas Khusus)"
            onChange={this._onFormChange}
            placeholder="Pilihan"
            // data={periode.list}
            // value={value.period}
            data={param.periods}
            value={form.value.period}
            error={form.error.period || ''}

          />
          <Select
            name="classes"
            label="Kelas"
            onChange={this._onFormChange}
            placeholder="Pilihan"
            data={classes.list}
            value={value.classes}
          />
          <Select
            name="levels"
            label="Unit"
            onChange={this._onFormChange}
            placeholder="Pilih Unit Sekolah"
            data={levels.list}
            value={value.levels}
          />

         
          <div className="add-budget">
            <Button
              type="submit"
              title="SUBMIT"
            />
          </div>
        </form>
      </div>
    );
  }

    //   render() {
    //     return (
    //         <ul>
    //             {this.props.items.map(item => (
    //                 <li key={item.id}>{item.text}<button onClick={this._handleDelete.bind(this, item.id)}>Delete</button></li>
    //             ))}
    //         </ul>
    //     );
    // } 
}
PembagianKelasKhusus.propTypes = {
  handleGetClasses: PropTypes.func,
  handleGetLevels: PropTypes.func,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
};
PembagianKelasKhusus.defaultProps = {
  user: null,
  handleGetClasses: noop,
  handleGetLevels: noop,
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  map, noop, has, find,
} from 'lodash';
import { Checkbox } from '../../../../../components/base';
import { commaFormatted } from '../../../../../utils/transformer.util';

export default class MonthlyCheckList extends Component {
  constructor(props) {
    super(props);
    this._onCheckDetail = this._onCheckDetail.bind(this);
    this._onCheckAll = this._onCheckAll.bind(this);
    this._setRef = this._setRef.bind(this);
    this._setNewState = this._setNewState.bind(this);
    this.renderAllocations = this.renderAllocations.bind(this);
    this.renderTema = this.renderTema.bind(this);
    this.renderFundrequestDetails = this.renderFundrequestDetails.bind(this);
    this.renderTotal = this.renderTotal.bind(this);
    this.state = {
      selected: {},
      unchecked: {},
      checkedAll: false,
    };
    this.checkboxRefs = [];
  }

  componentDidMount() {
    const { selectedBudgetDetails, budgets } = this.props;

    this.setState({
      selected: { ...selectedBudgetDetails },
      unchecked: {},
      checkedAll:
        Object.keys(budgets).length === Object.keys(selectedBudgetDetails).length,
    });
    // console.log( Object.keys(budgets))
    // console.log( Object.keys(selectedBudgetDetails))
  }

  componentWillUnmount() {
    const { onChange } = this.props;
    onChange(this.state);
  }

  _setRef(el) {
    if (el === null || !el) {
      return;
    }
    const { input } = el;
    const { value } = input;
    this.checkboxRefs[value] = input;
  }

  _setNewState(state = {}, checked = false, detailId = null) {
    const { budgets, selectedBudgetDetails } = this.props;
    const { selected, unchecked } = state;
    const newState = { ...state };

    if (!checked) {
      if (has(selectedBudgetDetails, detailId)) {
        newState.unchecked[detailId] = { ...selectedBudgetDetails[detailId] };
      }
      delete newState.selected[detailId];
    } else {
      newState.selected[detailId] = find(budgets, o => parseInt(o.id) === parseInt(detailId));
      delete newState.unchecked[detailId];
    }
    return newState;
  }


  _onCheckAll(event) {
    const { target } = event;
    const detailIds = this.checkboxRefs;
    let newState = { ...this.state };
    const isCheckedAll = target.checked;


    for (const detailId in detailIds) {
      detailIds[detailId].checked = isCheckedAll;

      newState = this._setNewState(newState, isCheckedAll, detailId);
    }


    this.setState(prevState => ({
      ...prevState,
      ...newState,
      checkedAll: target.checked,
    }));
  }

  _onCheckDetail(event) {
    const { target } = event;
    const { value, checked } = target;
    const { budgets, selectedBudgetDetails } = this.props;
    const { selected, unchecked } = this.state;
    let newState = { ...this.state };

    newState = this._setNewState(newState, checked, value);

    this.setState({
      ...newState,
    });
  }


  renderAllocations(allocations) {
    // console.log(budget.allocations)
    let totalplafond = 0;
    let name = '';
    let rutinValue = 0;
    let kegiatanValue = 0;
    let danPemValue = 0;
    let biPengValue = 0;

    for (let i = 0; i < allocations.length; i++) {
      // console.log(allocations[i].plafond.plafond)

      name = allocations[i].plafond.plafond;
      const alo = allocations[i].nominal;
      if (name == 'Rutin') {
        rutinValue = parseInt(rutinValue) + parseInt(alo);
      } else if (name == 'Kegiatan') {
        kegiatanValue = parseInt(kegiatanValue) + parseInt(alo);
        // console.log(kegiatanValue)
      } else if (name == 'Dana Pemerintah') {
        danPemValue = parseInt(danPemValue) + parseInt(alo);
      } else if (name == 'Biaya Pengembangan Pendidikan') {
        biPengValue = parseInt(biPengValue) + parseInt(alo);
      }
      totalplafond = rutinValue + kegiatanValue + biPengValue + danPemValue;
    }
    // console.log(totalplafond)

    const rows = [];

    rows.push([
      <td className="nominal">Rp.{commaFormatted(rutinValue)}</td>,
      <td className="nominal">Rp.{commaFormatted(kegiatanValue)}</td>,
      <td className="nominal">Rp.{commaFormatted(biPengValue)}</td>,
      <td className="nominal">Rp.{commaFormatted(danPemValue)}</td>,

    ]);
    return rows;
  }

  renderTotal(allocations) {
    // console.log(budget.allocations)
    let totalplafond = 0;
    let name = '';
    let rutinValue = 0;
    let kegiatanValue = 0;
    let danPemValue = 0;
    let biPengValue = 0;

    for (let i = 0; i < allocations.length; i++) {
      // console.log(allocations[i].plafond.plafond)

      name = allocations[i].plafond.plafond;
      const alo = allocations[i].nominal;
      if (name == 'Rutin') {
        rutinValue = parseInt(rutinValue) + parseInt(alo);
      } else if (name == 'Kegiatan') {
        kegiatanValue = parseInt(kegiatanValue) + parseInt(alo);
        // console.log(kegiatanValue)
      } else if (name == 'Dana Pemerintah') {
        danPemValue = parseInt(danPemValue) + parseInt(alo);
      } else if (name == 'Biaya Pengembangan Pendidikan') {
        biPengValue = parseInt(biPengValue) + parseInt(alo);
      }
      totalplafond = rutinValue + kegiatanValue + biPengValue + danPemValue;
    }
    // console.log(totalplafond)

    const rows = [];

    rows.push([
      commaFormatted(totalplafond),
    ]);
    return rows;
  }


  renderTema(tema) {
    let code = '';
    let name = '';


    for (let i = 0; i < tema.length; i++) {
      code = tema[i].code;
      name = tema[i].name;
    }

    const rows = [];

    rows.push([
      <td className="nominal">{code}</td>,
      <td className="nominal">{name}</td>,

    ]);
    return rows;
  }

  renderFundrequestDetails(fund_request_details) {
    let difference = '';

    for (let i = 0; i < fund_request_details.length; i++) {
      if (fund_request_details[i].difference) {
        difference = fund_request_details[i].difference;
      }
    }
    return (
      <>
        Rp.{commaFormatted(difference)}
      </>
    );
  }


  render() {
    const {
      budgets, onChange,
      selectedBudget, amount,
    } = this.props;
    const { selected, checkedAll } = this.state;
    return (
      <div className="manage-fund-request__form-table">
        <table cellPadding="0" cellSpacing="0" border="0" className="table">
          <thead>
            <tr>
              <th rowSpan={2}>
                <Checkbox
                  noMargin
                  name="checkAll"
                  onChange={this._onCheckAll}
                  checked={checkedAll}
                />
              </th>
              <th rowSpan={2}>No.</th>
              <th rowSpan={2}>Kode Tema</th>
              <th rowSpan={2}>Nama Tema</th>
              <th rowSpan={2}>Rincian</th>
              <th rowSpan={2}>Semester</th>
              <th colSpan={4}>RAPBS</th>
              <th rowSpan={2}>Selisih</th>
            </tr>
            <tr>
              <th>Rutin</th>
              <th>Kegiatan</th>
              <th>Biaya Pengembangan Pemerintah</th>
              <th>Dana Pemerintah</th>
            </tr>
          </thead>
          <tbody>
            {
              map(budgets, (budget, idx) => (

                <tr key={`manage-fund-request_transaction__${idx}`}>
                  <td>
                    <Checkbox
                      noMargin
                      data-input-array
                      data-array-position={idx}
                      name="selectedBudget"
                      onChange={this._onCheckDetail}
                      value={budget.id}
                      checked={!!has(selected, budget.id)}
                      ref={this._setRef}
                    />
                  </td>
                  {/* {console.log(budget)} */}
                  <td>
                    {parseInt(idx) + 1}
                  </td>
                  {
                   this.renderTema(budget.tema)
                  //  {console.log(budget.tema)};
                  }
                  <td>
                    {budget.desc}
                  </td>
                  <td>
                    {budget.semester}
                  </td>
                  {
                   this.renderAllocations(budget.allocations)
                  }
                  <td className="nominal">
                    {/* {budget.allowed_fund_request === 0?
                    this.renderTotal(budget.allocations):commaFormatted(budget.allowed_fund_request)
                    } */}
                    {commaFormatted(budget.allowed_fund_request)}
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    );
  }
}
MonthlyCheckList.propTypes = {
  budgets: PropTypes.object,
  onChange: PropTypes.func,
  selectedBudget: PropTypes.object,
  amount: PropTypes.string,
  selectedBudgetDetails: PropTypes.object,
};
MonthlyCheckList.defaultProps = {
  budgets: {},
  onChange: noop,
  selectedBudget: {},
  amount: '',
  selectedBudgetDetails: {},
};

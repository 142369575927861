import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { map, noop } from 'lodash';
import Button from '../Button/Button.component';
import { formatData } from '../../../utils/transformer.util';
import { deleteConfirmation } from '../../../utils/alert.util';

export default class Table extends PureComponent {
  constructor(props) {
    super(props);
    this.clickHandlers = {};
    this.onClick = this.onClick.bind(this);
  }

  async onClick(val, action, customAction = noop, idx = null) {
    const { onClickEdit, onClickDelete } = this.props;
    if (action === 'edit') {
      onClickEdit(val);
    } else if (action === 'delete') {
      const willDelete = await deleteConfirmation();
      if (willDelete) {
        onClickDelete(val.id, val);
      }
    } else {
      customAction(val, idx);
    }
  }

  getClickHandler(val, action, customAction = noop, idx = null) {
    this.clickHandlers[`${val.id}_${action}`] = () => this.onClick(val, action, customAction, idx);
    return this.clickHandlers[`${val.id}_${action}`];
  }

  render() {
    const {
      fields,
      data,
      currentPage,
      listAmount,
      showActions,
      customThead,
      customAction,
      showEdit,
      showDelete,
    } = this.props;
    return (
      <table className="table">
        {
          customThead || (
            <thead>
              <tr>
                {
                  map(fields.label, (field, idx) => (
                    <th key={`table_th_${idx}`}>{field}</th>
                  ))
                }
              </tr>
            </thead>
          )
        }
        <tbody>
          {
          map(data, (list, idx) => (
            <tr key={`user_management_tr_${idx}`}>
              {
                map(fields.value, (field, fieldIdx) => {
                  if (fieldIdx === 0) {
                    return (
                      <td className={(field.type === 'number') ? 'nominal' : ''} key={`table_${fieldIdx}_${idx}`}>{(idx + 1) + ((currentPage - 1) * listAmount)}</td>
                    );
                  }
                  return (
                    <td className={(field.type === 'number') ? 'nominal' : ''} key={`table_${fieldIdx}_${idx}`}>{formatData(list, field)}</td>
                  );
                })
              }
              {
                showActions && (
                  <td>
                    <div className="table__actions">
                      {
                        showEdit && (
                          <Button
                            onClick={this.getClickHandler(list, 'edit')}
                            title="Edit"
                          />
                        )
                      }
                      {
                        showDelete && (
                          <Button
                            onClick={this.getClickHandler(list, 'delete')}
                            title="Hapus"
                          />
                        )
                      }
                      {
                        map(customAction, (action, idxAction) => {
                          const { Component, props } = action;
                          const { onClick, ...others } = props;
                          return (
                            <Component
                              key={`custom_action__${idxAction}`}
                              onClick={this.getClickHandler(list, 'custom', onClick, idx)}
                              {...others}
                            />
                          );
                        })
                      }
                    </div>
                  </td>
                )
              }
            </tr>
          ))
        }
        </tbody>
      </table>
    );
  }
}
Table.propTypes = {
  customThead: PropTypes.node,
  customAction: PropTypes.array,
  data: PropTypes.array,
  showActions: PropTypes.bool,
  showEdit: PropTypes.bool,
  showDelete: PropTypes.bool,
  onClickEdit: PropTypes.func,
  onClickDelete: PropTypes.func,
  fields: PropTypes.object.isRequired,
  currentPage: PropTypes.number.isRequired,
  listAmount: PropTypes.number.isRequired,
};
Table.defaultProps = {
  customThead: null,
  customAction: [],
  data: [],
  showActions: true,
  showEdit: true,
  showDelete: true,
  onClickEdit: noop,
  onClickDelete: noop,
};

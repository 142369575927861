import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop } from 'lodash';

export default class Checkbox extends PureComponent {
  constructor(props) {
    super(props);
    this.setRef = this.setRef.bind(this);
  }

  setRef(input) {
    this.input = input;
  }

  render() {
  const {
    checked,
    onChange,
    label,
    error,
    classNames,
    leftIcon,
    rightIcon,
    disabled,
    noMargin,
    center,
    name,
    ...others
  } = this.props;
  const wrapperClassnames = classnames([
    'checkbox',
    noMargin && 'checkbox__no-margin',
  ]);
  const labelClassnames = classnames([
    'checkbox__label',
    error && 'checkbox__label--error',
  ]);
  const containerClassnames = classnames([
    'checkbox__container',
    center && 'checkbox__container--center',
    disabled && 'checkbox__disabled',
    error && 'checkbox__container--error',
  ]);
  const inputClassnames = classnames([
    'checkbox__content',
    leftIcon && 'checkbox__text--icon-left',
    rightIcon && 'checkbox__text--icon-right',
    (leftIcon && rightIcon) && 'checkbox__text--no-border',
    disabled && 'checkbox__text--disabled',
    classNames,
  ]);
  return (
    <div className={wrapperClassnames}>
      <div className={containerClassnames}>
        {label && <label className={labelClassnames}>{label}</label>}
        {
          leftIcon
          && (
            <span className="checkbox__icon-left">
              {
                typeof leftIcon === 'string'
                  ? <i className={leftIcon} />
                  : leftIcon
              }
            </span>
          )
        }
        <label className={inputClassnames}>
          <input
            ref={this.setRef}
            type="checkbox"
            name={name}
            checked={checked}
            onChange={onChange}
            {...others}
          />
          <span className="checkbox__checkmark" />
        </label>
        {
          rightIcon
          && (
            <span className="checkbox__icon-right">
              {
                typeof rightIcon === 'string'
                  ? <i className={rightIcon} />
                  : rightIcon
              }
            </span>
          )
        }
      </div>
      {error && <p className="checkbox__err">{error}</p>}
    </div>
  );
  }
}
Checkbox.propTypes = {
  center: PropTypes.bool,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  noMargin: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  classNames: PropTypes.string,
  leftIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  rightIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
Checkbox.defaultProps = {
  center: false,
  checked: false,
  disabled: false,
  noMargin: false,
  onChange: noop,
  name: '',
  label: '',
  error: '',
  classNames: '',
  leftIcon: '',
  rightIcon: '',
};

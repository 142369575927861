import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop, map, isEqual, has, find } from 'lodash';
import StudentReportFilter from '../../../components/Filter/StudentReport.filter.component';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { ABSENSI_HARIAN } from '../../../constants/student.constant';
import { ABSENSI_HARIAN_TABLE } from '../../../constants/student.constant';
import { validateFundRequest, validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted, normalizeAmount, formatData } from '../../../utils/transformer.util';
import { Button, Input, Checkbox, Pagination, Select } from '../../../components/base/index';
import { DAFTAR_NILAI_LIST_TABLE_FIELDS } from '../../../constants/kurikulum/kurikulum.constant';
import language from '../../../languages';
import { Row } from 'react-bootstrap';

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class DaftarNilai extends Component {
        constructor(props) {
          super(props);
          this._onClickUpload = this._onClickUpload.bind(this);
          this._onFormChange = this._onFormChange.bind(this);
          this.onSubmit = this.onSubmit.bind(this);
          // this.isMount = false;
          this._onSearchContent = this._onSearchContent.bind(this);
          this._onClickNext = this._onClickNext.bind(this);
          this._onClickAdd = this._onClickAdd.bind(this);
          this.handleGetData = this.handleGetData.bind(this);
          this.onChangePage = this.onChangePage.bind(this);
          this._onCheckDetail = this._onCheckDetail.bind(this);
          this._onChangeFilter = this._onChangeFilter.bind(this);
          this._onChangeListAmount = this._onChangeListAmount.bind(this);
          this._setForm = this._setForm.bind(this);
          this.onEdit = this.onEdit.bind(this);
          this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
          this._getPeriodsOptions= this._getPeriodsOptions.bind(this);
          this._getSubjectOptions= this._getSubjectOptions.bind(this);
          // this._getTypeExam= this._getTypeExam.bind(this);
          this._getTypeExamScoreList= this._getTypeExamScoreList.bind(this);
          this._getClassesOptions= this._getClassesOptions.bind(this);
          
          const { user } = this.props;
          const { organizations_id, school_unit } = user;
          const { levels_id } = school_unit;

          this.state = {
            page: 1,
            examsubject:{},
            selected: {},
            content: initialContent,
            unchecked: {},
            filters:{},
            list:[],
            form: {
              value: {
                levels_id,
                organizations_id,
                daftarnilai: [
                  {
                    students_id: '',
                    period: '',
                    classrooms_id: '',
                    date: '',
                    status: '',
                    description: '',
                  }
                ],
              },
              error: {
                classes: '',
                levels: '',
                daftarnilai:''
              },
            },
            param:{},
            
          };
          this._handleFormChange = this._handleFormChange.bind(this);
          this._onSearch = this._onSearch.bind(this);
          this._getParamOptions = this._getParamOptions.bind(this);
        }
      
       componentDidMount() {
        const { location, getLevels, getClasses, user } = this.props;
        const { organizations_id, school_unit } = user;
        const { levels_id, units_id } = school_unit;
        const { state = {} } = location;
        const {isEdit =  false, data} = state;
        const { id } = state.data || '';
          const { content } = this.state;
          const paramTypes = ['classes','levels', 'classrooms'];

          paramTypes.forEach((type) => {
            // this._getParamOptions(type);
            this._getParamOptionsAcademics(type);
          });
          if (isEdit && id) {
            this.setState({id:id})
            this._setForm(data.id);
          } else
          // this.handleGetData();
          // this.isMount = true;
          if (isEmpty(content.list)) {
            this._onSearchContent({ page: 1 });
          }
          this._getPeriodsOptions();
          this._getSubjectOptions({ filters: { units_id: [units_id] }});
          // this._getTypeExam();  
          this._getTypeExamScoreList();     
          this._getClassesOptions({ filters: { levels_id: [levels_id] }});

        }
      
        componentDidUpdate(prevProps) {
          const { location } = this.props;
          const { state = {} } = location;
          const {isEdit =  false, data} = state;
          const { id } = state.data || '';
          if ((!isEdit || !id) && !isEqual(prevProps, this.props)) {
            this._onSearchContent({ page: 1 });
          }
        }

        // async _getTypeExam() {
        //   const { getTypeExam } = this.props;
        //   const res = await getTypeExam();
        //   this.setState(prevState => ({
        //     ...prevState,
        //     param: {
        //       ...prevState.param,
        //       type_exam: res,
        //     },
        //   }));
        // }

        async _getTypeExamScoreList() {
          const { getTypeExamScoreList } = this.props;
          const res = await getTypeExamScoreList();
          this.setState(prevState => ({
            ...prevState,
            param: {
              ...prevState.param,
              type_exam_score_list: res,
            },
          }));
        }

        async _getClassesOptions(filters={}) {
          const { getClassesOptions, user } = this.props;
          const {school_unit} = user;
          const { levels_id } = school_unit;
          filters.levels_id = levels_id;
  
          const res = await getClassesOptions(filters);
          // console.log(res)
          this.setState(prevState => ({
            ...prevState,
            param: {
              ...prevState.param,
              classes: res,
            },
          }));
         
        }

        async _setForm(id) {
          const { handleGetAttendance } = this.props;
          try {
            const payload = await handleGetAttendance({ id });
            this.setState({
              filters: {
                classrooms_id: payload.classrooms_id,
                period: payload.period,
                date: payload.date,
              },
              form: {
                value: {
                  id: payload.id,
                  date: payload.date,
                  period: payload.period,
                  daftarnilai: map(payload.daftarnilai, detail => ({
                    classrooms_id: detail.classrooms_id,
                    name: detail.name,
                    date: detail.date,
                    students_id: detail.students_id,
                    status: detail.status,
                    description: detail.description,
                   
                  })),
                },
                error: {
                  daftarnilai: [],
                },
              },
            });
          } catch (err) {
            // err action
          }
        }

        async _getSubjectOptions(filters={}) {
          const { getSubjectOptions, user } = this.props;
          const { organizations_id, school_unit } = user;
          const { levels_id, units_id } = school_unit;
          const { form } = this.state;
          const {subject_id } = form.value;
          const res = await getSubjectOptions(filters);
          const selectedSubject = res.find(item => item.value == subject_id);
          filters.units_id = units_id;

          this.setState(prevState => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                kkm: selectedSubject ? selectedSubject.attributes.kkm : '' 
              }
            },
            param: {
              ...prevState.param,
              subject: res,
            },
          }));
        }

        async _onSearchContent(params = {}) {
          const { filters, form } = this.state;
          const { value } = form;
          const { user } = this.props;
          const { organizations_id } = user;
            try {
              const { handleListScoreStudents, handleListTypeExam, user } = this.props;
              const { school_unit } = user;
              const { units_id } = school_unit;
              const result = await handleListScoreStudents({ ...params, organizations_id, units_id, 
                filters: {
                  classrooms_id:value.classrooms_id,
                  subject_id:value.subject_id,
                  period:value.period,
                  semester:value.semester,
                } });
              const res = await handleListTypeExam({...params, organizations_id,
                filters: {
                  subject_id:value.subject_id,
                  classrooms_id:value.classrooms_id,
                }});
                if (result) {
                  result.forEach(item => {
                    if (!item.description) {
                      item.description = '';
                    }
                  });
                } 
                this.setState(prevState => ({
                  ...prevState,
                  form: {
                    ...prevState.form,
                    value: {
                      ...prevState.form.value,
                      daftarnilai: result,
                    }
                  },
                  examsubject: res,
                }));
                
              // }
            } catch (err) {
                this.setState(prevState => ({
                  ...prevState,
                  form: {
                    ...prevState.form,
                    value: {
                      ...prevState.form.value,
                      daftarnilai: [],
                    }
                  },
                  examsubject: [],
                }));
                
            }
            // }
        }

        _onChangeListAmount(event) {
          const { keywords } = this.state;
          this.setState({
            listAmount: Number(event.target.value),
          });
          this._onSearchContent({ limit: event.target.value, keywords, page: 1 });
        }

        async _getPeriodsOptions() {
          const { getPeriodsOptions } = this.props;
          const res = await getPeriodsOptions();
          this.setState(prevState => ({
            ...prevState,
            param: {
              ...prevState.param,
              periods: res,
            },
          }));
          
        }

        async onEdit() {
          const { history, handleEditAttendance } = this.props;
          const { form } = this.state;
          const { value } = form;
          const res = await handleEditAttendance(value);        
          history.push('/dashboard/kesiswaan/absensi-siswa/absensi-rekap');
        }

        _onChangeFilter(e) {
          const { target } = e;
          const { value, name } = target;
          const { user } = this.props;
          const { organizations_id } = user;
          this.setState(prevState => ({
            ...prevState,
            filters: {
              ...prevState.filters,
              [name]: value,
            },
          }), () =>{
            if (name === 'levels_id') {
              this._getParamOptionsAcademics( 'classrooms', { filters: { levels_id: value, organizations_id }} );
            } this._onSearchContent()});
        
        }
        _onChangePage(page) {
          const { listAmount, keywords } = this.state;
          const offset = listAmount * (page - 1);
          // this.handleGetData({
          this._onSearchContent({
            limit: listAmount,
            keywords,
            offset,
            page,
          });
        }

        async handleGetData(payload = {}) {
          const { handleListStudentAttendance, handleListAnnouncementStudent, user } = this.props;
          const { units_id, workingUnit, id, user_group, profile, organizations_id } = user;
          let unit_id = units_id;
      
          if (unit_id === null && workingUnit && workingUnit.id) {
            unit_id = workingUnit.id;
          }
          // if(user_group.id === 2){
            const res = await handleListStudentAttendance({...payload, user_id: id, organizations_id});
      
          this.setState(prevState => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                daftarnilai: res,
              }
            }
          }));
      
         
          
        }
        _handleFormChange(event) {
            const {
              name,
              value,
            } = event.target;
            this.setState(prevState => ({
              form: {
                value: {
                  ...prevState.form.value,
                  [name]: value,
                },
                error: {
                  ...prevState.form.error,
                  [name]: '',
                },
              },
            }));
          }
        
          _onSearch() {
            const { form } = this.state;
            const error = validateRequiredFields(form.value, ['year']);
            this.setState(prevState => ({
              form: {
                value: prevState.form.value,
                error,
              },
            }));
            if (isEmpty(error)) {
              this.setState({ filtered: true });
            }
          }


        _onFormChange(event) {
          const {
            name,
            value,
            dataset,
            checked,
            type,
          } = event.target;
          const {
            inputType = 'text', inputArray = false, arrayPosition = 0,
            fieldName,
          } = dataset;
          const { user } = this.props;
          const { organizations_id } = user;
          this.setState((prevState) => {
            let newList = [];
            let newListError = [];
            let formattedValue = value;
      
            if (inputType === 'number') {
              formattedValue = normalizeAmount(value);
            }
            if (inputArray) {
              if (type === 'checkbox') {
                formattedValue = checked ? value : '';
              }
              newList = prevState.form.value[fieldName];
              newListError = prevState.form.error[fieldName] || [];
              newList[arrayPosition][name] = formattedValue;
              if (name === 'code_of_account') {
                newList[arrayPosition].isCredit = value.type;
              }
              if (!isEmpty(newListError[arrayPosition])) {
                newListError[arrayPosition][name] = '';
              }
            }
            return {
              form: {
                value: {
                  ...prevState.form.value,
                  ...(inputArray
                    ? { [fieldName]: newList }
                    : { [name]: formattedValue }),
                },
                error: {
                  ...prevState.form.error,
                  ...(inputArray
                    ? { [fieldName]: newListError }
                    : { [name]: '' }),
                },
              },
            };
          }, () => {
            if (name === 'levels_id') {
              this._getParamOptionsAcademics( 'classrooms', {levels_id: value, organizations_id });
            }
            if (name === 'classes_id') {
              this._getParamOptionsAcademics( 'classrooms', {classes_id: value, organizations_id } );
              this._getSubjectOptions({ filters: {classes_id: value} } );
            
            }
            if(name==='classrooms_id' || name === 'period' || name === 'semester' || name === 'subject_id'){
              this._onSearchContent()
            }
          });
        }
        _onClickUpload() {
          const { history } = this.props;
          history.push('/dashboard/kesiswaan/absensi-siswa/upload-absensi-harian');
        }
      
        _onClickAdd(data) {
          const { form } = this.state;
          const { plafon } = form.value;
          
          plafon.push({
            tipePlafon: '',
            // divisi: '',
            nominal: 0,
            //period:'',
          });
          
          this.setState(prevState => ({
            form: {
              value: {
                ...prevState.form.value,
                plafon,
              },
              error: {
                ...prevState.form.error,
              }
            }
          }));
        }
      
        onChangePage(page) {
          const { listAmount, keywords } = this.state;
          const offset = listAmount * (page - 1);
          this.handleGetData({
            limit: listAmount,
            keywords,
            offset,
            page,
          });
        }
      
        

        _onCheckDetail(event) {
          const { target } = event;
          const { value, checked } = target;
          const { budgets, selectedBudgetDetails } = this.props;
          const { selected, unchecked } = this.state;
          let newState = { ...this.state };
      
          newState = this._setNewState(newState, checked, value);
      
          this.setState({
            ...newState
          });
        }
      
        async onSubmit() {
          const { history, handlesaveScoreList } = this.props;
          const { form } = this.state;
          const { value } = form;        
          let canSaveA = false;
          const dataForm = form.value;
          // if(dataForm.name && dataForm.publish_date && dataForm.tgl_selesai && dataForm.levels && dataForm.classes){
          //   canSaveA = true;
          // } else {
          //   canSaveA = false;
          // }
          // if (canSaveA == false){
          //   alert("Harap lengkapi data form. Bagian yang harus diisi ditandai dengan tanda bintang (*).");
          // } else {
            await handlesaveScoreList(value);
            history.push('/dashboard/kurikulum/ulangan/daftar-nilai');
            history.go(0)

          // }
        }
      
        
        _onClickNext() {
            window.scrollTo(0, 0);
            this.setState(prevState => ({
              page: prevState.page + 1,
            }));
          }
        async _getParamOptions(type, filters={}) {
          const {getParamOptions, user} = this.props;
          const {organizations_id} = user;
          if (type === 'classes' || type === 'classrooms') {
            filters.organizations_id = organizations_id;
          } else {
            delete filters.organizations_id;
          }
          const res = await getParamOptions(filters, type);
          this.setState(prevState => ({
            ...prevState,
            param: {
              ...prevState.param,
              [type]: res,
            },
          }));
        }

        async _getParamOptionsAcademics(type, filters={}) {
          const { getParamOptionsAcademics, user } = this.props;
          const { organizations_id, school_unit } = user;
          const { levels_id } = school_unit;
          if (type === 'classrooms' || type === 'classrooms') {
            filters.organizations_id = organizations_id;
            filters.levels_id = levels_id;
          } else {
            delete filters.organizations_id;
          }
          const res = await getParamOptionsAcademics(filters, type);
          this.setState(prevState => ({
            ...prevState,
            param: {
              ...prevState.param,
              [type]: res,
            },
          }));
        }

        _renderTypeExamUH(student) {
          const { param = {}, examsubject ={}} = this.state;
          const { cognitive } = student;
          const row = [];

          map(examsubject, (data, idx) => {
            const cognitiveRow = cognitive.find(item => item.id == data.cognitive_value_id) || {};
            // console.log(cognitiveRow.id)
            if(data.id == 1){
            row.push([
               <td>
                 {cognitiveRow.score_students}
               </td>
            ])
          };
          });
          return row;
        }  

        _renderTypeExamKO(student) {
          const { param = {}, examsubject ={}} = this.state;
          const { cognitive } = student;
          const row = [];
          map(examsubject, (data, idx) => {
            const cognitiveRow = cognitive.find(item => item.id == data.cognitive_value_id) || {};
            if(data.id == 2){
            row.push([
               <td>
                 {cognitiveRow.score_students}
               </td>
            ])
          };
          });
      
          return row;
        }  

        _renderTypeExamPR(student) {
          const { param = {}, examsubject ={}} = this.state;
          const { cognitive } = student;
          const row = [];
      
          map(examsubject, (data, idx) => {
            const cognitiveRow = cognitive.find(item => item.id == data.cognitive_value_id) || {};
            if(data.id == 3){
            row.push([
               <td>
                 {cognitiveRow.score_students}
               </td>
            ])
          };
          });
      
          return row;
        }  

        _renderTypeExamUM(student) {
          var um = true;
          const { param = {}, examsubject ={}} = this.state;
          const { cognitive } = student;
          const row = [];
          
          map(examsubject, (data, idx) => {
            const cognitiveRow = cognitive.find(item => item.type_of_exam == data.id) || {};
            if(data.id == 4){
              row.push([
                 <td>
                   {cognitiveRow.score_students}
                 </td>
              ])
              um = !true;
            }
          });
          if (um == true) {
            row.push(<td></td>)
          }
          return row;
          // return ;
        }  

        _renderTypeExamUU(student) {
          var uu = true;
          const { param = {}, examsubject ={}} = this.state;
          const { cognitive } = student;
          const row = [];
      
          map(examsubject, (data, idx) => {
            const cognitiveRow = cognitive.find(item => item.type_of_exam == data.id) || {};
            if(data.id == 5){
              row.push([
                 <td>
                   {cognitiveRow.score_students}
                 </td>
              ])
              uu = !true;
              // return row;

            }
            

          //   if(data.id == 5){
          //   row.push([
          //      <td>
          //        {cognitiveRow.score_students}
          //      </td>
          //   ])
          // };
          });
          if (uu == true) {
            row.push(<td></td>)
          }
          return row;
        }  
      
        render() {
          const {param, form,
                 page, list = {}, selected, checkedAll, content, filters, listAmount, examsubject } = this.state;
          const {daftarnilai} = form.value;
          const {
            classrooms_id = '',
            semester='',
            period='',
            subject_id='',
          } = filters;
          const semesters = [
            { label: 'Ganjil', value: 'ganjil'},
            { label: 'Genap', value: 'genap'},
          ]
          const {  location } = this.props;
          const loadingProps = { show: content.loading };
          const academic_year = [
            { label: '2019', value: '2019'},
            { label: '2020', value: '2020'},
            { label: '2021', value: '2021'},
          ]
          
          let lengthUH = 0;
          let lengthKO = 0;
          let lengthPR = 0;
          let idx = 0;
          var iduh = 1;
          var idko = 1;
          var idpr = 1;
          { map(examsubject, item => {
            if(item.id===1){
              return(
                lengthUH++
              )
            }
            if(item.id===2){
              return(
                lengthKO++
              )
            }
            if(item.id===3){
              return(
                lengthPR++
              )
            }
               
          })}
          return (
            <div className="manage-registration">
                <div className="budget__title">
                    <h1>Daftar Nilai</h1>
               </div>
               <div className="kode-matpel__custom-form">
               <div className="kode-matpel__custom-form-row">
                  <div className="kode-matpel__custom-form-row__field">
                    <Select
                      label="Tahun Ajaran"
                      name="period"
                      onChange={this._onFormChange}
                      placeholder="Pilihan"
                      data={param.periods}
                      value={form.value.period}
                    />
                  </div>
                  <div className="kode-matpel__custom-form-column__field">
                    <Select
                      name="semester"
                      label="Semester"
                      placeholder={'Pilihan'}
                      onChange={this._onFormChange}
                      data={semesters}
                      value={form.value.semester}
                    />
                </div>
              </div>
                <div className="kode-matpel__custom-form-row">
                  <div className="kode-matpel__custom-form-row__field">
                    <Select
                      type="text"
                      name="levels_id"
                      label="Unit"
                      disabled
                      placeholder="Pilih Unit"
                      data={param.levels}
                      value={form.value.levels_id}
                      onChange={this._onFormChange}
                      error={form.error.levels_id || ''}
                      />
                  </div>
                  <div className="data-pelanggaran__custom-form-row__field">
                    <Select
                      name="classes_id"
                      label="Tingkat Kelas"
                      onChange={this._onFormChange}
                      data={param.classes}
                      placeholder={'Pilihan'}
                      value={form.value.classes_id}
                      error={form.error.classes_id || ''}
                    />
                  <div className="data-pelanggaran__custom-form-row__field"></div>
                    <Select
                      name="classrooms_id"
                      label="Kelas"
                      onChange={this._onFormChange}
                      placeholder="Pilih Kelas"
                      data={param.classrooms}
                      value={form.value.classrooms_id}
                      error={form.error.classrooms_id || ''}
                    />
                  </div>
              </div>
              <div className="kode-matpel__custom-form-row">
                <div className="kode-matpel__custom-form-row__field">
                <Select
                      label="Mata Pelajaran"
                      name="subject_id"
                      placeholder="Pilihan"
                      data={param.subject}
                      onChange={this._onFormChange}
                      value={form.value.subject_id}
                      // error={form.error.subject_id|| ''}
                    />
                  
                </div>
        
                <div className="kode-matpel__custom-form-column__field">
                   {/* <Input
                      type='date'
                      name="date"
                      label="Tanggal"
                      onChange={this._onFormChange}
                      value={form.value.date}
                      error={form.error.date || ''}
                    /> */}
                </div>
               </div>
            </div>

         <div className="fund-request__content">
            <table className="table-daftarnilai">
              <thead>
                <tr>
                  <th rowSpan={2}>No</th>
                  <th rowSpan={2}>Nis</th>
                  <th rowSpan={2}>Nama Siswa</th>
                  <th rowSpan={2}>Guru</th>
                  <th colSpan={lengthUH+1}>Ulangan Harian/Test Lisan</th>
                  <th rowSpan={2}>NH</th>
                  <th colSpan={lengthKO+1}>Ko-Kurikuler/Test Lisan</th>
                  <th colSpan={lengthPR+1}>Praktek/Penugasan</th>
                  {/* <th colSpan={length}>Tanggal Masuk Kelas</th> */}
                  <th colSpan={5}>Kesimpulan</th>

                </tr>
                <tr>
                 
                  { map(examsubject, (item, idx) => {
                    if(item.id == 1){
                      return(
                        <th >{item.code}{iduh++}</th> 
                      )
                    }
                  })}
                   
                 
                  <th>UH</th>

                  { map(examsubject, (item, idx) => {
                    if(item.id == 2){
                      return(
                        <th >{item.code}{idko++}</th> 
                      )
                    }
                  })}
                  <th>KO</th>

                  {map(examsubject, (item, idx) => {
                    if(item.id == 3){
                      return(
                        <th >{item.code}{idpr++}</th> 
                      )
                    }
                  })}
                  <th>PR</th>
                  <th>MPR</th>
                  <th>FPR</th>
                  <th>UM</th>
                  <th>UU</th>
                  {/* <th>RNH</th>
                  <th>RPR</th>
                  <th>NR</th>
                  <th>COMMENT</th> */}





                </tr>
              </thead>
              <tbody>
                {
                    map(daftarnilai, (item, idx) => (
                      <tr key={`budget_row_${idx}`}>
                        <td>{(idx + 1)}</td>
                        <td>{item.nis}</td>
                        <td>{item.name}</td>
                        <td>{item.teacher}</td>
                        {this._renderTypeExamUH(item)}
                      
                        <td>{item.uh}</td>
                        <td>{item.nh}</td>

                        {this._renderTypeExamKO(item)}
                        <td>{item.ko}</td>

                        {this._renderTypeExamPR(item)}
                        <td>{item.pr}</td>
                        <td>{item.mpr}</td>
                        <td>{item.fpr}</td>
                        {item.cognitive!=''?
                        this._renderTypeExamUM(item):
                        <td></td>
                        }
                        
                        {item.cognitive!=''?
                        this._renderTypeExamUU(item):
                        <td></td>
                       }
                        {/* <td><Input
                            type="number"
                            name="rnh"
                            data-input-array={true}
                            data-array-position={idx}
                            data-field-name='daftarnilai'
                            onChange={this._onFormChange}
                            value={item.rnh}
                          /></td>
                        <td><Input
                            type="number"
                            name="rpr"
                            data-input-array={true}
                            data-array-position={idx}
                            data-field-name='daftarnilai'
                            onChange={this._onFormChange}
                            value={item.rpr}
                          /></td>
                        <td><Input
                            type="number"
                            name="nr"
                            data-input-array={true}
                            data-array-position={idx}
                            data-field-name='daftarnilai'
                            onChange={this._onFormChange}
                            value={item.nr}
                          /></td>
                         <td><Input
                            type="text"
                            name="comment"
                            data-input-array={true}
                            data-array-position={idx}
                            data-field-name='daftarnilai'
                            onChange={this._onFormChange}
                            value={item.comment}
                          /></td> */}
                    </tr>
                    ))
                  }
              </tbody>
            </table>
          </div>
   
          <div className="student-list__header">
        
          {/* <div className="student-list__button">
              <Button
                type="button"
                title="Simpan"
                onClick={this.onSubmit}
              />
          </div> */}
          </div>
              
            </div>
          );
        }
}
DaftarNilai.propTypes = {
  getParamOptions: PropTypes.func,  
  history: PropTypes.object.isRequired,
  handleGetAttendance: PropTypes.func,
  // handleGetLevels: PropTypes.func,
};
DaftarNilai.defaultProps = {
  getParamOptions: noop,
  handleGetAttendance: noop,
  // handleGetLevels: noop,
};
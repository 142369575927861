import { connect } from 'react-redux';
import PindahKelas from '../../../views/Akademi/DataKelas/PindahKelas.view';
import { getCodeOfAccount } from '../../../states/thunks/common.thunk';
import { getCoaOptions } from '../../../states/thunks/options.thunk';
import { getClasses, getPeriodsOptions, getLevels, getParamOptions, getClassroomOptions,getStudentOptions, getClassesOptions, getParamOptionsAcademics } from '../../../states/thunks/options.thunk';
import { listStudentChangeStudent, saveStudentChange, getClassDiv, getClassData } from '../../../states/thunks/academics.thunk'


function mapStateToProps(state) {
  return {
    user: state.user,
    classes: state.options.classes,
    levels: state.options.levels,
    result: state.result,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetCodeOfAccount: code => dispatch(getCoaOptions(code)),
    handleGetLevels: payload => dispatch(getLevels(payload)),
    getStudentOptions: (payload) => dispatch(getStudentOptions(payload)),
    getClassroomsOptions: (payload) => dispatch(getClassroomOptions(payload)),
    getClassesOptions: (payload) => dispatch(getClassesOptions(payload)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
    handleGetClasses: (payload) => dispatch(getClasses(payload)),
    handleGetClassDiv: payload => dispatch(getClassDiv(payload)),
    handleGetClassData: payload => dispatch(getClassData(payload)),
    handleListStudentChangeStudent: (payload, goback) => dispatch(listStudentChangeStudent(payload, goback)),
    handleSaveStudentChange: (payload, goback) => dispatch(saveStudentChange(payload, goback)),
    getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PindahKelas);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop, map, isEqual, has, find } from 'lodash';
import StudentReportFilter from '../../../components/Filter/StudentReport.filter.component';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { ABSENSI_HARIAN } from '../../../constants/student.constant';
import { ABSENSI_HARIAN_TABLE } from '../../../constants/student.constant';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted, normalizeAmount, formatData } from '../../../utils/transformer.util';
import { Button, Input, Checkbox, Pagination, Select } from '../../../components/base/index';
import language from '../../../languages';
import { UPLOAD_RPP_LIST_TABLE_FIELDS } from '../../../constants/Student/student.constant';
import { PENGISIAN_PSIK__AFEK_LIST_TABLE_FIELDS } from '../../../constants/Student/student.constant';
import { Link } from 'react-router-dom';


const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class UploadNilai extends Component {
  constructor(props) {
    super(props);
    this._onClickUpload = this._onClickUpload.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._onUpload = this._onUpload.bind(this);
    // this.onSubmit = this.onSubmit.bind(this);
    this._getSubjectOptions= this._getSubjectOptions.bind(this);
    this._getRppOptions= this._getRppOptions.bind(this);
    this._getPeriodsOptions= this._getPeriodsOptions.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    // this.onClickAdd = this.onClickAdd.bind(this);
    // this.onClickAddFolder = this.onClickAddFolder.bind(this);
    // this.handleGetData = this.handleGetData.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this._onCheckDetail = this._onCheckDetail.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onChangeListAmount = this._onChangeListAmount.bind(this);
    this._setForm = this._setForm.bind(this);
    this._renderButtons = this._renderButtons.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this._getClassesOptions= this._getClassesOptions.bind(this);
    
    const { user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;
    this.state = {
      page: 1,
      selected: {},
      content: initialContent,
      unchecked: {},
      filters:{},
      list:[],
      form: {
        value: {
          levels_id,
          // description: '',
          organizations_id,
          nilaikognitif: [
            {
              id:'',
              score_students:'',
              students_id: '',

            }
          ],
        },
        error: {
          classes: '',
          levels: '',
          nilaikognitif:''
        },
      },
      param:{},
      filtered: false,

    };
    this._handleFormChange = this._handleFormChange.bind(this);
    this._onSearch = this._onSearch.bind(this);
    this._getParamOptions = this._getParamOptions.bind(this);
  }

  componentDidMount() {
  const { location, getLevels, getClasses, user } = this.props;
  const { school_unit } = user;
  const { levels_id } = school_unit;
  const { state = {} } = location;
  const {isEdit =  false, data} = state;
  const { id } = state.data || '';
    const { content } = this.state;
    const paramTypes = ['classes','levels', 'classrooms'];

    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
    if (isEdit && id) {
      this.setState({id:id})
      this._setForm(data.id);
    } else
    if (isEmpty(content.list)) {
      this._onSearchContent({ page: 1 });
    }
    this._getClassesOptions({ filters: { levels_id: [levels_id] }});
    this._getSubjectOptions();       
    this._getRppOptions();       
    this._getPeriodsOptions();       
  
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { state = {} } = location;
    const {isEdit =  false, data} = state;
    const { id } = state.data || '';
    if (!isEqual(prevProps, this.props)) {
      this._onSearchContent({ page: 1 });
    }
  }

  async _getClassesOptions(filters={}) {
    const { getClassesOptions, user } = this.props;
    const { school_unit } = user;
    const { levels_id } = school_unit;
    filters.levels_id = levels_id;

    const res = await getClassesOptions(filters);
    // console.log(res)
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        classes: res,
      },
    }));
    
  }

  async _setForm(id) {
    const { handleGetNilaiKognitif, user } = this.props;
    const { organizations_id } = user;
    try {
      const payload = await handleGetNilaiKognitif({ id });
      const { classes, levels_id, classes_id } = payload;
      this._getClassesOptions({ filters: { levels_id: [levels_id] }} );
      this._getParamOptionsAcademics( 'classrooms',  { classes_id: classes_id, organizations_id } );            
      this.setState({
        filters: {
          classrooms_id: payload.classrooms_id,
          period: payload.period,
          date: payload.date,
        },
        form: {
          value: {
            // description: data.description,
            
            nilaikognitif: map(payload.nilaikognitif, detail => ({
              id: detail.id,
              score_students: detail.score_students,
              students_id: detail.students_id,
              
            })),
          },
          error: {
            nilaikognitif: [],
          },
        },
      });
    } catch (err) {
    }
  }

  async _onSearchContent(params = {}) {
    const { filters, form } = this.state;
    const { value } = form;
    const { menu } = value;
    this.setState({
      content: initialContent,
    }, async () => {
      try {
        const { handleListNilaiKognitif, handleListPsikAfek, user } = this.props;
        const { organizations_id, school_unit } = user;
        const { units_id } = school_unit;
        let res = {};
        if (menu === undefined) {
            res = await handleListNilaiKognitif({ ...params,  organizations_id, units_id, filters });
        } else 
        if (menu === '1') {
          res = await handleListNilaiKognitif({ ...params,  organizations_id, units_id, filters });
        } else if (menu == '2') {
          res = await handleListPsikAfek({ ...params,  organizations_id, units_id, filters });
        }

        console.log(res);
        const {
          result = [], currentPage = 1, total = 0, totalPage = 1,
        } = res;
        
        if (result) {
          this.setState({
            content: {
              loading: false,
              list: result,
              currentPage,
              total,
              totalPage,
            },
          });
        }
      } catch (err) {
        // if (this.isMount) {
          this.setState({
            content: {
              loading: false,
              list: [],
              currentPage: 1,
              total: 1,
              totalPage: 1,
              error: true,
            },
          });
        }
      // }
    });
  }


  onClickEdit(val) {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/ulangan/tambah-penilaian-nilai-kognitif', { data: val, isEdit: true });
  }

  onClickDelete(idx) {
    this.setState((prevState) => {
      const { nilaikognitif } = prevState.form.value;
      const list = prevState.form.value.nilaikognitif;
      list.splice(idx, 1);
      return {
        ...prevState,
        nilaikognitif: list
      };
    });
  }

  _onChangeListAmount(event) {
    const { keywords } = this.state;
    this.setState({
      listAmount: Number(event.target.value),
    });
    this._onSearchContent({ limit: event.target.value, keywords, page: 1 });
  }



  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    const { user } = this.props;
    const { organizations_id } = user;
    this.setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        [name]: value,
      },
    }), () =>{
      if (name === 'levels_id') {
        this._getParamOptionsAcademics( 'classrooms', { filters: { levels_id: value, organizations_id }} );
      } this._onSearchContent()});
  
  }
  _onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this._onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  async _getSubjectOptions() {
    const { getSubjectOptions } = this.props;
    const res = await getSubjectOptions();
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        subject: res,
      },
    }));
    
  }

  async _getRppOptions() {
    const { getRppOptions } = this.props;
    const res = await getRppOptions();
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        rpp_types: res,
      },
    }));
    
  }

  async _getPeriodsOptions() {
    const { getPeriodsOptions } = this.props;
    const res = await getPeriodsOptions();
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
    
  }

  async handleGetData(payload = {}) {
    const { handleListStudentAttendance, handleListAnnouncementStudent, user } = this.props;
    const { units_id, workingUnit, id, user_group, profile, organizations_id } = user;
    let unit_id = units_id;

    if (unit_id === null && workingUnit && workingUnit.id) {
      unit_id = workingUnit.id;
    }
      const res = await handleListStudentAttendance({...payload, user_id: id, organizations_id});

    this.setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          absensiharian: res,
        }
      }
    }));
  }
  _handleFormChange(event) {
      const {
        name,
        value,
      } = event.target;
      this.setState(prevState => ({
        form: {
          value: {
            ...prevState.form.value,
            [name]: value,
          },
          error: {
            ...prevState.form.error,
            [name]: '',
          },
        },
      }));
    }
  
    _onSearch() {
      const { form } = this.state;
      const error = validateRequiredFields(form.value, ['year']);
      this.setState(prevState => ({
        form: {
          value: prevState.form.value,
          error,
        },
      }));
      if (isEmpty(error)) {
        this.setState({ filtered: true });
      }
    }


  _onFormChange(event) {
    const {
      name,
      value,
      dataset,
      checked,
      type,
    } = event.target;
    const {
      inputType = 'text', inputArray = false, arrayPosition = 0,
      fieldName,
    } = dataset;
    const { user } = this.props;
    const { organizations_id } = user;
    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        if (type === 'checkbox') {
          formattedValue = checked ? value : '';
        }
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName] || [];
        newList[arrayPosition][name] = formattedValue;
        if (name === 'code_of_account') {
          newList[arrayPosition].isCredit = value.type;
        }
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = '';
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray
              ? { [fieldName]: newListError }
              : { [name]: '' }),
          },
        },
      };
    }, () => {
      // if (name === 'levels_id') {
      //   this._getParamOptionsAcademics( 'classrooms', {levels_id: value, organizations_id: 4 });
      // }
      if (name === 'levels_id') {
        this._getClassesOptions({ filters: { levels_id: [value] }} );
      }
      if (name === 'classes_id') {
        this._getParamOptionsAcademics( 'classrooms', {classes_id: value, organizations_id } );
      }
      this._onSearchContent();
    });
  }

  _renderButtons(data) {
    const { user } = this.props;
    const {user_group} = user;
    const button = {};
    
    return (
      <td>
        <div className="table__actions">
        
              <Button
              onClick={() => this.onClickEdit(data)}
              title='Edit'
            />
        </div>
      </td>
    );
  }

  _onClickUpload() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/absensi-siswa/upload-absensi-harian');
  }

  // _onClickAdd(data) {
  //   const { form } = this.state;
  //   const { plafon } = form.value;
    
  //   plafon.push({
  //     tipePlafon: '',
  //     nominal: 0,
  //   });
    
  //   this.setState(prevState => ({
  //     form: {
  //       value: {
  //         ...prevState.form.value,
  //         plafon,
  //       },
  //       error: {
  //         ...prevState.form.error,
  //       }
  //     }
  //   }));
  // }

  onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this._onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  _onUpload() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/rencana-penilaian/upload-document');
  }
  _onSubmit() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/rencana-penilaian/upload');
  }

  // onClickAddFolder() {
  //   const { history } = this.props;
  //   history.push('/dashboard/kurikulum/ulangan/tambah-penilaian-nilai-kognitif', { isEdit: true });
  // }

  _onCheckDetail(event) {
    const { target } = event;
    const { value, checked } = target;
    const { budgets, selectedBudgetDetails } = this.props;
    const { selected, unchecked } = this.state;
    let newState = { ...this.state };

    newState = this._setNewState(newState, checked, value);

    this.setState({
      ...newState
    });
  }

  // async onSubmit() {
  //   const { history, handleSaveAttendance } = this.props;
  //   const { form } = this.state;
  //   const { value } = form;        
  //   let canSaveA = false;
  //   const dataForm = form.value;
  //     await handleSaveAttendance(value);
  //     history.push('/dashboard/kesiswaan/absensi-siswa/absensi-rekap');
  // }

  
  _onClickNext() {
      window.scrollTo(0, 0);
      this.setState(prevState => ({
        page: prevState.page + 1,
      }));
    }
  async _getParamOptions(type, filters={}) {
    const {getParamOptions, user} = this.props;
    const { organizations_id } = user;
    if (type === 'classes' || type === 'classrooms') {
      filters.organizations_id = organizations_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptions(filters, type);
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getParamOptionsAcademics(type, filters={}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id } = user;
    if (type === 'classrooms' || type === 'classrooms') {
      filters.organizations_id = organizations_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  render() {
    const {param, form,
            page, list = {}, selected, checkedAll, content, filters, listAmount } = this.state;
    const {absensiharian} = form.value;
    const componentRef = React.createRef();
    const {
      classrooms_id = '',
      Semester = '',
      Menu = '',
      subject_id='',
    } = filters;
    const {  location } = this.props;
    const loadingProps = { show: content.loading };
    const academic_year = [
      { label: '2019', value: '2019'},
      { label: '2020', value: '2020'},
      { label: '2021', value: '2021'},
    ]
    const semester = [
      { label: 'Ganjil', value: 'ganjil'},
      { label: 'Genap', value: 'genap'},
    ]
    const menu = [
      { label: 'Nilai Kognitif', value: '1'},
      { label: 'Nilai PSIK & AFEK', value: '2'},
    ]
    console.log(form.value.menu)
    return (
      <div className="student-list">

        
        <br></br>
        <br></br>
        <div className="nilai-kognitif__custom-form">
          <div className="nilai-kognitif__custom-form-row">
            <div className="nilai-kognitif__custom-form-row__field">
              <Select
                label="Tahun Ajaran"
                name="periods"
                onChange={this._onFormChange}
                placeholder="Pilihan"
                data={param.periods}
                value={form.value.periods}
                error={form.error.periods || ''}
              />
            </div>
            <div className="nilai-kognitif__custom-form-column__field">
              <Select
                name="Semester"
                label="Semester"
                onChange={this._onChangeFilter}
                value={Semester}
                data={semester}
                placeholder={'Pilihan'}
              />
            </div>
          </div>
          
          <div className="nilai-kognitif__custom-form-row">
            <div className="nilai-kognitif__custom-form-column__field">
              <Select
                name="levels_id"
                label="Unit"
                disabled
                onChange={this._onFormChange}
                data={param.levels}
                value={form.value.levels_id}
                placeholder={'Pilihan'}
                error={form.error.levels_id || ''}
              />
            </div>
            <div className="nilai-kognitif__custom-form-column__field">
              <Select
                name="classes_id"
                label="Tingkat Kelas"
                onChange={this._onFormChange}
                data={param.classes}
                placeholder={'Pilihan'}
                value={form.value.classes_id}
                error={form.error.classes_id || ''}
              />
            
            </div>
          </div>

          <div className="nilai-kognitif__custom-form-row">
            <div className="nilai-kognitif__custom-form-column__field">
              <Select
                placeholder="Pilih Kelas"
                label = "Kelas"
                name="classrooms_id"
                value={classrooms_id}
                data={param.classrooms}
                onChange={this._onChangeFilter}
              />
            
            </div>
            <div className="nilai-kognitif__custom-form-column__field">
              <div className="nilai-kognitif__custom-form-row__field">
                <Select
                  name="rpp_types"
                  label="Jenis"
                  onChange={this._onFormChange}
                  data={param.rpp_types}
                  placeholder="Pilih Jenis"
                  value={form.value.rpp_types}
                  error={form.error.rpp_types || ''}
                />              
              </div>
            
            </div>
          </div>

          <div className="nilai-kognitif__custom-form-row">
            <div className="nilai-kognitif__custom-form-row__field">
              <Select
                name="subject_id"
                label="Mata Pelajaran"
                onChange={this._onChangeFilter}
                data={param.subject}
                placeholder="Pilih Matapelajaran"
                value={subject_id}
                error={form.error.subject_id || ''}
              />
            </div>
            <div className="nilai-kognitif__custom-form-column__field">
              <Input
                // disabled={!isEdit}
                name="id"
                label="ID"
                onChange={this._onFormChange}
                value={form.value.id}
                error={form.error.id || ''}
              />
            </div>
          </div>
          
          <div className="nilai-kognitif__custom-form-row">
            
            <div className="nilai-kognitif__custom-form-column__field">
              <Input
                // disabled={!isEdit}
                name="judul_dokumen"
                label="Judul Dokumen"
                onChange={this._onFormChange}
                value={form.value.judul_dokumen}
                error={form.error.judul_dokumen || ''}
              />
            </div>
            <div className="nilai-kognitif__custom-form-column__field">
              <Input
                // disabled={!isEdit}
                name="description"
                label="Deskripsi"
                onChange={this._onFormChange}
                value={form.value.description}
                error={form.error.description || ''}
              />
            </div>
          </div>

          <div className="nilai-kognitif__custom-form-row">
            
            
            <div className="nilai-kognitif__custom-form-column__field">
              <div className="nilai-kognitif__custom-form-row__field">
                <Input
                  // disabled={!isEdit}
                  name="max_size"
                  label="Max Size 2mg"
                  onChange={this._onFormChange}
                  value={form.value.max_size}
                  error={form.error.max_size || ''}
                />
              
              </div>
            
            </div>
          
            <div className="nilai-kognitif__custom-form-column__field">
                          
            </div>
          
          </div>

          <div className="nilai-kognitif__custom-form-row">
        
          </div>
        </div>
        
           
        <div className="user-management__table-footer">
        </div>
        <br></br>
        <br></br>
        <br></br>
        <div className="beginning-balance__button-wrapper">
          <Button
            title="UPLOAD"
            onClick={this._onUpload}
          />
          <Button
            title="SIMPAN"
            onClick={this._onSubmit}
          />

        </div> 
      </div>
    );
  }
}

UploadNilai.propTypes = { 
  getParamOptions: PropTypes.func,  
  history: PropTypes.object.isRequired,
  handleGetNilaiKognitif: PropTypes.func,
};
UploadNilai.defaultProps = {
  getParamOptions: noop,
  handleGetNilaiKognitif: noop,
};
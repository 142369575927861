import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { map, noop, isEmpty } from 'lodash';
import { commaFormatted, dateFormat } from '../../../../utils/transformer.util';
import Button from '../../../../components/base/Button/Button.component';
import { printJournalKAS } from '../../../../utils/print.util';

function onClickPrint(header, content, footer) {
  printJournalKAS(header, content, footer);
}

export default class CashJournal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      journal: {},
    };
    this.onGetDetails = this.onGetDetails.bind(this);
  }

  componentDidMount() {
    this.onGetDetails();
  }

  async onGetDetails() {
    const { handleGetCashJournal, match } = this.props;
    const { id } = match.params;
    try {
      const payload = await handleGetCashJournal({ id });
      this.setState({
        journal: payload,
      });
    } catch (err) {
      // err action
    }
  }

  render() {
    const { journal } = this.state;
    return (
      <div>
        <Button
          title="Print"
          onClick={() => {
            onClickPrint('detail-cash-journal__header',
              'detail-cash-journal__table',
              'detail-cash-journal__footer');
          }}
        />
        <div id="detail-cash-journal" className="detail-cash-journal">
          {
            !isEmpty(journal) && (
              <div className="detail-cash-journal__content">
                <div className="detail-cash-journal__header">
                  <div className="detail-cash-journal__header-top">
                    <div className="detail-cash-journal__header-top--left">
                    <p>{journal.unit.school_unit.name}</p>
                    </div>
                    <div className="detail-cash-journal__header-title">
                      <h1>{journal.isCredit === false ? 'BUKTI KAS KELUAR' : 'BUKTI KAS MASUK'}</h1>
                    </div>
                    <div className="detail-cash-journal__header-top--right">
                      <p>Nomor: {journal.journal_number}</p>
                      <p>Tanggal: {dateFormat(journal.date)}</p>
                    </div>
                  </div>

                </div>
                <div className="detail-cash-journal__body">
                  <table className="detail-cash-journal__table">
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th>Kode</th>
                        <th>Nama Perkiraan</th>
                        <th>Keterangan Transaksi</th>
                        <th>Jumlah Rp</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                      map(journal.details, (list, idx) => (
                        <tr key={`journal_detail_${idx}`}>
                          <td>{idx + 1}</td>
                          <td>{list.code_of_account}</td>
                          <td>{list.parameter_code.title}</td>
                          <td>{list.description}</td>
                          <td className="nominal">{commaFormatted(list.amount)}</td>
                        </tr>
                      ))
                    }
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan="4" className="nominal">TOTAL BRUTO</td>
                        <td className="nominal">{commaFormatted(journal.total)}</td>
                      </tr>
                      <tr>
                        <td colSpan="4" className="nominal">POTONGAN PAJAK</td>
                        <td className="nominal">{commaFormatted(journal.total_tax)}</td>
                      </tr>
                      <tr>
                        <td colSpan="4" className="nominal">TOTAL NETTO</td>
                        <td className="nominal">{commaFormatted(journal.total_nett)}</td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div className="detail-cash-journal__footer">
                  <div className="detail-cash-journal__footer-bottom">
                    <div>
                      <p>Diserahkan oleh:</p>
                      <p>{journal.submitted_by}</p>
                    </div>
                    <div>
                      <p>Diterima oleh:</p>
                      <p>{journal.accepted_by}</p>
                    </div>
                    <div>
                      <p>Dibukukan oleh:</p>
                      <p>{journal.booked_by}</p>
                    </div>
                    <div>
                      <p>Diketahui oleh:</p>
                      <p>Name 4</p>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        </div>
      </div>
    );
  }
}
CashJournal.propTypes = {
  handleGetCashJournal: PropTypes.func,
  match: PropTypes.object.isRequired,
};
CashJournal.defaultProps = {
  handleGetCashJournal: noop,
};

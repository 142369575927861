import {
  isNaN, isNumber, map,
  forEach, result, sumBy,
} from 'lodash';
import moment from 'moment';

export function datetimeFormat(date = new Date(), format = 'YYYY-MM-DD HH:MM') {
  return moment(date).format(format);
}

export function filterObjectProperties(sourceObject = {}, keys = []) {
  const filtered = {};
  keys.forEach((eachKey) => {
    filtered[eachKey] = sourceObject[eachKey];
  });
  return filtered;
}

export function normalizeAmount(amount) {
  if (!amount) {
    return 0;
  }
  let temp = amount;
  if (isNumber(temp)) {
    temp = String(temp);
  }
  return Number(temp.replace(/,/g, ''));
}

export function commaFormatted(amount) {
  const delimiter = ',';
  let temp = normalizeAmount(amount);
  if (isNaN(temp)) {
    return 0;
  }
  let minus = false;
  if (temp < 0) {
    minus = true;
  }
  temp = Math.abs(Math.round(temp));
  let formatted = String(temp);
  const splitted = [];
  while (formatted.length > 3) {
    const nn = formatted.substr(formatted.length - 3);
    splitted.unshift(nn);
    formatted = formatted.substr(0, formatted.length - 3);
  }
  if (formatted.length > 0) {
    splitted.unshift(formatted);
  }
  formatted = splitted.join(delimiter);

  return (minus) ? `(${formatted})` : formatted;
}

export function generateYearList(date = new Date()) {
  const year = date.getUTCFullYear();
  return [
    { label: year + 1, value: year + 1 },
    { label: year + 2, value: year + 2 },
    { label: year + 3, value: year + 3 },
    { label: year + 4, value: year + 4 },
    { label: year + 5, value: year + 5 },
    { label: year + 6, value: year + 6 },
    { label: year + 7, value: year + 7 },
    { label: year + 8, value: year + 8 },
    { label: year + 9, value: year + 9 },
    { label: year + 10, value: year + 10 }];
}

export function dateFormat(date = new Date(), format = 'DD MMM YYYY') {
  return moment(date).format(format);
}

export function formatData(data, field) {
  if (!data) {
    return '-';
  }
  const fieldData = result(data, field.attribute, '-');
  if (field.type === 'date') {
    return dateFormat(fieldData);
  }
  if (field.type === 'number') {
    return commaFormatted(fieldData);
  }
  if (field.type === 'status') {
    if (data.submitted === 0) {
      return 'Pending';
    }
    if (data.submitted === 1 && data.approved === 0) {
      return 'Submitted';
    }
    if (data.submitted === 1 && data.approved === 1) {
      return 'Approved';
    }
  }
  return fieldData;
}

export function generateInvoice(additionalString = '', date = new Date()) {
  return `krj-${additionalString}-${dateFormat(date, 'YYYYMMDDHHmmss')}`;
}

export function selectFormat(data, label = 'name', value = '_id') {
  return map(data, list => ({
    label: result(list, label, ''),
    value: result(list, value, ''),
  }));
}

export function replaceStringAt(string, index, replace) {
  return string.substring(0, index) + replace + string.substring(index + 1);
}

export function dateString(date) {
  return moment(date)
    .hour(0)
    .minute(0)
    .second(0)
    .format();
}

export function editFormFormat(formFields, val) {
  const initialState = {};
  initialState._id = val._id;
  forEach(formFields, (field) => {
    switch (field.inputType) {
      case 'number': {
        initialState[field.name] = commaFormatted(val[field.name]);
        break;
      }
      case 'object': {
        initialState[field.name] = { value: val[field.name]._id, label: val[field.name].name };
        break;
      }
      case 'date': {
        initialState[field.name] = dateFormat(val[field.name].time, 'YYYY-MM-DD');
        break;
      }
      default: {
        initialState[field.name] = val[field.name];
        break;
      }
    }
  });
  return initialState;
}

export function sumTransactionAmount(transactions) {
  return commaFormatted(sumBy(transactions, transaction => normalizeAmount(transaction.amount)));
}

export function sumExpectedSemesterAmount(data = {}) {
  const {
    quantity = 0,
    price = 0,
    term = 0,
  } = data;
  //console.log(data)
  if (parseFloat(term) === 0 || term === null) {
    return normalizeAmount(quantity) * normalizeAmount(price);
  }
  return normalizeAmount(quantity) * normalizeAmount(price) * normalizeAmount(term);
  
}


export function sumActualSemesterAmount(data = {}) {
  // console.log("aaaaaaaaaaaaaaaaaaa")//gak kebaca
  // console.log(data)
  const {
    allocations,
  } = data;
  let total = 0;
  

  for (const plafondId in allocations) {
    total += allocations[plafondId];
  }
  return total;
}
  

export function calculateAllSemester(semesterOne = [], semesterTwo = [], plafonds = [], divisi_id) {
  let total = 0;
  const summary = {};
  const plafondIds = [];
  forEach(plafonds, (plafond) => {
    const plafondId = plafond.value;
    if (!summary[plafondId]) {
      summary[plafondId] = 0;
    }
    plafondIds.push(plafondId);
   
  });

  forEach([...semesterOne, ...semesterTwo], (data) => {
    let term = data.term;
    if (data.divisi_id === divisi_id) {
      forEach(plafondIds, (plafondId) => {
        summary[plafondId] += normalizeAmount(data.allocations[plafondId]);

        // console.log("cek summary"+summary)
      });

      if(data.term == 0)
      {
        term = 1;
      }
      
      total += normalizeAmount(data.quantity)
        * normalizeAmount(data.price) * normalizeAmount(term);
        // console.log(total)
    }
  });
  
  return {
    ...summary,
    total,
  };
}

export function editBudgetTransfer(data) {
  return {
    id: data.id,
    description: data.description,
    rapbuSource: map(data.budget_relocation_recipients, list => ({
      id: list.id,
      code: {
        code: list.budget_detail_draft.parameter_code.code,
        title: list.budget_detail_draft.parameter_code.title,
        type: list.budget_detail_draft.parameter_code.type,
      },
      title: list.budget_detail_draft.title,
      semester: list.budget_detail_draft.semester,
      quantity: commaFormatted(list.budget_detail_draft.quantity),
      price: commaFormatted(list.budget_detail_draft.price),
      term: commaFormatted(list.budget_detail_draft.term),
      desc: list.budget_detail_draft.desc,
    })),
    periode: '2020',
    head: data.head,
    selectedBudget: map(data.budget_relocation_sources, list => ({
      amount: commaFormatted(list.relocated_amount), ...list.budget_detail,
    })),
    code_of_account: {
      code: data.account,
      title: '',
      type: '',
    },
  };
}

export function sumBudgetTransferSource(data = []) {
  return sumBy(data, list => normalizeAmount(list.quantity)
    * normalizeAmount(list.price)
    * normalizeAmount(list.term));
}

export function sumByAttribute(data, attr = '') {
  return sumBy(data, list => normalizeAmount(list[attr]));
}

export function manageStaffFormState(payload) {
  // console.log(payload)
  // console.log("payload")
  let data_unit_kerja = payload.unit_kerja ? payload.unit_kerja.id_unit_kerja : []
  let data_aktif_non_aktif = payload.aktif_non_aktif ? payload.aktif_non_aktif.id_aktif_non_aktif : []
  let data_status_perkawinan = payload.status_perkawinan ? payload.status_perkawinan.id_ptkp : []
  let data_golongan_darah = payload.golongan_darah ? payload.golongan_darah.id_golongan_darah : []
  let data_golongan = payload.golongan ? payload.golongan.id_golongan : []
  let data_jabatan = payload.jabatan ? payload.jabatan.id_jabatan : []
  let data_agama = payload.agama ? payload.agama.id_agama : []


  return {
    form: {
      value: {
        id: payload.id,
        nip:payload.nip,
        nama_lengkap: payload.nama_lengkap,
        nama_panggilan: payload.nama_panggilan,
        email : payload.email,
        tempat_lahir: payload.tempat_lahir,
        tanggal_lahir: payload.tanggal_lahir,
        id_absensi_kartu_fingerprint: payload.id_absensi_kartu_fingerprint,
        jenis_kelamin: payload.jenis_kelamin,
        tinggi: payload.tinggi,
        berat: payload.berat,
        alamat_tempat_tinggal: payload.alamat_tempat_tinggal,
        rt: payload.rt,
        rw: payload.rw,
        kelurahan: payload.kelurahan,
        kecamatan: payload.kecamatan,
        kotamadya_kabupaten: payload.kotamadya_kabupaten,
        propinsi: payload.propinsi,
        no_wa: payload.no_wa,
        no_telpon: payload.no_telpon,
        agama: data_agama,
        // agama: payload.agama.id_agama,
        kewarganegaraan: payload.kewarganegaraan,
        golongan_darah: data_golongan_darah,
        // golongan_darah: payload.golongan_darah.id_golongan_darah,
        pendidikan_terakhir: payload.pendidikan_terakhir,
        status_perkawinan: data_status_perkawinan,
        //status_perkawinan: payload.status_perkawinan.id_ptkp,
        bahasa_yg_dikuasai: payload.bahasa_yg_dikuasai,
        penyakit_yg_diderita : payload.penyakit_yg_diderita,
        kelainan_fisik: payload.kelainan_fisik,
        no_ktp: payload.no_ktp,
        no_kk: payload.no_kk,
        no_npwp: payload.no_npwp,
        bank: payload.bank,
        no_rek_bank: payload.no_rek_bank,
        bpjs_kesehatan_status:payload.bpjs_kesehatan_status,
        bpjs_kesehatan_no:payload.bpjs_kesehatan_no,
        bpjs_ketenagakerjaan_status:payload.bpjs_ketenagakerjaan_status,
        bpjs_ketenagakerjaan_no:payload.bpjs_ketenagakerjaan_no,
        tanggal_mulai_bekerja:payload.tanggal_mulai_bekerja,
        masa_kerja_saat_ini: payload.masa_kerja_saat_ini,
        tgl_keluar_pensiun: payload.tgl_keluar_pensiun,
        keterangan_keluar_pensiun:payload.keterangan_keluar_pensiun,
        unitkerja: data_unit_kerja,
        // unitkerja: payload.unit_kerja.id_unit_kerja,
        status_kepegawaian : payload.status_kepegawaian,
        jabatan_tugas_tambahan:payload.jabatan_tugas_tambahan,
        jabatan: data_jabatan,
        // jabatan: payload.jabatan,
        golongan: data_golongan,
        // golongan:payload.golongan.id_golongan,
        no_yadapen:payload.no_yadapen,
        aktif_non_aktif: data_aktif_non_aktif,
        // aktif_non_aktif : payload.aktif_non_aktif.id_aktif_non_aktif,
        apd:payload.apd,
        region: payload.region,

        pendidikan_formal: map(payload.pendidikan_formal, list => ({
          // id_staff_edu_formal:list.id_staff_edu_formal,
          level:list.level,
          // pendidikan: list.pendidikan,
          nama_sekolah:list.nama_sekolah,
          kota: list.kota,
          jurusan: list.jurusan,
          dari_tahun: list.dari_tahun,
          sampai_tahun: list.sampai_tahun,
        })),

        pendidikan_non_formal: map(payload.pendidikan_non_formal, list => ({
          // institusi: list.institusi,
          nama_training:list.nama_training,
          lembaga: list.lembaga,
          kota: list.kota,
          tahun: list.tahun,
          lama: list.lama,
          //nim: list.nim,
        })),

        pekerjaan_history: map(payload.pekerjaan_history, list => ({
          perusahaan :list.perusahaan,
          kota       :list.kota,
          jabatan    :list.jabatan,
          tahun      :list.tahun,
          sampai_tahun  :list.sampai_tahun,
          keterangan    :list.keterangan,
        })),

          memorandum: map(payload.memorandum, list => ({
            // nip:list.nip,
            level: list.level,
            tanggal : list.tanggal,
            no_surat :list.no_surat,
            jenis_pelanggaran: list.jenis_pelanggaran,
            keterangan: list.keterangan,
          })),
    
        cuti_panjang: map(payload.cuti_panjang, list => ({
          alasan_cuti: list.alasan_cuti,
          tanggal:list.tanggal,
          sampai_tanggal:list.sampai_tanggal,
          keterangan: list.keterangan,
        })),

        keluarga: map(payload.keluarga, list => ({
          nip: list.nip,
          nama_lengkap: list.nama_lengkap,
          tanggal_lahir: list.tanggal_lahir,
          agama:list.agama,
          pendidikan: list.pendidikan,
          status: list.status,
          tertanggung:list.tertanggung,
        })),
      },

      error: {
        pendidikan_formal: map(payload.pendidikan_formal, () => ({
          id_staff_edu_formal:'',
          level:'',
          pendidikan:'',
          nama_sekolah:'',
          kota: '',
          jurusan:'',
          dari_tahun: '',
          sampai_tahun: '',
        })),

        pendidikan_non_formal: map(payload.pendidikan_non_formal, () => ({
          institusi: '',
          nama_training:'',
          kota: '',
          //nim: '',
          lembaga: '',
          tahun: '',
          lama: '',

        })),
        pekerjaan_history: map(payload.pekerjaan_history, () => ({
          perusahaan: '',
          kota:'',
          jabatan: '',
          tahun: '',
          sampai_tahun: '',
          keterangan: '',
        })),
     
        memorandum: map(payload.memorandum, () => ({
          level: '',
          tanggal: '',
          no_surat: '',
          jenis_pelanggaran: '',
          keterangan: '',
        })),
        cuti_panjang: map(payload.cuti_panjang, () => ({
          alasan_cuti: '',
          tanggal: '',
          sampai_tanggal: '',
          keterangan: '',
        })),
        keluarga: map(payload.keluarga, () => ({
          
          id_staff_family:'',
          nama_lengkap: '',
          tanggal_lahir: '',
          agama:'',
          pendidikan:'',
          status:'',
          tertanggung:'',
        })),
        
      },
    },
  };
}

export function toCamelCase(str) {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => (index == 0 ? word.toLowerCase() : word.toUpperCase())).replace(/\s+/g, '');
}


export function manageGetClasses(payload) {
  // console.log(payload)
  // console.log("payload")
  
  let data_classes = payload.classes ? payload.classes.id : []
  


  return {
    form: {
      value: {
        id: payload.id,
        classes: data_classes,
      },

      error: {
      
        
      },
    },
  };
}

export function manageGetRegistration(payload) {
  // console.log(payload)
  // console.log("payload")
  
  let data_provinces = payload.provinces ? payload.provinces.id : []
  let data_cities = payload.cities ? payload.cities.id : []
  let data_provinces2 = payload.provinces ? payload.provinces.id : []
  let data_cities2 = payload.cities ? payload.cities.id : []
  let data_districts = payload.districts ? payload.districts.id : []
  let data_sub_districts = payload.sub_districts ? payload.sub_districts.id : []
  let data_citizenships = payload.citizenships ? payload.citizenships.id : []
  let data_religions = payload.religions ? payload.religions.id : []
  let data_educations = payload.educations ? payload.educations.id : []
  let data_occupations = payload.occupations ? payload.occupations.id : []
  let data_genders = payload.genders ? payload.genders.id : []
  let data_blood_types = payload.blood_types ? payload.blood_types.id : []
  let data_levels = payload.levels ? payload.levels.id : []

  return {
    form: {
      value: {
        id: payload.id,
        provinces: data_provinces,
        cities: data_cities,
        districts: data_districts,
        sub_districts: data_sub_districts,
        citizenships:data_citizenships,
        religions:data_religions,
        educations:data_educations,
        occupations:data_occupations,
        genders:data_genders,
        blood_types:data_blood_types,
        levels:data_levels,


      },

      error: {
        id: payload.id,
        provinces: data_provinces,
        cities: data_cities,
        districts: data_districts,
        sub_districts: data_sub_districts,
        citizenships:data_citizenships,
        religions:data_religions,
        educations:data_educations,
        occupations:data_occupations,
        genders:data_genders,
        blood_types:data_blood_types,
        levels:data_levels,
        
      },
    },
  };
}
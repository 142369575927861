import React from 'react';
import PropTypes from 'prop-types';
import { noop, map, isEqual } from 'lodash';
import { Button, Pagination } from '../../../components/base';
import BaseTable from '../../../components/BaseTable/BaseTable.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { formatData } from '../../../utils/transformer.util';
import language from '../../../languages';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
import { REPORT_LIST_TABLE_FIELDS } from '../../../constants/student.constant';


const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class Report extends BaseTable {
  constructor(props) {
    super(props);
    this.onClickAdd = this.onClickAdd.bind(this);
    // this.onClickAddFolder = this.onClickAddFolder.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this._renderButtons = this._renderButtons.bind(this);
    this.handleGetData = this.handleGetData.bind(this);
    this.onChangePage = this.onChangePage.bind(this);

    this.state = {
      content: initialContent,
      list: [],
      workflow: {},
      listAmount: 5,
    };
  }

  componentDidMount() {
    const { content } = this.state;
    this.handleGetData();
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    const { workingUnit } = user;
    const { user: prevUser } = prevProps;
    const { workingUnit: prevWorkingUnit } = prevUser;

    if (!isEqual(workingUnit, prevWorkingUnit)) {
      this.handleGetData();
    }
  }
  async onClickDownload(id) {
    const { handleDownloadAnnouncement } = this.props;
    const res = await handleDownloadAnnouncement(id.id, id.upload)
  }


  async handleGetData(payload = {}) {
    const { handleListReportLoggedin, user } = this.props;
    const { units_id, workingUnit, id, user_group, organizations_id } = user;
    let unit_id = units_id;

    if (unit_id === null && workingUnit && workingUnit.id) {
      unit_id = workingUnit.id;
    }

    // if(user_group.id === 2){
      const res = await handleListReportLoggedin({...payload, user_id: id, organizations_id: organizations_id});

    this.setState({
      list: res,
    });
//   }
    
  }

  // onClickAdd() {
  //   const { history } = this.props;
  //   history.push('/dashboard/kurikulum/tambah-pengumuman', { isEdit: true });
  // }

  // onClickAddFolder() {
  //   const { history } = this.props;
  //   history.push('/dashboard/kurikulum/tambah-folder', { isEdit: true });
  // }

  // onClickEdit(val) {
  //   const { history } = this.props;
  //   history.push('/dashboard/kurikulum/tambah-pengumuman', { data: val, isEdit: true });
  // }
  async onClickDelete(id) {
    const { handleDeleteAnnouncement } = this.props;
    const res = await handleDeleteAnnouncement(id);
    if(res){
      this.handleGetData();
    }
  }

  onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this.handleGetData({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  _renderButtons(data) {
    const { user } = this.props;
    const {user_group} = user;
    const button = {};
    
    return (
      <td>
        <div className="table__actions">
        
          {data.upload ? <Button
            onClick={() => this.onClickDownload(data)}
            title='Download'
          /> : <div></div>}
          {user_group.id === 2?
             <Button
             onClick={() => this.onClickEdit(data)}
             title='Edit'
           />
           :''
          }
            {user_group.id === 2?
          
           <Button
             onClick={() => this.onClickDelete(data)}
             title='Delete'
           />
           :''
          }
         
        </div>
      </td>
    );
  }

  render() {
    const { list = {} } = this.state;
    const { handleGetFundRequest, handleDeleteFundRequest, user } = this.props;
    const { user_group } = user;
    const { permissions } = user_group;
    const dataset = {
      toggle: "modal"
    };
    console.log(user);

    return (
      <div className="fund-request">
        <div className="beginning-balance__button-wrapper">
          <h1 style={{textAlign:"center"}}>Report Logged-in</h1>
         {/* <Button
          title="Tambah Pengumuman"
          onClick={this.onClickAdd}
          data={dataset}
        /> */}
        {/* <Button
          title="Tambah Folder Baru"
          onClick={this.onClickAddFolder}
          // data={dataset}
        /> */}

         </div>
        
        
        <div className="fund-request__content">
          <table className="table">
            <thead>
              <tr>
                {
                    map(REPORT_LIST_TABLE_FIELDS.label, (field, idx) => (
                      <th key={`table_th_${idx}`}>{field}</th>
                   ))
                  }
              </tr>
            </thead>
            <tbody>
              {
                  map(list.result, (data, idx) => (
                    <tr key={`budget_row_${idx}`}>
                      {
                      map(REPORT_LIST_TABLE_FIELDS.value, (field, fieldIdx) => {
                        if (fieldIdx === 0) {
                          return (
                            <td key={`table_index_${fieldIdx}_${idx}`}>{idx + 1}</td>
                          );
                        }
                        if (field.type === 'link') {
                          return (
                            <td key={`table_${fieldIdx}_${idx}`}><a href={"https://"+data.hyperlink} target="_blank" rel="noopener noreferrer">{data.hyperlink}</a></td>
                              // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                          );
                        }
                        return (
                          <td className={(field.type === 'number') ? 'nominal' : ''} key={`table_${fieldIdx}_${idx}`}>
                            {formatData(data, field)}
                          </td>
                        );
                      })
                      }
                      {/* {this._renderButtons(data)} */}
                    </tr>
                  ))
                }
            </tbody>
          </table>
        </div>
        <div className="user-management__table-footer">
          <p className="user-management__page-info">
            {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${list.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${list.totalPage}`}
          </p>
          <Pagination
            totalPage={list.totalPage}
            currentPage={list.currentPage}
            onClick={this.onChangePage}
          />
        </div>
      </div>
    );
  }
}
Report.propTypes = {
  handlelistReportLogin: PropTypes.func,
  handleDelete: PropTypes.func,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
};
Report.defaultProps = {
  handlelistReportLogin: noop,
  handleDelete: noop,
  user: null,
};

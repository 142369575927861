import React from 'react';
import PropTypes from 'prop-types';
import { FileInput } from '../../../../../components/base';

const MonthlyActivityPlanFormTableHeaders = (props) => {
  const {
    showEdit,
    workflow,
    showApprovalCol,
    type = '',
  } = props;
  const lastWorkflow = workflow[workflow.length - 1] || {};
  return (
    <thead>
      <tr>
        <th style={{ width: '1%' }} rowSpan={2}>
          No.
        </th>
        <th style={{ width: '5%' }} rowSpan={2}>
          Kode Kegiatan
        </th>
        <th rowSpan={2}>
          Program Kegiatan
        </th>
        <th
          rowSpan={2}
        >
          Jenis Kegiatan
        </th>
        <th
          style={{ width: '15s%' }}
          rowSpan={2}
        >
          PIC Kegiatan
        </th>
        <th colSpan={2}>
          Tgl
        </th>
        <th colSpan={3}>
          RP
        </th>
        <th rowSpan={2}>
          Pembayaran Dana
        </th>
        <th rowSpan={2}>
          Rincian Anggaran
        </th>
        { (type === 'procurement' || type === 'activity') && (
          <th rowSpan={2}>
            File
          </th>
        )}
        {showApprovalCol && (<th rowSpan={2}>Persetujuan Pengurus</th>)}
        {showEdit && workflow.length === 0 && <th rowSpan={2} width="4%" />}
        {showEdit && lastWorkflow.action === 'reject' && (
        <th rowSpan={2} width="4%" />
        )}
      </tr>
      <tr>
        <th width="5%">
          Butuh Dana
        </th>
        <th width="5%">Pelaksanaan</th>
        <th width="5%">
          Saldo Awal
        </th>
        <th width="5%">Pengajuan</th>
        <th width="5%">Saldo Akhir</th>
      </tr>
    </thead>
  );
};

MonthlyActivityPlanFormTableHeaders.propTypes = {
  showEdit: PropTypes.bool,
  workflow: PropTypes.array,
};
MonthlyActivityPlanFormTableHeaders.defaultProps = {
  showEdit: false,
  workflow: [],
};

export default MonthlyActivityPlanFormTableHeaders;

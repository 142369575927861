import { noop } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import Header from '../components/Header/Header.component';
import Sidebar from '../components/Sidebar/Sidebar.component';
import Home from '../pages/Home.page';
import ListPendaftaranSiswa from '../pages/Student/ListPendaftaranSiswa.student.page';
import ManageRegistration from '../pages/Student/ManageRegistration.student.page';
import Student from '../pages/Student/Student.page';
import UploadStudent from '../pages/Student/UploadStudent.student.page';
import DaftarSiswaKls from '../pages/Student/report/DaftarSiswaKls.student.page';
import Klapper from '../pages/Student/report/Klapper.student.page';
import Laporan8355 from '../pages/Student/report/Laporan8355.student.page';
import LaporanAbsensiKelas from '../pages/Student/report/LaporanAbsensiKelas.student.page';
import LaporanDispliner from '../pages/Student/report/LaporanDispliner.student.page';
import LaporanKonseling from '../pages/Student/report/LaporanKonseling.student.page';
import LaporanPersensi from '../pages/Student/report/LaporanPersensi.student.page';
import Report from '../pages/Student/report/Report.student.page';
import ReportLoggedIn from '../pages/Student/report/ReportLoggedIn.page';
import SearchSiswa from '../pages/Student/report/SearchSiswa.student.page';
import StudentBook from '../pages/Student/report/StudentBook.student.page';
import StudentGrade from '../pages/Student/report/StudentGrade.student.page';
import StudentKbk from '../pages/Student/report/StudentKbk.student.page';
import StudentNumber from '../pages/Student/report/StudentNumber.student.page';
import CetakBukuSiswa from '../pages/Student/report/cetak/CetakBukuSiswa.student.page';
import CetakLaporan8355 from '../pages/Student/report/cetak/CetakLaporan8355.student.page';
import CetakLaporanAbsensiKelas from '../pages/Student/report/cetak/CetakLaporanAbsensiKelas.student.page';
import CetakLaporanDispliner from '../pages/Student/report/cetak/CetakLaporanDispliner.student.page';
import CetakLaporanKonseling from '../pages/Student/report/cetak/CetakLaporanKonseling.student.page';
import CetakLaporanPersensi from '../pages/Student/report/cetak/CetakLaporanPersensi.student.page';
import { handleLogout, setWorkingUnit } from '../states/thunks/auth.thunk';
import { getUnitOptions } from '../states/thunks/options.thunk';

import AbsensiHarian from '../pages/Student/absensi/AbsensiHarian.student.page';
import AbsensiRekap from '../pages/Student/absensi/AbsensiRekap.student.page';
import AbsensiRekapGrafik from '../pages/Student/absensi/AbsensiRekapGrafik.student.page';
import AbsensiSiswa from '../pages/Student/absensi/AbsensiSiswa.student.page';
import IsiPerbidangStudy from '../pages/Student/absensi/IsiPerbidangStudy.student.page';
import LaporanAbsensi from '../pages/Student/absensi/LaporanAbsensi.student.page';
import Mengikuti from '../pages/Student/absensi/Mengikuti.student.page';
import TBM from '../pages/Student/absensi/TBM.student.page';
import TambahAbsenPerbidangStudy from '../pages/Student/absensi/TambahAbsenPerbidangStudy.student.page';
import UploadAbsensiHarian from '../pages/Student/absensi/UploadAbsensiHarian.student.page';
import CetakAbsensiRekap from '../pages/Student/absensi/cetak/CetakAbsensiRekap.student.page';

import AnalisaDispliner from '../pages/Student/displiner/AnalisaDispliner.student.page';
import BuatGroupPelanggaran from '../pages/Student/displiner/BuatGroupPelanggaran.student.page';
import BuatGroupPrestasi from '../pages/Student/displiner/BuatGroupPrestasi.student.page';
import BuatPelanggaran from '../pages/Student/displiner/BuatPelanggaran.student.page';
import BuatPrestasi from '../pages/Student/displiner/BuatPrestasi.student.page';
import CatatHarianSiswa from '../pages/Student/displiner/CatatHarianSiswa.student.page';
import CatatPelanggaran from '../pages/Student/displiner/CatatPelanggaran.student.page';
import CatatPrestasi from '../pages/Student/displiner/CatatPrestasi.student.page';
import Displiner from '../pages/Student/displiner/Displiner.student.page';
import ListGroupPelanggaran from '../pages/Student/displiner/ListGroupPelanggaran.student.page';
import ListGroupPrestasi from '../pages/Student/displiner/ListGroupPrestasi.student.page';
import TablePelanggaran from '../pages/Student/displiner/TablePelanggaran.student.page';
import TablePrestasi from '../pages/Student/displiner/TablePrestasi.student.page';
import AnalisaPelanggaran from '../pages/Student/displiner/cetak/AnalisaPelanggaran.student.page';
import AnalisaPrestasi from '../pages/Student/displiner/cetak/AnalisaPrestasi.student.page';
import Pelanggaran from '../pages/Student/displiner/cetak/Pelanggaran.student.page';
import Prestasi from '../pages/Student/displiner/cetak/Prestasi.student.page';

import AbsensiEkskul from '../pages/Student/ekskul/AbsensiEkskul.student.page';
import BuatDaftarEkskul from '../pages/Student/ekskul/BuatDaftarEkskul.student.page';
import BuatJenisEkskul from '../pages/Student/ekskul/BuatJenisEkskul.student.page';
import BuatKelasEkskul from '../pages/Student/ekskul/BuatKelasEkskul.student.page';
import BuatNilaiEkskul from '../pages/Student/ekskul/BuatNilaiEkskul.student.page';
import DaftarPesertaEkskul from '../pages/Student/ekskul/DaftarPesertaEkskul.student.page';
import Ekskul from '../pages/Student/ekskul/Ekskul.student.page';
import isiNilaiEkskul from '../pages/Student/ekskul/IsiNilaiEkskul.student.page';
import JenisEkstrakulikuler from '../pages/Student/ekskul/JenisEkstrakulikuler.student.page';
import LaporanEkskul from '../pages/Student/ekskul/LaporanEkskul.student.page';
import PembagianKelasEkskul from '../pages/Student/ekskul/PembagianKelasEkskul.student.page';
import PengisianEkskul from '../pages/Student/ekskul/PengisianEkskul.student.page';
import PengisianNilaiEkskul from '../pages/Student/ekskul/PengisianNilaiEkskul.student.page';

import BuatCatatanKonseling from '../pages/Student/BuatCatatanKonseling.student.page';
import HistoryKonseling from '../pages/Student/HistoryKonseling.student.page';
import KonselingSiswa from '../pages/Student/KonselingSiswa.student.page';
import TambahTempatKonseling from '../pages/Student/TambahTempatKonseling.student.page';

import CatatSiswaKeluar from '../pages/Student/DataSiswa/CatatSiswaKeluar.student.page';
import CatatSiswaKeluar2 from '../pages/Student/DataSiswa/CatatSiswaKeluar2.student.page';
import DataSiswa from '../pages/Student/DataSiswa/DataSiswa.student.page';
import PembatalanSiswaKeluar from '../pages/Student/DataSiswa/PembatalanSiswaKeluar.student.page';
import DaftarSiswaKlsUnit from '../pages/Student/report/DaftarSiswaKlsUnit.student.page';

import ManageStaff from '../pages/Personnel/ManageStaff.personnel.page';
import StaffList from '../pages/Personnel/StaffList.personnel.page';
import UploadStaff from '../pages/Personnel/UploadStaff.personnel.page';

import InformationLetter from '../pages/Personnel/Letter/InformationLetter.letter.page';
import ManageInformationLetter from '../pages/Personnel/Letter/ManageInformationLetter.letter.page';

import JobApplication from '../pages/Personnel/Letter/JobApplication.letter.page';
import ManageJobApplication from '../pages/Personnel/Letter/ManageJobApplication.letter.page';

import ManageRequest from '../pages/Personnel/Letter/ManageRequest.letter.page';
import Request from '../pages/Personnel/Letter/Request.letter.page';

import Discontinuance from '../pages/Personnel/Letter/Discontinuance.letter.page';
import ManageDiscontinuance from '../pages/Personnel/Letter/ManageDiscontinuance.letter.page';

import DiscontinuanceDescription from '../pages/Personnel/Letter/DiscontinuanceDescription.letter.page';
import ManageDiscontinuanceDescription from '../pages/Personnel/Letter/ManageDiscontinuanceDescription.letter.page';

import Assignment from '../pages/Personnel/Letter/Assignment.letter.page';
import ManageAssignment from '../pages/Personnel/Letter/ManageAssignment.letter.page';

import ManageRetire from '../pages/Personnel/Letter/ManageRetire.letter.page';
import Retire from '../pages/Personnel/Letter/Retire.letter.page';

import ManagePromotion from '../pages/Personnel/Letter/ManagePromotion.letter.page';
import Promotion from '../pages/Personnel/Letter/Promotion.letter.page';

import EmployeeContract from '../pages/Personnel/Letter/EmployeeContract.letter.page';
import ManageEmployeeContract from '../pages/Personnel/Letter/ManageEmployeeContract.letter.page';

import ManageTeacherContract from '../pages/Personnel/Letter/ManageTeacherContract.letter.page';
import TeacherContract from '../pages/Personnel/Letter/TeacherContract.letter.page';

import ManagePosition from '../pages/Personnel/Letter/ManagePosition.letter.page';
import Position from '../pages/Personnel/Letter/Position.letter.page';

import ManagePermanent from '../pages/Personnel/Letter/ManagePermanent.letter.page';
import Permanent from '../pages/Personnel/Letter/Permanent.letter.page';

import JobPeriod from '../pages/Personnel/Report/JobPeriod.report.page';
import TeacherEmployee from '../pages/Personnel/Report/TeacherEmployee.report.page';

import AbsensiPegawai from '../pages/Personnel/Absensi/AbsensiPegawai.absensi.page';
import Approval from '../pages/Personnel/Absensi/Approval.absensi.page';
import LaporanAbsensiKhusus from '../pages/Personnel/Absensi/LaporanAbsensiKhusus.absensi.page';
import LaporanAbsensiPegawai from '../pages/Personnel/Absensi/LaporanAbsensiPegawai.absensi.page';
import LaporanAbsensiPegawaiDetail from '../pages/Personnel/Absensi/LaporanAbsensiPegawaiDetail.absensi.page';
import PengajuanCuti from '../pages/Personnel/Absensi/PengajuanCuti.absensi.page';
import PengajuanIjinPulang from '../pages/Personnel/Absensi/PengajuanIjinPulang.absensi.page';
import PengajuanKegiatan from '../pages/Personnel/Absensi/PengajuanKegiatan.absensi.page';
import PengajuanLembur from '../pages/Personnel/Absensi/PengajuanLembur.absensi.page';
import TableKegiatan from '../pages/Personnel/Absensi/TableKegiatan.absensi.page';

import AddBudget from '../pages/Finance/Budgeting/Budget/AddBudget.finance.page';
import Budget from '../pages/Finance/Budgeting/Budget/Budget.finance.page';
import BudgetType from '../pages/Finance/Budgeting/Budget/BudgetType.finance.page';
import BudgetType2 from '../pages/Finance/Budgeting/Budget/BudgetType2.finance.page';
import BudgetType3 from '../pages/Finance/Budgeting/Budget/BudgetType3.finance.page';
import EditBudget from '../pages/Finance/Budgeting/Budget/EditBudget.finance.page';
import Budgeting from '../pages/Finance/Budgeting/Budgeting.page';
import Finance from '../pages/Finance/Finance.page';
import Upload from '../pages/Upload.page';

import ManageBudget from '../pages/Finance/Budgeting/Budget/ManageBudget.finance.page';
import ManageBudgetRapbu from '../pages/Finance/Budgeting/Budget/ManageBudgetRapbu.finance.page';
import ManageBudgetRapbuDetail from '../pages/Finance/Budgeting/Budget/ManageBudgetRapbuDetail.finance.page';
import ManageBudgetRapbuDetailRapby from '../pages/Finance/Budgeting/Budget/ManageBudgetRapbuDetailRapby.finance.page';
import ManageBudgetRapbuRekap from '../pages/Finance/Budgeting/Budget/ManageBudgetRapbuRekap.finance.page';
import ManageBudgetRapbuRekapExcel from '../pages/Finance/Budgeting/Budget/ManageBudgetRapbuRekapExcel.finance.page';
import ManageBudgetRapbuRekapRapby from '../pages/Finance/Budgeting/Budget/ManageBudgetRapbuRekapRapby.finance.page';
import ManageBudgetRapbuRekapRapbyExcel from '../pages/Finance/Budgeting/Budget/ManageBudgetRapbuRekapRapbyExcel.finance.page';
import ManageBudgetRapby from '../pages/Finance/Budgeting/Budget/ManageBudgetRapby.finance.page';

import AddUploadRapbu from '../pages/Finance/Budgeting/Budget/AddUploadRapbu.finance.page';
import UploadRapbu from '../pages/Finance/Budgeting/Budget/UploadRapbu.finance.page';
// import APBU from '../pages/Finance/Budgeting/Budget/APBU.finance.page'

import ManageNonBudget from '../pages/Finance/Budgeting/NonBudget/ManageNonBudget.finance.page';
import NonBudget from '../pages/Finance/Budgeting/NonBudget/NonBudget.finance.page';

import BudgetTransfer from '../pages/Finance/Budgeting/BudgetTransfer/BudgetTransfer.finance.page';
import ManageBudgetTransfer from '../pages/Finance/Budgeting/BudgetTransfer/ManageBudgetTransfer.finance.page';

import FundRequest from '../pages/Finance/Budgeting/FundRequest/FundRequest.finance.page';
import ManageFundRequest from '../pages/Finance/Budgeting/FundRequest/ManageFundRequest.finance.page';

// Topan New Recent Change for Jurnal Ricci
import MonthlyActivityPlanRapbs from '../pages/Finance/Budgeting/MonthlyActivityPlan/MonthlyActivityPlantRapbs.finance.page';
import MonthlyActivityPlanRapby from '../pages/Finance/Budgeting/MonthlyActivityPlan/MonthlyActivityPlantRapby.finance.page';


import ManageRealization from '../pages/Finance/Budgeting/Realization/ManageRealization.finance.page';

import ManageMonthlyActivityPlanRapby from '../pages/Finance/Budgeting/MonthlyActivityPlan/ManageMonthlyActivityPlantRapby.finance.page';

import BankJournal from '../pages/Finance/Bookkeeping/BankJournal/BankJournal.page';
import DetailBankJournal from '../pages/Finance/Bookkeeping/BankJournal/DetailBankJournal.page';
import AddBankJournal from '../pages/Finance/Bookkeeping/BankJournal/ManageBankJournal.page';
import BeginningBalance from '../pages/Finance/Bookkeeping/BeginningBalance.page';
import Bookkeeping from '../pages/Finance/Bookkeeping/Bookkeeping.page';
import CashJournal from '../pages/Finance/Bookkeeping/CashJournal/CashJournal.page';
import DetailCashJournal from '../pages/Finance/Bookkeeping/CashJournal/DetailCashJournal.page';
import AddCashJournal from '../pages/Finance/Bookkeeping/CashJournal/ManageCashJournal.page';

import ManagePaymentJournal from '../pages/Finance/Bookkeeping/PaymentJournal/ManagePaymentJournal.finance.page';
import PaymentJournal from '../pages/Finance/Bookkeeping/PaymentJournal/PaymentJournal.finance.page';

import DetailGeneralJournal from '../pages/Finance/Bookkeeping/GeneralJournal/DetailGeneralJournal.page';
import GeneralJournal from '../pages/Finance/Bookkeeping/GeneralJournal/GeneralJournal.bookkeeping.page';
import ManageGeneralJournal from '../pages/Finance/Bookkeeping/GeneralJournal/ManageGeneralJournal.bookkeeping.page';

import ClosingJournal from '../pages/Finance/Bookkeeping/ClosingJournal/ClosingJournal.bookkeeping.page';
import ManageClosingJournal from '../pages/Finance/Bookkeeping/ClosingJournal/ManageClosingJournal.bookkeeping.page';

import ManageNonKasJournal from '../pages/Finance/Bookkeeping/NonKasJournal/ManageNonKasJournal.bookkeeping.page';
import NonKasJournal from '../pages/Finance/Bookkeeping/NonKasJournal/NonKasJournal.bookkeeping.page';

import Balance from '../pages/Finance/Report/Balance.report.page';
import BankJournalReport from '../pages/Finance/Report/BankJournal.report.page';
import BigBookReport from '../pages/Finance/Report/BigBook.report.page';
import CashJournalReport from '../pages/Finance/Report/CashJournal.report.page';
import DailyAndMonthly from '../pages/Finance/Report/DailyAndMonthly.report.page';
import MonthlyReport from '../pages/Finance/Report/Monthly.report.page';
import ProfitAndLost from '../pages/Finance/Report/ProfitAndLost.report.page';

import JenisSPP from '../pages/Finance/Spp/JenisSPP/JenisSPP.JenisSPP.page';
import TambahJenisSPP from '../pages/Finance/Spp/JenisSPP/TambahJenisSPP.JenisSPP.page';
import TambahStatus from '../pages/Finance/Spp/JenisSPP/TambahStatus.JenisSPP.page';
import Spp from '../pages/Finance/Spp/Spp.page';

import DataKeu from '../pages/Finance/Spp/MasterData/DataKeu.masterdata.page';
import DataKeuDetail from '../pages/Finance/Spp/MasterData/DataKeuDetail.masterdata.page';
import MasterDataKeu from '../pages/Finance/Spp/MasterData/MasterDataKeu.masterdata.page';

import CreateInvoice from '../pages/Finance/Spp/CreateInvoice/CreateInvoice.page';
import GenerateInvoice from '../pages/Finance/Spp/CreateInvoice/GenerateInvoice.createinvoice.page';
import Individual from '../pages/Finance/Spp/CreateInvoice/Individual.createinvoice.page';
import KelompokKls from '../pages/Finance/Spp/CreateInvoice/KelompokKls.createinvoice.page';
import ProsesInvoice from '../pages/Finance/Spp/CreateInvoice/ProsesInvoice.createinvoice.page';
import CetakBuktiDetail from '../pages/Finance/Spp/Pembayaran/CetakBuktiDetail.pembayaran.page';
import CetakBuktiKwitansi from '../pages/Finance/Spp/Pembayaran/CetakBuktiKwitansi.pembayaran.page';
import Pembayaran from '../pages/Finance/Spp/Pembayaran/Pembayaran.page';
import ProsesPembayaran from '../pages/Finance/Spp/Pembayaran/ProsesPembayaran.pembayaran.page';

import CetakInvoice from '../pages/Finance/Spp/LaporanKeu/CetakInvoice.laporankeu.page';
import CetakSlip from '../pages/Finance/Spp/LaporanKeu/CetakSlip.laporaninvoice.page';
import CetakSlipDetail from '../pages/Finance/Spp/LaporanKeu/CetakSlipDetail.laporaninvoice.page';
import CetakSlipKwitansi from '../pages/Finance/Spp/LaporanKeu/CetakSlipKwitansi.laporaninvoice.page';
import InvoiceOutstading from '../pages/Finance/Spp/LaporanKeu/InvoiceOutstading.laporankeu.page';
import InvoicePayment from '../pages/Finance/Spp/LaporanKeu/InvoicePayment.laporankeu.page';
import InvoiceSummary from '../pages/Finance/Spp/LaporanKeu/InvoiceSummary.laporankeu.page';
import LaporanInvoice from '../pages/Finance/Spp/LaporanKeu/LaporanInvoice.laporaninvoice.page';
import LaporanInvoiceDetail from '../pages/Finance/Spp/LaporanKeu/LaporanInvoiceDetail.laporaninvoice.page';
import LaporanSPP from '../pages/Finance/Spp/LaporanKeu/LaporanSPP.laporankeu.page';
import UploadInvoice from '../pages/Finance/Spp/LaporanKeu/UploadInvoice.laporaninvoice.page';

import TemaCoaMapping from '../pages/Finance/Settings/TemaCoaMapping.finance.settings.page';


import Administration from '../pages/Akademi/Administration.page';
import DaftarPindahUnit from '../pages/Akademi/DataKelas/DaftarPindahUnit.page';
import DataKelas from '../pages/Akademi/DataKelas/DataKelas.page';
import Identitas from '../pages/Akademi/DataKelas/Identitas.page';
import PermohonanPindahUnit from '../pages/Akademi/DataKelas/PermohonanPindahUnit.page';
import PindahUnit from '../pages/Akademi/DataKelas/PindahUnit.page';
import TahunAjaran from '../pages/Akademi/DataKelas/TahunAjaran.page';
import TambahIdentitas from '../pages/Akademi/DataKelas/TambahIdentitas.page';
import TambahIdentitas2 from '../pages/Akademi/DataKelas/TambahIdentitas2.page';

import JurusanKelas from '../pages/Akademi/DataKelas/JurusanKelas.page';
import Kelas from '../pages/Akademi/DataKelas/Kelas.page';
import KelasKhusus from '../pages/Akademi/DataKelas/KelasKhusus.page';
import Kelola from '../pages/Akademi/DataKelas/Kelola.page';
import KelolaPembagianKls from '../pages/Akademi/DataKelas/KelolaPembagianKls.page';
import KelolaPembagianKlsKhusus from '../pages/Akademi/DataKelas/KelolaPembagianKlsKhusus.page';
import KelolaPindahKelas from '../pages/Akademi/DataKelas/KelolaPindahKelas.page';
import KenaikanKelas from '../pages/Akademi/DataKelas/KenaikanKelas.page';
import PembagianKelas from '../pages/Akademi/DataKelas/PembagianKelas.page';
import PembagianKelasKhusus from '../pages/Akademi/DataKelas/PembagianKelasKhusus.page';
import PindahKelas from '../pages/Akademi/DataKelas/PindahKelas.page';
import TambahJurusan from '../pages/Akademi/DataKelas/TambahJurusan.page';
import TambahKelas from '../pages/Akademi/DataKelas/TambahKelas.page';
import TambahKelasKhusus from '../pages/Akademi/DataKelas/TambahKelasKhusus.page';
import UploadKelas from '../pages/Akademi/DataKelas/UploadKelas.page';

import Jadwal from '../pages/Kurikulum/Jadwal/Jadwal.kurikulum.page';
import JadwalKaryawan from '../pages/Kurikulum/Jadwal/JadwalKaryawan.kurikulum.page';
import JadwalKelas from '../pages/Kurikulum/Jadwal/JadwalKelas.kurikulum.page';
import JadwalKelasDetail from '../pages/Kurikulum/Jadwal/JadwalKelasDetail.kurikulum.page';
import TambahJadwalKaryawan from '../pages/Kurikulum/Jadwal/TambahJadwalKaryawan.kurikulum.page';
import TambahJadwalKelas from '../pages/Kurikulum/Jadwal/TambahJadwalKelas.kurikulum.page';
import Kurikulum from '../pages/Kurikulum/Kurikulum.page';
import Pengumuman from '../pages/Kurikulum/Pengumuman/Pengumuman.kurikulum.page';
import TambahFolder from '../pages/Kurikulum/Pengumuman/TambahFolder.kurikulum.page';
import TambahPengumuman from '../pages/Kurikulum/Pengumuman/TambahPengumuman.kurikulum.page';

import AnalisaNilaiSiswa from '../pages/Kurikulum/Ulangan/AnalisaNilai.page';
import DaftarNilai from '../pages/Kurikulum/Ulangan/DaftarNilai.page';
import DaftarNilaiK13 from '../pages/Kurikulum/Ulangan/DaftarNilaiK13.page';
import DaftarNilaiKTSP from '../pages/Kurikulum/Ulangan/DaftarNilaiKTSP.page';
import DaftarNilaiPraktek from '../pages/Kurikulum/Ulangan/DaftarNilaiPraktek.page';
import DaftarNilaiSiswa from '../pages/Kurikulum/Ulangan/DaftarNilaiSiswa.page';
import KomentarManual from '../pages/Kurikulum/Ulangan/KomentarManual.page';
import NilaiKognitif from '../pages/Kurikulum/Ulangan/NilaiKognitif.page';
import Psikomotorik from '../pages/Kurikulum/Ulangan/Psikomotorik.page';
import TambahPengisianPsikAfek from '../pages/Kurikulum/Ulangan/TambahPengisianPsikAfek.page';
import TambahPenilaianNilaiKognitif from '../pages/Kurikulum/Ulangan/TambahPenilaianNilaiKognitif.page';
import Ulangan from '../pages/Kurikulum/Ulangan/Ulangan.page';
import UploadNilaiKognitif from '../pages/Kurikulum/Ulangan/UploadNilaiKognitif.page';

import PengisianNilai from '../pages/Kurikulum/Ulangan/PengisianNilai.page';
import UploadPengisianPsikAfek from '../pages/Kurikulum/Ulangan/UploadPengisianPsikAfek.page';

import Anekdot_SubTema from '../pages/Kurikulum/Ulangan/Anekdot_SubTema.page';
import Anekdot_Tema from '../pages/Kurikulum/Ulangan/Anekdot_Tema.page';
import CatatanAnekdotTema from '../pages/Kurikulum/Ulangan/CatatanAnekdotTema.page';
import CatatanAnekdotView from '../pages/Kurikulum/Ulangan/CatatanAnekdotView.page';
import LembarCatatanAnekdot from '../pages/Kurikulum/Ulangan/LembarCatatanAnekdot.page';
import LembarObsHarian from '../pages/Kurikulum/Ulangan/LembarObsHarian.page';
import PengisianCatatanAnekdot from '../pages/Kurikulum/Ulangan/PengisianCatatanAnekdot.page';
import PengisianKompetensiDasar from '../pages/Kurikulum/Ulangan/PengisianKompetensiDasar.page';
import PengisianNilaiAfek from '../pages/Kurikulum/Ulangan/PengisianNilaiAfek.page';
import PengisianNilaiPsik from '../pages/Kurikulum/Ulangan/PengisianNilaiPsik.page';
import PengisianObsHarian from '../pages/Kurikulum/Ulangan/PengisianObsHarian.page';
import PengisianPraktek from '../pages/Kurikulum/Ulangan/PengisianPraktek.page';
import PengisianPsikAfek from '../pages/Kurikulum/Ulangan/PengisianPsikAfek.page';
import PengisianSikap from '../pages/Kurikulum/Ulangan/PengisianSikap.page';
// import PengisianObsMingguan from '../pages/Kurikulum/Ulangan/PengisianObsMingguan.page';
import CetakObservasi from '../pages/Kurikulum/Ulangan/CetakObservasi.page';
import CetakRaport from '../pages/Kurikulum/Ulangan/CetakRaport.page';
import CetakRaportEng from '../pages/Kurikulum/Ulangan/CetakRaportEng.page';
import CetakRaportMan from '../pages/Kurikulum/Ulangan/CetakRaportMan.page';
import DaftarNilaiReport from '../pages/Kurikulum/Ulangan/DaftarNilaiReport.page';
import LembarObsMingguan from '../pages/Kurikulum/Ulangan/LembarObsMingguan.page';
import PengisianDaftarNilai from '../pages/Kurikulum/Ulangan/PengisianDaftarNilai.page';
import PengisianDaftarNilaiEng from '../pages/Kurikulum/Ulangan/PengisianDaftarNilaiEng.page';
import PengisianDaftarNilaiMan from '../pages/Kurikulum/Ulangan/PengisianDaftarNilaiMan.page';

import BuatGroupMatpel from '../pages/Kurikulum/MataPelajaran/BuatGroupMatpel.page';
import BuatKodeMatpel from '../pages/Kurikulum/MataPelajaran/BuatKodeMatpel.page';
import CetakJadwalMatpel from '../pages/Kurikulum/MataPelajaran/CetakJadwalMatpel.page';
import DaftarGuru from '../pages/Kurikulum/MataPelajaran/DaftarGuru.page';
import EditKodeMatpel from '../pages/Kurikulum/MataPelajaran/EditKodeMatpel.page';
import IsiJadwal from '../pages/Kurikulum/MataPelajaran/IsiJadwal.page';
import JadwalMatpel from '../pages/Kurikulum/MataPelajaran/JadwalMatpel.page';
import KodeMatpel from '../pages/Kurikulum/MataPelajaran/KodeMatpel.page';
import MataPelajaran from '../pages/Kurikulum/MataPelajaran/MataPelajaran.page';
import TambahDaftarGuru from '../pages/Kurikulum/MataPelajaran/TambahDaftarGuru.page';

import BobotNilai from '../pages/Kurikulum/BobotNilai/BobotNilai.page';
import IsiBobotNilai from '../pages/Kurikulum/BobotNilai/IsiBobotNilai.page';

import DocUpload from '../pages/Kurikulum/RencanaNilai/DocUpload.page';
import LaporanRPP from '../pages/Kurikulum/RencanaNilai/LaporanRPP.page';
import LaporanRPPdetail from '../pages/Kurikulum/RencanaNilai/LaporanRPPdetail.page';
import PengisianKD from '../pages/Kurikulum/RencanaNilai/PengisianKD.page';
import PengisianKD2 from '../pages/Kurikulum/RencanaNilai/PengisianKD2.page';
import RencanaNilai from '../pages/Kurikulum/RencanaNilai/RencanaNilai.page';
import UploadDocument from '../pages/Kurikulum/RencanaNilai/UploadDocument.page';
import UploadNilai from '../pages/Kurikulum/RencanaNilai/UploadNilai.page';
import UploadRPP from '../pages/Kurikulum/RencanaNilai/UploadRPP.page';

import NilaiRaport from '../pages/Kurikulum/Raport/NilaiRaport.page';
import NilaiRaportCetak from '../pages/Kurikulum/Raport/NilaiRaportCetak.page';
import NilaiRaportEdit from '../pages/Kurikulum/Raport/NilaiRaportEdit.page';
import Raport from '../pages/Kurikulum/Raport/Raport.page';

import LeggerPeriode from '../pages/Kurikulum/Raport/LeggerPeriode.page';
import LeggerRekap from '../pages/Kurikulum/Raport/LeggerRekap.page';
import RaportKBK from '../pages/Kurikulum/Raport/RaportKBK.page';
import RaportKBKdetail from '../pages/Kurikulum/Raport/RaportKBKdetail.page';

import IdentitasPortraitK13 from '../pages/Kurikulum/Raport/Cetak/IdentitasPortraitK13.page';
import IdentitasPortraitKTSP from '../pages/Kurikulum/Raport/Cetak/IdentitasPortraitKTSP.page';
import InputRaportNarasi from '../pages/Kurikulum/Raport/Cetak/InputRaportNarasi.page';
import LaporanHasilKI2 from '../pages/Kurikulum/Raport/Cetak/LaporanHasilKI2.page';
import LaporanHasilKI4 from '../pages/Kurikulum/Raport/Cetak/LaporanHasilKI4.page';
import LaporanHasilKTSP from '../pages/Kurikulum/Raport/Cetak/LaporanHasilKTSP.page';
import RaportNarasi from '../pages/Kurikulum/Raport/Cetak/RaportNarasi.page';
import RaportPTS from '../pages/Kurikulum/Raport/Cetak/RaportPTS.page';
import SampulDpnK13 from '../pages/Kurikulum/Raport/Cetak/SampulDpnK13.page';
import SampulDpnKTSP from '../pages/Kurikulum/Raport/Cetak/SampulDpnKTSP.page';
import SampulIdentitasK13 from '../pages/Kurikulum/Raport/Cetak/SampulIdentitasK13.page';
import SampulIdentitasKTSP from '../pages/Kurikulum/Raport/Cetak/SampulIdentitasKTSP.page';

import Analisa from '../pages/Kurikulum/Analisa/Analisa.kurikulum.page';
import EstimasiKenaikanKls from '../pages/Kurikulum/Analisa/EstimasiKenaikanKls.kurikulum.page';
import KomposisiSiswa from '../pages/Kurikulum/Analisa/KomposisiSiswa.kurikulum.page';
import KomposisiSiswaAgama from '../pages/Kurikulum/Analisa/KomposisiSiswaAgama.kurikulum.page';
import Syarat from '../pages/Kurikulum/Analisa/Syarat.kurikulum.page';
import GenerateSoal from '../pages/Kurikulum/Soal/GenerateSoal.kurikulum.page';
import ListSoal from '../pages/Kurikulum/Soal/ListSoal.kurikulum.page';
import PengaturanSoal from '../pages/Kurikulum/Soal/PengaturanSoal.kurikulum.page';
import Soal from '../pages/Kurikulum/Soal/Soal.kurikulum.page';
import TambahSoal from '../pages/Kurikulum/Soal/TambahSoal.kurikulum.page';

import CatatBuku from '../pages/Library/CatatBuku/CatatBuku.library.page';
import DataBuku from '../pages/Library/CatatBuku/DataBuku.library.page';
import DataPerpus from '../pages/Library/DataPerpus.page';
import Library from '../pages/Library/Library.page';
import BuatDaftarBuku from '../pages/Library/TableAsal/BuatDaftarBuku.library.page';
import TableAsal from '../pages/Library/TableAsal/TableAsal.library.page';
import BuatDaftarUnitPerpus from '../pages/Library/TableControlPerpus/BuatDaftarUnitPerpus.library.page';
import TableControlPerpus from '../pages/Library/TableControlPerpus/TableControlPerpus.library.page';
import BuatKlasifikasi from '../pages/Library/TableKlasifikasi/BuatKlasifikasi.library.page';
import TableKlasifikasi from '../pages/Library/TableKlasifikasi/TableKlasifikasi.library.page';
import Top10Analysis from '../pages/Library/Top10Analysis/Top10Analysis.library.page';
import Top10AnalysisDetail from '../pages/Library/Top10Analysis/Top10AnalysisDetail.library.page';
import UploadBuku from '../pages/Library/Upload.library.page';

import EntryData from '../pages/Library/EntryData/EntryData.library.page';
import EntryDataIndividu from '../pages/Library/EntryData/EntryDataIndividu.library.page';
import EntryDataPerkelas from '../pages/Library/EntryData/EntryDataPerkelas.library.page';
import GrafikAnalisis from '../pages/Library/GrafikAnalisis/GrafikAnalisis.library.page';
import GrafikAnalisisDetail from '../pages/Library/GrafikAnalisis/GrafikAnalisisDetail.library.page';
import CetakLaporanPemasukanUang from '../pages/Library/LaporanPemasukanUang/CetakLaporanPemasukanUang.library.page';
import LaporanPemasukanUang from '../pages/Library/LaporanPemasukanUang/LaporanPemasukanUang.library.page';
import LaporanPeminjam from '../pages/Library/LaporanPeminjam/LaporanPeminjam.library.page';
import LaporanPeminjamDetail from '../pages/Library/LaporanPeminjam/LaporanPeminjamDetail.library.page';
import LaporanPengunjung from '../pages/Library/LaporanPengunjung/LaporanPengunjung.library.page';
import LaporanPengunjungDetail from '../pages/Library/LaporanPengunjung/LaporanPengunjungDetail.library.page';
import PeminjamanBuku from '../pages/Library/PeminjamanBuku/PeminjamanBuku.library.page';
import PengembalianBuku from '../pages/Library/PengembalianBuku/PengembalianBuku.library.page';
import SearchBuku from '../pages/Library/SearchBuku/SearchBuku.library.page';
import SearchBukuDetail from '../pages/Library/SearchBuku/SearchBukuDetail.library.page';

import { remove, storageKeys } from '../utils/storage.util';

import { SIDEBAR__DASHBOARD_MENU } from '../constants/menu.constant';
import MonthlyActivityProcurementFinancePage from '../pages/Finance/Budgeting/MonthlyActivityPlan/MonthlyActivityProcurement.finance.page';
import MonthlyActivityActivityFinancePage from '../pages/Finance/Budgeting/MonthlyActivityPlan/MonthlyActivityActivity.finance.page';
import MonthlyActivityMaintenanceFinancePage from '../pages/Finance/Budgeting/MonthlyActivityPlan/MonthlyActivityMaintenance.finance.page';
import ManageMonthlyActivityPlan from '../pages/Finance/Budgeting/MonthlyActivityPlan/ManageMonthlyActivityPlan.finance.page';

class Dashboard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showSidebar: window.innerWidth > 900,
    };
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.onPressHome = this.onPressHome.bind(this);
    this.onClickLogout = this.onClickLogout.bind(this);
    this.onClickProfile = this.onClickProfile.bind(this);
    this.onClickMenu = this.onClickMenu.bind(this);
    this.onLogout = this.onLogout.bind(this);
    this._onClickBreadcrumb = this._onClickBreadcrumb.bind(this);
  }

  onPressHome() {
    const { history } = this.props;
    history.replace('/dashboard');
  }

  onClickLogout() {
    const { history } = this.props;
    history.replace('/login');
    remove(storageKeys.USER_TOKEN);
  }

  onClickProfile() {
    const { history } = this.props;
    history.push('/dashboard/profile');
  }

  onClickMenu(path) {
    const { history } = this.props;
    history.push(path);
  }

  onLogout() {
    const { onLogout, history } = this.props;
    onLogout(history.push);
  }

  _onClickBreadcrumb(index) {
    const { location, history } = this.props;
    const listPath = location.pathname.split('/');
    const newPath = listPath.slice(0, index + 2);
    history.push(newPath.join('/'));
  }

  toggleSidebar() {
    this.setState(prevState => ({
      showSidebar: !prevState.showSidebar,
    }));
  }

  render() {
    const { showSidebar } = this.state;
    const {
      match, location, user, units, onSearchUnit, handleSetWorkingUnit,
    } = this.props;

    return (
      <div className="dashboard">
        <Header
          onClickMenu={this.toggleSidebar}
          pathname={location.pathname}
          onClickBreadcrumb={this._onClickBreadcrumb}
          onSearchUnit={onSearchUnit}
          setWorkingUnit={handleSetWorkingUnit}
          user={user}
          units={units}
        />
        <div className="dashboard__wrapper">
          <Sidebar
            show={showSidebar}
            sidebarMenu={SIDEBAR__DASHBOARD_MENU}
            onClickMenu={this.onClickMenu}
            onLogout={this.onLogout}
            user={user}
          />
          <div className="dashboard__main-panel">
            <div className="dashboard__content-wrapper">
              <Route exact path={match.path} component={Home} />

              <Route
                exact
                path={`${match.path}/kesiswaan`}
                component={Student}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/pendaftaran`}
                component={ManageRegistration}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/list-pendaftaran-siswa`}
                component={ListPendaftaranSiswa}
              />

              <Route
                exact
                path={`${match.path}/kesiswaan/data-siswa/catat-siswa-keluar`}
                component={CatatSiswaKeluar}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/data-siswa/catat-siswa-keluar2`}
                component={CatatSiswaKeluar2}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/data-siswa/pembatalan-siswa-keluar`}
                component={PembatalanSiswaKeluar}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/data-siswa/upload`}
                component={UploadStudent}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/data-siswa`}
                component={DataSiswa}
              />

              <Route
                exact
                path={`${match.path}/kesiswaan/laporan`}
                component={Report}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/laporan/nis`}
                component={StudentNumber}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/laporan/klapper`}
                component={Klapper}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/laporan/kbk`}
                component={StudentKbk}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/laporan/nilai-siswa`}
                component={StudentGrade}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/laporan/buku-siswa`}
                component={StudentBook}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/laporan/daftar-siswa-kelas`}
                component={DaftarSiswaKls}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/laporan/daftar-siswa-kelas-unit`}
                component={DaftarSiswaKlsUnit}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/laporan/search-siswa`}
                component={SearchSiswa}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/laporan/cetak-buku-induk`}
                component={CetakBukuSiswa}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/laporan/laporan-8355`}
                component={Laporan8355}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/laporan/laporan-displiner`}
                component={LaporanDispliner}
              />
              <Route
                exact
                path={`${match.path}/Kesiswaan/laporan/laporan-konseling`}
                component={LaporanKonseling}
              />
              <Route
                exact
                path={`${match.path}/Kesiswaan/laporan/laporan-absensi-kelas`}
                component={LaporanAbsensiKelas}
              />
              <Route
                exact
                path={`${match.path}/Kesiswaan/laporan/laporan-persensi`}
                component={LaporanPersensi}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/laporan/cetak-laporan-8355`}
                component={CetakLaporan8355}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/laporan/cetak-laporan-displiner`}
                component={CetakLaporanDispliner}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/laporan/cetak-laporan-konseling`}
                component={CetakLaporanKonseling}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/laporan/cetak-laporan-absensi-kelas`}
                component={CetakLaporanAbsensiKelas}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/laporan/cetak-laporan-persensi`}
                component={CetakLaporanPersensi}
              />

              <Route
                exact
                path={`${match.path}/kesiswaan/absensi-siswa`}
                component={AbsensiSiswa}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/absensi-siswa/absensi-harian`}
                component={AbsensiHarian}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/absensi-siswa/upload-absensi-harian`}
                component={UploadAbsensiHarian}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/absensi-siswa/absensi-rekap`}
                component={AbsensiRekap}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/absensi-siswa/absensi-rekap/cetak-absensi-rekap`}
                component={CetakAbsensiRekap}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/absensi-siswa/absensi-rekap/grafik`}
                component={AbsensiRekapGrafik}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/absensi-siswa/isi-perbidang-study`}
                component={IsiPerbidangStudy}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/absensi-siswa/tambah-absen-perbidang-study`}
                component={TambahAbsenPerbidangStudy}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/absensi-siswa/laporan-absen-bidang-study`}
                component={LaporanAbsensi}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/absensi-siswa/TBM`}
                component={TBM}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/absensi-siswa/Mengikuti`}
                component={Mengikuti}
              />

              <Route
                exact
                path={`${match.path}/kesiswaan/displiner`}
                component={Displiner}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/displiner/table-pelanggaran`}
                component={TablePelanggaran}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/displiner/buat-pelanggaran`}
                component={BuatPelanggaran}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/displiner/buat-group-pelanggaran`}
                component={BuatGroupPelanggaran}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/displiner/list-group-pelanggaran`}
                component={ListGroupPelanggaran}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/displiner/table-prestasi`}
                component={TablePrestasi}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/displiner/buat-prestasi`}
                component={BuatPrestasi}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/displiner/buat-group-prestasi`}
                component={BuatGroupPrestasi}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/displiner/list-group-prestasi`}
                component={ListGroupPrestasi}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/displiner/catat-pelanggaran`}
                component={CatatPelanggaran}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/displiner/catat-prestasi`}
                component={CatatPrestasi}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/displiner/catat-harian-siswa`}
                component={CatatHarianSiswa}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/displiner/analisa-displiner`}
                component={AnalisaDispliner}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/displiner/analisa-displiner-pelanggaran`}
                component={AnalisaPelanggaran}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/displiner/analisa-pelanggaran`}
                component={Pelanggaran}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/displiner/analisa-displiner-prestasi`}
                component={AnalisaPrestasi}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/displiner/analisa-prestasi`}
                component={Prestasi}
              />

              <Route
                exact
                path={`${match.path}/kesiswaan/ekstrakulikuler`}
                component={Ekskul}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/ekstrakulikuler/jenis-ekstrakulikuler`}
                component={JenisEkstrakulikuler}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/ekstrakulikuler/buat-jenis-ekskul`}
                component={BuatJenisEkskul}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/ekstrakulikuler/daftar-peserta-ekskul`}
                component={DaftarPesertaEkskul}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/ekstrakulikuler/buat-daftar-ekskul`}
                component={BuatDaftarEkskul}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/ekstrakulikuler/absensi-ekskul`}
                component={AbsensiEkskul}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/ekstrakulikuler/isi-absen-ekskul`}
                component={PengisianEkskul}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/ekstrakulikuler/laporan-ekskul`}
                component={LaporanEkskul}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/ekstrakulikuler/pengisian-nilai-ekskul`}
                component={PengisianNilaiEkskul}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/ekstrakulikuler/pembagian-kelas-ekskul`}
                component={PembagianKelasEkskul}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/ekstrakulikuler/buat-kelas-ekskul`}
                component={BuatKelasEkskul}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/ekstrakulikuler/buat-nilai-ekskul`}
                component={BuatNilaiEkskul}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/ekstrakulikuler/isi-nilai-ekskul`}
                component={isiNilaiEkskul}
              />

              <Route
                exact
                path={`${match.path}/kesiswaan/bimbingan-konseling/konseling-siswa`}
                component={KonselingSiswa}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/buat-catatan-konseling`}
                component={BuatCatatanKonseling}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/tambah-tempat-konseling`}
                component={TambahTempatKonseling}
              />
              <Route
                exact
                path={`${match.path}/kesiswaan/bimbingan-konseling/history-konseling`}
                component={HistoryKonseling}
              />

              <Route
                exact
                path={`${match.path}/personalia/kelola-staff`}
                component={ManageStaff}
              />
              <Route
                exact
                path={`${match.path}/personalia/data-staff`}
                component={StaffList}
              />
              <Route
                exact
                path={`${match.path}/personalia/upload-staff`}
                component={UploadStaff}
              />

              <Route
                exact
                path={`${match.path}/personalia/surat-keputusan/:nip`}
                component={InformationLetter}
              />
              <Route
                exact
                path={`${match.path}/personalia/tambah-surat-keputusan/:nip`}
                component={ManageInformationLetter}
              />
              <Route
                exact
                path={`${match.path}/personalia/edit-surat-keputusan/:id`}
                component={ManageInformationLetter}
              />

              <Route
                exact
                path={`${match.path}/personalia/surat/lamaran-kerja`}
                component={JobApplication}
              />
              <Route
                exact
                path={`${match.path}/personalia/surat/tambah-lamaran-kerja`}
                component={ManageJobApplication}
              />

              <Route
                exact
                path={`${match.path}/personalia/surat/permohonan-barang-jasa`}
                component={Request}
              />
              <Route
                exact
                path={`${match.path}/personalia/surat/tambah-permohonan-barang-jasa`}
                component={ManageRequest}
              />

              <Route
                exact
                path={`${match.path}/personalia/surat/pemberhentian`}
                component={Discontinuance}
              />
              <Route
                exact
                path={`${match.path}/personalia/surat/tambah-pemberhentian`}
                component={ManageDiscontinuance}
              />

              <Route
                exact
                path={`${match.path}/personalia/surat/keterangan-pemberhentian`}
                component={DiscontinuanceDescription}
              />
              <Route
                exact
                path={`${match.path}/personalia/surat/tambah-keterangan-pemberhentian`}
                component={ManageDiscontinuanceDescription}
              />

              <Route
                exact
                path={`${match.path}/personalia/surat/penugasan`}
                component={Assignment}
              />
              <Route
                exact
                path={`${match.path}/personalia/surat/tambah-penugasan`}
                component={ManageAssignment}
              />

              <Route
                exact
                path={`${match.path}/personalia/surat/pensiun`}
                component={Retire}
              />
              <Route
                exact
                path={`${match.path}/personalia/surat/tambah-pensiun`}
                component={ManageRetire}
              />

              <Route
                exact
                path={`${match.path}/personalia/surat/kenaikan`}
                component={Promotion}
              />
              <Route
                exact
                path={`${match.path}/personalia/surat/tambah-kenaikan`}
                component={ManagePromotion}
              />

              <Route
                exact
                path={`${match.path}/personalia/surat/karyawan-kontrak`}
                component={EmployeeContract}
              />
              <Route
                exact
                path={`${match.path}/personalia/surat/tambah-karyawan-kontrak`}
                component={ManageEmployeeContract}
              />

              <Route
                exact
                path={`${match.path}/personalia/surat/guru-kontrak`}
                component={TeacherContract}
              />
              <Route
                exact
                path={`${match.path}/personalia/surat/tambah-guru-kontrak`}
                component={ManageTeacherContract}
              />

              <Route
                exact
                path={`${match.path}/personalia/surat/jabatan`}
                component={Position}
              />
              <Route
                exact
                path={`${match.path}/personalia/surat/tambah-jabatan`}
                component={ManagePosition}
              />

              <Route
                exact
                path={`${match.path}/personalia/surat/pengangkatan`}
                component={Permanent}
              />
              <Route
                exact
                path={`${match.path}/personalia/surat/tambah-pengangkatan`}
                component={ManagePermanent}
              />

              <Route
                exact
                path={`${match.path}/personalia/laporan/masa-kerja`}
                component={JobPeriod}
              />
              <Route
                exact
                path={`${match.path}/personalia/laporan/guru-karyawan`}
                component={TeacherEmployee}
              />

              <Route
                exact
                path={`${match.path}/personalia/absensi-pegawai`}
                component={AbsensiPegawai}
              />
              <Route
                exact
                path={`${match.path}/personalia/absensi-pegawai/approval`}
                component={Approval}
              />
              <Route
                exact
                path={`${match.path}/personalia/absensi-pegawai/laporan-absensi-pegawai`}
                component={LaporanAbsensiPegawai}
              />
              <Route
                exact
                path={`${match.path}/personalia/absensi-pegawai/laporan-absensi-khusus-pegawai`}
                component={LaporanAbsensiKhusus}
              />
              <Route
                exact
                path={`${match.path}/personalia/absensi-pegawai/laporan-absensi-pegawai-detail`}
                component={LaporanAbsensiPegawaiDetail}
              />
              <Route
                exact
                path={`${match.path}/personalia/absensi-pegawai/pengajuan-cuti`}
                component={PengajuanCuti}
              />
              <Route
                exact
                path={`${match.path}/personalia/absensi-pegawai/pengajuan-ijin-pulang-awal`}
                component={PengajuanIjinPulang}
              />
              <Route
                exact
                path={`${match.path}/personalia/absensi-pegawai/pengajuan-kegiatan-pegawai`}
                component={PengajuanKegiatan}
              />
              <Route
                exact
                path={`${match.path}/personalia/absensi-pegawai/pengajuan-lembur-pegawai`}
                component={PengajuanLembur}
              />
              <Route
                exact
                path={`${match.path}/personalia/absensi-pegawai/table-kegiatan-pegawai`}
                component={TableKegiatan}
              />

              <Route exact path={`${match.path}/upload`} component={Upload} />
              <Route
                exact
                path={`${match.path}/keuangan`}
                component={Finance}
              />
              <Route
                exact
                path={`${match.path}/keuangan/penganggaran`}
                component={Budgeting}
              />
              <Route
                exact
                path={`${match.path}/keuangan/penganggaran/anggaran`}
                component={Budget}
              />
              <Route
                exact
                path={`${match.path}/keuangan/penganggaran/tambah-anggaran`}
                component={AddBudget}
              />
              <Route
                exact
                path={`${match.path}/keuangan/penganggaran/edit-anggaran`}
                component={EditBudget}
              />
              <Route
                exact
                path={`${match.path}/keuangan/penganggaran/tipe-anggaran`}
                component={BudgetType}
              />
              <Route
                exact
                path={`${match.path}/keuangan/penganggaran/tipe-anggaran2`}
                component={BudgetType2}
              />
              <Route
                exact
                path={`${match.path}/keuangan/penganggaran/tipe-anggaran3`}
                component={BudgetType3}
              />

              <Route
                exact
                path={`${match.path}/keuangan/penganggaran/kelola-anggaran`}
                component={ManageBudget}
              />
              <Route
                exact
                path={`${match.path}/keuangan/penganggaran/kelola-anggaran/*`}
                component={ManageBudget}
              />

              <Route
                exact
                path={`${match.path}/keuangan/penganggaran/rapbs`}
                component={ManageBudgetRapbu}
              />
              <Route
                exact
                path={`${match.path}/keuangan/penganggaran/rapby`}
                component={ManageBudgetRapby}
              />
              <Route
                exact
                path={`${match.path}/keuangan/penganggaran/upload-rapbs-rapby`}
                component={UploadRapbu}
              />
              <Route
                exact
                path={`${match.path}/keuangan/penganggaran/tambah-upload-rapbs-rapby`}
                component={AddUploadRapbu}
              />
              <Route
                exact
                path={`${match.path}/keuangan/penganggaran/detail-rapbs`}
                component={ManageBudgetRapbuDetail}
              />
              <Route
                exact
                path={`${match.path}/keuangan/penganggaran/detail-rapby`}
                component={ManageBudgetRapbuDetailRapby}
              />
              <Route
                exact
                path={`${match.path}/keuangan/penganggaran/rekap-rapby`}
                component={ManageBudgetRapbuRekapRapby}
              />
              <Route
                exact
                path={`${match.path}/keuangan/penganggaran/rekap-rapbs`}
                component={ManageBudgetRapbuRekap}
              />
              <Route
                exact
                path={`${match.path}/keuangan/penganggaran/rekap-rapbs-excel`}
                component={ManageBudgetRapbuRekapExcel}
              />
              <Route
                exact
                path={`${match.path}/keuangan/penganggaran/rekap-rapby-excel`}
                component={ManageBudgetRapbuRekapRapbyExcel}
              />

              <Route
                exact
                path={`${match.path}/keuangan/penganggaran/rapbu`}
                component={ManageBudgetRapbu}
              />

              <Route
                exact
                path={`${match.path}/keuangan/penganggaran/non-anggaran`}
                component={NonBudget}
              />
              <Route
                exact
                path={`${match.path}/keuangan/penganggaran/tambah-non-anggaran`}
                component={ManageNonBudget}
              />
              <Route
                exact
                path={`${match.path}/keuangan/penganggaran/edit-non-anggaran`}
                component={ManageNonBudget}
              />

              <Route
                exact
                path={`${match.path}/keuangan/penganggaran/pengalihan-anggaran`}
                component={BudgetTransfer}
              />
              <Route
                exact
                path={`${match.path}/keuangan/penganggaran/tambah-pengalihan-anggaran`}
                component={ManageBudgetTransfer}
              />
              <Route
                exact
                path={`${match.path}/keuangan/penganggaran/edit-pengalihan-anggaran`}
                component={ManageBudgetTransfer}
              />

              <Route
                exact
                path={`${match.path}/keuangan/penganggaran/permohonan-dana`}
                component={FundRequest}
              />
              <Route
                exact
                path={`${match.path}/keuangan/penganggaran/tambah-permohonan-dana`}
                component={ManageFundRequest}
              />
              <Route
                exact
                path={`${match.path}/keuangan/penganggaran/edit-permohonan-dana`}
                component={ManageFundRequest}
              />

              {/* // Riri New Recent Change for Jurnal Ricci  */}
              <Route
                exact
                path={`${match.path}/keuangan/penganggaran/pengajuan-pengadaan`}
                component={MonthlyActivityProcurementFinancePage}
              />
              <Route
                exact
                path={`${match.path}/keuangan/penganggaran/pengajuan-perbaikan`}
                component={MonthlyActivityMaintenanceFinancePage}
              />
              <Route
                exact
                path={`${match.path}/keuangan/penganggaran/pengajuan-kegiatan`}
                component={MonthlyActivityActivityFinancePage}
              />
              <Route
                exact
                path={`${match.path}/keuangan/penganggaran/tambah-kegiatan-bulanan`}
                component={ManageMonthlyActivityPlan}
              />
              <Route
                exact
                path={`${match.path}/keuangan/penganggaran/edit-rencana-kegiatan-bulanan`}
                component={ManageMonthlyActivityPlan}
              />
              <Route
                exact
                path={`${match.path}/keuangan/penganggaran/rencana-kegiatan-bulanan-rapbs`}
                component={MonthlyActivityPlanRapbs}
              />
              <Route
                exact
                path={`${match.path}/keuangan/penganggaran/cetak-rencana-kegiatan-bulanan`}
                component={MonthlyActivityPlanRapby}
              />
              <Route
                exact
                path={`${match.path}/keuangan/penganggaran/tambah-kegiatan-bulanan-rapby`}
                component={ManageMonthlyActivityPlanRapby}
              />
              <Route
                exact
                path={`${match.path}/keuangan/penganggaran/realisasi`}
                component={ManageRealization}
              />
              <Route
                exact
                path={`${match.path}/keuangan/penganggaran/tambah-realisasi`}
                component={ManageRealization}
              />
              <Route
                exact
                path={`${match.path}/keuangan/penganggaran/edit-realisasi`}
                component={ManageRealization}
              />

              <Route
                exact
                path={`${match.path}/keuangan/pembukuan`}
                component={Bookkeeping}
              />
              <Route
                exact
                path={`${match.path}/keuangan/pembukuan/saldo-awal`}
                component={BeginningBalance}
              />
              <Route
                exact
                path={`${match.path}/keuangan/pembukuan/jurnal-kas`}
                component={CashJournal}
              />
              <Route
                exact
                path={`${match.path}/keuangan/pembukuan/jurnal-kas/:id`}
                component={DetailCashJournal}
              />
              <Route
                exact
                path={`${match.path}/keuangan/pembukuan/kelola-jurnal-kas`}
                component={AddCashJournal}
              />

              <Route
                exact
                path={`${match.path}/keuangan/pembukuan/jurnal-bank`}
                component={BankJournal}
              />
              <Route
                exact
                path={`${match.path}/keuangan/pembukuan/jurnal-bank/:id`}
                component={DetailBankJournal}
              />
              <Route
                exact
                path={`${match.path}/keuangan/pembukuan/kelola-jurnal-bank`}
                component={AddBankJournal}
              />

              <Route
                exact
                path={`${match.path}/keuangan/pembukuan/jurnal-pembayaran`}
                component={PaymentJournal}
              />
              <Route
                exact
                path={`${match.path}/keuangan/pembukuan/kelola-jurnal-pembayaran`}
                component={ManagePaymentJournal}
              />

              <Route
                exact
                path={`${match.path}/keuangan/pembukuan/jurnal-umum`}
                component={GeneralJournal}
              />
              <Route
                exact
                path={`${match.path}/keuangan/pembukuan/kelola-jurnal-umum`}
                component={ManageGeneralJournal}
              />
              <Route
                exact
                path={`${match.path}/keuangan/pembukuan/jurnal-umum/:id`}
                component={DetailGeneralJournal}
              />

              <Route
                exact
                path={`${match.path}/keuangan/pembukuan/jurnal-penutup`}
                component={ClosingJournal}
              />
              <Route
                exact
                path={`${match.path}/keuangan/pembukuan/kelola-jurnal-penutup`}
                component={ManageClosingJournal}
              />

              <Route
                exact
                path={`${match.path}/keuangan/pembukuan/jurnal-non-kas`}
                component={NonKasJournal}
              />
              <Route
                exact
                path={`${match.path}/keuangan/pembukuan/kelola-jurnal-non-kas`}
                component={ManageNonKasJournal}
              />

              <Route
                exact
                path={`${match.path}/keuangan/laporan/jurnal-kas`}
                component={CashJournalReport}
              />
              <Route
                exact
                path={`${match.path}/keuangan/laporan/jurnal-bank`}
                component={BankJournalReport}
              />
              <Route
                exact
                path={`${match.path}/keuangan/laporan/buku-besar`}
                component={BigBookReport}
              />
              <Route
                exact
                path={`${match.path}/keuangan/laporan/transaksi-bulanan`}
                component={MonthlyReport}
              />
              <Route
                exact
                path={`${match.path}/keuangan/laporan/jurnal-harian-dan-bulanan`}
                component={DailyAndMonthly}
              />
              <Route
                exact
                path={`${match.path}/keuangan/laporan/laba-rugi`}
                component={ProfitAndLost}
              />
              <Route
                exact
                path={`${match.path}/keuangan/laporan/neraca`}
                component={Balance}
              />

              <Route
                exact
                path={`${match.path}/keuangan/spp`}
                component={Spp}
              />
              <Route
                exact
                path={`${match.path}/keuangan/spp/jenis-spp`}
                component={JenisSPP}
              />
              <Route
                exact
                path={`${match.path}/keuangan/spp/tambah-jenis-spp`}
                component={TambahJenisSPP}
              />
              <Route
                exact
                path={`${match.path}/keuangan/spp/tambah-status`}
                component={TambahStatus}
              />
              <Route
                exact
                path={`${match.path}/keuangan/spp/master-data-keuangan`}
                component={MasterDataKeu}
              />
              <Route
                exact
                path={`${match.path}/keuangan/spp/data-keuangan`}
                component={DataKeu}
              />
              <Route
                exact
                path={`${match.path}/keuangan/spp/data-keuangan/detail`}
                component={DataKeuDetail}
              />
              <Route
                exact
                path={`${match.path}/keuangan/spp/create-invoice`}
                component={CreateInvoice}
              />
              <Route
                exact
                path={`${match.path}/keuangan/spp/pembayaran`}
                component={Pembayaran}
              />
              <Route
                exact
                path={`${match.path}/keuangan/spp/pembayaran/proses-pembayaran`}
                component={ProsesPembayaran}
              />
              <Route
                exact
                path={`${match.path}/keuangan/spp/create-invoice/individual`}
                component={Individual}
              />
              <Route
                exact
                path={`${match.path}/keuangan/spp/create-invoice/kelompok-kelas`}
                component={KelompokKls}
              />
              <Route
                exact
                path={`${match.path}/keuangan/spp/create-invoice/proses-invoice`}
                component={ProsesInvoice}
              />
              <Route
                exact
                path={`${match.path}/keuangan/spp/laporan-spp`}
                component={LaporanSPP}
              />
              <Route
                exact
                path={`${match.path}/keuangan/spp/laporan-keuangan/invoice-summary-report`}
                component={InvoiceSummary}
              />
              <Route
                exact
                path={`${match.path}/keuangan/spp/laporan-keuangan/cetak-invoice`}
                component={CetakInvoice}
              />
              <Route
                exact
                path={`${match.path}/keuangan/spp/laporan-keuangan/invoice-payment-report`}
                component={InvoicePayment}
              />
              <Route
                exact
                path={`${match.path}/keuangan/spp/laporan-keuangan/invoice-outstading-report`}
                component={InvoiceOutstading}
              />
              <Route
                exact
                path={`${match.path}/keuangan/spp/laporan-invoice-persiswa`}
                component={LaporanInvoice}
              />
              <Route
                exact
                path={`${match.path}/keuangan/spp/laporan-invoice-persiswa/detail`}
                component={LaporanInvoiceDetail}
              />
              <Route
                exact
                path={`${match.path}/keuangan/spp/upload-invoice`}
                component={UploadInvoice}
              />
              <Route
                exact
                path={`${match.path}/keuangan/spp/cetak-slip-inv/kwitansi`}
                component={CetakSlip}
              />
              <Route
                exact
                path={`${match.path}/keuangan/spp/cetak-slip-inv/kwitansi/detail`}
                component={CetakSlipDetail}
              />
              <Route
                exact
                path={`${match.path}/keuangan/spp/cetak-slip-inv/kwitansi/cetak`}
                component={CetakSlipKwitansi}
              />
              <Route
                exact
                path={`${match.path}/keuangan/pengaturan/tema-coa-map`}
                component={TemaCoaMapping}
              />
              <Route
                exact
                path={`${match.path}/keuangan/spp/cetak-bukti-bayar/kwitansi/detail`}
                component={CetakBuktiDetail}
              />
              <Route
                exact
                path={`${match.path}/keuangan/spp/cetak-bukti-bayar/kwitansi/cetak`}
                component={CetakBuktiKwitansi}
              />
              <Route
                exact
                path={`${match.path}/keuangan/spp/create-invoice/generate-invoice`}
                component={GenerateInvoice}
              />

              <Route
                exact
                path={`${match.path}/administrasi`}
                component={Administration}
              />
              <Route
                exact
                path={`${match.path}/administrasi/data-kelas`}
                component={DataKelas}
              />
              <Route
                exact
                path={`${match.path}/administrasi/data-kelas/identitas-unit-sekolah`}
                component={Identitas}
              />
              <Route
                exact
                path={`${match.path}/administrasi/data-kelas/buat-identitas-sekolah`}
                component={TambahIdentitas}
              />
              <Route
                exact
                path={`${match.path}/administrasi/data-kelas/buat-identitas-sekolah2`}
                component={TambahIdentitas2}
              />
              <Route
                exact
                path={`${match.path}/administrasi/data-kelas/tahun-ajaran`}
                component={TahunAjaran}
              />
              <Route
                exact
                path={`${match.path}/administrasi/data-kelas/pindah-unit`}
                component={PindahUnit}
              />
              <Route
                exact
                path={`${match.path}/administrasi/data-kelas/daftar-pindah-unit`}
                component={DaftarPindahUnit}
              />
              <Route
                exact
                path={`${match.path}/administrasi/data-kelas/permohonan-pindah-unit`}
                component={PermohonanPindahUnit}
              />

              <Route
                exact
                path={`${match.path}/administrasi/data-kelas/jurusan-kelas`}
                component={JurusanKelas}
              />
              <Route
                exact
                path={`${match.path}/administrasi/data-kelas/buat-data-jurusan-kelas`}
                component={TambahJurusan}
              />
              <Route
                exact
                path={`${match.path}/administrasi/data-kelas/kenaikan-kelas-siswa`}
                component={KenaikanKelas}
              />
              <Route
                exact
                path={`${match.path}/administrasi/data-kelas/kelola-kenaikan-kelas-siswa`}
                component={Kelola}
              />
              <Route
                exact
                path={`${match.path}/administrasi/data-kelas/kelas`}
                component={Kelas}
              />
              <Route
                exact
                path={`${match.path}/administrasi/data-kelas/buat-data-kelas`}
                component={TambahKelas}
              />
              <Route
                exact
                path={`${match.path}/administrasi/data-kelas/upload-kelas`}
                component={UploadKelas}
              />
              <Route
                exact
                path={`${match.path}/administrasi/data-kelas/kelas-khusus`}
                component={KelasKhusus}
              />
              <Route
                exact
                path={`${match.path}/administrasi/data-kelas/buat-data-kelas-khusus`}
                component={TambahKelasKhusus}
              />
              <Route
                exact
                path={`${match.path}/administrasi/data-kelas/pembagian-kelas`}
                component={PembagianKelas}
              />
              <Route
                exact
                path={`${match.path}/administrasi/data-kelas/kelola-pembagian-kelas`}
                component={KelolaPembagianKls}
              />
              <Route
                exact
                path={`${match.path}/administrasi/data-kelas/pembagian-kelas-khusus`}
                component={PembagianKelasKhusus}
              />
              <Route
                exact
                path={`${match.path}/administrasi/data-kelas/kelola-pembagian-kelas-khusus`}
                component={KelolaPembagianKlsKhusus}
              />
              <Route
                exact
                path={`${match.path}/administrasi/data-kelas/siswa-pindah-kelas`}
                component={PindahKelas}
              />
              <Route
                exact
                path={`${match.path}/administrasi/data-kelas/kelola-siswa-pindah-kelas`}
                component={KelolaPindahKelas}
              />

              <Route
                exact
                path={`${match.path}/kurikulum`}
                component={Kurikulum}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/ulangan`}
                component={Ulangan}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/ulangan/daftar-nilai`}
                component={DaftarNilai}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/laporan/daftar-nilai-siswa-ktsp`}
                component={DaftarNilaiKTSP}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/laporan/daftar-nilai-siswa`}
                component={DaftarNilaiSiswa}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/laporan/analisa-nilai-siswa`}
                component={AnalisaNilaiSiswa}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/laporan/daftar-nilai-siswa-k13`}
                component={DaftarNilaiK13}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/laporan/komentar-manual`}
                component={KomentarManual}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/laporan/psikomotorik-siswa`}
                component={Psikomotorik}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/laporan/daftar-nilai-praktek`}
                component={DaftarNilaiPraktek}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/ulangan/pengisian-nilai-kognitif`}
                component={NilaiKognitif}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/ulangan/tambah-penilaian-nilai-kognitif`}
                component={TambahPenilaianNilaiKognitif}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/ulangan/tambah-penilaian-nilai-kognitif/upload-nilai-kognitif`}
                component={UploadNilaiKognitif}
              />

              <Route
                exact
                path={`${match.path}/kurikulum/ulangan/tambah-pengisian-psik-afek`}
                component={TambahPengisianPsikAfek}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/ulangan/tambah-pengisian-psik-afek/upload-pengisian-psik-afek`}
                component={UploadPengisianPsikAfek}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/ulangan/pengisian-nilai`}
                component={PengisianNilai}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/ulangan/pengisian-psik-afek`}
                component={PengisianPsikAfek}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/ulangan/pengisian-indikator-praktek`}
                component={PengisianPraktek}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/ulangan/pengisian-nilai-psik`}
                component={PengisianNilaiPsik}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/ulangan/pengisian-indikator-sikap`}
                component={PengisianSikap}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/ulangan/pengisian-nilai-afek`}
                component={PengisianNilaiAfek}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/ulangan/pengisian-kompetensi-dasar`}
                component={PengisianKompetensiDasar}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/ulangan/catatan-anekdot/tema`}
                component={Anekdot_Tema}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/ulangan/catatan-anekdot/sub-tema`}
                component={Anekdot_SubTema}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/ulangan/catatan-anekdot/pengisian-catatan-anekdot`}
                component={PengisianCatatanAnekdot}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/ulangan/catatan-anekdot/pengisian-catatan-anekdot/lembar`}
                component={LembarCatatanAnekdot}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/ulangan/catatan-anekdot/pengisian-catatan-anekdot/lembar/tema`}
                component={CatatanAnekdotTema}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/ulangan/catatan-anekdot/pengisian-catatan-anekdot/lembar/view`}
                component={CatatanAnekdotView}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/ulangan/pengisian-observasi-harian`}
                component={PengisianObsHarian}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/ulangan/pengisian-lembar-observasi-harian`}
                component={LembarObsHarian}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/ulangan/pengisian-lembar-observasi-mingguan`}
                component={LembarObsMingguan}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/ulangan/cetak`}
                component={CetakObservasi}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/ulangan/daftar-nilai/report`}
                component={DaftarNilaiReport}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/ulangan/pengisian-lembar-daftar-nilai/report`}
                component={PengisianDaftarNilai}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/ulangan/pengisian-lembar-daftar-nilai/report-inggris`}
                component={PengisianDaftarNilaiEng}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/ulangan/pengisian-lembar-daftar-nilai/report-mandarin`}
                component={PengisianDaftarNilaiMan}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/ulangan/cetak-raport`}
                component={CetakRaport}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/ulangan/cetak-raport-inggris`}
                component={CetakRaportEng}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/ulangan/cetak-raport-mandarin`}
                component={CetakRaportMan}
              />

              <Route
                exact
                path={`${match.path}/kurikulum/pengumuman`}
                component={Pengumuman}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/tambah-pengumuman`}
                component={TambahPengumuman}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/tambah-folder`}
                component={TambahFolder}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/jadwal`}
                component={Jadwal}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/jadwal/jadwal-per-kelas`}
                component={JadwalKelas}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/jadwal/tambah-jadwal-per-kelas`}
                component={TambahJadwalKelas}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/jadwal/jadwal-per-kelas/detail`}
                component={JadwalKelasDetail}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/jadwal/jadwal-karyawan`}
                component={JadwalKaryawan}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/jadwal/tambah-jadwal-karyawan`}
                component={TambahJadwalKaryawan}
              />

              <Route
                exact
                path={`${match.path}/kurikulum/mata-pelajaran`}
                component={MataPelajaran}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/mata-pelajaran/kode-mata-pelajaran`}
                component={KodeMatpel}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/mata-pelajaran/buat-kode-mata-pelajaran`}
                component={BuatKodeMatpel}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/mata-pelajaran/edit-kode-mata-pelajaran`}
                component={EditKodeMatpel}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/mata-pelajaran/buat-group-mata-pelajaran`}
                component={BuatGroupMatpel}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/mata-pelajaran/jadwal-mata-pelajaran`}
                component={JadwalMatpel}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/mata-pelajaran/isi-data-atau-edit-mata-pelajaran`}
                component={IsiJadwal}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/mata-pelajaran/cetak/jadwal-mata-pelajaran`}
                component={CetakJadwalMatpel}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/mata-pelajaran/daftar-guru`}
                component={DaftarGuru}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/mata-pelajaran/tambah-daftar-guru`}
                component={TambahDaftarGuru}
              />

              <Route
                exact
                path={`${match.path}/kurikulum/bobot-penilaian`}
                component={BobotNilai}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/bobot-penilaian/pengisian-bobot-penilaian`}
                component={IsiBobotNilai}
              />

              <Route
                exact
                path={`${match.path}/kurikulum/rencana-penilaian`}
                component={RencanaNilai}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/rencana-penilaian/upload-rpp`}
                component={UploadRPP}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/rencana-penilaian/upload`}
                component={UploadNilai}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/rencana-penilaian/upload-document`}
                component={UploadDocument}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/rencana-penilaian/document-upload`}
                component={DocUpload}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/rencana-penilaian/laporan-rpp-rpk`}
                component={LaporanRPP}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/rencana-penilaian/laporan-rpp-rpk/detail`}
                component={LaporanRPPdetail}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/rencana-penilaian/pengisian-kd`}
                component={PengisianKD}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/rencana-penilaian/pengisian-kd/tema`}
                component={PengisianKD2}
              />

              <Route
                exact
                path={`${match.path}/kurikulum/raport`}
                component={Raport}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/raport/daftar-nilai-raport`}
                component={NilaiRaport}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/raport/daftar-nilai-raport/edit`}
                component={NilaiRaportEdit}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/raport/daftar-nilai-raport/cetak`}
                component={NilaiRaportCetak}
              />

              <Route
                exact
                path={`${match.path}/kurikulum/raport/legger-per-periode`}
                component={LeggerPeriode}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/raport/legger-rekap-semua`}
                component={LeggerRekap}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/raport/raport-kbk`}
                component={RaportKBK}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/raport/raport-kbk/detail`}
                component={RaportKBKdetail}
              />

              <Route
                exact
                path={`${match.path}/kurikulum/raport/cetak/sampul-dpn-ktsp`}
                component={SampulDpnKTSP}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/raport/cetak/sampul-dpn-k13`}
                component={SampulDpnK13}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/raport/cetak/sampul-identitas-ktsp`}
                component={SampulIdentitasKTSP}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/raport/cetak/sampul-identitas-k13`}
                component={SampulIdentitasK13}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/raport/cetak/identitas-siswa-portrait-ktsp`}
                component={IdentitasPortraitKTSP}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/raport/cetak/identitas-siswa-portrait-k13`}
                component={IdentitasPortraitK13}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/raport/cetak/laporan-hasil-belajar-ktsp`}
                component={LaporanHasilKTSP}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/raport/cetak/laporan-hasil-belajar-ki2`}
                component={LaporanHasilKI2}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/raport/cetak/laporan-hasil-belajar-ki4`}
                component={LaporanHasilKI4}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/raport/cetak/raport-narasi`}
                component={RaportNarasi}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/raport/cetak/input-raport-narasi`}
                component={InputRaportNarasi}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/raport/cetak/raport-pts`}
                component={RaportPTS}
              />

              <Route
                exact
                path={`${match.path}/kurikulum/analisa`}
                component={Analisa}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/analisa/estimasi-kenaikan-kelas`}
                component={EstimasiKenaikanKls}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/analisa/syarat-ke/1`}
                component={Syarat}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/analisa/komposisi-siswa`}
                component={KomposisiSiswa}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/analisa/komposisi-siswa-agama`}
                component={KomposisiSiswaAgama}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/Soal/`}
                component={Soal}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/Soal/list-soal`}
                component={ListSoal}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/Soal/pengaturan-soal`}
                component={PengaturanSoal}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/Soal/tambah-soal`}
                component={TambahSoal}
              />
              <Route
                exact
                path={`${match.path}/kurikulum/Soal/buat-soal/generate-soal`}
                component={GenerateSoal}
              />

              <Route
                exact
                path={`${match.path}/perpustakaan`}
                component={Library}
              />
              <Route
                exact
                path={`${match.path}/perpustakaan/data-perpustakaan`}
                component={DataPerpus}
              />
              <Route
                exact
                path={`${match.path}/perpustakaan/data-perpustakaan/control-perpustakaan`}
                component={TableControlPerpus}
              />
              <Route
                exact
                path={`${match.path}/perpustakaan/data-perpustakaan/buat-daftar-unit-perpus`}
                component={BuatDaftarUnitPerpus}
              />
              <Route
                exact
                path={`${match.path}/perpustakaan/data-perpustakaan/buat-klasifikasi`}
                component={TableKlasifikasi}
              />
              <Route
                exact
                path={`${match.path}/perpustakaan/data-perpustakaan/buat-daftar-klasifikasi`}
                component={BuatKlasifikasi}
              />
              <Route
                exact
                path={`${match.path}/perpustakaan/data-perpustakaan/catat-buku-atau-catat-majalah`}
                component={CatatBuku}
              />
              <Route
                exact
                path={`${match.path}/perpustakaan/data-perpustakaan/catat-buku-atau-catat-majalah/data-buku-detail`}
                component={DataBuku}
              />
              <Route
                exact
                path={`${match.path}/perpustakaan/data-perpustakaan/upload`}
                component={UploadBuku}
              />
              <Route
                exact
                path={`${match.path}/perpustakaan/data-perpustakaan/buat-asal-buku-atau-majalah`}
                component={TableAsal}
              />
              <Route
                exact
                path={`${match.path}/perpustakaan/data-perpustakaan/buat-asal-buku-atau-majalah/buat-daftar`}
                component={BuatDaftarBuku}
              />
              <Route
                exact
                path={`${match.path}/perpustakaan/data-perpustakaan/top-10-analysis`}
                component={Top10Analysis}
              />
              <Route
                exact
                path={`${match.path}/perpustakaan/data-perpustakaan/top-10-analysis/detail`}
                component={Top10AnalysisDetail}
              />

              <Route
                exact
                path={`${match.path}/perpustakaan/data-perpustakaan/entry-data-pengunjung`}
                component={EntryData}
              />
              <Route
                exact
                path={`${match.path}/perpustakaan/data-perpustakaan/entry-data-pengunjung/individu`}
                component={EntryDataIndividu}
              />
              <Route
                exact
                path={`${match.path}/perpustakaan/data-perpustakaan/entry-data-pengunjung/perkelas`}
                component={EntryDataPerkelas}
              />
              <Route
                exact
                path={`${match.path}/perpustakaan/data-perpustakaan/laporan-pemasukan-uang`}
                component={LaporanPemasukanUang}
              />
              <Route
                exact
                path={`${match.path}/perpustakaan/data-perpustakaan/laporan-pemasukan-uang/cetak`}
                component={CetakLaporanPemasukanUang}
              />
              <Route
                exact
                path={`${match.path}/perpustakaan/data-perpustakaan/laporan-pengunjung`}
                component={LaporanPengunjung}
              />
              <Route
                exact
                path={`${match.path}/perpustakaan/data-perpustakaan/laporan-pengunjung/detail`}
                component={LaporanPengunjungDetail}
              />
              <Route
                exact
                path={`${match.path}/perpustakaan/data-perpustakaan/laporan-peminjaman`}
                component={LaporanPeminjam}
              />
              <Route
                exact
                path={`${match.path}/perpustakaan/data-perpustakaan/laporan-peminjaman/detail`}
                component={LaporanPeminjamDetail}
              />
              <Route
                exact
                path={`${match.path}/perpustakaan/data-perpustakaan/peminjaman-buku`}
                component={PeminjamanBuku}
              />
              <Route
                exact
                path={`${match.path}/perpustakaan/data-perpustakaan/pengembalian-buku`}
                component={PengembalianBuku}
              />
              <Route
                exact
                path={`${match.path}/perpustakaan/data-perpustakaan/search-buku`}
                component={SearchBuku}
              />
              <Route
                exact
                path={`${match.path}/perpustakaan/data-perpustakaan/search-buku/detail`}
                component={SearchBukuDetail}
              />
              <Route
                exact
                path={`${match.path}/perpustakaan/data-perpustakaan/grafik-analisis`}
                component={GrafikAnalisis}
              />
              <Route
                exact
                path={`${match.path}/perpustakaan/data-perpustakaan/grafik-analisis/detail`}
                component={GrafikAnalisisDetail}
              />

              <Route
                exact
                path={`${match.path}/report-logged-in`}
                component={ReportLoggedIn}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  onLogout: PropTypes.func,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  units: PropTypes.object,
  onSearchUnit: PropTypes.func,
  handleSetWorkingUnit: PropTypes.func,
};
Dashboard.defaultProps = {
  onLogout: noop,
  units: null,
  onSearchUnit: noop,
  handleSetWorkingUnit: noop,
};

function mapStateToProps(state) {
  return {
    user: state.user,
    units: state.options.unit,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onLogout: push => dispatch(handleLogout(push)),
    onSearchUnit: payload => dispatch(getUnitOptions(payload)),
    handleSetWorkingUnit: payload => dispatch(setWorkingUnit(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

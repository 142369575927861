import React from 'react';
import PropTypes from 'prop-types';
import { noop, map, isEqual, isEmpty } from 'lodash';
import { Button, Pagination } from '../../components/base';
import BaseTable from '../../components/BaseTable/BaseTable.component';
import ContentTable from '../../components/ContentTable/ContentTable.component';
import { formatData, normalizeAmount } from '../../utils/transformer.util';
import language from '../../languages';
import { Input, Select, SearchSelect } from '../../components/base/index';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
import { LIST_PENDAFTARAN_SISWA_LIST_TABLE_FIELDS } from '../../constants/Student/student.constant';


const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class ListPendaftaranSiswa extends BaseTable {
  constructor(props) {
    super(props);
    this.onClickAdd = this.onClickAdd.bind(this);
    this.onClickAddFolder = this.onClickAddFolder.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this._renderButtons = this._renderButtons.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onChangeListAmount = this._onChangeListAmount.bind(this);
    this._getStudentOptions = this._getStudentOptions.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);

    this.state = {
      page: 1,
      selected: {},
      content: initialContent,
      unchecked: {},
      filters:{},
      list:[],
      form: {
        value: {
          periods_id: '',
          levels: [],
          classrooms_id: '',
        },
        error: {
          classrooms: '',
          levels: '',
        },
      },
      param:{},
      filtered: false,
    };
  }

  componentDidMount() {
    const { content } = this.state;
    const paramTypes = ['classrooms','levels', 'classrooms'];
    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
    if (isEmpty(content.list)) {
      this._onSearchContent({ page: 1 });
    }
    this._getStudentOptions();
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    const { workingUnit } = user;
    const { user: prevUser } = prevProps;
    const { workingUnit: prevWorkingUnit } = prevUser;

    if (!isEqual(workingUnit, prevWorkingUnit)) {
      this._onSearchContent({ page: 1 });
    }
  }
  async onClickDownload(id) {
    const { handleDownloadAnnouncement, user} = this.props;
    const res = await handleDownloadAnnouncement(id.id, id.upload)
  }
  async _onSearchContent(params = {}) {
    const { filters } = this.state;
    this.setState({
      content: initialContent,
    }, async () => {
      try {
        const { handleListManageRegis, user } = this.props;
        const { units_id, workingUnit, id, user_group, organizations_id } = user;
        const {
          result = [], currentPage = 1, total = 0, totalPage = 1,
        } = await handleListManageRegis({ ...params, filters, organizations_id });
        // if (this.isMount) {
          this.setState({
            content: {
              loading: false,
              list: result,
              currentPage,
              total,
              totalPage,
            },
          });
        // }
      } catch (err) {
        // if (this.isMount) {
          this.setState({
            content: {
              loading: false,
              list: [],
              currentPage: 1,
              total: 1,
              totalPage: 1,
              error: true,
            },
          });
        }
      // }
    });
  }

  _onFormChange(event) {
    const { user } = this.props;
    const { organizations_id } = user;
    const {
      name,
      value,
      dataset,
      checked,
      type,
    } = event.target;
    const {
      inputType = 'text', inputArray = false, arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        if (type === 'checkbox') {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
        if (name === 'code_of_account') {
          newList[arrayPosition].isCredit = value.type;
        }
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = '';
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray
              ? { [fieldName]: newListError }
              : { [name]: '' }),
          },
        },
      };
    }, () => {
      if (name === 'levels_id') {
        this._getParamOptionsAcademics( 'classrooms', {levels_id: value, organizations_id: organizations_id});
      }
      // if (name === 'classes_id') {
      //   this._getParamOptionsAcademics( 'classrooms', {classes_id: value, organizations_id: 4 });
      // }
      if (name === 'classrooms_id' && !inputArray ) {
        this._setClassrooms(value)
      }
      if (name === 'levels_id') {
        this._getClassesOptions({ filters: { levels_id: [value] }} );
      }
    });
  }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    this.setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        [name]: value,
      },
    }), () => this._onSearchContent());
  }

  _onChangeListAmount(event) {
    const { keywords } = this.state;
    this.setState({
      listAmount: Number(event.target.value),
    });
    this._onSearchContent({ limit: event.target.value, keywords, page: 1 });
  }


  async _getParamOptionsAcademics(type, filters={}) {
    const { getParamOptionsAcademics, user } = this.props;
    const {organizations_id} = user;
    if (type === 'classrooms' || type === 'classrooms') {
      filters.organizations_id = organizations_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }
  
  async _getStudentOptions(filters={}) {
    const { getStudentOptions } = this.props;
    const res = await getStudentOptions(filters);
    // console.log(res)
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        student: res,
      },
    }));
    
  }

  onClickAdd() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/pendaftaran', { isEdit: true });
  }

  onClickAddFolder() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/tambah-folder', { isEdit: true });
  }

  onClickEdit(val) {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/pendaftaran', { data: val, isEdit: true });
  }
  async onClickDelete(id) {
    const { handleDeleteAnnouncement } = this.props;
    const res = await handleDeleteAnnouncement(id);
    if(res){
      this.handleGetData();
    }
  }

  onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this._onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  _renderButtons(data) {
    const { user } = this.props;
    const {user_group} = user;
    const button = {};
    
    return (
      <td>
        <div className="table__actions">
        
          {data.upload ? <Button
            onClick={() => this.onClickDownload(data)}
            title='Download'
          /> : <div></div>}
          {/* {user_group.id === 2? */}
             <Button
             onClick={() => this.onClickEdit(data)}
             title='Edit'
           />
           {/* :'' */}
          {/* } */}
            {/* {user_group.id === 2? */}
          
           {/* <Button
             onClick={() => this.onClickDelete(data)}
             title='Delete'
           /> */}
           {/* :'' */}
          {/* } */}
         
        </div>
      </td>
    );
  }

  render() {
    const { list = {}, content, param, filters, form } = this.state;
    const { handleGetFundRequest, handleDeleteFundRequest, user } = this.props;
    const { user_group } = user;
    const { permissions } = user_group;
    const { value } = form;

    const {
      students_id = '',
      entrance_year = '',
    } = filters;
    const entrance = [
      // { label: '2017', value: '2017'},
      // { label: '2018', value: '2018'},
      // { label: '2019', value: '2019'},
      // { label: '2020', value: '2020'},
      { label: '2021', value: '2021'},
      { label: '2022', value: '2022'}
    ]
    const dataset = {
      toggle: "modal"
    };
    return (
      <div className="fund-request">
        
        <div className="beginning-balance__button-wrapper">
         <Button
          title="Buat Pendaftaran"
          onClick={this.onClickAdd}
          data={dataset}
        />
         </div>
         <br></br>
         <div className="isi-bidang__custom-form">
      <div className="isi-bidang__custom-form-column">
        <div className="isi-bidang__custom-form-row__field">
        <Select
            name="entrance_year"
            label="Tahun Masuk"
            placeholder={'Pilihan'}
            onChange={this._onChangeFilter}
            data={ entrance}
            value={entrance_year}
          />
          
          <div className="isi-bidang__custom-form-row__field"></div>
          <SearchSelect
              noMargin
              async={false}
              name="students_id"
              list={param.student}
              inputArray
              onClick={this._onChangeFilter}
              placeholder="Pilih Nama Siswa"
              value={filters.students_id}
              // labelName="label"
              // valueName="value"
              rightIcon="icon-search"
              label= "Nama Siswa"
              />
         </div>
       
           
      </div>
    </div>
        <div className="fund-request__content">
          <table className="table">
            <thead>
              <tr>
                {
                    map(LIST_PENDAFTARAN_SISWA_LIST_TABLE_FIELDS.label, (field, idx) => (
                      <th key={`table_th_${idx}`}>{field}</th>
                   ))
                  }
              </tr>
            </thead>
            <tbody>
              {
                   map(content.list, (data, idx) => (
                    <tr key={`budget_row_${idx}`}>
                      {
                      map(LIST_PENDAFTARAN_SISWA_LIST_TABLE_FIELDS.value, (field, fieldIdx) => {
                        if (fieldIdx === 0) {
                          return (
                            <td key={`table_index_${fieldIdx}_${idx}`}>{idx + 1}</td>
                          );
                        }
                        if (field.type === 'link') {
                          return (
                            <td key={`table_${fieldIdx}_${idx}`}><a href={"https://"+data.hyperlink} target="_blank" rel="noopener noreferrer">{data.hyperlink}</a></td>
                              // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                          );
                        }
                        return (
                          <td className={(field.type === 'number') ? 'nominal' : ''} key={`table_${fieldIdx}_${idx}`}>
                            {formatData(data, field)}
                          </td>
                        );
                      })
                      }
                      {this._renderButtons(data)}
                    </tr>
                  ))
                }
            </tbody>
          </table>
         
         
        </div>
        <div className="user-management__table-footer">
          <p className="user-management__page-info">
            {`${language.translate.COMPONENT_CONTENT_TABLEPAGE} ${content.currentPage} ${language.translate.COMPONENTCONTENT_TABLE_OF} ${content.totalPage}`}
          </p>
          <Pagination
            totalPage={content.totalPage}
            currentPage={content.currentPage}
            onClick={this.onChangePage}
          />
        </div>
      </div>
    );
  }
}
ListPendaftaranSiswa.propTypes = {
  handleListAnnouncement: PropTypes.func,
  handleListAnnouncementStudent: PropTypes.func,
  handleDelete: PropTypes.func,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
};
ListPendaftaranSiswa.defaultProps = {
  handleListAnnouncement: noop,
  handleListAnnouncementStudent:PropTypes.func,
  handleDelete: noop,
  user: null,
};
import {
  filter,
  find,
  isEmpty,
  keyBy, map,
  mapValues,
  noop
} from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import swal from 'sweetalert';
import { Button, Select, Textarea } from '../../../../components/base';
import language from '../../../../languages';
import { printJournal } from '../../../../utils/print.util';
import { commaFormatted, datetimeFormat, normalizeAmount } from '../../../../utils/transformer.util';
import BudgetRapbu from './components/BudgetRapbu.component';
import DashboardPrint from './components/DashboardPrint.component';

export default class ManageBudget extends Component {
  constructor(props) {
    super(props);
    this.onSetForm = this.onSetForm.bind(this);
    this.calculateTotalPlafond = this.calculateTotalPlafond.bind(this);
    this.onChangeRecommendation = this.onChangeRecommendation.bind(this);
    this.onSaveRecommendations = this.onSaveRecommendations.bind(this);
    this.onSubmitApproval = this.onSubmitApproval.bind(this);
    this.onReject = this.onReject.bind(this);
    this.onSelectBudgetDetail = this.onSelectBudgetDetail.bind(this);
    this.onSelectDivisiDetail = this.onSelectDivisiDetail.bind(this);
    this.setRemarks = this.setRemarks.bind(this);
    this.onClickDivisi = this.onClickDivisi.bind(this);
    this.renderPlafondRows = this.renderPlafondRows.bind(this);
    this.confirmReject = this.confirmReject.bind(this);
    this.hasRecommendations = this.hasRecommendations.bind(this);

    this.remarks = React.createRef();
    this.renderWorkflowHistory = this.renderWorkflowHistory.bind(this);
    this._onChangeDivisi = this._onChangeDivisi.bind(this);
    this.onFormChange = this.onFormChange.bind(this);


    this.state = {
      budget: {
        plafon: [],
        school_unit: {
          school_unit: {
            name: '',
          },
        },
      },
      value: {},
      rapbuSummary: {
        semesterOne: [],
        semesterTwo: [],
      },
      recommendations: {},
      selectedBudgetDetails: [],
      workflow: {},
      total_pendapatan: '',
      total_pengeluaran: '',
      total_estimasi: '',
      total_saldo: '',
      remarks: '',
      unique_id: '',
      head_unique_id: '',
      selectedDivisi: null,
    };
  }

  componentDidMount() {
    this.onSetForm();
  }

  onClickDivisi(event) {
    const { history } = this.props;
    const { form } = this.state;
    const { target } = event;
    const { dataset = {} } = target;

    history.push(`/dashboard/keuangan/penganggaran/rapbs/${dataset.type}`, {
      ...form.value,
      type: dataset.type,
    });
  }

  async onSetForm(keyword = null) {
    const {
      handleGetBudgetDetail, handleGetDivisiOptions, handleGetPlafondOptions, location,
    } = this.props;
    const { account_unique_id, head_unique_id, value } = location.state;
    const plafond = await handleGetPlafondOptions();
    const res = await handleGetBudgetDetail({ account_unique_id, head_unique_id, type: 'RAPBU' });

    const divisi = await handleGetDivisiOptions({
      keyword,
      label: (value === 5) ? 'keuangan' : 'manajerial',
    });
    const availablePlafonds = filter(plafond, (data) => {
      const exists = find(res.budget.plafon, plafon => plafon.plafond_id === data.value);
      return typeof exists !== 'undefined';
    });

    const totalPlafond = mapValues(keyBy(res.budget.plafon, 'plafond_id'), 'total');
    const countable = {};
    map(res.budget.plafon, 'plafond_id').forEach((plafondId) => {
      countable[plafondId] = {
        semesterOne: {},
        semesterTwo: {},
      };
    });

    let totalalokasiAllSemester = 0;
    let totalalokasiAllSemester2 = 0;
    let totalalokasiAllSemester3 = 0;
    let totalalokasiAllSemester4 = 0;

    const arrpengeluaran = [];
    const MyObject = res.ganjil.pengeluaran;
    Object.keys(MyObject).forEach(key => arrpengeluaran.push({ name: key, value: MyObject[key] }));
    let totalAlokegiatan = 0;
    let totalAlorutin = 0;
    let totalAloBpp = 0;
    let totalAloDanaP = 0;
    arrpengeluaran.forEach((item) => {
      const { data } = item.value;
      data.forEach((o) => {
        const { allocations } = o;
        Object.keys(allocations).forEach((key) => {
          const nominal = parseInt(allocations[key].nominal, 10);
          if (key === '1') {
            totalAlorutin += nominal;
          }
          if (key === '2') {
            totalAlokegiatan += nominal;
          }
          if (key === '3') {
            totalAloDanaP += nominal;
          }
          if (key === '4') {
            totalAloBpp += nominal;
          }
        });
      });
    });

    const arrpengeluarangenap = [];
    const MyObject4 = res.genap.pengeluaran;
    Object.keys(MyObject4).forEach(key => arrpengeluarangenap.push({
      name: key, value: MyObject4[key],
    }));
    let totalAloganjilpengeluarankegiatan = 0;
    let totalAloganjilpengeluaranrutin = 0;
    let totalAloganjilpengeluaranBpp = 0;
    let totalAloganjilpengeluaranDanaP = 0;

    arrpengeluarangenap.forEach((item) => {
      const { data } = item.value;
      data.forEach((o) => {
        const { allocations } = o;
        allocations.forEach((alloc) => {
          const { plafond_id, nominal } = alloc;
          const nominalInt = parseInt(nominal, 10);
          if (plafond_id === '1') {
            totalAloganjilpengeluaranrutin += nominalInt;
          }
          if (plafond_id === '2') {
            totalAloganjilpengeluarankegiatan += nominalInt;
          }
          if (plafond_id === '3') {
            totalAloganjilpengeluaranDanaP += nominalInt;
          }
          if (plafond_id === '4') {
            totalAloganjilpengeluaranBpp += nominalInt;
          }
        });
      });
    });

    totalalokasiAllSemester = totalAloganjilpengeluaranrutin + totalAlorutin;
    totalalokasiAllSemester2 = totalAloganjilpengeluarankegiatan + totalAlokegiatan;
    totalalokasiAllSemester3 = totalAloganjilpengeluaranDanaP + totalAloDanaP;
    totalalokasiAllSemester4 = totalAloganjilpengeluaranBpp + totalAloBpp;

    localStorage.setItem('TotalItem', totalalokasiAllSemester);
    localStorage.setItem('TotalItem2', totalalokasiAllSemester2);
    localStorage.setItem('TotalItem3', totalalokasiAllSemester3);
    localStorage.setItem('TotalItem4', totalalokasiAllSemester4);


    this.setState({
      form: {
        value: {
          semesterOne: res.ganjil || [],
          semesterTwo: res.genap || [],
        },
        error: {
          semesterOne: [],
          semesterTwo: [],
        },
      },

      budget: res.budget,
      rapbuSummary: {
        semesterOne: res.ganjil || [],
        semesterTwo: res.genap || [],
      },
      pengeluaranganjil: res.ganjil.pengeluaran || [],
      pengeluarangenap: res.genap.pengeluaran || [],


      recommendations: res.recommendations || {},
      workflow: res.workflow || {},
      total_pendapatan: res.total_pendapatan,
      total_pengeluaran: res.total_pengeluaran,
      total_estimasi: res.total_estimasi,
      total_saldo: res.total_saldo,
      selectedDivisi: parseInt(divisi[0].value, 10),
      availablePlafonds: availablePlafonds || [],
      totalPlafond: totalPlafond || {},
    }, () => {
      this.calculateTotalPlafond();
    });
  }

  onFormChange(event, formName) {
    const {
      name,
      value,
      dataset,
    } = event.target;
    const {
      inputArray = false, arrayPosition = 0, countable = false, inputType, plafondId,
    } = dataset;
    const { selectedDivisi } = this.state;

    this.setState((prevState) => {
      let formattedValue = value;
      let newSemester = [];
      let newSemesterError = [];
      const countableData = { ...prevState.countable };

      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        newSemester = prevState.form.value[formName];
        newSemesterError = prevState.form.error[formName];
        newSemester[arrayPosition][name] = formattedValue;
        newSemester[arrayPosition].divisi_id = selectedDivisi;
        if (!isEmpty(newSemesterError[arrayPosition])) {
          newSemesterError[arrayPosition][name] = '';
        }
        if (plafondId) {
          if (!newSemester[arrayPosition].allocations) {
            newSemester[arrayPosition].allocations = {};
          }
          newSemester[arrayPosition].allocations[plafondId] = formattedValue;
        }
      }

      const error = {
        ...prevState.form.error,
        ...(inputArray
          ? { [formName]: newSemesterError }
          : { [name]: '' }),
      };
      error.semesterOne = filter(error.semesterOne, (err) => {
        if (err) {
          return err.length;
        }
        return {};
      });
      error.semesterTwo = filter(error.semesterTwo, (err) => {
        if (err) {
          return err.length;
        }
        return {};
      });

      if (countable) {
        if (!countableData[plafondId]) {
          countableData[plafondId] = {
            semesterOne: {},
            semesterTwo: {},
          };
        }
        countableData[plafondId][formName][arrayPosition] = formattedValue;
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [formName]: newSemester }
              : { [name]: formattedValue }),
          },
          error,
        },
        countable: countableData,
      };
    }, () => {
    });
  }

  onChangeRecommendation(event) {
    const { target } = event;
    const { dataset, value, name } = target;
    const { referenceid } = dataset;
    const { recommendations } = this.state;

    if (recommendations[name] === null) {
      recommendations[name] = {};
    }

    recommendations[name][referenceid] = normalizeAmount(value);

    this.setState(prevState => ({
      ...prevState,
      recommendations: {
        ...recommendations,
      },
    }));
  }

  async onSubmitApproval() {
    const { recommendations, selectedBudgetDetails } = this.state;
    const { handleSubmitApproval, location, history } = this.props;
    const { head_unique_id } = location.state;

    await handleSubmitApproval({
      head: head_unique_id,
      recommendations,
      selectedBudgetDetails,
    });

    history.push('/dashboard/keuangan/penganggaran/anggaran');
  }

  onSaveRecommendations() {
    const { recommendations } = this.state;
    const { handleSaveRecommendations, history } = this.props;

    handleSaveRecommendations({
      recommendations,
    }, history.goBack);
  }

  async onReject(remarks) {
    const { recommendations, selectedBudgetDetails } = this.state;
    const { handleReject, history, location } = this.props;
    const { head_unique_id } = location.state;
    if (remarks === '') {
      alert('Keterangan Reject Wajib Diisi');
    } else {
      await handleReject({
        head: head_unique_id,
        recommendations,
        selectedBudgetDetails,
        remarks,
      });

      history.push('/dashboard/keuangan/penganggaran/anggaran');
    }
  }

  onSelectBudgetDetail(event) {
    const { target } = event;
    const { value, checked } = target;
    const { selectedBudgetDetails } = this.state;
    const index = selectedBudgetDetails.indexOf(value);
    const newArray = selectedBudgetDetails.slice(0, selectedBudgetDetails.length);

    if (index !== -1) {
      if (checked === false) {
        newArray.splice(index, 1);
      }
    } else if (checked) {
      newArray.push(value);
    }

    this.setState(prevState => ({
      ...prevState,
      selectedBudgetDetails: newArray,
    }));
  }

  onSelectDivisiDetail() {
    const { handleSelectDivisiDetail, history } = this.props;

    handleSelectDivisiDetail({
    }, history.goBack);
  }

  setRemarks(event) {
    const { value } = event.target;

    this.setState(prevState => ({
      ...prevState,
      remarks: value,
    }));
  }

  confirmReject() {
    const el = this.remarks.current.input;
    el.classList.remove('hidden');
    return swal({
      title: language.translate.ALERT__ARE_YOU_SURE,
      text: 'Masukkan remarks untuk menolak RAPBS',
      content: el,
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((reject) => {
      if (reject) {
        this.onReject(el.value);
      }
    });
  }

  hasRecommendations() {
    const { recommendations } = this.state;

    return Object.keys(recommendations).some((key) => {
      const items = recommendations[key];
      if (items !== null && Object.keys(items).length > 0) {
        return Object.keys(items).some((referenceid) => {
          const value = items[referenceid];
          return value !== null && value > 0;
        });
      }
      return false;
    });
  }

  _onChangeDivisi(event) {
    const { target } = event;
    const { value } = target;

    this.setState(prevState => ({
      ...prevState,
      selectedDivisi: parseInt(value, 10),
    }));
  }

  calculateTotalPlafond() {
    const { form, totalPlafond = {} } = this.state;
    const { semesterOne = {} } = form.value;
    const { pengeluaran = {} } = semesterOne

    Object.keys(pengeluaran).forEach((key) => {
      const { data = [] } = pengeluaran[key]
      data.forEach((item) => {
        const { allocations = {} } = item ;
        Object.keys(allocations).forEach((allocation_id) => {
          const { plafond_id, nominal } = allocations[allocation_id];
          totalPlafond[plafond_id] = totalPlafond[plafond_id] ? totalPlafond[plafond_id] + parseInt(nominal, 10) : parseInt(nominal, 10);
        });
      })
    });

    this.setState(prevState => ({
      ...prevState,
      totalPlafond,
    }));
  }

  renderPlafondRows() {
    const {
      budget,
      availablePlafonds = [],
      totalPlafond = {},
    } = this.state;

    const rows = [];

    if (budget.plafon.length > 0) {
      const { plafon: budgetPlafond } = budget;
      availablePlafonds.forEach((plafond) => {
        const currentPlafond = budgetPlafond.find(o => o.plafond_id === plafond.value);
        const total = totalPlafond[plafond.value];
        const { nominal } = currentPlafond;
        const percent = (total / nominal) * 100;
        rows.push((
          <tr key={`plafond_${plafond.value}`}>
            <td>
              {plafond.label}
            </td>
            <td className="nominal">
              {commaFormatted(total)}
            </td>
            <td
              className={`nominal ${total > nominal ? 'red' : ''}`}
            >
              {commaFormatted(nominal)}
            </td>
            <td
              className={`nominal ${percent > 100 ? 'red' : ''}`}
            >
              {commaFormatted(percent)} %
            </td>
          </tr>
        ));
      });
    }

    return rows;
  }

  renderButton() {
    const { user } = this.props;
    const { user_groups_id, user_group } = user;
    const { permissions = [] } = user_group;
    const { workflow } = this.state;
    const lastWorkflow = workflow[workflow.length - 1] || {};
    const { is_done = false } = lastWorkflow;

    const submitButton = (
      <div key="button_submit">
        { permissions.indexOf('submit_budget') >= 0 || permissions.indexOf('approve_budget') >= 0

          ? (
            <Button
              type="button"
              onClick={this.onSubmitApproval}
              title={permissions.indexOf('approve_budget') < 0 ? 'Submit' : 'Approve'}
            />
          )
          : ''}
      </div>
    );

    const saveButton = (
      <div key="button_save">
        <Button
          type="button"
          onClick={this.onSaveRecommendations}
          title="Save"
        />
      </div>
    );
    const rejectButton = (
      <div key="button_reject">
        <Button
          type="button"
          onClick={this.confirmReject}
          title="Reject"
        />
      </div>
    );

    const buttons = [];

    if (lastWorkflow.hasOwnProperty('is_done')) {
      if (is_done) {
        return (
          <div>
            <i>RAPBS telah disetujui</i>
          </div>
        );
      }
      if (permissions.indexOf('submit_budget') >= 0) {
        buttons.push(submitButton);
      }
      if (permissions.indexOf('reject_budget') >= 0) {
        buttons.push(rejectButton);
      }

      if (lastWorkflow.next_role === user_group.name || user_group.name === 'Keuangan Pusat') {
        switch (user_group.name) {
          case 'Keuangan Sekolah':
            buttons.push(submitButton, rejectButton);
            break;
          case 'Bendahara Perwakilan':
            buttons.push(submitButton);
            break;
          case 'Bendahara Pusat':
            buttons.push(submitButton);
            break;
          case 'Kepala Sekolah':
            buttons.push(submitButton);
            break;
          case 'Korektor Perwakilan':
            buttons.push(submitButton, rejectButton);
            break;
          case 'Korektor Pusat':
            buttons.push(saveButton, submitButton, rejectButton);
            break;
          case 'Accounting Pusat':
            buttons.push(saveButton, submitButton, rejectButton);
            break;
          case 'Manager Keuangan':
            buttons.push(saveButton, submitButton, rejectButton);
            break;
          case 'Bendahara':
            buttons.push(submitButton, rejectButton);
            break;
          case 'Keuangan Pusat':
            if (lastWorkflow.action !== 'reject') {
              buttons.push(submitButton, rejectButton);
            } else {
              return (
                <div>
                  <i>RAPBS telah disubmit ke {lastWorkflow.next_role}</i>
                </div>
              );
            }
            break;
          default:
            break;
        }

        if (user_groups_id) {
          return buttons;
        }
      } else {
        return (
          <div>
            <i>RAPBS telah disubmit ke {lastWorkflow.next_role}</i>
          </div>
        );
      }
    }

    if (user_group.name === 'Keuangan Sekolah' || user_group.name === 'Kepala Sekolah' || user_group.name === 'Keuangan Pusat') {
      buttons.push(submitButton);
      return buttons;
    }

    return false;
  }

  renderWorkflowHistory() {
    const { workflow = [] } = this.state;
    const rows = [];

    if (workflow.length > 0) {
      workflow.forEach((flow, idx) => {
        const {
          prev_role, next_role, action, updated_at, remarks, is_done,
        } = flow;
        const roleMap = {
          'Keuangan Sekolah': 'Kepala Sekolah',
        };

        const flowAction = (action === 'reject')
          ? 'mengembalikan ke' : ' mengajukan ke';
        const nextRole = roleMap[next_role] ? roleMap[next_role] : next_role;
        const prevRole = roleMap[prev_role] ? roleMap[prev_role] : prev_role;
        let flowValue = `${prevRole} ${flowAction} ${nextRole}`;

        if (is_done) {
          flowValue = 'RAPBS telah disetujui oleh Bagian Keuangan';
        }
        rows.push([
          <tr key={`workflow_history_item_${idx}`}>
            <td>{parseInt(idx, 10) + 1}</td>
            <td>{flowValue}</td>
            <td>{remarks}</td>
            <td>{datetimeFormat(updated_at)}</td>
          </tr>,
        ]);
      });

      return rows;
    }

    return false;
  }

  render() {
    const {
      rapbuSummary, recommendations, workflow, remarks, selectedDivisi, total_pendapatan,
      total_pengeluaran, total_estimasi, total_saldo, budget,
    } = this.state;
    const {
      location, user, divisi = {}, history,
    } = this.props;
    const { type, code } = location.state;
    const {
      periode, desc, transaction_number, created_at, school_unit,
    } = budget;
    const unit = school_unit.school_unit.name;

    let divisiValue = selectedDivisi || '';

    if (divisiValue === null && Object.keys(divisi).length > 0) {
      divisiValue = divisi.list[0].value;
    }

    return (
      <div className="manage-budget">
        <div className="table_title">
          <Textarea
            onChange={this.setRemarks}
            ref={this.remarks}
            classNames="hidden"
            value={remarks}
          />
          <h1>
            {language.translate.FINANCE__BUDGETING__MANAGE_BUDGET__TITLE}&nbsp;
            {language.translate[`FINANCE__BUDGETING__MANAGE_BUDGET__${type}`]}
          </h1>
          <br />
          <h2>
            Periode {periode}
          </h2>
        </div>
        <table>
          <tbody>
            <tr>
              <td valign="top" style={{ width: '350px', fontSize: '15px' }}>
                <table className="table0" style={{ width: '100%', marginTop: '5px', marginBottom: '10px' }}>
                  <tbody>
                    <tr>
                      <td width="100px">Transaksi</td>
                      <td>:</td>
                      <td>{transaction_number}</td>
                    </tr>
                    <tr>
                      <td width="100px">Tanggal</td>
                      <td>:</td>
                      <td>{created_at}</td>
                    </tr>
                    <tr>
                      <td width="100px">Unit</td>
                      <td>:</td>
                      <td>{unit}</td>
                    </tr>
                    <tr>
                      <td width="100px">Judul</td>
                      <td>:</td>
                      <td>{desc}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        { type === 'RAPBU'
          && (
          <table className="table table5">
            <thead>
              <tr>
                <th className="manage-budget__row" rowSpan={2}>Biaya</th>
                <th rowSpan={1}>Total</th>
                <th rowSpan={1}>Plafon</th>
                <th rowSpan={1}>%</th>
              </tr>
            </thead>
            <tbody>
              {this.renderPlafondRows()}
            </tbody>
          </table>
          )
        }
        <br />

        <br />

        {/* {console.log(this.props)} */}
        <DashboardPrint
          location={location}
          budget={budget}
          code={code}
          user={user}
          history={history}
        />

        <br />
        <br />

        {

          type === 'RAPBU'
            ? (
              <Select
                data={divisi.list}
                value={divisiValue}
                onChange={this._onChangeDivisi}
              />
            )
            : (<h1>Keuangan</h1>)

        }
        <div className="table table6">
          <BudgetRapbu
            code={code}
            rapbuSummary={rapbuSummary}
            recommendations={recommendations}
            onChangeRecommendation={this.onChangeRecommendation}
            onSelectBudgetDetail={this.onSelectBudgetDetail}
            onSelectDivisiDetail={this.onSelectDivisiDetail}
            user={user}
            workflow={workflow}
            total_pendapatan={total_pendapatan}
            total_pengeluaran={total_pengeluaran}
            total_estimasi={total_estimasi}
            total_saldo={total_saldo}
            selectedDivisi={selectedDivisi}
          />
        </div>

        { workflow && (
          <>
            <h2>Workflow</h2>
            <table>
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Flow</th>
                  <th>Catatan</th>
                  <th>Tanggal</th>
                </tr>
              </thead>
              <tbody>
                {this.renderWorkflowHistory()}
              </tbody>
            </table>
          </>
        )}

        {workflow.remarks
        && (
        <table className="workflow_remarks">
          <div className="workflow_remarks">
            <p><b>Catatan {workflow.prev_role}</b>: {workflow.remarks}</p>
          </div>
        </table>
        )
        }

        <div id="buttons">
          {this.renderButton()}
        </div>

      </div>
    );
  }
}
ManageBudget.propTypes = {
  handleGetBudgetDetail: PropTypes.func,
  handleSaveRecommendations: PropTypes.func,
  handleSelectDivisiDetail: PropTypes.func,
  handleSubmitApproval: PropTypes.func,
  handleGetPlafondOptions: PropTypes.func,
  handleReject: PropTypes.func,
  location: PropTypes.object.isRequired,
  user: PropTypes.object,
  history: PropTypes.object,
  divisi: PropTypes.object,
};
ManageBudget.defaultProps = {
  handleGetBudgetDetail: noop,
  handleSaveRecommendations: noop,
  handleSelectDivisiDetail: noop,
  handleGetPlafondOptions: noop,
  handleSubmitApproval: noop,
  handleReject: noop,
  user: null,
  history: null,
  divisi: {},
};

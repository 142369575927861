import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';
import './assets/scss/main.scss';
import App from './routes/App.route';
import initStore from './states/store';
import { initializeHTTPInterceptors } from './utils/axios.util';

import * as serviceWorker from './serviceWorker';

const store = initStore();
initializeHTTPInterceptors(store);

ReactDOM.render((
  <Provider store={store}>
    <Router>
      <Route path="/" name="App" component={App} />
    </Router>
  </Provider>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

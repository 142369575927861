import { connect } from 'react-redux';
import ManageBudgetRapbuRekap from '../../../../views/Finance/Budgeting/Budget/ManageBudgetRapbuRekap.finance.view';
import { handleGetBudgetRapbuRekapDetail } from '../../../../states/thunks/finance.thunk';
import { getTemaFilterOptions } from '../../../../states/thunks/options.thunk';


function mapStateToProps(state) {
  return {
    user: state.user,
    tema: state.options.tema,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetBudgetRapbuRekapDetail: payload => dispatch(handleGetBudgetRapbuRekapDetail(payload)),
    handleGetTemaOptions: payload => dispatch(getTemaFilterOptions(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageBudgetRapbuRekap);

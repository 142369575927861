import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop, map } from 'lodash';
import StudentReportFilter from '../../../components/Filter/StudentReport.filter.component';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { ABSENSI_HARIAN } from '../../../constants/student.constant';
import { ABSENSI_HARIAN_TABLE } from '../../../constants/student.constant';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted, normalizeAmount } from '../../../utils/transformer.util';
import { Button, Input, Select } from '../../../components/base/index';
import { ANALISA_DISPLINER_FORM_FIELDS} from '../../../constants/Student/displiner.constant';
import MatpelGuruTable from './components/MatpelGuruTable.component';
import EkskulMatpel from './components/EkskulMatpel.component';

export default class TambahDaftarGuru extends Component {
    constructor(props) {
        super(props);
        this._onClickAdd = this._onClickAdd.bind(this);
        this._onDeleteList = this._onDeleteList.bind(this);
        this._onClickAdd2 = this._onClickAdd2.bind(this);
        this._onDeleteList2 = this._onDeleteList2.bind(this);
        this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this._setForm = this._setForm.bind(this);
        this._getParamOptions = this._getParamOptions.bind(this);
        this._getSubjectOptions= this._getSubjectOptions.bind(this);
        this._getEkskulOptions= this._getEkskulOptions.bind(this);
        this.onEdit = this.onEdit.bind(this);

      
        const { user } = this.props;
        const { organizations_id, school_unit } = user;
        const { levels_id , units_id} = school_unit;
        this.state = {
            form: {
                value: {
                  levels_id,
                  units_id,
                  organizations_id,
                  matpel: [
                    {

                      subject_id: '',
                      classrooms_id:'',
                    }                                      
                  ],
                  ekskul: [
                    {
                      ekskul_id: '',
                      classrooms_id:'',
                    }                                      
                  ],
                },
                error: {
                  matpel: '',
                  ekskul: '',
        
                },
              },
              param:{},
        }
      }

      componentDidMount(){
        const { location, getLevels, getclassrooms, user } = this.props;
        const { organizations_id, school_unit } = user;
        const { levels_id, units_id } = school_unit;
        const { state = {} } = location;
        const {isEdit =  false, data} = location.state;
        const { id } = state.data || '';
          const { handleGetclassrooms } = this.props;
          const paramTypes = ['classes','levels', 'classrooms'];
          if (isEdit && id) {
            this.setState({id:id})
            this._setForm(data.id);
          }
          paramTypes.forEach((type) => {
            this._getParamOptionsAcademics(type);
          });
          this._getSubjectOptions({ filters: { units_id: [units_id] }});
          this._getEkskulOptions();
      }
    
      async _getParamOptionsAcademics(type, filters = {}) {
        const { getParamOptionsAcademics, user } = this.props;
        const { organizations_id, school_unit } = user;
        const { levels_id } = school_unit;
        if (type === 'classes' || type === 'classrooms') {
          filters.organizations_id = organizations_id;
          filters.levels_id = levels_id;
        } else {
          delete filters.organizations_id;
        }
        const res = await getParamOptionsAcademics(filters, type);
        this.setState(prevState => ({
          ...prevState,
          param: {
            ...prevState.param,
            [type]: res,
          },
        }));
      }
      async _getSubjectOptions(filters={}) {
        const { getSubjectOptions, user } = this.props;
        const { organizations_id, school_unit } = user;
        const { levels_id, units_id } = school_unit;
        const res = await getSubjectOptions(filters);
        filters.units_id = units_id;
        // console.log(res)
        this.setState(prevState => ({
          ...prevState,
          param: {
            ...prevState.param,
            subject: res,
          },
        }));
       
      }

      async _getEkskulOptions() {
        const { getEkskulOptions } = this.props;
        const res = await getEkskulOptions();
        // console.log(res)
        this.setState(prevState => ({
          ...prevState,
          param: {
            ...prevState.param,
            ekskul: res,
          },
        }));
       
      }
      
      async _setForm(id) {
        const { handleGetListTeacher } = this.props;
        try {
          const payload = await handleGetListTeacher({ id });
         
          this.setState({
            form: {
              value: {
                id: payload.id,
                employee_id:payload.employee_id,
                full_name: payload.full_name,
                homeroom_teache_classrooms_id: payload.homeroom_teache_classrooms_id,
               
                matpel: map(payload.matpel, detail => ({
                  classrooms_id: detail.classrooms_id,
                  subject_id: detail.subject_id,
                })),
                ekskul: map(payload.ekskul, detail => ({
                  classrooms_id: detail.classrooms_id,
                  ekskul_id: detail.ekskul_id,
                })),
              },
              error: {
                matpel: [],
                ekskul: [],

              },
            },
          });
        } catch (err) {
          // err action
        }
      }

      _onClickAdd(data) {
        const { form } = this.state;
        const { matpel } = form.value;
        
        matpel.push({
          subject_id: '',
          classrooms_id:'',
        });
        
        this.setState(prevState => ({
          form: {
            value: {
              ...prevState.form.value,
              matpel,
            },
            error: {
              ...prevState.form.error,
            }
          }
        }));
      }

      _onClickAdd2(data) {
        const { form } = this.state;
        const { ekskul } = form.value;
        
        ekskul.push({
          ekskul_id: '',
          classrooms_id:'',
        });
        
        this.setState(prevState => ({
          form: {
            value: {
              ...prevState.form.value,
              ekskul,
            },
            error: {
              ...prevState.form.error,
            }
          }
        }));
      }
    
      _onDeleteList(idx) {
        this.setState((prevState) => {
          const { matpel } = prevState.form.value;
          const list = prevState.form.value.matpel;
    
          list.splice(idx, 1);
          return {
            ...prevState,
            matpel: list
          };
        });
      }

      _onDeleteList2(idx) {
        this.setState((prevState) => {
          const { ekskul } = prevState.form.value;
          const list = prevState.form.value.ekskul;
    
          list.splice(idx, 1);
          return {
            ...prevState,
            ekskul: list
          };
        });
      }
    
      _onClickAddGroup() {
        const { history } = this.props;
        history.push('/dashboard/kesiswaan/displiner/buat-group');
      }
      _onFormChange(event) {
        const {
          name,
          value,
          dataset,
          checked,
          type,
        } = event.target;
        const {
          inputType = 'text', inputArray = false, arrayPosition = 0,
          fieldName,
        } = dataset;
        this.setState((prevState) => {
          let newList = [];
          let newListError = [];
          let formattedValue = value;
    
          if (inputType === 'number') {
            formattedValue = normalizeAmount(value);
          }
          if (inputArray) {
            if (type === 'checkbox') {
              formattedValue = checked;
            }
            newList = prevState.form.value[fieldName];
            newListError = prevState.form.error[fieldName];
            newList[arrayPosition][name] = formattedValue;
            if (name === 'code_of_account') {
              newList[arrayPosition].isCredit = value.type;
            }
            if (!isEmpty(newListError[arrayPosition])) {
              newListError[arrayPosition][name] = '';
            }
          }
          return {
            form: {
              value: {
                ...prevState.form.value,
                ...(inputArray
                  ? { [fieldName]: newList }
                  : { [name]: formattedValue }),
              },
              error: {
                ...prevState.form.error,
                ...(inputArray
                  ? { [fieldName]: newListError }
                  : { [name]: '' }),
              },
            },
          };
        });
      }

      async onSubmit() {
        const { history, handleSaveListTeacher } = this.props;
        const { form } = this.state;
        const { value } = form;
        let canSaveA = false;
        let canSaveB = false;
        const dataTabel = value.pelajaran;
        const dataForm = form.value;
        // let a;
        // for(a=0; a<dataTabel.length; a++){
        //   if(dataTabel[a].activities_name && dataTabel[a].date && dataTabel[a].end_hour && dataTabel[a].end_minute && dataTabel[a].start_hour && dataTabel[a].start_minute && dataTabel[a].teacher_id){
        //     canSaveA = true;
        //   } else{
        //     canSaveA = false;
        //     break;
        //   }
        // }
        // if(dataForm.name && dataForm.publish_date && dataForm.tgl_selesai && dataForm.levels && dataForm.classrooms){
        //   canSaveB = true; 
        // } else {
        //   canSaveB = false;
        // }
        // if (canSaveA == false){
        //   alert("Harap isi tabel secara lengkap, atau hapus jika tidak diperlukan.");
        // } else if (canSaveB == false){
        //   alert("Harap lengkapi data form. Bagian yang harus diisi ditandai dengan tanda bintang (*).");
        // } else {
          await handleSaveListTeacher(value);
          history.push('/dashboard/kurikulum/mata-pelajaran/daftar-guru');
        // }
      }

      async onEdit() {
        const { history, handleEditListTeacher } = this.props;
        const { form } = this.state;
        const { value } = form;
        let canSaveA = false;
        let canSaveB = false;
        const dataTabel = value.pelajaran;
        const dataForm = form.value;
        // let a;
        // for(a=0; a<dataTabel.length; a++){
        //   if(dataTabel[a].activities_name && dataTabel[a].date && dataTabel[a].end_hour && dataTabel[a].end_minute && dataTabel[a].start_hour && dataTabel[a].start_minute && dataTabel[a].teacher_id){
        //     canSaveA = true;
        //   } else{
        //     canSaveA = false;
        //     break;
        //   }
        // }
        // if(dataForm.name && dataForm.publish_date && dataForm.tgl_selesai && dataForm.levels && dataForm.classrooms){
        //   canSaveB = true; 
        // } else {
        //   canSaveB = false;
        // }
        // if (canSaveA == false){
        //   alert("Harap isi tabel secara lengkap, atau hapus jika tidak diperlukan.");
        // } else if (canSaveB == false){
        //   alert("Harap lengkapi data form. Bagian yang harus diisi ditandai dengan tanda bintang (*).");
        // } else {
          await handleEditListTeacher(value);
          history.push('/dashboard/kurikulum/mata-pelajaran/daftar-guru');
        // }
      }

      async _getParamOptions(type, filters={}) {
        const {getParamOptions, user} = this.props;
        const { organizations_id } = user;
        if (type === 'classrooms' || type === 'classrooms') {
          filters.organizations_id = organizations_id;
        } else {
          delete filters.organizations_id;
        }
        const res = await getParamOptions(filters, type);
        this.setState(prevState => ({
          ...prevState,
          param: {
            ...prevState.param,
            [type]: res,
          },
        }));
      }
      async _onSearchContent(params = {}) {
        const { filters } = this.state;
        
          try {
            const { handleListStudentAttendance, user } = this.props;
            const { organizations_id, school_unit } = user;
            const { units_id } = school_unit;
            const result = await handleListStudentAttendance({ ...params, organizations_id, units_id, filters });
            // if (this.isMount) {
              if (result) {
                result.forEach(item => {
                  if (!item.description) {
                    item.description = '';
                  }
                });
              } 
              this.setState(prevState => ({
                ...prevState,
                form: {
                  ...prevState.form,
                  value: {
                    ...prevState.form.value,
                    absensiharian: result,
                  }
                }
              }));
              
            // }
          } catch (err) {
            // if (this.isMount) {
              this.setState(prevState => ({
                ...prevState,
                form: {
                  ...prevState.form,
                  value: {
                    ...prevState.form.value,
                    absensiharian: [],
                  }
                }
              }));
              
          }
          // }
      }

      async _getParamOptions(type, filters={}) {
        const {getParamOptions, user} = this.props;
        const {organizations_id} = user;
        if (type === 'classrooms' || type === 'classrooms') {
          filters.organizations_id = organizations_id;
        } else {
          delete filters.organizations_id;
        }
        const res = await getParamOptions(filters, type);
        this.setState(prevState => ({
          ...prevState,
          param: {
            ...prevState.param,
            [type]: res,
          },
        }));
      }
      
    
      render() {
        const {form, param} = this.state;
        const {value, error} = form;
        const { matpel, ekskul } = form.value;
        const { handleGetKelas, handleDeleteKelas,coa, classrooms={},hari, levels = {}, location } = this.props;
        const { isEdit = true, data } = location.state;
        
        return (
        <div className="add-budget">
           
        <div className="nilai-kognitif__custom-form-row">
            <div className="nilai-kognitif__custom-form-row__field">
                <Input
                    type='text'
                    name="employee_id"
                    label="NIK/NIP"
                    onChange={this._onFormChange}
                    value={value.employee_id}
                    error={error.employee_id || ''}
                />
            </div>
        
            <div className="nilai-kognitif__custom-form-column__field">
                
                <Input
                    type='text'
                    name="full_name"
                    label="Nama Guru"
                    onChange={this._onFormChange}
                    value={value.full_name}
                    error={error.full_name || ''}
                />
            
            </div>
        </div>
        <div className="nilai-kognitif__custom-form-row">
            <div className="nilai-kognitif__custom-form-row__field">
                {/* <Select
                    name="homeroom_teache_classrooms_id"
                    label="Wali Kelas"
                    placeholder="Pilihan"
                    data={param.classrooms}
                    onChange={this._onFormChange}
                    value={value.homeroom_teache_classrooms_id}
                    error={error.homeroom_teache_classrooms_id	 || ''}
                /> */}

            </div>
        
            <div className="nilai-kognitif__custom-form-column__field">
               
            </div>
        </div>
         
            
                
                    <MatpelGuruTable
                    list={matpel}
                    onAddList={this._onClickAdd}
                    onFormChange={this._onFormChange}
                    onDeleteList={this._onDeleteList}
                    classrooms={classrooms}
                    param={param}
                    />
                     <EkskulMatpel
                    list={ekskul}
                    onAddList2={this._onClickAdd2}
                    onFormChange={this._onFormChange}
                    onDeleteList2={this._onDeleteList2}
                    classrooms={classrooms}
                    param={param}
                    />
            <div className="student-list__button2">
            {this.state.id?
              <Button
                type="button"
                title="Update"
                onClick={this.onEdit}
              />:
              <Button
                type="button"
                title="Simpan"
                onClick={this.onSubmit}
              />
            }
              </div>
            
         </div>
        );
      }
}
TambahDaftarGuru.propTypes = {
  getParamOptions: PropTypes.func,
  handleGetclassrooms: PropTypes.func,  
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
TambahDaftarGuru.defaultProps = {
  getParamOptions: noop,
  handleGetclassrooms: noop,  
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};
export const TABLE_PELANGGARAN_TABLE_FIELDS = {
  label: ["No", "Nama Pelanggaran", "Group Pelanggaran", "Opsi"],
  value: [
    {},
    { type: "string", attribute: "name" },
    { type: "string", attribute: "group" },
  ],
};

export const GROUP_PELANGGARAN_FORM_FIELDS = [
  {
    type: "text",
    label: "Nama Group",
    name: "group",
    full: true,
  },
];

export const TABLE_PRESTASI_TABLE_FIELDS = {
  label: ["No", "Nama Prestasi", "Group Prestasi", "Opsi"],

  value: [
    {},
    { type: "string", attribute: "name" },
    { type: "string", attribute: "group" },
  ],
};

export const GROUP_PRESTASI_FORM_FIELDS = [
  {
    type: "text",
    label: "Nama Group",
    name: "group",
    full: true,
  },
];

export const TABLE_LIST_PELANGGARAN_TABLE_FIELDS = {
  label: ["No", "Nama Group Pelanggaran", "Opsi"],
  value: [{}, { type: "string", attribute: "name" }],
};

export const TABLE_LIST_PRESTASI_TABLE_FIELDS = {
  label: ["No", "Nama Group Prestasi", "Opsi"],
  value: [{}, { type: "string", attribute: "name" }],
};

const CATAT_PELANGGARAN_FORM_FIELDS = [
  {
    type: "select",
    label: "Tahun Ajaran",
    name: "tahunajaran",
    data: [
      { label: "2019-2020", value: "tahunajaran1" },
      { label: "2020-2021", value: "tahunajaran2" },
    ],
  },
  {
    type: "select",
    label: "Kelas",
    name: "kelas",
    placeholder: "Pilihan",
    data: [{ label: "Kelas 7A", value: "kelas7" }],
  },
  {
    type: "text",
    label: "Nomor Absen",
    name: "nomorabsen",
  },
  {
    type: "date",
    label: "tanggal",
    name: "tanggal",
  },
  {
    type: "text",
    label: "NIS/Nama",
    name: "nis/name",
    placeholder: "Nomer Induk Siswa/Nama Panggilan/Nama Lengkap Siswa",
    full: true,
  },
];

const CATAT_PELANGGARAN_TABLE_FIELDS = {
  label: [
    "No",
    "Tanggal",
    "Mapel",
    "Pelanggaran",
    "Keterangan",
    "Sanksi",
    "Opsi",
  ],

  value: [
    {},
    { type: "string", attribute: "tanggal" },
    { type: "string", attribute: "mapel" },
    { type: "string", attribute: "pelanggaran" },
    { type: "string", attribute: "keterangan" },
    { type: "string", attribute: "sanksi" },
    { type: "string", attribute: "opsi" },
  ],
};

export const CATAT_PELANGGARAN = {
  1: CATAT_PELANGGARAN_FORM_FIELDS,
  2: CATAT_PELANGGARAN_TABLE_FIELDS,
};

const CATAT_PRESTASI_FORM_FIELDS = [
  {
    type: "select",
    label: "Tahun Ajaran",
    name: "tahunajaran",
    data: [
      { label: "2019-2020", value: "tahunajaran1" },
      { label: "2020-2021", value: "tahunajaran2" },
    ],
  },
  {
    type: "select",
    label: "Kelas",
    name: "kelas",
    placeholder: "Pilihan",
    data: [{ label: "Kelas 7A", value: "kelas7" }],
  },
  {
    type: "text",
    label: "Nomor Absen",
    name: "nomorabsen",
  },
  {
    type: "date",
    label: "tanggal",
    name: "tanggal",
  },
  {
    type: "text",
    label: "NIS/Nama",
    name: "nis/name",
    placeholder: "Nomer Induk Siswa/Nama Panggilan/Nama Lengkap Siswa",
    full: true,
  },
];

const CATAT_PRESTASI_TABLE_FIELDS = {
  label: ["No", "Tanggal", "Mapel", "Prestasi", "Keterangan", "Sanksi", "Opsi"],

  value: [
    {},
    { type: "string", attribute: "tanggal" },
    { type: "string", attribute: "mapel" },
    { type: "string", attribute: "pelanggaran" },
    { type: "string", attribute: "keterangan" },
    { type: "string", attribute: "sanksi" },
    { type: "string", attribute: "opsi" },
  ],
};

export const CATAT_PRESTASI = {
  1: CATAT_PRESTASI_FORM_FIELDS,
  2: CATAT_PRESTASI_TABLE_FIELDS,
};

const CATAT_HARIANSISWA_FORM_FIELDS = [
  {
    type: "select",
    label: "Tahun Ajaran",
    name: "tahunajaran",
    data: [
      { label: "2019-2020", value: "tahunajaran1" },
      { label: "2020-2021", value: "tahunajaran2" },
    ],
  },
  {
    type: "select",
    label: "Kelas",
    name: "kelas",
    placeholder: "Pilihan",
    data: [{ label: "Kelas 7A", value: "kelas7" }],
  },
  {
    type: "text",
    label: "Nomor Absen",
    name: "nomorabsen",
  },
  {
    type: "date",
    label: "tanggal",
    name: "tanggal",
  },
  {
    type: "text",
    label: "NIS/Nama",
    name: "nis/name",
    placeholder: "Nomer Induk Siswa/Nama Panggilan/Nama Lengkap Siswa",
    full: true,
  },
];

const CATAT_HARIANSISWA_TABLE_FIELDS = {
  label: ["No", "Tanggal", "Mapel", "Catatan", "Keterangan", "Sanksi", "Opsi"],

  value: [
    {},
    { type: "string", attribute: "tanggal" },
    { type: "string", attribute: "mapel" },
    { type: "string", attribute: "pelanggaran" },
    { type: "string", attribute: "keterangan" },
    { type: "string", attribute: "sanksi" },
    { type: "string", attribute: "opsi" },
  ],
};

export const CATAT_HARIANSISWA = {
  1: CATAT_HARIANSISWA_FORM_FIELDS,
  2: CATAT_HARIANSISWA_TABLE_FIELDS,
};

export const ANALISA_DISPLINER_FORM_FIELDS = [
  {
    type: "select",
    label: "Tahun Ajaran",
    name: "tahunajaran",
    data: [{ label: "2019-2020", value: "tahun1" }],
    full: true,
  },
  {
    type: "select",
    label: "Jenis",
    name: "jenis",
    placeholder: "Pilihan",
    data: [{ label: "2019-2020", value: "tahun1" }],
    full: true,
  },
];

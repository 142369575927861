import React,  { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import { Input, Select } from '../../../components/base/index';
import { GENDER } from '../../../variables/common.variable';
import { commaFormatted, normalizeAmount, manageGetRegistration } from '../../../utils/transformer.util';


export default class OrtuForm extends Component {
    constructor(props) {
        super(props);
        this._onFormChange = this._onFormChange.bind(this);
        this._filterOptions = this._filterOptions.bind(this);
        
        this.state = {
          original: {
            sub_districts: props.sub_districts || [],
            districts: props.districts || [],
            cities: props.cities || [],
            provinces: props.provinces || [],
          },
          options: {
            sub_districts: [],
            districts: [],
            cities: [],
            provinces: [],
          }
        };
          
       
      }

      componentDidUpdate({provinces: prevP, cities: prevC, sub_districts: prevS, districts: prevD }) {
        const { provinces = [], cities = [], sub_districts = [], districts = [] } = this.props;
        if (
          JSON.stringify(provinces) !== JSON.stringify(prevP) 
          || JSON.stringify(cities) !== JSON.stringify(prevC)
          || JSON.stringify(districts) !== JSON.stringify(prevD)
          || JSON.stringify(sub_districts) !== JSON.stringify(prevS)
        ) {
          this.setState({
            original: {
              provinces,
              cities,
              districts,
              sub_districts
            },
            options: {
              provinces,
              cities,
              districts,
              sub_districts
            }
          });
        }
      }
      


      _onFormChange(e) {
        const { onChange } = this.props;
        let change = {}
        change[e.target.name] = e.target.value
        this.setState(change , () => { console.log(this.state) })

        onChange(e);
      }
      
      _filterOptions(name) {
        const { original } = this.state;
        const { form = {} } = this.props;
        const { value = {} } = form;
    
        if (name === 'cities') {
          return original.cities.filter(o => o.data.provinces_id == value.father_provincess_id);
        } else if (name === 'districts') {
          return original.districts.filter(o => o.data.cities_id == value.father_city_id);
        } else if (name === 'sub_districts') {
          return original.sub_districts.filter(o => o.data.districts_id == value.father_districts_id);
        } else {
          return original.provinces;
        }
      }

  render(){
    const { options, original } = this.state;
    const { provinces, sub_districts, districts, cities } = options;
    const { form, idx, onChange, classes, param } = this.props;
    return (
      <div className="manage-registration__custom-form">
        <h1>Data Orang Tua/Wali</h1>
        <h1>Ayah</h1>

        <div className="manage-registration__custom-form-row">
        <div className="manage-registration__custom-form-row__field">
          <Input
            type="text"
            name="father_name"
            label="Nama Lengkap"
            onChange={this._onFormChange}
            value={form.value.father_name}
            error={form.error.father_name || ''}
          />
        </div>
        <div className="manage-registration__custom-form-column__field">
          <Input
            type="text"
            name="father_nik"
            label="NIK"
            onChange={this._onFormChange}
            value={form.value.father_nik}
            error={form.error.father_nik || ''}
          />
        </div>
        </div>

        <div className="manage-registration__custom-form-row">
        <div className="manage-registration__custom-form-row__field">
          <Select
            name="father_religions_id"
            label="Agama"
            onChange={this._onFormChange}
            placeholder="Pilihan"
            value={form.value.father_religions_id}
            error={form.error.father_religions_id || ''}
            data={param.religions}
          />
        </div>
        <div className="manage-registration__custom-form-column__field">
          <Select 
              name="father_citizenships_id"
              label="Kewarganegaraan"
              onChange={this._onFormChange}
              placeholder="Pilihan"
              value={form.value.father_citizenships_id}
              error={form.error.father_citizenships_id || ''}
              data={param.citizenships}
            />
        </div>
        </div>

        <div className="manage-registration__custom-form-row">
        <div className="manage-registration__custom-form-row__field">
          <Select
              name="father_educations_id"
              label="Pendidikan Terakhir"
              onChange={this._onFormChange}
              placeholder="Pilihan"
              value={form.value.father_educations_id}
              error={form.error.father_educations_id || ''}
              data={param.educations}
            />
        </div>
        <div className="manage-registration__custom-form-row-input__field">
                <Input
                    type='text'
                    name="father_pob"
                    label="Tempat Tanggal Lahir"
                    onChange={this._onFormChange}
                    value={form.value.father_pob}
                    error={form.error.father_pob || ''}
                />
                <div className="manage-registration__custom-form-margin-left">
                    <Input
                        type='date'
                        name="father_dob"
                        label="Tanggal "
                        onChange={this._onFormChange}
                        value={form.value.father_dob}
                        error={form.error.father_dob || ''}
                    />
                </div>
            </div>
            
        </div>
          <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
          <Input
            type="number"
            name="father_phone"
            label="No Telpon"
            onChange={this._onFormChange}
            value={form.value.father_phone}
            error={form.error.father_phone || ''}
          />
        </div>


        <div className="manage-registration__custom-form-row__field">

           <Select
              name="father_occupations_id"
              label="Pekerjaan"
              onChange={this._onFormChange}
              data={param.occupations}
              placeholder="Pilihan"
              value={form.value.father_occupations_id}
              error={form.error.father_occupations_id || ''}
            />
        </div>

          </div>

          <div className="manage-registration__custom-form-row">
        <div className="manage-registration__custom-form-row__field">
          <Input
            name="father_position"
            label="Jabatan"
            onChange={this._onFormChange}
            value={form.value.father_position}
            error={form.error.father_position || ''}
          />
        </div>
        

        <div className="manage-registration__custom-form-column__field">
          <Input
            type='nominal'
            name="father_salary"
            label="Penghasilan"
            onChange={this._onFormChange}
            value={form.value.father_salary}
            error={form.error.father_salary || ''}
          />
          </div>
          </div>

            <div className="manage-registration__custom-form-row">
              <div className="manage-registration__custom-form-row__field">
                <Input
                  type="text"
                  name="father_address"
                  label="Alamat Tempat Tinggal"
                  onChange={this._onFormChange}
                  value={form.value.father_address}
                  error={form.error.father_address || ''}
                />
              </div>
              <div className="manage-registration__custom-form-column__field">

              <Select
                  name="father_provincess_id"
                  value={form.value.father_provincess_id} 
                  error={form.error.father_provincess_id || ''}
                  placeholder="Pilihan"
                  data={this._filterOptions('provinces')}
                  label="Propinsi"
                  onChange={this._onFormChange}
            />
                {/* {console.log(form.value.provinces)}  */}
              </div>
            </div>

          {form.value.father_provincess_id? 
          <div>
            <div className="manage-registration__custom-form-row">
              <div className="manage-registration__custom-form-row__field">
              <Select
                name="father_city_id"
                label="Kabupaten/Kota"
                onChange={this._onFormChange}
                placeholder="Pilihan"
                value={form.value.father_city_id}
                error={form.error.father_city_id || ''}
                data={this._filterOptions('cities')}
                />
            </div>
            
             
            <div className="manage-registration__custom-form-column__field">
 
            </div>
          </div>
          {form.value.father_city_id?
           <div className="manage-registration__custom-form-row">
           <div className="manage-registration__custom-form-row__field">
   
         </div>
         
          
         <div className="manage-registration__custom-form-column-city__field">
           <Select
             name="father_districts_id"
             label="Kecamatan"
             onChange={this._onFormChange}
             data={this._filterOptions('districts')}
             placeholder="Pilihan"
             value={form.value.father_districts_id}
             error={form.error.father_districts_id || ''}
           />
         </div>
       </div>
           : ''}

        {form.value.father_districts_id?
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
              <Select
                name="father_sub_districts_id"
                label="Kelurahan"
                onChange={this._onFormChange}
                data={this._filterOptions('sub_districts')}
                placeholder="Pilihan"
                value={form.value.father_sub_districts_id}
                error={form.error.father_sub_districts_id || ''}
              />
            </div> 
            <div className="manage-registration__custom-form-column__field">
            <Input
            name="father_status"
            label="Status/Kondisi"
            onChange={this._onFormChange}
            value={form.value.father_status}
            error={form.error.father_status || ''}
          />
          </div>
        </div>:""}
      
        </div>
        :""
      
          }

         


        </div>
    );
}
  
}
OrtuForm.propTypes = {
//   onFormChange: PropTypes.func,
idx: PropTypes.number.isRequired,
  form: PropTypes.object.isRequired,
  onChange: PropTypes.func, 
  list: PropTypes.array.isRequired,
  provinces3 : PropTypes.array,
  cities3: PropTypes.array,
  districts3: PropTypes.array,
  sub_districts3: PropTypes.array,
 
};
OrtuForm.defaultProps = {
//   onFormChange: noop,
onChange: noop,
provinces3: [],
cities3: [],
districts3:[],
father_city_id: [],
father_districts_id: [],
sub_districts3: [],

};

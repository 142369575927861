import { connect } from 'react-redux';
import ManageRegistration from '../../views/Student/ManageRegistration.student.view';
import { manageRegistration, getManageRegistration, editManageRegistration, uploadImageFileStudents} from '../../states/thunks/student.thunk';
import { 
  getParamOptions, getProvinces, getParamOptionsAcademics,
  getClassesOptions, getCities, getDistricts,  getSubDistricts, 
  getCitizenships, getReligions, getEducations,
  getOccupations, getClasses, getGenders, getBloodTypes,
  getLevels, getGuardians, getPeriodsOptions, getYearsOfEducationsOptions
} from '../../states/thunks/options.thunk';



function mapStateToProps(state) {
  return {
    user: state.user,
    provinces: state.options.provinces,
    cities: state.options.cities,
    districts: state.options.districts,
    sub_districts: state.options.sub_districts,
    citizenships: state.options.citizenships,
    religions: state.options.religions,
    educations: state.options.educations,
    occupations: state.options.occupations,
    classes: state.options.classes,
    genders: state.options.genders,
    blood_types: state.options.blood_types,
    levels: state.options.levels,
    guardians: state.options.guardians,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    handleGetManageRegistration: payload => dispatch(getManageRegistration(payload)),
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
    getClassesOptions: (payload) => dispatch(getClassesOptions(payload)),
    getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
    getYearsOfEducationOptions: (payload) => dispatch(getYearsOfEducationsOptions(payload)),
    handleManageRegistration: (payload, push) => dispatch(manageRegistration(payload, push)),
    handleEditManageReistrationt: (payload, goback) => dispatch(editManageRegistration(payload, goback)), 
    handleUploadImageFileStudents: (payload, goback) => dispatch(uploadImageFileStudents(payload, goback)),
    handleGetProvinces: payload => dispatch(getProvinces(payload)),
    handleGetCities: payload => dispatch(getCities(payload)),
    handleGetDistricts: payload => dispatch(getDistricts(payload)),
    handleGetSubDistricts: payload => dispatch(getSubDistricts(payload)),
    handleGetCitizenships: payload => dispatch(getCitizenships(payload)),
    handleGetReligions: payload => dispatch(getReligions(payload)),
    handleGetEducations: payload => dispatch(getEducations(payload)),
    handleGetOccupations: payload => dispatch(getOccupations(payload)),
    handleGetClasses: payload => dispatch(getClasses(payload)),
    handleGetGenders: payload => dispatch(getGenders(payload)),
    handleGetBloodTypes: payload => dispatch(getBloodTypes(payload)),
    handleGetLevels: payload => dispatch(getLevels(payload)),
    handleGetGuardians: payload => dispatch(getGuardians(payload)),

    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ManageRegistration);

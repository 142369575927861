import {
  noop,
} from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { commaFormatted } from '../../../../../utils/transformer.util';

const loopSubheaderTema = (subHeaderTema, budgetLoop, total, rows) => {
  const {
    tema: subTema, prm_detail_header_tema = [], prm_sub_detail_header_tema = [],
  } = subHeaderTema;

  let subTotal = total;

  subTema.forEach((tema) => {
    const { id, code, name } = tema;
    const filtered = budgetLoop.filter(o => o.tema === id) || {};
    filtered.forEach((budget) => {
      const {
        target = '', allocations = [], execution_time, desc,
      } = budget;
      const rutin = allocations.find(o => o.plafond_id === 1) || 0;
      const kegiatan = allocations.find(o => o.plafond_id === 2) || 0;
      const swadaya = allocations.find(o => o.plafond_id === 3) || 0;
      const bpp = allocations.find(o => o.plafond_id === 4) || 0;
      const rowTotal = rutin.nominal || 0
        + kegiatan.nominal || 0
        + swadaya.nominal || 0
        + bpp.nominal || 0;

      subTotal.totalRutin += rutin.nominal || 0;
      subTotal.totalKegiatan += kegiatan.nominal || 0;
      subTotal.totalSwadaya += swadaya.nominal || 0;
      subTotal.totalBpp += bpp.nominal || 0;
      if (Object.keys(budget).length > 0) {
        rows.push(<tr>
          <td>{code}</td>
          <td>{name}</td>
          <td>{desc}</td>
          <td>{target}</td>
          <td>{execution_time}</td>
          <td className="nominal">{commaFormatted(rutin.nominal || 0)}</td>
          <td className="nominal">{commaFormatted(kegiatan.nominal || 0)}</td>
          <td className="nominal">{commaFormatted(swadaya.nominal || 0)}</td>
          <td className="nominal">{commaFormatted(bpp.nominal || 0)}</td>
          <td className="nominal">{commaFormatted(rowTotal) || 0}</td>
        </tr>);
      }
    });
  });
  if (prm_detail_header_tema.length > 0) {
    prm_detail_header_tema.forEach((o) => {
      subTotal = loopSubheaderTema(o, budgetLoop, subTotal, rows);
    });
  } else if (prm_sub_detail_header_tema.length > 0) {
    prm_sub_detail_header_tema.forEach((o) => {
      subTotal = loopSubheaderTema(o, budgetLoop, subTotal, rows);
    });
  }
  return subTotal;
};
export default class BudgetRapbuRekap extends Component {
  constructor(props) {
    super(props);

    this.renderRows = this.renderRows.bind(this);
    this.onSearchTema = this.onSearchTema.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
  }

  onSearchTema(tema = null) {
    const { handleGetTemaOptions } = this.props;
    const { target } = tema;
    const { value } = target;
    handleGetTemaOptions({
      tema,
      value,
    });
  }

  onFormChange(event) {
    const {
      name,
      value,
      dataset,
    } = event.target;
    const { onChange, idx } = this.props;

    onChange({
      target: {
        name,
        value,
        dataset: {
          ...dataset,
          inputArray: true,
          arrayPosition: idx,
        },
      },
    });
  }

  _onFormChange(event) {
    const { onChange } = this.props;
    onChange(event);
  }

  renderRows() {
    const {
      dataLoop: data = [],
      budgetLoop = [],
    } = this.props;
    const rows = [];

    let totalRutin = 0;
    let totalSwadaya = 0;
    let totalKegiatan = 0;
    let totalBpp = 0;
    let total = 0;

    data.forEach((header) => {
      const { tema: childTema, sub_header_tema: subHeader } = header;
      childTema.forEach((tema) => {
        const { id, code, name } = tema;
        const filtered = budgetLoop.filter(o => o.tema === id) || {};
        filtered.forEach((budget) => {
          const {
            target = '', allocations = [], execution_time, desc,
          } = budget;
          const rutin = allocations.find(o => o.plafond_id === 1) || 0;
          const kegiatan = allocations.find(o => o.plafond_id === 2) || 0;
          const swadaya = allocations.find(o => o.plafond_id === 3) || 0;
          const bpp = allocations.find(o => o.plafond_id === 4) || 0;
          const subTotal = rutin.nominal
            || 0 + kegiatan.nominal
            || 0 + swadaya.nominal
            || 0 + bpp.nominal || 0;

          totalRutin += rutin.nominal || 0;
          totalKegiatan += kegiatan.nominal || 0;
          totalSwadaya += swadaya.nominal || 0;
          totalBpp += bpp.nominal || 0;
          if (Object.keys(budget).length > 0) {
            rows.push(
              <tr>
                <td>{code}</td>
                <td>{name}</td>
                <td>{desc}</td>
                <td>{target}</td>
                <td>{execution_time}</td>
                <td className="nominal">{commaFormatted(rutin.nominal || 0)}</td>
                <td className="nominal">{commaFormatted(kegiatan.nominal || 0)}</td>
                <td className="nominal">{commaFormatted(swadaya.nominal || 0)}</td>
                <td className="nominal">{commaFormatted(bpp.nominal || 0)}</td>
                <td className="nominal">{commaFormatted(subTotal) || 0}</td>
              </tr>);
          }
        });
      });

      subHeader.forEach((subHeaderTema) => {
        const subTotal = loopSubheaderTema(subHeaderTema, budgetLoop, {
          totalRutin,
          totalKegiatan,
          totalBpp,
          totalSwadaya,
        }, rows);
        totalRutin = subTotal.totalRutin || 0;
        totalKegiatan = subTotal.totalKegiatan || 0;
        totalSwadaya = subTotal.totalSwadaya || 0;
        totalBpp = subTotal.totalBpp || 0;
      });
    });

    total += totalRutin + totalKegiatan + totalSwadaya + totalBpp;

    rows.push(<tfoot>
      <tr className="total">
        <td colSpan={5}>Total</td>
        <td className="nominal">{commaFormatted(totalRutin)}</td>
        <td className="nominal">{commaFormatted(totalKegiatan)}</td>
        <td className="nominal">{commaFormatted(totalSwadaya)}</td>
        <td className="nominal">{commaFormatted(totalBpp)}</td>
        <td className="nominal">{commaFormatted(total)}</td>
      </tr>
    </tfoot>);

    return rows;
  }

  render() {
    return (
      <div className="budget_content">
        <table className="table">
          <thead>
            <tr>
              <th style={{ width: '5%' }} rowSpan={2}>
                Kode
              </th>
              <th style={{ width: '5%' }} rowSpan={2}>
                Nama
              </th>
              <th rowSpan={2}>
                Deskripsi
              </th>
              <th rowSpan={2}>
                Waktu Pelaksanaan
              </th>
              <th rowSpan={2}>
                Target
              </th>
              <th rowSpan={2}>
                Rutin
              </th>
              <th rowSpan={2}>
                Kegiatan
              </th>
              <th rowSpan={2}>
                Dana Pemerintah
              </th>
              <th rowSpan={2}>
                Biaya Pengembangan Pendidikan
              </th>
              <th rowSpan={2}>
                Total
              </th>
            </tr>
          </thead>
          {this.renderRows()}
        </table>
      </div>
    );
  }
}
BudgetRapbuRekap.propTypes = {
  handleGetTemaOptions: PropTypes.func,
  onChange: PropTypes.func,
  idx: PropTypes.number,
  budgetLoop: PropTypes.array,
  dataLoop: PropTypes.array,
};
BudgetRapbuRekap.defaultProps = {
  handleGetTemaOptions: noop,
  onChange: noop,
  idx: null,
  budgetLoop: [],
  dataLoop: [],
};

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { find } from "lodash";
import pendapatan from "../../../../assets/img/finance/pendapatan.png";
import pengeluaran from "../../../../assets/img/finance/pengeluaran.png";
import rapbu from "../../../../assets/img/finance/rapbu.png";
import rekap from "../../../../assets/img/finance/rekap.png";
import detail from "../../../../assets/img/finance/detail.png";

export default class BudgetType extends PureComponent {
  constructor(props) {
    super(props);
    this.clickHandlers = {};
    this.onClick = this.onClick.bind(this);
  }

  onClick(type, code) {
    const { location, history } = this.props;
    const { data } = location.state;
    const { account = [], unique_id, periode } = location.state.data;
    // console.log(this.props);
    // console.log(location);
    if (type === "RAPBU") {
      history.push("/dashboard/keuangan/penganggaran/rapbs", {
        type,
        code: "",
        account_unique_id: "",
        head_unique_id: unique_id,
      });
    } else if (type === "DETAIL_RAPBU") {
      history.push("/dashboard/keuangan/penganggaran/detail-rapbs", {
        type,
        code: "",
        head_unique_id: unique_id,
      });
    } else if (type === "REKAP_RAPBU") {
      history.push("/dashboard/keuangan/penganggaran/rekap-rapbs", {
        type,
        code: "",
        head_unique_id: unique_id,
      });
    } else if (type === "UPLOAD_RAPBU") {
      history.push("/dashboard/keuangan/penganggaran/upload-rapbs-rapby", {
        head: unique_id,
        periode,
        prevPath: location.pathname,
        data,
      });
      // console.log(type)
    } else if (type === "RAPBY") {
      history.push("/dashboard/keuangan/penganggaran/rapby", {
        type,
        code: "",
        account_unique_id: "",
        head_unique_id: unique_id,
      });
      // console.log(type)
    } else if (type === "DETAIL_RAPBY") {
      history.push("/dashboard/keuangan/penganggaran/detail-rapby", {
        type,
        code: "",
        head_unique_id: unique_id,
      });
    } else if (type === "REKAP_RAPBY") {
      history.push("/dashboard/keuangan/penganggaran/rekap-rapby", {
        type,
        code: "",
        head_unique_id: unique_id,
      });
    } else {
      const selectedData = find(account, ["account_type", code]);
      history.push("/dashboard/keuangan/penganggaran/kelola-anggaran", {
        type,
        code,
        account_unique_id: selectedData.unique_id,
        head_unique_id: selectedData.head,
      });
    }
  }

  getClickHandler(type, code) {
    // console.log(type)

    if (!Object.prototype.hasOwnProperty.call(this.clickHandlers, type)) {
      this.clickHandlers[type] = () => this.onClick(type, code);
    }
    return this.clickHandlers[type];
  }

  render() {
    const { user } = this.props;
    const { user_group, workingUnit = {}, school_unit = {} } = user;
    const activeUnit =
      Object.values(workingUnit).length > 0 ? workingUnit : school_unit;
    const { id: activeUnitId } = activeUnit;
    // console.log(school_unit);
    return (
      <div className="bookkeeping">
        {user_group.name === "Keuangan Sekolah" ||
        user_group.name === "Admin" ||
        user_group.name === "Keuangan Pusat" ? (
          <div className="bookkeeping__list" key="bookeeping_list">
            <button onClick={this.getClickHandler("INCOME", 400000)}>
              <img src={pendapatan} alt="budget" />
              <h3>Pendapatan</h3>
            </button>
          </div>
        ) : (
          ""
        )}

        <div className="finance__list" key="finance_list">
          <button onClick={this.getClickHandler("OUTCOME", 500000)}>
            <img src={pengeluaran} alt="pengeluaran" />
            <h3>Pengeluaran</h3>
          </button>
        </div>

        {user_group.id < 13 && activeUnitId < 10 ? (
          <div className="finance__list">
            <button onClick={this.getClickHandler("RAPBU")}>
              <img src={rapbu} alt="RAPBS" />
              <h3>RAPBS</h3>
            </button>
          </div>
        ) : (
          ""
        )}

        <div className="finance__list">
          <button onClick={this.getClickHandler("UPLOAD_RAPBU")}>
            <img src={rapbu} alt="RAPBS" />
            <h3>Upload RAPBS / RAPBY</h3>
          </button>
        </div>

        {user_group.id < 13 && activeUnitId < 10 ? (
          <div className="finance__list">
            <button onClick={this.getClickHandler("REKAP_RAPBU")}>
              <img src={rekap} alt="REKAP RAPBU" />
              <h3>Rekap RAPBS</h3>
            </button>
          </div>
        ) : (
          ""
        )}

        {user_group.id < 13 && activeUnitId < 10 ? (
          <div className="finance__list">
            <button onClick={this.getClickHandler("DETAIL_RAPBU")}>
              <img src={detail} alt="DETAIL RAPBU" />
              <h3>Detail RAPBS</h3>
            </button>
          </div>
        ) : (
          ""
        )}

        {/* Menu RAPBY */}

        {user_group.name === "Hrd" ||
        user_group.name === "Pengadaan" ||
        user_group.name === "Litbang" ||
        user_group.name === "IT" ||
        user_group.name === "Opsdik" ||
        user_group.name === "RT" ||
        user_group.name === "Sekretariat" ||
        user_group.name === "Keuangan" ||
        user_group.name === "Keuangan Sekolah" && activeUnitId > 9 ||
        activeUnitId === "0" ||
        activeUnitId === 9 ||
        user_group.name === "Admin" ||
        (user_group.name === "Keuangan Pusat" && activeUnitId > 9) ||
        activeUnitId === "0" ||
        activeUnitId === 9 ? (
          <div className="finance__list">
            <button onClick={this.getClickHandler("RAPBY")}>
              <img src={rapbu} alt="RAPBY" />
              <h3>RAPBY</h3>
            </button>
          </div>
        ) : (
          ""
        )}
        {user_group.name === "Hrd" ||
        user_group.name === "Pengadaan" ||
        user_group.name === "Litbang" ||
        user_group.name === "IT" ||
        user_group.name === "Opsdik" ||
        user_group.name === "RT" ||
        user_group.name === "Sekretariat" ||
        user_group.name === "Keuangan" ||
        user_group.name === "Keuangan Sekolah"&& activeUnitId > 9 ||
        activeUnitId === "0" ||
        activeUnitId === 9 ||
        (user_group.name === "Keuangan Pusat" && activeUnitId > 9) ||
        activeUnitId === "0" ||
        activeUnitId === 9 ? (
          <div className="finance__list">
            <button onClick={this.getClickHandler("REKAP_RAPBY")}>
              <img src={rekap} alt="REKAP RAPBY" />
              <h3>Rekap RAPBY</h3>
            </button>
          </div>
        ) : (
          ""
        )}
        {user_group.name === "Hrd" ||
        user_group.name === "Pengadaan" ||
        user_group.name === "Litbang" ||
        user_group.name === "IT" ||
        user_group.name === "Opsdik" ||
        user_group.name === "RT" ||
        user_group.name === "Sekretariat" ||
        user_group.name === "Keuangan" ||
        user_group.name === "Keuangan Sekolah" && activeUnitId > 9||
        (user_group.name === "Keuangan Pusat" && activeUnitId > 9) ||
        activeUnitId === "0" ||
        activeUnitId === 9 ? (
          <div className="finance__list">
            <button onClick={this.getClickHandler("DETAIL_RAPBY")}>
              <img src={detail} alt="DETAIL RAPBY" />
              <h3>Detail RAPBY</h3>
            </button>
          </div>
        ) : (
          ""
        )}

        {/* Menu RAPBY */}
      </div>
    );
  }
}
BudgetType.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

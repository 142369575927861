import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import budgeting from '../../../assets/img/finance/anggaran.png';
import bookkeeping from '../../../assets/img/bookkeeping/jurnal_bank.png';
import { map } from 'lodash';

export default class Ekskul extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickPembagianKelasEkskul = this.onClickPembagianKelasEkskul.bind(this);
    this.onClickJenisEkskul = this.onClickJenisEkskul.bind(this);
    this.onClickDaftarPesertaEkskul= this.onClickDaftarPesertaEkskul.bind(this);
    this.onClickAbsensiEkskul= this.onClickAbsensiEkskul.bind(this);
    this.onClickPengisianNilaiEkskul = this.onClickPengisianNilaiEkskul.bind(this);
 this.state = {
   form: {
     value: {
      permission: [],

     },
     error: {
       permission: '',
     }
   }
 }
  }
  onClickPembagianKelasEkskul() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/ekstrakulikuler/pembagian-kelas-ekskul');
  }
  onClickJenisEkskul() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/ekstrakulikuler/jenis-ekstrakulikuler');
  }

  onClickDaftarPesertaEkskul() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/ekstrakulikuler/daftar-peserta-ekskul');
  }

  onClickAbsensiEkskul() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/ekstrakulikuler/absensi-ekskul');
  }

  onClickPengisianNilaiEkskul() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/ekstrakulikuler/pengisian-nilai-ekskul');
  }


  render() {
    const { form } = this.state;
    const { user } = this.props;
    const { permissions } = user.user_group;
    return (
    <div>
      {
      map (permissions, (item) => {

        if (item == "view_ekskul_guru") {
          return (
            <div className="akademi">
              <div className="akademi__list">
                <button onClick={this.onClickDaftarPesertaEkskul}>
                  <img src={budgeting} alt="DaftarPesertaEkskul" />
                  <h3>Daftar Peserta Ekskuls</h3>
                </button>
              </div>
              <div className="akademi__list">
                <button onClick={this.onClickAbsensiEkskul}>
                  <img src={budgeting} alt="AbsensiEkskul" />
                  <h3>Absensi Ekskul</h3>
                </button>
              </div>
              <div className="akademi__list">
                <button onClick={this.onClickPengisianNilaiEkskul}>
                  <img src={budgeting} alt="PengisianNilaiEkskul" />
                  <h3>Pengisian Nilai Ekskul</h3>
                </button>
              </div>
            </div>
            )
          }
          if (item == "view_form_TU") {
            return (
            <div className="akademi">
              <div className="akademi__list">
                <button onClick={this.onClickJenisEkskul}>
                  <img src={budgeting} alt="JenisEkskul" />
                  <h3>Jenis Ekstrakulikuler</h3>
                </button>
              </div>
              <div className="akademi__list">
                <button onClick={this.onClickDaftarPesertaEkskul}>
                  <img src={budgeting} alt="DaftarPesertaEkskul" />
                  <h3>Daftar Peserta Ekskul</h3>
                </button>
              </div>
              <div className="akademi__list">
                <button onClick={this.onClickPembagianKelasEkskul}>
                  <img src={budgeting} alt="PembagianKelasEkskul" />
                  <h3>Pembagian Kelas Ekskul</h3>
                </button>
              </div>
              {/* <div className="akademi__list">
                <button onClick={this.onClickAbsensiEkskul}>
                  <img src={budgeting} alt="AbsensiEkskul" />
                  <h3>Absensi Ekskul</h3>
                </button>
              </div>
              <div className="akademi__list">
                <button onClick={this.onClickPengisianNilaiEkskul}>
                  <img src={budgeting} alt="PengisianNilaiEkskul" />
                  <h3>Pengisian Nilai Ekskul</h3>
                </button>
              </div> */}
            </div>
            )
                }
            })
          }
      </div>
      
    );
  }
}
Ekskul.propTypes = {
  history: PropTypes.object.isRequired,
};

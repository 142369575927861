import { connect } from 'react-redux';
import TambahAbsenPerbidangStudy from '../../../views/Student/absensi/TambahAbsenPerbidangStudy.student.view';
import { getStudent } from '../../../states/thunks/student.thunk';
import { getClasses , getParamOptions, getSubjectOptions, getParamOptionsAcademics, getClassesOptions, getPeriodsOptions} from '../../../states/thunks/options.thunk';
import { saveAddAttendanceStudyField, editAddAttendanceStudyField, getAddAttendanceStudyField, listStudentAddAttendanceStudyField } from '../../../states/thunks/academics.thunk';


function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    user: state.user || {},

  }
}
function mapDispatchToProps(dispatch) {
  return {
    handleGetClasses: payload => dispatch(getClasses(payload)),
    handleGetStudent: () => dispatch(getStudent()),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    getSubjectOptions: (payload) => dispatch(getSubjectOptions(payload)),
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
    handleSaveAddAttendanceStudyField: (payload, goback) => dispatch(saveAddAttendanceStudyField(payload, goback)),
    handleEditAddAttendanceStudyField: (payload, goback) => dispatch(editAddAttendanceStudyField(payload, goback)), 
    handleGetAddAttendanceStudyField: payload => dispatch(getAddAttendanceStudyField(payload)),
    handleListStudentAddAttendanceStudyField: (payload, goback) => dispatch(listStudentAddAttendanceStudyField(payload, goback)),
    getClassesOptions: (payload) => dispatch(getClassesOptions(payload)),
    getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(TambahAbsenPerbidangStudy);
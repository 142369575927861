import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import StudentReportFilter from '../../../components/Filter/StudentReport.filter.component';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { ABSENSI_HARIAN } from '../../../constants/student.constant';
import { ABSENSI_HARIAN_TABLE } from '../../../constants/student.constant';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted, normalizeAmount } from '../../../utils/transformer.util';
import { Button } from '../../../components/base/index';
import {ESTIMASI_KENAIKAN_KLS_FORM} from '../../../constants/kurikulum/ulangan.constant';
import EstimasiTable from '../Analisa/components/EstimasiTable.component';
import KomposisiSiswaForm from './components/KomposisiSiswaForm.component';

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class KomposisiSiswa extends Component {
    constructor(props) {
      super(props);
      this._onFormChange = this._onFormChange.bind(this);
      this.onSubmit = this.onSubmit.bind(this);
      this._onDeleteList = this._onDeleteList.bind(this);
      this._onClickNext = this._onClickNext.bind(this);
  
      //this.deletepelajaran = this.deletepelajaran.bind(this);
      //this._onAddList = this._onAddList.bind(this);
      this._onClickAdd = this._onClickAdd.bind(this);
      this._getPeriodsOptions= this._getPeriodsOptions.bind(this);
        
      this.state = {
        form: {
          value: {
           
          },
          error: {
            pelajaran: '',
  
          },
        },
        param:{},
        
      };
    }

    componentDidMount() {
      const { content } = this.state;
    
      this._getPeriodsOptions();       
          
    }

    async _onSearchContent(params = {}) {
      const { filters } = this.state;
      this.setState({
        content: initialContent,
      }, async () => {
        try {
          const { handleListStudentRekapAttendance } = this.props;
          const {
            result = [], currentPage = 1, total = 0, totalPage = 1,
          } = await handleListStudentRekapAttendance({ ...params, organizations_id: 4, filters });
          // if (this.isMount) {
            this.setState({
              content: {
                loading: false,
                list: result,
                currentPage,
                total,
                totalPage,
              },
            });
          // }
        } catch (err) {
          // if (this.isMount) {
            this.setState({
              content: {
                loading: false,
                list: [],
                currentPage: 1,
                total: 1,
                totalPage: 1,
                error: true,
              },
            });
          }
        // }
      });
    }

    async _getPeriodsOptions() {
      const { getPeriodsOptions } = this.props;
      const res = await getPeriodsOptions();
      this.setState(prevState => ({
        ...prevState,
        param: {
          ...prevState.param,
          periods: res,
        },
      }));
      
    }
  
    // on formchange untuk form
    _onFormChange(event) {
      const {
        name,
        value,
        dataset,
        checked,
        type,
      } = event.target;
      const {
        inputType = 'text', inputArray = false, arrayPosition = 0,
        fieldName,
      } = dataset;
      this.setState((prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;
  
        if (inputType === 'number') {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          if (type === 'checkbox') {
            formattedValue = checked;
          }
          newList = prevState.form.value[fieldName];
          newListError = prevState.form.error[fieldName];
          newList[arrayPosition][name] = formattedValue;
          if (name === 'code_of_account') {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = '';
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray
                ? { [fieldName]: newListError }
                : { [name]: '' }),
            },
          },
        };
      });
    }
  
    _onClickAdd(data) {
      const { form } = this.state;
      const { pelajaran } = form.value;
      
      pelajaran.push({
        Kelas: '',
        Matpel:'',
        // divisi: '',
        nominal: 0,
        //period:'',
      });
      
      this.setState(prevState => ({
        form: {
          value: {
            ...prevState.form.value,
            pelajaran,
          },
          error: {
            ...prevState.form.error,
          }
        }
      }));
      console.log(pelajaran)
    }
  
    _onDeleteList(idx) {
      this.setState((prevState) => {
        const { pelajaran } = prevState.form.value;
        const list = prevState.form.value.pelajaran;
  
        list.splice(idx, 1);
        return {
          ...prevState,
          pelajaran: list
        };
      });
    }
  
    // on formchange untuk tabel
    onFormChange(event) {
      const {
        name,
        value,
        dataset,
      } = event.target;
      console.log(name)
      const { inputArray = false, arrayPosition = 0, inputType = 'text' } = dataset;
      this.setState((prevState) => {
        let formattedValue = value;
        let newpelajaran = [];
        if (inputType === 'number') {
          formattedValue = normalizeAmount(value);
        }
  
        if (inputArray) {
          newpelajaran = prevState.form.value.pelajaran;
          newpelajaran[arrayPosition][name] = value;
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray ? 
                { pelajaran: newpelajaran } : 
                { [name]: formattedValue }
              )
            },
            error: {
              ...prevState.form.error,
              form:'',
              [name]: '',
            },
          },
        };
      });
    }
  
    _onClickNext(){
        const {history} =this.props;
        history.push('/dashboard/kurikulum/analisa/komposisi-siswa-agama')
  
    }
    onSubmit(e) {
      e.preventDefault();
      // const { form} = this.state;
      const { form } = this.state;
      const { handleAddBudget, history, user } = this.props;
      const { workingUnit, prm_school_units_id } = user;
  
      let unit_id = prm_school_units_id;
  
      if (unit_id === null && workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      handleAddBudget(form.value, history.goBack, unit_id);
      // handleAddBudget(form.value, pelajaran, history.goBack, unit_id);
      //handleAddBudget(form.value, history.goBack, unit_id);
    }
  
    deletepelajaran(e) {
      var array = [...this.state.people]; // make a separate copy of the array
      var index = array.indexOf(e.target.value)
      if (index !== -1) {
        array.splice(index, 1);
        this.setState({people: array});
      }
    }
  
    render() {
      const { form, param } = this.state;
      return (
        <div className="add-budget">
          <form onSubmit={this.onSubmit}>
             
            <KomposisiSiswaForm
            form={form}
            param={param}
              // onAddList={this._onClickAdd}
              // list={pelajaran}
              // onDeleteList={this._onDeleteList}
              //deletepelajaran={this._deletepelajaran}
              onFormChange={this._onFormChange}
              //items={this.state.items} _handleDelete={this.delete.bind(this)}
            />

            <div className="manage-registration__footer">
                <Button
                  title="Cari"
                  onClick={this._onClickNext}
                />
              </div>
           
          </form>
        </div>
      );
    }
  
      //   render() {
      //     return (
      //         <ul>
      //             {this.props.items.map(item => (
      //                 <li key={item.id}>{item.text}<button onClick={this._handleDelete.bind(this, item.id)}>Delete</button></li>
      //             ))}
      //         </ul>
      //     );
      // } 
  }
KomposisiSiswa.propTypes = {
    
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
KomposisiSiswa.defaultProps = {
    
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};

import { connect } from 'react-redux';
import ManageBudgetRapbu from '../../../../views/Finance/Budgeting/Budget/ManageBudgetRapbu.finance.view';
import { getCoa, getCoaOptions } from '../../../../states/thunks/common.thunk';
import {
  getBudgetDetail, saveRecommendations, submitApproval, rejectRapbu,
  addBudgetDetail, editBudgetDetail, deleteBudgetDetail, getFlaponDetail,
} from '../../../../states/thunks/finance.thunk';
import {
  getDivisiOptions, getDivisiDetailListOptions, 
  getPlafondOptions,getTemaOptions,
} from '../../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    coa: state.coa,
    user: state.user,
    divisi: state.options.divisi,
    plafond: state.options.plafond,
    tema: state.options.tema,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetBudgetDetail: payload => dispatch(getBudgetDetail(payload)),
    handleSaveRecommendations: (payload, goBack) => dispatch(saveRecommendations(payload, goBack)),
    handleSubmitApproval: (payload, goBack) => dispatch(submitApproval(payload, goBack)),
    handleReject: (payload, goBack) => dispatch(rejectRapbu(payload, goBack)),
    handleGetCoaOptions: payload => dispatch(getCoaOptions(payload)),
    handleGetDivisiDetailsList: payload => dispatch(getDivisiDetailListOptions(payload)),
    handleGetDivisiOptions: payload => dispatch(getDivisiOptions(payload)),
    handleGetCoa: payload => dispatch(getCoa(payload)),
    handleGetBudgetDetail: payload => dispatch(getBudgetDetail(payload)),
    handleGetFlaponDetail: payload => dispatch(getFlaponDetail(payload)),
    handleGetPlafondOptions: payload => dispatch(getPlafondOptions(payload)),
    handleGetTemaOptions: payload => dispatch(getTemaOptions(payload)),
    handleAddBudgetDetail: (payload, status) => dispatch(addBudgetDetail(payload, status)),
    handleEditBudgetDetail: (payload, status) => dispatch(editBudgetDetail(payload, status)),
    handleDeleteBudgetDetail: (payload, status) => dispatch(deleteBudgetDetail(payload, status)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageBudgetRapbu);

import LembarObsHarian from '../../../views/Kurikulum/Ulangan/LembarObsHarian.view';
import { connect } from 'react-redux';
import { getStudent } from '../../../states/thunks/student.thunk';
import { editLembarObsHarian, saveLembarObsHarian, getLembarObsHarian } from '../../../states/thunks/academics.thunk'
import { getPeriodsOptions, getPrmAspectOptions, getKDMaterial } from '../../../states/thunks/options.thunk';


function mapStateToProps(state){
    return{
        classes: state.options.classes,
        result: state.result,
        user: state.user || {},
        levels: state.options.levels,
    }
}

function mapDispatchToProps(dispatch){
    return{
        handleGetStudent: () => dispatch(getStudent()),
        handleEditLembarObsHarian: (payload, goBack) => dispatch(editLembarObsHarian(payload, goBack)),
        handleSaveLembarObsHarian: (payload, goback) => dispatch(saveLembarObsHarian(payload, goback)),
        handleGetLembarObsHarian: payload => dispatch(getLembarObsHarian(payload)),
        getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
        getPrmAspectOptions: (payload) => dispatch(getPrmAspectOptions(payload)),
        getKDMaterial: (payload) => dispatch(getKDMaterial(payload)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps) (LembarObsHarian);
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import StudentReportFilter from '../../../components/Filter/StudentReport.filter.component';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { ABSENSI_HARIAN } from '../../../constants/student.constant';
import { ABSENSI_HARIAN_TABLE } from '../../../constants/student.constant';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted, normalizeAmount } from '../../../utils/transformer.util';
import { Button } from '../../../components/base/index';
import { SEARCH_FIELDS} from '../../../constants/Akademi/DataKelas/kelas.constant';
import { TABLE_PELANGGARAN_TABLE_FIELDS} from '../../../constants/Student/displiner.constant';
import  BuatPrestasiForm  from '../components/BuatPrestasiForm.component';

export default class BuatPrestasi extends Component {
    constructor(props) {
        super(props);
        this._onClickAdd = this._onClickAdd.bind(this);
        this._onClickEdit = this._onClickEdit.bind(this);
        this._onClickAddGroup = this._onClickAddGroup.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this._getAchievementGroupOptions= this._getAchievementGroupOptions.bind(this);
        this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
        const { user } = this.props;
        const { organizations_id } = user;
        this.state= {
          coa: {
            loading: true,
            list: [],
    
          },
          form: {
            value: {
              organizations_id: organizations_id

            },
            error: {},
          },
          param : {},
        };
      
      }
    
      componentDidMount() {
        const { location } = this.props;
        const { state = {} } = location;
        const {isEdit =  false, data} = state;
        const { id } = state.data || '';
        const paramTypes = ['levels', 'classes', 'classrooms'];
        if (isEdit && id) {
          this.setState({id:id})
          this._setForm(data.id);
        }
        paramTypes.forEach((type) => {
          this._getParamOptionsAcademics(type);
        });
        this._getAchievementGroupOptions();
      }
      
      async _setForm(id) {
        const { handleGetAchievementTable } = this.props;
        try {
          const payload = await handleGetAchievementTable({ id });
          this.setState({
            form: {
              value: {
                id: payload.id,
                name: payload.name,
                score: payload.score,
                sort: payload.sort,
                achievement_group_id: payload.achievement_group_id,
                achievement_in_class: payload.achievement_in_class,
                achievement_in_ekskul: payload.achievement_in_ekskul,
              },
              error: {
                // details: [],
              },
            },
          });
        } catch (err) {
          // err action
        }
      }

      async _getParamOptionsAcademics(type, filters={}) {
        const { getParamOptionsAcademics, user } = this.props;
        const {organizations_id} = user;
        if (type === 'classrooms' || type === 'classrooms') {
          filters.organizations_id = organizations_id;
        } else {
          delete filters.organizations_id;
        }
        const res = await getParamOptionsAcademics(filters, type);
        this.setState(prevState => ({
          ...prevState,
          param: {
            ...prevState.param,
            [type]: res,
          },
        }));
      }


      async _getAchievementGroupOptions() {
        const { getAchievementGroupOptions } = this.props;
        const res = await getAchievementGroupOptions();
        // console.log(res)
        this.setState(prevState => ({
          ...prevState,
          param: {
            ...prevState.param,
            prm_achievement_group: res,
          },
        }));
       
      }
      _onClickAdd() {
        const { history } = this.props;
        history.push('/dashboard/kesiswaan/displiner/buat-pelanggaran', { data: '' });
      }
    
      _onClickEdit(val) {
        const { history } = this.props;
        history.push('/dashboard/kesiswaan/pendaftaran', { data: val });
      }
    
      _onClickAddGroup() {
        const { history } = this.props;
        history.push('/dashboard/kesiswaan/displiner/buat-group');
      }
      _onFormChange(event) {
        const {
          name,
          value,
          dataset,
          checked,
          type,
        } = event.target;
        const {
          inputType = 'text', inputArray = false, arrayPosition = 0,
          fieldName,
        } = dataset;
        this.setState((prevState) => {
          let newList = [];
          let newListError = [];
          let formattedValue = value;
    
          if (inputType === 'number') {
            formattedValue = normalizeAmount(value);
          }
          if (inputArray) {
            if (type === 'checkbox') {
              formattedValue = checked;
            }
            newList = prevState.form.value[fieldName];
            newListError = prevState.form.error[fieldName];
            newList[arrayPosition][name] = formattedValue;
            if (name === 'code_of_account') {
              newList[arrayPosition].isCredit = value.type;
            }
            if (!isEmpty(newListError[arrayPosition])) {
              newListError[arrayPosition][name] = '';
            }
          }
          return {
            form: {
              value: {
                ...prevState.form.value,
                ...(inputArray
                  ? { [fieldName]: newList }
                  : { [name]: formattedValue }),
              },
              error: {
                ...prevState.form.error,
                ...(inputArray
                  ? { [fieldName]: newListError }
                  : { [name]: '' }),
              },
            },
          };
        });
      }
      
      async onSubmit() {
        const { history, handleSaveAchievementTable } = this.props;
        const { form } = this.state;
        const { value } = form;        
        let canSaveA = false;
        const dataForm = form.value;
        // if(dataForm.folder_code && dataForm.folder_name && dataForm.folder_description){
        //   canSaveA = true;
        // } else {
        //   canSaveA = false;
        // }
        // if (canSaveA == false){
        //   alert("Harap lengkapi data form. Bagian yang harus diisi ditandai dengan tanda bintang (*).");
        // } else {
          await handleSaveAchievementTable(value);
          history.push('/dashboard/kesiswaan/displiner/table-prestasi');
        // }
      }

      async onEdit() {
        const { history, handleEditAchievementTable } = this.props;
        const { form } = this.state;
        const { value } = form;
        const res = await handleEditAchievementTable(value);        
        history.push('/dashboard/kesiswaan/displiner/table-prestasi');
      }
    
    
      render() {
        const {form, param, content ={}} = this.state;
        const { handleGetKelas, handleDeleteKelas,coa } = this.props;
        return (
            <div className="add-budget">
        
            <form onSubmit={this.onSubmit}>
              <h1>Buat Jenis Prestasi</h1>
              <hr></hr>
              <BuatPrestasiForm
                param={param}
                form={form}
                onFormChange={this._onFormChange}
               
              />
              <div className="add-budget">
              {this.state.id?
                <Button
                  type="button"
                  title="Update"
                  onClick={this.onEdit}
                />:
                <Button
                  type="button"
                  title="Simpan"
                  onClick={this.onSubmit}
                />}
              </div>
          </form>
          </div>
        );
      }
}
BuatPrestasi.propTypes = {
    
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
BuatPrestasi.defaultProps = {
    
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};

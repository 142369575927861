import { connect } from 'react-redux';
import UploadDocument from '../../../views/Kurikulum/RencanaNilai/UploadDocument.view.js';
// import { uploadDocumentFile } from '../../../../states/thunks/finance.thunk';
import { uploadDocumentFile } from '../../../states/thunks/student.thunk';


function mapDispatchToProps(dispatch) {
  return {
    handleUploadDocumentFile: (payload, goBack) => dispatch(uploadDocumentFile(payload, goBack)),
  };
}

export default connect(null, mapDispatchToProps)(UploadDocument);

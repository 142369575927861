import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, map, isEqual } from "lodash";
import StudentReportFilter from "../../../components/Filter/StudentReport.filter.component";
import ListForm from "../../../components/ListForm/ListForm.component";
import ContentTable from "../../../components/ContentTable/ContentTable.component";
import { CATAT_HARIANSISWA } from "../../../constants/Student/displiner.constant";
import { validateRequiredFields } from "../../../utils/validation.util";
import {
  commaFormatted,
  normalizeAmount,
  formatData,
} from "../../../utils/transformer.util";
import { Button, Pagination } from "../../../components/base/index";
import CatatanKegiatan from "../components/CatatanKegiatan.components";
import CatatHarianSiswaForm from "../components/CatatHarianSiswaForm.components";
import { FOLDER__LIST_CATAT_HARIAN_SISWA_FIELDS } from "../../../constants/Student/student.constant";
import language from "../../../languages";

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class CatatHarianSiswa extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this._getParamOptions = this._getParamOptions.bind(this);
    this._renderButtons = this._renderButtons.bind(this);
    this._setForm = this._setForm.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this._getSubjectOptions = this._getSubjectOptions.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this._getPrmAspectOptions = this._getPrmAspectOptions.bind(this);
    this._getTeacherOptions= this._getTeacherOptions.bind(this);

    const { user } = this.props;
    const { organizations_id } = user;
    this.state = {
      page: 1,
      list: [],
      listAmount: 5,
      form: {
        value: {
          organizations_id,
        },
        error: {
          classrooms: "",
        },
      },
      param: {},
    };
  }

  componentDidMount() {
    const { location, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id } = school_unit;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";

    const paramTypes = ["levels", "classrooms"];
    if (isEdit && id) {
      this.setState({ id: id });
      this._setForm(data.id);
    }
    this.handleGetData();
    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
    this._getSubjectOptions({ filters: { units_id: [units_id] } });
    this._getPeriodsOptions();
    this._getStudentOptions();
    this._getPrmAspectOptions();
    this._getTeacherOptions();
  }

  async _getPrmAspectOptions() {
    const { getPrmAspectOptions } = this.props;
    const res = await getPrmAspectOptions();
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        prm_aspect: res,
      },
    }));
  }

  async _getSubjectOptions(filters = {}) {
    const { getSubjectOptions, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id, units_id } = school_unit;
    const res = await getSubjectOptions(filters);
    filters.units_id = units_id;

    // console.log(res)
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        subject: res,
      },
    }));
  }

  async _getStudentOptions(filters = {}) {
    const { getStudentOptions } = this.props;
    const res = await getStudentOptions(filters);
    // console.log(res)
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        student: res,
      },
    }));
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;
    if (type === "classrooms" || type === "classrooms") {
      filters.organizations_id = organizations_id;
      filters.levels_id = levels_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getPeriodsOptions(filters = {}) {
    const { getPeriodsOptions, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    const res = await getPeriodsOptions(filters);
    filters.organizations_id = organizations_id;

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  async _getTeacherOptions() {
    const { getTeacherOptions } = this.props;
    const res = await getTeacherOptions();
    // console.log(res)
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        teacher: res,
      },
    }));
   
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";
    // const { user } = this.props;
    // const { workingUnit } = user;
    // const { user: prevUser } = prevProps;
    // const { workingUnit: prevWorkingUnit } = prevUser;

    // if (!isEqual(workingUnit, prevWorkingUnit)) {
    //   this.handleGetData();
    // }
    if ((!isEdit || !id) && !isEqual(prevProps, this.props)) {
      this.handleGetData();
    }
  }

  _handleFormChange(event) {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          [name]: value,
        },
        error: {
          ...prevState.form.error,
          [name]: "",
        },
      },
    }));
  }

  onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this.handleGetData({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  _onSearch() {
    const { form } = this.state;
    const error = validateRequiredFields(form.value, ["year"]);
    this.setState((prevState) => ({
      form: {
        value: prevState.form.value,
        error,
      },
    }));
    if (isEmpty(error)) {
      this.setState({ filtered: true });
    }
  }
  _onFormChange(event) {
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          if (type === "checkbox") {
            formattedValue = checked;
          }
          newList = prevState.form.value[fieldName];
          newListError = prevState.form.error[fieldName];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {
        if (name === "classrooms_id") {
          this._getStudentOptions({
            filters: { classrooms_id: [value], organizations_id: 4 },
          });
        }
      }
    );
  }

  _onClickAdd(data) {
    const { form } = this.state;
    const { plafon } = form.value;

    plafon.push({
      tipePlafon: "",
      // divisi: '',
      nominal: 0,
      //period:'',
    });

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          plafon,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
    console.log(plafon);
  }

  // on formchange untuk tabel
  onFormChange(event) {
    const { name, value, dataset } = event.target;
    console.log(name);
    const {
      inputArray = false,
      arrayPosition = 0,
      inputType = "text",
    } = dataset;
    this.setState((prevState) => {
      let formattedValue = value;
      let newPlafon = [];
      if (inputType === "number") {
        formattedValue = normalizeAmount(value);
      }

      if (inputArray) {
        newPlafon = prevState.form.value.plafon;
        newPlafon[arrayPosition][name] = value;
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { plafon: newPlafon }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            form: "",
            [name]: "",
          },
        },
      };
    });
  }

  onSubmit(e) {
    e.preventDefault();
    const { history } = this.props;
    history.push(
      "/dashboard/administrasi/data-kelas/kelola-siswa-pindah-kelas"
    );
    // window.open("/dashboard/administrasi/data-kelas/kelola-siswa-pindah-kelas", '_blank');
  }
  onCetak() {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/laporan/cetak-buku-induk");
    // window.open("/dashboard/administrasi/data-kelas/kelola-siswa-pindah-kelas", '_blank');
  }

  _onClickNext() {
    window.scrollTo(0, 0);
    this.setState((prevState) => ({
      page: prevState.page + 1,
    }));
  }

  async _getParamOptions(type, filters = {}) {
    const { getParamOptions, user } = this.props;
    const { organizations_id } = user;

    if (type === "classes" || type === "classrooms") {
      filters.organizations_id = organizations_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptions(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }
  // async _onSearchContent(params = {}) {
  //   const { filters } = this.state;
  //   this.setState({
  //     content: initialContent,
  //   }, async () => {
  //     try {
  //       const { handleListStudentRekapAttendance } = this.props;
  //       const {
  //         result = [], currentPage = 1, total = 0, totalPage = 1,
  //       } = await handleListStudentRekapAttendance({ ...params, filters });
  //       // if (this.isMount) {
  //         this.setState({
  //           content: {
  //             loading: false,
  //             list: result,
  //             currentPage,
  //             total,
  //             totalPage,
  //           },
  //         });
  //       // }
  //     } catch (err) {
  //       // if (this.isMount) {
  //         this.setState({
  //           content: {
  //             loading: false,
  //             list: [],
  //             currentPage: 1,
  //             total: 1,
  //             totalPage: 1,
  //             error: true,
  //           },
  //         });
  //       }
  //     // }
  //   });
  // }
  async onSubmit() {
    const { history, handleSaveRecordDaily } = this.props;
    const { form } = this.state;
    const { value } = form;
    let canSaveA = false;
    const dataForm = form.value;
    // if(dataForm.folder_code && dataForm.folder_name && dataForm.folder_description){
    //   canSaveA = true;
    // } else {
    //   canSaveA = false;
    // }
    // if (canSaveA == false){
    //   alert("Harap lengkapi data form. Bagian yang harus diisi ditandai dengan tanda bintang (*).");
    // } else {
    await handleSaveRecordDaily(value);
    history.push("/dashboard/kesiswaan/displiner/catat-harian-siswa");
    history.go(0);
    // }
  }

  async handleGetData(payload = {}) {
    const { handleListRecordDaily, user } = this.props;
    const { school_unit, workingUnit, organizations_id } = user;
    const { levels_id, units_id } = school_unit;

    const res = await handleListRecordDaily({
      ...payload,
      units_id,
      organizations_id,
    });

    this.setState({
      list: res,
    });
  }

  async _setForm(id) {
    const { handleGetRecordDaily } = this.props;
    try {
      const payload = await handleGetRecordDaily({ id });
      this.setState({
        form: {
          value: {
            id: payload.id,
            period: payload.period,
            date: payload.date,
            classrooms_id: payload.classrooms_id,
            development_aspect_id: payload.development_aspect_id,
            nis: payload.nis,
            name: payload.name,
            order_of_activities: payload.order_of_activities,
            name_of_activities: payload.name_of_activities,
            description: payload.description,
            students_id: payload.students_id,
            teacher_id: payload.teacher_id,
            narasi: payload.narasi,
            hour: payload.hour,
            minute: payload.minute,
          },
          error: {
            // details: [],
          },
        },
      });
    } catch (err) {
      // err action
    }
  }

  async onEdit() {
    const { history, handleEditRecordDaily } = this.props;
    const { form } = this.state;
    const { value } = form;
    const res = await handleEditRecordDaily(value);
    history.push("/dashboard/kesiswaan/displiner/catat-harian-siswa");
    history.go(0);
  }
  onClickEdit(val) {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/displiner/catat-harian-siswa", {
      data: val,
      isEdit: true,
    });
    history.go(0);
  }

  _renderButtons(data) {
    const { user } = this.props;
    const button = {};

    return (
      <td>
        <div className="table__actions">
          <Button onClick={() => this.onClickEdit(data)} title="Edit" />
        </div>
      </td>
    );
  }

  render() {
    const { form, page, list, param } = this.state;
    const { plafon } = form.value;
    const { handleGetKelas, handleDeleteKelas, classrooms = {} } = this.props;
    return (
      <div className="manage-registration">
        <form onSubmit={this.onSubmit}>
          <div className="budget__title">
            <h1>Catat Harian Siswa</h1>
          </div>

          <div className="upload-student__content">
            <CatatHarianSiswaForm
              classrooms={classrooms}
              form={form}
              param={param}
              onFormChange={this._onFormChange}
            />

            <div className="manage-registration">
              <div className="accident__content">
                {this.state.id ? (
                  <Button type="button" title="Update" onClick={this.onEdit} />
                ) : (
                  <Button
                    type="button"
                    title="Simpan"
                    onClick={this.onSubmit}
                  />
                )}
              </div>
            </div>
          </div>
        </form>

        <div className="fund-request__content">
          <table className="table">
            <thead>
              <tr>
                {map(
                  FOLDER__LIST_CATAT_HARIAN_SISWA_FIELDS.label,
                  (field, idx) => (
                    <th key={`table_th_${idx}`}>{field}</th>
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {map(list.result, (data, idx) => (
                <tr key={`budget_row_${idx}`}>
                  {map(
                    FOLDER__LIST_CATAT_HARIAN_SISWA_FIELDS.value,
                    (field, fieldIdx) => {
                      if (fieldIdx === 0) {
                        return (
                          <td key={`table_index_${fieldIdx}_${idx}`}>
                            {idx + 1}
                          </td>
                        );
                      }
                      if (field.type === "link") {
                        return (
                          <td key={`table_${fieldIdx}_${idx}`}>
                            <a
                              href={"https://" + data.hyperlink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {data.hyperlink}
                            </a>
                          </td>
                          // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                        );
                      }
                      return (
                        <td
                          className={field.type === "number" ? "nominal" : ""}
                          key={`table_${fieldIdx}_${idx}`}
                        >
                          {formatData(data, field)}
                        </td>
                      );
                    }
                  )}
                  {this._renderButtons(data)}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="user-management__table-footer">
          <p className="user-management__page-info">
            {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${list.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${list.totalPage}`}
          </p>
          <Pagination
            totalPage={list.totalPage}
            currentPage={list.currentPage}
            onClick={this.onChangePage}
          />
        </div>
      </div>
    );
  }
}
CatatHarianSiswa.propTypes = {
  handleGetStudent: PropTypes.func,
  handleGetclassrooms: PropTypes.func,
  history: PropTypes.object.isRequired,
};
CatatHarianSiswa.defaultProps = {
  handleGetStudent: noop,
  HandleGetclassrooms: noop,
};

import React, {
    PureComponent, useState, useEffect, useReducer,
    useRef, useContext,
  } from 'react';
  import { successMessage, errorsMessage } from '../../utils/alert.util';
  import { normalizeAmount } from '../../utils/transformer.util';
  import {
    Select, Input, SearchSelect, Button, Textarea, Checkbox, Alert
  } from './';
  import { WorkflowContext } from '../../contexts/WorkflowContext';
  import { CanContextProvider } from '../../contexts/AuthContext';
  import { AlertContext } from '../../contexts/AlertContext';
  
  
  
  export default class BaseForm extends PureComponent {
    constructor(props) {
      super(props);
  
      this._getErrorMessage = this._getErrorMessage.bind(this);
      this._onFormChange = this._onFormChange.bind(this);
      this._getData = this._getData.bind(this);
      this._setForm = this._setForm.bind(this);
    }
  
    async _getData(id) {
      const { handleGetData } = this.props;
      const res = await handleGetData({ id });
      this._setForm(res);
    }
  
    _setForm(data) {
      this.setState(prevState => ({
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.value,
            data,
          },
        },
      }));
    }
  
  
    _getErrorMessage(field, key = null) {
      const { form } = this.state;
      const { error = {} } = form;
  
      if (key !== null) {
        if (typeof error[key] !== 'undefined') {
          return error[key][field] || '';
        }
        return '';
      }
      if (Array.isArray(field)) {
        let obj = { ...error };
        field.forEach((index) => {
          if (typeof obj[index] !== 'undefined') {
            obj = obj[index];
          } else {
            obj = '';
          }
        });
        return obj;
      }
      return error[field] || '';
    }
  
    async _onFormChange(event, callback) {
      const {
        name,
        value,
        dataset,
      } = event.target;
      const {
        inputType = 'text', arrayPosition = null, fieldName,
      } = dataset;
      const formattedValue = (inputType === 'number') ? normalizeAmount(value) : value;
  
      this.setState((prevState) => {
        const { form } = prevState;
        const { value: newValue, error: newError = {} } = form;
        let field = newValue[name] || '';
        let error = newError[name] || {};
  
        if (typeof fieldName !== 'undefined') {
          field = newValue[fieldName] || { [fieldName]: {} };
          error = newError[fieldName] || { [fieldName]: {} };
        }
  
        if (arrayPosition !== null) {
          if (!field[arrayPosition]) {
            field[arrayPosition] = {};
          }
  
          if (!error[arrayPosition]) {
            error[arrayPosition] = {};
          }
  
          field[arrayPosition][name] = formattedValue;
          error[arrayPosition][name] = '';
        } else {
          newValue[name] = formattedValue;
          error[name] = '';
        }
  
        return {
          ...prevState,
          form: {
            value: {
              ...prevState.form.value,
              ...newValue,
            },
            error: {
              ...prevState.form.error,
              ...newError,
            },
          },
        };
      }, () => {
        if (typeof callback === 'function') {
          callback.call(event);
        }
      });
    }
  }
  
  
  export const FormContext = React.createContext({
    state: {},
    dispatch: () => {},
  });
  
  function formReducer(state, action) {
    const { type, value, path = {} } = action;
    const { path: fieldPath = {}, name } = path;
    const { collection, key } = fieldPath;
    const newState = { ...state };
  
    if (collection) {
      if (!newState.value[collection]) {
        newState.value[collection] = {};
      }
    }
  
    switch (type) {
      case 'update':
        return { ...state, value: { ...state.value, [name]: value } };
      case 'delete':
        delete newState[path];
        return { ...newState };
      case 'addCollection':
        return {
          ...newState,
          value: {
            ...newState.value,
            [collection]: {
              ...newState.value[collection],
              ...value,
            },
          },
        };
      case 'updateCollection':
        return {
          ...newState,
          value: {
            ...newState.value,
            [collection]: {
              ...newState.value[collection],
              [key]: {
                ...state.value[collection][key],
                [name]: value,
              },
            },
          },
        };
      case 'updateState':
        return {
          ...state,
          value: {
            ...value
          }
        }
      default:
        return state;
    }
  }
  
  function useFormFieldHandler(init = {}) {
    const { state, dispatch } = useContext(FormContext);
    const {
      name = '', path = {},
    } = init;
  
    const [value, setValue] = useState('');
    const fieldPath = { path, name };
    const actionType = (path.collection && path.key) ? 'updateCollection' : 'update';
  
    useEffect(() => dispatch({ path: fieldPath, type: actionType, value }), [value]);
  
    const handleFieldChange = (e, isNumber = false) => {
      e.persist();
      const { target } = e;
      setValue(isNumber ? target.value : normalizeAmount(target.value));
    };
  
    const getDataAttributes = (datasetProp = {}) => {
      const datasets = {};
      Object.keys(datasetProp).forEach((key) => {
        Object.assign(datasets, { [`data-${key}`]: datasetProp[key] });
      });
    };
  
    const getFieldProps = () =>  {
      const {
        classNames,
        dataset,
        aria,
        value: propsValue = value,
        ...rest
      } = init;
      return {
        name,
        classNames,
        aria,
        value: propsValue,
        onChange: handleFieldChange,
        ...rest,
        ...getDataAttributes(dataset),
      };
    };
  
    return {
      handleFieldChange,
      getFieldProps,
      value,
    };
  }
  
  export function useForm({ data = {}, onChangeCallback }) {
    const [state, dispatch] = useReducer(formReducer, data);
  
    useEffect(() => {
      if (onChangeCallback) {
        onChangeCallback.call(state);
      }
    }, [state]);
  
    return {
      state,
      dispatch,
    };
  }
  
  export function Field(props) {
    const fieldEl = useRef(null);
    const {
      state, value: valueProp, type, name, disabled, data, placeholder, label, classNames,
      dataset, onChange,
    } = props;
    const { handleFieldChange, getFieldProps, value } = useFormFieldHandler({
      ...props
    });
  
    const onChangeHandler = (e) => {
      e.persist();
      const eventCopy = { ...e };
      handleFieldChange(e);
      if (onChange) {
        onChange(eventCopy);
      }
    };
  
    switch (type) {
      case 'searchSelect':
        return (
          <SearchSelect
            disabled={disabled}
            name={name}
            label={label}
            data={data}
            onChange={onChangeHandler}
            placeholder={placeholder || ''}
            value={valueProp || value}
            {...getFieldProps()}
          />
        );
      case 'select':
        return (
          <Select
            disabled={disabled}
            name={name}
            label={label}
            data={data}
            onChange={onChangeHandler}
            placeholder={placeholder || ''}
            value={valueProp || value}
          />
        );
      case 'textarea':
        return (
          <Textarea
            onChange={onChangeHandler}
            {...getFieldProps()}
          />
        );
      case 'checkbox':
        return (
          <Checkbox
            onChange={onChangeHandler}
            {...getFieldProps()}
          />
        );
      case 'number':
        return (
          <Input
            type="text"
            isNumber
            onChange={e => handleFieldChange(e, true)}
            {...getFieldProps()}
          />
        );
      default:
        return (
          <Input
            type="text"
            onChange={handleFieldChange}
            {...getFieldProps()}
          />
        );
    }
  }
  
  //if array
  //render table
  //else render fields
  //
  
  export function Form(props) {
    const {
      children,
      loadData,
      onSubmit,
      onSave,
      onReject,
      onFormChange,
      data,
      state,
      dispatch,
      subject,
      ...rest
    } = props;
  
    const {
      workflow, canSubmit, canReject,
    } = useContext(WorkflowContext);
  
    const { showAlert } = useContext(AlertContext);
  
    const [alertProps, setAlertProps] = useState({ show: false, type: 'warning', message: '' });
  
    const handleSave = async () => {
      if (onSave) {
        const res = await onSave(state.value);
        if (res.errors) {
          showAlert('error', errorsMessage(res));
        } else {
          dispatch({ type: 'updateState', value: res.data });
          showAlert('success', 'Data berhasil disimpan');
        }
      }
    };
  
    const handleSubmit = async () => {
      if (onSubmit) {
        const res = await onSubmit(state.value.id);
        dispatch({ type: 'updateState', value: res });
        showAlert('success', 'Data berhasil diajukan');
      }
    };
  
    const handleReject = async () => {
      if (onReject) {
        const res = await onReject(state.value.id);
        dispatch({ type: 'updateState', value: res });
        showAlert('success', 'Data telah ditolak');
      }
    };
  
    const buttons = () => {
      if (typeof workflow !== 'undefined') {
        return (
          <>
            <CanContextProvider
              subject={subject}
              action="create"
            >
              <Button
                fullsize={false}
                title="Simpan"
                icon="icon-floppy"
                onClick={handleSave}
              />
            </CanContextProvider>
            { canSubmit && (
              <>
                <CanContextProvider
                  subject={subject}
                  action="submit"
                >
                  <Button
                    fullsize={false}
                    title="Ajukan"
                    icon="icon-forward-1"
                    onClick={handleSubmit}
                  />
                </CanContextProvider>
                <CanContextProvider
                  subject={subject}
                  action="approve"
                >
                  <Button
                    fullsize={false}
                    title="Setujui"
                    icon="icon-forward-1"
                    onClick={handleSubmit}
                  />
                </CanContextProvider>
              </>
            )}
            {
              canReject && (
                <CanContextProvider
                  subject={subject}
                  action="reject"
                >
                  <Button
                    fullsize={false}
                    title="Setujui"
                    icon="icon-back"
                    onClick={handleReject}
                  />
                </CanContextProvider>
              )}
          </>
        );
      }
  
      return (
        <CanContextProvider
          subject={subject}
          action="create"
        >
          <Button
            fullsize={false}
            title="Simpan"
            icon="icon-floppy"
            onClick={handleSave}
          />
        </CanContextProvider>
      );
    };
  
    return (
      <FormContext.Provider value={{ state, dispatch }}>
        <Alert
          {...alertProps}
        />
        <form className="" onSubmit={onSubmit}>
          {children}
          <div className="buttons-wrapper">
            {buttons()}
          </div>
        </form>
      </FormContext.Provider>
    );
  }
  
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop, map, isEqual } from 'lodash';
import StudentReportFilter from '../../../components/Filter/StudentReport.filter.component';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted, normalizeAmount, formatData } from '../../../utils/transformer.util';
import { Button, Pagination, Select, Input } from '../../../components/base/index';
import language from '../../../languages';
import { BUAT_KELAS_EKSKUL_TABLE_FIELDS} from '../../../constants/Student/student.constant';
import { Link } from 'react-router-dom';

const padStr = (num) => {
  
    if (num !== "" && num !== null && typeof num !== 'undefined') {
      return num.toString().padStart(2, '0');
      
    }
    
    return null;
  }
const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};
export default class BuatKelasEkskul extends Component {
        constructor(props) {
          super(props);
          this._onFormChange = this._onFormChange.bind(this);
          this.onSubmit = this.onSubmit.bind(this);
          this._onClickNext = this._onClickNext.bind(this);
          this._onChangeListAmount = this._onChangeListAmount.bind(this);
          this._onSearchContent = this._onSearchContent.bind(this);
          this._onChangeFilter = this._onChangeFilter.bind(this);
          this._onChangeListAmount = this._onChangeListAmount.bind(this);
          this._onClickAdd = this._onClickAdd.bind(this);
          this.onCetak = this.onCetak.bind(this);
          this._getParamOptions = this._getParamOptions.bind(this);
          this._onChangePage = this._onChangePage.bind(this);
          this._getEkskulOptions= this._getEkskulOptions.bind(this);
          this._setForm = this._setForm.bind(this);
          this.onEdit = this.onEdit.bind(this);
          this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
          this._getPeriodsOptions= this._getPeriodsOptions.bind(this);

          const {user} = this.props;
          const { organizations_id, school_unit } = user;
          const { levels_id } = school_unit;
          this.state = {
            page: 1,
            content: initialContent,
            unchecked: {},
            filters:{},
            list:[],
            form: {
              value: {
                // hour: '',
                // minute: '',
                // academic_year: '',
                // period: '',
                levels_id,
                organizations_id,
                pembagian_kelasekskul: [
                  {
                    students_id: '',
                    classrooms_id: '',
                    levels_id: '',
                    ekskul_id: '',
                    description: '',
                    hour: '',
                    minute: '',
                    academic_year: '',
                    period: '',
                    teacher_id: '',
                  }
                ],
              },
              error: {
                pembagian_kelasekskul:''
              },
            },
            filtered: false,
            param:'',
          };
        }
      
        componentDidMount() {
          const {location, handleGetclassrooms } = this.props;
          
          const { state = {} } = location;
          const {isEdit =  false, data} = state;
          const { id } = state.data || '';
          const { content } = this.state;
          const paramTypes = ['classrooms','levels', 'classrooms'];
          
          paramTypes.forEach((type) => {
            this._getParamOptionsAcademics(type);
          });
          if (isEdit && id) {
            this.setState({id:id})
            this._setForm(data.id);
          } else
          
          if (isEmpty(content.list)) {
            this._onSearchContent({ page: 1 });
          }
          this._getTeacherEkskulOptions();
          this._getEkskulOptions();
          this._getPeriodsOptions();       

        }

        componentDidUpdate(prevProps) {
          const { location } = this.props;
          const { state = {} } = location;
          const {isEdit =  false, data} = state;
          const { id } = state.data || '';
          if ((!id) && !isEqual(prevProps, this.props)) {
            this._onSearchContent({ page: 1 });
          }
        }

        async _getParamOptionsAcademics(type, filters={}) {
          const { getParamOptionsAcademics, user } = this.props;
          const { organizations_id, school_unit } = user;
          const { levels_id } = school_unit;

          if (type === 'classrooms' || type === 'classrooms') {
            filters.organizations_id = organizations_id;
            filters.levels_id = levels_id;

          } else {
            delete filters.organizations_id;
          }
          const res = await getParamOptionsAcademics(filters, type);
          this.setState(prevState => ({
            ...prevState,
            param: {
              ...prevState.param,
              [type]: res,
            },
          }));
        }
      
       
        async _getParamOptions(type, filters={}) {
          const {getParamOptions, user} = this.props;
          const { organizations_id } = user;

          if (type === 'classes' || type === 'classrooms') {
            filters.organizations_id = organizations_id;
          } else {
            delete filters.organizations_id;
          }
          const res = await getParamOptions(filters, type);
          this.setState(prevState => ({
            ...prevState,
            param: {
              ...prevState.param,
              [type]: res,
            },
          }));
        }

        async _getPeriodsOptions(filters={}) {
          const { getPeriodsOptions ,user} = this.props;
          const { organizations_id, school_unit } = user;
          const { units_id, levels_id } = school_unit
          const res = await getPeriodsOptions(filters);
          filters.organizations_id = organizations_id;

          this.setState(prevState => ({
            ...prevState,
            param: {
              ...prevState.param,
              periods: res,
            },
          }));
          
        }

      
        // on formchange untuk form
       
        _onChangeListAmount(event) {
          const { keywords } = this.state;
          this.setState({
            listAmount: Number(event.target.value),
          });
          this._onSearchContent({ limit: event.target.value, keywords, page: 1 });
        }
          
        _onChangePage(page) {
          const { listAmount, keywords } = this.state;
          const offset = listAmount * (page - 1);
          // this.handleGetData({
          this._onSearchContent({
            limit: listAmount,
            keywords,
            offset,
            page,
          });
        }

        // _onFormChange(event) {
        //   const {
        //     name,
        //     value,
        //     dataset,
        //     checked,
        //     type,
        //   } = event.target;
        //   const {
        //     inputType = 'text', inputArray = false, arrayPosition = 0,
        //     fieldName,
        //   } = dataset;
        //   this.setState((prevState) => {
        //     let newList = [];
        //     let newListError = [];
        //     let formattedValue = value;
      
        //     if (inputType === 'number') {
        //       formattedValue = normalizeAmount(value);
        //     }
        //     if (inputArray) {
        //       if (type === 'checkbox') {
        //         formattedValue = checked;
        //       }
        //       newList = prevState.form.value[fieldName];
        //       newListError = prevState.form.error[fieldName];
        //       newList[arrayPosition][name] = formattedValue;
        //       if (name === 'code_of_account') {
        //         newList[arrayPosition].isCredit = value.type;
        //       }
        //       if (!isEmpty(newListError[arrayPosition])) {
        //         newListError[arrayPosition][name] = '';
        //       }
        //     }
        //     return {
        //       form: {
        //         value: {
        //           ...prevState.form.value,
        //           ...(inputArray
        //             ? { [fieldName]: newList }
        //             : { [name]: formattedValue }),
        //         },
        //         error: {
        //           ...prevState.form.error,
        //           ...(inputArray
        //             ? { [fieldName]: newListError }
        //             : { [name]: '' }),
        //         },
        //       },
        //     };
        //   });
        // }
      
        _onClickAdd(data) {
          const { form } = this.state;
          const { plafon } = form.value;
          
          plafon.push({
            tipePlafon: '',
            // divisi: '',
            nominal: 0,
            //period:'',
          });
          
          this.setState(prevState => ({
            form: {
              value: {
                ...prevState.form.value,
                plafon,
              },
              error: {
                ...prevState.form.error,
              }
            }
          }));
          console.log(plafon)
        }
      
       
      
        // on formchange untuk tabel
        _onFormChange(event) {
          const { user } = this.props;
          const { organizations_id } = user;
          const {
            name,
            value,
            dataset,
            checked,
            type,
          } = event.target;
          const {
            inputType = 'text', inputArray = false, arrayPosition = 0,
            fieldName,
          } = dataset;
          this.setState((prevState) => {
            let newList = [];
            let newListError = [];
            let formattedValue = value;
      
            if (inputType === 'number') {
              formattedValue = normalizeAmount(value);
            }
            if (inputArray) {
              if (type === 'checkbox') {
                formattedValue = checked ? value : '';
              }
              newList = prevState.form.value[fieldName];
              newListError = prevState.form.error[fieldName] || [];
              newList[arrayPosition][name] = formattedValue;
              if (name === 'code_of_account') {
                newList[arrayPosition].isCredit = value.type;
              }
              if (!isEmpty(newListError[arrayPosition])) {
                newListError[arrayPosition][name] = '';
              }
            }
            return {
              form: {
                value: {
                  ...prevState.form.value,
                  ...(inputArray
                    ? { [fieldName]: newList }
                    : { [name]: formattedValue }),
                },
                error: {
                  ...prevState.form.error,
                  ...(inputArray
                    ? { [fieldName]: newListError }
                    : { [name]: '' }),
                },
              },
            };
          }, () => {
            if (name === 'levels_id') {
              this._getParamOptionsAcademics( 'classrooms', {levels_id: value, organizations_id });
            }
            if (name === 'ekskul_id') {
              this._onSearchContent()
            }

            // if (name === 'ekskul_id') {
            //   this._getTeacherEkskulOptions({ filters: { ekskul_id: value }} );
            // }
          
          });
        }

        // onSubmit(e) {
        //   e.preventDefault();
        //   const {history} = this.props;
        //   history.push('/dashboard/administrasi/data-kelas/kelola-siswa-pindah-kelas');
        //   // window.open("/dashboard/administrasi/data-kelas/kelola-siswa-pindah-kelas", '_blank');
        // }
        onCetak() {
          const { history } = this.props;
         history.push('/dashboard/kesiswaan/laporan/cetak-buku-induk');
          // window.open("/dashboard/administrasi/data-kelas/kelola-siswa-pindah-kelas", '_blank');
        }
      
        
        _onClickNext() {
            window.scrollTo(0, 0);
            this.setState(prevState => ({
              page: prevState.page + 1,
            }));
          }

          _onChangeFilter(e) {
            const { target } = e;
            const { value, name } = target;
            this.setState(prevState => ({
              ...prevState,
              filters: {
                ...prevState.filters,
                [name]: value,
              },
            }), () => this._onSearchContent());
          }

          async onSubmit() {
            const { history, handleSaveClassEkskul } = this.props;
            const { form } = this.state;
            const { value } = form;        
            let canSaveA = false;
            const dataForm = form.value;
            // if(dataForm.name && dataForm.publish_date && dataForm.tgl_selesai && dataForm.levels && dataForm.classrooms){
            //   canSaveA = true;
            // } else {
            //   canSaveA = false;
            // }
            // if (canSaveA == false){
            //   alert("Harap lengkapi data form. Bagian yang harus diisi ditandai dengan tanda bintang (*).");
            // } else {
              await handleSaveClassEkskul(value);
              history.push('/dashboard/kesiswaan/ekstrakulikuler/pembagian-kelas-ekskul');
            // }
          }

          async _getTeacherEkskulOptions(filters={}) {
            const { getTeacherEkskulOptions } = this.props;
            const res = await getTeacherEkskulOptions(filters={});
            // console.log(res)
            this.setState(prevState => ({
              ...prevState,
              param: {
                ...prevState.param,
                teacher_ekskul: res,
              },
            }));
           
          }


          async _onSearchContent(params = {}) {
            const { form } = this.state;
            const { value } = form;
              try {
                const { handleListClassStudentEkskul, user } = this.props;
                const { school_unit, workingUnit, id, user_group, organizations_id } = user;
                const { levels_id, units_id } = school_unit;

                const result = await handleListClassStudentEkskul({ ...params, organizations_id, units_id,  teacher_id: value.teacher_id, ekskul_id: value.ekskul_id });
                // if (this.isMount) {
                  if (result) {
                    result.forEach(item => {
                      if (!item.description) {
                        item.description = '';
                      }
                    });
                  } console.log(result)
                  this.setState(prevState => ({
                    ...prevState,
                    form: {
                      ...prevState.form,
                      value: {
                        ...prevState.form.value,
                        pembagian_kelasekskul: result,
                      }
                    }
                  }));
                  
                // }
              } catch (err) {
                // if (this.isMount) {
                  this.setState(prevState => ({
                    ...prevState,
                    form: {
                      ...prevState.form,
                      value: {
                        ...prevState.form.value,
                        pembagian_kelasekskul: [],
                      }
                    }
                  }));
                  
              }
              // }
          }
      
          async _getEkskulOptions() {
            const { getEkskulOptions } = this.props;
            const res = await getEkskulOptions();
            // console.log(res)
            this.setState(prevState => ({
              ...prevState,
              param: {
                ...prevState.param,
                ekskul: res,
              },
            }));
           
          }

          async onEdit() {
            const { history, handleEditClassEkskul } = this.props;
            const { form } = this.state;
            const { value } = form;
            const res = await handleEditClassEkskul(value);        
            history.push('/dashboard/kesiswaan/ekstrakulikuler/pembagian-kelas-ekskul');
          }


          async _setForm(id) {
            const { handleGetClassEkskul } = this.props;
            try {
              const payload = await handleGetClassEkskul({ id });
           
          // console.log(payload)
              this.setState({
                // filters: {
                //   classrooms_id: payload.classrooms_id,
                //   period: payload.period,
                //   date: payload.date,
                // },
                form: {
                  value: {
                    id: payload.id,
                    academic_year: payload.academic_year,
                    period: payload.period,
                    levels_id: payload.levels_id,
                    teacher_id: payload.teacher_id,
                    hour: payload.hour,
                    minute: payload.minute,
                    pembagian_kelasekskul: map(payload.pembagian_kelasekskul, detail => ({
                      classrooms_id: detail.classrooms_id,
                      student_name: detail.student_name,
                      name: detail.name,
                      nis: detail.nis,
                      ekskul_id: detail.ekskul_id,
                      students_id: detail.students_id,
                      description: detail.description,
                      teacher_id: detail.teacher_id,
                     
                    })),
                  },
                  error: {
                    pembagian_kelasekskul: [],
                  },
                },
              });
            } catch (err) {
              // err action
            }
          }

          
        render() {
          const { form, page, content, param, filters } = this.state;
          const {value} =  form;
          const {
            ekskul_id = '',
            teacher_id = '',
          } = filters;
          const { handleGetKelas, handleDeleteKelas,onFormChange,classrooms={}, location } = this.props;
          const { state = {} } = location;
          const {pembagian_kelasekskul} = form.value;
          
          const academic_year = [
            { label: '2019', value: '2019'},
            { label: '2020', value: '2020'},
            { label: '2021', value: '2021'},
          ]
          const periods = [
            { label: 'Genap', value: 'genap'},
            { label: 'Ganjil', value: 'ganjil'},
          ]
          const hour = [
            { label: '00', value: '00'}, { label: '01', value: '01'}, { label: '02', value: '02'}, { label: '03', value: '03'}, { label: '04', value: '04'}, { label: '05', value: '05'}, { label: '06', value: '06'}, { label: '07', value: '07'}, { label: '08', value: '08'}, { label: '09', value: '09'},
            { label: '10', value: '10'}, { label: '11', value: '11'}, { label: '12', value: '12'}, { label: '13', value: '13'}, { label: '14', value: '14'}, { label: '15', value: '15'}, { label: '16', value: '16'}, { label: '17', value: '17'}, { label: '18', value: '18'}, { label: '19', value: '19'},
            { label: '20', value: '20'}, { label: '21', value: '21'}, { label: '22', value: '22'}, { label: '23', value: '23'},
          ]
          const minute = [
            { label: '00', value: '00'}, { label: '01', value: '01'}, { label: '02', value: '02'}, { label: '03', value: '03'}, { label: '04', value: '04'}, { label: '05', value: '05'}, { label: '06', value: '06'}, { label: '07', value: '07'}, { label: '08', value: '08'}, { label: '09', value: '09'},
            { label: '10', value: '10'}, { label: '11', value: '11'}, { label: '12', value: '12'}, { label: '13', value: '13'}, { label: '14', value: '14'}, { label: '15', value: '15'}, { label: '16', value: '16'}, { label: '17', value: '17'}, { label: '18', value: '18'}, { label: '19', value: '19'},
            { label: '20', value: '20'}, { label: '21', value: '21'}, { label: '22', value: '22'}, { label: '23', value: '23'}, { label: '24', value: '24'}, { label: '25', value: '25'}, { label: '26', value: '26'}, { label: '27', value: '27'}, { label: '28', value: '28'}, { label: '29', value: '29'},
            { label: '30', value: '30'}, { label: '31', value: '31'}, { label: '32', value: '32'}, { label: '33', value: '33'}, { label: '34', value: '34'}, { label: '35', value: '35'}, { label: '36', value: '36'}, { label: '37', value: '37'}, { label: '38', value: '38'}, { label: '39', value: '39'},
            { label: '40', value: '40'}, { label: '41', value: '41'}, { label: '42', value: '42'}, { label: '43', value: '43'}, { label: '44', value: '44'}, { label: '45', value: '45'}, { label: '46', value: '46'}, { label: '47', value: '47'}, { label: '48', value: '48'}, { label: '49', value: '49'},
            { label: '50', value: '50'}, { label: '51', value: '51'}, { label: '52', value: '52'}, { label: '53', value: '53'}, { label: '54', value: '54'}, { label: '55', value: '55'}, { label: '56', value: '56'}, { label: '57', value: '57'}, { label: '58', value: '58'}, { label: '59', value: '59'},
          ]
          const jenis_ekskul = [
            { label: '2019', value: '1'},
            { label: '2020', value: '2'},
            { label: '2021', value: '3'},
          ]
          const padStr = (num) => {
            // if (num) 
            // num.length > 0
            // num !==
            if (num !== "" && num !== null && typeof num !== 'undefined') {
              return num.toString().padStart(2, '0');
              // if (num < 10) {
              //   return "0" + num;
              // }
              // return num;
            }
            
            return null;
          }
          return (
            <div className="manage-registration">
              <div className="absensi-rekap__custom-form">
                <div className="absensi-rekap__custom-form-row">
                  <div className="absensi-rekap__custom-form-row__field">
                  <Select
                  label="Tahun Ajaran"
                  name="academic_year"
                  onChange={this._onFormChange}
                  placeholder="Pilihan"
                  data={param.periods}
                  value={form.value.academic_year}
                  error={form.error.academic_year || ''}
                />
                  </div>
                  <div className="absensi-rekap__custom-form-column__field">
                  <Select
                    type="text"
                    name="levels_id"
                    label="Unit"
                    placeholder="Pilih Unit"
                    disabled
                    data={param.levels}
                    value={form.value.levels_id}
                    onChange={this._onFormChange}
                    error={form.error.levels_id || ''}
                  />
                  </div>
                </div>
                
                <div className="absensi-rekap__custom-form-row">
                    <div className="absensi-rekap__custom-form-row__field">
                  
                   <Select
                    name="period"
                    label="Periode"
                    placeholder="Pilih Periode"
                    onChange={this._onFormChange}
                    data={periods}
                    value={form.value.period}
                    error={form.error.period || ''}
                  />
                    </div>  
                  
                    <div className="absensi-rekap__custom-form-column__field">
                   
                  <Select
                      value={form.value.ekskul_id}
                      data={param.ekskul}
                      placeholder='Pilihan'
                      name='ekskul_id'
                      onChange={this._onFormChange}
                      label="Jenis Ekskul"
                      placeholder="Pilihan"
                    />
                    </div>
                    </div>
            <div className="data-pelanggaran__custom-form-row">
            <div className="data-pelanggaran__custom-form-data-row">  
          
            <Select
                      type="text"
                      name="teacher_id"
                      label="Nama Guru Ekskul"
                      placeholder="Pilihan"
                      data={param.teacher_ekskul}
                      onChange={this._onFormChange}
                      value={form.value.teacher_id}
                      error={form.error.teacher_id|| ''}
                    />
            </div>
            
            
            <div className="data-pelanggaran__custom-form-row__field">
            <Select
              name="hour"
              label="Jam"
              onChange={this._onFormChange}
              data={hour}
              placeholder="Pilih Jam"
              value={padStr(form.value.hour)}
              error={form.error.hour || ''}
            />
          <div className="data-pelanggaran__custom-form-row__field"></div>
            <Select
              name="minute"
              label="(menit)"
              onChange={this._onFormChange}
              data={minute}
              placeholder="Pilih Menit"
              value={padStr(form.value.minute)}
              error={form.error.minute || ''}
            />
            </div>
           </div>
                
                
            </div>
            <br/>
            
                


        
                <div className="fund-request__content">
      <table className="table">
        <thead>
          <tr>
            {
                map( BUAT_KELAS_EKSKUL_TABLE_FIELDS.label, (field, idx) => (
                  <th key={`table_th_${idx}`}>{field}</th>
              ))
              }
          </tr>
        </thead>
        <tbody>
          {
              map(pembagian_kelasekskul, (list, idx) => (
                <tr key={`budget_row_${idx}`}>
                  {
                  map( BUAT_KELAS_EKSKUL_TABLE_FIELDS.value, (field, fieldIdx) => {
                    if (fieldIdx === 0) {
                      return (
                        <td key={`table_index_${fieldIdx}_${idx}`}>{(idx + 1)}</td>
                      );
                    }
                    if (field.attribute === 'classrooms_id') {
                      return (
                        <td key={`table_${fieldIdx}_${idx}`}>
                        <Select
                        name="classrooms_id"
                        data-input-array={true}
                        data-array-position={idx}
                        arrayPosition={idx}
                        placeholder="Pilihan"
                        data-field-name='pembagian_kelasekskul'
                        onChange={this._onFormChange}
                        data={param.classrooms}
                        value={value.pembagian_kelasekskul[idx].classrooms_id ? value.pembagian_kelasekskul[idx].classrooms_id : ''}
                        // ref={this._setRef}
                      />  </td>
                          // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                      );
                    }
              
                    if (field.attribute === 'description') {
                      return (
                        <td key={`table_${fieldIdx}_${idx}`}><Input
                        type="text"
                        name="description"
                        data-input-array={true}
                        data-array-position={idx}
                        data-field-name='pembagian_kelasekskul'
                        onChange={this._onFormChange}
                        value={list.description}
                      />  </td>
                          // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                      );
                    }

                    return (
                      <td className={(field.type === 'number') ? 'nominal' : ''} key={`table_${fieldIdx}_${idx}`}>
                        {formatData(list, field)}
                      </td>
                    );
                  })
                  }
                </tr>
              ))
            }
        </tbody>
      </table>
    </div>
              
              <div className="student-list__button">
          {this.state.id?
              <Button
                type="button"
                title="Update"
                onClick={this.onEdit}
              />:
              <Button
                type="button"
                title="Simpan"
                onClick={this.onSubmit}
              />}
          </div>
          </div>

          
    
          );
        }
}
BuatKelasEkskul.propTypes = {
  handleGetclassrooms: PropTypes.func,  
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
BuatKelasEkskul.defaultProps = {
  handleGetclassrooms: noop,  
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};
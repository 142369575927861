import React,  { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isEqual, noop } from 'lodash';
import { Input, Select } from '../../../components/base/index';
import { GENDER } from '../../../variables/common.variable';
import { commaFormatted, normalizeAmount, manageGetRegistration } from '../../../utils/transformer.util';


export default class AlamatForm extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._filterOptions = this._filterOptions.bind(this);
    this.state = {
      original: {
        sub_districts: props.sub_districts || [],
        districts: props.districts || [],
        cities: props.cities || [],
        provinces: props.provinces || [],
      },
      options: {
        sub_districts: [],
        districts: [],
        cities: [],
        provinces: [],
      }
    };
    this.updated = false;
  }

  componentDidUpdate({provinces: prevP, cities: prevC, sub_districts: prevS, districts: prevD }) {
    const { provinces = [], cities = [], sub_districts = [], districts = [] } = this.props;
    if (
      JSON.stringify(provinces) !== JSON.stringify(prevP) 
      || JSON.stringify(cities) !== JSON.stringify(prevC)
      || JSON.stringify(districts) !== JSON.stringify(prevD)
      || JSON.stringify(sub_districts) !== JSON.stringify(prevS)
    ) {
      this.setState({
        original: {
          provinces,
          cities,
          districts,
          sub_districts
        },
        options: {
          provinces,
          cities,
          districts,
          sub_districts
        }
      });
    }
  }

  _onFormChange(ev) {
    const { onChange } = this.props;
    onChange(ev)
  }

  _filterOptions(name) {
    const { original } = this.state;
    const { form = {} } = this.props;
    const { value = {} } = form;

    if (name === 'cities') {
      return original.cities.filter(o => o.data.provinces_id == value.provinces_id);
    } else if (name === 'districts') {
      return original.districts.filter(o => o.data.cities_id == value.cities_id);
    } else if (name === 'sub_districts') {
      return original.sub_districts.filter(o => o.data.districts_id == value.districts_id);
    } else {
      return original.provinces;
    }
  }

  render(){
    const { options, original } = this.state;
    const { provinces, sub_districts, districts, cities } = options;
    const { form, idx, onChange, classes, param, param2 } = this.props;
    return (
      <div className="manage-registration__custom-form">
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="number"
              name="entrance_year"
              label="Tahun Masuk"
              onChange={this._onFormChange}
              value={form.value.entrance_year}
              error={form.error.entrance_year || ''}
            />
             <div className="manage-registration__custom-form-row-datadiri">
             <Select
                name="years_of_education"
                label="Tahun Pendidikan"
                onChange={this._onFormChange}
                data={param.years_educations}
                placeholder={'Pilihan'}
                value={form.value.years_of_education}
                error={form.error.years_of_education || ''}
              />
             </div>
          </div>
          <div className="manage-registration__custom-form-column__field">
            <Select
                name="classes_id"
                label="Kelas"
                onChange={this._onFormChange}
                value={form.value.classes_id }
                placeholder="Pilihan"
                data={param.classes}
                error={form.error.classes_id || ''}
              />
          </div>
          </div>
            <div className="manage-registration__custom-form-row">
              <div className="manage-registration__custom-form-row__field">
                <Input
                  type="text"
                  name="address"
                  label="Alamat Tempat Tinggal"
                  onChange={this._onFormChange}
                  value={form.value.address}
                  error={form.error.address || ''}
                />
              </div>
              <div className="manage-registration__custom-form-column__field">
                <Select 
                  // name="provinces_id"
                  name="provinces_id"
                  // inputArray
                  // arrayPosition={idx}
                  label="Propinsi"
                  onChange={this._onFormChange}
                  placeholder="Pilihan"
                  value={form.value.provinces_id}
                  error={form.error.provinces_id}
                  data={this._filterOptions('provinces')}
                //   error={form.error.tempatLahirSaudara1 || ''}

                />
                {/* <Input
                  type="text"
                  name="provinces_id"
                  label="Propinsi"
                  onChange={this._onFormChange}
                  value={form.value.provinces_id } 
                  error={form.error.provinces_id || ''}
                /> */}
              </div>
            </div>

          {form.value.provinces_id? 
          <div>
            <div className="manage-registration__custom-form-row">
              <div className="manage-registration__custom-form-row__field">
              <Select
                name="cities_id"
                label="Kabupaten/Kota"
                onChange={this._onFormChange}
                placeholder="Pilihan"
                value={form.value.cities_id }
                error={form.error.cities_id || ''}
                data={this._filterOptions('cities')}
              />
            </div>
            
             
            <div className="manage-registration__custom-form-column__field">
              {/* <Select
                name="districts_id"
                label="Kecamatan"
                onChange={this._onFormChange}
                data={districts.list}
                style={{backgroundColor: 'grey'}}
                disabled
                placeholder="Pilihan"
                value={form.value.districts_id}
              /> */}
            </div>
          </div>
          {form.value.cities_id?
           <div className="manage-registration__custom-form-row">
           <div className="manage-registration__custom-form-row__field">
           {/* <Select
             name="cities_id"
             label="Kabupaten/Kota"
             onChange={this._onFormChange}
             placeholder="Pilihan"
             value={form.value.cities_id}
             data={cities.list}
           /> */}
         </div>
         
          
         <div className="manage-registration__custom-form-column-city__field">
           <Select
             name="districts_id"
             label="Kecamatan"
             onChange={this._onFormChange}
             data={this._filterOptions('districts')}
             placeholder="Pilihan"
             value={form.value.districts_id }
             error={form.error.districts_id || ''}
           />
         </div>
       </div>
           : ''}

        {form.value.districts_id?
        <div>
          <div className="manage-registration__custom-form-row">
              <Select
                name="sub_districts_id"
                label="Kelurahan"
                onChange={this._onFormChange}
                data={this._filterOptions('sub_districts')}
                placeholder="Pilihan"
                value={form.value.sub_districts_id }
                error={form.error.sub_districts_id || ''}
              />
            </div> 
        </div>:""}
        </div>
        :""
          }
        </div>
    );
}
  
}
AlamatForm.propTypes = {
//   onFormChange: PropTypes.func,
idx: PropTypes.number.isRequired,
  form: PropTypes.object.isRequired,
  handleGetProvinces: PropTypes.func,
  onChange: PropTypes.func, 
  list: PropTypes.array.isRequired,
  handleGetCities:PropTypes.array,
  provinces : PropTypes.array,
  districts: PropTypes.array,
  sub_districts: PropTypes.array,
  classes: PropTypes.array,
};
AlamatForm.defaultProps = {
//   onFormChange: noop,
onChange: noop,
provinces: [],
cities_id: [],
districts_id: [],
sub_districts: [],
classes: [],
};

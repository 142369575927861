import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Input, Select, FileInput, SearchSelect } from '../../../components/base/index';
import { GENDER } from '../../../variables/common.variable';


export default class CatatSiswaKeluarForm2 extends Component {
   constructor(props){
       super(props);
       this._onFormChange = this._onFormChange.bind(this);
   
    this.state ={
        form: {
            values: {
                classrooms: '',
                nis:'',
                studentname: '',
                tanggal:'',
                statusmutasi: '',
                nosuratpindah: '',
                tatusabsen:'',
            },
            error: {
                classrooms: '',
                nis:'',
                studentname: '',
                tanggal:'',
                statusmutasi: '',
                nosuratpindah: '',
                statusabsen:'',
            }
        }
    }
    }

   _onFormChange(e) {
    const { onChange } = this.props;
    let change = {}
    change[e.target.name] = e.target.value
    this.setState(change , () => { console.log(this.state) })

    onChange(e);
  }

render(){
    const { onChange, form, classrooms, levels, onFormChange, param } = this.props;
    const status_mutasi = [
      { label: 'Dikeluarkan Dari Sekolah Ini', value: '1'},
      { label: 'Keluar Dari Sekolah Ini', value: '2'},
      { label: 'Pindah Dari Sekolah Ini', value: '3'},
    ]
    const status_absen = [
      { label: 'Tetap DItampilkan Di Absen (dengan tanda *)', value: '1'},
      { label: 'Tidak Perlu Ditampilkan DI Absen', value: '2'},
    ]
    return (
      <div className="absensi-rekap__custom-form">
        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
{/*           
          <Input
              type="text"
              name="nis"
              label="NIS/Siswa"
              onChange={onFormChange}
              value={form.value.nis || ''}
              error={form.error.nis || ''}
            /> */}
            <Select
              name="classrooms_id"
              label="Kelas"
              onChange={onFormChange}
              data={param.classrooms}
              placeholder={'Pilihan'}
              value={form.value.classrooms_id}
              error={form.error.classrooms_id || ''}
            />
            </div>
          <div className="absensi-rekap__custom-form-column__field">
          <Select
              name="students_id"
              label="Nama Siswa"
              onChange={onFormChange}
              data={param.student}
              placeholder={'Pilihan'}
              value={form.value.students_id}
              error={form.error.students_id || ''}

            />
         
          </div>
      </div>
          
      <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
              
          {/* <SearchSelect
              noMargin
              async={false}
              name="students_id"
              list={param.student}
              inputArray
              onClick={onFormChange}
              placeholder="Pilih Nama Siswa"
              value={form.value.students_id}
              // labelName="label"
              // valueName="value"
              rightIcon="icon-search"
              label= "Nama Siswa"
              /> */}

            <Select
              name="student_mutations_id"
              label="Status Mutasi"
              data={status_mutasi}
              placeholder={'Pilihan'}
              onChange={onFormChange}
              value={form.value.student_mutations_id}
              error={form.error.student_mutations_id || ''}
            />
            
          </div>  
         
          <div className="absensi-rekap__custom-form-column__field">
          <Input
            type="text"
              name="transfer_letter_number"
              label="Nomor Surat Pindah"
              onChange={onFormChange}
              //data={levels.list}
              placeholder="Pilihan"
              value={form.value.transfer_letter_number}
              error={form.error.transfer_letter_number || ''}
            />
         
          </div>
      </div>
      <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
          <Input
                type="date"
                name="date"
                label="Tanggal"
                onChange={onFormChange}
                value={form.value.date}
                error={form.error.date || ''}
              />
          </div>
          <div className="absensi-rekap__custom-form-column__field">
          <Input
            type="text"
            name="transfer_to"
            label="Keterangan Pindah Ke"
            onChange={onFormChange}
            value={form.value.transfer_to}
            error={form.error.transfer_to || ''}
          />
            
            </div>
      </div>
      {/* <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
          <Select
                name="student_attendance_status_id"
                label="Status Absen"
                onChange={onFormChange}
                data={status_absen}
                placeholder={"Pilihan"}
                value={form.value.student_attendance_status_id}
                error={form.error.student_attendance_status_id || ''}
              />
          </div>
          <div className="absensi-rekap__custom-form-column__field">
            
           
            </div>
      </div> */}
  </div>
          
          
          
   
     
    );
}
  
}
CatatSiswaKeluarForm2.propTypes = {
  onChange: PropTypes.func,
  onFormChange: PropTypes.func,
  form: PropTypes.object.isRequired,
  classrooms: PropTypes.array,
  levels: PropTypes.array,
};
CatatSiswaKeluarForm2.defaultProps = {
  onChange: noop,
  onFormChange: noop,
  classrooms: [],
  levels: [],
};
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop, map } from 'lodash';
import StudentReportFilter from '../../../../components/Filter/StudentReport.filter.component';
import ListForm from '../../../../components/ListForm/ListForm.component';
import ContentTable from '../../../../components/ContentTable/ContentTable.component';
import { validateRequiredFields } from '../../../../utils/validation.util';
import { commaFormatted, normalizeAmount } from '../../../../utils/transformer.util';
import { Input, Select, Button } from '../../../../components/base/index';
import LaporanKeuForm from './components/LaporanKeuForm.component';
import { LAPORAN_SPP_TABEL_FORM } from '../../../../constants/finance/spp/tambah-uang-sekolah.constant';
import TabelLaporan from './components/TabelLaporan.component';
export default class LaporanSPP extends Component {
    constructor(props) {
        super(props);
        this._onClickSubmit = this._onClickSubmit.bind(this);
        this._onSubmit = this._onSubmit.bind(this);
        this._onSubmit2 = this._onSubmit2.bind(this);
        this._onSubmit3 = this._onSubmit3.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
        this._getPeriodsOptions= this._getPeriodsOptions.bind(this);
          
        this.state= {
          coa: {
            loading: true,
            list: [],
    
          },
          form: {
            value: {},
            error: {},
          },
          param: {},
        };
      
      }

      componentDidMount() {
        const { content } = this.state;
      
        this._getPeriodsOptions();       
            
      }
    
      _onClickSubmit() {
        const { history } = this.props;
        history.push('/dashboard/kurikulum/laporan/daftar-nilai-siswa-ktsp');
      }

      _onSubmit() {
        const { history } = this.props;
        // history.push('/dashboard/keuangan/spp/laporan-keuangan/invoice-summary-report');
         history.push('/dashboard/keuangan/spp/laporan-keuangan/invoice-payment-report');
        // history.push('/dashboard/keuangan/spp/laporan-keuangan/invoice-outstading-report');
      }

      _onSubmit2() {
        const { history } = this.props;
        history.push('/dashboard/kurikulum/ulangan/pengisian-indikator-sikap');
      }
      _onSubmit3() {
        const { history } = this.props;
        history.push('/dashboard/kurikulum/laporan/psikomotorik-siswa');
      }

      async _getPeriodsOptions() {
        const { getPeriodsOptions } = this.props;
        const res = await getPeriodsOptions();
        this.setState(prevState => ({
          ...prevState,
          param: {
            ...prevState.param,
            periods: res,
          },
        }));
        
      }
    
    
      _onFormChange(event) {
        const {
          name,
          value,
          dataset,
          checked,
          type,
        } = event.target;
        const {
          inputType = 'text', inputArray = false, arrayPosition = 0,
          fieldName,
        } = dataset;
        this.setState((prevState) => {
          let newList = [];
          let newListError = [];
          let formattedValue = value;
    
          if (inputType === 'number') {
            formattedValue = normalizeAmount(value);
          }
          if (inputArray) {
            if (type === 'checkbox') {
              formattedValue = checked;
            }
            newList = prevState.form.value[fieldName];
            newListError = prevState.form.error[fieldName];
            newList[arrayPosition][name] = formattedValue;
            if (name === 'code_of_account') {
              newList[arrayPosition].isCredit = value.type;
            }
            if (!isEmpty(newListError[arrayPosition])) {
              newListError[arrayPosition][name] = '';
            }
          }
          return {
            form: {
              value: {
                ...prevState.form.value,
                ...(inputArray
                  ? { [fieldName]: newList }
                  : { [name]: formattedValue }),
              },
              error: {
                ...prevState.form.error,
                ...(inputArray
                  ? { [fieldName]: newListError }
                  : { [name]: '' }),
              },
            },
          };
        });
      }
      
    
      render() {
        const {form, param} = this.state;
        const { handleGetKelas} = this.props;
        return (
          <div className="student-list">
               <div className="budget__title">
                  <h1>Laporan SPP</h1>
                  <hr></hr>
                </div>
                  <LaporanKeuForm
                    form={form}
                    param={param}
                    onFormChange={this._onFormChange}
                  />
                <div className="manage-registration__footer">
                  <Button
                  onClick={this._onSubmit}
                  title="Cari"
                  />
                </div>
                <div className="cash-journal__content">
                  <table className ="table">
                  <TabelLaporan
                    form={form}
                    onFormChange={this._onFormChange}
                  />
                  <tbody>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td colSpan={11}>Total</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                  </table>
          
                  
        </div>
                      
          </div>
        );
      }
}
LaporanSPP.propTypes = {
    
  handleGetStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
LaporanSPP.defaultProps = {
    
  handleGetStudent: noop,
};

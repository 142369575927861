import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import logo from '../../assets/img/logo_ricci.png';
import { HOME_INFO } from '../../variables/home.variable';

class Home extends PureComponent {
  constructor(props) {
    super(props);
    // this.onClickAdd = this.onClickAdd.bind(this);
    // this.onClickAddFolder = this.onClickAddFolder.bind(this);
    // this.onClickEdit = this.onClickEdit.bind(this);
    // this._renderButtons = this._renderButtons.bind(this);
    // this.handleGetData = this.handleGetData.bind(this);
    // this.onChangePage = this.onChangePage.bind(this);

    this.state = {
      // content: initialContent,
      list: [],
      workflow: {},
      listAmount: 5,
    };
  }
  componentDidMount() {
    const { location, getLevels, getClasses, user } = this.props;
    const { school_unit } = user;
    const { levels_id } = school_unit;
    const paramTypes = ['classes','levels', 'classrooms'];

    
    
    // if (isEmpty(content.list)) {
    //   this._onSearchContent({ page: 1 });
    // }
    this.handleGetData();       
  }

  async handleGetData(payload = {}) {
    const { handleStudentCount, handleListAnnouncementStudent, user } = this.props;
    const { school_unit, workingUnit, id, user_group, profile, organizations_id } = user;
    const { units_id } = school_unit;

    // if(user_group.id === 2){
      const res = await handleStudentCount({...payload,organizations_id, units_id});
    this.setState({
      data_siswa: res,
    });

    // }

    
  }
  render() {
    const { list = {} } = this.state;
    const { user } = this.props;
    const { school_unit } = user;
    return (
      <div className="home">
        <img className="home__logo" src={logo} alt="" />
        <div className="home__info">
          {
            map(HOME_INFO, (list, index) => (
              <div key={`home__info__${index}`} className="home__info-list">
                <p>{list.label}</p>
                {
                  ((school_unit !== null)) && (
                    <p>: {school_unit[list.value]}</p>
                  )
                }
                {
                  ((school_unit === null)) && (
                    <p>PUSAT</p>
                  )
                }
              </div>
            ))
          }
        </div>
        <div>

        </div>
      </div>
    );
  }
}

Home.propTypes = {
  user: PropTypes.object.isRequired,
};

export default function App(props) {
  return (
    <Home {...props} />
  );
}

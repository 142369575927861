import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop, isEqual, map} from 'lodash';
import StudentReportFilter from '../../../components/Filter/StudentReport.filter.component';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted, normalizeAmount, formatData } from '../../../utils/transformer.util';
import { Button, Select, Pagination,SearchSelect , Input} from '../../../components/base/index';
import language from '../../../languages';
import SearchNisCatat from '../../Student/components/SearchNisCatat.component';
import { PINDAH_KELAS_LIST_TABLE_FIELDS } from '../../../constants/kurikulum/kurikulum.constant';

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class PindahKelas extends Component {
        constructor(props) {
          super(props);
          this._onSearchContent = this._onSearchContent.bind(this);
          this._onFormChange = this._onFormChange.bind(this);
          this.onSubmit = this.onSubmit.bind(this);
          this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
          this._getParamOptions = this._getParamOptions.bind(this);
          this._getClassesOptions= this._getClassesOptions.bind(this);
          this._onClickNext = this._onClickNext.bind(this);
          this.onClickAddGrafik = this.onClickAddGrafik.bind(this);
          this._renderButtons = this._renderButtons.bind(this);
          this._getStudentOptions = this._getStudentOptions.bind(this);
          this._onChangeFilter = this._onChangeFilter.bind(this);
          this._onChangePage = this._onChangePage.bind(this);
          this._onChangeListAmount = this._onChangeListAmount.bind(this);
          this.onClickEdit = this.onClickEdit.bind(this);
          this._setForm = this._setForm.bind(this);
          this._getClassroomsOptions = this._getClassroomsOptions.bind(this);
          this._getPeriodsOptions= this._getPeriodsOptions.bind(this);
                
          
          const { user } = this.props;
          const { organizations_id, school_unit } = user;
          const { units_id, levels_id } = school_unit;
      this.state = {
      content: initialContent,
      listAmount: 5,
      list: [],
      filters: {},
      form: {
        value: {
          levels_id,
          organizations_id,
          units_id,
          pindah_kelas: [
            {
              students_id: '',
              period: '',
              classrooms_id: '',
              date: '',
            }
          ],
        
        },
        error: {
          pindah_kelas: '',

        },
      },
      param:{},
    };
        }
      
       componentDidMount() {
          const { handleGetCodeOfAccount, location, handleGetClasses, handleGetLevels, user } = this.props;
          const { school_unit } = user;
          const { levels_id } = school_unit;
          const paramTypes = ['classes','levels'];
          const { content } = this.state;
          paramTypes.forEach((type) => {
            this._getParamOptionsAcademics(type);
            this._getStudentOptions();
            
          });
          if (isEmpty(content.list)) {
            this._onSearchContent({ page: 1 });
          }
         this._getClassroomsOptions();
         this._getClassesOptions({ filters: { levels_id: [levels_id] }});

          // handleGetClasses();
          // handleGetLevels();
          this._getPeriodsOptions();       

      
        }
      
       
        componentDidUpdate(prevProps) {
          // const { user } = this.props;
          // const { workingUnit } = user;
          // const { user: prevUser } = prevProps;
          // const { workingUnit: prevWorkingUnit } = prevUser;
      
          // if (!isEqual(workingUnit, prevWorkingUnit)) {
          //   this.handleGetData();
          // }
          if (!isEqual(prevProps, this.props)) {
            this._onSearchContent({ page: 1 });
          }
        }

        async _getPeriodsOptions(filters={}) {
          const { getPeriodsOptions ,user} = this.props;
          const { organizations_id, school_unit } = user;
          const { units_id, levels_id } = school_unit
          const res = await getPeriodsOptions(filters);
          filters.organizations_id = organizations_id;

          this.setState(prevState => ({
            ...prevState,
            param: {
              ...prevState.param,
              periods: res,
            },
          }));
          
        }

        _handleFormChange(event) {
            const {
              name,
              value,
            } = event.target;
            this.setState(prevState => ({
              form: {
                value: {
                  ...prevState.form.value,
                  [name]: value,
                },
                error: {
                  ...prevState.form.error,
                  [name]: '',
                },
              },
            }));
          }
          async _getStudentOptions(filters={}) {
            const { getStudentOptions } = this.props;
            const res = await getStudentOptions(filters);
            // console.log(res)
            this.setState(prevState => ({
              ...prevState,
              param: {
                ...prevState.param,
                student: res,
              },
            }));
            
          }
        
          _onSearch() {
            const { form } = this.state;
            const error = validateRequiredFields(form.value, ['year']);
            this.setState(prevState => ({
              form: {
                value: prevState.form.value,
                error,
              },
            }));
            if (isEmpty(error)) {
              this.setState({ filtered: true });
            }
          }


          async _getClassroomsOptions() {
            const { getClassroomsOptions } = this.props;
            const { form } = this.state;
            const { classes_id, levels_id, periods_id, classrooms_id } = form.value;
            const res = await getClassroomsOptions({
              classes_id: classes_id,
              levels_id: levels_id,
              periods_id: periods_id,
            });
        
            const selectedClassroom = res.find(item => item.value == classrooms_id); 
        
            // console.log(selectedClassroom, res, classrooms_id);
            this.setState(prevState => ({
              ...prevState,
              form: {
                ...prevState.form,
                value: {
                  ...prevState.form.value,
                  capacity: selectedClassroom ? selectedClassroom.attributes.capacity : '' 
                }
              },
              param: {
                ...prevState.param,
                classrooms: res,
              }
            }));
          }

          async _setForm(id) {
            const { handleGetClassDiv, user } = this.props;
            try {
              const { organizations_id } = user;
              const payload = await handleGetClassDiv({ id });
              const {classrooms, levels_id, classes_id} = payload;
              this._getClassesOptions({ filters: { levels_id: [levels_id] }} );
              this._getParamOptionsAcademics('classrooms', { classes_id: classes_id, organizations_id }  );
        
              this.setState({
               
                form: {
                  value: {
                    id: payload.id,
                    periods_id: payload.periods_id,
                    classrooms_id: payload.classrooms_id,
                    levels_id: payload.levels_id,
                    classes_id: payload.classes_id,
                    pindah_kelas: map(payload.pindah_kelas, detail => ({
                      name: detail.name,
                      homeroom_teacher: detail.homeroom_teacher,       
                      capacity: detail.capacity,          
                      classrooms_id: detail.classrooms_id,
                      nis: detail.nis,
                      students_id: detail.students_id,
                    })),
                  },
                  error: {
                    pindah_kelas: [],
                  },
                },
              });
            } catch (err) {
              // err action
            }
          }
        

         

          async _getParamOptions(type, filters={}) {
            const {getParamOptions} = this.props;
            const res = await getParamOptions(filters, type);
            this.setState(prevState => ({
              ...prevState,
              param: {
                ...prevState.param,
                [type]: res,
              },
            }));
          }

          async _getClassesOptions(filters={}) {
            const { getClassesOptions, user } = this.props;
            const {school_unit} = user;
            const { levels_id } = school_unit;
            filters.levels_id = levels_id;
            const res = await getClassesOptions(filters);
            // console.log(res)
            this.setState(prevState => ({
              ...prevState,
              param: {
                ...prevState.param,
                classes_prm: res,
              },
            }));
           
          }

          async onSubmit() {
            const { history, handleSaveStudentChange } = this.props;
            const { form } = this.state;
            const { value } = form;        
            let canSaveA = false;
            const dataForm = form.value;
              await handleSaveStudentChange(value);
              history.push('/dashboard/kesiswaan/laporan/daftar-siswa-kelas');
              // }
          }
          
          async _onSearchContent(params = {}) {
            const { filters, form } = this.state;
            const { value } = form;
              try {
                const { handleListStudentChangeStudent, user } = this.props;
                const { organizations_id, school_unit } = user;
                const { units_id } = school_unit;
                const result = await handleListStudentChangeStudent({ ...params, organizations_id, units_id, filters, classrooms_id: value.classrooms_id });
                const { classes = [], data = [] } = result;
                  this.setState(prevState => ({
                    ...prevState,
                    form: {
                      ...prevState.form,
                      value: {
                        ...prevState.form.value,
                        pindah_kelas: result,
                      }
                    }
                  }));
                  
                // }
              } catch (err) {
                // if (this.isMount) {
                  this.setState(prevState => ({
                    ...prevState,
                    form: {
                      ...prevState.form,
                      value: {
                        ...prevState.form.value,
                        pindah_kelas: [],
                      }
                    }
                  }));
                  
              }
              // }
          }
  
      
        _onChangeListAmount(event) {
          const { keywords } = this.state;
          this.setState({
            listAmount: Number(event.target.value),
          });
          this._onSearchContent({ limit: event.target.value, keywords, page: 1 });
        }

        _onChangeFilter(e) {
          const { target } = e;
          const { value, name } = target;
          this.setState(prevState => ({
            ...prevState,
            filters: {
              ...prevState.filters,
              [name]: value,
            },
          }), () => this._onSearchContent());
        }
        _onChangePage(page) {
          const { listAmount, keywords } = this.state;
          const offset = listAmount * (page - 1);
          // this.handleGetData({
          this._onSearchContent({
            limit: listAmount,
            keywords,
            offset,
            page,
          });
        }

      
        // on formchange untuk tabel
        _onFormChange(event) {
          const {
            name,
            value,
            dataset,
            checked,
            type,
          } = event.target;
          const {
            inputType = 'text', inputArray = false, arrayPosition = 0,
            fieldName,
          } = dataset;
          this.setState((prevState) => {
            let newList = [];
            let newListError = [];
            let formattedValue = value;
      
            if (inputType === 'number') {
              formattedValue = normalizeAmount(value);
            }
            if (inputArray) {
              if (type === 'checkbox') {
                formattedValue = checked;
              }
              newList = prevState.form.value[fieldName];
              newListError = prevState.form.error[fieldName];
              newList[arrayPosition][name] = formattedValue;
              if (name === 'code_of_account') {
                newList[arrayPosition].isCredit = value.type;
              }
              if (!isEmpty(newListError[arrayPosition])) {
                newListError[arrayPosition][name] = '';
              }
            }
            return {
              form: {
                value: {
                  ...prevState.form.value,
                  ...(inputArray
                    ? { [fieldName]: newList }
                    : { [name]: formattedValue }),
                },
                error: {
                  ...prevState.form.error,
                  ...(inputArray
                    ? { [fieldName]: newListError }
                    : { [name]: '' }),
                },
              },
            };
          }, () => {
            if (name === 'levels_id') {
              this._getClassesOptions({ filters: { levels_id: [value] }} );
            }
            if (name === 'classes_id' || name === 'periods_id') {
              this._getClassroomsOptions();
            }
            if (name === 'classrooms_id') {
              const param = this.state.param.classrooms;
              console.log(param)
              this.setState(prevState => ({
                ...prevState,
                form: {
                  ...prevState.form,
                  value: {
                    ...prevState.form.value,
                    capacity: param.find(i => i.value == value).attributes.capacity,
                  }
                }
              }));
            }
            
          });
        }
       
      
        onClickAddGrafik() {
          const { history } = this.props;
          history.push('/dashboard/kesiswaan/absensi-siswa/absensi-rekap/grafik', { isEdit: true });
        }
        
        _onClickNext() {
        const { history } = this.props;
        history.push('/dashboard/kesiswaan/laporan/cetak-laporan-8355');
      }

      onClickEdit(val) {
        const { history } = this.props;
        history.push('/dashboard/kesiswaan/absensi-siswa/absensi-harian', { data: val, isEdit: true });
      }

      _renderButtons(data) {
        const { user } = this.props;
        const { user_group } = user;
        const { permissions = [] } = user_group;
      
        return (
          <td>
            <div className="table__actions">
            
             
                 <Button
                 onClick={() => this.onClickEdit(data)}
                 title='Edit'
               />
                 
               {/* </div> */}
            {/* } */}
               
      
             
            </div>
          </td>
        );
      }

      async _getParamOptionsAcademics(type, filters={}) {
        const { getParamOptionsAcademics, user } = this.props;
        const { organizations_id } = user;
        if (type === 'classes' || type === 'classrooms') {
          filters.organizations_id = organizations_id;
        } else {
          delete filters.organizations_id;
        }
        const res = await getParamOptionsAcademics(filters, type);
    
        this.setState(prevState => ({
          ...prevState,
          param: {
            ...prevState.param,
            [type]: res,
          },
        }));
      }

      async _getParamOptions(type, filters={}) {
        const {getParamOptions, user} = this.props;
        const { organizations_id } = user;
        if (type === 'classes' || type === 'classrooms') {
          filters.organizations_id = organizations_id;
        } else {
          delete filters.organizations_id;
        }
        const res = await getParamOptions(filters, type);
        this.setState(prevState => ({
          ...prevState,
          param: {
            ...prevState.param,
            [type]: res,
          },
        }));
      }
      
  render() {
    
    const {param, form,
           page, list = {},
           selected, checkedAll, content, 
           filters, listAmount } = this.state;
    const {
      classes_id = '',
      students_id = '',
      classrooms_id2 = '',
    } = filters;
    const { classes = {}, levels = {}, tema = {}  } = this.props;
    const {value} = form
    const {pindah_kelas} = form.value;
    const academic_year = [
      { label: '2019', value: '2019'},
      { label: '2020', value: '2020'},
      { label: '2021', value: '2021'},
    ]

    return (
      <div className="absensi-rekap__custom-form">
      <div className="absensi-rekap__custom-form-row">
        <div className="absensi-rekap__custom-form-row__field">
    
     
          <Select
            name="periods_id"
            label="Tahun Ajaran"
            placeholder={'Pilihan'}
            onChange={this._onFormChange}
            data={ param.periods}
            value={form.value.periods_id}
            error={form.error.periods_id || ''}
          />

        </div>
        <div className="absensi-rekap__custom-form-row__field">
          <Select
              name="levels_id"
              label="Unit"
              disabled
              onChange={this._onFormChange}
              data={ param.levels}
              placeholder={'Pilihan'}
              value={form.value.levels_id}
              error={form.error.levels_id || ''}
            />
        </div>
      </div>
      <div className="absensi-rekap__custom-form-row">
        <div className="absensi-rekap__custom-form-row__field">
        <Select
                placeholder="Pilih Tingkat Kelas"
                label = "Tingkat Kelas"
                name="classes_id"
                value={form.value.classes_id}
                data={param.classes_prm}
                onChange={this._onFormChange}
              />
      
        </div>
        <div className="absensi-rekap__custom-form-row__field">
          
<         Select
              placeholder="Pilih Kelas"
              label = "Kelas"
              name="classrooms_id2"
              value={classrooms_id2}
              data={param.classrooms}
              onChange={this._onChangeFilter}
            />
      
        </div>
      </div>

      <div className="absensi-rekap__custom-form-row">
        <div className="absensi-rekap__custom-form-row__field">
            <Select
              placeholder="Pilih Kelas"
              label = "Akan Pindah Ke Kelas"
              name="classrooms_id"
              value={form.value.classrooms_id}
              data={param.classrooms}
              onChange={this._onFormChange}
            />

        </div>
        <div className="absensi-rekap__custom-form-row__field">
        <Input
                label = "Daya Tampung"
                name="capacity"
                value={form.value.capacity}
                data={param.classrooms}
                onChange={this._onFormChange}
                disabled
              />

        </div>
      </div>
      <div className="absensi-rekap__custom-form-row">
        <div className="absensi-rekap__custom-form-row__field">
        <SearchSelect
              noMargin
              async={false}
              name="students_id"
              list={param.student}
              inputArray
              onClick={this._onChangeFilter}
              placeholder="Pilih Nama Siswa"
              value={filters.students_id}
              rightIcon="icon-search"
              label= "Nama Siswa"
              />
        </div>
        <div className="absensi-rekap__custom-form-row__field">
   
        </div>
      </div>
      <div>
        </div>
         
        <div className="fund-request__content">
          <table className="table">
           
  <thead>
    <tr>
      {
          map(PINDAH_KELAS_LIST_TABLE_FIELDS.label, (field, idx) => (
            <th key={`table_th_${idx}`}>{field}</th>
        ))
        }
    </tr>
  </thead>
  <tbody>
    {
        map(pindah_kelas, (data, idx) => (
          <tr key={`budget_row_${idx}`}>
            {
            map(PINDAH_KELAS_LIST_TABLE_FIELDS.value, (field, fieldIdx) => {
              if (fieldIdx === 0) {
                return (
                  <td key={`table_index_${fieldIdx}_${idx}`}>{idx + 1}</td>
                );
              }
              if (field.attribute === 'checkbox') {
                return (


                  <td key={`table_${fieldIdx}_${idx}`}>
                  <Input
                  type="checkbox"
                  name="checkbox"
                  data-input-array={true}
                  data-array-position={idx}
                  data-field-name='pindah_kelas'
                  onChange={this._onFormChange}
                  checked={list.checkbox}
                  value={field.value}
                />  </td>
                    );
                  }
                 
              

              return (
                <td className={(field.type === 'number') ? 'nominal' : ''} key={`table_${fieldIdx}_${idx}`}>
                  {formatData(data, field)}
                </td>
              );
            })
            }
          </tr>
        ))
      }
  </tbody>
</table>
</div>
<div className="student-list__button">
    {this.state.id?
              <Button
                type="button"
                title="Update"
                onClick={this.onEdit}
              />:
              <Button
                type="button"
                title="Simpan"
                onClick={this.onSubmit}
              />}
          </div>
      </div>
    

    );
  }
}
PindahKelas.propTypes = {
  handleGetLevels: PropTypes.func,
  handleGetClasses: PropTypes.func,
  history: PropTypes.object.isRequired,
  getStudentOptions: PropTypes.func,
};
PindahKelas.defaultProps = {
  handleGetLevels: noop,  
  handleGetClasses: noop,
  getStudentOptions: noop,
};

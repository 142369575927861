import React, { Component } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import PropTypes from 'prop-types';
import { map, noop, filter } from 'lodash';
import { Input, Button, Select, Checkbox, SearchSelect2 } from '../../../../components/base';
import { commaFormatted, normalizeAmount } from '../../../../utils/transformer.util';
import { InputGroup } from 'react-bootstrap';

const MySwal = withReactContent(Swal);


export default class TabelPengaturanSoal extends Component {
  constructor(props) {
    super(props);
    this._onAddList = this._onAddList.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this.onClickButton = this.onClickButton.bind(this);
    this._onClickNext = this._onClickNext.bind(this);
    this.state = {
      list: [],
      workflow: {},
      listAmount: 5,
    };
  
    this.checkboxRefs = [];
  }
  
  _onAddList() {
    const { onAddList, fieldName } = this.props;
    onAddList(fieldName);
  }

  onClickButton(data, list) {
    const filteredList = filter(list, o => (
      o.code === data.code && data.code_tema === o.code_tema
    ));

    MySwal.fire({
      size: 20000,
      width: '1000px',
      height: '1000px',
      footer: '',
      html: this.renderModalContent(filteredList),
    });
  }

  renderHeaderModal() {
    const { selected, checkedAll } = this.state;
    return (
      <thead>
        <tr>
        <th rowSpan={2}>
                <Checkbox
                  noMargin
                  name="checkAll"
                  onChange={this._onCheckAll}
                  checked={checkedAll}
                />
              </th>
            <th  className="manage-budget__row table" style={{ width: "20%" }}>No</th>
            <th  className="manage-budget__row table" style={{ width: "20%" }}>Kelas</th>
          </tr>
      </thead>
    );
  }

  _onDeleteList(idx) {
    const { onDeleteList, fieldName } = this.props;
    onDeleteList(idx, fieldName);
  }

  _onFormChange(event) {
    const { onChange } = this.props;
    onChange(event);
  }
  
  _onClickNext() {
    const { history, onClickNext } = this.props;
    onClickNext(history);
  }

  renderModalContent(filteredList) {
    const {
      list, error, onChange,
      fieldName, plafond_name, nis, periode, rincian
    } = this.props;
    return (
      <div>
        <div>
          <h1>{filteredList[0].name_tema}</h1>
        </div>
        <table className="manage-budget__form-table table">
          {this.renderHeaderModal()}
          {
          map(list, (data, idx) => (
              <tr key={`plafon_journal_data__${idx}`}>
                <td><Checkbox
                      noMargin
                      data-input-array
                      data-array-position={idx}
                      name="selectedBudget"
                      onChange={this._onCheckDetail}
                      ref={this._setRef}
                      
                    /></td>
               
                
                <td className="nominal">
                 
                </td>
                <td className="nominal">
                
                 
                </td>
              </tr>
            ))
          }
        </table>
      </div>
    );
  }

  render() {
    const { form } = this.state;
    const {
      list, error, onChange, user,
      fieldName, plafond_name, nis, periode, rincian, onFormChange
    } = this.props;
    const dataset = {
      toggle: "modal"
    };
    const tipePlafon = [
      { label: '---', value: '---' },
      { label: 'Rutin', value: 'rutin' },
      { label: 'Kegiatan', value: 'kegiatan' },
      { label: 'Swadaya', value: 'swadaya' },
      { label: 'Dana Pemerintah', value: 'dana pemerintah' },
      { label: 'Biaya Pengembangan Pemerintah', value: 'biaya pengembangan pemerintah' },
    ];

    return (
      <table className="manage-rapbs-plafon__form-table table">
        <thead>
          <tr>
            <th>No</th>
            <th className="manage-budget__row table" style={{ width: "5%" }}>Judul</th>
            <th className="manage-budget__row table" style={{ width: "18%" }}>Tanggal</th>
            <th className="manage-budget__row table" style={{ width: "18%" }}>Keterangan</th>
            <th className="manage-budget__row table" style={{ width: "18%" }}>Mata Pelajaran</th>
            <th className="manage-budget__row table" style={{ width: "10%" }}>Periode/Hari</th>
            <th  className="manage-budget__row table" style={{ width: "20%" }}>Kelas</th>
            <th className="manage-budget__row table" style={{ width: "18%" }}>Upload Picture</th>
            <th className="manage-budget__row table" style={{ width: "18%" }}>Action</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {
            map(list, (data, idx) => (
              <tr key={`plafon_journal_data__${idx}`}>
                <td>{idx + 1}</td>
                <td className="nominal"></td>
                <td className="nominal"></td>
                <td className="nominal"></td>
                <td className="nominal">
                  <SearchSelect2
                         noMargin
                         async={false}
                         name="tema"
                         onChange={this.onSearchTema}
                         placeholder="Pilih Nomor Invoice"
                         labelName="name"
                         valueName="code"
                         label=""
                         rightIcon="icon-search"
                         />
                </td>
                <td className="nominal">
                  <Select
                    type="text"
                    noMargin
                    data-input-array
                    data-array-position={idx}
                    data-field-name='tabelpengaturansoal'
                    data-input-type="text"
                    name="no_invoice"
                    onChange={onFormChange}
                    value={data.no_invoice || ''}
                  // error={error[idx] ? error[idx].nominal : ''}
                  />
                </td>
               
                <td className="nominal">
                <Button variant="primary"  onClick={() => this.onClickButton(data, list)} >Tambah Kelas</Button>
                </td>
                <td className="nominal">
                <Input
                    type="file"
                    noMargin
                    data-input-array
                    data-array-position={idx}
                    data-field-name='tabelpengaturansoal'
                    data-input-type="text"
                    name="no_invoice"
                    onChange={onFormChange}
                    value={data.no_invoice || ''}
                  />
                 
                </td>
        
                <td>
                  <Button onClick={() => this._onDeleteList(idx)} icon="icon-trash-o" />
                </td>
                <td>
                <Button
                    title="Lanjut"
                    onClick={this._onClickNext}
                />
                </td>
              </tr>
            ))
          }
          <tr className="manage-rapbs-plafon__add-more">
            <td colSpan={13}>
              <div>
                <Button
                  icon="icon-plus"
                  onClick={this._onAddList}
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

TabelPengaturanSoal.propTypes = {
  list: PropTypes.array.isRequired,
  error: PropTypes.array.isRequired,
  onAddList: PropTypes.func,
  onDeleteList: PropTypes.func,
  onChange: PropTypes.func,
  plafond_name: PropTypes.array,
  onFormChange: PropTypes.func,
};
TabelPengaturanSoal.defaultProps = {
  onAddList: noop,
  onDeleteList: noop,
  onChange: noop,
  onFormChange: noop,
  plafond_name: [],
};

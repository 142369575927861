import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import budgeting from '../../../assets/img/finance/anggaran.png';
import bookkeeping from '../../../assets/img/bookkeeping/jurnal_bank.png';

export default class Analisa extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickEstimasi = this.onClickEstimasi.bind(this);
    this.onClickRaportKBK = this.onClickRaportKBK.bind(this);
  }

  onClickEstimasi() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/analisa/estimasi-kenaikan-kelas');
  }

  onClickRaportKBK() {
    const { history } = this.props;
    history.push('/dashboard/kurikulum/analisa/komposisi-siswa');
  }

 
  render() {
    return (
      <div className="finance">
        <div className="finance__list">
          <button onClick={this.onClickNilaiRaport}>
            <img src={budgeting} alt="kode" />
            <h3>Estimasi Kenaikan Kelas</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickRaportKBK}>
            <img src={budgeting} alt="raportkbk" />
            <h3>Komposisi Siswa</h3>
          </button>
        </div>
        
        
      
      </div>
    );
  }
}
Analisa.propTypes = {
  history: PropTypes.object.isRequired,
};

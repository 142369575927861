import { connect } from 'react-redux';
import ReportLoggedIn from '../../../views/Student/report/ReportLoggedIn.view';
import { getClasses, getParamOptionsAcademics } from '../../../states/thunks/options.thunk';
import { listReportLogin } from '../../../states/thunks/academics.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    user: state.user
  }
}
function mapDispatchToProps(dispatch) {
  return {
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    handleListReportLoggedin: (payload, goback) => dispatch(listReportLogin(payload, goback))
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ReportLoggedIn);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop , map, isEmpty, isEqual} from 'lodash';
import { Button, Input , SearchSelect} from '../../../components/base';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import ListForm from '../../../components/ListForm/ListForm.component';
import { NIS_SISWA_SEARCH } from '../../../constants/student.constant';
import { BATAL_SISWA_KELUAR_TABLE } from '../../../constants/student.constant';
import { commaFormatted, formatData } from '../../../utils/transformer.util';
import { BATAL_SISWA_KELUAR_LIST_TABLE_FIELDS } from '../../../constants/Student/student.constant';
import { Link } from 'react-router-dom';

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};
export default class PembatalanSiswaKeluar extends PureComponent {
  constructor(props) {
    super(props);
    this._onClickAdd = this._onClickAdd.bind(this);
    this._onClickEdit = this._onClickEdit.bind(this);
    this._onClickUpload = this._onClickUpload.bind(this);
    this._getParamOptions = this._getParamOptions.bind(this);
    this._getStudentOptions = this._getStudentOptions.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._renderButtons = this._renderButtons.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);

    this.state ={
      page: 1,
      content: initialContent,
      unchecked: {},
      filters:{},
      list:[],
        form: {
            value: {},
            error: {},
           

        },
        param:'',
    };
  }

  componentDidMount() {
    const {location, handleGetClasses } = this.props;
    const { content } = this.state;
    const { state = {} } = location;
  const {isEdit =  false, data} = state;
    const paramTypes = ['classes','levels', 'classrooms'];
    paramTypes.forEach((type) => {
      this._getParamOptions(type);
    });
    this._getStudentOptions();
    if (isEmpty(content.list)) {
      this._onSearchContent({ page: 1 });
    }
  }
  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { state = {} } = location;
    const {isEdit =  false, data} = state;
    const { id } = state.data || '';
    // const { user } = this.props;
    // const { workingUnit } = user;
    // const { user: prevUser } = prevProps;
    // const { workingUnit: prevWorkingUnit } = prevUser;

    // if (!isEqual(workingUnit, prevWorkingUnit)) {
    //   this.handleGetData();
    // }
    if ((!isEdit || !id) && !isEqual(prevProps, this.props)) {
      this._onSearchContent({ page: 1 });
    }
  }


  _onFormChange(event) {
    const {
      name,
      value,
      dataset,
      files,
    } = event.target;
    const { inputType = 'text' } = dataset;
    this.setState((prevState) => {
      let formattedValue = value;
      if (inputType === 'number') {
        formattedValue = commaFormatted(value);
      }
      if (inputType === 'file') {
        [formattedValue] = files;
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            [name]: formattedValue,
          },
          error: {
            ...prevState.form.error,
            [name]: '',
          },
        },
      };
    });
  }

  _onClickAdd() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/pendaftaran', { data: '' });
  }

  _onClickEdit(val) {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/pendaftaran', { data: val });
  }

  _onClickUpload() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/data-siswa/upload');
  }

  async onClickDelete(id) {
    const { handleactivatedExitRecord } = this.props;
    const res = await handleactivatedExitRecord(id);
    if(res){
      this._onSearchContent();
    }
  }

  async _getParamOptions(type, filters={}) {
    const {getParamOptions} = this.props;
    if (type === 'classes' || type === 'classrooms') {
      filters.organizations_id = 4;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptions(filters, type);
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getStudentOptions(filters={}) {
    const { getStudentOptions } = this.props;
    const res = await getStudentOptions(filters);
    // console.log(res)
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        student: res,
      },
    }));
    
  }

  async _onSearchContent(params = {}) {
    const { filters } = this.state;
    this.setState({
      content: initialContent,
    }, async () => {
      try {
        const { handleListExitRecord, user } = this.props;
        const { school_unit, workingUnit, id, user_group, organizations_id } = user;
        const { levels_id, units_id } = school_unit;
        const {
          result = [], currentPage = 1, total = 0, totalPage = 1,
        } = await handleListExitRecord({ ...params, organizations_id, units_id, filters });
        // if (this.isMount) {
          this.setState({
            content: {
              loading: false,
              list: result,
              currentPage,
              total,
              totalPage,
            },
          });
        // }
      } catch (err) {
        // if (this.isMount) {
          this.setState({
            content: {
              loading: false,
              list: [],
              currentPage: 1,
              total: 1,
              totalPage: 1,
              error: true,
            },
          });
        }
      // }
    });
  }

  async onClickDelete(id) {
    const { handleactivatedExitRecord } = this.props;
    const res = await handleactivatedExitRecord(id);
    if(res){
      this._onSearchContent();
    }
  }

  async _getParamOptions(type, filters={}) {
    const {getParamOptions} = this.props;
    if (type === 'classes' || type === 'classrooms') {
      filters.organizations_id = 4;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptions(filters, type);
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getStudentOptions(filters={}) {
    const { getStudentOptions } = this.props;
    const res = await getStudentOptions(filters);
    // console.log(res)
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        student: res,
      },
    }));
    
  }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    this.setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        [name]: value,
      },
    }), () => this._onSearchContent());
  }

  _renderButtons(data) {
    const { user } = this.props;
    const { user_group } = user;
    const { permissions = [] } = user_group;
  
    return (
      <td>
       <div className="table__actions">
        
         
        <Button
        onClick={() => this.onClickDelete(data)}
        title='Aktifasi'
      />
        
      {/* </div> */}
   {/* } */}
      

    
   </div>
      </td>
    );
  }

  render() {
    const { onChange, form, classes } = this.props;
    const {content,list, param, filters = {} } = this.state;
   
    return (
      <div className="fund-request">
        
        <div className="beginning-balance__button-wrapper">
        <SearchSelect
            noMargin
            async={false}
            name="students_id"
            list={param.student}
            inputArray
            onClick={this._onChangeFilter}
            placeholder="Pilih Nama Siswa"
            value={filters.students_id}
            // labelName="label"
            // valueName="value"
            rightIcon="icon-search"
            label= "Nama Siswa"
          />
    </div>
        <div className="fund-request__content">
        <table className="table">
                    <thead>
                      <tr>
                        {
                            map(BATAL_SISWA_KELUAR_LIST_TABLE_FIELDS.label, (field, idx) => (
                              <th key={`table_th_${idx}`}>{field}</th>
                            ))
                          }
                      </tr>
                    </thead>
                    <tbody>
                      {
                          map(content.list, (list, idx) => (
                            <tr key={`budget_row_${idx}`}>
                              {
                              map(BATAL_SISWA_KELUAR_LIST_TABLE_FIELDS.value, (field, fieldIdx) => {
                                if (fieldIdx === 0) {
                                  return (
                                    <td key={`table_index_${fieldIdx}_${idx}`}>{idx + 1}</td>
                                  );
                                }
                                if (field.type === 'link') {
                                  return (
                                    <td key={`table_${fieldIdx}_${idx}`}><Link to={'/dashboard/kesiswaan/ekstrakulikuler/pembagian-kelas-ekskul'}>{list.name}</Link></td>
                                      // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                                  );
                                }
                                return (
                                  <td className={(field.type === 'number') ? 'nominal' : ''} key={`table_${fieldIdx}_${idx}`}>
                                    {formatData(list, field)}
                                  </td>
                                );
                              })
                              }
                              {this._renderButtons(list)}
                            </tr>
                          ))
                        }
                    </tbody>
                  </table>
         
         
        </div>
        <div className="user-management__table-footer">
          {/* <p className="user-management__page-info">
            {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${list.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${list.totalPage}`}
          </p> */}
          {/* <Pagination
            totalPage={list.totalPage}
            currentPage={list.currentPage}
            onClick={this.onChangePage}
          /> */}
        </div>
      </div>
    );
  }
}
PembatalanSiswaKeluar.propTypes = {
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
PembatalanSiswaKeluar.defaultProps = {
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};

import { connect } from 'react-redux';
import CetakBukuSiswa from '../../../../views/Student/report/cetak/CetakBukuSiswa.student.view';
import { getStudent } from '../../../../states/thunks/student.thunk';
import { editLembarObsHarian, saveLembarObsHarian, getStudentNumber } from '../../../../states/thunks/academics.thunk'

function maptSateToProps(state) {
  return {
    user: state.user,
    result: state.result,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetStudentNumber: payload => dispatch(getStudentNumber(payload)),
  };
}

export default connect(maptSateToProps, mapDispatchToProps)(CetakBukuSiswa);



import {
  isEmpty,
  each,
  forEach,
} from 'lodash';
import {
  normalizeAmount,
  sumActualSemesterAmount,
  sumBudgetTransferSource,
  sumExpectedSemesterAmount,
} from './transformer.util';
import {
  BUDGET_REQUIRED_FIELD,
} from '../constants/finance/budgeting/budget.constant';
import {
  BUDGET_TRANSFER_REQUIRED_FIELD,
} from '../constants/finance/budgeting/budgetTransfer.constant';
import {
  FUND_REQUEST_REQUIRED_FIELD,
} from '../constants/finance/budgeting/fundRequest.constant';

import {
  VALIDATION_FORM_PENDAFTARAN,
} from '../constants/student.constant';

import {
  VALIDATION_FORM_RKB,
} from '../constants/finance/finance.constant';


export function validateRequiredFields(values, fields) {
  const errors = {};
  each(fields, ((field) => {
    if (!values[field]) {
      errors[field] = 'Harus di isi';
    }
  }));
  return errors;
}

export function validateAllocationPlafond(values) {
  const errors = {};

  const {
    allocations,
  } = values;

  if (!allocations || Object.keys(allocations).length === 0) {
    errors.plafond = 'Harus di isi';
  } else {
    let allocationSum = 0;
    Object.values(allocations).forEach((allocation) => {
      allocationSum += allocation;
    });
    if (allocationSum <= 0) {
      errors.plafond = 'Harus lebih besar dari 0';
    }
  }
  return errors;
}

export function validateAllocationPlafondAdd(values) {
  const errors = {
    semesterOne: [],
    semesterTwo: [],
  };

  Object.values(errors).forEach((err, semester) => {
    const semesterData = values[semester];
    const error = {};
    if (semesterData) {
      semesterData.forEach((data) => {
        if (!data.allocations || Object.keys(data.allocations).length === 0) {
          error.plafond = 'Harus di isi';
        } else {
          let allocationSum = 0;
          Object.values(data.allocations).forEach((allocation) => {
            allocationSum += allocation;
          });
          if (allocationSum <= 0) {
            error.plafond = 'Harus lebih besar dari 0';
          }
        }
        errors[semester].push(error);
      });
    }
  });

  return errors;
}


export function validatePlafondAllocation(plafonds = [], payload = {}) {

}

export function validateManageBudget(payload = {}, plafonds = [], totalPlafond = {}) {
  const {
    semesterOne,
    semesterTwo,
  } = payload;
  const semesterTwoError = [];
  const semesterOneError = [];
  const plafondError = [];
  if (semesterTwo) {
    forEach(semesterTwo, (data) => {
      const error = validateRequiredFields(data.BUDGET_REQUIRED_FIELD);
      if (Object.keys(error).length > 0) {
        semesterTwoError.push(error);
      }
    });
  }

  if (semesterOne) {
    forEach(semesterOne, (data) => {
      const error = validateRequiredFields(data, BUDGET_REQUIRED_FIELD);
      if (Object.keys(error).length > 0) {
        semesterOneError.push(error);
      }
    });
  }

  // forEach(semesterOne, (data) => {
  //   const error = validateAllocationPlafond(data);
  //   if (error) {
  //     semesterOneError.push(error);
  //   }
  //   console.log(error)
  // });

  // forEach(semesterOneallo, (data) => {
  //   const error = validateRequiredFields(data, BUDGET_REQUIRED_FIELD_AllO);
  //   if (error) {
  //     semesterOneError.push(error);
  //   }
  //   console.log(error)
  // });

  if (semesterOneError.length === 0 && semesterTwoError.length === 0) {
    forEach(plafonds, (plafond) => {
      if (totalPlafond[plafond.plafond_id] > plafond.nominal) {
        plafondError[plafond.plafond_id] = `Jumlah plafond ${plafond.plafond.plafond} melebihi batas`;
      }
    });
  }
  return {
    semesterTwo: semesterTwoError,
    semesterOne: semesterOneError,
    plafond: plafondError,
  };
}

export function checkEmptyObject(objs) {
  const valid = [];
  each(objs, ((val) => {
    if (val) {
      valid.push('valid');
    }
  }));
  return isEmpty(valid);
}

export function validateSemesterAmount(payload) {
  const {
    semesterOne,
    semesterTwo,
  } = payload;
  let error = false;
  forEach([...semesterOne, ...semesterTwo], (data) => {
    const expectedAmount = sumExpectedSemesterAmount(data);
    const actualAmount = sumActualSemesterAmount(data);
    if (expectedAmount !== actualAmount) {
      error = true;

      //   console.log("data total")
      // console.log(expectedAmount)
    }
  });

  return error;
}

export function validateBudgetTransfer(payload = {}) {
  const {
    selectedBudget,
    rapbuSource,
  } = payload;
  const selectedBudgetError = [];
  const rapbuSourceError = [];
  let totalTransferError = '';
  const totalRapbu = sumBudgetTransferSource(rapbuSource);
  let totalAmount = 0;
  forEach(selectedBudget, (data) => {
    const {
      remains = 0, total = 0, amount = 0,
    } = data;
    totalAmount += normalizeAmount(amount);
    const error = validateRequiredFields(data, BUDGET_TRANSFER_REQUIRED_FIELD);
    if (error) {
      selectedBudgetError.push(error);
    }
    const expectedAmount = remains ? normalizeAmount(remains) : normalizeAmount(total);
    if (expectedAmount < normalizeAmount(amount)) {
      error.amount = 'Jumlah tidak cukup';
    }
  });

  forEach(rapbuSource, (data) => {
    const error = validateRequiredFields(data, BUDGET_REQUIRED_FIELD);
    if (error) {
      rapbuSourceError.push(error);
    }
  });
  if (totalAmount < totalRapbu) {
    totalTransferError = 'Total pengalihan dana tidak cukup';
  }
  return {
    selectedBudget: selectedBudgetError,
    rapbuSource: rapbuSourceError,
    totalTransferError,
  };
}

export function validateFundRequest(payload = {}) {
  const {
    selectedBudget = {}, amount = 0,
  } = payload;
  const {
    total = 0, remains = 0,
  } = selectedBudget;
  const error = validateRequiredFields(payload, FUND_REQUEST_REQUIRED_FIELD);
  const expectedAmount = remains ? normalizeAmount(remains) : normalizeAmount(total);
  if (expectedAmount < normalizeAmount(amount)) {
    error.amount = 'Jumlah tidak cukup';
  }
  return error;
}

export function validateManageRegis(payload = {}) {
  const data = payload;
  const fieldName = VALIDATION_FORM_PENDAFTARAN;
  let flag = true;

  each(fieldName, ((field) => {
    if (data.value[field.name] === undefined || isEmpty(data.value[field.name])) {
      if (field.type === 'select' || field.type === 'date') {
        data.error[field.name] = 'Mohon dipilih ';
      } else {
        data.error[field.name] = 'Mohon diisi ';
      }
      flag = false;
    }
  }));

  return ({
    error: data,
    flag,
  });
}

export function validateRKB(payload = {}) {
  const data = payload;
  const fieldName = VALIDATION_FORM_RKB;
  let flag = true;

  each(fieldName, ((field) => {
    if (data.value[field.name] === undefined || isEmpty(data.value[field.name])) {
      if (field.type === 'select' || field.type === 'date') {
        data.error[field.name] = 'Mohon dipilih ';
      } else {
        data.error[field.name] = 'Mohon diisi ';
      }
      flag = false;
    }
  }));

  return ({
    error: data,
    flag,
  });
}

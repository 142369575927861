import { connect } from 'react-redux';
import CatatSiswaKeluar from '../../../views/Student/DataSiswa/CatatSiswaKeluar.student.view';
import { getStudent } from '../../../states/thunks/student.thunk';
import { getClasses } from '../../../states/thunks/options.thunk';
import { getParamOptions, getStudentExitOptions, getParamOptionsAcademics } from '../../../states/thunks/options.thunk';
import {listExitRecord} from '../../../states/thunks/academics.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    user: state.user,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    handleGetClasses: payload => dispatch(getClasses(payload)),
    handleGetStudent: () => dispatch(getStudent()),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    handleListExitRecord: (payload, goback) => dispatch(listExitRecord(payload, goback)),
    getStudentExitOptions: (payload) => dispatch(getStudentExitOptions(payload)),
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CatatSiswaKeluar);



import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop, isEqual, map } from 'lodash';
import StudentReportFilter from '../../../components/Filter/StudentReport.filter.component';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { LAPORAN_ABSEN } from '../../../constants/student.constant';
import { ABSENSI_REKAP_TABLE } from '../../../constants/student.constant';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted, normalizeAmount, formatData } from '../../../utils/transformer.util';
import { Button, Select, Pagination,SearchSelect , Input } from '../../../components/base/index';
import LaporanAbsensiTable from '../components/LaporanAbsensiTable.component';
import { LAPORAN_ABSEN_TABLE_FIELDS } from '../../../constants/Student/student.constant';
import language from '../../../languages';

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class LaporanAbsensi extends Component {
        constructor(props) {
          super(props);
          this._onFormChange = this._onFormChange.bind(this);
          this._onClickNext = this._onClickNext.bind(this);
          this._onChangeFilter = this._onChangeFilter.bind(this);
          this._onSearchContent = this._onSearchContent.bind(this);
          //this._onAddList = this._onAddList.bind(this);
          this._onClickAdd = this._onClickAdd.bind(this);
          this._handleFormChange = this._handleFormChange.bind(this);
          this._onSearch = this._onSearch.bind(this);
          this._getSubjectOptions= this._getSubjectOptions.bind(this);
          this._onChangePage = this._onChangePage.bind(this);
          this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
          this._getPeriodsOptions= this._getPeriodsOptions.bind(this);
          this._getClassesOptions= this._getClassesOptions.bind(this);

          this.state = {
            page: 1,
            selected: {},
            content: initialContent,
            unchecked: {},
            filters:{},
            list:[],
            form: {
              value: {
                laporan_absensi: [],
                classrooms: [],
                levels: [],
                classrooms_id: '',
              },
              error: {
                laporan_absensi:'',
                classrooms: '',
                levels: '',
              },
            },
            param:{},
            filtered: false,
          };
         
        }
      
        componentDidMount() {
          const { handleGetCodeOfAccount, location, handleGetclassrooms, handleGetLevels, user } = this.props;
          const paramTypes = ['classes','levels', 'classrooms'];
          const { content } = this.state;
          const { school_unit } = user;
          const { levels_id, units_id} = school_unit;
          paramTypes.forEach((type) => {
            this._getParamOptionsAcademics(type);
          });
           if (isEmpty(content.list)) {
            this._onSearchContent({ page: 1 });
          }
          this._getSubjectOptions({ filters: { units_id: [units_id] }});  
          this._getClassesOptions({ filters: { levels_id: [levels_id] }});
          this._getPeriodsOptions();       

        }
      
        componentDidUpdate(prevProps) {
          // const { user } = this.props;
         // const { workingUnit } = user;
         // const { user: prevUser } = prevProps;
         // const { workingUnit: prevWorkingUnit } = prevUser;
     
         // if (!isEqual(workingUnit, prevWorkingUnit)) {
         //   this.handleGetData();
         // }
         if (!isEqual(prevProps, this.props)) {
           this._onSearchContent({ page: 1 });
         }
       }

       async _getClassesOptions(filters={}) {
        const { getClassesOptions, user } = this.props;
        const {school_unit} = user;
        const { levels_id } = school_unit;
        filters.levels_id = levels_id;

        const res = await getClassesOptions(filters);
        // console.log(res)
        this.setState(prevState => ({
          ...prevState,
          param: {
            ...prevState.param,
            classes: res,
          },
        }));
       
      }

        // on formchange untuk form
        _handleFormChange(event) {
            const {
              name,
              value,
            } = event.target;
            this.setState(prevState => ({
              form: {
                value: {
                  ...prevState.form.value,
                  [name]: value,
                },
                error: {
                  ...prevState.form.error,
                  [name]: '',
                },
              },
            }));
          }
        
          _onSearch() {
            const { form } = this.state;
            const error = validateRequiredFields(form.value, ['year']);
            this.setState(prevState => ({
              form: {
                value: prevState.form.value,
                error,
              },
            }));
            if (isEmpty(error)) {
              this.setState({ filtered: true });
            }
          }

          async _getPeriodsOptions(filters={}) {
            const { getPeriodsOptions ,user} = this.props;
            const { organizations_id, school_unit } = user;
            const { units_id, levels_id } = school_unit
            const res = await getPeriodsOptions(filters);
            filters.organizations_id = organizations_id;
  
            this.setState(prevState => ({
              ...prevState,
              param: {
                ...prevState.param,
                periods: res,
              },
            }));
            
          }

          async _getParamOptionsAcademics(type, filters={}) {
            const { getParamOptionsAcademics, user } = this.props;
            const { organizations_id , school_unit } = user;
            const { levels_id } = school_unit;
  
            if (type === 'classrooms' || type === 'classrooms') {
              filters.organizations_id = organizations_id;
              filters.levels_id = levels_id;

            } else {
              delete filters.organizations_id;
            }
            const res = await getParamOptionsAcademics(filters, type);
            this.setState(prevState => ({
              ...prevState,
              param: {
                ...prevState.param,
                [type]: res,
              },
            }));
          }

          async _getParamOptions(type, filters={}) {
            const {getParamOptions, user} = this.props;
            const { organizations_id } = user;
  
            if (type === 'classes' || type === 'classrooms') {
              filters.organizations_id = organizations_id;
            } else {
              delete filters.organizations_id;
            }
            const res = await getParamOptions(filters, type);
            this.setState(prevState => ({
              ...prevState,
              param: {
                ...prevState.param,
                [type]: res,
              },
            }));
          }

          async _getSubjectOptions(filters={}) {
            const { getSubjectOptions, user } = this.props;
            const { organizations_id, school_unit } = user;
            const { levels_id, units_id } = school_unit;
            const res = await getSubjectOptions(filters);
            filters.units_id = units_id;
            // console.log(res)
            this.setState(prevState => ({
              ...prevState,
              param: {
                ...prevState.param,
                subject: res,
              },
            }));
           
          }

        _onFormChange(event) {
          const { user } = this.props;
          const { organizations_id } = user;
          const {
            name,
            value,
            dataset,
            checked,
            type,
          } = event.target;
          const {
            inputType = 'text', inputArray = false, arrayPosition = 0,
            fieldName,
          } = dataset;
          this.setState((prevState) => {
            let newList = [];
            let newListError = [];
            let formattedValue = value;
      
            if (inputType === 'number') {
              formattedValue = normalizeAmount(value);
            }
            if (inputArray) {
              if (type === 'checkbox') {
                formattedValue = checked;
              }
              newList = prevState.form.value[fieldName];
              newListError = prevState.form.error[fieldName];
              newList[arrayPosition][name] = formattedValue;
              if (name === 'code_of_account') {
                newList[arrayPosition].isCredit = value.type;
              }
              if (!isEmpty(newListError[arrayPosition])) {
                newListError[arrayPosition][name] = '';
              }
            }
            return {
              form: {
                value: {
                  ...prevState.form.value,
                  ...(inputArray
                    ? { [fieldName]: newList }
                    : { [name]: formattedValue }),
                },
                error: {
                  ...prevState.form.error,
                  ...(inputArray
                    ? { [fieldName]: newListError }
                    : { [name]: '' }),
                },
              },
            };
          }, () =>
           {
            if (name === 'classes_id') {
              this._getParamOptionsAcademics( 'classrooms', {classes_id: value, organizations_id } );
              this._getSubjectOptions({ filters: {classes_id: value} } );
            
            }

            // if (name === 'ekskul_id') {
            //   this._getTeacherEkskulOptions({ filters: { ekskul_id: value }} );
            // }
          
          });
        }
      
        _onClickAdd(data) {
          const { form } = this.state;
          const { plafon } = form.value;
          
          plafon.push({
            tipePlafon: '',
            // divisi: '',
            nominal: 0,
            //period:'',
          });
          
          this.setState(prevState => ({
            form: {
              value: {
                ...prevState.form.value,
                plafon,
              },
              error: {
                ...prevState.form.error,
              }
            }
          }));
          console.log(plafon)
        }
      
        async _onSearchContent(params = {}) {
          const { filters, form } = this.state;
          const { value } = form;
          const { subject_id } = filters;
          this.setState({
            content: initialContent,
          }, async () => {
            try {
              const { handleListReportStudy , handleListReportStudent, handleListAchievement, user} = this.props;
              const { school_unit, workingUnit, id, user_group, organizations_id } = user;
              const { levels_id, units_id } = school_unit;
              let result = [];
              if (subject_id) {
              result = await handleListReportStudy({ ...params, organizations_id, units_id, subject_id: value.subject_id, filters });
              } else {
                result = await handleListReportStudent({ ...params, organizations_id ,units_id,  filters });
              }
              console.log(result)
              this.setState(prevState => ({
                ...prevState,
                form: {
                  ...prevState.form,
                  value: {
                    ...prevState.form.value,
                    laporan_absensi: result,
                  }
                }
              }));
            } catch (err) {
              // if (this.isMount) {
                this.setState(prevState => ({
                  ...prevState,
                  form: {
                    ...prevState.form,
                    value: {
                      ...prevState.form.value,
                      laporan_absensi: [],
                    }
                  }
                }));
              }
            // }
          });
        }
      
        // on formchange untuk tabel
        onFormChange(event) {
          const {
            name,
            value,
            dataset,
          } = event.target;
          console.log(name)
          const { inputArray = false, arrayPosition = 0, inputType = 'text' } = dataset;
          this.setState((prevState) => {
            let formattedValue = value;
            let newPlafon = [];
            if (inputType === 'number') {
              formattedValue = normalizeAmount(value);
            }
      
            if (inputArray) {
              newPlafon = prevState.form.value.plafon;
              newPlafon[arrayPosition][name] = value;
            }
            return {
              form: {
                value: {
                  ...prevState.form.value,
                  ...(inputArray ? 
                    { plafon: newPlafon } : 
                    { [name]: formattedValue }
                  )
                },
                error: {
                  ...prevState.form.error,
                  form:'',
                  [name]: '',
                },
              },
            };
          });
        }
      
       

        _onChangeFilter(e) {
          const { target } = e;
          const { value, name } = target;
          this.setState(prevState => ({
            ...prevState,
            filters: {
              ...prevState.filters,
              [name]: value,
            },
          }), () => this._onSearchContent());
        }
        _onChangePage(page) {
          const { listAmount, keywords } = this.state;
          const offset = listAmount * (page - 1);
          // this.handleGetData({
          this._onSearchContent({
            limit: listAmount,
            keywords,
            offset,
            page,
          });
        }
      
        
    _onClickNext() {
        window.scrollTo(0, 0);
        this.setState(prevState => ({
          page: prevState.page + 1,
        }));
      }
  
      render() {

        const {param, form,
                page, list = {},
                selected, checkedAll, content, 
                filters, listAmount } = this.state;
        const {laporan_absensi} = form.value;
        const {
          classrooms_id = '',
          status_id = '',
          period = '',
          subject_id = '',
          date='',
          date_to = '',
        } = filters;
        const { classrooms = {}, levels = {} } = this.props;
        const presence_status = [
                { label: 'Mengikuti', value: 'mengikuti'},
                { label: 'Tidak Mengikuti', value: 'tidak'},
              ]
        const status = [
          { label: 'Hadir', value: 'HADIR'},
          { label: 'Sakit', value: 'SAKIT'},
          { label: 'Izin', value: 'IZIN'},
          { label: 'Alpa', value: 'ALPA'},
          { label: 'Terlambat', value: 'TERLAMBAT'},


        ]
        const academic_year = [
          { label: '2019', value: '2019'},
          { label: '2020', value: '2020'},
          { label: '2021', value: '2021'},
        ]
        return (
          <div className="manage-registration">
            <form onSubmit={this.onSubmit}>
              <div className="budget__title">
                <h1>Laporan Absen Bidang Study</h1>
              </div>
              
          <div className="kode-matpel__custom-form">
            <div className="kode-matpel__custom-form-row">
            <div className="kode-matpel__custom-form-row__field">
            
            <Select
                name="period"
                label="Tahun Ajaran"
                onChange={this._onChangeFilter}
                // data={ levels.list}
                value={period}
                placeholder={'Pilihan'}
                data={param.periods}
                // error={form.error.namaSaudara1 || ''}
              />
            </div>
              
            
            <div className="kode-matpel__custom-form-column__field">
              <Select
                  name="classes_id"
                  label="Tingkat Kelas"
                  onChange={this._onFormChange}
                  data={param.classes}
                  placeholder={'Pilihan'}
                  value={form.value.classes_id}
                  error={form.error.classes_id || ''}
                />
                 
            
              
            </div>
            </div>
            <div className="kode-matpel__custom-form-row">
              <div className="kode-matpel__custom-form-row__field">
              <Select
                  placeholder="Pilih Kelas"
                  label = "Kelas"
                  name="classrooms_id"
                  onChange={this._onChangeFilter}
                  value={classrooms_id}
                  // error={form.error.classrooms_id|| ''}
                  data={param.classrooms}
                />
              </div>
              <div className="kode-matpel__custom-form-column__field">
                <Select
                    label="Mata Pelajaran"
                    name="subject_id"
                    placeholder="Pilihan"
                    data={param.subject}
                    onChange={this._onChangeFilter}
                    value={subject_id}
                    // error={form.error.subject_id|| ''}
                  />
              </div>
            </div>
            <div className="kode-matpel__custom-form-row">
              <div className="kode-matpel__custom-form-row__field">
                <div className="data-pelanggaran__custom-form-row__field">
                <Input
                  type="date"
                  name="date"
                  label="Tanggal"
                  onChange={this._onChangeFilter}
                  value={date}
              />
              <div className="data-pelanggaran__custom-form-row__field"></div>
                <Input
                  type="date"
                  name="date_to"
                  label="Sampai Dengan"
                  onChange={this._onChangeFilter}
                  value={date_to }
                  //  placeholder={'2019 - 2020'}
                />
              </div>
              <div className="kode-matpel__custom-form-column__field">
                <Select
                  label="Keterangan"
                  name="status_id"
                  onChange={this._onChangeFilter}
                  placeholder="Pilihan"
                  data={status}
                  value={status_id}
                />
                </div>
            </div>
            
            </div>
            <div className="kode-matpel__custom-form-row">
             
            <div className="kode-matpel__custom-form-column__field">
              {/* <Select
                label="Status Kehadiran"
                name="presence_status"
                onChange={this._onFormChange}
                placeholder="Pilihan"
                data={presence_status}
                value={form.value.presence_status}
                error={form.error.presence_status || ''}
              /> */}
              </div>
            </div>
    
          </div>      
          <div className="manage-registration">
            <div className="fund-request__content">
              <table className="table">
                <thead>
                  <tr>
                    {
                        map(LAPORAN_ABSEN_TABLE_FIELDS.label, (field, idx) => (
                          <th key={`table_th_${idx}`}>{field}</th>
                        ))
                      }
                  </tr>
                </thead>
                <tbody>
                  {
                      map(laporan_absensi, (list, idx) => (
                        <tr key={`budget_row_${idx}`}>
                          {
                          map(LAPORAN_ABSEN_TABLE_FIELDS.value, (field, fieldIdx) => {
                            if (fieldIdx === 0) {
                              return (
                                <td key={`table_index_${fieldIdx}_${idx}`}>{idx + 1}</td>
                              );
                            }
                            if (field.type === 'link') {
                              return (
                                <td key={`table_${fieldIdx}_${idx}`}><a href={"https://"+list.hyperlink} target="_blank" rel="noopener noreferrer">{list.hyperlink}</a></td>
                                  // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                              );
                            }
                            return (
                              <td className={(field.type === 'number') ? 'nominal' : ''} key={`table_${fieldIdx}_${idx}`}>
                                {formatData(list, field)}
                              </td>
                            );
                          })
                          }
                          {/* {this._renderButtons(list)} */}
                        </tr>
                      ))
                    }
                </tbody>
              </table>
            </div>
          </div>
        </form>
      </div>
        );
      }
}
LaporanAbsensi.propTypes = {
    
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
LaporanAbsensi.defaultProps = {
    
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import ListForm from '../../../../components/ListForm/ListForm.component';
import { commaFormatted } from '../../../../utils/transformer.util';
import { FINANCE_ADD_UPLOAD_RAPBU_FORM_FIELDS } from '../../../../constants/finance/budgeting/uploadRapbu.constant';
import { Button, Select, Input, SearchSelect } from '../../../../components/base';

export default class AddUploadRapbu extends Component {
  constructor(props) {
    super(props);
    const { location } = props;
    const { head, periode } = location.state;
    this.onFormChange = this.onFormChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      form: {
        value: {
          head,
          periode,
        },
        error: {},
      },
    };
  }

  onFormChange(event) {
    const {
      name,
      value,
      dataset,
      files,
    } = event.target;
    const { inputType = 'text' } = dataset;
    this.setState((prevState) => {
      let formattedValue = value;
      if (inputType === 'number') {
        formattedValue = commaFormatted(value);
      }
      if (inputType === 'file') {
        formattedValue = files[0];
      }

      return {
        form: {
          value: {
            ...prevState.form.value,
            [name]: formattedValue,
          },
          error: {
            ...prevState.form.error,
            [name]: '',
          },
        },
      };
    });
  }

  onSubmit(e) {
    e.preventDefault();
    const { form } = this.state;
    const { handleUploadRapbuFile, history } = this.props;
    handleUploadRapbuFile(form.value, history.goBack);
    // console.log(form.value)
  }

  render() {
    const { form, handleUploadRapbuFile } = this.state;

    return (
      <div className="add-upload-rapbu">
        <h1>Upload RAPBS & RAPBY</h1>
        <form onSubmit={this.onSubmit}>
          <ListForm
            form={form}
            formFields={FINANCE_ADD_UPLOAD_RAPBU_FORM_FIELDS}
            onFormChange={this.onFormChange}
            handleUploadFile={this.onFormChange}
          />
          <div className="add-upload-rapbu">
            <Button
              type="submit"
              title="Simpan"
            />
          </div>
        </form>
      </div>
    );
  }
}
AddUploadRapbu.propTypes = {
  handleUploadRapbuFile: PropTypes.func,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};
AddUploadRapbu.defaultProps = {
  handleUploadRapbuFile: noop,
};
